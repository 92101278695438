import Table from "components/DataTable/Table";
import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function CustomerContact({
  selectedWorkOrder,
  setSelectedContacts,
  showModal,
}) {
  const dispatch = useDispatch();
  /*   ALL STATES
   ********************************************* */
  const [customerContacts, setCustomerContacts] = useState(null);

  const [selectedFilesList, setSelectedFilesList] = useState([]);

  /*   All Functions
   ********************************************* */
  function handleFileSelect(item) {
    const newSet = new Set(selectedFilesList);
    if (newSet.has(item?.fileName)) {
      newSet.delete(item?.fileName);
    } else {
      newSet.add(item?.fileName);
    }
    setSelectedFilesList(Array.from(newSet));
  }

  const getCustomerContacts = async () => {
    try {
      const { data } = await API.post(
        "/customer-contacts",
        { id: selectedWorkOrder?.customerId },
        {
          headers: authHeader(),
        }
      );
      if (data?.type === "success") {
        let contactsData = data.data.map((item) => {
          return { ...JSON.parse(item?.data) };
        });
        setCustomerContacts(contactsData);
        return;
      }
      if (data?.type === "error") {
        dispatch(
          setNotification({
            message: data?.message,
            type: "error",
          })
        );
        return;
      }
      dispatch(
        setNotification({
          message: "Something Went wrong check console.",
          type: "error",
        })
      );
      console.log(data);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.response.data.message,
          type: "error",
        })
      );
    }
  };

  function handleFileSelect(item) {
    const newSet = new Set(selectedFilesList);
    if (newSet.has(item?.email)) {
      newSet.delete(item?.email);
    } else {
      newSet.add(item?.email);
    }
    setSelectedFilesList(Array.from(newSet));
  }

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    getCustomerContacts();
  }, []);

  /*   Table functions
   ********************************************* */
  const table_head = [
    "",
    "First Name",
    "Last Name",
    "Department",
    "Phone",
    "Email",
  ];

  const table_body = (item) => (
    <tr
      className=""
      onClick={() => {
        handleFileSelect(item);
      }}
      key={item.id}
    >
      <td>
        <input
          type="checkbox"
          checked={selectedFilesList?.includes(item?.email)}
        />
      </td>
      <td className={"py-1"}>{item.firstName}</td>
      <td className={"py-1"}>{item.lastName}</td>
      <td className={"py-1"}>{item.department} </td>
      <td className={"py-1"}>{item.phone} </td>
      <td className={"py-1"}>{item.email} </td>
    </tr>
  );
  return (
    <div>
      {/*   Table
       ********************************************* */}
      <div className="px-2 flex-grow-1 d-flex">
        <div className="horizontal-scrollable d-flex flex-grow-1">
          {customerContacts ? (
            <Table
              table_head={table_head}
              table_body={table_body}
              table_data={customerContacts}
            />
          ) : (
            <Spinner />
          )}
        </div>
      </div>
      <div className="text-end my-2">
        <button
          onClick={() => {
            showModal(false);
          }}
          className="btn btn-secondary"
        >
          Close
        </button>
        <button
          onClick={() => {
            setSelectedContacts((prev) => selectedFilesList);
            showModal(false);
            setSelectedFilesList([]);
          }}
          disabled={selectedFilesList.length === 0}
          className="btn btn-success ms-1"
        >
          Select Files
        </button>
      </div>
    </div>
  );
}
