import Spinner from "components/Spinner";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import authHeader from "services/auth-header";
import API from "services/axios";

function PortalUserAdd({ showAddPage, refreshFunc }) {
  /*   All States Below
   ********************************************* */
  const { register, handleSubmit } = useForm();
  const [showPass, setShowPass] = useState(false);
  const [customersList, setCustomersList] = useState(null);

  /*   All Functions Below
   ********************************************* */
  const addPortalUserData = async (formData, e) => {
    e.preventDefault();
    let customer = JSON.parse(formData?.customer);

    let body = {
      email: customer?.accountPayableEmail,
      password: formData?.password,
      phone: customer?.accountPayablePhone,
      firstName: customer?.customerName.split(" ")[0],
      lastName: customer?.customerName.split(" ")[1],
      cid: customer?.id,
    };

    if (!formData?.customer || formData?.customer === "Select Customer") {
      return alert("Please select a customer");
    }

    try {
      const { data } = await API.post("/portal-users", body, {
        headers: authHeader(),
      });
      if (data.message.type === "success") {
        refreshFunc();
        showAddPage(false);
        return;
      }
    } catch (err) {
      alert(err.message);
    }
  };

  const getCustomersLists = async () => {
    try {
      const { data } = await API.get("/customers", { headers: authHeader() });
      setCustomersList(data);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getCustomersLists();
  }, []);

  if (!customersList) return <Spinner />;

  return (
    <div className="row">
      <div className="col-12 px-3">
        {/*    ADD FORM START
         ********************************************* */}
        <div className="mb-3">
          <h3>ADD Portal User</h3>
        </div>
        <form className="" onSubmit={handleSubmit(addPortalUserData)}>
          <div className="row">
            {/* ----------  Left Section  ----------- */}
            <div className="col-12">
              <div className="mb-3">
                <label htmlFor="phone" className="form-label mb-0">
                  Customers List
                </label>
                <select className="form-control" {...register("customer")}>
                  <option selected value={null} disabled>
                    Select Customer
                  </option>
                  {customersList?.map((customer) => {
                    if (!customer?.accountPayableEmail) return false;
                    return (
                      <option
                        key={customer?.id}
                        value={JSON.stringify(customer)}
                      >
                        {customer.customerName}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className=" mb-3">
                <label htmlFor="password" className="form-label mb-0">
                  Password
                </label>
                <div className="input-group">
                  <input
                    type={showPass ? "text" : "password"}
                    className={`form-control `}
                    placeholder="Enter password"
                    {...register("password")}
                  />
                  <span
                    onClick={() => setShowPass(!showPass)}
                    className="input-group-text input-group-text"
                  >
                    <i
                      className={`mdi mdi-eye${showPass ? "" : "-off"} fs-4`}
                    ></i>
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/*   Buttons
           ********************************************* */}
          <div className={`mt-4 mb-2 d-flex justify-content-end`}>
            <div>
              <button
                className="btn px-1 btn-secondary"
                type="button"
                onClick={() => {
                  showAddPage(false);
                }}
              >
                Cancel
              </button>
              <button className="btn btn-primary ms-1" type="submit">
                ADD
              </button>
            </div>
          </div>
        </form>

        {/*    ADD FORM END
         ********************************************* */}
      </div>
    </div>
  );
}

export default PortalUserAdd;
