import Spinner from "components/Spinner";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import authHeader from "services/auth-header";
import API from "services/axios";

function AccountPayable({ customerDetails, refreshFunc }) {
  /*   All States
   ********************************************* */
  const [showPayableModal, setShowPayableModal] = useState(false);
  const [formData, setFormData] = useState({ ...customerDetails });
  const [isLoading, setIsLoading] = useState(false);

  /*   All Functions
   ********************************************* */
  const updateAccountPayable = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const updatedData = JSON.stringify({ ...formData, id: customerDetails.id });

    try {
      const { data } = await API.patch(
        "/customers",
        { updatedData },
        { headers: authHeader() }
      );
      if (data.success) {
        refreshFunc(customerDetails.id);
        setIsLoading(false);
        setShowPayableModal(false);
        return;
      }
    } catch (err) {
      alert(err.message);
    }
  };

  useEffect(() => {
    setFormData(customerDetails);
  }, [customerDetails]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center w-100">
        <h4 className="border-bottom border-secondary">ACCOUNT PAYABLE</h4>
        <div>
          <button
            className="btn d-flex justify-content-center align-items-center btn-sm btn-success "
            style={{ width: 28, height: 28 }}
            onClick={() => {
              setShowPayableModal(true);
            }}
          >
            <i className="mdi fs-5 mdi-pencil"></i>
          </button>
        </div>
      </div>
      <div className="w-100 d-flex mb-2">
        <div className="d-flex flex-column" style={{ minWidth: 110 }}>
          <div className="">Name</div>
          <div className="">Phone</div>
          <div className="">Email</div>
        </div>

        <div className="">
          <div className="">
            :&nbsp;&nbsp;&nbsp;
            <p className="d-inline text-truncate">
              {customerDetails?.accountPayableContact != ""
                ? customerDetails?.accountPayableContact
                : "-"}
            </p>
          </div>
          <div className="">
            :&nbsp;&nbsp;&nbsp;
            <p className="d-inline text-truncate">
              {customerDetails?.accountPayablePhone != ""
                ? customerDetails?.accountPayablePhone
                : "-"}
            </p>
          </div>
          <div className="">
            :&nbsp;&nbsp;&nbsp;
            <p className="d-inline text-truncate">
              {customerDetails?.accountPayableEmail != ""
                ? customerDetails?.accountPayableEmail
                : "-"}
            </p>
          </div>
        </div>
      </div>
      <h4 className="border-bottom border-secondary">BILLING ADDRESS</h4>
      <div className="d-flex">
        <div className="d-flex flex-column" style={{ minWidth: 110 }}>
          {/* Address Company Name */}
          <div className="">Company Name</div>
          <div className="">Address Contact</div>
          {/* Address 1 */}
          <div className="">Address 1</div>
          {/* Tracking ID */}
          <div className="">Address 2</div>
          {/* Tracking ID */}
          <div className="">City</div>
          {/* Void  */}
          <div className="">State</div>
          {/* Refference  */}
          <div className="">Zip Code</div>
          {/* Weight  */}
          <div className="">Country</div>
        </div>

        <div className="">
          {/* Website  */}
          <div className="">
            :&nbsp;&nbsp;&nbsp;
            <p className="d-inline text-truncate">
              {customerDetails?.addressCompany
                ? customerDetails.addressCompany
                : "-"}
            </p>
          </div>
          <div className="">
            :&nbsp;&nbsp;&nbsp;
            <p className="d-inline text-truncate">
              {customerDetails?.addressContact
                ? customerDetails.addressContact
                : "-"}
            </p>
          </div>

          {/* Website  */}
          <div className="">
            :&nbsp;&nbsp;&nbsp;
            <p className="d-inline text-truncate">
              {customerDetails?.addressLine1
                ? customerDetails.addressLine1
                : "-"}
            </p>
          </div>
          {/* Transit Days  */}
          <div className="w-50">
            :&nbsp;&nbsp;&nbsp;
            <p className="d-inline text-truncate ">
              {customerDetails?.addressLine2
                ? customerDetails.addressLine2
                : "-"}
            </p>
          </div>
          {/* Customer Type  */}
          <div className="">
            <div className="">
              :&nbsp;&nbsp;&nbsp;
              <p className="d-inline text-truncate">
                {customerDetails?.addressCity
                  ? customerDetails.addressCity
                  : "-"}
              </p>
            </div>
          </div>
          {/* No of Packages  */}
          <div className=" ">
            :&nbsp;&nbsp;&nbsp;
            <p className="d-inline text-truncate">
              {customerDetails?.addressState
                ? customerDetails.addressState
                : "-"}
            </p>
          </div>
          {/* No of Packages  */}
          <div className="">
            :&nbsp;&nbsp;&nbsp;
            <p className="d-inline text-truncate">
              {customerDetails?.addressZipCode
                ? customerDetails.addressZipCode
                : "-"}
            </p>
          </div>
          {/* No of Packages  */}
          <div className="">
            :&nbsp;&nbsp;&nbsp;
            <p className="d-inline text-truncate">
              {customerDetails?.addressCountry
                ? customerDetails.addressCountry
                : "-"}
            </p>
          </div>
        </div>
      </div>
      {/*  Payable Modal
       ********************************************* */}
      <Modal show={showPayableModal} onHide={() => setShowPayableModal(false)}>
        <Modal.Body>
          <div className="">
            <form onSubmit={updateAccountPayable}>
              {/*   Payable Person Edit
               ********************************************* */}
              <div className="">
                <h4 className="mb-0">Payable Person Details</h4>
              </div>
              <hr className="mt-0" />
              <div className="row mb-1">
                <label className="col-3 my-auto">Name</label>
                <div className="col-9 ">
                  <input
                    type="text"
                    value={formData?.accountPayableContact}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        accountPayableContact: e.target.value,
                      })
                    }
                    className="form-control"
                    placeholder="Enter Name"
                  />
                </div>
              </div>
              <div className="row mb-1">
                <label className="col-3 my-auto">Phone</label>
                <div className="col-9 ">
                  <input
                    type="text"
                    value={formData?.accountPayablePhone}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        accountPayablePhone: e.target.value,
                      })
                    }
                    className="form-control"
                    placeholder="Enter Phone"
                  />
                </div>
              </div>
              <div className="row mb-1">
                <label className="col-3 my-auto">Email</label>
                <div className="col-9 ">
                  <input
                    type="email"
                    value={formData?.accountPayableEmail}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        accountPayableEmail: e.target.value,
                      })
                    }
                    className="form-control"
                    placeholder="Enter Email"
                  />
                </div>
              </div>
              {/*   Billing Address
               ********************************************* */}
              <div className="mt-3">
                <h4 className="mb-0">Billing Address</h4>
              </div>
              <hr className="mt-0" />
              <div className="row mb-1">
                <label className="col-3 my-auto">Company Name</label>
                <div className="col-9 ">
                  <input
                    type="text"
                    value={formData?.addressCompany}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        addressCompany: e.target.value,
                      })
                    }
                    className="form-control"
                    placeholder="Enter comapny name"
                  />
                </div>
              </div>
              <div className="row mb-1">
                <label className="col-3 my-auto">Address Contact</label>
                <div className="col-9 ">
                  <input
                    type="text"
                    value={formData?.addressContact}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        addressContact: e.target.value,
                      })
                    }
                    className="form-control"
                    placeholder="Enter address contact"
                  />
                </div>
              </div>
              <div className="row mb-1">
                <label className="col-3 my-auto">Adddress 1</label>
                <div className="col-9 ">
                  <input
                    type="text"
                    value={formData?.addressLine1}
                    onChange={(e) =>
                      setFormData({ ...formData, addressLine1: e.target.value })
                    }
                    className="form-control"
                    placeholder="Enter adddress 1"
                  />
                </div>
              </div>
              <div className="row mb-1">
                <label className="col-3 my-auto">Adddress 2</label>
                <div className="col-9 ">
                  <input
                    type="text"
                    value={formData?.addressLine2}
                    onChange={(e) =>
                      setFormData({ ...formData, addressLine2: e.target.value })
                    }
                    className="form-control"
                    placeholder="Enter adddress 2"
                  />
                </div>
              </div>

              <div className="row mb-1">
                <label className="col-3 my-auto">City/State/Zipcode</label>
                <div className="col-9 ">
                  <div className="d-flex">
                    <input
                      type="text"
                      value={formData?.addressCity}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          addressCity: e.target.value,
                        })
                      }
                      className="form-control w-50"
                      placeholder="City"
                    />
                    <input
                      type="text"
                      value={formData?.addressState}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          addressState: e.target.value,
                        })
                      }
                      style={{ width: "20%" }}
                      className="form-control"
                      placeholder="State"
                    />
                    <input
                      type="number"
                      value={formData?.addressZipCode}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          addressZipCode: e.target.value,
                        })
                      }
                      style={{ width: "30%" }}
                      className="form-control"
                      placeholder="Zipcode"
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-1">
                <label className="col-3 my-auto">Country</label>
                <div className="col-9 ">
                  <input
                    type="text"
                    value={formData?.addressCountry}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        addressCountry: e.target.value,
                      })
                    }
                    className="form-control"
                    placeholder="Enter country"
                  />
                </div>
              </div>

              {isLoading && <Spinner />}

              {/*   Buttons
               ********************************************* */}
              <div className="mt-3 flex-grow-1 d-flex justify-content-end align-items-end">
                <div>
                  <button
                    onClick={() => setShowPayableModal(false)}
                    type="button"
                    className="btn btn-secondary me-2 px-1"
                  >
                    CLOSE
                  </button>
                  <button type="submit" className="btn btn-primary">
                    UPDATE
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AccountPayable;
