import Toast from "components/Toast/ToastComponent";
import { setNotification } from "features/Notification/notificationSlice";
import ImageWIthSignedUrl from "pages/DesignRequests/DesignRequest/ImageWIthSignedUrl";
import { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";
import CategorySelect from "./MockupFabrics/CategorySelect";
import FabricSelect from "./MockupFabrics/FabricSelect";

export function AddMockupCatalogue({ showModal, refreshFunc }) {
  /*   All States
   ********************************************* */
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [showCategoriesModal, setShowCategoriesModal] = useState(false);
  const [showFabricsModal, setShowFabricsModal] = useState(false);
  const [categoriesList, setCategoriesList] = useState([]);
  const [fabricList, setFabricList] = useState([]);

  const [files, setFiles] = useState({});

  /*   All Functions
   ********************************************* */

  let loadingOn = () => {
    setLoading(true);
  };

  let loadingOff = () => {
    setLoading(false);
  };

  /*   All Functions
   ********************************************* */
  const handleFileChange = (e) => {
    let file = e.target.files[0];

    let fileData = {
      file: file,
      fileName: file.name,
    };

    setFiles((prev) => {
      return { ...prev, [e.target.name]: fileData };
    });
  };

  const addMockupCatalogueData = async (e) => {
    e.preventDefault();
    loadingOn();

    const formDataValue = new FormData();

    const updatedData = {
      ...formData,
      categories: JSON.stringify(categoriesList),
      fabrics: JSON.stringify(fabricList),
    };
    formDataValue.append("updatedData", JSON.stringify(updatedData));

    Array.from(Object.keys(files)).map((key) => {
      formDataValue.append(`file`, files[key].file);
    });
    formDataValue.append("filesData", JSON.stringify(files));

    try {
      const { data } = await API.post("/mockup-catalogue", formDataValue, {
        headers: { ...authHeader(), "Content-Type": "multipart/form-data" },
      });
      if (data.message.type === "success") {
        refreshFunc();
        loadingOff();
        showModal(false);
        return;
      }
    } catch (err) {
      loadingOff();
      alert(err.message);
    }
  };

  const updateInput = (e) => {
    setFormData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  /*   All UseEffects
   ********************************************* */
  return (
    <div className="row">
      <div className="col-12">
        {loading && <Spinner />}
        <Toast />
        {/*    ADD FORM START
         ********************************************* */}
        <div className="mb-13 ms-3">
          <h3>ADD Product</h3>
        </div>
        <form className="ps-3 pe-3" onSubmit={addMockupCatalogueData}>
          {/* ----------  Fields  ----------- */}
          <div className="mt-3 mb-3">
            <label htmlFor="title" className="form-label">
              Style #
            </label>
            <input
              className="form-control"
              type="text"
              required
              id="styleNo"
              placeholder="Enter Style No"
              name="styleNo"
              onChange={updateInput}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="description" className="form-label">
              Description
            </label>
            <input
              className="form-control"
              type="text"
              id="description"
              placeholder="Enter description"
              name="description"
              onChange={updateInput}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="productImage" className="form-label">
              Product Image
            </label>
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-grow-1">
                <input
                  className="form-control"
                  type="file"
                  id="productImage"
                  name="productImage"
                  onChange={handleFileChange}
                />
              </div>
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="pdfCatalogue" className="form-label">
              Pdf Catalogue
            </label>
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-grow-1">
                <input
                  className="form-control"
                  type="file"
                  id="pdfCatalogue"
                  name="pdfCatalogue"
                  onChange={handleFileChange}
                />
              </div>
            </div>
          </div>

          {/* <div className="mb-3">
            <div className="row mb-1">
              <label className="col-5">Has Player Names?</label>
              <div className="col-7 ">
                <input
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      hasPlayerNames: e.target.checked,
                    })
                  }
                  checked={formData?.hasPlayerNames}
                  type="checkbox"
                  id="switch0"
                  data-switch="success"
                />
                <label
                  htmlFor="switch0"
                  data-on-label="Yes"
                  data-off-label="No"
                ></label>
              </div>
            </div>
            <div className="row mb-1">
              <label className="col-5">Has Numbers?</label>
              <div className="col-7 ">
                <input
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      hasNumbers: e.target.checked,
                    })
                  }
                  checked={formData?.hasNumbers}
                  type="checkbox"
                  id="switch1"
                  data-switch="success"
                />
                <label
                  htmlFor="switch1"
                  data-on-label="Yes"
                  data-off-label="No"
                ></label>
              </div>
            </div>
          </div> */}

          <hr />

          <div className="d-flex gap-2">
            <button
              onClick={() => {
                setShowCategoriesModal(true);
              }}
              type="button"
              className={`btn btn-sm ${
                categoriesList.length ? "btn-success" : "btn-secondary"
              }`}
            >
              Select Categories
            </button>
            <button
              onClick={() => {
                setShowFabricsModal(true);
              }}
              type="button"
              className={`btn btn-sm ${
                fabricList.length ? "btn-success" : "btn-secondary"
              }`}
            >
              Select Fabrics
            </button>
          </div>

          <div className={`mt-4 mb-2 d-flex justify-content-end`}>
            <div>
              <button
                className="btn px-1 btn-secondary"
                type="button"
                onClick={() => {
                  showModal(false);
                }}
              >
                Cancel
              </button>
              <button className="btn btn-primary ms-1" type="submit">
                ADD
              </button>
            </div>
          </div>
        </form>

        {/*    ADD FORM END
         ********************************************* */}
        {/*   Categories List Modal
         ********************************************* */}
        <Modal
          size="sm"
          contentClassName="shadow-lg custom-border-radius"
          show={showCategoriesModal}
          onHide={() => setShowCategoriesModal(false)}
        >
          <Modal.Body className="custom-shadow">
            <div>
              <CategorySelect
                categoriesList={categoriesList}
                setCategoriesList={setCategoriesList}
                showModal={setShowCategoriesModal}
              />
            </div>
          </Modal.Body>
        </Modal>
        {/*   Fabrics List Modal
         ********************************************* */}
        <Modal
          size="sm"
          contentClassName="shadow-lg custom-border-radius"
          show={showFabricsModal}
          onHide={() => setShowFabricsModal(false)}
        >
          <Modal.Body className="custom-shadow">
            <div>
              <FabricSelect
                fabricList={fabricList}
                setFabricList={setFabricList}
                showModal={setShowFabricsModal}
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export function UpdateMockupCatalogue({
  selectedItem,
  showModal,
  refreshFunc,
}) {
  const dispatch = useDispatch();

  /*   All States
   ********************************************* */
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  const [showCategoriesModal, setShowCategoriesModal] = useState(false);
  const [showFabricsModal, setShowFabricsModal] = useState(false);
  const [categoriesList, setCategoriesList] = useState([]);
  const [fabricList, setFabricList] = useState([]);

  const [files, setFiles] = useState({});

  /*   All Functions
   ********************************************* */

  let loadingOn = () => {
    setLoading(true);
  };

  let loadingOff = () => {
    setLoading(false);
  };

  /*   All Functions
   ********************************************* */
  const handleFileChange = (e) => {
    let file = e.target.files[0];

    let fileData = {
      file: file,
      fileName: file.name,
    };

    setFiles((prev) => {
      return { ...prev, [e.target.name]: fileData };
    });
  };

  const updateMockupCatalogueData = async (e) => {
    e.preventDefault();
    loadingOn();

    const formDataValue = new FormData();

    const updatedData = {
      ...formData,
      categories: JSON.stringify(categoriesList),
      fabrics: JSON.stringify(fabricList),
    };
    formDataValue.append("updatedData", JSON.stringify(updatedData));

    Array.from(Object.keys(files)).map((key) => {
      formDataValue.append(`file`, files[key].file);
    });
    formDataValue.append("filesData", JSON.stringify(files));

    try {
      const { data } = await API.patch("/mockup-catalogue", formDataValue, {
        headers: { ...authHeader(), "Content-Type": "multipart/form-data" },
      });
      if (data?.message?.type === "success") {
        refreshFunc();
        showModal(false);
        loadingOff();
        return;
      } else {
        dispatch(
          setNotification({
            message: data,
            type: "error",
          })
        );
      }
      console.log(data);
    } catch (err) {
      // alert(err.message);
      console.log(err);
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
    loadingOff();
  };

  const updateInput = (e) => {
    setFormData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  /*   All UseEffects
   ********************************************* */

  useEffect(() => {
    setFormData({
      id: selectedItem?.id,
      styleNo: selectedItem?.styleNo,
      description: selectedItem?.description,
      productImage: selectedItem?.productImage,
      pdfCatalogue: selectedItem?.pdfCatalogue,
    });
    setCategoriesList(
      selectedItem?.categories && JSON.parse(selectedItem?.categories)
    );
    setFabricList(selectedItem?.fabrics && JSON.parse(selectedItem?.fabrics));
  }, [selectedItem]);
  return (
    <div className="row">
      <div className="col-12">
        {loading && <Spinner />}
        <Toast />
        {/*    ADD FORM START
         ********************************************* */}
        <div className="mb-13 ms-3">
          <h3>Update Product</h3>
        </div>
        <form className="ps-3 pe-3" onSubmit={updateMockupCatalogueData}>
          {/* ----------  Fields  ----------- */}
          <div className="mt-3 mb-3">
            <label htmlFor="title" className="form-label">
              Style #
            </label>
            <input
              className="form-control"
              type="text"
              required
              id="styleNo"
              value={formData?.styleNo || ""}
              placeholder="Enter Style No"
              name="styleNo"
              onChange={updateInput}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="description" className="form-label">
              Description
            </label>
            <input
              className="form-control"
              type="text"
              id="description"
              value={formData?.description || ""}
              placeholder="Enter description"
              name="description"
              onChange={updateInput}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="productImage" className="form-label">
              Product Image
            </label>
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-grow-1">
                <input
                  className="form-control"
                  type="file"
                  id="productImage"
                  name="productImage"
                  onChange={handleFileChange}
                />
                {formData?.productImage && (
                  <div className="bg-info">
                    <ImageWIthSignedUrl
                      path={formData?.productImage}
                      width={50}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="pdfCatalogue" className="form-label">
              Pdf Catalogue
            </label>
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-grow-1 align-items-center">
                <input
                  className="form-control"
                  type="file"
                  id="pdfCatalogue"
                  name="pdfCatalogue"
                  onChange={handleFileChange}
                />
                {formData?.pdfCatalogue && (
                  <div className="">
                    <div className="text-success ms-2">Attached</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <hr />

          <div className="d-flex gap-2">
            <button
              onClick={() => {
                setShowCategoriesModal(true);
              }}
              type="button"
              className={`btn btn-sm ${
                categoriesList.length ? "btn-success" : "btn-secondary"
              }`}
            >
              Select Categories
            </button>
            <button
              onClick={() => {
                setShowFabricsModal(true);
              }}
              type="button"
              className={`btn btn-sm ${
                fabricList.length ? "btn-success" : "btn-secondary"
              }`}
            >
              Select Fabrics
            </button>
          </div>

          <div className={`mt-4 mb-2 d-flex justify-content-end`}>
            <div>
              <button
                className="btn px-1 btn-secondary"
                type="button"
                onClick={() => {
                  showModal(false);
                }}
              >
                Cancel
              </button>
              <button className="btn btn-primary ms-1" type="submit">
                Update
              </button>
            </div>
          </div>
        </form>

        {/*    Update FORM END
         ********************************************* */}
        {/*   Categories List Modal
         ********************************************* */}
        <Modal
          size="sm"
          contentClassName="shadow-lg custom-border-radius"
          show={showCategoriesModal}
          onHide={() => setShowCategoriesModal(false)}
        >
          <Modal.Body className="custom-shadow">
            <div>
              <CategorySelect
                categoriesList={categoriesList}
                setCategoriesList={setCategoriesList}
                showModal={setShowCategoriesModal}
              />
            </div>
          </Modal.Body>
        </Modal>
        {/*   Fabrics List Modal
         ********************************************* */}
        <Modal
          size="sm"
          contentClassName="shadow-lg custom-border-radius"
          show={showFabricsModal}
          onHide={() => setShowFabricsModal(false)}
        >
          <Modal.Body className="custom-shadow">
            <div>
              <FabricSelect
                fabricList={fabricList}
                setFabricList={setFabricList}
                showModal={setShowFabricsModal}
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}
