import Spinner from "components/Spinner";
import Toast from "components/Toast/ToastComponent";
import { setNotification } from "features/Notification/notificationSlice";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";

function MockupFabricsUpdate({ updateData, showUpdatePage, refreshFunc }) {
  const dispatch = useDispatch();
  /*   All States Below
   ********************************************* */
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);

  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  /*   All Functions Below
   ********************************************* */
  const updateMockupFabricsData = async (formData, e) => {
    e.preventDefault();

    try {
      loadingOn();
      const { data } = await API.patch(
        "/mockup-fabric/",
        { ...formData, id: updateData.id },
        { headers: authHeader() }
      );

      if (data?.type === "success") {
        await refreshFunc();
        showUpdatePage(false);
        return;
      }
      loadingOff();
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      loadingOff();
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        {loading && <Spinner />}
        <Toast />
        {/*    Update FORM START
         ********************************************* */}
        {updateData && (
          <form
            className="ps-3 pe-3"
            onSubmit={handleSubmit(updateMockupFabricsData)}
          >
            <div className="mb-3">
              <h3>UPDATE {updateData?.name?.toUpperCase()}</h3>
            </div>
            {/* ----------  Fields  ----------- */}
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Fabric Name
              </label>
              <input
                className="form-control"
                type="text"
                id="name"
                required
                defaultValue={updateData?.name}
                placeholder="Enter Fabric name"
                {...register("name")}
              />
            </div>

            <div className={`mt-4 mb-2 d-flex justify-content-end`}>
              <div>
                <button
                  className="btn px-1 btn-secondary"
                  type="button"
                  onClick={() => {
                    showUpdatePage(false);
                  }}
                >
                  Cancel
                </button>
                <button className="btn btn-primary ms-1" type="submit">
                  UPDATE
                </button>
              </div>
            </div>
          </form>
        )}
        {/*    Update FORM END
         ********************************************* */}
      </div>
    </div>
  );
}

export default MockupFabricsUpdate;
