import React, { useEffect, useState } from "react";
import API from "services/axios";
import authHeader from "services/auth-header";
import Table from "components/DataTable/Table";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import CustomerAdresssAdd from "./CustomerAddressAdd";
import CustomerAdresssUpdate from "./CustomerAddressUpdate";
import { useSelector } from "react-redux";

function CustomersAddress({ id, customersAddressData, getCustomerInfo }) {
  const user = useSelector((state) => state.user?.userInfo);
  /*   All States
   ********************************************* */
  const [addressData, setAdresssData] = useState(null);
  const [lastSortId, setLastSortId] = useState(null);
  const [filterBy, setFilterBy] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const [deleteID, setDeleteID] = useState(null);

  /*   All Functions
   ********************************************* */

  // Delete Customers
  const deleteCustomerAdress = async (id) => {
    try {
      const { data } = await API.post(
        "/customers/addresses/delete",
        { id },
        { headers: authHeader() }
      );
      if (data.success) {
        getCustomerInfo();
        setShowDeleteModal(false);
        return;
      }
    } catch (err) {
      alert(err.message);
    }
  };

  // Sorting Up
  const sortUp = async (index) => {
    const currentSortId = customersAddressData[index].sortId;
    const currentId = customersAddressData[index].id;
    const upSortId = customersAddressData[index - 1].sortId;
    const upId = customersAddressData[index - 1].id;
    try {
      const { data } = await API.post(
        "/customers/addresses/sortup",
        { currentSortId, currentId, upSortId, upId },
        { headers: authHeader() }
      );
      if (data.success) {
        getCustomerInfo();
      }
    } catch (err) {
      alert(err.message);
    }
  };

  // Sorting Up
  const sortDown = async (index) => {
    const currentSortId = customersAddressData[index].sortId;
    const currentId = customersAddressData[index].id;
    const downSortId = customersAddressData[index + 1].sortId;
    const downId = customersAddressData[index + 1].id;
    try {
      const { data } = await API.post(
        "/customers/addresses/sortdown",
        { currentSortId, currentId, downSortId, downId },
        { headers: authHeader() }
      );
      if (data.success) {
        getCustomerInfo();
      }
    } catch (err) {
      alert(err.message);
    }
  };
  /*   Table Functions
   ********************************************* */
  const btn1 = () => (
    <div>
      {(user?.permissions?.includes("customer_add") ||
        user?.roles === "admin") && (
        <button
          onClick={() => setShowAddModal(true)}
          type="button"
          className="btn btn-primary  ms-1"
        >
          ADD ADDRESS
        </button>
      )}
    </div>
  );

  const btnLeft = () => (
    <div className="d-flex">
      <h4 className="border-secondary border-bottom">CUSTOMERS ADDRESS</h4>
    </div>
  );

  const buttons = () => (
    <div className="d-flex justify-content-end flex-grow-1">
      <div className=" d-flex justify-content-end ">
        {/* FilterBy */}
        <button
          type="button"
          className="px-1 bg-primary btn text-dark bg-opacity-10 border border-primary rounded-end mx-2 rounded-start"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {filterBy
            ? filterBy.toUpperCase().replaceAll("_", " ")
            : "Filter by All"}
        </button>
        <div className="dropdown-menu">
          <button onClick={() => setFilterBy(null)} className="dropdown-item">
            Filter by All
          </button>
          <button
            onClick={() => setFilterBy("description")}
            className="dropdown-item"
          >
            Description
          </button>
          <button
            onClick={() => setFilterBy("addressCompanyName")}
            className="dropdown-item"
          >
            Company Name
          </button>
          <button
            onClick={() => setFilterBy("attentionTo")}
            className="dropdown-item"
          >
            Attention To
          </button>
          <button
            onClick={() => setFilterBy("address1")}
            className="dropdown-item"
          >
            Address 1
          </button>
          <button
            onClick={() => setFilterBy("address2")}
            className="dropdown-item"
          >
            Address 2
          </button>
          <button onClick={() => setFilterBy("city")} className="dropdown-item">
            City
          </button>
          <button
            onClick={() => setFilterBy("state")}
            className="dropdown-item"
          >
            State
          </button>
          <button
            onClick={() => setFilterBy("zipcode")}
            className="dropdown-item"
          >
            Zip
          </button>
          <button
            onClick={() => setFilterBy("country")}
            className="dropdown-item"
          >
            Country
          </button>
        </div>
      </div>
    </div>
  );
  const table_head = [
    "Description",
    "Company Name",
    "Attention To",
    "Address 1",
    "Address 2",
    "City",
    "State",
    "Zip Code",
    "Country",
    "Action",
  ];

  const table_body = (item, index) => (
    <tr className="" key={item.id}>
      <td className="py-0 pe-1 align-middle">{item.description}</td>
      <td className="py-0 pe-1 align-middle">{item.addressCompanyName} </td>
      <td className="py-0 pe-1 align-middle">{item.attentionTo} </td>
      <td className="py-0 pe-1 align-middle">{item.address1} </td>
      <td className="py-0 pe-1 align-middle">{item.address2} </td>
      <td className="py-0 pe-1 align-middle">{item.city} </td>
      <td className="py-0 pe-1 align-middle">{item.state} </td>
      <td className="py-0 pe-1 align-middle">{item.zipcode} </td>
      <td className="py-0 pe-1 align-middle">{item.country} </td>
      <td className="table-action py-0">
        {(user?.permissions?.includes("customer_update") ||
          user?.roles === "admin") && (
          <Link
            onClick={() => {
              item.sortId != 1 && sortUp(index);
            }}
            className="action-icon"
          >
            <i className="mdi mdi-chevron-double-up"></i>
          </Link>
        )}
        {(user?.permissions?.includes("customer_update") ||
          user?.roles === "admin") && (
          <Link
            onClick={() => {
              item.sortId != addressData.length && sortDown(index);
            }}
            className="action-icon"
          >
            <i className="mdi mdi-chevron-double-down"></i>
          </Link>
        )}
        {(user?.permissions?.includes("customer_update") ||
          user?.roles === "admin") && (
          <Link
            onClick={() => {
              setSelected(item);
              setShowUpdateModal(true);
            }}
            className="action-icon"
          >
            <i className="mdi mdi-pencil"></i>
          </Link>
        )}
        {(user?.permissions?.includes("customer_delete") ||
          user?.roles === "admin") && (
          <Link
            onClick={() => {
              setShowDeleteModal(true);
              setDeleteID(item.id);
            }}
            className="action-icon"
          >
            <i className="mdi mdi-delete"></i>
          </Link>
        )}
      </td>
    </tr>
  );

  useEffect(() => {
    setAdresssData(customersAddressData);
    setLastSortId(
      customersAddressData[customersAddressData.length - 1]?.sortId
        ? customersAddressData[customersAddressData.length - 1]?.sortId
        : 0
    );
  }, [filterBy, customersAddressData]);

  return (
    <div className="d-flex flex-grow-1 ">
      {/*    Table Card
       ********************************************* */}
      <div className=" py-2 flex-grow-1">
        {addressData && (
          <Table
            filterBy={filterBy}
            buttons={buttons}
            btn1={btn1}
            btnLeft={btnLeft}
            table_head={table_head}
            table_body={table_body}
            table_data={addressData}
          />
        )}
      </div>
      {/*   Add Modal
       ********************************************* */}
      <Modal
        show={showAddModal}
        size="xl"
        contentClassName="bg-transparent"
        onHide={() => setShowAddModal(false)}
      >
        <Modal.Body className="bg-white custom-shadow custom-border-radius">
          <CustomerAdresssAdd
            id={id}
            lastSortId={lastSortId}
            refreshFunc={getCustomerInfo}
            addressData={addressData}
            showAddModal={showAddModal}
            setShowAddModal={setShowAddModal}
          />
        </Modal.Body>
      </Modal>

      {/*   Update Modal
       ********************************************* */}

      {selected && (
        <Modal
          show={showUpdateModal}
          size="xl"
          onHide={() => setShowUpdateModal(false)}
        >
          <Modal.Body>
            {selected && (
              <CustomerAdresssUpdate
                id={id}
                selected={selected}
                refreshFunc={getCustomerInfo}
                addressData={addressData}
                showUpdateModal={showUpdateModal}
                setShowUpdateModal={setShowUpdateModal}
              />
            )}
          </Modal.Body>
        </Modal>
      )}
      {/*   Delete Modal
       ********************************************* */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Body className="bg-danger">
          <div className="">
            <div className="text-center text-white">
              <i className="ri-close-circle-line h1"></i>
              <h4 className="mt-2">Confirm Delete!</h4>
              <p className="mt-3">Do You want to delete ?</p>
              <button
                type="button"
                onClick={() => setShowDeleteModal(false)}
                className="btn btn-light my-2 mx-2"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-outline-light my-2 "
                data-bs-dismiss="modal"
                onClick={() => {
                  deleteCustomerAdress(deleteID);
                }}
              >
                DELETE
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CustomersAddress;
