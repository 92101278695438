import Spinner from "components/Spinner";
import { useEffect, useState } from "react";
import authHeader from "services/auth-header";
import API from "services/axios";

function EmailSmtpAdd({ showAddPage, refreshFunc }) {
  /*   All States Below
   ********************************************* */
  const [formError, setFormError] = useState({});
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [locations, setLocations] = useState(null);

  /*   All Functions
   ********************************************* */
  // get All Location
  const getAllLocations = async () => {
    try {
      const { data } = await API.get("/locations", {
        headers: authHeader(),
      });
      setLocations(data);
    } catch (err) {
      alert(err.message);
    }
  };

  const addEmailSmtpData = async (e) => {
    setFormError({});
    e.preventDefault();
    // setLoading(true);

    if (formData.emailSmtpName === "") {
      setFormError({
        emailSmtpName: {
          type: "emailSmtpName",
          message: "Email is required",
        },
      });
      return;
    }

    if (!formData.locationId) {
      setFormError({
        locationId: {
          type: "locationId",
          message: "Please Select Location",
        },
      });
      return;
    }

    try {
      const { data } = await API.post("/email-smtp", formData, {
        headers: { ...authHeader() },
      });
      if (data.message.type === "success") {
        refreshFunc();
        setLoading(false);
        showAddPage(false);
        return;
      }
    } catch (err) {
      setLoading(false);
      alert(err.message);
    }
  };

  const updateInput = (e) => {
    setFormData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  useEffect(() => {
    getAllLocations();
  }, []);

  if (!locations) return <Spinner />;

  return (
    <div className="row">
      <div className="col-12">
        {loading && <Spinner />}
        {/*    ADD FORM START
         ********************************************* */}
        <div className="mb-13 ms-3">
          <h3>ADD Email Smtp</h3>
        </div>
        <form className="ps-3 pe-3" onSubmit={addEmailSmtpData}>
          {/* ----------  Fields  ----------- */}
          <div className="mt-3 mb-3">
            <label htmlFor="accountName" className="form-label">
              Account Name
            </label>
            <input
              className="form-control"
              type="text"
              id="accountName"
              placeholder="Enter Account title/name"
              name="name"
              required
              onChange={updateInput}
            />
            {formError && formError.accountName && (
              <p className="text-danger">{formError.accountName.message}</p>
            )}
          </div>
          <div className="mt-3 mb-3">
            <label htmlFor="locationId" className="form-label">
              Location
            </label>
            <select
              name="locationId"
              onChange={(e) => {
                setFormData((prev) => {
                  return { ...prev, locationId: parseInt(e.target.value) };
                });
              }}
              className="form-control"
              required
            >
              <option disabled selected>
                Select Location
              </option>
              {locations?.map((item) => (
                <option key={item?.id} value={item?.id}>
                  {item?.locationName}
                </option>
              ))}
            </select>
            {formError && formError.locationId && (
              <p className="text-danger">{formError.locationId.message}</p>
            )}
          </div>
          <div className="mt-3 mb-3">
            <label htmlFor="emailSmtpName" className="form-label">
              Smtp Email
            </label>
            <input
              className="form-control"
              type="email"
              id="emailSmtpName"
              placeholder="Enter Email"
              name="email"
              required
              onChange={updateInput}
            />
            {formError && formError.emailSmtpName && (
              <p className="text-danger">{formError.emailSmtpName.message}</p>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="smtp-password" className="form-label">
              Smtp Password
            </label>
            <div className="d-flex gap-2 justify-content-between align-items-center">
              <input
                className="form-control"
                type={showPassword ? "text" : "password"}
                required
                id="smtp-password"
                placeholder="Enter Password"
                name="password"
                onChange={updateInput}
              />
              <div>
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? "Hide" : "Show"}
                </button>
              </div>
            </div>
          </div>

          <div className={`mt-4 mb-2 d-flex justify-content-end`}>
            <div>
              <button
                className="btn px-1 btn-secondary"
                type="button"
                onClick={() => {
                  showAddPage(false);
                }}
              >
                Cancel
              </button>
              <button className="btn btn-primary ms-1" type="submit">
                ADD
              </button>
            </div>
          </div>
        </form>

        {/*    ADD FORM END
         ********************************************* */}
      </div>
    </div>
  );
}

export default EmailSmtpAdd;
