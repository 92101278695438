import { Text, View } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";

export default function QuotePdfItemsTable({ styles, selectedQuote }) {
  /*   All States
   ********************************************* */

  /*   All Functions
   ********************************************* */

  /*   All UseEffects
   ********************************************* */

  return (
    <View>
      {/*   Table Head
       ********************************************* */}
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-end",
          borderBottom: "2px solid gray",
        }}
      >
        <View style={styles.colWithXs}>
          <Text style={styles.textbody}>Sr</Text>
        </View>
        <View style={styles.colWith}>
          <Text style={styles.textbody}>Style No</Text>
        </View>
        <View style={styles.colWithLong}>
          <Text style={styles.textbody}>Description</Text>
        </View>
        <View style={styles.colWithSm}>
          <Text style={styles.textbody}>Color</Text>
        </View>

        <View style={styles.colWithSm}>
          <Text style={styles.textbody}>Fabric</Text>
        </View>
        <View style={styles.colWithSm}>
          <Text style={styles.textbody}>Qty</Text>
        </View>
        <View style={styles.colWithMd}>
          <Text style={[styles.textbody, { textAlign: "left" }]}>Price</Text>
        </View>
        <View style={styles.colWithMd}>
          <Text style={[styles.textbody, { textAlign: "right" }]}>Total</Text>
        </View>
      </View>
      {/*   Table Body
       ********************************************* */}
      {selectedQuote?.quoteProducts &&
        selectedQuote?.quoteProducts?.map((item, index) => (
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid gray",

              paddingHorizontal: 10,
            }}
            key={item?.id}
          >
            <View style={styles.colWithXs}>
              <Text style={styles.textbody}>{index + 1}</Text>
            </View>

            <View style={styles.colWith}>
              <Text style={[styles.textbody, { textAlign: "left" }]}>
                {item?.styleNo}
              </Text>
            </View>
            <View style={styles.colWithLong}>
              <Text style={[styles.textbody, { textAlign: "left" }]}>
                {item?.description}
              </Text>
            </View>
            <View style={styles.colWithSm}>
              <Text style={styles.textbody}>{item?.color}</Text>
            </View>

            <View style={[styles.colWithSm]}>
              <Text style={styles.textbody}>{item?.fabric?.slice(0, 6)}</Text>
            </View>
            <View style={styles.colWithSm}>
              <Text style={styles.textbody}>{item?.quantity}</Text>
            </View>
            <View style={styles.colWithMd}>
              <Text style={[styles.textbody, { textAlign: "left" }]}>
                $ {parseFloat(item?.price)?.toFixed(2)}
              </Text>
            </View>
            <View style={styles.colWithMd}>
              <Text style={[styles.textbody, { textAlign: "right" }]}>
                ${" "}
                {(
                  parseFloat(item?.price) * parseFloat(item?.quantity)
                )?.toFixed(2)}
              </Text>
            </View>
          </View>
        ))}
    </View>
  );
}
