import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Table from 'components/DataTable/Table';
import { setNotification } from 'features/Notification/notificationSlice';
import API from 'services/axios';
import authHeader from 'services/auth-header';
import PriceMatrixAdd from './priceMatrixAdd';
import { v4 as uid } from 'uuid';
import PriceMatrixUpdate from './priceMatrixUpdate';
import Spinner from 'components/Spinner';

export default function PriceMatrix() {
  const dispatch = useDispatch();
  /*   ALL STATES
   ********************************************* */
  const [isLoading, setIsLoading] = useState(false);

  const [filterBy, setFilterBy] = useState(null);
  // const [data, setData] = useState([
  //   {
  //     id: uid(),
  //     name: 'Quantity Price Matrix',
  //     discountLevelId: 'Retail Customer',
  //     type: 'By Quantity',
  //     matrixJson: JSON.stringify({
  //       additionalLocationDiscount: 0,
  //       tableHead: ['Quantity', 'Column', 'Column 2'],
  //       tableBody: [
  //         { Quantity: 10, Column: 1.1, 'Column 2': 1.2 },
  //         { Quantity: 20, Column: 2.1, 'Column 2': 2.2 },
  //         { Quantity: 30, Column: 3.1, 'Column 2': 3.2 },
  //         { Quantity: 40, Column: 4.1, 'Column 2': 4.2 },
  //         { Quantity: 50, Column: 5.1, 'Column 2': 5.2 },
  //       ],
  //     }),
  //   },
  //   {
  //     id: uid(),
  //     name: 'Area Price Matrix ',
  //     discountLevelId: 'Retail Customer',
  //     type: 'By Area',
  //     matrixJson: JSON.stringify({
  //       printingGaps: 0.25,
  //       tableHead: ['Quantity', '1 SqIn', '100 SqIn', '500 SqIn'],
  //       tableBody: [{ Quantity: 1, '1 SqIn': 0.05, '100 SqIn': 0.04, '500 SqIn': 0.03 }],
  //     }),
  //   },
  //   {
  //     id: uid(),
  //     name: 'Sheet Price Matrix ',
  //     discountLevelId: 'Retail Customer',
  //     type: 'By Sheet',
  //     matrixJson: JSON.stringify({
  //       width: 12,
  //       height: 18,
  //       printMargin: 0.25,
  //       tableHead: ['Quantity', '1 Sheet', '2 Sheet', '3 Sheet'],
  //       tableBody: [{ Quantity: 1, '1 Sheet': 10, '2 Sheet': 20, '3 Sheet': 30 }],
  //     }),
  //   },
  //   {
  //     id: uid(),
  //     name: 'Stitch Price Matrix',
  //     discountLevelId: 'Retail Customer',
  //     type: 'By Stitch',
  //     matrixJson: JSON.stringify({
  //       columnName: 'Stitch',
  //       additionalCostQuantity: 1000,
  //       additionalCostPrice: 1,
  //       tableHead: ['Quantity', '1000 Stitch', '2000 Stitch', '7000 Stitch', '10000 Stitch'],
  //       tableBody: [
  //         {
  //           Quantity: 1,
  //           '1000 Stitch': 1.1,
  //           '2000 Stitch': 2.1,
  //           '7000 Stitch': 3.1,
  //           '10000 Stitch': 4.1,
  //         },
  //         {
  //           Quantity: 10,
  //           '1000 Stitch': 1.2,
  //           '2000 Stitch': 2.2,
  //           '7000 Stitch': 3.2,
  //           '10000 Stitch': 4.2,
  //         },
  //         {
  //           Quantity: 20,
  //           '1000 Stitch': 1.3,
  //           '2000 Stitch': 2.3,
  //           '7000 Stitch': 3.3,
  //           '10000 Stitch': 4.3,
  //         },
  //       ],
  //     }),
  //   },
  // ]);
  const [priceMatrixData, setPriceMatrixData] = useState(false);
  const [discountLevel, setDiscountLevel] = useState(undefined);
  const [lastSortId, setLastSortId] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteID, setDeleteID] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [updateData, setUpdateData] = useState(null);

  /*   All Functions
   ********************************************* */
  //get Data
  const getAllPriceMatrixes = async () => {
    try {
      setIsLoading(true);
      const { data } = await API.get('/pricematrix', { headers: authHeader() });
      setPriceMatrixData(data);
      setLastSortId(data[data.length - 1]?.sortId ? data[data.length - 1]?.sortId : 0);
      setIsLoading(false);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: 'error',
        })
      );
      setIsLoading(false);
    }
  };
  const getAllDiscounts = async () => {
    try {
      const { data } = await API.get('/discountlevels', { headers: authHeader() });
      setDiscountLevel(data);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: 'error',
        })
      );
    }
  };

  // Delete Quotations
  const deleteMatrix = async (id) => {
    try {
      const { data } = await API.delete('/priceMatrix/delete/' + id, { headers: authHeader() });
      if (data?.message?.type === 'success') {
        getAllPriceMatrixes();
        setShowDeleteModal(false);
        return;
      }
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: 'error',
        })
      );
    }
  };

  // Sorting Up
  const sortUp = async (index) => {
    const currentSortId = priceMatrixData[index].sortId;
    const currentId = priceMatrixData[index].id;
    const upSortId = priceMatrixData[index - 1].sortId;
    const upId = priceMatrixData[index - 1].id;
    try {
      const { data } = await API.post(
        '/priceMatrix/sortup',
        { currentSortId, currentId, upSortId, upId },
        { headers: authHeader() }
      );
      if (data.success) {
        getAllPriceMatrixes();
      }
    } catch (err) {
      alert(err.message);
    }
  };

  // Sorting Up
  const sortDown = async (index) => {
    const currentSortId = priceMatrixData[index].sortId;
    const currentId = priceMatrixData[index].id;
    const downSortId = priceMatrixData[index + 1].sortId;
    const downId = priceMatrixData[index + 1].id;
    try {
      const { data } = await API.post(
        '/priceMatrix/sortDown',
        { currentSortId, currentId, downSortId, downId },
        { headers: authHeader() }
      );
      if (data.success) {
        getAllPriceMatrixes();
      }
    } catch (err) {
      alert(err.message);
    }
  };

  const getDiscountName = (id) => {
    let name = 'not found';
    discountLevel?.map((item) => {
      if (item.id === parseInt(id)) name = item.discountName;
    });
    return name;
  };

  useEffect(() => {
    getAllPriceMatrixes();
    getAllDiscounts();
  }, []);

  /*   Table functions
   ********************************************* */
  const btn1 = () => (
    <div>
      <>
        <button
          onClick={() => setShowAddModal(true)}
          type='button'
          className='btn btn-primary  ms-1'
        >
          Add Price Matrix
        </button>
      </>
    </div>
  );

  const buttons = () => (
    <div className=' d-flex justify-content-end '>
      {/* FilterBy */}
      <button
        type='button'
        className='px-1 bg-primary btn text-dark bg-opacity-10 border border-primary rounded-end mx-2 rounded-start'
        data-bs-toggle='dropdown'
        aria-haspopup='true'
        aria-expanded='false'
      >
        {filterBy ? filterBy.toUpperCase().replaceAll('_', ' ') : 'Filter by All'}
      </button>
      <div className='dropdown-menu'>
        <a onClick={() => setFilterBy(null)} className='dropdown-item' role={'button'}>
          Filter by All
        </a>
        <a onClick={() => setFilterBy('company_name')} className='dropdown-item' role={'button'}>
          Company Name
        </a>
        <a
          onClick={() => setFilterBy('qxternal_Quotations_id')}
          className='dropdown-item'
          role={'button'}
        >
          External ID
        </a>
        <a onClick={() => setFilterBy('website')} className='dropdown-item' role={'button'}>
          Website
        </a>
        <a onClick={() => setFilterBy('transit_days')} className='dropdown-item' role={'button'}>
          Transit Days
        </a>
        <a
          onClick={() => setFilterBy('is_tax_exemption')}
          className='dropdown-item'
          role={'button'}
        >
          Tax Exampted
        </a>
        <a onClick={() => setFilterBy('quotations_type')} className='dropdown-item' role={'button'}>
          Customer Type
        </a>
      </div>
    </div>
  );
  const table_head = ['Name', 'Type', 'Discount Level', 'Action'];

  const table_body = (item, index) => (
    <tr className='' key={item.id}>
      <td className='align-middle py-0'>{item.name}</td>
      <td className='align-middle py-0'>{item.type}</td>
      <td className='align-middle py-0'>{getDiscountName(item.discountLevelId)}</td>
      <td className='align-middle table-action py-0'>
        <Link
          onClick={() => {
            item.sortId != 1 && sortUp(index);
          }}
          className='action-icon'
        >
          <i className='mdi mdi-chevron-double-up'></i>
        </Link>
        <Link
          onClick={() => {
            item.sortId != priceMatrixData.length && sortDown(index);
          }}
          className='action-icon'
        >
          <i className='mdi mdi-chevron-double-down'></i>
        </Link>
        <Link className='action-icon'>
          <i
            onClick={() => {
              setShowUpdateModal(true);
              setUpdateData(item);
            }}
            className='mdi mdi-pencil'
          ></i>
        </Link>

        <Link
          onClick={() => {
            setShowDeleteModal(true);
            setDeleteID(item.id);
          }}
          className='action-icon'
        >
          <i className='mdi mdi-delete'></i>
        </Link>
      </td>
    </tr>
  );
  return (
    <div className=''>
      <h3 className='my-3 mt-2'>Price Matrix</h3>
      {isLoading && <Spinner />}
      {/*   Table
       ********************************************* */}
      <div className='px-2 flex-grow-1 d-flex' style={{ minHeight: '50vh', borderRadius: 15 }}>
        <div className='horizontal-scrollable d-flex flex-grow-1'>
          <Table
            filterBy={filterBy}
            buttons={buttons}
            btn1={btn1}
            table_head={table_head}
            table_body={table_body}
            table_data={priceMatrixData}
          />
        </div>
      </div>
      {/*   ADD Modal
       ********************************************* */}
      <Modal
        size='xl'
        show={showAddModal}
        onHide={() => {
          setShowAddModal(false);
        }}
      >
        <Modal.Body className='shadow-lg bg-white px-3 ' style={{ borderRadius: 15 }}>
          <PriceMatrixAdd
            refreshFunc={getAllPriceMatrixes}
            setShowAddModal={setShowAddModal}
            discountLevel={discountLevel}
            lastSortId={lastSortId}
          />
        </Modal.Body>
      </Modal>

      {/*   Update Modal
       ********************************************* */}
      <Modal
        size='xl'
        show={showUpdateModal}
        onHide={() => {
          setShowUpdateModal(false);
        }}
      >
        <Modal.Body className='shadow-lg bg-white px-3 ' style={{ borderRadius: 15 }}>
          {updateData && (
            <PriceMatrixUpdate
              refreshFunc={getAllPriceMatrixes}
              matrixData={updateData}
              setShowUpdateModal={setShowUpdateModal}
              discountLevel={discountLevel}
            />
          )}
        </Modal.Body>
      </Modal>

      {/*   Delete Modal
       ********************************************* */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Body className='bg-danger'>
          <div className=''>
            <div className='text-center text-white'>
              <i className='ri-close-circle-line h1'></i>
              <h4 className='mt-2'>Confirm Delete!</h4>
              <p className='mt-3'>Do You want to delete ?</p>
              <button
                type='button'
                onClick={() => setShowDeleteModal(false)}
                className='btn btn-light my-2 mx-2'
              >
                Cancel
              </button>
              <button
                type='button'
                className='btn btn-outline-light my-2 '
                data-bs-dismiss='modal'
                onClick={() => {
                  deleteMatrix(deleteID);
                }}
              >
                DELETE
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
