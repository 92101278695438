import { BlobProvider } from "@react-pdf/renderer";
import React, { useState } from "react";
import { QuotePdf } from "./QuotePdf";
import API from "services/axios";
import authHeader from "services/auth-header";
import { Modal } from "react-bootstrap";

export default function QuotePdfButton({ btnClasses, selectedQuote }) {
  /*   All States
   ********************************************* */
  const [showPdf, setShowPdf] = useState(false);
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [locationLogo, setLocationLogo] = useState(null);
  const [pdfOptions, setPdfOptions] = useState({ show_Total: true });

  /*   All Functions
   ********************************************* */
  const getLocationLogo = async () => {
    const { data } = await API.post(
      "/design-request/get-image",
      { path: selectedQuote?.location?.pdfLogo },
      {
        headers: authHeader(),
      }
    );
    setShowPdf(true);
    setLocationLogo(data);
    return data;
  };

  /*   All UseEffects
   ********************************************* */
  return (
    <>
      <button
        className={btnClasses}
        onClick={() => {
          setShowPdfModal(true);
        }}
      >
        Get Pdf
      </button>

      {/*   Delete Modal
       ********************************************* */}
      <Modal
        show={showPdfModal}
        onHide={() => setShowPdfModal(false)}
        contentClassName="bg-transparent"
      >
        <Modal.Body className="custom-border-radius-sm bg-white">
          <h3>Select Pdf Options</h3>
          <div className="my-3">
            {Object.keys(pdfOptions)?.map((option) => (
              <div key={option} className="d-flex gap-2">
                <input
                  onChange={(e) => {
                    setPdfOptions((prev) => {
                      return { ...prev, [option]: e.target.checked };
                    });
                  }}
                  type="checkbox"
                  checked={pdfOptions[option]}
                  id={"invoicePage"}
                  data-switch="success"
                />
                <label
                  htmlFor={"invoicePage"}
                  data-on-label="Yes"
                  data-off-label="No"
                ></label>
                <div className="ms-2 fw-bold">
                  {option?.replaceAll("_", " ")?.toUpperCase()}
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-end gap-1 mt-3">
            <button
              onClick={() => {
                setShowPdfModal(false);
              }}
              className="btn btn-secondary"
            >
              Close
            </button>
            {showPdf && locationLogo ? (
              <>
                <BlobProvider
                  document={
                    <QuotePdf
                      selectedQuote={selectedQuote}
                      locationLogo={locationLogo}
                      showTotal={pdfOptions.show_Total}
                    />
                  }
                >
                  {({ url, blob }) => {
                    return (
                      <>
                        <button
                          disabled={!selectedQuote}
                          className={btnClasses}
                        >
                          <a
                            className="text-decoration-none text-white"
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="mdi mdi-download"></i>Download Pdf
                            {!url && (
                              <div
                                className="spinner-border spinner-border-sm text-white ms-2 mb-1"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            )}
                          </a>
                        </button>
                      </>
                    );
                  }}
                </BlobProvider>
              </>
            ) : (
              <>
                <div>
                  {selectedQuote?.quoteProducts?.length === 0 ? (
                    <button className={`${btnClasses} btn-danger`}>
                      No Products
                    </button>
                  ) : !selectedQuote?.location?.pdfLogo ? (
                    <button className={`${btnClasses} btn-danger`}>
                      Please Select Work Order location First
                    </button>
                  ) : (
                    <button className={btnClasses} onClick={getLocationLogo}>
                      Generate PDF
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
