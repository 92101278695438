import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import ImageWIthSignedUrl from "pages/DesignRequests/DesignRequest/ImageWIthSignedUrl";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function AttUserDetail({
  formData,
  setFormData,
  handleChange,
  refreshFunc,
}) {
  const dispatch = useDispatch();

  /*   All States
   ********************************************* */
  let inputFields = [
    { name: "User Name", value: "name", type: "text" },
    { name: "Card NO#", value: "cardno", type: "text" },
    { name: "Password", value: "password", type: "text" },
    { name: "Badge No", value: "badgeNo", type: "text" },
  ];

  const [previewImage, setPreviewImage] = useState(null);

  /*   All Functions
   ********************************************* */

  const [loading, setLoading] = useState(false);

  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  const deleteProfilePic = async () => {
    try {
      loadingOn();
      const { data } = await API.post(
        "/device-user-pic-delete/" + formData?.id,
        { folder: formData?.picture },
        {
          headers: authHeader(),
        }
      );
      if (data?.success) {
        setFormData((prev) => ({ ...prev, picture: null }));
        await refreshFunc();
        dispatch(
          setNotification({
            message: data?.message,
            type: "success",
          })
        );
        setPreviewImage(null);
      }

      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const getBase64Image = async (e) => {
    const file = e.target.files[0];
    setFormData((prev) => {
      return { ...prev, file };
    });
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewImage((prev) => reader?.result);
    };
  };

  /*   All UseEffects
   ********************************************* */

  return (
    <div className="d-flex gap-3 align-items-start">
      {loading && <Spinner />}
      <div className="w-50">
        {inputFields.map((field, index) => (
          <div key={field.value} className="mb-2">
            <label>{field.name}</label>
            <input
              name={field.value}
              type={field.type}
              placeholder={`Enter ${field.name}`}
              value={formData[field.value] || ""}
              onChange={handleChange}
              className="form-control"
            />
          </div>
        ))}
      </div>
      <div className="w-50 ">
        <label className="">User Photo</label>
        {formData?.picture ? (
          <ImageWIthSignedUrl
            path={formData?.picture}
            width={200}
            height={200}
            alt={"profile photo"}
          />
        ) : (
          <img
            src={
              previewImage ? previewImage : "https://via.placeholder.com/200"
            }
            width={200}
            height={200}
            alt=""
          />
        )}
        {(formData?.picture || previewImage) && (
          <button
            type="button"
            className="btn btn-sm w-100 btn-danger mt-2"
            onClick={(e) => {
              if (!formData?.picture) {
                setPreviewImage(null);
                return;
              }
              deleteProfilePic();
            }}
          >
            Remove Photo
          </button>
        )}
        <input
          type="file"
          name="file"
          className="form-control mt-2"
          onChange={getBase64Image}
        />
      </div>
    </div>
  );
}
