import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

import { BreadCrumbProvider } from "features/Contexts/BreadCrumbs";
import { CompanyLogoProvider } from "features/Contexts/CompanyLogoContext";
import NavBar from "../components/NavBar";
import { WorkOrderProvider } from "features/Contexts/WorkOrderContext";

const ProtectedRoute = () => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.user);

  useEffect(() => {
    if (!userInfo) navigate("/brick-cloud/client-login");
  }, [navigate, userInfo]);

  // show unauthorized screen if no user is found in redux store

  return (
    <div className="wrapper">
      <CompanyLogoProvider>
        <WorkOrderProvider>
          <BreadCrumbProvider>
            {/* <TopBar /> */}
            <NavBar />

            <div
              className=""
              style={{ minHeight: "100vh", backgroundColor: "#f0f4f9" }}
            >
              <div className="content">
                <div className="">
                  <Outlet />
                </div>
              </div>
            </div>
          </BreadCrumbProvider>
        </WorkOrderProvider>
      </CompanyLogoProvider>
    </div>
  );
};

export default ProtectedRoute;
