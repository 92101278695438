import React, { useEffect, useState } from "react";
import "./DisplayBoard.css";
import Modal from "react-bootstrap/Modal";
import Spinner from "components/Spinner";
import WOProductQuickView from "./WOProductQuickView";
import JobBoardFullViewData from "./JobBoardFullViewData";
import authHeader from "services/auth-header";
import API from "services/axios";
import { setNotification } from "features/Notification/notificationSlice";
import { useDispatch } from "react-redux";

export default function PendingJobs({
  filterBy,
  productType,
  filterText,
  refreshStatusList,
  setRefreshStatusList,
  productTypesData,
}) {
  /*   All States
   ********************************************* */
  const [showProductsModal, setShowProductsModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [filteredSearchedData, setFilteredSearchedData] = useState(null);

  const [openFullView, setOpenFullView] = useState(false);

  const [WOData, setWOData] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const [loading, setLoading] = useState(false);

  /*   All Functions
   ********************************************* */
  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  const dispatch = useDispatch();

  const getWOByWOStatus = async () => {
    try {
      loadingOn();
      const { data } = await API.post(
        "/job-board/WO-status",
        { WOStatus: filterBy, productType: productType ? productType : null },
        { headers: authHeader() }
      );
      if (data.type === "success") {
        setWOData(data.result);
        setRefresh(false);
        setRefreshStatusList((prev) => {
          prev[filterBy] = false;
          return prev;
        });
        loadingOff();
        return;
      }
      if (data.type === "error") {
        dispatch(
          setNotification({
            message: data.message,
            type: "error",
          })
        );
        setRefresh(false);
        loadingOff();
        return;
      }
      loadingOff();
      console.log(data);
    } catch (err) {
      console.log(err.message);
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      loadingOff();
      setRefresh(false);
    }
  };

  /*   All UseEffects
   ********************************************* */

  useEffect(() => {
    if (filterText) {
      let filteredData = WOData?.filter((item) => {
        return (
          item?.id.toString().includes(filterText) ||
          item?.jobName.toLowerCase().includes(filterText.toLowerCase()) ||
          item?.customerName.toLowerCase().includes(filterText.toLowerCase())
        );
      });
      setFilteredSearchedData(filteredData);
    } else {
      setFilteredSearchedData(null);
    }
  }, [filterText]);

  useEffect(() => {
    getWOByWOStatus();
  }, [filterBy]);

  useEffect(() => {
    if (refresh) {
      getWOByWOStatus();
    }
  }, [refresh]);

  useEffect(() => {
    if (refreshStatusList[filterBy]) {
      getWOByWOStatus();
    }
  }, [refreshStatusList[filterBy]]);

  return (
    <div
      className="bg-white flex-grow-1 overflow-hidden pb-3 custom-shadow-sm custom-border-radius "
      style={{ width: "45%", height: "44%" }}
    >
      {loading && <Spinner />}
      {!WOData && <Spinner />}
      {/* ******************** FilterBy Header ************************* */}
      <div className="topHeader bg-primary text-white custom-border-radius">
        <div>{productType ? productType : filterBy}</div>
        <div>
          <button
            onClick={() => {
              setWOData(null);
              setRefresh(true);
            }}
            className="btn btn-sm btn-info"
          >
            Refresh
          </button>
          <button
            onClick={() => {
              setOpenFullView(true);
            }}
            className="btn btn-sm btn-success mx-2"
          >
            Open
          </button>
          {filterText ? filteredSearchedData?.length : WOData?.length}
        </div>
      </div>
      <div className="bodyHeader row">
        <div className="col-1">ID</div>
        <div className="col-5">Job Name</div>
        <div className="col-4">Customer Name</div>
        <div className="col-2">Quantity</div>
      </div>
      <div className="bodyContent overflow-auto ">
        {filteredSearchedData
          ? filteredSearchedData?.map((item, i) => (
              <div
                onDoubleClick={() => {
                  setShowProductsModal(true);
                  setSelectedItem(item);
                }}
                key={item.id}
                className="row item"
              >
                <div className="col-1">{item?.id}</div>
                <div className=" col-5 text-nowrap">
                  {item?.jobName?.slice(0, 30)}
                  {item?.jobName?.length >= 30 ? "..." : ""}
                </div>
                <div className=" col-4">{item?.customerName}</div>
                <div className="col-2 text-end">{item?.totalQuantity}</div>
              </div>
            ))
          : WOData?.map((item, i) => (
              <div
                onDoubleClick={() => {
                  setShowProductsModal(true);
                  setSelectedItem(item);
                }}
                key={item.id}
                className="row item"
              >
                <div className="col-1">{item?.id}</div>
                <div className=" col-5 text-nowrap">
                  {item?.jobName?.slice(0, 30)}
                  {item?.jobName?.length >= 30 ? "..." : ""}
                </div>
                <div className=" col-4">{item?.customerName}</div>
                <div className="col-2 text-end">{item?.totalQuantity}</div>
              </div>
            ))}
      </div>
      {/*   View ALL Modal
       ********************************************* */}
      <Modal
        size="xl"
        show={openFullView}
        onHide={() => setOpenFullView(false)}
      >
        <Modal.Body className="scrollbar-hide ">
          <div>
            <JobBoardFullViewData
              filterBy={filterBy}
              WOData={filterText ? filteredSearchedData : WOData}
            />
          </div>
          <div className="text-end">
            <button
              className="btn btn-secondary"
              onClick={() => {
                setOpenFullView(false);
              }}
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {/*   Quick View Modal
       ********************************************* */}
      <Modal
        size="xl"
        show={showProductsModal}
        onHide={() => setShowProductsModal(false)}
      >
        <Modal.Body>
          {selectedItem && (
            <WOProductQuickView
              filterBy={filterBy}
              selectedWOID={selectedItem?.id}
              allWOData={filterText ? filteredSearchedData : WOData}
              refreshFunc={getWOByWOStatus}
              setShowProductsModal={setShowProductsModal}
              productTypesData={productTypesData}
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}
