import { Text, View } from "@react-pdf/renderer";
import React from "react";

export default function QuotePdfInfo({ styles, selectedQuote }) {
  /*   All States
   ********************************************* */

  /*   All Functions
   ********************************************* */

  /*   All UseEffects
   ********************************************* */
  return (
    <View style={{ marginVertical: 5 }}>
      <View style={styles.shippingDescription}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <View style={{ flexGrow: 1 }}>
            <Text style={styles.headingSecond}>Ordered By:</Text>
            <View
              style={{
                border: 1,
                borderRight: 0,
                paddingVertical: 2,
                paddingHorizontal: 5,
              }}
            >
              <Text style={styles.textbody}>
                {selectedQuote?.contactFirstName}{" "}
                {selectedQuote?.contactLastName}
              </Text>
            </View>
          </View>
          <View style={{ flexGrow: 1 }}>
            <Text style={styles.headingSecond}>Phone:</Text>
            <View
              style={{
                border: 1,
                borderRight: 0,
                paddingVertical: 2,
                paddingHorizontal: 5,
              }}
            >
              <Text style={styles.textbody}>
                {selectedQuote?.contactPhone
                  ? selectedQuote?.contactPhone
                  : " "}
              </Text>
            </View>
          </View>
          <View style={{ flexGrow: 2 }}>
            <Text style={styles.headingSecond}>Email:</Text>
            <View
              style={{
                border: 1,
                paddingVertical: 2,
                paddingHorizontal: 5,
              }}
            >
              <Text style={styles.textbody}>
                {selectedQuote?.contactEmail
                  ? selectedQuote?.contactEmail
                  : " "}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View style={{ ...styles.shippingDescription, marginTop: 5 }}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Text
            style={{
              ...styles.headingSecond,
              textAlign: "left",
            }}
          >
            Job Name:{" "}
            <Text style={{ textDecoration: "underline" }}>
              {selectedQuote?.jobName}
            </Text>
          </Text>
        </View>
      </View>
      <View style={{ ...styles.shippingDescription }}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Text
            style={{
              ...styles.headingSecond,
              textAlign: "left",
            }}
          >
            Customer Name:
            <Text style={{ textDecoration: "underline" }}>
              {selectedQuote?.customer?.customerName}
            </Text>
          </Text>
        </View>
      </View>
    </View>
  );
}
