import { useState } from "react";
import { Modal } from "react-bootstrap";
import UpdateAttUser from "./UpdateAttUser";
import ImgWithSignedUrl from "components/SignedUrl/ImgWithSignedUrl";

export default function AttUserInfo({ userData, refreshFunc }) {
  /*   All States
   ********************************************* */
  const [showUserEditModal, setShowUserEditModal] = useState(null);

  /*   All Functions
   ********************************************* */

  /*   All UseEffects
   ********************************************* */

  return (
    <div>
      <div className="bg-white custom-shadow-sm px-3 py-2 custom-border-radius-sm mb-3 mt-2">
        <div className="d-flex  align-items-start gap-5">
          <div>
            <div className="d-flex gap-3 align-items-start">
              <h3 className="border-bottom">User Info</h3>
              <button
                onClick={(e) => {
                  setShowUserEditModal(true);
                }}
                className="btn btn-info btn-sm px-1 py-0"
              >
                <i className="mdi fs-5 mdi-pencil"></i>
              </button>
            </div>
            <div className="d-flex align-items-start gap-3">
              <div className="fw-extrabold h4">
                <div className="mb-2">Id</div>
                <div className="mb-2">Name</div>
                <div className="mb-2">Badge No </div>
                {/* <div className="mb-2">Relaxation Time </div> */}
                <div className="mb-2">Card Number</div>
                <div className="mb-2">Role</div>
                <div className="mb-2">Is Night Office?</div>
              </div>
              <div className="h4">
                <div className="mb-2">{userData?.userId}</div>
                <div className="mb-2">{userData?.name || "-"}</div>
                <div className="mb-2">{userData?.badgeNo || "-"}</div>

                {/* <div className="mb-2">{userData?.relaxationMinutes || "-"}</div> */}
                <div className="mb-2">{userData?.cardno || "-"}</div>
                <div className="mb-2">
                  {userData?.role === "14" ? "Admin" : "User"}
                </div>
                <div className="mb-2">
                  {userData?.isNightOffice ? "True" : "False"}
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <h3 className="border-bottom mb-3">Profile Picture </h3>
            {userData?.picture ? (
              <ImgWithSignedUrl path={userData?.picture} width={200} />
            ) : (
              <img
                src="https://via.placeholder.com/200"
                className="img-fluid"
                alt=""
              />
            )}
          </div>
        </div>
      </div>
      <Modal
        show={showUserEditModal}
        onHide={() => setShowUserEditModal(false)}
        contentClassName="bg-transparent"
      >
        <Modal.Body className="bg-white custom-border-radius p-3 py-2 pb-3">
          <UpdateAttUser
            showModal={setShowUserEditModal}
            userData={userData}
            refreshFunc={refreshFunc}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
