import Spinner from "components/Spinner";
import React, { useState } from "react";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function DesRUpdateProduct({
  setDesignRequests,
  selectedDesign,
  setSelectedDesign,
  showModal,
  selectedProduct,
}) {
  /*   All States
   ********************************************* */

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(false);

  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  /*   All Functions
   ********************************************* */
  // Update Product => Updates product title
  const updateProduct = async (e) => {
    e.preventDefault();
    let product = { ...selectedProduct, ...formData };
    try {
      loadingOn();
      let updatedSelectedDesign = null;
      setDesignRequests((prev) => {
        return prev.map((item) => {
          if (item.id === selectedDesign.id) {
            let updatedProducts = item.products.map((prod) => {
              if (prod.id === product.id) {
                return product;
              } else {
                return prod;
              }
            });

            let newItem = { ...item, products: updatedProducts };
            updatedSelectedDesign = newItem;
            setSelectedDesign((prev) => newItem);
            return newItem;
          } else {
            return item;
          }
        });
      });
      const { data } = await API.patch(
        "/design-request/update",
        {
          formData: {
            ...updatedSelectedDesign,
            products: JSON.stringify(updatedSelectedDesign?.products),
          },
        },
        { headers: authHeader() }
      );
      if (data?.success) {
        showModal(false);
      }
      loadingOff();
    } catch (err) {
      loadingOff();
    }
  };

  /*   All UseEffects
   ********************************************* */
  return (
    <div>
      <form onSubmit={updateProduct}>
        <div className="mb-3">
          <label htmlFor="title" className="form-label">
            Title
          </label>
          <input
            type="text"
            className="form-control"
            id="title"
            defaultValue={selectedProduct?.title}
            onChange={(e) => {
              setFormData((prev) => {
                return { ...prev, title: e.target.value };
              });
            }}
          />
        </div>
        {loading && <Spinner />}

        <div className="d-flex justify-content-end">
          <button
            onClick={() => {
              showModal(false);
            }}
            className="btn btn-secondary btn-sm"
            type="button"
          >
            Close
          </button>
          <button className="btn btn-primary btn-sm ms-1" type="submit">
            Update
          </button>
        </div>
      </form>
    </div>
  );
}
