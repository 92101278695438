import Spinner from "components/Spinner";
import Toast from "components/Toast/ToastComponent";
import { setNotification } from "features/Notification/notificationSlice";
import React, { useEffect, useState } from "react";

import ReactQuill from "react-quill";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function WONotes({ selectedWorkOrder, refreshFunc }) {
  const dispatch = useDispatch();

  /*   All States
   ********************************************* */
  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["clean"],
    ],
  };

  const [notes, setNotes] = useState(null);

  const [loading, setLoading] = useState(false);

  /*   All Functions
   ********************************************* */
  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  const saveNotes = async () => {
    try {
      if (!notes) {
        return;
      }
      loadingOn();
      let apiData = { id: selectedWorkOrder?.id };

      if (notes) {
        apiData = { ...apiData, data: { notes } };
      }
      const { data } = await API.patch("/work-orders", apiData, {
        headers: authHeader(),
      });
      if (data?.message?.type === "success") {
        await refreshFunc();
        loadingOff();
        return;
      }
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    setNotes(selectedWorkOrder?.notes);
  }, [selectedWorkOrder]);
  return (
    <div
      className="bg-white shadow-lg w-50 px-3 pb-2 custom-border-radius-sm"
      style={{ height: "300px" }}
    >
      {loading && <Spinner />}
      <div className="d-flex inline-block justify-content-between align-items-center">
        <h4 className="mb-0">WO Notes</h4>
        <h4 className="mb-0">
          <button
            type="button"
            className="btn btn-success
             btn-sm"
            onClick={() => saveNotes()}
          >
            Save
          </button>
        </h4>
      </div>
      <hr className="my-0 mb-2 w-50" />
      <div className="" style={{ height: "230px", overflow: "auto" }}>
        <ReactQuill
          modules={modules}
          theme="snow"
          value={notes}
          onChange={setNotes}
        />
      </div>
    </div>
  );
}
