import API from '../../services/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import authHeader from '../../services/auth-header';

export const getSettings = createAsyncThunk('settings', async (args, { rejectWithValue }) => {
  try {
    // configure header's Content-Type as JSON

    const { data } = await API.get(
      `/user/info`,

      { headers: authHeader() }
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
export const settingsUpdate = createAsyncThunk(
  'settings/update',
  async (formData, { rejectWithValue }) => {
    try {
      const { data } = await API.post(`/user/update`, formData, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
