import Spinner from "components/Spinner";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import authHeader from "services/auth-header";
import API from "services/axios";
import { GetNameWithTimeStamp } from "utils/UtilityFuncs";
import GeneralDetails from "./generalDetails";

function WOProductUpdate({
  wOProductsData,
  selectedWorkOrder,
  setShowUpdateModal,
  refreshFunc,
  setUpdateId,
  isViewOnly,
}) {
  /*   All States Below
   ********************************************* */
  const [active, setActive] = useState("general");
  const [searchParams] = useSearchParams();

  const [selectedProduct, setSelectedProduct] = useState(null);

  const [generalData, setGeneralData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isReadyState, setIsReadyState] = useState(false);

  /*   All Functions Below
   ********************************************* */

  const handleSubmit = async (e, updateData) => {
    if (e) {
      e.preventDefault();
    }

    setIsLoading(true);
    if (updateData?.productType === "") {
      alert("Please select product type");
      return;
    }
    try {
      let formData = new FormData();
      let bodyData = { ...updateData, id: selectedProduct?.id };

      //Appending File if present.
      if (updateData?.file) {
        let file = updateData?.file;
        formData.append("file", file);
        let folder = `kms/wo/${selectedWorkOrder?.id}/products`;
        formData.append("folder", folder);
        // Creating filePath of upload folder to save it to database
        let fileName = GetNameWithTimeStamp(file.name);
        let productImage = `${folder}/${fileName}`;
        bodyData.productImage = productImage;
        formData.append("fileName", fileName);
      }

      // Assigning update data to formData in JSON string
      formData.append("data", JSON.stringify(bodyData));

      const { data } = await API.patch(`/wo-products`, formData, {
        headers: { ...authHeader(), "Content-Type": "multipart/form-data" },
      });
      if (data.message.type === "success") {
        await refreshFunc();
        setIsLoading(false);
        return;
      }
    } catch (err) {
      setIsLoading(true);
      if (err?.response?.status === 413) {
        alert("Size Too Large");
        setIsLoading(false);
        return;
      }
      alert(err.message);
    }
  };

  const updateReadyStatus = async (value) => {
    try {
      const { data } = await API.patch(
        `/wo-products`,
        { data: { id: selectedProduct?.id, isReady: value } },
        { headers: authHeader() }
      );
      if (data.message.type === "success") {
        await refreshFunc();
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(true);
      if (err.response.status === 413) {
        alert("Size Too Large");
        setIsLoading(false);
        return;
      }
      alert(err.message);
    }
  };

  const checkActive = (val) => {
    setActive(val);
  };

  const addSortId = (sizes) => {
    const parsedSizes = JSON.parse(sizes);
    if (parsedSizes?.length && parsedSizes[0]["sortId"]) {
      return sizes;
    } else {
      let updatedSizes = parsedSizes?.map((item, i) => {
        item["sortId"] = i + 1;
        return item;
      });
      return JSON.stringify(updatedSizes);
    }
  };

  const updateGeneralData = async (selectedProduct) => {
    setGeneralData(null);
    let data = {
      productType: selectedProduct?.productType,
      productStyle: selectedProduct?.productStyle,
      productDescription: selectedProduct?.productDescription,
      productFabric: selectedProduct?.productFabric,
      productColor: selectedProduct?.productColor,
      totalQuantity: selectedProduct?.totalQuantity,
      totalPrice: selectedProduct?.totalPrice,
      notes: selectedProduct?.notes,
      hasNumbers: selectedProduct?.hasNumbers,
      hasName: selectedProduct?.hasName,
      teamName: selectedProduct?.teamName,
      productSizes: await addSortId(selectedProduct?.productSizes),
      productImage: selectedProduct?.productImage,
    };

    setTimeout(() => {
      setGeneralData((prev) => data);
    }, 50);
  };

  const prevProduct = () => {
    let currentIndex = wOProductsData.indexOf(selectedProduct);
    if (currentIndex === 0) {
      return;
    } else {
      // This functions updates the productId in params and
      // then updates selected product according to id
      setUpdateId(wOProductsData[currentIndex - 1]?.id);
    }
  };
  const nextProduct = () => {
    let currentIndex = wOProductsData.indexOf(selectedProduct);
    if (currentIndex === wOProductsData.length - 1) {
      return;
    } else {
      // This functions updates the productId in params and
      // then updates selected product according to id
      setUpdateId(wOProductsData[currentIndex + 1]?.id);
    }
  };

  /*   Arrow Functions
   ********************************************* */

  useEffect(() => {
    // Set Selected Product according to productId in url
    if (searchParams) {
      let productId = parseInt(searchParams.get("productId"));
      if (productId) {
        let selected = wOProductsData?.find((item) => item.id === productId);
        setSelectedProduct(selected);
      }
    }
  }, [searchParams, wOProductsData]);

  useEffect(() => {
    if (selectedProduct) {
      setIsReadyState(selectedProduct?.isReady);
      updateGeneralData(selectedProduct);
    }
  }, [selectedProduct]);

  return (
    <div className="row">
      <div className="col-12">
        {/*    ADD FORM START
         ********************************************* */}
        <div className="mb-13 ms-1 d-flex justify-content-between ">
          <h3>Update WO Product</h3>
          <div className="d-flex align-items-center">
            <div className="row">
              <label className="col-5">Ready?</label>
              <div className="col-7 ">
                <input
                  onChange={(e) => {
                    if (!isViewOnly) {
                      setIsReadyState(e.target.checked);
                      updateReadyStatus(e.target.checked);
                    }
                  }}
                  readOnly={isViewOnly}
                  checked={isReadyState}
                  type="checkbox"
                  id="switch1"
                  data-switch="success"
                />
                <label
                  htmlFor="switch1"
                  data-on-label="Yes"
                  data-off-label="No"
                ></label>
              </div>
            </div>
            {active === "general" && !isViewOnly && (
              <button
                onClick={(e) => {
                  handleSubmit(e, generalData);
                }}
                type="submit"
                className="btn btn-success mx-1"
              >
                Save
              </button>
            )}
            <button
              type="button"
              className="btn btn-danger "
              onClick={() => {
                setShowUpdateModal(false);
              }}
            >
              Back
            </button>
            <button
              onClick={() => {
                prevProduct();
              }}
              className={"btn btn-success btn-sm p-0 px-1 mx-1"}
            >
              <i className="mdi mdi-arrow-left-bold fs-3 py-0 my-0"></i>
            </button>
            <button
              onClick={() => {
                nextProduct();
              }}
              className={"btn btn-success btn-sm p-0 px-1"}
            >
              <i className="mdi mdi-arrow-right-bold fs-3 py-0 my-0"></i>
            </button>
          </div>
        </div>
        <div className="flex-grow-1">
          <ul className="nav nav-tabs nav-bordered mb-3 d-flex flex-grow-1">
            <li
              onClick={() => {
                checkActive("general");
              }}
              className="nav-item"
            >
              <button
                className={
                  active === "general"
                    ? `nav-link rounded-0 active bg-primary text-white`
                    : "nav-link rounded-0"
                }
              >
                <span className="d-none d-md-block">General</span>
              </button>
            </li>
          </ul>
          <form>
            {isLoading && <Spinner />}
            <div className="">
              {active === "general" && !isLoading && generalData && (
                <div className="tab-pane show active">
                  <GeneralDetails
                    generalData={generalData}
                    setGeneralData={setGeneralData}
                    updateFunc={handleSubmit}
                    isViewOnly={isViewOnly}
                  />
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default WOProductUpdate;
