import { Image, Page, Text, View } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import logo from "../../../assets/images/logo.png";

export default function InvoicePage({
  styles,
  productsList,
  selectedWO,
  locationLogo,
}) {
  /*   All States
   ********************************************* */
  const [invoiceProductList, setInvoiceProductList] = useState([]);
  const [totalQty, setTotalQty] = useState(0);
  const [priceTotal, setPriceTotal] = useState(0);
  const [salesTax, setSalesTax] = useState(0);

  const customerInfo =
    typeof selectedWO?.contactData === "string"
      ? JSON.parse(selectedWO?.contactData)
      : selectedWO?.contactData;
  const billingAddress =
    typeof selectedWO?.billingAddress === "string"
      ? JSON.parse(selectedWO?.billingAddress)
      : selectedWO?.billingAddress;
  const shippingAddress =
    typeof selectedWO?.shippingAddress === "string"
      ? JSON.parse(selectedWO?.shippingAddress)
      : selectedWO?.shippingAddress;

  /*   All Functions
   ********************************************* */

  const getProductDesc = (product) => {
    let sizeList = JSON.parse(product?.productSizes);
    let possibleSizes = [
      "xs",
      "axs",
      "yxs",
      "s",
      "as",
      "ys",
      "as",
      "ys",
      "m",
      "am",
      "ym",
      "l",
      "al",
      "yl",
      "xl",
      "axl",
      "yxl",
      "2xl",
      "a2xl",
      "y2xl",
    ];

    let groupedByPrice = {};
    sizeList?.map((size) => {
      let old = groupedByPrice[size.price]
        ? [...groupedByPrice[size.price]]
        : [];
      old.push(size);
      groupedByPrice[size.price] = old;
    });

    let allProd = [];
    Array.from(Object.keys(groupedByPrice))?.map((price) => {
      // const sortedArr = groupedByPrice[price];
      const sortedArr = groupedByPrice[price]?.sort((a, b) => {
        return possibleSizes.indexOf(b.size) - possibleSizes.indexOf(a.size);
      });

      let totalQty = 0;

      sortedArr.map((item) => {
        totalQty += parseInt(item?.quantity);
      });

      let updatedSize =
        sortedArr.length > 1
          ? `${sortedArr[0]?.size?.toUpperCase()}-${sortedArr[
              sortedArr.length - 1
            ]?.size?.toUpperCase()}`
          : sortedArr[0]?.size?.toUpperCase();

      let updatedDescription = `${product?.productDescription} ${updatedSize}`;

      let newItem = {
        size: updatedSize,
        price: parseFloat(sortedArr[0]?.price),
        productDescription: updatedDescription,
        productStyle: product?.productStyle,
        productColor: product?.productColor,
        productType: product?.productType,
        quantity: totalQty,
        id: product.id,
        sortId: sortedArr[0].sortId,
      };
      allProd.push(newItem);
    });

    const sortedProds = allProd?.sort((a, b) => {
      return a.sortId - b.sortId;
    });

    return sortedProds;
  };

  const convertSqlTimestampToDate = (sqlTime) => {
    const timestamp = sqlTime;
    const date = new Date(timestamp);
    const time = date.toLocaleString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    return time.replaceAll("/", "-");
  };

  const PageHeader = () => {
    return (
      <>
        <View style={{ ...styles.topHeader }}>
          {/*   Top Header Section
           ********************************************* */}
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <View style={{}}>
              {locationLogo ? (
                <Image
                  src={locationLogo}
                  style={{
                    width: 140,
                    height: 60,
                  }}
                />
              ) : (
                <Image
                  src={logo}
                  style={{
                    width: 140,
                    height: 60,
                  }}
                />
              )}
            </View>
            <View
              style={{
                flexGrow: 1,
                alignItems: "flex-end",
              }}
            >
              <Text style={[styles.headingMain, { marginBottom: 2 }]}>
                WO : {selectedWO?.id}
              </Text>
              <View
                style={{
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <Text style={[styles.headingSecond]}>
                  WO Date: &nbsp;&nbsp;&nbsp;
                  {convertSqlTimestampToDate(selectedWO?.WODate)}
                </Text>
                <Text style={[styles.headingSecond]}>
                  Print Date:&nbsp;&nbsp;&nbsp;
                  {convertSqlTimestampToDate(Date.now())}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.shippingDescription}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View style={{ flexGrow: 1 }}>
              <Text style={styles.headingSecond}>Ordered By:</Text>
              <View
                style={{
                  border: 1,
                  borderRight: 0,
                  paddingVertical: 2,
                  paddingHorizontal: 5,
                }}
              >
                <Text style={styles.textbody}>
                  {customerInfo?.firstName} {customerInfo?.lastName}
                </Text>
              </View>
            </View>
            <View style={{ flexGrow: 1 }}>
              <Text style={styles.headingSecond}>Phone:</Text>
              <View
                style={{
                  border: 1,
                  borderRight: 0,
                  paddingVertical: 2,
                  paddingHorizontal: 5,
                }}
              >
                <Text style={styles.textbody}>
                  {customerInfo?.phone ? customerInfo?.phone : " "}
                </Text>
              </View>
            </View>
            <View style={{ flexGrow: 2 }}>
              <Text style={styles.headingSecond}>Email:</Text>
              <View
                style={{ border: 1, paddingVertical: 2, paddingHorizontal: 5 }}
              >
                <Text style={styles.textbody}>
                  {customerInfo?.email ? customerInfo?.email : " "}
                </Text>
              </View>
            </View>
          </View>

          {/* ----------- billing SHipping Address ---------------- */}
          <View style={{ flexDirection: "row", marginVertical: 5 }}>
            {/* ---------- Billing Area ---------- */}
            <View style={{ flexGrow: 1 }}>
              <View
                style={{
                  flexGrow: 1,
                  paddingVertical: 2,
                  paddingHorizontal: 5,
                }}
              >
                <Text style={styles.headingSecond}>Bill To:</Text>
                <Text style={styles.headingSecond}>
                  {billingAddress?.addressCompany}
                </Text>
                <Text style={styles.headingSecond}>
                  {billingAddress?.addressLine1}
                </Text>
                <Text style={styles.headingSecond}>
                  {billingAddress?.addressLine2}
                </Text>
                <Text style={styles.headingSecond}>
                  {billingAddress?.city}&nbsp;{billingAddress?.state}&nbsp;
                  {billingAddress?.zip}
                  &nbsp;
                </Text>
                <Text style={styles.headingSecond}>
                  {billingAddress?.country}
                </Text>
              </View>
            </View>
            {/* ---------- Shipping Area ---------- */}
            <View style={{ flexGrow: 1 }}>
              <View
                style={{
                  flexGrow: 1,
                  paddingVertical: 2,
                  paddingHorizontal: 5,
                }}
              >
                <Text style={styles.headingSecond}>Ship To:</Text>
                <Text style={styles.headingSecond}>
                  {shippingAddress?.addressCompany}
                </Text>
                <Text style={styles.headingSecond}>
                  {shippingAddress?.addressLine1}
                </Text>
                <Text style={styles.headingSecond}>
                  {shippingAddress?.addressLine2}
                </Text>
                <Text style={styles.headingSecond}>
                  {shippingAddress?.city}&nbsp;{shippingAddress?.state}
                  &nbsp;
                  {shippingAddress?.zip}&nbsp;
                </Text>
                <Text style={styles.headingSecond}>
                  {shippingAddress?.country}
                </Text>
              </View>
            </View>
          </View>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View style={{ flexGrow: 1 }}>
              <Text style={styles.headingSecond}>Customer #:</Text>
              <View
                style={{
                  border: 1,
                  borderRight: 0,
                  paddingVertical: 2,
                  paddingHorizontal: 5,
                }}
              >
                <Text style={styles.textbody}>{selectedWO?.customerId}</Text>
              </View>
            </View>

            <View style={{ flexGrow: 1 }}>
              <Text style={styles.headingSecond}>Term:</Text>
              <View
                style={{
                  border: 1,
                  borderRight: 0,
                  paddingVertical: 2,
                  paddingHorizontal: 5,
                }}
              >
                <Text style={styles.textbody}>
                  {selectedWO?.paymentTerm?.name
                    ? selectedWO?.paymentTerm?.name
                    : " "}
                </Text>
              </View>
            </View>
            <View style={{ flexGrow: 1 }}>
              <Text style={styles.headingSecond}>Sales Person:</Text>
              <View
                style={{
                  border: 1,
                  borderRight: 1,
                  paddingVertical: 2,
                  paddingHorizontal: 5,
                }}
              >
                <Text style={styles.textbody}>
                  {selectedWO?.salesPerson?.name
                    ? selectedWO?.salesPerson?.name
                    : " "}
                </Text>
              </View>
            </View>
            <View style={{ flexGrow: 1 }}>
              <Text style={styles.headingSecond}>Shipment Method:</Text>
              <View
                style={{
                  border: 1,
                  borderLeft: 0,
                  paddingVertical: 2,
                  paddingHorizontal: 5,
                }}
              >
                <Text style={styles.textbody}>
                  {selectedWO?.shippingMethod?.name
                    ? selectedWO?.shippingMethod?.name
                    : " "}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </>
    );
  };

  /*   All UseEffects
   ********************************************* */

  useEffect(() => {
    if (productsList) {
      let updatedProductList = [];
      let products = productsList;

      products?.map((item) => {
        updatedProductList = [...updatedProductList, ...getProductDesc(item)];
      });
      let TotalQty = updatedProductList?.reduce((prev, curr) => {
        let price = parseFloat(
          (parseFloat(curr?.quantity) * parseFloat(curr?.price) * 100) / 100
        ).toFixed(2);
        let total = parseFloat(prev) + parseFloat(price);

        return total;
      }, 0);

      let taxRate = selectedWO?.taxrate?.rate ? selectedWO?.taxrate?.rate : 0;
      setSalesTax(((parseFloat(taxRate * TotalQty) * 100) / 100).toFixed(2));

      let total = TotalQty * taxRate + TotalQty;
      let parsedTotal = ((parseFloat(total) * 100) / 100).toFixed(2);

      setPriceTotal(parsedTotal);
      setTotalQty(TotalQty);
      setInvoiceProductList(updatedProductList);

      // Product Details
    }
  }, [productsList, selectedWO]);

  return (
    <Page size="A4" style={styles.page}>
      <PageHeader />

      <View style={{ marginTop: "10px" }}>
        {/*   Table Head
         ********************************************* */}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-end",
            borderBottom: "2px solid gray",

            paddingHorizontal: 10,
          }}
        >
          <View style={styles.colWithXs}>
            <Text style={styles.textbody}>Ln#</Text>
          </View>
          <View style={styles.colWith}>
            <Text style={styles.textbody}>Product Code</Text>
          </View>
          <View style={styles.colWith}>
            <Text style={styles.textbody}>Color</Text>
          </View>
          <View style={styles.colWithLong}>
            <Text style={styles.textbody}>Description</Text>
          </View>

          <View style={styles.colWithSm}>
            <Text style={styles.textbody}>Qty</Text>
          </View>
          <View style={styles.colWithSm}>
            <Text style={styles.textbody}>Unit Price</Text>
          </View>
          <View style={styles.colWithSm}>
            <Text style={styles.textbody}>Total Price</Text>
          </View>
        </View>
        {/*   Table Body
         ********************************************* */}
        {invoiceProductList &&
          invoiceProductList?.map((item, index) => (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid gray",

                paddingHorizontal: 10,
              }}
              key={item?.id}
            >
              <View style={styles.colWithXs}>
                <Text style={styles.textbody}>{index + 1}</Text>
              </View>

              <View style={styles.colWith}>
                <Text style={[styles.textbody, { textAlign: "left" }]}>
                  {item?.productStyle}
                </Text>
              </View>
              <View style={styles.colWith}>
                <Text style={[styles.textbody, { textAlign: "left" }]}>
                  {item?.productColor}
                </Text>
              </View>
              <View style={styles.colWithLong}>
                <Text style={styles.textbody}>{item?.productDescription}</Text>
              </View>

              <View style={styles.colWithSm}>
                <Text style={styles.textbody}>{item?.quantity}</Text>
              </View>
              <View style={styles.colWithSm}>
                <Text style={styles.textbody}>{item?.price.toFixed(2)}</Text>
              </View>
              <View style={styles.colWithSm}>
                <Text style={[styles.textbody, { textAlign: "right" }]}>
                  {parseFloat(
                    (parseFloat(item?.quantity) *
                      parseFloat(item?.price) *
                      100) /
                      100
                  ).toFixed(2)}
                </Text>
              </View>
            </View>
          ))}
      </View>
      <View style={{ flexDirection: "row" }}>
        <View style={{ flex: 1, marginVertical: 30 }}>
          <Text style={styles.headingThird}>Note:</Text>
        </View>
        <View
          style={{
            width: "30%",
            marginVertical: 30,
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <View style={{ flexDirection: "row", gap: 3, alignItems: "center" }}>
            <View>
              <Text style={styles.headingSecond}>Subtotal</Text>
            </View>
            <View
              style={{
                border: 1.5,
                borderBottom: 0,

                marginLeft: 5,
                height: 15,
                width: "60%",
              }}
            >
              <Text style={[styles.headingSecond, { textAlign: "right" }]}>
                {((parseFloat(totalQty) * 100) / 100).toFixed(2)}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: "row", gap: 3, alignItems: "center" }}>
            <View>
              <Text style={[styles.headingSecond, { textAlign: "right" }]}>
                Sales Tax
              </Text>
            </View>
            <View
              style={{
                border: 1.5,
                borderBottom: 0,

                marginLeft: 5,
                height: 15,
                width: "60%",
              }}
            >
              <Text style={[styles.headingSecond, { textAlign: "right" }]}>
                {salesTax}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: "row", gap: 3, alignItems: "center" }}>
            <View>
              <Text style={[styles.headingSecond, { textAlign: "right" }]}>
                Shipping
              </Text>
            </View>
            <View
              style={{
                border: 1.5,
                borderBottom: 0,

                marginLeft: 5,
                height: 15,
                width: "60%",
              }}
            >
              <Text
                style={[styles.headingSecond, { textAlign: "right" }]}
              ></Text>
            </View>
          </View>
          <View style={{ flexDirection: "row", gap: 3, alignItems: "center" }}>
            <View>
              <Text style={[styles.headingSecond, { textAlign: "right" }]}>
                Total
              </Text>
            </View>
            <View
              style={{
                border: 1.5,
                marginLeft: 5,
                height: 18,
                width: "60%",
              }}
            >
              <Text style={[styles.headingSecond, { textAlign: "right" }]}>
                {priceTotal}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
}
