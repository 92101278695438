import Table from "components/DataTable/Table";
import { useEffect, useState } from "react";

function AddressSelectModal({ selectedWorkOrder, showModal, setFormData }) {
  /*   All States
   ********************************************* */
  const [filterBy, setFilterBy] = useState();
  const [addressData, setAddressData] = useState(null);

  /*   All functions
   ********************************************* */
  const handleSelectedAddress = (item) => {
    let address = {
      description: item.description,
      addressCompany: item.addressCompanyName,
      addressLine1: item.address1,
      addressLine2: item.address2,
      city: item.city,
      state: item.state,
      zip: item.zipcode,
      country: item.country,
    };
    setFormData(address);
  };

  const parseJsonData = (data) => {
    let newArray = [];
    data?.map((item) => {
      newArray.push({ ...item, ...JSON.parse(item.data) });
    });
    return newArray;
  };

  useEffect(() => {
    if (selectedWorkOrder?.customer?.customerinfos) {
      let typeByContact = [];
      selectedWorkOrder?.customer?.customerinfos?.forEach((item) => {
        if (item?.type === "address") {
          typeByContact.push(item);
        }
      });
      setAddressData(parseJsonData(typeByContact));
    }
  }, [selectedWorkOrder]);

  /*   ALL TABLES FUNCTIONS
   ********************************************* */
  const btn1 = () => {
    return (
      <button
        className=" ms-2 btn btn-secondary "
        type="button"
        onClick={() => {
          showModal(false);
        }}
      >
        Close
      </button>
    );
  };
  const buttons = () => (
    <div className=" d-flex justify-content-end ">
      {/* FilterBy */}
      <button
        type="button"
        className="px-1 bg-primary btn text-dark bg-opacity-10 border border-primary rounded-end mx-2 rounded-start"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {filterBy
          ? filterBy.toUpperCase().replaceAll("_", " ")
          : "Filter by All"}
      </button>
      <div className="dropdown-menu">
        <button onClick={() => setFilterBy(null)} className="dropdown-item">
          Filter by All
        </button>
        <button
          onClick={() => setFilterBy("description")}
          className="dropdown-item"
        >
          Description
        </button>
        <button
          onClick={() => setFilterBy("website")}
          className="dropdown-item"
        >
          Company Name
        </button>
        <button
          onClick={() => setFilterBy("attentionTo")}
          className="dropdown-item"
        >
          Attention To
        </button>
        <button
          onClick={() => setFilterBy("address1")}
          className="dropdown-item"
        >
          Address 1
        </button>
        <button
          onClick={() => setFilterBy("address2")}
          className="dropdown-item"
        >
          Address 2
        </button>
        <button onClick={() => setFilterBy("city")} className="dropdown-item">
          City
        </button>
        <button onClick={() => setFilterBy("state")} className="dropdown-item">
          State
        </button>
        <button
          onClick={() => setFilterBy("zipcode")}
          className="dropdown-item"
        >
          Zip
        </button>
        <button
          onClick={() => setFilterBy("country")}
          className="dropdown-item"
        >
          Country
        </button>
      </div>
    </div>
  );
  const table_head = [
    "Description",
    "Company Name",
    "Attention To",
    "Address 1",
    "Address 2",
    "City",
    "State",
    "Zip Code",
    "Country",
    "Action",
  ];
  const table_body = (item) => (
    <tr className="" key={item.id}>
      <td className="py-1">{item.description}</td>
      <td className="py-1">{item.addressCompanyName} </td>
      <td className="py-1">{item.attentionTo} </td>
      <td className="py-1">{item.address1} </td>
      <td className="py-1">{item.address2} </td>
      <td className="py-1">{item.city} </td>
      <td className="py-1">{item.state} </td>
      <td className="py-1">{item.zipcode} </td>
      <td className="py-1">{item.country} </td>
      <td className="table-action py-1">
        <div
          className="btn btn-sm btn-success"
          onClick={() => {
            handleSelectedAddress(item);
            showModal(false);
          }}
        >
          SELECT
        </div>
      </td>
    </tr>
  );
  return (
    <div className=" bg-white shadow-lg px-4 pt-4" style={{ borderRadius: 10 }}>
      <div>
        <div className="horizontal-scrollable py-2 d-flex flex-grow-1">
          <Table
            btn1={btn1}
            filterBy={filterBy}
            buttons={buttons}
            table_head={table_head}
            table_body={table_body}
            table_data={addressData}
          />
        </div>
      </div>
    </div>
  );
}

export default AddressSelectModal;
