import { setNotification } from "features/Notification/notificationSlice";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import authHeader from "services/auth-header";
import API from "services/axios";
import DesignInfo from "./DesignInfo";
import AddVariationForm from "./AddVariationForm";
import VariationsList from "./VariationsList";

export default function DesignDetailsPage() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /*   All States
   ********************************************* */

  const [loading, setLoading] = useState(false);
  const [selectedDesignData, setSelectedDesignData] = useState(null);
  const [selectedVariation, setSelectedVariation] = useState(null);

  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  /*   All Functions
   ********************************************* */

  const GetDesignById = async (id) => {
    try {
      loadingOn();
      //   const { data } = await API.get("/design/"+id, { headers: authHeader() });
      let data = {
        id: 1,
        name: "Design 1",
        customer: {
          id: 12,
          customerName: "Customer 1",
        },
        designType: "Design Type 1",
        designVariations: [
          {
            id: 1,
            designCode: "Code 1",
            color: "Red",
            styleNo: "STY-001",
            sizingCut: "Raglan",
            productImg: "",
            custom1: "111",
            custom2: "222",
            custom3: "333",
          },
          {
            id: 2,
            designCode: "Code 2",
            color: "Yellow",
            styleNo: "STY-002",
            productImg: "",
            sizingCut: "Cut & Sew",
            custom1: "111",
            custom2: "222",
            custom3: "333",
          },
        ],
      };

      //   console.log(data);
      setSelectedDesignData(data);
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const handleBack = () => {
    navigate("/designs");
  };

  /*   All UseEffects
   ********************************************* */

  useEffect(() => {
    if (params.id) {
      GetDesignById(params.id);
    }
  }, [params]);
  return (
    <div
      className="me-2"
      style={{ paddingLeft: 280, paddingTop: 10, height: "90vh" }}
    >
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="fw-bold">Design Request Details</h2>
        <div>
          <button onClick={handleBack} className="btn btn-danger">
            Back
          </button>
        </div>
      </div>
      <div className="bg-white custom-shadow-sm custom-border-radius-sm px-3 pt-2 pb-3 mt-2">
        <DesignInfo selectedDesignData={selectedDesignData} />
      </div>
      <div className="d-flex gap-2 overflow-auto">
        <div className="w-25 bg-white custom-shadow-sm custom-border-radius-sm px-3 pt-2 pb-3 mt-2">
          <AddVariationForm
            setSelectedDesignData={setSelectedDesignData}
            selectedDesignData={selectedDesignData}
            selectedVariation={selectedVariation}
          />
        </div>
        <div className="w-75 bg-white custom-shadow-sm custom-border-radius-sm px-3 pt-2 pb-3 mt-2">
          <VariationsList
            selectedDesignData={selectedDesignData}
            selectedVariation={selectedVariation}
            setSelectedVariation={setSelectedVariation}
          />
        </div>
      </div>
    </div>
  );
}
