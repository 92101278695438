import { createSlice } from "@reduxjs/toolkit";

export const NotificationSlice = createSlice({
  name: "alert",
  initialState: {
    alerts: []
  },
  reducers: {
    setNotification: (state, { payload }) => {
      if ( payload.message ) {
        state.alerts.push({
          message: payload.message,
          type: payload.type
        });
      } else {
        state.alerts = []
      }
    }
  }
});

export const { setNotification } = NotificationSlice.actions;
export default NotificationSlice.reducer;
