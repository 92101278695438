import Table from "components/DataTable/Table";
import { useState } from "react";

export default function BidContactSelectModal({
  handleContactSelect,
  contactsList,
  showModal,
}) {
  /*   All States
   ********************************************* */
  const [filterBy, setFilterBy] = useState();

  /*   All functions
   ********************************************* */

  /*   ALL TABLES FUNCTIONS
   ********************************************* */
  const btn1 = () => {
    return (
      <button
        className=" ms-2 btn btn-secondary "
        type="button"
        onClick={() => {
          showModal(false);
        }}
      >
        Close
      </button>
    );
  };
  const buttons = () => (
    <div className=" d-flex justify-content-end ">
      {/* FilterBy */}
      <button
        type="button"
        className="px-1 bg-primary btn text-dark bg-opacity-10 border border-primary rounded-end mx-2 rounded-start"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {filterBy
          ? filterBy.toUpperCase().replaceAll("_", " ")
          : "Filter by All"}
      </button>
      <div className="dropdown-menu">
        <button onClick={() => setFilterBy(null)} className="dropdown-item">
          Filter by All
        </button>
        <button
          onClick={() => setFilterBy("firstName")}
          className="dropdown-item"
        >
          First Name
        </button>
        <button
          onClick={() => setFilterBy("lastName")}
          className="dropdown-item"
        >
          Last Name
        </button>
        <button
          onClick={() => setFilterBy("department")}
          className="dropdown-item"
        >
          Department
        </button>
        <button onClick={() => setFilterBy("email")} className="dropdown-item">
          Email
        </button>
        <button onClick={() => setFilterBy("phone")} className="dropdown-item">
          Phone
        </button>
      </div>
    </div>
  );

  const table_head = [
    "First Name",
    "Last Name",
    "Department",
    "Phone",
    "Email",
    "Action",
  ];

  const table_body = (item) => (
    <tr className="" key={item.id}>
      <td className={"py-1 align-middle"}>{item.firstName}</td>
      <td className={"py-1 align-middle"}>{item.lastName}</td>
      <td className={"py-1 align-middle"}>{item.department} </td>
      <td className={"py-1 align-middle"}>{item.phone} </td>
      <td className={"py-1 align-middle"}>{item.email} </td>
      <td className="table-action py-1 align-middle">
        <div
          className="btn btn-sm btn-success"
          onClick={() => {
            handleContactSelect(item);
            showModal(false);
          }}
        >
          SELECT
        </div>
      </td>
    </tr>
  );
  return (
    <div className="shadow-lg bg-white px-4 py-2" style={{ borderRadius: 10 }}>
      <h3>Customer Contacts List</h3>
      <Table
        btn1={btn1}
        filterBy={filterBy}
        buttons={buttons}
        table_head={table_head}
        table_body={table_body}
        table_data={contactsList}
      />
    </div>
  );
}
