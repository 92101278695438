import TableOnly from "components/DataTable/TableOnly";
import Pagination from "components/Pagination/Pagination";
import SearchComp from "components/SearchComp/SearchComp";
import Spinner from "components/Spinner";
import Toast from "components/Toast/ToastComponent";
import useWorkOrder from "features/Contexts/WorkOrderContext";
import { setNotification } from "features/Notification/notificationSlice";
import ViewQuoteProducts from "pages/WorkOrders/ViewWOProduct";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import authHeader from "services/auth-header";
import API from "services/axios";
import * as XLSX from "xlsx";

export default function PortalWorkOrder() {
  const user = useSelector((state) => state.user?.userInfo);
  const { company } = useParams();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { workOrderContextData } = useWorkOrder();

  /*   All States
   ********************************************* */
  const [workOrdersData, setWorkOrdersData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [searchResults, setSearchResults] = useState(null);
  const [showWorkOrderProductModal, setShowWorkOrderProductModal] =
    useState(false);

  const [selectedId, setSelectedId] = useState(null);
  const [showDetailsPage, setShowDetailsPage] = useState(false);

  const [locationData, setLocationData] = useState(null);

  const [active, setActive] = useState("all");

  const [totalPages, setTotalPages] = useState(1);

  const [isLoading, setIsLoading] = useState(null);

  const checkActive = (val) => {
    setActive(val);
    setShowDetailsPage(false);
  };

  /*   All Functions
   ********************************************* */
  const getWorkOrdersData = async () => {
    setIsLoading(true);
    try {
      let page = searchParams.get("page");
      let query = searchParams.get("query");

      let body = { page, activeTab: active };

      if (query) {
        body.query = query;
      }
      if (!user?.cid) {
        dispatch(
          setNotification({
            message: "No Cid Found",
            type: "error",
          })
        );
      }
      if (!company) {
        dispatch(
          setNotification({
            message: "Company Not found in url",
            type: "error",
          })
        );
      }

      body.cid = user?.cid;
      body.company = company;

      const { data } = await API.post("/portal-workOrders", body, {
        headers: authHeader(),
      });
      setWorkOrdersData(data.result);
      setTotalPages(data.pages);
      setIsLoading(false);
      return data.result;
    } catch (err) {
      console.log(err.response);

      dispatch(
        setNotification({
          message: err.response.data,
          type: "error",
        })
      );
      setIsLoading(false);
    }
  };

  // Getting 12 Hours Time from SQL timestamp
  const convertSqlTimestampToDate = (sqlTime) => {
    const timestamp = sqlTime;
    const date = new Date(timestamp);
    const time = date.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    return time;
  };

  const downloadExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, `${active}.xlsx`);
  };

  const getLocations = async () => {
    try {
      const { data } = await API.get("/locations", { headers: authHeader() });
      setLocationData(data);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };
  const getLocationName = (id) => {
    const item = locationData?.find((item) => item.id === id);
    if (item) {
      return item.locationName;
    } else {
      return null;
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getLocations();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (workOrderContextData) {
      setShowDetailsPage(true);
      setSelectedId(workOrderContextData.data.id);
      setWorkOrdersData([workOrderContextData.data]);
    } else {
      getWorkOrdersData();
    }
  }, [showDetailsPage, searchParams, active, workOrderContextData]);

  useEffect(() => {
    if (workOrderContextData) {
      setShowDetailsPage(true);
      setSelectedId(workOrderContextData.data.id);
    }
  }, [workOrderContextData]);

  useEffect(() => {
    if (workOrdersData && active == "all") {
      setFilteredData(workOrdersData);
    } else if (workOrdersData && active == "P & P") {
      let pending = workOrdersData.filter(
        (item) =>
          item.quoteStatus == "pending" || item.quoteStatus == "processing"
      );
      setFilteredData(pending);
    } else if (workOrdersData && active == "completed") {
      let completed = workOrdersData.filter(
        (item) => item.quoteStatus == "completed"
      );
      setFilteredData(completed);
    }
  }, [active, workOrdersData]);

  /*   Table Functions
   ********************************************* */
  const btn1 = () => (
    <div>
      {(user?.permissions?.includes("work_orders_add") ||
        user?.roles === "admin") && (
        <>
          <button
            onClick={() => downloadExcel(filteredData)}
            type="button"
            className="btn btn-primary btn-success ms-1"
          >
            To Excel
          </button>
        </>
      )}
    </div>
  );

  const table_head = [
    "Quote ID",
    "Customer Name",
    "Job Name",
    "Location",
    "Created Date",
    "Created By",
    "WO Status",
    "Actions",
  ];

  const table_body = (item, index) => (
    <tr className="" key={item.id}>
      <td className="py-1">{item.id}</td>
      <td className="py-1">{item.customerName}</td>
      <td className="py-1">{item.jobName}</td>
      <td className="py-1">{getLocationName(item.locationId)}</td>
      <td className="py-1">{convertSqlTimestampToDate(item.createdAt)} </td>
      <td className="py-1">{item.createdBy} </td>
      <td className="py-1">{item.quoteStatus}</td>
      <td className="table-action py-1">
        <button
          onClick={() => {
            setSelectedId(item.id);
            setShowWorkOrderProductModal(true);
          }}
          className={`btn btn-sm bg-success text-white`}
        >
          Quick View
        </button>
      </td>
    </tr>
  );

  return (
    <div className="row">
      <Toast />
      {workOrdersData ? (
        <div className="col-12">
          <div className="d-flex">
            {/*    Table Card
             ********************************************* */}
            <div className=" flex-grow-1">
              {!showDetailsPage && (
                <div
                  className={`flex-grow-1 d-flex flex-column bg-white ${
                    !showDetailsPage && "shadow-lg"
                  } px-5 py-2`}
                  style={{
                    position: "fixed",
                    top: 0,
                    width: "calc(100vw - 200px)",
                    marginLeft: 200,
                    zIndex: "99",
                  }}
                >
                  <h4 className="ms-3 h3">
                    WorkOrders {JSON.stringify(company)}{" "}
                  </h4>

                  {/* <ul className="nav nav-tabs nav-bordered border-0 d-flex flex-grow-1">
                    <li
                      onClick={() => {
                        checkActive("P & P");
                      }}
                      className="nav-item"
                    >
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id="tooltip">Pending & Processing</Tooltip>
                        }
                      >
                        <button
                          className={
                            active == "P & P"
                              ? `nav-link rounded-0 active bg-primary text-white`
                              : "nav-link rounded-0"
                          }
                        >
                          <span className="d-none d-md-block">P & P</span>
                        </button>
                      </OverlayTrigger>
                    </li>

                    <li
                      onClick={() => {
                        checkActive("completed");
                      }}
                      className="nav-item"
                    >
                      <button
                        className={
                          active == "completed"
                            ? `nav-link rounded-0 active bg-primary text-white`
                            : "nav-link rounded-0"
                        }
                      >
                        <span className="d-none d-md-block">COMPLETED</span>
                      </button>
                    </li>
                    <li
                      onClick={() => {
                        checkActive("all");
                      }}
                      className=""
                    >
                      <button
                        className={
                          active == "all"
                            ? `nav-link rounded-0 active bg-primary text-white`
                            : "nav-link rounded-0"
                        }
                      >
                        <i className="mdi mdi-settings-outline d-md-none d-block"></i>
                        <span className="d-none d-md-block">ALL</span>
                      </button>
                    </li>
                  </ul> */}
                </div>
              )}
              <div className="">
                {!showDetailsPage && !workOrderContextData ? (
                  <>
                    <div
                      className=" px-3 py-2 pb-2 horizontal-scrollable py-4 d-flex flex-column flex-grow-1 mt-5"
                      style={{ borderRadius: 10, marginLeft: 260 }}
                    >
                      <div className="d-flex justify-content-end align-items-center mb-3">
                        <div className="me-1">{btn1()}</div>
                        <SearchComp
                          activeTab={active}
                          setLoading={setIsLoading}
                          setTotalPages={setTotalPages}
                          searchResults={searchResults}
                          setSearchResults={setSearchResults}
                          apiPath={`/portal/${company
                            ?.toLowerCase()
                            .replaceAll(" ", "-")}/workOrders`}
                        />
                      </div>
                      {searchResults ? (
                        <TableOnly
                          table_head={table_head}
                          table_body={table_body}
                          table_data={searchResults}
                          totalPages={totalPages}
                        />
                      ) : (
                        <TableOnly
                          table_head={table_head}
                          table_body={table_body}
                          table_data={filteredData}
                          totalPages={totalPages}
                        />
                      )}
                      {isLoading && <Spinner />}
                      {!searchResults && (
                        <Pagination
                          totalPages={totalPages}
                          // apiPath={`/workOrders?page=`}
                          apiPath={`/portal/${company
                            ?.toLowerCase()
                            .replaceAll(" ", "-")}/workOrders?page=`}
                          currPage={parseInt(searchParams.get("page")) || 1}
                        />
                      )}
                    </div>
                  </>
                ) : (
                  <div className="mt-5"></div>
                )}
              </div>
            </div>
          </div>
          {/*   Update Modal
           ********************************************* */}
          <Modal
            size="xl"
            show={showWorkOrderProductModal}
            onHide={() => {
              setShowWorkOrderProductModal(false);
              getWorkOrdersData();
            }}
          >
            <Modal.Body>
              <div>
                <ViewQuoteProducts
                  refreshFunc={getWorkOrdersData}
                  allQuotesData={filteredData}
                  setShowWorkOrderProductModal={setShowWorkOrderProductModal}
                  selectedId={selectedId}
                  setShowDetailsPage={setShowDetailsPage}
                  setSelectedId={setSelectedId}
                />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
}
