import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import api from "services/axios";

function CompaniesUpdate() {
  const [defaultValues, setDefaultValues] = useState(null);

  const { register, handleSubmit } = useForm();
  const { companyID } = useParams();
  let token = localStorage.getItem("userToken");

  const navigate = useNavigate();

  const submitForm = (data, e) => {
    e.preventDefault();
    api
      .patch(
        "/companies/update/" + companyID,
        { data },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => navigate("/companies", { replace: true }))
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getCompaniesByID = async () => {
    let result = await api.get("/companies/" + companyID, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    setDefaultValues(result.data);
  };
  useEffect(() => {
    getCompaniesByID();
  }, []);

  return (
    <div className="container-fluid">
      {/* start page title */}
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <h4 className="page-title">Update RecID </h4>
          </div>
        </div>
      </div>
      {/* end page title */}
      {defaultValues && (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="tab-content">
                  <div
                    className="tab-pane show active"
                    id="input-types-preview"
                  >
                    <form onSubmit={handleSubmit(submitForm)}>
                      <div className="row">
                        {/* start col 1 */}
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Email Company ID
                            </label>
                            <input
                              {...register("emailcompany_id")}
                              type="text"
                              className="form-control"
                              defaultValue={defaultValues.emailcompany_id}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">
                              Street Address 1
                            </label>
                            <input
                              {...register("street_1")}
                              type="text"
                              className="form-control"
                              defaultValue={defaultValues.street_1}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">
                              Street Address 2
                            </label>
                            <input
                              {...register("street_2")}
                              type="text"
                              className="form-control"
                              defaultValue={defaultValues.street_2}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">City</label>
                            <input
                              {...register("city")}
                              type="text"
                              className="form-control"
                              defaultValue={defaultValues.city}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">State</label>
                            <input
                              {...register("state")}
                              type="text"
                              className="form-control"
                              defaultValue={defaultValues.state}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Zip</label>
                            <input
                              {...register("zip")}
                              type="text"
                              className="form-control"
                              defaultValue={defaultValues.zip}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Country</label>
                            <input
                              {...register("country")}
                              type="text"
                              className="form-control"
                              defaultValue={defaultValues.country}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Timezone</label>
                            <input
                              {...register("time_zone")}
                              type="text"
                              className="form-control"
                              defaultValue={defaultValues.time_zone}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Total Users</label>
                            <input
                              {...register("total_num_users")}
                              type="text"
                              className="form-control"
                              defaultValue={defaultValues.total_num_users}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">
                              Account Executive
                            </label>
                            <input
                              {...register("account_executive_id")}
                              type="text"
                              className="form-control"
                              defaultValue={defaultValues.account_executive_id}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">
                              Account Manager ID
                            </label>
                            <input
                              {...register("account_manager_id")}
                              type="text"
                              className="form-control"
                              defaultValue={defaultValues.account_manager_id}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Preference</label>
                            <input
                              {...register("preference")}
                              type="text"
                              className="form-control"
                              defaultValue={defaultValues.preference}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">UTM Campaign</label>
                            <input
                              {...register("utm_campaign")}
                              type="text"
                              className="form-control"
                              defaultValue={defaultValues.utm_campaign}
                            />
                          </div>
                        </div>
                        {/* end col 1*/}

                        {/* start col 2 */}
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label className="form-label">UTM Medium</label>
                            <input
                              {...register("utm_medium")}
                              type="text"
                              className="form-control"
                              defaultValue={defaultValues.utm_medium}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">UTM Source</label>
                            <input
                              {...register("utm_source")}
                              type="text"
                              className="form-control"
                              defaultValue={defaultValues.utm_source}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Deactivated At</label>
                            <input
                              {...register("deactivated_at")}
                              type="text"
                              className="form-control"
                              defaultValue={defaultValues.deactivated_at}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Balance</label>
                            <input
                              {...register("balance")}
                              type="text"
                              className="form-control"
                              defaultValue={defaultValues.balance}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Credit Balance</label>
                            <input
                              {...register("credit_balance")}
                              type="text"
                              className="form-control"
                              defaultValue={defaultValues.credit_balance}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">
                              Last Invoice on
                            </label>
                            <input
                              {...register("last_invoice_on")}
                              type="text"
                              className="form-control"
                              defaultValue={defaultValues.last_invoice_on}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">
                              Subscription Plan
                            </label>
                            <input
                              {...register("subscription_plan")}
                              type="text"
                              className="form-control"
                              defaultValue={defaultValues.subscription_plan}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">
                              Subscription Cancelled on
                            </label>
                            <input
                              {...register("subscription_cancelled_on")}
                              type="text"
                              className="form-control"
                              defaultValue={
                                defaultValues.subscription_cancelled_on
                              }
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">
                              Enabled Features
                            </label>
                            <input
                              {...register("enabled_features")}
                              type="text"
                              className="form-control"
                              defaultValue={defaultValues.enabled_features}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Segment Type</label>
                            <input
                              {...register("segment_type")}
                              type="text"
                              className="form-control"
                              defaultValue={defaultValues.segment_type}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Industry</label>
                            <input
                              {...register("industry")}
                              type="text"
                              className="form-control"
                              defaultValue={defaultValues.industry}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Is Active</label>
                            <input
                              {...register("is_active")}
                              type="text"
                              className="form-control"
                              defaultValue={defaultValues.is_active}
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Reactivated At</label>
                            <input
                              {...register("reactivated_at")}
                              type="text"
                              className="form-control"
                              defaultValue={defaultValues.reactivated_}
                            />
                          </div>
                        </div>
                        {/* end col 2*/}
                      </div>
                      <div className="d-flex flex-row-reverse">
                        <button type="submit" className="btn btn-primary">
                          Update Company
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CompaniesUpdate;
