import Table from 'components/DataTable/Table';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import authHeader from 'services/auth-header';
import API from 'services/axios';
import Modal from 'react-bootstrap/Modal';
import CustomerContactAdd from './CustomerContactAdd';
import CustomerContactUpdate from './customerContactUpdate';
import { setNotification } from 'features/Notification/notificationSlice';

function CustomerContact({ id, customerContactData, getCustomerInfo }) {
  const user = useSelector((state) => state.user?.userInfo);
  const dispatch = useDispatch();

  /*   ALL STATES
   ********************************************* */
  const [lastSortId, setLastSortId] = useState(null);
  const [filterBy, setFilterBy] = useState(null);

  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const [selected, setSelected] = useState(null);
  const [deleteID, setDeleteID] = useState(null);

  const [contactsData, setContactsData] = useState(null);

  useEffect(() => {
    if (customerContactData) {
      setContactsData(customerContactData);
      setLastSortId(
        customerContactData[customerContactData?.length - 1]?.sortId
          ? customerContactData[customerContactData?.length - 1]?.sortId
          : 0
      );
    }
  }, [filterBy, customerContactData]);

  // Sorting Up
  const sortUp = async (index) => {
    const currentSortId = contactsData[index].sortId;
    const currentId = contactsData[index].id;
    const upSortId = contactsData[index - 1].sortId;
    const upId = contactsData[index - 1].id;
    try {
      const { data } = await API.post(
        '/customers/contacts/sortup',
        { currentSortId, currentId, upSortId, upId },
        { headers: authHeader() }
      );
      if (data.success) {
        getCustomerInfo();
      }
    } catch (err) {
      alert(err.message);
    }
  };

  // Sorting Up
  const sortDown = async (index) => {
    const currentSortId = contactsData[index].sortId;
    const currentId = contactsData[index].id;
    const downSortId = contactsData[index + 1].sortId;
    const downId = contactsData[index + 1].id;
    try {
      const { data } = await API.post(
        '/customers/contacts/sortdown',
        { currentSortId, currentId, downSortId, downId },
        { headers: authHeader() }
      );
      if (data.success) {
        getCustomerInfo();
      }
    } catch (err) {
      alert(err.message);
    }
  };

  // Delete Customers
  const deleteCustomerContact = async (id) => {
    try {
      const { data } = await API.post(
        '/customers/contacts/delete',
        { id },
        { headers: authHeader() }
      );
      if (data.success) {
        getCustomerInfo();
        setShowDeleteModal(false);
        return;
      }
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: 'error',
        })
      );
    }
  };

  const copyContent = (val) => {
    navigator.clipboard.writeText(val);
  };

  /*   Table Functions
   ********************************************* */
  const btn1 = () => (
    <div>
      {(user?.permissions?.includes('customer_add') || user?.roles === 'admin') && (
        <>
          {' '}
          <button
            onClick={() => setShowAddModal(true)}
            type='button'
            className='btn btn-primary ms-1'
          >
            ADD
          </button>
        </>
      )}
    </div>
  );

  const btnLeft = () => (
    <div className='d-flex'>
      <h4 className='border-secondary border-bottom'>CUSTOMERS CONTACTS</h4>
    </div>
  );

  const buttons = () => (
    <div className='d-flex justify-content-end flex-grow-1'>
      <div className=' d-flex justify-content-end '>
        {/* FilterBy */}
        <button
          type='button'
          className='px-1 bg-primary btn text-dark bg-opacity-10 border border-primary rounded-end mx-2 rounded-start'
          data-bs-toggle='dropdown'
          aria-haspopup='true'
          aria-expanded='false'
        >
          {filterBy ? filterBy.toUpperCase().replaceAll('_', ' ') : 'Filter by All'}
        </button>
        <div className='dropdown-menu'>
          <a onClick={() => setFilterBy(null)} className='dropdown-item' role={'button'}>
            Filter by All
          </a>
          <a onClick={() => setFilterBy('firstName')} className='dropdown-item' role={'button'}>
            First Name
          </a>
          <a onClick={() => setFilterBy('lastName')} className='dropdown-item' role={'button'}>
            Last Name
          </a>
          <a onClick={() => setFilterBy('description')} className='dropdown-item' role={'button'}>
            Description
          </a>
          <a onClick={() => setFilterBy('email')} className='dropdown-item' role={'button'}>
            Email
          </a>
          <a onClick={() => setFilterBy('phone')} className='dropdown-item' role={'button'}>
            Phone
          </a>
        </div>
      </div>
    </div>
  );

  const table_head = [
    'First Name',
    'Last Name',
    'Department',
    'Phone',
    'Mobile Phone',
    'Email',
    'Action',
  ];

  const table_body = (item, index) => (
    <tr className='' key={item?.id}>
      <td className='py-0 pe-0 align-middle'>{item?.firstName}</td>
      <td className='py-0 pe-0 align-middle'>{item?.lastName}</td>
      <td className='py-0 pe-0 align-middle'>{item?.department} </td>
      <td className='py-0 pe-0 align-middle'>{item?.phone} </td>
      <td className='py-0 pe-0 align-middle'>{item?.mobilePhone} </td>
      <td
        onDoubleClick={() => {
          copyContent(item?.email);
        }}
        className='py-0 pe-0 align-middle user-select-none'
      >
        {item?.email}{' '}
      </td>
      <td className='table-action py-0 '>
        {(user?.permissions?.includes('customer_update') || user?.roles === 'admin') && (
          <Link
            onClick={() => {
              item.sortId != 1 && sortUp(index, item);
            }}
            className='action-icon'
          >
            <i className='mdi mdi-chevron-double-up'></i>
          </Link>
        )}
        {(user?.permissions?.includes('customer_update') || user?.roles === 'admin') && (
          <Link
            onClick={() => {
              item.sortId != contactsData?.length && sortDown(index);
            }}
            className='action-icon'
          >
            <i className='mdi mdi-chevron-double-down'></i>
          </Link>
        )}
        {(user?.permissions?.includes('customer_update') || user?.roles === 'admin') && (
          <Link
            onClick={() => {
              setSelected(item);
              setShowUpdateModal(true);
            }}
            className='action-icon'
          >
            <i className='mdi mdi-pencil'></i>
          </Link>
        )}
        {(user?.permissions?.includes('customer_delete') || user?.roles === 'admin') && (
          <Link
            onClick={() => {
              setShowDeleteModal(true);
              setDeleteID(item.id);
            }}
            className='action-icon'
          >
            <i className='mdi mdi-delete'></i>
          </Link>
        )}
      </td>
    </tr>
  );
  return (
    <>
      <div className='flex-grow-1'>
        <Table
          filterBy={filterBy}
          buttons={buttons}
          btnLeft={btnLeft}
          btn1={btn1}
          table_head={table_head}
          table_body={table_body}
          table_data={contactsData}
        />
      </div>
      {/*   Add Modal
       ********************************************* */}
      <Modal show={showAddModal} size='lg' onHide={() => setShowAddModal(false)}>
        <Modal.Body>
          <CustomerContactAdd
            id={id}
            lastSortId={lastSortId}
            refreshFunc={getCustomerInfo}
            setShowAddModal={setShowAddModal}
          />
        </Modal.Body>
      </Modal>

      {/*   Update Modal
       ********************************************* */}
      {selected && (
        <Modal show={showUpdateModal} size='lg' onHide={() => setShowUpdateModal(false)}>
          <Modal.Body>
            {selected && (
              <CustomerContactUpdate
                id={id}
                lastSortId={lastSortId}
                selected={selected}
                refreshFunc={getCustomerInfo}
                setShowUpdateModal={setShowUpdateModal}
              />
            )}
          </Modal.Body>
        </Modal>
      )}
      {/*   Delete Modal
       ********************************************* */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Body className='bg-danger'>
          <div className=''>
            <div className='text-center text-white'>
              <i className='ri-close-circle-line h1'></i>
              <h4 className='mt-2'>Confirm Delete!</h4>
              <p className='mt-3'>Do You want to delete ?</p>
              <button
                type='button'
                onClick={() => setShowDeleteModal(false)}
                className='btn btn-light my-2 mx-2'
              >
                Cancel
              </button>
              <button
                type='button'
                className='btn btn-outline-light my-2 '
                data-bs-dismiss='modal'
                onClick={() => {
                  deleteCustomerContact(deleteID);
                }}
              >
                DELETE
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CustomerContact;
