import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import Accordion from "react-bootstrap/Accordion";
import { Table } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import CopyPastePicture from "./CopyPastePicture";

function ExcelFunctions() {
  /*   All States
   ********************************************* */

  const [excelData, setExcelData] = useState([]);
  const [wb, setWb] = useState(null);
  const [ws, setWs] = useState(null);

  const [showWorkSheetModal, setShowWorkSheetModal] = useState(null);
  const [showCopyPasteModal, setShowCopyPasteModal] = useState(false);

  /*   All Functions
   ********************************************* */

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      if (!file) {
        return;
      }
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        // const rows = XLSX.utils.sheet_to_json(ws, { header: 1 });

        // const jsonData = processRows(rows);

        resolve(wb);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      setWb(d);

      //   setExcelData(d);
    });
  };

  function processRows(rows) {
    // Rows is the data
    let result = [];
    let teamName = null;
    let currSizeArr = null;
    let teamArr = [];

    rows.forEach((row, index) => {
      const isNumberCheck = row.every((cell) => typeof cell === "number");

      if (row.length === 1 && !isNumberCheck) {
        teamName = row[0];
        currSizeArr = rows[index + 1];
        teamArr = [];
      } else {
        // This is a data row
        if (row !== currSizeArr && row.length > 0) {
          let currentData = {};

          row.forEach((cell, cellIndex) => {
            if (cell !== null) {
              let currentSize = currSizeArr[cellIndex];
              currentData[currentSize] = parseInt(cell);
            }
          });

          teamArr.push(currentData);
          result[teamName] = teamArr;
        }
      }
    });

    return result;
  }

  function getResults(sheetName) {
    let selectedSheet = wb.Sheets[sheetName];
    const rows = XLSX.utils.sheet_to_json(selectedSheet, { header: 1 });

    const jsonData = processRows(rows);
    setExcelData(jsonData);
    setShowWorkSheetModal(false);
  }

  const getTableHeadingList = (dataArr) => {
    let maxEntryObject = dataArr.reduce(
      (max, obj) =>
        Object.keys(obj).length > Object.keys(max).length ? obj : max,
      {}
    );

    // Get the list of keys of that object
    let keys = Object.keys(maxEntryObject);

    return keys;
  };
  /*   All UseEffects
   ********************************************* */

  useEffect(() => {
    if (wb) {
      setWs(wb.SheetNames);
      setShowWorkSheetModal(true);
    }
  }, [wb]);

  return (
    <div
      className="p-5"
      style={{
        position: "fixed",
        top: 0,
        width: "calc(100vw - 200px)",
        marginLeft: 200,
        zIndex: "99",
      }}
    >
      <div className="bg-white shadow-lg custom-border-radius p-4 mx-5">
        <label className="h3">Upload Excel File</label>
        <input
          type="file"
          className="form-control mb-3"
          onChange={(e) => {
            const file = e.target.files[0];
            readExcel(file);
          }}
        />

        <h3 className="mt-4">Result</h3>

        <div>
          <Accordion defaultActiveKey="0">
            {excelData ? (
              Object.keys(excelData).map((key, index) => (
                <Accordion.Item key={index} eventKey={key}>
                  <Accordion.Header>{key}</Accordion.Header>
                  <Accordion.Body>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          {getTableHeadingList(excelData[key]).map((el, i) => (
                            <th key={i}>{el}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {excelData[key].map((item, index) => (
                          <tr key={index}>
                            {getTableHeadingList(excelData[key]).map(
                              (td, i) => (
                                <td key={i}>{item[td] || "-"}</td>
                              )
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Accordion.Body>
                </Accordion.Item>
              ))
            ) : (
              <h4 className="text-danger my-2">No File Selected</h4>
            )}
          </Accordion>
        </div>
      </div>
      <button
        className="btn btn-primary"
        onClick={() => {
          setShowCopyPasteModal(true);
        }}
      >
        Open Copy Paste
      </button>
      <Modal
        show={showWorkSheetModal}
        onHide={() => setShowWorkSheetModal(false)}
      >
        <Modal.Body className="">
          <h3>Select WorkSheet</h3>
          <div className="my-3">
            {ws &&
              ws.map((item) => (
                <div className="bg-white custom-shadow-sm custom-border-radius-sm p-1 px-2 mb-1 d-flex align-excelData-center justify-content-between">
                  <div> {item}</div>
                  <div>
                    <button
                      onClick={() => {
                        getResults(item);
                      }}
                      className="btn btn-sm btn-success"
                    >
                      select
                    </button>
                  </div>
                </div>
              ))}
          </div>
          <div className="text-end">
            <button
              onClick={() => {
                setShowWorkSheetModal(false);
              }}
              className="btn btn-secondary"
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {/*   Copy Paste Function
       ********************************************* */}
      <Modal
        show={showCopyPasteModal}
        onHide={() => setShowCopyPasteModal(false)}
      >
        <Modal.Body className="">
          <h3>Copy Paste</h3>
          <div className="my-3">
            <label htmlFor="msgBody" className="form-label">
              Input Msg
            </label>
            <CopyPastePicture
              showModal={setShowCopyPasteModal}
              placeholder={"Type Msg..."}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ExcelFunctions;
