exports.GetNameWithTimeStamp = (name) => {
  // Get current timestamp
  let timeStamp = new Date()
    .toISOString()
    .replace(/:/g, "-")
    .replace(/\..+/, "");

  // Split name into filename and extension
  const parts = name.split(".");
  const filename = parts.shift();
  const extension = parts.join(".");

  // Return Name with TimeStamp
  return `${filename}_${timeStamp}.${extension}`;
};

// Getting 12 Hours Time from SQL timestamp
exports.convertSqlTimestampToDate = (sqlTime) => {
  const timestamp = sqlTime;
  const date = new Date(timestamp);
  const time = date.toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  return time;
};

// Getting 12 Date Time from SQL Time
exports.convertSqlTimeTo12Hrs = (sqlTime) => {
  if (!sqlTime) {
    return;
  }
  let fullHours = sqlTime.split(":")[0];
  let minutes = sqlTime.split(":")[1];

  let shortHours = fullHours >= "12" ? fullHours - 12 : fullHours;
  let ampm = fullHours >= "12" ? "PM" : "AM";

  return `${shortHours}:${minutes} ${ampm}`;
};

exports.convertAttendanceRecordToTime = (record) => {
  let date = new Date(record);
  const options = {
    timeZone: "Asia/Karachi",
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  const localTime = date.toLocaleString("en-GB", options);
  return localTime;
};
