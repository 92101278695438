import { BlobProvider } from "@react-pdf/renderer";
import { useState } from "react";
import { AttendanceReport } from "./AttendanceReport";

export default function AttReportBtn({ btnClasses, selectedReport }) {
  /*   All States
   ********************************************* */
  const [showPdf, setShowPdf] = useState(false);

  /*   All Functions
   ********************************************* */

  /*   All UseEffects
   ********************************************* */

  return (
    <>
      {showPdf ? (
        <>
          <BlobProvider
            document={<AttendanceReport selectedReport={selectedReport} />}
          >
            {({ url, blob }) => {
              return (
                <>
                  <button disabled={!showPdf} className={btnClasses}>
                    <a
                      className="text-decoration-none text-white"
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="mdi mdi-download"></i>Download Report
                      {!url && (
                        <div
                          className="spinner-border spinner-border-sm text-white ms-2 mb-1"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                    </a>
                  </button>
                </>
              );
            }}
          </BlobProvider>
        </>
      ) : (
        <>
          <button
            className={btnClasses}
            onClick={(e) => {
              setShowPdf(true);
            }}
          >
            Generate Report
          </button>
        </>
      )}
    </>
  );
}
