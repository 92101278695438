import WOPdfButton from "components/Pdfs/WOPdf/WOPdfButton";
import PrintProductPdf from "components/ProductPdfPrintButton/PrintProductPdf";
import Spinner from "components/Spinner";
import Toast from "components/Toast/ToastComponent";
import { setNotification } from "features/Notification/notificationSlice";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";

function CustomerWOProduct({
  refreshFunc,
  groupBy,
  selectedWOId,
  customerWO,
  setWorkOrderContext,
  setShowProductsModal,
}) {
  /*   All States
   ********************************************* */
  const dispatch = useDispatch();

  /*   All States
   ********************************************* */

  const [allSizes, setAllSizes] = useState(null);
  const [allCustomerWO, setAllCustomerWO] = useState(null);
  const [generalData, setGeneralData] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [WOProductsData, setWOProductsData] = useState(null);
  const [count, setCount] = useState(1);

  const [selectedWorkOrder, setSelectedWorkOrder] = useState(null);
  const [WOId, setWOId] = useState(null);
  const [WOIndex, setWOIndex] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [productTypesData, setProductTypesData] = useState(false);

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["clean"],
    ],
  };

  /*   All Functions
   ********************************************* */

  const prevWO = () => {
    if (WOIndex == 0) {
      return;
    } else {
      setWOIndex((prevState) => prevState - 1);
      setCount(1);
      setCurrentIndex(0);
    }
  };
  const nextWO = () => {
    if (WOIndex == allCustomerWO.length - 1) {
      return;
    } else {
      setWOIndex((prevState) => prevState + 1);
      setCount(1);
      setCurrentIndex(0);
    }
  };

  /*   All Function
   ********************************************* */
  const handleWOStatusChange = async (e) => {
    setAllCustomerWO((prev) => {
      let selectedIndex = prev.indexOf(selectedWorkOrder);
      prev[selectedIndex].WOStatus = e.target.value;
      return prev;
    });

    setIsLoading(true);
    if (!selectedWorkOrder) {
      return alert("workOrder not selected");
    }
    try {
      const { data } = await API.patch(
        "/work-orders",
        {
          data: { WOStatus: e.target.value },
          id: selectedWorkOrder?.id,
        },
        { headers: authHeader() }
      );

      if (data.message.type === "success") {
        // setSelectedWorkOrder((prev) => {
        //   return { ...prev, WOStatus: e.target.value };
        // });
        // setAllCustomerWO((prev) => {
        //   let selectedIndex = prev.indexOf(selectedWorkOrder);
        //   console.log(selectedIndex);
        //   prev[selectedIndex].WOStatus = e.target.value;
        //   return prev;
        // });

        // if (groupBy) {
        //   let groupedData = updatedData.filter(
        //     (item) => item.WOStatus === groupBy
        //   );
        //   setAllCustomerWO(groupedData);
        // } else {
        //   setAllCustomerWO(updatedData);
        // }
        setIsLoading(false);
        return;
      } else {
        dispatch(
          setNotification({
            message: "Something Else",
            type: "error",
          })
        );
      }
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      setIsLoading(false);
    }
  };

  const getWorkOrdersProducts = async (WOId) => {
    setIsLoading(true);
    try {
      const { data } = await API.get(`/wo-products/${WOId}`, {
        headers: authHeader(),
      });

      setWOProductsData(data);
      setAllSizes(
        data?.productSizes
          ? typeof data?.productSizes === "string"
            ? JSON.parse(data?.productSizes)
            : data?.productSizes
          : []
      );
      setIsLoading(false);
      return data;
    } catch (err) {
      setIsLoading(false);
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const getAllProductTypes = async () => {
    try {
      const { data } = await API.get("/productTypes", {
        headers: authHeader(),
      });
      setProductTypesData(data);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const handleProductTypeSelection = async (e) => {
    let selectedType = e.target.value;

    if (!generalData) {
      return;
    }

    setIsLoading(true);
    let updateData = {
      id: generalData.id,
      productType: selectedType,
    };

    try {
      const { data } = await API.patch(
        "/wo-products",
        { data: updateData },
        {
          headers: authHeader(),
        }
      );
      if (data?.message?.type === "success") {
        // setGeneralData((prev) => {
        //   return { ...prev, productType: selectedType };
        // });
        setWOProductsData((prev) => {
          prev[currentIndex]["productType"] = selectedType;
          return prev;
        });
        setIsLoading(false);
      }

      if (data?.message?.type === "error") {
        dispatch(
          setNotification({
            message: data?.message?.message,
            type: "error",
          })
        );
        setIsLoading(false);
      }
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      setIsLoading(false);
    }
  };

  const prevProduct = () => {
    if (currentIndex == 0) {
      return;
    } else {
      setCurrentIndex((prevState) => prevState - 1);
      setCount((prev) => prev - 1);
    }
  };

  const nextProduct = () => {
    if (currentIndex == WOProductsData.length - 1) {
      return;
    } else {
      setCurrentIndex((prevState) => prevState + 1);
      setCount((prev) => prev + 1);
    }
  };
  /*   All UseEffects
   ********************************************* */

  useEffect(() => {
    if (customerWO) {
      setAllCustomerWO(customerWO);
      customerWO?.map((item, i) => {
        if (item.id === selectedWOId) {
          setWOIndex(i);
          setSelectedWorkOrder(item);
        }
      });
    }
  }, []);

  // useEffect(() => {
  //   if (allCustomerWO && selectedWOId) {
  //     allCustomerWO?.map((item, i) => {
  //       if (item.id === selectedWOId) {
  //         setWOIndex(i);
  //         setSelectedWorkOrder(item);
  //       }
  //     });
  //   }
  // }, [allCustomerWO, selectedWOId]);

  useEffect(() => {
    if (WOIndex !== null && allCustomerWO) {
      setSelectedWorkOrder(allCustomerWO[WOIndex]);
      setWOId(allCustomerWO[WOIndex]?.id);
    }
  }, [WOIndex, allCustomerWO]);

  useEffect(() => {
    if (!productTypesData) {
      getAllProductTypes();
    }
  }, [productTypesData]);

  useEffect(() => {
    if (selectedWorkOrder) getWorkOrdersProducts(selectedWorkOrder?.id);
  }, [selectedWorkOrder]);

  useEffect(() => {
    if (WOProductsData) {
      let selected = WOProductsData[currentIndex];

      setGeneralData(selected);
      setAllSizes(
        selected?.productSizes
          ? typeof selected?.productSizes === "string"
            ? JSON.parse(selected?.productSizes)
            : selected?.productSizes
          : []
      );
    }
  }, [currentIndex, WOProductsData]);
  return (
    <div>
      {isLoading && <Spinner />}
      <Toast />
      <div className="mb-1 mx-1 me-2 d-flex justify-content-between">
        <h4>
          <button
            onClick={() => {
              setWorkOrderContext(selectedWorkOrder);
            }}
            className={`btn btn-sm bg-info text-white mx-1 my-0 `}
          >
            View WorkOrder
          </button>

          <span>
            {selectedWorkOrder?.id} -{" "}
            {selectedWorkOrder?.jobName?.length <= 35
              ? selectedWorkOrder?.jobName
              : `${selectedWorkOrder?.jobName?.slice(0, 35)}..`}
          </span>
        </h4>
        <div className="d-flex justify-content-end align-items-center flex-grow-1">
          <h5 className="me-1">WO Status</h5>
          <div className="me-1 ">
            <select
              disabled={isLoading}
              value={selectedWorkOrder?.WOStatus}
              onChange={handleWOStatusChange}
              className="form-select"
            >
              <option value="pending">Pending</option>
              <option value="processing">Processing</option>
              <option value="completed">Completed</option>
            </select>
          </div>
          <div className="d-flex gap-1">
            {selectedWorkOrder && (
              // <PrintProductPdf
              //   btnClass={"btn btn-success ms-1"}
              //   btnTitle={"Print Pdf"}
              //   selectedWorkOrder={selectedWorkOrder}
              // />
              <WOPdfButton
                btnClasses={"btn btn-success ms-1"}
                btnTitle={"Print Pdf"}
                selectedWO={selectedWorkOrder}
              />
            )}
            <button
              onClick={() => {
                prevWO();
              }}
              disabled={allCustomerWO?.length === 0 || isLoading}
              className={"btn btn-success btn-sm p-0 px-1 "}
            >
              <i className="mdi mdi-arrow-left-bold fs-3 py-0 my-0"></i>
            </button>
            <button
              onClick={() => {
                nextWO();
              }}
              disabled={allCustomerWO?.length === 0 || isLoading}
              className={"btn btn-success btn-sm p-0  px-1"}
            >
              <i className="mdi mdi-arrow-right-bold fs-3 py-0 my-0"></i>
            </button>

            <button
              className="btn btn-danger"
              onClick={() => {
                setShowProductsModal(false);
              }}
            >
              Back
            </button>
          </div>
        </div>
      </div>
      <hr className="mb-0" />
      <div className="d-flex">
        {/*   Left Panel
         ********************************************* */}
        <div
          className=" bg-white shadow p-2 w-50  mb-2 me-2"
          style={{ borderRadius: 15 }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <h3>
              Product
              {` {${WOProductsData?.length === 0 ? " 0 " : ` ${count} `} of ${
                WOProductsData?.length
              } }`}
            </h3>
            <div className="d-flex gap-1">
              {/*   WO Product Buttons
               ********************************************* */}
              <button
                onClick={() => {
                  prevProduct();
                }}
                disabled={WOProductsData?.length === 0}
                className={"btn btn-success btn-sm p-0 px-1 "}
              >
                <i className="mdi mdi-arrow-left-bold fs-3 py-0 my-0"></i>
              </button>
              <button
                onClick={() => {
                  nextProduct();
                }}
                disabled={WOProductsData?.length === 0}
                className={"btn btn-success btn-sm p-0 px-1"}
              >
                <i className="mdi mdi-arrow-right-bold fs-3 py-0 my-0"></i>
              </button>
            </div>
          </div>
          <div className=" p-1">
            <div className="row mb-1">
              <label className="col-5">Product Type</label>
              <select
                disabled={!generalData}
                value={generalData?.productType ? generalData.productType : ""}
                className="col-7 w-50 form-control w-fit"
                onChange={handleProductTypeSelection}
              >
                {productTypesData &&
                  productTypesData?.map((item) => (
                    <option key={item?.id} value={item?.productTypes}>
                      {item?.productTypes}
                    </option>
                  ))}
              </select>
            </div>
            <div className="row mb-1">
              <label className="col-5">Product Style</label>
              <div className="col-7 d-flex align-items-center">
                {generalData?.productStyle}
              </div>
            </div>
            <div className="row mb-1">
              <label className="col-5">Product Description</label>
              <div className="col-7 ">{generalData?.productDescription}</div>
            </div>
            <div className="row mb-1">
              <label className="col-5">Product Fabric</label>
              <div className="col-7 ">{generalData?.productFabric}</div>
            </div>
            <div className="row mb-1">
              <label className="col-5">Product Color</label>
              <div className="col-7 ">{generalData?.productColor}</div>
            </div>

            <div className="row mb-1">
              <label className="col-5">Team Name</label>
              <div className="col-7 ">{generalData?.teamName}</div>
            </div>

            <div className="row">
              <div className=" col-5 d-flex mb-1">
                <label className="text-nowrap ">Has Names</label>
                <div
                  className="d-flex form-switch "
                  style={{ paddingLeft: 48 }}
                >
                  <input
                    type="checkbox"
                    checked={generalData?.hasName ? true : false}
                    readOnly
                    className="form-check-input "
                    style={{ width: 40 }}
                  />
                </div>
              </div>
              <div className="col-7 d-flex mb-1">
                <label className="text-nowrap">Has Numbers</label>
                <div
                  className="d-flex form-switch "
                  style={{ paddingLeft: 48 }}
                >
                  <input
                    type="checkbox"
                    checked={generalData?.hasNumbers ? true : false}
                    readOnly
                    className="form-check-input "
                    style={{ width: 40 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*   Right Panel
         ********************************************* */}
        <div className="w-50 flex-grow-1 pb-2 d-flex flex-column">
          <div
            className="bg-white shadow px-3 mb-2 me-2 d-flex flex-column py-2"
            style={{ borderRadius: 15, height: 250 }}
          >
            <div className="" style={{ overflowY: "scroll", height: "99%" }}>
              <div className="table-responsive  ">
                <table className="table ">
                  <thead>
                    <tr>
                      <th scope="col">Size</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allSizes?.map((item, i) => (
                      <tr key={i}>
                        <td className="py-0">{item?.size?.toUpperCase()}</td>
                        <td className="py-0">{item?.quantity}</td>
                        <td className="py-0">
                          $ {parseFloat(item?.price).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="d-flex">
            <div
              className="d-flex justify-content-evenly flex-grow-1 py-1 align-items-center bg-white shadow me-2"
              style={{ borderRadius: 15 }}
            >
              <div>
                Total Qty
                <span className="border ms-1 px-2">
                  {parseFloat(generalData?.totalQuantity)}
                </span>
              </div>
              <div>
                Total Price
                <span className="border ms-1 px-2">
                  $ {parseFloat(generalData?.totalPrice).toFixed(2)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Bottom Pane */}
      <div className="d-flex">
        {/*   Image select
         ********************************************* */}
        <div
          className="bg-white shadow p-2 w-50 mb-2 me-2 flex-center"
          style={{ borderRadius: 15, minHeight: 300 }}
        >
          {!isLoading ? (
            <div>
              {generalData?.productImage ? (
                <img
                  width={500}
                  className=" mb-2"
                  height={300}
                  src={generalData?.productImage}
                  alt="product image"
                />
              ) : (
                <div className="fs-3 text-muted  text-center my-auto">
                  NO Image
                </div>
              )}
            </div>
          ) : (
            <Spinner />
          )}
        </div>

        {/*   Notes
         ********************************************* */}
        <div
          className="bg-white shadow p-2 w-50 mb-2 me-2 d-flex flex-column justify-content-between "
          style={{ borderRadius: 15, minHeight: 300 }}
        >
          <div className="">
            <ReactQuill
              modules={modules}
              theme="snow"
              value={generalData?.notes}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerWOProduct;
