import React from 'react';
import Breadcrumbs from '../components/Breadcrumbs';
import NoMatchImage from '../assets/images/svg/file-searching.svg';

function NoMatch() {
  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <div className='page-title-box'>
            <Breadcrumbs current='404 Error' />
            <h4 className='page-title'>404 Error</h4>
          </div>
        </div>
      </div>

      <div className='row justify-content-center'>
        <div className='col-lg-4'>
          <div className='text-center'>
            <img src={NoMatchImage} height='90' alt='File not found' />

            <h1 className='text-error mt-4'>404</h1>
            <h4 className='text-uppercase text-danger mt-3'>Page Not Found</h4>
            <p className='text-muted mt-3'>
              It's looking like you may have taken a wrong turn. Don't worry... it happens to the
              best of us. Here's a little tip that might help you get back on track.
            </p>

            <a className='btn btn-info mt-3' href='/dashboard'>
              <i className='mdi mdi-reply'></i> Return Home
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default NoMatch;
