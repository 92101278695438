import Table from 'components/DataTable/Table';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import authHeader from 'services/auth-header';
import API from 'services/axios';
import LocationsAdd from './smsSettingsAdd';
import LocationsUpdate from './smsSettingsUpdate';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';

function SmsSettings() {
  const user = useSelector((state) => state.user.userInfo);
  /*   All Satates Below
   ********************************************* */
  const [smsSettings, setSmsSettings] = useState([]);

  // Modals
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  // below update data is used to set a varible before moving to update component
  const [updateData, setUpdateData] = useState(null);

  const [selectedDelete, setSelectedDelete] = useState();

  /*   All Functions Below
   ********************************************* */
  // get All Locations
  const getAllSmsSettings = async () => {
    try {
      const { data } = await API.get('/smssettings', { headers: authHeader() });
      setSmsSettings(data);
    } catch (err) {
      alert(err.message);
    }
  };

  // Delete password Data
  const deleteSmsSettings = async (id) => {
    const { data } = await API.delete('/smssettings/delete/' + id, { headers: authHeader() });
    if (data.message.type == 'success') {
      getAllSmsSettings();
      return;
    }
  };

  useEffect(() => {
    getAllSmsSettings();
  }, []);

  /*   Table Functions below
   ********************************************* */
  const buttons = () => (
    <div className='d-flex justify-content-end ms-2'>
      {smsSettings.length == 0 && (
        <button onClick={() => setShowAddModal(true)} className='btn btn-primary'>
          ADD NEW
        </button>
      )}
    </div>
  );

  const table_head = ['Telnxy Phone Number', 'Telnyx Api Key', 'Action'];
  const table_body = (item, index) => (
    <tr key={item.id}>
      <td>{item.phoneNumber}</td>

      <td>{item.apiKey}</td>

      <td className='table-action'>
        <Link className='action-icon'>
          <i
            onClick={() => {
              setShowUpdateModal(true);
              setUpdateData(item);
            }}
            className='mdi mdi-pencil'
          ></i>
        </Link>
        <Link className='action-icon'>
          <i
            data-bs-toggle='modal'
            data-bs-target='#danger-alert-modal'
            className='mdi mdi-delete'
            onClick={() => setSelectedDelete(item)}
          ></i>
        </Link>
      </td>
    </tr>
  );

  return (
    <div className='row'>
      <h3>Sms Settings</h3>
      <div className='col-12'>
        <Table
          btn1={buttons}
          table_body={table_body}
          table_data={smsSettings}
          table_head={table_head}
        />

        {/*   ADD Modal
         ********************************************* */}
        <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
          <Modal.Body className='custom-shadow'>
            <div>
              <LocationsAdd showAddPage={setShowAddModal} refreshFunc={getAllSmsSettings} />
            </div>
          </Modal.Body>
        </Modal>
        {/*   Update Modal
         ********************************************* */}
        <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
          <Modal.Body className='custom-shadow'>
            <div>
              <LocationsUpdate
                data={updateData}
                showUpdatePage={setShowUpdateModal}
                refreshFunc={getAllSmsSettings}
              />
            </div>
          </Modal.Body>
        </Modal>
        {/*   Confirmation Modal
         ********************************************* */}

        <div
          id='danger-alert-modal'
          className='modal fade'
          tabIndex='-1'
          role='dialog'
          aria-hidden='true'
        >
          <div className='modal-dialog modal-sm '>
            <div className='modal-content modal-filled bg-danger'>
              <div className='modal-body p-4'>
                <div className='text-center'>
                  <i className='ri-close-circle-line h1'></i>
                  <h4 className='mt-2'>Confirm Delete!</h4>
                  <p className='mt-3'>Do You want to delete ?</p>
                  <button type='button' className='btn btn-light my-2 mx-2' data-bs-dismiss='modal'>
                    Cancel
                  </button>
                  <button
                    type='button'
                    className='btn btn-outline-light my-2 '
                    data-bs-dismiss='modal'
                    onClick={() => {
                      deleteSmsSettings(selectedDelete.id);
                    }}
                  >
                    DELETE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SmsSettings;
