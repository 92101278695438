import { Document, Page, StyleSheet } from "@react-pdf/renderer";
import TeamViewPdfHeader from "./TeamViewPdfHeader";
import TeamViewPdfInfo from "./TeamViewPdfInfo";
import TeamViewPdfProducts from "./TeamViewPdfProducts";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    padding: 20,
  },
  topHeader: {
    height: 40,
  },
  shippingDescription: {
    marginVertical: 0,
  },
  headingMain: { fontSize: 20, fontWeight: "extrabold" },
  headingSecond: { fontSize: 12, fontWeight: "extrabold" },
  headingThird: { fontSize: 15, fontWeight: "extrabold" },
  textbody: { fontSize: 10 },
  mainContainer: {
    marginBottom: "10px",
    flexDirection: "column",
    border: "1px solid grey",
  },
  prodTitle: {
    width: "100%",
    textAlign: "left",
    padding: "5px 10px",

    borderBottom: "1px solid grey",
    fontSize: "14px",
  },
  prodMain: {
    width: "100%",
    flexDirection: "row",
  },
  prodContent: {
    flexGrow: 1,
  },
  prodSizes: {
    padding: "5px",
    width: "150px",
    borderLeft: "1px solid grey",
  },
  prodInfoRow: {
    display: "flex",
    flexDirection: "row",
    fontSize: "10px",
  },
  prodCol: {
    display: "flex",
    flexDirection: "row",
    width: "50%",
  },
  prodKey: {
    padding: "3px 5px",
    width: "40%",
    borderRight: "1px solid grey",
    borderBottom: "1px solid grey",
  },
  prodVal: {
    padding: "3px 5px",
    width: "60%",
    borderBottom: "1px solid grey",
  },
  prodComments: {
    padding: "5px 10px",
    fontSize: "12px",
    borderTop: "1px solid grey",
  },
  prodSubHeading: {
    fontSize: "12px",
  },
  prodTxt: {
    fontSize: "10px",
  },
  borderRight: { borderRight: "1px solid grey" },
  colWithLong: {
    width: "200px",
    marginTop: 5,
    marginBottom: 3,
  },
  colWith: {
    width: "60px",
    marginTop: 5,
    marginBottom: 3,
    marginHorizontal: 5,
  },
  colWithMd: {
    width: "40px",
    marginTop: 5,
    marginBottom: 3,
  },
  colWithSm: {
    width: "30px",
    marginTop: 5,
    marginBottom: 3,
  },
  colWithXs: {
    width: "10px",
    marginTop: 5,
    marginBottom: 3,
  },
});

// Create Document Component
export const TeamViewPdf = ({
  selectedWO,
  productsList,
  locationLogo,
  showInvoicePage,
}) => {
  /*   ALL STATES
   ********************************************* */

  /*   ALL FUnctions
   ********************************************* */

  /*   All Use Effects
   ********************************************* */

  return (
    <>
      <Document title={selectedWO?.id}>
        <Page size="A4" style={styles.page}>
          <TeamViewPdfHeader
            logo={locationLogo}
            selectedWO={selectedWO}
            styles={styles}
          />

          {/* <TeamViewPdfInfo styles={styles} selectedWO={selectedWO} /> */}

          <TeamViewPdfProducts
            styles={styles}
            productsList={productsList}
            selectedWO={selectedWO}
          />
          {/* {showTotal && (
            <QuotePdfFooter styles={styles} selectedQuote={selectedQuote} />
          )} */}
        </Page>
      </Document>
    </>
  );
};
