import Spinner from "components/Spinner";
import Toast from "components/Toast/ToastComponent";
import { setNotification } from "features/Notification/notificationSlice";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function BillingAddress({ selectedWorkOrder, refreshFunc }) {
  const dispatch = useDispatch();

  /*   All States
   ********************************************* */
  const [showUpdateModal, setShowUpdateModal] = useState(null);
  const [billingAddress, setBillingAddress] = useState(null);

  const [formData, setFormData] = useState(null);

  const [loading, setLoading] = useState(false);

  /*   All Functions
   ********************************************* */
  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  const handleUpdateBillingAddress = async (e) => {
    e.preventDefault();
    try {
      loadingOn();
      let billingAddressData = {
        description: formData?.description,
        addressCompany: formData?.addressCompany,
        addressLine1: formData?.addressLine1,
        addressLine2: formData?.addressLine2,
        city: formData?.city,
        state: formData?.state,
        zip: formData?.zip,
        country: formData?.country,
      };
      let apiData = {
        id: selectedWorkOrder?.id,
        data: { billingAddress: billingAddressData },
      };
      const { data } = await API.patch("/work-orders", apiData, {
        headers: authHeader(),
      });
      if (data?.message?.type === "success") {
        await refreshFunc();
        loadingOff();
        setShowUpdateModal(false);
        return;
      }
      console.log(data);
      alert("something went wrong check logs");
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    setBillingAddress(
      selectedWorkOrder?.billingAddress &&
        typeof selectedWorkOrder?.billingAddress === "string"
        ? JSON.parse(selectedWorkOrder?.billingAddress)
        : selectedWorkOrder?.billingAddress
    );
    if (showUpdateModal) {
      setFormData(
        selectedWorkOrder?.billingAddress &&
          typeof selectedWorkOrder?.billingAddress === "string"
          ? JSON.parse(selectedWorkOrder?.billingAddress)
          : selectedWorkOrder?.billingAddress
      );
    }
  }, [selectedWorkOrder, showUpdateModal]);

  return (
    <div className="bg-white shadow-lg w-50 px-3 pb-2 custom-border-radius-sm">
      {loading && <Spinner />}
      {/*   WO Info
       ********************************************* */}
      <div className="d-flex inline-block justify-content-between align-items-center">
        <h4 className="mb-0">Billing Address</h4>
        <h4 className="mb-0">
          <button
            type="button"
            className="btn btn-info btn-sm"
            onClick={() => setShowUpdateModal(true)}
          >
            Edit
          </button>
        </h4>
      </div>
      <hr className="mt-0 mb-1 w-50" />
      {/* Job Name */}
      <div className="row">
        <div className="col-4 fw-bold"> Description</div>
        <div className="col-8">{billingAddress?.description}</div>
      </div>
      <div className="row">
        <div className="col-4 fw-bold"> Company</div>
        <div className="col-8">{billingAddress?.addressCompany}</div>
      </div>
      <div className="row">
        <div className="col-4 fw-bold">Address Line 1</div>
        <div className="col-8">{billingAddress?.addressLine1}</div>
      </div>
      <div className="row">
        <div className="col-4 fw-bold">Address Line 2</div>
        <div className="col-8">{billingAddress?.addressLine2}</div>
      </div>
      <div className="row">
        <div className="col-4 fw-bold">City | State | Zip</div>
        <div className="col-8">
          {billingAddress?.city} | {billingAddress?.state} |{" "}
          {billingAddress?.zip}
        </div>
      </div>
      <div className="row">
        <div className="col-4 fw-bold">Country</div>
        <div className="col-8">{billingAddress?.country}</div>
      </div>

      {/*   Customer Select Modal
       ********************************************* */}
      <Modal
        contentClassName="bg-transparent"
        show={showUpdateModal}
        onHide={() => setShowUpdateModal(false)}
      >
        <Modal.Body>
          <div
            className="bg-white shadow-lg px-3 pb-2 pt-2 mb-2 me-2"
            style={{ borderRadius: 20 }}
          >
            <div className="fw-bold fs-4 mt-3 d-flex justify-content-between">
              <div>Billing Address </div>
            </div>
            <hr className="my-0 mb-2" />
            <form onSubmit={handleUpdateBillingAddress} className=" p-1">
              <div className="row mb-2">
                <label className="col-4 form-label">Description</label>
                <div className="col-8 ">
                  <input
                    className="form-control  flex-grow-1 mx-0"
                    placeholder="Enter  description"
                    type="text"
                    value={formData?.description || ""}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        description: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row mb-2">
                <label className="col-4 form-label"> Company</label>
                <div className="col-8 ">
                  <input
                    className="form-control flex-grow-1 mx-0"
                    placeholder="Enter address company"
                    type="text"
                    value={formData?.addressCompany || ""}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        addressCompany: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row mb-2">
                <label className="col-4 form-label">Address Line 1</label>
                <div className="col-8 ">
                  <input
                    className="form-control flex-grow-1 mx-0"
                    placeholder="Address Line 1"
                    type="text"
                    value={formData?.addressLine1 || ""}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        addressLine1: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row mb-2">
                <label className="col-4 form-label">Address Line 2</label>
                <div className="col-8 ">
                  <input
                    className="form-control flex-grow-1 mx-0"
                    placeholder="Address Line 2"
                    type="text"
                    value={formData?.addressLine2 || ""}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        addressLine2: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row mb-2">
                <label className="col-4 form-label">City | State | Zip</label>
                <div className="col-8 ">
                  <div className="d-flex justify-content-start align-items-center gap-1">
                    <input
                      className="form-control "
                      placeholder="City"
                      type="text"
                      style={{ width: "50%" }}
                      value={formData?.city || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          city: e.target.value,
                        })
                      }
                    />
                    <input
                      className="form-control "
                      placeholder="State"
                      type="text"
                      style={{ width: "20%" }}
                      value={formData?.state || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          state: e.target.value,
                        })
                      }
                    />
                    <input
                      className="form-control "
                      placeholder="Zip"
                      type="text"
                      style={{ width: "30%" }}
                      value={formData?.zip || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          zip: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-2">
                <label className="col-4 form-label">Country</label>
                <div className="col-8 ">
                  <input
                    className="form-control flex-grow-1 mx-0"
                    placeholder="Country"
                    type="text"
                    value={formData?.country || ""}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        country: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="text-end mt-3">
                <button
                  onClick={() => {
                    setShowUpdateModal(false);
                  }}
                  type="button"
                  className="btn btn-secondary"
                >
                  Close
                </button>
                <button type="submit" className="btn btn-success ms-1">
                  Save
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
