import ProductWiseItem from "components/DisplayBoard/ProductWiseItem";
import Spinner from "components/Spinner";
import Toast from "components/Toast/ToastComponent";

export default function JobDashBoard({
  selectedLocation,
  searchText,
  productTypes,
  productTypesData,
  refreshStatusList,
  setRefreshStatusList,
}) {
  /*   All States
   ********************************************* */

  /*   All Functions
   ********************************************* */

  /*   All UseEffects
   ********************************************* */

  if (!productTypes) return <Spinner />;

  return (
    <div
      className="row justify-content-evenly scrollbar-hide mx-auto overflow-scroll"
      style={{ height: "85vh", marginTop: 60 }}
    >
      <Toast />
      {productTypes.map((key) => (
        <ProductWiseItem
          key={key}
          filterBy={key}
          selectedLocation={selectedLocation}
          filterText={searchText}
          productTypesData={productTypesData}
          refreshStatusList={refreshStatusList}
          setRefreshStatusList={setRefreshStatusList}
        />
      ))}
    </div>
  );
}
