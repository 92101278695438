import Spinner from "components/Spinner";
import React, { useEffect, useState } from "react";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function ArtImageWIthSignedUrl({
  path,
  width,
  height,
  alt,
  imgClasses,
  imgStyle,
}) {
  const [url, setUrl] = useState(null);

  const getImageUrl = async () => {
    const { data } = await API.post(
      "/art-approval/get-image",
      { path },
      {
        headers: authHeader(),
      }
    );
    setUrl(data);
    return data;
  };

  useEffect(() => {
    getImageUrl();
  }, [path]);
  return (
    <>
      {!url && <Spinner />}
      <img
        src={url}
        width={width && width}
        height={height && height}
        alt={alt && alt}
        className={`${imgClasses && imgClasses}`}
        style={imgStyle && imgStyle}
      />
    </>
  );
}
