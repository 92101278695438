import Spinner from "components/Spinner";
import Toast from "components/Toast/ToastComponent";
import { setNotification } from "features/Notification/notificationSlice";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function CategorySelect({
  categoriesList,
  setCategoriesList,
  showModal,
}) {
  const dispatch = useDispatch();
  /*   All States
   ********************************************* */

  const [loading, setLoading] = useState(false);
  const [mockupCategories, setMockupCategories] = useState(null);

  /*   All Functions
   ********************************************* */
  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  const getAllMockupCategories = async () => {
    try {
      loadingOn();
      const { data } = await API.get("/mockup-categories", {
        headers: authHeader(),
      });

      if (data?.type === "success") {
        let resultData = data.data;
        setMockupCategories(resultData);
      }
      if (data?.type === "error") {
        dispatch(
          setNotification({
            message: data?.message,
            type: "error",
          })
        );
      }
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const handleChange = (id) => {
    let newList = new Set(categoriesList);
    if (newList.has(id)) {
      newList.delete(id);
    } else {
      newList.add(id);
    }
    setCategoriesList([...newList]);
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    getAllMockupCategories();
  }, []);
  return (
    <div className="">
      {loading && <Spinner />}
      <Toast />
      <h3>Select Categories</h3>

      <div className="my-3">
        {mockupCategories &&
          mockupCategories?.map((category) => (
            <div
              onClick={() => {
                handleChange(category?.id);
              }}
              key={category.id}
              className="d-flex gap-2 align-items-center"
            >
              <input
                checked={categoriesList?.includes(category.id)}
                type="checkbox"
              />
              <div className="">{category?.name}</div>
            </div>
          ))}
      </div>
      <div className="mt-3 text-end">
        <button
          onClick={() => {
            showModal(false);
          }}
          type="button"
          className="btn btn-sm btn-success"
        >
          Done
        </button>
      </div>
    </div>
  );
}
