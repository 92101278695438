import { generatePassword } from 'features/passwordGeneraotr/passwordSlice';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import authHeader from 'services/auth-header';
import API from 'services/axios';

function CompanyPasswordAdd({ showAddPage, filterBy, refreshFunc }) {
  /*   All Satates Below
   ********************************************* */
  const { register, handleSubmit } = useForm();
  const [formError, setFormError] = useState({});
  const [randomPassword, setRandompassword] = useState('');
  const { password } = useSelector((state) => state.password);
  const [usersList, setUsersList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchUserText, setSearchUserText] = useState('');

  // redux
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.userInfo);

  /*   All Functions Below
   ********************************************* */
  const addCompanyData = async (formData, e) => {
    setFormError({});
    e.preventDefault();

    const userId = JSON.parse(localStorage.getItem('user')).id;
    const submitData = {
      ...formData,
      passwordValue: randomPassword,
      userId,
      companyId: user.companyId,
      passwordType: filterBy,
      availableTo: `[${user.id}]`,
    };

    try {
      const { data } = await API.post(
        '/insertpassword',
        { data: submitData },
        { headers: authHeader() }
      );
      if (data.message.type === 'success') {
        refreshFunc();
        showAddPage(false);
        return;
      }
    } catch (err) {
      alert(err.message);
    }
  };

  const handleUserClick = (clickedUser) => {
    setSelectedUsers(
      selectedUsers.includes(clickedUser)
        ? selectedUsers.filter((item) => item !== clickedUser)
        : [...selectedUsers, clickedUser]
    );
  };

  // Get all users with role = users
  const getUsers = async () => {
    try {
      const { data } = await API.get('/password/users', {
        headers: authHeader(),
      });

      if (data.message?.type === 'error') {
        alert(data?.message?.message);
        return;
      }
      setUsersList(data);
    } catch (error) {
      alert(error.message);
    }
  };

  // generates random password using settings from Password Generator tabs
  const generateRandomPassword = async () => {
    dispatch(generatePassword());
    setRandompassword(password);
  };

  return (
    <div className='row'>
      <div className='col-12'>
        {/*    ADD FORM START
         ********************************************* */}
        <div className='mb-13 ms-3'>
          <h3>ADD COMPANY PASSWORD</h3>
        </div>
        <form className='ps-3 pe-3' onSubmit={handleSubmit(addCompanyData)}>
          {/* ----------  Fields  ----------- */}
          <div className='mt-3 mb-3'>
            <label htmlFor='title' className='form-label'>
              Title
            </label>
            <input
              className='form-control'
              type='text'
              id='title'
              placeholder='Enter title'
              required
              {...register('title')}
            />
          </div>
          <div className='mb-3'>
            <label htmlFor='password' className='form-label'>
              Url
            </label>
            <input
              className='form-control'
              type='text'
              id='url'
              placeholder='Enter url'
              {...register('url')}
            />
          </div>
          <div className='mb-3'>
            <label htmlFor='username' className='form-label'>
              Username
            </label>
            <input
              className='form-control'
              type='text'
              id='username'
              placeholder='Enter username'
              {...register('username')}
            />
          </div>

          <div className='mb-3'>
            <label htmlFor='password' className='form-label'>
              Password
            </label>
            <div className='d-flex' onChange={(e) => setRandompassword(e.target.value)}>
              <input
                className='form-control'
                type='text'
                id='password'
                value={randomPassword}
                placeholder='Enter password'
                {...register('passwordValue')}
              />
              <button
                onClick={generateRandomPassword}
                type='button'
                className='bg-success btn-sm btn text-white'
              >
                Random
              </button>
            </div>
          </div>

          <div
            className={`mt-4 mb-2 d-flex ${
              user.roles && user.roles != 'user' && filterBy != '_private'
                ? 'justify-content-between'
                : 'justify-content-end'
            }`}
          >
            {user.roles && user.roles != 'user' && filterBy != '_private' && (
              <div>
                <button
                  className='btn btn-light dropdown-toggle'
                  type='button'
                  id='dropdownMenuButton'
                  data-bs-toggle='dropdown'
                  aria-haspopup='true'
                  onClick={() => getUsers()}
                  aria-expanded='false'
                >
                  Allowed To
                </button>
                <div
                  className='dropdown-menu w-50 shadow-lg'
                  aria-labelledby='dropdownMenuButt on'
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className='d-flex justify-content-center flex-column  m-2 '>
                    <input
                      onChange={(e) => {
                        setSearchUserText(e.target.value);
                      }}
                      value={searchUserText}
                      className='form-control mb-2'
                      type={'search'}
                      placeholder='Search users'
                    />
                    {usersList &&
                      !searchUserText &&
                      usersList.map((item) => (
                        <div key={item.id} className='form-check form-switch mb-1'>
                          <input
                            type='checkbox'
                            className='form-check-input'
                            onChange={() => handleUserClick(item.id)}
                          />
                          <label className='form-check-label'>
                            {`${item.firstName} ${item.lastName}`}
                          </label>
                        </div>
                      ))}
                    {usersList &&
                      searchUserText &&
                      usersList
                        .filter((o) =>
                          Object.keys(o).some((k) => {
                            if (typeof o[k] == 'string') {
                              return o[k]
                                .toString()
                                .toLowerCase()
                                .includes(searchUserText.toLowerCase());
                            } else if (o[k] != null) {
                              o[k].toString().toLowerCase().includes(searchUserText.toLowerCase());
                            }
                          })
                        )
                        .map((item) => (
                          <div key={item.id} className='form-check form-switch mb-1'>
                            <input
                              type='checkbox'
                              className='form-check-input'
                              onChange={() => handleUserClick(item.id)}
                            />
                            <label className='form-check-label'>
                              {`${item.firstName} ${item.lastName}`}
                            </label>
                          </div>
                        ))}
                  </div>
                </div>
              </div>
            )}
            <div>
              <button
                className='btn px-1 btn-secondary'
                type='button'
                onClick={() => {
                  showAddPage(false);
                }}
              >
                Cancel
              </button>
              <button className='btn btn-primary ms-1' type='submit'>
                ADD
              </button>
            </div>
          </div>
        </form>

        {/*    ADD FORM END
         ********************************************* */}
      </div>
    </div>
  );
}

export default CompanyPasswordAdd;
