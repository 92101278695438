import Table from "components/DataTable/Table";
import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import DeleteModalButton from "pages/artApproval/Mockups/components/DeleteButton/DeleteModalButton";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import authHeader from "services/auth-header";
import API from "services/axios";
import AttSelectDevice from "./AttSelectDevice";

export default function Attendance() {
  const user = useSelector((state) => state.user?.userInfo);
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();
  /*   ALL STATES
   ********************************************* */
  const [allAttendanceUsers, setAllAttendanceUsers] = useState(null);

  const [showDevicesListModal, setShowDevicesListModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  /*   All Functions
   ********************************************* */
  // Sync-Database
  const syncDatabase = async (id) => {
    loadingOn();
    try {
      const { data } = await API.get("/device-users-sync/" + id, {
        headers: authHeader(),
      });

      if (data?.success) {
        setAllAttendanceUsers(data?.data);
        dispatch(
          setNotification({
            message: data.message,
            type: "success",
          })
        );
      }
      if (!data?.success) {
        dispatch(
          setNotification({
            message: data.message,
            type: "error",
          })
        );
      }
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const getUsersData = async (id) => {
    loadingOn();
    try {
      const { data } = await API.get("/device-users-list/" + id, {
        headers: authHeader(),
      });

      if (data?.success) {
        setAllAttendanceUsers(data?.data);
      }
      if (!data?.success) {
        dispatch(
          setNotification({
            message: data.message,
            type: "error",
          })
        );
      }

      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    // If not deviceId in search params then get devices list
    let deviceId = searchParams.get("deviceId");
    if (!deviceId) {
      setShowDevicesListModal(true);
      return;
    }

    let clearTimeoutId = setTimeout(() => {
      getUsersData(deviceId);
    }, 100);
    return () => clearTimeout(clearTimeoutId);
  }, [searchParams]);

  /*   Table functions
   ********************************************* */
  const btn1 = () => (
    <div>
      {(user?.permissions?.includes("work_orders_add") ||
        user?.roles === "admin") && (
        <>
          <button
            onClick={() => {
              let deviceId = searchParams.get("deviceId");
              if (!deviceId) {
                return;
              }
              syncDatabase(deviceId);
            }}
            type="button"
            disabled={!searchParams.get("deviceId") || loading}
            className="btn btn-primary  ms-1"
          >
            Sync Database
          </button>
        </>
      )}
    </div>
  );

  const buttons = () => (
    <div className=" d-flex justify-content-end">
      <button
        onClick={(e) => {
          setShowDevicesListModal(true);
        }}
        className="btn btn-info mx-1"
      >
        Get Devices List
      </button>
    </div>
  );
  const table_head = ["ID", "User Name", "Role", "Card No"];

  const table_body = (item, index) => (
    <tr className="" key={item.id}>
      <td>{item.userId}</td>
      <td>{item.name}</td>
      <td>{item.role === "14" ? "Admin" : "User"}</td>
      <td>{item.cardno}</td>
      <td className="table-action">
        <Link
          to={`/attendance/user/${searchParams.get("deviceId")}/${
            item?.userId
          }/`}
          className="btn btn-sm btn-info"
        >
          Details
        </Link>
      </td>
    </tr>
  );

  return (
    <div
      className=""
      style={{
        marginLeft: 280,
        height: "90vh",
        paddingTop: 10,
        paddingRight: 30,
      }}
    >
      {loading && <Spinner />}
      <h1 className="mb-3">Attendance</h1>
      {/*   Table
       ********************************************* */}
      <div
        className="px-2 flex-grow-1 d-flex"
        style={{ minHeight: "50vh", borderRadius: 15 }}
      >
        <div className="horizontal-scrollable d-flex flex-grow-1">
          <Table
            buttons={buttons}
            btn1={btn1}
            table_head={table_head}
            table_body={table_body}
            table_data={allAttendanceUsers}
          />
        </div>
      </div>

      <Modal
        show={showDevicesListModal}
        onHide={(e) => {
          setShowDevicesListModal(false);
        }}
        contentClassName="bg-transparent"
        size="xl"
      >
        <Modal.Body className="bg-white custom-border-radius custom-shadow ps-3">
          <AttSelectDevice showModal={setShowDevicesListModal} />
          <div className="text-end me-3">
            <button
              onClick={(e) => {
                setShowDevicesListModal(false);
              }}
              className="btn btn-sm btn-secondary"
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
