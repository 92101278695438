import { Text, View } from "@react-pdf/renderer";
import { useEffect, useState } from "react";

export default function QuotePdfFooter({ styles, selectedQuote }) {
  /*   All States
   ********************************************* */
  const [totalPrice, setTotalPrice] = useState(null);
  const [total, setTotal] = useState(null);

  /*   All Functions
   ********************************************* */

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    if (selectedQuote) {
      const totalPrice = selectedQuote?.quoteProducts?.reduce((acc, item) => {
        let updatedPrice = parseFloat(
          parseFloat(acc) + parseFloat(item.price) * parseFloat(item.quantity)
        )?.toFixed(2);
        return updatedPrice;
      }, 0.0);
      console.log(totalPrice);

      setTotalPrice(totalPrice);
      setTotal(
        (
          parseFloat(totalPrice) +
          parseFloat(totalPrice) *
            (parseFloat(selectedQuote?.taxrate?.rate) || 0)
        ).toFixed(2)
      );
    }
  }, []);

  return (
    <View style={{ flexDirection: "row", justifyContent: "flex-end" }}>
      <View
        style={{
          width: "30%",
          marginVertical: 30,
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <View style={{ flexDirection: "row", gap: 3, alignItems: "center" }}>
          <View>
            <Text style={styles.headingSecond}>Subtotal</Text>
          </View>
          <View
            style={{
              border: 1.5,
              borderBottom: 0,

              marginLeft: 5,
              height: 15,
              width: "60%",
            }}
          >
            <Text style={[styles.headingSecond, { textAlign: "right" }]}>
              {parseFloat(totalPrice)?.toFixed(2)}
            </Text>
          </View>
        </View>
        <View style={{ flexDirection: "row", gap: 3, alignItems: "center" }}>
          <View>
            <Text style={[styles.headingSecond, { textAlign: "right" }]}>
              Tax Rate ( {selectedQuote?.taxrate?.rate || 0} )
            </Text>
          </View>
          <View
            style={{
              border: 1.5,
              borderBottom: 0,

              marginLeft: 5,
              height: 15,
              width: "60%",
            }}
          >
            <Text style={[styles.headingSecond, { textAlign: "right" }]}>
              {parseFloat(
                totalPrice * selectedQuote?.taxrate?.rate || 0
              ).toFixed(2)}
            </Text>
          </View>
        </View>
        <View style={{ flexDirection: "row", gap: 3, alignItems: "center" }}>
          <View>
            <Text style={[styles.headingSecond, { textAlign: "right" }]}>
              Total
            </Text>
          </View>
          <View
            style={{
              border: 1.5,
              marginLeft: 5,
              height: 18,
              width: "60%",
            }}
          >
            <Text style={[styles.headingSecond, { textAlign: "right" }]}>
              {parseFloat(total)?.toFixed(2)}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}
