import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function EmailToCustomer({
  showModal,
  link,
  getLink,
  selectedDesign,
}) {
  const dispatch = useDispatch();

  /*   All States
   ********************************************* */
  const [loading, setLoading] = useState(false);
  const [customerContacts, setCustomerContacts] = useState(null);
  const [emailTo, setEmailTo] = useState(selectedDesign?.email);
  const [showContactSelectModal, setShowContactSelectModal] = useState(false);

  /*   All Functions
   ********************************************* */
  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  //get Data
  const getCustomerContacts = async () => {
    try {
      loadingOn();
      const { data } = await API.post(
        "/customer-contacts",
        { id: selectedDesign?.customerId },
        {
          headers: authHeader(),
        }
      );
      if (data.type === "success") {
        setCustomerContacts(data?.data);
      } else {
        dispatch(setNotification({ type: "error", message: data.message }));
      }
      loadingOff();
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      loadingOff();
    }
  };

  const handleContactSelect = (contact) => {
    const parsedData = JSON.parse(contact?.data);
    setEmailTo(parsedData?.email);
    setShowContactSelectModal(false);
  };

  const sendEmail = async () => {
    try {
      loadingOn();
      if (!emailTo) {
        dispatch(
          setNotification({ type: "error", message: "Please select a contact" })
        );
        return;
      }
      const { data } = await API.post(
        "/mockup-email",
        {
          emailTo: emailTo,
          mockupTitle: selectedDesign?.title,
          locationLogo: selectedDesign?.customer?.location?.logo,
          locationId: selectedDesign?.customer?.locationId,
          products: JSON.stringify(selectedDesign?.products),
          link,
        },
        {
          headers: authHeader(),
        }
      );
      if (data.type === "success") {
        dispatch(setNotification({ type: "success", message: data.message }));
      } else {
        dispatch(setNotification({ type: "error", message: data.message }));
      }
      loadingOff();

      showModal(false);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );

      loadingOff();
    }
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    getLink({ showMsg: false });
    getCustomerContacts();
  }, []);

  if (!link) return <Spinner />;

  return (
    <div>
      {loading && <Spinner />}
      <div className="d-flex justify-content-between align-items-center">
        <h3>Send Email</h3>
        <div>
          <button
            onClick={() => {
              setShowContactSelectModal(true);
            }}
            className="btn btn-sm btn-info"
          >
            Choose Email
          </button>
        </div>
      </div>
      <hr className="mt-1" />
      <div className="my-2">
        <label htmlFor="emailTo">Email To</label>
        <input
          type="email"
          required
          placeholder="Enter Email"
          name="emailTo"
          value={emailTo || ""}
          onChange={(e) => {
            setEmailTo(e.target.value);
          }}
          className="form-control"
        />
      </div>
      <div className="d-flex justify-content-end gap-1 mt-3">
        <button
          onClick={() => {
            showModal(false);
          }}
          className="btn btn-sm btn-secondary"
        >
          Close
        </button>
        <button
          onClick={() => {
            sendEmail();
          }}
          disabled={!emailTo}
          className="btn btn-sm btn-success"
        >
          Send
        </button>
      </div>

      {/*   Choose Email
       ********************************************* */}
      <Modal
        show={showContactSelectModal}
        contentClassName="bg-transparent"
        onHide={() => setShowContactSelectModal(false)}
      >
        <Modal.Body
          className="shadow-lg bg-white px-3 "
          style={{ borderRadius: 15 }}
        >
          <div>
            <table className="table table-responsive w-full">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {customerContacts &&
                  customerContacts.map((customerContacts, i) => (
                    <tr key={customerContacts?.id}>
                      <th scope="row">{i + 1}</th>
                      <td>{`${JSON.parse(customerContacts?.data).firstName} ${
                        JSON.parse(customerContacts?.data).lastName
                      }`}</td>
                      <td>{JSON.parse(customerContacts?.data).email}</td>
                      <td>
                        <button
                          type="button"
                          onClick={(e) => {
                            handleContactSelect(customerContacts);
                          }}
                          className="btn btn-sm btn-success"
                        >
                          Select
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
