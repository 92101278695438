import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";
import AttUserDetail from "./UserDetails/AttUserDetail";
import AttUserSchedule from "./UserDetails/AttUserSchedule";
import { useParams } from "react-router-dom";
import AttRules from "./AttRules";

export default function UpdateAttUser({ showModal, userData, refreshFunc }) {
  /*   All States
   ********************************************* */
  const user = useSelector((state) => state.user?.userInfo);

  const [formData, setFormData] = useState({});
  const [activeTab, setActiveTab] = useState("userInfo");

  /*   All Functions
   ********************************************* */
  const dispatch = useDispatch();
  const params = useParams();

  const [loading, setLoading] = useState(false);

  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      loadingOn();
      const userFormData = new FormData();
      userFormData.append(
        "formData",
        JSON.stringify({
          ...formData,
          deviceId: params?.deviceId,
          weeklySchedule: JSON.stringify(formData?.weeklySchedule),
          rules: JSON.stringify(formData?.rules),
        })
      );

      userFormData.append("file", formData.file);
      userFormData.append(
        "folder",
        `kms/attendance/${user?.companyId}/${params?.deviceId}/${userData?.id}/profilePic`
      );

      const { data } = await API.patch(
        "/device-user-update/" + userData?.id,
        userFormData,
        {
          headers: { ...authHeader(), "Content-Type": "multipart/form-data" },
        }
      );
      if (data.success) {
        await refreshFunc();
        dispatch(
          setNotification({
            message: data.message,
            type: "success",
          })
        );
        showModal(false);
        loadingOff();
        return;
      }
      if (!data.success) {
        dispatch(
          setNotification({
            message: data.message,
            type: "error",
          })
        );
        showModal(false);
        loadingOff();
        return;
      }
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    setFormData({
      ...userData,
      weeklySchedule: JSON.parse(userData?.weeklySchedule) || {},
      rules: Object.keys(userData?.rules || {}).length
        ? JSON.parse(userData?.rules)
        : {
            overTime: {
              name: "Overtime Calculation",
              description:
                "Calculate overtime after expected hours + overtimeAfterMinutes",
              isChecked: true,
            },
            lateTime: {
              name: "Late Time Calculation",
              description:
                "Only calculate late time when inTime is greater than inTimeWithRelaxationFormatted. Count late time from userInTime when inTime is less than inTimeWithRelaxationFormatted",
              isChecked: true,
            },
          },
    });
  }, [userData]);

  return (
    <div>
      {loading && <Spinner />}
      <h3 className="mb-2">Update Attendance User</h3>
      <div className="mt-3 custom-shadow-sm" style={{ width: "fit-content" }}>
        <button
          onClick={() => setActiveTab("userInfo")}
          className={`btn   ${
            activeTab === "userInfo" ? "btn-primary" : "btn-light"
          }`}
        >
          User Info
        </button>
        <button
          onClick={() => setActiveTab("schedule")}
          className={`btn ${
            activeTab === "schedule" ? "btn-primary" : "btn-light"
          }`}
        >
          Schedule
        </button>
        <button
          onClick={() => setActiveTab("rules")}
          className={`btn ${
            activeTab === "rules" ? "btn-primary" : "btn-light"
          }`}
        >
          Rules
        </button>
      </div>
      <hr className="mt-2 mb-3" />

      <form onSubmit={handleSubmit}>
        {activeTab === "userInfo" && (
          <AttUserDetail
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
            refreshFunc={refreshFunc}
          />
        )}
        {activeTab === "schedule" && formData?.weeklySchedule && (
          <AttUserSchedule
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
          />
        )}
        {activeTab === "rules" && formData?.rules && (
          <AttRules
            formData={formData}
            setFormData={setFormData}
            handleChange={handleChange}
          />
        )}

        <div className="text-end mt-3">
          <button
            onClick={(e) => {
              showModal(false);
            }}
            type="button"
            className="btn btn-secondary me-1"
          >
            Close
          </button>
          <button type="submit" className="btn btn-primary">
            Update
          </button>
        </div>
      </form>
    </div>
  );
}
