import React, { memo } from "react";
import { useState } from "react";
import { createContext } from "react";
import { useContext } from "react";

const CompanyLogoContext = createContext({});

export const CompanyLogoProvider = ({ children }) => {
  /* *************  States  **************** */
  const [tempLogo, setTempLogo] = useState(null);

  /* ******************** CompanyLogo ************************* */

  const setTempLogoData = (url) => {
    if (url) {
      setTempLogo((prev) => url);
    }
  };

  const companyLogoValues = {
    tempLogo,
    setTempLogo,
    setTempLogoData,
  };
  return (
    <CompanyLogoContext.Provider value={companyLogoValues}>
      {children}
    </CompanyLogoContext.Provider>
  );
};

export default function useCompanyLogo() {
  return useContext(CompanyLogoContext);
}
