import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import authHeader from 'services/auth-header';
import API from 'services/axios';

function DiscountNameUpdate({ data, showUpdatePage, refreshFunc }) {
  /*   All Satates Below
   ********************************************* */
  const { register, handleSubmit } = useForm();
  const [formError, setFormError] = useState({});

  /*   All Functions Below
   ********************************************* */
  const updatePrintType = async (formData, e) => {
    setFormError({});
    e.preventDefault();
    if (formData.discountName === '') {
      setFormError({
        discountName: { type: 'discountName', message: 'Print name is required' },
      });
      return;
    }
    try {
      const resp = await API.patch(
        '/discountlevels/',
        { data: { ...formData, id: data.id } },
        { headers: authHeader() }
      );

      if (resp.data.message.type === 'success') {
        refreshFunc();
        showUpdatePage(false);
        return;
      }
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <div className='row'>
      <div className='col-12'>
        {/*    Update FORM START
         ********************************************* */}
        {data && (
          <form className='ps-3 pe-3' onSubmit={handleSubmit(updatePrintType)}>
            <div className='mb-3'>
              <h3>UPDATE {data?.discountName?.toUpperCase()}</h3>
            </div>
            {/* ----------  Fields  ----------- */}
            <div className='mb-3'>
              <label htmlFor='discountName' className='form-label'>
                Discount Name
              </label>
              <input
                className='form-control'
                type='text'
                id='discountName'
                defaultValue={data?.discountName}
                placeholder='Enter Discount Name'
                {...register('discountName')}
              />
              {formError && formError.discountName && (
                <p className='text-danger'>{formError.discountName.message}</p>
              )}
            </div>

            <div className={`mt-4 mb-2 d-flex justify-content-end`}>
              <div>
                <button
                  className='btn px-1 btn-secondary'
                  type='button'
                  onClick={() => {
                    showUpdatePage(false);
                  }}
                >
                  Cancel
                </button>
                <button className='btn btn-primary ms-1' type='submit'>
                  UPDATE
                </button>
              </div>
            </div>
          </form>
        )}
        {/*    Update FORM END
         ********************************************* */}
      </div>
    </div>
  );
}

export default DiscountNameUpdate;
