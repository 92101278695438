import React, { useEffect, useRef, useState } from "react";

export default function Electron() {
  /*   All States
   ********************************************* */
  let pathRef = useRef();

  const [selectedPcPath, setSelectedPcPath] = useState(null);
  const [newPath, setNewPath] = useState("");
  const [newFolderPath, setNewFolderPath] = useState(null);

  /*   All Functions
   ********************************************* */
  function handleOpenFolder(e) {
    try {
      let path = e.target.getAttribute("data-path");
      window.electronApi.openFolder(path);
    } catch (err) {
      console.log("Electron no loaded.");
    }
  }
  function handleSelectFolder(e) {
    try {
      console.log(window.electronApi);
      window.electronApi.selectFolder();
    } catch (err) {
      console.log("Electron no loaded.");
    }
  }

  const handlePathInput = (e) => {
    let value = e.target.value;
    setNewPath(value);

    let pathValue = window.path.join(selectedPcPath[0], value);

    if (value === "") {
      return setNewFolderPath(null);
    }
    if (window.electronApi) {
      setNewFolderPath(pathValue);
    } else {
      console.log("no electron path");
    }
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    if (window.electronApi) {
      window.electronApi.showSelectedFolder((folderPath) => {
        setSelectedPcPath(folderPath);
        setNewPath("");
        setNewFolderPath(folderPath);
      });
    }
  }, [pathRef]);
  return (
    <div
      className="d-flex flex-grow-1 "
      style={{
        borderRadius: "10px",
        position: "fixed",
        top: 0,
        width: "calc(100vw - 180px)",
        paddingLeft: 100,
        marginLeft: 180,
        zIndex: 101,
      }}
    >
      <div className="h-100 d-flex flex-column gap-2">
        <h1 className="fw-bold">Electron</h1>
        <div className="mb-5">
          <h3 className="fw-bold">Select Default Folder in Pc</h3>
          <hr className="mb-3 mt-0" />
          <div>
            <h4>
              {" "}
              Selected Folder Path :{" "}
              <span className="text-success">{selectedPcPath}</span>
            </h4>
            <div className="d-flex align-items-center gap-1">
              <button onClick={handleSelectFolder} className="btn btn-success">
                Select Folder
              </button>
              <button
                onClick={handleOpenFolder}
                data-path={selectedPcPath}
                disabled={!selectedPcPath}
                className="btn btn-success"
              >
                Open Folder
              </button>
            </div>
          </div>
        </div>
        <h3 className="fw-bold">Open Or Create Folder</h3>

        <hr className="mb-0 mt-0" />
        <p>
          <span className="fw-bold">Note</span>: If input name dose not exists
          new folder will be created with this name
        </p>
        <div>
          <input
            type="text"
            value={newPath}
            disabled={!selectedPcPath}
            onChange={handlePathInput}
            className="form-control"
            placeholder="Enter Folder Name"
          />
        </div>
        {newFolderPath && <h4 className="text-success">{newFolderPath}</h4>}
        <button
          onClick={handleOpenFolder}
          data-path={newFolderPath}
          disabled={!newFolderPath}
          className="btn btn-success"
        >
          Open Folder
        </button>
      </div>
    </div>
  );
}
