import Toast from "components/Toast/ToastComponent";
import { setNotification } from "features/Notification/notificationSlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";
import CompAddress from "./address/CompAddress";
import CompInfo from "./info/CompInfo";
import UserInfo from "./userInfo/UserInfo";
import Spinner from "components/Spinner";

export default function CompanySettings() {
  const user = useSelector((state) => state.user.userInfo);
  const dispatch = useDispatch();

  /*   All Satates Below
   ********************************************* */

  // Modals
  const [companyInfo, setCompanyInfo] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);

  /*   All Functions Below
   ********************************************* */
  const getCompanyInfo = async () => {
    setLoading(true);
    try {
      const { data } = await API.get("/companies/list/" + user.companyId, {
        headers: authHeader(),
      });
      setCompanyInfo(data);
      setLoading(false);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      setLoading(false);
    }
  };
  const getUserInfo = async () => {
    setLoading(true);
    try {
      const { data } = await API.get("/user-info/" + user?.id, {
        headers: authHeader(),
      });
      setUserData(data);
      setLoading(false);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    getCompanyInfo();
    getUserInfo();
  }, []);

  /*   Table Functions below
   ********************************************* */

  return (
    <div className="row " style={{ minHeight: "75vh" }}>
      <Toast />
      {loading && <Spinner />}
      {/* <h2 className="px-3">Company Info</h2> */}
      <div className="col-12 d-flex">
        <div className="d-flex flex-grow-1">
          {/*   left pannel
           ********************************************* */}
          {userData && companyInfo && (
            <UserInfo user={userData} refreshFunc={getUserInfo} />
          )}
          {/*   Right Pannel
           ********************************************* */}
          <div
            className="flex-grow-1 d-flex flex-column "
            style={{ borderRadius: 10 }}
          >
            {companyInfo && (
              <CompInfo
                companyInfo={companyInfo}
                refreshFunc={getCompanyInfo}
              />
            )}
            {companyInfo && (
              <CompAddress
                companyInfo={companyInfo}
                refreshFunc={getCompanyInfo}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
