import ImgWithSignedUrl from "components/SignedUrl/ImgWithSignedUrl";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import authHeader from "services/auth-header";
import API from "services/axios";
import { socket } from "socket";
import logo from "../../../assets/images/logo.png";
import ArtImg from "./components/ArtImg";
import ArtProductSlider from "./components/ArtProductSlider";
import ArtCommentSection from "./components/CommentSection/ArtCommentSection";
import axios from "axios";
import { setNotification } from "features/Notification/notificationSlice";
import Spinner from "components/Spinner";

export default function ArtApprovalDetails({
  selectedDesign,
  setSelectedDesign,
  getDesignByToken,
  user,
  setUser,
}) {
  const { token } = useParams();
  const dispatch = useDispatch();

  /*   All States
   ********************************************* */
  const companyLogo = useSelector(
    (state) => state?.company?.company?.companyLogo
  );
  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["clean"],
    ],
  };

  const [selectedProduct, setSelectedProduct] = useState(null);

  const [loading, setLoading] = useState(false);
  const [mockupFabrics, setMockupFabrics] = useState(null);

  const [selectedImg, setSelectedImg] = useState(null);
  const [selectedImgIndex, setSelectedImgIndex] = useState(0);

  const [selectedMarkerState, setSelectedMarkerState] = useState(null);
  const [selectedComment, setSelectedComment] = useState(null);

  const [notes, setNotes] = useState(false);
  const [showNotes, setShowNotes] = useState("");

  /*   All Functions
   ********************************************* */

  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  const updateSelectedImg = (img) => {
    setSelectedImg((prev) => null);
    setTimeout(() => setSelectedImg((prev) => img), 100);
  };

  const changeImageByCommentVersion = (imageVersion) => {
    if (selectedImg?.version !== imageVersion) {
      updateSelectedImg(selectedImg);
      setSelectedImgIndex((prev) => {
        return selectedProduct?.images?.findIndex(
          (item) => item.version === imageVersion
        );
      });
    }
  };

  const updateSelectedState = (newState, imageVersion) => {
    changeImageByCommentVersion(imageVersion);
  };

  const updateSelectedProduct = async (product, commentPath) => {
    setSelectedProduct((prev) => null);
    setTimeout(() => {
      setSelectedProduct((prev) => product);
    }, 100);

    let updatedSelectedDesign = null;
    let updatedProducts = selectedDesign.products.map((prod) => {
      if (prod.id === product.id) {
        return product;
      } else {
        return prod;
      }
    });

    updatedSelectedDesign = { ...selectedDesign, products: updatedProducts };
    setSelectedDesign((prev) => updatedSelectedDesign);

    /*   update Api Call
     ********************************************* */

    try {
      const { data } = await API.patch(
        "/art-approval/update",
        {
          token,
          formData: {
            ...updatedSelectedDesign,
            products: JSON.stringify(updatedSelectedDesign?.products),
          },
          path: commentPath ? commentPath : null,
        },
        { headers: authHeader() }
      );
    } catch (err) {
      console.log(err.message);
    }
  };

  function logout() {
    localStorage.setItem("username", "");
    setUser((prev) => {
      return { isSet: false, user: null };
    });
  }

  const downloadFile = (fileKey) => {
    loadingOn();
    axios

      .post(`${process.env.REACT_APP_API_URI}/aws/images`, {
        key: fileKey,
      })
      .then((response) => {
        const url = response.data[0];
        loadingOff();
        const link = document.createElement("a");
        link.href = url;
        link.download = fileKey;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })

      .catch((error) => {
        console.log("Error downloading file:", error);
      });
  };

  const getAllMockupFabrics = async () => {
    try {
      loadingOn();
      const { data } = await API.get("/mockup-fabrics", {
        headers: authHeader(),
      });

      if (data?.type === "success") {
        let resultData = data.data;
        setMockupFabrics(resultData);
      }
      if (data?.type === "error") {
        dispatch(
          setNotification({
            message: data?.message,
            type: "error",
          })
        );
      }
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const getFabricNameById = (id) => {
    let fabricItem = mockupFabrics?.find((item) => item.id === id);
    return fabricItem?.name;
  };

  /*   All UseEffects
   ********************************************* */

  useEffect(() => {
    setNotes((prev) => selectedProduct?.notes);
    updateSelectedImg(selectedProduct?.images[selectedImgIndex]);
    setSelectedImgIndex(0);
    return () => updateSelectedImg(selectedProduct?.images[selectedImgIndex]);
  }, [selectedProduct]);

  useEffect(() => {
    updateSelectedImg(selectedProduct?.images[selectedImgIndex]);
    return () => updateSelectedImg(selectedProduct?.images[selectedImgIndex]);
  }, [selectedImgIndex]);

  useEffect(() => {
    if (selectedDesign) {
      socket.on("connect", () => {
        console.log("connected");
      });
      socket.on("disconnect", () => {
        console.log("disconnected");
      });

      socket.on(`mockups-link`, (data) => {
        if (parseInt(data?.id) === selectedDesign?.id) {
          getDesignByToken(token);
        }
      });
    }
  }, [socket, selectedDesign]);

  useEffect(() => {
    getAllMockupFabrics();
  }, []);
  return (
    <div>
      {loading && <Spinner />}
      <div className="d-flex gap-3 px-4" style={{ height: "97vh" }}>
        <div className="w-75 d-flex flex-column gap-2">
          <div className="d-flex justify-content-between gap-2 align-items-center">
            <div className="d-flex align-items-start gap-3">
              <div style={{ width: 150, height: 80 }} className="">
                {selectedDesign?.customer?.customerLogo ? (
                  <ImgWithSignedUrl
                    path={selectedDesign?.customer?.customerLogo}
                    styles={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                ) : selectedDesign?.customer?.location?.logo ? (
                  <img
                    src={require(`../../../assets/images/${selectedDesign?.customer?.location?.logo}`)}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "",
                    }}
                    alt="location logo"
                  />
                ) : companyLogo ? (
                  <ImgWithSignedUrl
                    path={companyLogo}
                    styles={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                ) : (
                  <img
                    src={logo}
                    alt="logo"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "",
                    }}
                  />
                )}
              </div>
              <div className="d-flex flex-column justify-content-start align-items-start">
                <h3 className="mt-0">{selectedDesign?.title}</h3>
                <h4 className="mt-0">
                  {selectedDesign?.customer?.customerName} -{" "}
                  {selectedDesign?.customer?.location?.locationName}
                </h4>
              </div>
            </div>
            <button type="button" onClick={logout} className="btn btn-info">
              Logout
            </button>
          </div>
          {/*   Top Header Section
           ********************************************* */}
          <div className="bg-white custom-shadow-sm px-3 custom-border-radius p-2 d-flex justify-content-between align-items-center">
            {/* Pdf Catalogue options */}
            <div className="d-flex justify-content-between gap-2 align-items-center">
              <h4>
                {selectedProduct?.title ? (
                  <div className="d-flex align-items-center">
                    <button className="btn btn-primary btn-sm me-2">
                      {selectedProduct?.id}
                    </button>
                    {selectedProduct?.title}
                  </div>
                ) : (
                  "Please Add Product"
                )}
              </h4>
            </div>
            <div className="flex-grow-1 gap-1 ms-3">
              {selectedProduct?.description && (
                <div className="">
                  <span className="fw-bold h4">Desc:</span>
                  <span className="ms-1">{selectedProduct?.description}</span>
                </div>
              )}
            </div>
            <div className="d-flex align-items-center gap-1">
              <div>
                <button
                  type="button"
                  className="btn btn-sm btn-success dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Version {selectedImg?.version}
                </button>

                <div className="dropdown-menu">
                  <div className="dropdown-item"></div>
                  {selectedProduct?.images?.map((img, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        if (index !== selectedImgIndex) {
                          setSelectedImgIndex((prev) => index);
                          setSelectedComment(null);
                          setSelectedMarkerState(null);
                        }
                      }}
                      className="dropdown-item"
                      href="#"
                    >
                      Version {img?.version}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/*   Image Section
           ********************************************* */}
          <div className="flex-grow-1 d-flex  justify-content-between gap-3">
            {/*   Product Slider
             ********************************************* */}
            <ArtProductSlider
              user={user.user}
              selectedDesign={selectedDesign}
              setSelectedProduct={setSelectedProduct}
              selectedProduct={selectedProduct}
              setSelectedDesign={setSelectedDesign}
              updateSelectedProduct={updateSelectedProduct}
            />
            {/*   Image
             ********************************************* */}
            <div className="bg-white overflow-auto custom-shadow-sm custom-border-radius flex-center flex-grow-1 p-3">
              {selectedImg && (
                <ArtImg
                  user={user.user}
                  selectedImg={selectedImg}
                  selectedProduct={selectedProduct}
                  selectedMarkerState={selectedMarkerState}
                  setSelectedMarkerState={setSelectedMarkerState}
                  updateSelectedState={updateSelectedState}
                  updateSelectedProduct={updateSelectedProduct}
                  selectedComment={selectedComment}
                  setSelectedComment={setSelectedComment}
                />
              )}
            </div>
          </div>
        </div>
        {/*    Comment Section
         ********************************************* */}
        <ArtCommentSection
          user={user.user}
          token={token}
          selectedDesign={selectedDesign}
          selectedProduct={selectedProduct}
          selectedImg={selectedImg}
          selectedComment={selectedComment}
          setSelectedDesign={setSelectedDesign}
          setSelectedProduct={setSelectedProduct}
          updateSelectedProduct={updateSelectedProduct}
          updateSelectedState={updateSelectedState}
          setSelectedComment={setSelectedComment}
          setSelectedMarkerState={setSelectedMarkerState}
        />
      </div>
      {/*   ADD Product Notes
       ********************************************* */}
      <Modal
        show={showNotes}
        contentClassName="bg-transparent"
        onHide={() => setShowNotes(false)}
      >
        <Modal.Body
          className="shadow-lg bg-white px-3 "
          style={{ borderRadius: 15 }}
        >
          <h2 className="mb-3">Product Notes</h2>
          {/*   Notes
           ********************************************* */}
          <div
            className="bg-white shadow-lg p-2 my-3 me-2 d-flex flex-column justify-content-between "
            style={{ borderRadius: 15, minHeight: 300 }}
          >
            <div className="">
              <ReactQuill
                modules={modules}
                theme="snow"
                value={notes}
                onChange={(val) => setNotes(val)}
              />
            </div>
          </div>

          {/*   buttons
           ********************************************* */}
          <div className="my-2">
            <div className="d-flex justify-content-end">
              <button
                onClick={() => {
                  setShowNotes(false);
                  setNotes("");
                }}
                className="btn btn-secondary btn-sm"
                type="button"
              >
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
