import { useState } from "react";
import QuickViewGeneralDetails from "./QuickViewGeneralDetails";

function QuickViewWOProduct({
  selectedWorkOrder,
  setShowUpdateModal,
  selectProduct,
}) {
  /*   All States Below
   ********************************************* */
  const [active, setActive] = useState("general");
  const [selectedCurrentProduct, setSelectedCurrentProduct] = useState(null);

  /*   All Functions Below
   ********************************************* */

  const checkActive = (val) => {
    setActive(val);
  };

  const handleSelectProduct = () => {
    if (selectedCurrentProduct) {
      selectProduct(selectedCurrentProduct);
      setShowUpdateModal(false);
    } else {
      alert("No Product Selected");
    }
  };

  /*   Arrow Functions
   ********************************************* */

  return (
    <div className="row">
      <div className="col-12">
        {/*    ADD FORM START
         ********************************************* */}
        <div className="mb-13 ms-1 d-flex justify-content-between">
          <h3>Select Product</h3>
          <div>
            <button
              type="button"
              className="btn btn-success mx-1"
              onClick={() => {
                handleSelectProduct(false);
              }}
            >
              Select Current Product
            </button>
            <button
              type="button"
              className="btn btn-danger "
              onClick={() => {
                setShowUpdateModal(false);
              }}
            >
              Back
            </button>
          </div>
        </div>
        <div className="flex-grow-1">
          <ul className="nav nav-tabs nav-bordered mb-3 d-flex flex-grow-1">
            <li
              onClick={() => {
                checkActive("general");
              }}
              className="nav-item"
            >
              <button
                className={
                  active == "general"
                    ? `nav-link rounded-0 active bg-primary text-white`
                    : "nav-link rounded-0"
                }
              >
                <span className="d-none d-md-block">General</span>
              </button>
            </li>
          </ul>
          <form>
            <div className="">
              {active == "general" && (
                <div className="tab-pane show active">
                  <QuickViewGeneralDetails
                    selectedWorkOrder={selectedWorkOrder}
                    selectProduct={selectProduct}
                    setSelectedCurrentProduct={setSelectedCurrentProduct}
                  />
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default QuickViewWOProduct;
