import React from 'react';
import { useState } from 'react';
import { useMemo } from 'react';
import { memo } from 'react';
import { createContext } from 'react';
import { useContext } from 'react';

const BreadCrumbContext = createContext({});

export const BreadCrumbProvider = memo(({ children }) => {
  /* *************  States  **************** */
  const [title, setTitle] = useState('');

  const setBreadCrumb = (name) => {
    setTitle(name);
  };

  const value = useMemo(() => ({
    title,
    setBreadCrumb,
  }));
  return <BreadCrumbContext.Provider value={value}>{children}</BreadCrumbContext.Provider>;
});

export default function useBreadCrumb() {
  return useContext(BreadCrumbContext);
}
