import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "react-quill/dist/quill.snow.css";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";
import PrizeCalculatorModal from "./PrizeCalculatorModal";

function PriceCalculator() {
  const dispatch = useDispatch();

  /*   ALL STATES
   ********************************************* */

  const [showCalculatorModal, setShowCalculatorModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [allSelectedMatrixes, setAllSelectedMatrixes] = useState([]);

  const [priceMatrixData, setPriceMatrixData] = useState(null);
  const [selectedMatrix, setSelectedMatrix] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);

  const getAllPriceMatrixes = async () => {
    try {
      setIsLoading(true);
      const { data } = await API.get("/pricematrix", { headers: authHeader() });
      setPriceMatrixData(data);
      setIsLoading(false);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let totalPrice = allSelectedMatrixes?.reduce((prev, item) => {
      let newPrice = prev + parseFloat(item.calculatedPrice, 10);
      return newPrice;
    }, 0.0);
    setTotalPrice(totalPrice);
  }, [allSelectedMatrixes]);

  useEffect(() => {
    getAllPriceMatrixes();
  }, []);

  return (
    <div
      className=""
      style={{ paddingLeft: 280, paddingTop: 20, height: "90vh" }}
    >
      {isLoading && <Spinner />}

      <h3>Price Calculator</h3>
      {/*   Table
       ********************************************* */}
      <div className="me-3">
        <div className="px-2 mt-3">
          <div className=" d-flex gap-2">
            <div className="d-flex w-100">
              <select
                onChange={(e) => {
                  priceMatrixData?.find((item) => {
                    if (item.id === parseInt(e.target.value)) {
                      setSelectedMatrix(item);
                    }
                  });
                }}
                className="form-control"
              >
                <option disabled={false}>Select Matrix</option>
                {priceMatrixData?.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="">
              <button
                type="Button"
                onClick={() => {
                  setShowCalculatorModal(true);
                }}
                disabled={!selectedMatrix}
                className="btn btn-info text-nowrap"
              >
                Add Service
              </button>
            </div>
          </div>
        </div>
        {/*   Dynamic Matrix Section
         ********************************************* */}
        <div className="px-2 my-3">
          {allSelectedMatrixes?.map((item, index) => (
            <div className="d-flex align-items-center gap-2" key={index}>
              <div className="flex-grow-1 bg-primary2 row mb-2 custom-border-radius px-2 mx-1 py-2">
                <div className="col-3">Type: {item?.type}</div>
                <div className="col-3">Location: {item?.location}</div>
                <div className="col-3">Colors: {item?.colors}</div>
                <div className="col-3 text-end">
                  Price: {item?.calculatedPrice}
                </div>
              </div>
              <button
                type="button"
                onClick={() => {
                  setAllSelectedMatrixes((prev) =>
                    prev.filter((selected) => selected !== item)
                  );
                }}
                className="btn btn-danger mb-2"
              >
                <i className="mdi mdi-delete"></i>
              </button>
            </div>
          ))}
        </div>

        <div className="d-flex justify-content-end mb-3">
          <div
            className="bg-white custom-shadow-sm p-2 custom-border-radius d-flex justify-content-between align-items-center"
            style={{ width: "15%" }}
          >
            <div>Total Price</div>
            <div>$ {totalPrice}</div>
          </div>
        </div>
      </div>

      {/*   ADD Modal
       ********************************************* */}
      <Modal
        show={showCalculatorModal}
        onHide={() => setShowCalculatorModal(false)}
      >
        <Modal.Body
          className="custom-shadow bg-white px-3 "
          style={{ borderRadius: 15 }}
        >
          <PrizeCalculatorModal
            selectedMatrix={selectedMatrix}
            setAllSelectedMatrixes={setAllSelectedMatrixes}
            closeModal={setShowCalculatorModal}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default PriceCalculator;
