import React from "react";

export default function CustomFields({
  formData,
  setFormData,
  handleInputChange,
}) {
  /*   All States
   ********************************************* */

  /*   All Functions
   ********************************************* */

  /*   All UseEffects
   ********************************************* */
  return (
    <div className="col-6 flex-grow-1 px-3 py-2 my-1">
      <div className="flex-grow-1 row mb-1">
        <div className="col-4 flex-center justify-content-start align-items-center">
          <label htmlFor={"customField1"} className="customer">
            Custom Field 1
          </label>
        </div>
        <div className="col-8">
          <input
            name="customField1"
            type="text"
            value={formData["customField1"] || ""}
            onChange={(e) => {
              handleInputChange(e, "text");
            }}
            className="form-control form-control-sm w-100 flex-grow-1"
            placeholder="🔎 Custom Field 1"
          />
        </div>
      </div>
      <div className="flex-grow-1 row mb-1">
        <div className="col-4 flex-center justify-content-start align-items-center">
          <label htmlFor={"customField2"} className="customer">
            Custom Field 2
          </label>
        </div>
        <div className="col-8">
          <input
            name="customField2"
            type="text"
            value={formData["customField2"] || ""}
            onChange={(e) => {
              handleInputChange(e, "text");
            }}
            className="form-control form-control-sm w-100 flex-grow-1"
            placeholder="🔎 Custom Field 1"
          />
        </div>
      </div>
      <div className="flex-grow-1 row mb-1">
        <div className="col-4 flex-center justify-content-start align-items-center">
          <label htmlFor={"customField3"} className="customer">
            Custom Field 3
          </label>
        </div>
        <div className="col-8">
          <input
            name="customField3"
            type="text"
            value={formData["customField3"] || ""}
            onChange={(e) => {
              handleInputChange(e, "text");
            }}
            className="form-control form-control-sm w-100 flex-grow-1"
            placeholder="🔎 Custom Field 1"
          />
        </div>
      </div>
    </div>
  );
}
