import { createContext, useContext, useState } from "react";

const WorkOrderContext = createContext({});

export const WorkOrderProvider = ({ children }) => {
  /* *************  States  **************** */
  const [workOrderContextData, setWorkOrderContextData] = useState(null);
  const [customerContextData, setCustomerContextData] = useState(null);

  /* ******************** WorkOrder ************************* */
  const clearWorkOrderData = () => {
    setWorkOrderContextData(null);
  };

  const setWorkOrderData = ({ data }) => {
    if (data) {
      setWorkOrderContextData({ data });
    }
  };

  /* ******************** Customer ************************* */
  const setCustomerData = ({ data, id }) => {
    setCustomerContextData({ data, id });
  };
  const clearCustomerData = () => {
    setCustomerContextData(null);
  };

  const workOrderValues = {
    workOrderContextData,
    setWorkOrderData,
    clearWorkOrderData,

    customerContextData,
    setCustomerData,
    clearCustomerData,
  };
  return (
    <WorkOrderContext.Provider value={workOrderValues}>
      {children}
    </WorkOrderContext.Provider>
  );
};

export default function useWorkOrder() {
  return useContext(WorkOrderContext);
}
