import Spinner from "components/Spinner";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import authHeader from "services/auth-header";
import API from "services/axios";
import logo from "../../../assets/images/logo.png";
import { socket } from "../../../socket";
import ArtImageWIthSignedUrl from "../Mockups/components/ArtImageWIthSignedUrl";
import MCenterButtons from "./MButtons/MCenterButtons";
import MComments from "./MCommentSection/MComments";
import MDesignImage from "./MDesignImage";

export default function MDetails({
  selectedDesign,
  setSelectedDesign,
  designRequests,
  setDesignRequests,
  setShowDetailsPage,
  user,
  setUser,
  getDesignByToken,
}) {
  const { token } = useParams();
  const companyLogo = useSelector(
    (state) => state?.company?.company?.companyLogo
  );
  /*   All States
   ********************************************* */
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [active, setActive] = useState("comments");

  const [selectedImg, setSelectedImg] = useState(null);
  const [selectedImgIndex, setSelectedImgIndex] = useState(null);
  const [selectedComment, setSelectedComment] = useState(null);

  /*   All Functions
   ********************************************* */

  const updateSelectedImg = (img) => {
    setSelectedImg((prev) => null);
    setTimeout(() => setSelectedImg((prev) => img), 100);
  };

  const changeImageByCommentVersion = (imageVersion) => {
    if (selectedImg?.version !== imageVersion) {
      updateSelectedImg(selectedImg);
      setSelectedImgIndex((prev) => {
        return selectedProduct?.images?.findIndex(
          (item) => item.version === imageVersion
        );
      });
    }
  };

  const updateSelectedState = (newState, imageVersion) => {
    changeImageByCommentVersion(imageVersion);
  };

  const updateSelectedProduct = async (product, commentPath) => {
    setSelectedProduct((prev) => null);
    setTimeout(() => {
      setSelectedProduct((prev) => product);
    }, 100);

    let updatedSelectedDesign = null;
    let updatedProducts = selectedDesign.products.map((prod) => {
      if (prod.id === product.id) {
        return product;
      } else {
        return prod;
      }
    });

    updatedSelectedDesign = { ...selectedDesign, products: updatedProducts };
    setSelectedDesign((prev) => updatedSelectedDesign);

    /*   update Api Call
     ********************************************* */

    try {
      const { data } = await API.patch(
        "/art-approval/update",
        {
          token,
          formData: {
            ...updatedSelectedDesign,
            products: JSON.stringify(updatedSelectedDesign?.products),
          },
          path: commentPath ? commentPath : null,
        },
        { headers: authHeader() }
      );
    } catch (err) {
      console.log(err.message);
    }
  };

  function logout() {
    localStorage.setItem("username", "");
    setUser((prev) => {
      return { isSet: false, user: null };
    });
  }

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    if (selectedDesign && !selectedProduct) {
      setSelectedProduct(
        selectedDesign?.products?.length ? selectedDesign?.products[0] : null
      );
    }
  }, [selectedProduct, selectedDesign]);

  useEffect(() => {
    if (selectedProduct) {
      setSelectedImg(
        selectedProduct?.images?.length ? selectedProduct?.images[0] : null
      );
    }
  }, [selectedProduct]);
  useEffect(() => {
    updateSelectedImg(selectedProduct?.images[selectedImgIndex]);
    setSelectedImgIndex(0);
    return () => updateSelectedImg(selectedProduct?.images[selectedImgIndex]);
  }, [selectedProduct]);

  useEffect(() => {
    updateSelectedImg(selectedProduct?.images[selectedImgIndex]);
    return () => updateSelectedImg(selectedProduct?.images[selectedImgIndex]);
  }, [selectedImgIndex]);

  useEffect(() => {
    if (selectedDesign) {
      socket.on("connect", () => {
        console.log("connected");
      });
      socket.on("disconnect", () => {
        console.log("disconnected");
      });

      socket.on(`mockups-link`, (data) => {
        if (parseInt(data?.id) === selectedDesign?.id) {
          setSelectedDesign((prev) => null);
          getDesignByToken(token);
        }
      });
    }
  }, [socket, selectedDesign]);

  if (!selectedDesign) return <Spinner />;

  return (
    <div style={{ height: "100dvh" }}>
      {/*   Header
       ********************************************* */}
      <div className="flex flex-col h-screen bg-gray-100">
        <header
          style={{ height: "9dvh" }}
          className="d-flex gap-2 align-items-center justify-content-between px-2 bg-white shadow-sm"
        >
          <div
            style={{ width: "15dvw", height: "8dvh" }}
            className="overflow-hidden"
          >
            {selectedDesign?.customer?.customerLogo ? (
              <ArtImageWIthSignedUrl
                path={selectedDesign?.customer?.customerLogo}
                imgStyle={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            ) : selectedDesign?.customer?.location?.logo ? (
              <img
                src={require(`../../../assets/images/${selectedDesign?.customer?.location?.logo}`)}
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "contain",
                }}
                alt="location logo"
              />
            ) : companyLogo ? (
              <ArtImageWIthSignedUrl
                path={companyLogo}
                imgStyle={{
                  height: "100%",
                  width: "100%",
                  objectFit: "contain",
                }}
              />
            ) : (
              <img
                src={logo}
                alt="logo"
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "contain",
                }}
              />
            )}
          </div>
          <div className="flex-grow-1">
            <div className="font-weight-bold h4 m-0 p-0">
              {selectedDesign?.title}
            </div>
          </div>
          <button
            type="button"
            onClick={logout}
            className="btn btn-sm btn-info"
          >
            Logout
          </button>
        </header>
      </div>

      {/*   Image
       ********************************************* */}
      <div className="py-1 px-2 mt-1" style={{ height: "25dvh" }}>
        <MDesignImage selectedImg={selectedImg} />
      </div>

      {/* Tabs */}
      <div
        className="px-2 d-flex align-items-center gap-1"
        style={{ height: "6dvh" }}
      >
        <ul className="nav nav-tabs nav-bordered border-0 d-flex flex-grow-1">
          <li
            onClick={() => {
              setActive("products");
            }}
            className="nav-item"
          >
            <button
              className={
                active === "products"
                  ? `nav-link rounded-0 active bg-primary text-white`
                  : "nav-link rounded-0"
              }
            >
              <span className="">Products</span>
            </button>
          </li>
          <li
            onClick={() => {
              setActive("comments");
            }}
            className="nav-item"
          >
            <button
              className={
                active === "comments"
                  ? `nav-link rounded-0 active bg-primary text-white`
                  : "nav-link rounded-0"
              }
            >
              <span className="">Comments</span>
            </button>
          </li>
        </ul>
        <button
          type="button"
          className="btn btn-success btn-sm dropdown-toggle ms-1"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Version {selectedImg?.version}
        </button>
        <div className="dropdown-menu">
          {selectedProduct?.images?.map((img, index) => (
            <div
              key={index}
              onClick={() => {
                if (index !== selectedImgIndex) {
                  setSelectedImgIndex((prev) => index);
                  setSelectedComment(null);
                }
              }}
              className="dropdown-item"
            >
              Version {img?.version}
            </div>
          ))}
        </div>
      </div>

      {/*   Comment Section
       ********************************************* */}
      <div className="py-1 px-2 " style={{ height: "58dvh" }}>
        {active === "products" && (
          <div className="bg-white custom-shadow-sm custom-border-radius-sm h-100 px-2 py-3">
            <MCenterButtons
              selectedImg={selectedImg}
              selectedProduct={selectedProduct}
              selectedImgIndex={selectedImgIndex}
              setSelectedImgIndex={setSelectedImgIndex}
              setSelectedComment={setSelectedComment}
              selectedDesign={selectedDesign}
              setSelectedProduct={setSelectedProduct}
              setSelectedDesign={setSelectedDesign}
              setDesignRequests={setDesignRequests}
            />
          </div>
        )}
        {active === "comments" && (
          <MComments
            user={user.user}
            token={token}
            selectedProduct={selectedProduct}
            selectedDesign={selectedDesign}
            updateSelectedProduct={updateSelectedProduct}
            selectedImg={selectedImg}
            updateSelectedState={updateSelectedState}
            selectedComment={selectedComment}
            setSelectedComment={setSelectedComment}
            setSelectedProduct={setSelectedProduct}
          />
        )}
      </div>
    </div>
  );
}
