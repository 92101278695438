import React, { useState, useEffect } from "react";
import { socket } from "../socket";

import Nav from "components/Nav";
import Hero from "components/Hero";
import Features from "components/Features";
import Pricing from "components/Pricing";
import Footer from "components/Footer";

function Home() {
  return (
    <>
      <div className="flex-center" style={{ height: "100vh", width: "100vw" }}>
        <h1>Coming Soon</h1>
      </div>
      {/* <Nav />

      <Hero />

      <Features />

      <Pricing />

      <Footer /> */}
    </>
  );
}

export default Home;
