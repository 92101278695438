import { Image, Text, View } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";

export default function TeamViewPdfProducts({
  styles,
  productsList,
  selectedWO,
}) {
  /*   All States
   ********************************************* */

  /*   All Functions
   ********************************************* */

  /*   All UseEffects
   ********************************************* */

  return (
    <View style={{ ...styles.productDetails, marginTop: 10 }}>
      {/* ----------  Looping Products   ---------- */}
      {productsList &&
        productsList?.map((item, index) => {
          return (
            <View style={styles.mainContainer} key={index}>
              <View style={[{ ...styles.prodMain }, { ...styles.textbody }]}>
                <Text
                  style={{
                    ...styles.prodTitle,
                    width: "30%",
                    fontSize: "12px",
                    borderRight: "1px solid #000",
                  }}
                >
                  {item?.productType}
                </Text>
                <Text style={[styles.prodTitle, styles.textbody]}>
                  {index + 1}: {item?.productDescription}
                </Text>
              </View>
              <View style={[{ ...styles.prodMain }, { ...styles.textbody }]}>
                <View style={styles.prodContent}>
                  <View style={styles.prodInfoRow}>
                    <View style={[styles.prodCol, styles.borderRight]}>
                      <Text style={styles.prodKey}>Style Number:</Text>
                      <Text style={styles.prodVal}>{item?.productStyle}</Text>
                    </View>
                    <View style={styles.prodCol}>
                      <Text style={styles.prodKey}>Fabrics:</Text>
                      <Text style={styles.prodVal}>{item?.productFabric}</Text>
                    </View>
                  </View>
                  <View style={styles.prodInfoRow}>
                    <View style={[styles.prodCol, styles.borderRight]}>
                      <Text style={styles.prodKey}>Color:</Text>
                      <Text style={styles.prodVal}>{item?.productColor}</Text>
                    </View>
                    <View style={styles.prodCol}>
                      <Text style={styles.prodKey}>Qty:</Text>
                      <Text style={styles.prodVal}>{item?.totalQuantity}</Text>
                    </View>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {item?.productImage && (
                      <Image
                        style={{ width: 250, height: 155 }}
                        src={item?.productImage}
                      />
                    )}
                  </View>
                </View>

                <View style={styles.prodSizes}>
                  <Text style={styles.prodSubHeading}>Size Details</Text>
                  {item?.productSizes &&
                    JSON.parse(item?.productSizes).map((d) => (
                      <Text key={d.id} style={styles.prodTxt}>
                        {d?.size} = {d?.quantity}
                      </Text>
                    ))}
                </View>
              </View>
              <Text style={styles.prodComments}>
                COMMENTS: {item?.notes?.replace(/<[^>]+>/g, "")}
              </Text>
            </View>
          );
        })}
    </View>
  );
}
