import React, { useEffect, useState } from "react";

export default function AttUserSchedule({ formData, setFormData }) {
  /*   All States
   ********************************************* */

  const [weeklySchedule, setWeeklySchedule] = useState(
    Object.keys(formData?.weeklySchedule).length > 0
      ? formData?.weeklySchedule
      : {
          Monday: {
            inTime: "",
            outTime: "",
            working: false,
          },
          Tuesday: {
            inTime: "",
            outTime: "",
            working: false,
          },
          Wednesday: {
            inTime: "",
            outTime: "",
            working: false,
          },
          Thursday: {
            inTime: "",
            outTime: "",
            working: false,
          },
          Friday: {
            inTime: "",
            outTime: "",
            working: false,
          },
          Saturday: {
            inTime: "",
            outTime: "",
            working: false,
          },
          Sunday: {
            inTime: "",
            outTime: "",
            working: false,
          },
        }
  );

  /*   All Functions
   ********************************************* */

  /*   All UseEffects
   ********************************************* */

  useEffect(() => {
    setFormData((prev) => {
      return { ...prev, weeklySchedule: weeklySchedule };
    });
  }, [weeklySchedule]);

  return (
    <div>
      <div className="d-flex align-items-center mb-2">
        <label style={{ width: "25%" }}>Is Night Office?</label>
        <div style={{ width: "75%" }}>
          <input
            onChange={(e) =>
              setFormData({
                ...formData,
                isNightOffice: e.target.checked,
              })
            }
            checked={formData?.isNightOffice || ""}
            type="checkbox"
            id="switch0"
            data-switch="none"
          />
          <label
            htmlFor="switch0"
            data-on-label="Yes"
            data-off-label="No"
          ></label>
        </div>
      </div>
      <div className="d-flex align-items-center mb-2">
        <label style={{ width: "25%" }}>Relaxation Minutes</label>
        <div style={{ width: "75%" }}>
          <input
            name={"relaxationMinutes"}
            type="number"
            max={60}
            min={0}
            placeholder={`Enter relaxation time in minutes`}
            value={formData?.relaxationMinutes || ""}
            onChange={(e) => {
              setFormData((prev) => {
                return { ...prev, relaxationMinutes: parseInt(e.target.value) };
              });
            }}
            className="form-control"
          />
        </div>
      </div>
      <div className="d-flex align-items-center mb-2">
        <label style={{ width: "25%" }}>Overtime After Minutes</label>
        <div style={{ width: "75%" }}>
          <input
            name={"overtimeAfterMinutes"}
            type="number"
            max={60}
            min={0}
            placeholder={`Enter Overtime After time in minutes`}
            value={formData?.overtimeAfterMinutes || ""}
            onChange={(e) => {
              setFormData((prev) => {
                return {
                  ...prev,
                  overtimeAfterMinutes: parseInt(e.target.value),
                };
              });
            }}
            className="form-control"
          />
        </div>
      </div>
      <div>
        <div className="d-flex align-items-center mb-2">
          <h5 style={{ width: "25%" }} className="text-nowrap">
            Week Name
          </h5>
          <h5 style={{ width: "30%" }} className="text-nowrap">
            In Time
          </h5>
          <h5 style={{ width: "30%" }} className="text-nowrap">
            Out Time
          </h5>
          <h5 style={{ width: "15%" }} className="text-center">
            Working
          </h5>
        </div>

        {weeklySchedule &&
          Object.keys(weeklySchedule)?.map((weekName) => (
            <div key={weekName} className="d-flex align-items-center">
              <h5 style={{ width: "25%" }}>{weekName}</h5>
              <div style={{ width: "30%" }}>
                <input
                  value={weeklySchedule[weekName].inTime}
                  onChange={(e) => {
                    setWeeklySchedule((prev) => {
                      return {
                        ...prev,
                        [weekName]: {
                          ...prev[weekName],
                          inTime: e.target.value,
                        },
                      };
                    });
                  }}
                  type="time"
                  className="form-control"
                  id="inTime"
                />
              </div>
              <div style={{ width: "30%" }}>
                <input
                  value={weeklySchedule[weekName].outTime || ""}
                  onChange={(e) => {
                    setWeeklySchedule((prev) => {
                      return {
                        ...prev,
                        [weekName]: {
                          ...prev[weekName],
                          outTime: e.target.value,
                        },
                      };
                    });
                  }}
                  type="time"
                  className="form-control"
                  id="outTime"
                />
              </div>

              <div style={{ width: "15%" }} className="text-center">
                <input
                  checked={weeklySchedule[weekName].working}
                  onChange={(e) => {
                    setWeeklySchedule((prev) => {
                      return {
                        ...prev,
                        [weekName]: {
                          ...prev[weekName],
                          working: e.target.checked,
                        },
                      };
                    });
                  }}
                  type="checkbox"
                  className="form-check-input"
                  id="working"
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
