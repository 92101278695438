import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";

import designImg from "../../../assets/images/DesignRequestImages/backup/img1.jpg";

export default function VariationsList({
  selectedDesignData,
  selectedVariation,
  setSelectedVariation,
}) {
  /*   All States
   ********************************************* */
  const [variationsList, setVariationsList] = useState(null);

  /*   All Functions
   ********************************************* */

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    if (selectedDesignData) {
      setVariationsList(selectedDesignData?.designVariations);
    }
  }, [selectedDesignData]);
  return (
    <div className="overflow-auto scrollbar-hide" style={{ height: "65vh" }}>
      <h3 className=" mt-1 mb-3">
        <span
          style={{
            borderBottom: "2px solid lightgray",
          }}
          className="pe-3"
        >
          Variation List
        </span>
      </h3>
      {variationsList && (
        <Accordion defaultActiveKey={null} className="mb-1">
          {variationsList.map((item) => (
            <Accordion.Item eventKey={item?.id} key={item.id}>
              <Accordion.Header>
                {item?.designCode} ( {item?.color} )
              </Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  <div className="col-8">
                    <div className="row">
                      <div className="col-4">Design Code</div>
                      <div className="col-8">{item["designCode"]}</div>
                    </div>
                    <div className="row">
                      <div className="col-4">Color</div>
                      <div className="col-8">{item["color"]}</div>
                    </div>
                    <div className="row">
                      <div className="col-4">Style No</div>
                      <div className="col-8">{item["styleNo"]}</div>
                    </div>
                    <div className="row">
                      <div className="col-4">Sizing Cut</div>
                      <div className="col-8">{item["sizingCut"]}</div>
                    </div>
                  </div>
                  <div className="col-4 ">
                    <img
                      src={designImg}
                      width={150}
                      height={150}
                      className=""
                      alt="Variation"
                    />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      )}
    </div>
  );
}
