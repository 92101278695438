import React from 'react';
import { useForm } from 'react-hook-form';
import authHeader from 'services/auth-header';
import API from 'services/axios';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../features/Notification/notificationSlice';

function ProductTypeAdd({ showAddPage, refreshFunc, lastSortId }) {
  /*   All Satates Below
   ********************************************* */
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();

  /*   All Functions Below
   ********************************************* */
  const addProductType = async (formData, e) => {
    e.preventDefault();
    try {
      const { data } = await API.post(
        '/producttypes',
        { data: { ...formData, sortId: lastSortId + 1 } },
        { headers: authHeader() }
      );
      if (data.message.type === 'success') {
        refreshFunc();
        showAddPage(false);
        return;
      }
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: 'error',
        })
      );
    }
  };

  return (
    <div className='row'>
      <div className='col-12'>
        {/*    ADD FORM START
         ********************************************* */}
        <div className='mb-13 ms-3 mb-3'>
          <h3>ADD Product Type</h3>
        </div>
        <form className='ps-3 pe-3' onSubmit={handleSubmit(addProductType)}>
          <div className='mb-3'>
            <label htmlFor='productType' className='form-label'>
              Product Type
            </label>
            <input
              className='form-control'
              type='text'
              id='productType'
              placeholder='Enter product type'
              {...register('productTypes')}
            />
          </div>

          <div className={`mt-4 mb-2 d-flex justify-content-end`}>
            <div>
              <button
                className='btn px-1 btn-secondary'
                type='button'
                onClick={() => {
                  showAddPage(false);
                }}
              >
                Cancel
              </button>
              <button className='btn btn-primary ms-1' type='submit'>
                ADD
              </button>
            </div>
          </div>
        </form>

        {/*    ADD FORM END
         ********************************************* */}
      </div>
    </div>
  );
}

export default ProductTypeAdd;
