import { createAsyncThunk } from "@reduxjs/toolkit";

import API from "../../services/axios";
import authHeader from "../../services/auth-header";

export const registerCompany = createAsyncThunk(
  "companies/create",
  async ({ name, street, city, state, zip, userId }, { rejectWithValue }) => {
    try {
      const { data } = await API.post(
        `/companies/create`,
        { name, street, city, state, zip, userId },
        { headers: authHeader() }
      );

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const companySubscription = createAsyncThunk(
  "companies/subscribe",
  async (formData, { rejectWithValue }) => {
    try {
      const { data } = await API.post(`/companies/subscribe`, formData, {
        headers: authHeader(),
      });

      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCompany = createAsyncThunk(
  "companies/get",
  async (arg, { rejectWithValue }) => {
    try {
      const { data } = await API.get("/companies/get", {
        headers: authHeader(),
      });

      return data.company;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
