import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { BlobProvider } from "@react-pdf/renderer";
import { useDispatch, useSelector } from "react-redux";
import API from "services/axios";
import authHeader from "services/auth-header";
import QuoteFileManager from "./WOFileManager";
import { setNotification } from "features/Notification/notificationSlice";
import Toast from "components/Toast/ToastComponent";
import Spinner from "components/Spinner";
import SelectEmailTemplate from "./SelectEmailTemplate";
import ReactQuill from "react-quill";
import CustomerContact from "./CustomerContact";
import { EmailPdf } from "pages/WorkOrders/WorkOrderDetails/WOProducts/EmailPdf";

function WOOrderEmail({
  btnClass,
  btnTitle,
  selectedWorkOrder,
  wOProductsData,
}) {
  /*   All States
   ********************************************* */
  const user = useSelector((state) => state.user?.userInfo);
  const dispatch = useDispatch();
  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["clean"],
    ],
  };

  /* ******************** Refs Start*/
  const checkBoxContainer = useRef();
  const allCheckBox = useRef();

  /* Refs End************************* */

  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showFilesModal, setShowFilesModal] = useState(false);
  const [showCustomerContact, setShowCustomerContact] = useState(false);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [locationLogo, setLocationLogo] = useState(null);
  const [showInvoicePage, setShowInvoicePage] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [selectedContacts, setSelectedContacts] = useState(null);

  const [productTypeList, setProductTypeList] = useState([]);
  const [pdfProducts, setPdfProducts] = useState([]);
  const [emailBody, setEmailBody] = useState("");
  const [emailTo, setEmailTo] = useState(null);
  const [subject, setSubject] = useState(null);

  const [locationId, setLocationId] = useState(null);
  const [selectedSmtpEmailId, setSelectedSmtpEmailId] = useState(null);

  const [productWithBase64Images, setProductWithBase64Images] = useState({});
  const [showEmailButton, setShowEmailButton] = useState(false);

  const [locations, setLocations] = useState(null);
  const [smtpEmailList, setSmtpEmailList] = useState(null);

  /*   All Functions
   ********************************************* */

  // Function to uncheck and check other checkboxes
  // based on "ALL" checkbox

  function loadingOn() {
    setIsLoading(true);
  }
  function loadingOff() {
    setIsLoading(false);
  }

  // Function to handle checkbox of productTypeList
  // And filter items according to checkbox
  const handleProductCheckbox = (e) => {
    setShowPdf(false);
    if (e.target.checked) {
      let pdfProduct = wOProductsData.filter(
        (item) => item.productType == e.target.id
      );
      let newProducts = [...pdfProducts, ...pdfProduct];
      setPdfProducts(newProducts);
    } else {
      let pdfProduct = pdfProducts?.filter(
        (item) => item.productType !== e.target.id
      );
      setPdfProducts(pdfProduct);
    }
  };

  // get All Location
  const getAllLocations = async () => {
    try {
      const { data } = await API.get("/locations", {
        headers: authHeader(),
      });
      setLocations(data);
    } catch (err) {
      alert(err.message);
    }
  };

  const handleLocationChange = async (v) => {
    let value = parseInt(v);
    setLocationId(value);
    setSelectedSmtpEmailId(null);
    loadingOn();
    try {
      const { data } = await API.post(
        "/email-smtp/id",
        { locationId: value },
        {
          headers: authHeader(),
        }
      );
      setSmtpEmailList(data);
      loadingOff();
      return data;
    } catch (err) {
      loadingOff();
      alert(err.message);
    }
  };

  const getFileName = (fileName) => {
    return fileName?.split("/")[fileName?.split("/").length - 1];
  };

  const handleDeleteFile = (item) => {
    let set = new Set(selectedFiles);
    set.delete(item);
    setSelectedFiles([...set]);
  };

  const handleClose = () => {
    setEmailBody("");
    setShowPdf(false);
    setShowEmailModal(false);
  };

  /* ******************** UseEffects ************************* */

  useEffect(() => {
    if (selectedContacts) {
      let contactList = emailTo;
      selectedContacts.forEach((element) => {
        contactList = contactList + ", " + element;
      });
      setEmailTo(contactList);
    }
  }, [selectedContacts]);

  // getting ProductListType from quoteProductData received
  useEffect(() => {
    if (wOProductsData) {
      let checkUniqueArr = [];
      let uniqueArr = [];
      wOProductsData?.map((item) => {
        if (!checkUniqueArr.includes(item?.productType)) {
          checkUniqueArr.push(item?.productType);
          uniqueArr.push(item);
          return item;
        }
      });

      setProductTypeList(uniqueArr);
      setPdfProducts(wOProductsData);
    }
  }, [wOProductsData]);

  const getLocationLogo = async () => {
    const { data } = await API.post(
      "/design-request/get-image",
      { path: selectedWorkOrder?.location?.pdfLogo },
      {
        headers: authHeader(),
      }
    );

    setLocationLogo(data);
    return data;
  };

  /* 

  Note: Make a function to get Pdf Images in blobs
  async function loadImage(url) {
  const response = await fetch(url);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}
// Usage:
const base64Image = await loadImage('https://example.com/image.jpg');
               */

  async function loadImage(url) {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  const getProductWithBase64Images = async (list) => {
    let productListObj = typeof list === "string" ? JSON.parse(list) : list;

    let updatedProducts = [];
    for (const product of productListObj) {
      const base64Image = product?.productImage
        ? await loadImage(product?.productImage)
        : "";
      product["productImage"] = base64Image;
      updatedProducts.push(product);
    }

    return updatedProducts;
  };

  useEffect(() => {
    if (showEmailButton) {
      getProductWithBase64Images(pdfProducts)
        .then((data) => {
          setProductWithBase64Images(data);
        })
        .catch((err) => {
          console.log(err);
          alert(err);
        });
    }
  }, [pdfProducts, showEmailButton]);

  useEffect(() => {
    if (selectedWorkOrder?.location?.pdfLogo) {
      getLocationLogo();
    }
  }, [selectedWorkOrder]);

  useEffect(() => {
    if (selectedWorkOrder?.contactData) {
      let email_to =
        typeof selectedWorkOrder?.contactData === "string"
          ? JSON.parse(selectedWorkOrder?.contactData)
          : selectedWorkOrder?.contactData;

      if (email_to) {
        setEmailTo(email_to.email);
      }
    }
    if (selectedWorkOrder?.locationId) {
      handleLocationChange(selectedWorkOrder?.locationId)
        .then((data) => {
          setSelectedSmtpEmailId(data[0].id);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedWorkOrder]);

  useEffect(() => {
    getAllLocations();
  }, []);

  if (!locations) return <Spinner />;

  if (!showEmailButton)
    return (
      <button
        onClick={() => {
          setShowEmailButton(true);
        }}
        className={"btn-info btn text-nowrap ms-1"}
      >
        Load Email
      </button>
    );

  if (!Object.keys(productWithBase64Images).length && showEmailButton)
    return (
      <button disabled className={"btn-info btn text-nowrap ms-1"}>
        Loading Imgs
      </button>
    );

  return (
    <div>
      {!Object.keys(productWithBase64Images).length && <Spinner />}
      <button
        onClick={() => {
          setShowEmailModal(true);
        }}
        className={btnClass && btnClass}
      >
        {btnTitle && btnTitle}
      </button>
      <Toast />
      {/*   Product Modal
       ********************************************* */}
      <Modal
        size="lg"
        contentClassName="bg-transparent"
        show={showEmailModal}
        onHide={() => handleClose()}
      >
        <Modal.Body>
          <div className="bg-white custom-shadow custom-border-radius px-4 py-3">
            <h3>SELECT PRODUCT TYPE</h3>
            <Toast />
            {!emailTo ? (
              <div className="my-3">
                <h3 className="text-danger">This quote has no contact email</h3>
              </div>
            ) : (
              <>
                {/* ******************** Invoice CheckBox ************************* */}
                <div className="d-flex mt-3">
                  <div>
                    <input
                      ref={allCheckBox}
                      onChange={(e) => {
                        setShowInvoicePage(e.target.checked);
                      }}
                      type="checkbox"
                      checked={showInvoicePage}
                      id={"invoicePage"}
                      data-switch="success"
                    />
                    <label
                      htmlFor={"invoicePage"}
                      data-on-label="Yes"
                      data-off-label="No"
                    ></label>
                  </div>
                  <div className="ms-2 fw-bold">Quotation</div>
                </div>
                <div ref={checkBoxContainer}>
                  {productTypeList?.map((item, i) => (
                    <div className="d-flex" key={i}>
                      <div>
                        <input
                          type="checkbox"
                          onChange={handleProductCheckbox}
                          name={item.productType}
                          defaultChecked="true"
                          id={item.productType}
                          data-switch="success"
                        />
                        <label
                          htmlFor={item.productType}
                          data-on-label="Yes"
                          data-off-label="No"
                        ></label>
                      </div>
                      <div className="ms-2 fw-bold">{item.productType}</div>
                    </div>
                  ))}
                </div>
                <hr />
                {/* ******************** Attachments ************************* */}
                <div className="d-flex justify-content-between mb-3">
                  <h3 className="my-0">Attachments</h3>
                  <button
                    className="btn btn-success btn-sm"
                    onClick={() => {
                      setShowFilesModal(true);
                    }}
                  >
                    Choose
                  </button>
                </div>
                <div className="d-flex gap-2 flex-wrap">
                  {selectedFiles &&
                    selectedFiles?.map((item) => (
                      <div
                        key={item}
                        className="bg-white custom-shadow-sm px-2 py-1 d-flex gap-2 rounded-3"
                      >
                        <div>{getFileName(item)}</div>
                        <div
                          role="button"
                          onClick={() => {
                            handleDeleteFile(item);
                          }}
                          className="bg-danger text-white px-1 rounded-3"
                        >
                          X
                        </div>
                      </div>
                    ))}
                </div>
                {isLoading && <Spinner />}
                <hr className="mb-2 mt-3" />
                {/* ******************** Email To && Email Body ************************* */}
                <div className="mb-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <label htmlFor="msgBody" className="form-label">
                      Email To ( "Separate multiple by comma" )
                    </label>
                    <div>
                      <button
                        type="button"
                        onClick={() => {
                          setShowCustomerContact(true);
                        }}
                        className="btn btn-sm btn-success"
                      >
                        Select Contacts
                      </button>
                    </div>
                  </div>
                  <input
                    type="text"
                    value={emailTo}
                    onChange={(e) => {
                      setEmailTo((prev) => e.target.value);
                    }}
                    className="form-control"
                    placeholder="Enter Recipients"
                  />
                </div>
                <div className="mt-3 mb-3 d-flex justify-content-between gap-2">
                  <div className="flex-grow-1">
                    <label htmlFor="locationId" className="form-label">
                      Location
                    </label>
                    <select
                      name="locationId"
                      value={locationId}
                      onChange={(e) => {
                        handleLocationChange(e.target.value);
                      }}
                      className="form-control"
                      required
                    >
                      <option disabled selected>
                        Select Location
                      </option>
                      {locations?.map((item) => (
                        <option key={item?.id} value={item?.id}>
                          {item?.locationName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex-grow-1">
                    <label htmlFor="locationId" className="form-label">
                      Smtp Email
                    </label>
                    <select
                      name="emailSmtp"
                      onChange={(e) => {
                        setSelectedSmtpEmailId((prev) =>
                          parseInt(e.target.value)
                        );
                      }}
                      defaultValue={""}
                      className="form-control"
                      required
                    >
                      <option value="">Select Email</option>
                      {smtpEmailList?.map((item) => (
                        <option key={item?.id} value={item?.id}>
                          {item?.email}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="msgBody" className="form-label">
                    Subject
                  </label>
                  <input
                    type="text"
                    value={subject || ""}
                    required
                    onChange={(e) => {
                      setSubject((prev) => e.target.value);
                    }}
                    className="form-control"
                    placeholder="Enter Subject"
                  />
                </div>
                <div className="">
                  <div className="d-flex justify-content-between align-items-center">
                    <label htmlFor="msgBody" className="form-label">
                      Email Body
                    </label>
                    <div>
                      <button
                        type="button"
                        onClick={() => {
                          setShowTemplateModal(true);
                        }}
                        className="btn btn-sm btn-success"
                      >
                        Select Template
                      </button>
                    </div>
                  </div>

                  <ReactQuill
                    modules={modules}
                    theme="snow"
                    value={emailBody}
                    onChange={(val) => setEmailBody(val)}
                  />
                </div>
              </>
            )}
            {/*   Buttons
             ********************************************* */}
            <div className="text-end my-3 mb-1">
              <button
                type="button"
                className="btn btn-secondary me-1"
                onClick={() => {
                  handleClose();
                }}
              >
                Close
              </button>
              {productTypeList && showPdf && wOProductsData ? (
                <>
                  <BlobProvider
                    document={
                      <EmailPdf
                        productList={productWithBase64Images}
                        selectedWorkOrder={selectedWorkOrder}
                        showInvoicePage={showInvoicePage}
                        onlyProducts={false}
                        locationLogo={locationLogo}
                      />
                    }
                  >
                    {({ url, blob }) => {
                      const sendEmail = async () => {
                        if (!selectedSmtpEmailId) {
                          return alert("Smtp Email is required");
                        }
                        loadingOn();
                        const formData = new FormData();
                        formData.append("file", blob);
                        formData.append("WoId", selectedWorkOrder?.id);
                        formData.append("emailTo", emailTo);
                        formData.append("subject", subject);
                        formData.append("emailBody", emailBody);
                        formData.append("emailSmtpId", selectedSmtpEmailId);
                        formData.append(
                          "selectedFiles",
                          JSON.stringify(selectedFiles)
                        );
                        try {
                          const { data } = await API.post(
                            "/wo-email",
                            formData,
                            {
                              headers: {
                                ...authHeader(),
                                "Content-Type": "multipart/form-data",
                              },
                            }
                          );
                          loadingOff();
                          if (data?.type === "success") {
                            dispatch(
                              setNotification({
                                message: data.message,
                                type: "success",
                              })
                            );
                            loadingOff();
                            setShowEmailModal(false);
                            return;
                          }
                          if (data?.type === "error") {
                            dispatch(
                              setNotification({
                                message: data.message,
                                type: "error",
                              })
                            );
                            loadingOff();
                            setShowEmailModal(false);
                            return;
                          }
                          dispatch(
                            setNotification({
                              message: "Something Went wrong check console.",
                              type: "error",
                            })
                          );
                          console.log(data);
                          loadingOff();
                        } catch (err) {
                          dispatch(
                            setNotification({
                              message: err.response.data.message,
                              type: "error",
                            })
                          );
                          loadingOff();
                        }
                      };
                      return (
                        <>
                          <button
                            disabled={
                              (!url && pdfProducts == [] && !showInvoicePage) ||
                              !blob ||
                              isLoading
                            }
                            onClick={sendEmail}
                            className="btn btn-success  ms-1"
                          >
                            Send Email
                          </button>
                          {!blob && <Spinner />}
                        </>
                      );
                    }}
                  </BlobProvider>
                </>
              ) : (
                <>
                  {!user?.permissions?.includes("work_orders_dataEntry") && (
                    <button
                      onClick={() => {
                        setShowPdf(true);
                        setShowEmailModal(true);
                      }}
                      disabled={
                        (!showInvoicePage && pdfProducts?.length === 0) ||
                        !emailTo
                      }
                      className="btn btn-success  ms-1"
                    >
                      {productWithBase64Images
                        ? "Generate PDF"
                        : "Loading Images"}
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/*    Select Files
       ********************************************* */}
      <Modal
        size="lg"
        contentClassName="bg-transparent"
        show={showFilesModal}
        onHide={() => setShowFilesModal(false)}
      >
        <Modal.Body className="bg-white custom-shadow custom-border-radius">
          <h4 className="h3 mb-3">Select Files</h4>
          <QuoteFileManager
            selectedWO={selectedWorkOrder}
            setSelectedFiles={setSelectedFiles}
            setShowFilesModal={setShowFilesModal}
          />
        </Modal.Body>
      </Modal>
      {/*    Select Email template
       ********************************************* */}
      <Modal
        size="lg"
        contentClassName="bg-transparent"
        show={showTemplateModal}
        onHide={() => setShowTemplateModal(false)}
      >
        <Modal.Body className="bg-white custom-shadow custom-border-radius">
          <h4 className="h3 mb-3">Select Files</h4>

          <SelectEmailTemplate
            selectedWorkOrder={selectedWorkOrder}
            setSubject={setSubject}
            setEmailBody={setEmailBody}
            showModal={setShowTemplateModal}
          />
        </Modal.Body>
      </Modal>
      {/*    Select Customer Contact
       ********************************************* */}
      <Modal
        size="lg"
        contentClassName="bg-transparent"
        show={showCustomerContact}
        onHide={() => setShowCustomerContact(false)}
      >
        <Modal.Body className="bg-white custom-shadow custom-border-radius">
          <h4 className="h3 mb-3">Select Files</h4>

          <CustomerContact
            selectedWorkOrder={selectedWorkOrder}
            setSelectedContacts={setSelectedContacts}
            showModal={setShowCustomerContact}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default WOOrderEmail;
