import Table from "components/DataTable/Table";
import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function WOFileManager({
  selectedWO,
  setSelectedFiles,
  setShowFilesModal,
}) {
  const dispatch = useDispatch();
  /*   ALL STATES
   ********************************************* */
  const [filterBy, setFilterBy] = useState(null);
  const [wOFiles, setWOFiles] = useState(null);

  const [selectedFilesList, setSelectedFilesList] = useState([]);

  /*   All Functions
   ********************************************* */
  //get Data
  const getWOFiles = async () => {
    try {
      const { data } = await API.post(
        "/wO-file/listfiles",
        { id: selectedWO?.id },
        {
          headers: authHeader(),
        }
      );
      setWOFiles(data);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const getFileName = (fileName) => {
    return fileName?.split("/")[fileName?.split("/").length - 1];
  };

  function handleFileSelect(item) {
    const newSet = new Set(selectedFilesList);
    if (newSet.has(item?.fileName)) {
      newSet.delete(item?.fileName);
    } else {
      newSet.add(item?.fileName);
    }
    setSelectedFilesList(Array.from(newSet));
  }

  // Getting 12 Hours Time from SQL timestamp
  const convertSqlTimestampToDate = (sqlTime) => {
    const timestamp = sqlTime;
    const date = new Date(timestamp);
    const time = date.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    return time;
  };

  useEffect(() => {
    getWOFiles();
  }, []);

  /*   Table functions
   ********************************************* */

  const buttons = () => (
    <div className=" d-flex justify-content-end ">
      {/* FilterBy */}
      <button
        type="button"
        className="px-1 bg-primary btn text-dark bg-opacity-10 border border-primary rounded-end mx-2 rounded-start"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {filterBy
          ? filterBy.toUpperCase().replaceAll("_", " ")
          : "Filter by All"}
      </button>
      <div className="dropdown-menu">
        <button onClick={() => setFilterBy(null)} className="dropdown-item">
          Filter by All
        </button>
        <button
          onClick={() => setFilterBy("company_name")}
          className="dropdown-item"
        >
          Company Name
        </button>
        <button
          onClick={() => setFilterBy("qxternal_Quotations_id")}
          className="dropdown-item"
        >
          External ID
        </button>
        <button
          onClick={() => setFilterBy("website")}
          className="dropdown-item"
        >
          Website
        </button>
        <button
          onClick={() => setFilterBy("transit_days")}
          className="dropdown-item"
        >
          Transit Days
        </button>
        <button
          onClick={() => setFilterBy("is_tax_exemption")}
          className="dropdown-item"
        >
          Tax Exampted
        </button>
        <button
          onClick={() => setFilterBy("quotations_type")}
          className="dropdown-item"
        >
          Customer Type
        </button>
      </div>
    </div>
  );
  const table_head = ["Action", "File Name", "Last Updated", "Uploaded By"];

  const table_body = (item, index) => (
    <tr
      onClick={() => {
        handleFileSelect(item);
      }}
      className=""
      key={item.id}
    >
      <td>
        <input
          type="checkbox"
          checked={selectedFilesList?.includes(item?.fileName)}
        />
      </td>
      <td>{getFileName(item.fileName)}</td>
      <td>{convertSqlTimestampToDate(item.updatedAt)}</td>
      <td className="">{item.uploadedBy}</td>
    </tr>
  );
  return (
    <div>
      {/*   Table
       ********************************************* */}
      <div className="px-2 flex-grow-1 d-flex">
        <div className="horizontal-scrollable d-flex flex-grow-1">
          {wOFiles ? (
            <Table
              filterBy={filterBy}
              buttons={buttons}
              table_head={table_head}
              table_body={table_body}
              table_data={wOFiles}
            />
          ) : (
            <Spinner />
          )}
        </div>
      </div>
      <div className="text-end my-2">
        <button
          onClick={() => {
            setShowFilesModal(false);
          }}
          className="btn btn-secondary"
        >
          Close
        </button>
        <button
          onClick={() => {
            setSelectedFiles((prev) => selectedFilesList);
            setShowFilesModal(false);
            setSelectedFilesList([]);
          }}
          disabled={selectedFilesList.length === 0}
          className="btn btn-success ms-1"
        >
          Select Files
        </button>
      </div>
    </div>
  );
}
