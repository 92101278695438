import Table from "components/DataTable/Table";
import Toast from "components/Toast/ToastComponent";
import { setNotification } from "features/Notification/notificationSlice";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import authHeader from "services/auth-header";
import API from "services/axios";
import PortalUsersAdd from "./PortalUsersAdd";
import PortalUsersUpdate from "./PortalUsersUpdate";

function PortalUsers() {
  const dispatch = useDispatch();

  /*   All States Below
   ********************************************* */
  const [portalUsers, setPortalUsers] = useState(null);

  // Modals
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // below update data is used to set a variable before moving to update component
  const [updateData, setUpdateData] = useState(null);

  const [selectedDelete, setSelectedDelete] = useState(null);

  /*   All Functions Below
   ********************************************* */

  // get All portalUsers
  const getAllPortalUsers = async () => {
    try {
      const { data } = await API.get("/portal-users", {
        headers: authHeader(),
      });
      setPortalUsers(data);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  // Delete password Data
  const deletePortalUsersData = async (id) => {
    try {
      const { data } = await API.delete("/portal-users/delete/" + id, {
        headers: authHeader(),
      });
      if (data.message.type === "success") {
        getAllPortalUsers();
        setShowDeleteModal(false);
        return;
      }
      console.log(data);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getAllPortalUsers();
  }, []);

  /*   Table Functions below
   ********************************************* */
  const buttons = () => (
    <div className="d-flex justify-content-end ms-2">
      <button onClick={() => setShowAddModal(true)} className="btn btn-primary">
        ADD NEW
      </button>
    </div>
  );
  const table_head = ["Name", "Email", "Phone", "Action"];
  const table_body = (item, index) => (
    <tr key={item.id}>
      <td>
        {item.firstName} {item.lastName}
      </td>
      <td>{item.email}</td>
      <td>{item.phone}</td>
      <td className="table-action">
        <Link className="action-icon">
          <i
            onClick={() => {
              setShowUpdateModal(true);
              setUpdateData(item);
            }}
            className="mdi mdi-pencil"
          ></i>
        </Link>
        <Link className="action-icon">
          <i
            className="mdi mdi-delete"
            onClick={() => {
              setSelectedDelete(item);
              setShowDeleteModal(true);
            }}
          ></i>
        </Link>
      </td>
    </tr>
  );

  return (
    <div className="row">
      <Toast />
      <h3>Customer Portal Users</h3>
      <div className="col-12">
        <Table
          btn1={buttons}
          table_body={table_body}
          table_data={portalUsers}
          table_head={table_head}
        />

        {/*   ADD Modal
         ********************************************* */}
        <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
          <Modal.Body className="custom-shadow">
            <div>
              <PortalUsersAdd
                showAddPage={setShowAddModal}
                refreshFunc={getAllPortalUsers}
              />
            </div>
          </Modal.Body>
        </Modal>
        {/*   Update Modal
         ********************************************* */}
        <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
          <Modal.Body className="custom-shadow">
            <div>
              <PortalUsersUpdate
                data={updateData}
                showUpdatePage={setShowUpdateModal}
                refreshFunc={getAllPortalUsers}
              />
            </div>
          </Modal.Body>
        </Modal>
        {/*   Delete Modal
         ********************************************* */}

        <Modal
          contentClassName="bg-transparent"
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
        >
          <Modal.Body className="">
            <div className="text-white bg-danger custom-border-radius custom-shadow">
              <div className="p-4">
                <div className="text-center">
                  <i className="ri-close-circle-line h1"></i>
                  <h4 className="mt-2">Confirm Delete!</h4>
                  <p className="mt-3">Do You want to delete ?</p>
                  <button
                    type="button"
                    className="btn btn-light my-2 mx-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-light my-2 "
                    data-bs-dismiss="modal"
                    onClick={() => {
                      deletePortalUsersData(selectedDelete.id);
                    }}
                  >
                    DELETE
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default PortalUsers;
