import { useEffect, useState } from 'react';

export default function AddByStitch({ formData, addMatrix, updateMatrix }) {
  /*   All States
   ********************************************* */
  const [additionalCost, setAdditionalCost] = useState(0);
  const [additionalCostRate, setAdditionalCostRate] = useState(0);

  const [labelCount, setLabelCount] = useState(1);
  const [qtyLevelCount, setQtyLevelCount] = useState(1);
  let defaultQuantityName = 'Quantity';

  const [columnName, setColumnName] = useState('Stitch');
  const [tableHead, setTableHead] = useState([defaultQuantityName, `1 ${columnName}`]);
  const [tableBody, setTableBody] = useState([
    {
      [`${defaultQuantityName}`]: 1,
      [`1 ${columnName}`]: '',
    },
  ]);

  /*   All Functions
   ********************************************* */
  const handleLabelChange = () => {
    let itemsLength = Object.keys(tableBody[0]).length;
    let labelCountInt = parseInt(labelCount) + 1;

    if (labelCountInt === itemsLength) return;

    if (labelCountInt < itemsLength) {
      let tableData = tableBody.map((item) => {
        let newItem = {};
        Object.keys(item).forEach((key, index) => {
          let limit = isNaN(parseInt(key.split(' ')[1])) ? 0 : parseInt(key.split(' ')[1]);

          if (limit < labelCountInt || key.includes(defaultQuantityName)) {
            newItem[key] = item[key];
          }
        });

        return newItem;
      });
      setTableHead(tableHead.slice(0, labelCountInt));

      return setTableBody(tableData);
    } else {
      let lastCountNumber = itemsLength - 1;

      let tableData = tableBody?.map((item, index) => {
        let newItem = { ...item };
        Array.from(Array(labelCountInt - (lastCountNumber + 1)).keys()).map((item, index) => {
          newItem[`${lastCountNumber + (index + 1)} ${columnName}`] = '';
        });

        return newItem;
      });
      Array.from(Array(labelCountInt - (lastCountNumber + 1)).keys()).map((item, index) => {
        setTableHead((prev) => {
          return [...prev, `${lastCountNumber + (index + 1)} ${columnName}`];
        });
      });

      setTableBody(tableData);
    }
  };

  const handleQtyChange = () => {
    let tableData = [];
    if (tableBody.length > qtyLevelCount) {
      let tableData = tableBody.slice(0, qtyLevelCount);
      return setTableBody(tableData);
    } else {
      Array.from(Array(parseInt(qtyLevelCount - tableBody.length)).keys()).map((item, index) => {
        let data = {};
        Object.keys(tableBody[0])?.map((item) => {
          data[item] = '';
        });
        tableData.push({ ...data, [`${defaultQuantityName}`]: tableBody.length + (index + 1) });
      });

      setTableBody([...tableBody, ...tableData]);
    }
  };

  const handleCellEdit = (e, count, field) => {
    // Condition :: Value Must be Number and have 2 decimal number
    // Solution  :: converting received value to number and
    //              using method "toFixed()" to get 2 decimal number
    let rawValue = e.target.innerText;
    const getFloatValue = (rawValue) => {
      let value = 0;
      if (rawValue.includes('.')) {
        let decimalSplit = rawValue.split('.');
        value = parseFloat(decimalSplit[0] + '.' + decimalSplit[1].slice(0, 2));
      } else {
        value = parseInt(e.target.innerText);
      }
      return value;
    }; // =================================================

    // Condition :: Value Must be Number and have 2 decimal number
    // Solution  :: Using Math.round() to get 2 decimal number

    // let value = Math.round(parseFloat(rawValue) * 100) / 100;

    // Update :: Not using this method because it automatically rounds off
    //           1.565 to 1.57. Need to exclude all number after 2 decimals
    //           placed without rounding off.
    // =================================================

    // Condition :: New Value Must be greater than Previous Value
    // Solution  :: Getting previous Value using combination of parentNode and
    //              FirstChild properties
    let previousCountValue = 0;
    if (e.target === e.target.parentNode.firstChild) {
      previousCountValue =
        e.target?.parentNode?.previousSibling &&
        parseInt(e.target?.parentNode?.previousSibling?.firstChild?.innerText);
    } /*  ============================================================= */

    const value = getFloatValue(rawValue);

    // Update Value in JSON
    if (!isNaN(value) && value > previousCountValue) {
      const updatedData = tableBody.map((item) => {
        if (item[`${defaultQuantityName}`] === count) {
          return { ...item, [field]: value };
        }
        e.target.innerText = value;
        return item;
      });
      setTableBody(updatedData);
    } else {
      const updatedData = tableBody.map((item) => {
        if (item[`${defaultQuantityName}`] === count) {
          return { ...item, [field]: '' };
        }
        e.target.innerText = value;
        return item;
      });
      setTableBody(updatedData);
      e.target.innerText = '';
    }
  };

  const handleColumnNameChange = (e, columnName) => {
    let newValue = e.target.innerText;
    if (newValue === columnName || tableHead.includes(newValue)) {
      e.target.innerText = columnName;
      return;
    }
    let updatedColumnJson = tableBody?.map((item) => {
      let newItem = { ...item };
      Object.keys(item).map((key) => {
        if (key === columnName && !(newValue === columnName)) {
          newItem[newValue] = item[key];
          delete newItem[key];
        }
      });

      return newItem;
    });

    setTableHead((prev) => {
      prev[prev.indexOf(columnName)] = newValue;
      return prev;
    });

    setTableBody(updatedColumnJson);
  };

  const handleChangeAllColumnName = (colName) => {
    /*   Changing the table Column
     ********************************************* */
    let newArr = [];
    tableHead?.map((item) => {
      if (item != 'Quantity') {
        newArr.push(`${item.split(' ')[0]} ${colName}`);
      }
    });
    setTableHead(['Quantity', ...newArr]);

    /*   Changing the table Body
     ********************************************* */
    const newTableBody = tableBody?.reduce((result, currItem) => {
      let newItem = {};
      Object.keys(currItem).map((key) => {
        if (key === 'Quantity') {
          newItem[key] = currItem[key];
        } else {
          newItem[`${key.split(' ')[0]} ${colName}`] = currItem[key];
        }
      });
      result.push(newItem);

      return result;
    }, []);
    setTableBody(newTableBody);
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    if (formData) {
      let data = formData?.matrixJson ? JSON.parse(formData?.matrixJson) : null;
      if (data) {
        let tHead = data?.tableHead;
        let tbody = data?.tableBody;
        setLabelCount(Object.keys(tbody[0])?.length - 1);
        setQtyLevelCount(tbody.length);
        setTableHead(tHead);
        setTableBody(tbody);
        setColumnName(tHead[1].split(' ')[1]);
        setColumnName(data?.columnName);
        setAdditionalCost(data?.additionalCostQuantity);
        setAdditionalCostRate(data?.additionalCostPrice);
      }
    }
  }, [formData]);

  /*   Temporarily getting values
   ********************************************* */

  const [selectedQuantity, setSelectedQuantity] = useState(null);
  // const [selectedColumn, setSelectedColumn] = useState(null);
  const [stitchCount, setStitchCount] = useState(0);

  const [generatedPrice, setGeneratedPrice] = useState(0);

  useEffect(() => {
    if (tableBody) {
      let quantityList = [];
      tableBody.map((item) => {
        quantityList.push(item[Object.keys(item)[0]]);
      });
      setSelectedQuantity(quantityList[0]);
    }
  }, [tableBody]);

  useEffect(() => {
    if (tableBody) {
      let quantityList = [];
      tableBody.map((item) => {
        quantityList.push(item[Object.keys(item)[0]]);
      });

      /* ******************** Get Column  ************************* */
      let selectedColumn = '';
      if (stitchCount <= parseInt(tableHead[1].split(' ')[0])) {
        selectedColumn = tableHead[1];
      } else if (stitchCount >= parseInt(tableHead[tableHead.length - 1].split(' ')[0])) {
        selectedColumn = tableHead[tableHead.length - 1];
      } else {
        for (let i = 1; i < tableHead?.length; i++) {
          if (stitchCount < parseInt(tableHead[i].split(' ')[0])) {
            selectedColumn = tableHead[i - 1];
            break;
          }
        }
      }

      /* *********** Get Price By Quantity Row ********************* */
      let totalPrice = 0;

      if (selectedQuantity >= quantityList[quantityList.length - 1]) {
        let price = parseFloat(tableBody[tableBody.length - 1][selectedColumn]);
        totalPrice = price;
      }

      if (stitchCount > parseInt(tableHead[tableHead.length - 1].split(' ')[0])) {
        let noOfUnits = (stitchCount - parseInt(tableHead[tableHead.length - 1])) / additionalCost;
        totalPrice = totalPrice + additionalCostRate * noOfUnits;
      }
      for (let i = 1; i < tableBody?.length; i++) {
        if (selectedQuantity < quantityList[i]) {
          let price = parseFloat(tableBody[i - 1][selectedColumn]);
          totalPrice = price;

          break;
        }
      }
      setGeneratedPrice(Math.round(parseFloat(totalPrice) * 100) / 100);
    }
  }, [selectedQuantity, stitchCount, tableBody, additionalCost, additionalCostRate]);

  return (
    <div>
      <div className='d-flex'>
        <div className='w-75'>
          {/*   Buttons & Inputs
           ********************************************* */}
          <div className='mb-2'>
            <div className='row'>
              <label className='text-nowrap my-auto col-3'>Columns Name</label>
              <div className='col-2 my-auto'>
                <input
                  value={columnName}
                  onChange={(e) => setColumnName(e.target.value)}
                  placeholder='Label'
                  type='text'
                  className='form-control '
                />
              </div>
              <button
                onClick={() => {
                  handleChangeAllColumnName(columnName);
                }}
                className='col-1 btn btn-sm btn-success my-auto'
              >
                Set
              </button>
            </div>
            <div className='row'>
              <label className='text-nowrap my-auto col-3'>Columns Count</label>
              <div className='col-2 my-1'>
                <input
                  min={1}
                  value={labelCount}
                  onChange={(e) => setLabelCount(e.target.value)}
                  placeholder='Label'
                  type='number'
                  className='form-control'
                />
              </div>
              <button onClick={handleLabelChange} className='col-1 btn btn-sm btn-success my-auto'>
                Set
              </button>
            </div>
            <div className='row mb-1'>
              <label className='text-nowrap my-auto col-3'>Qty Count</label>
              <div className='col-2 my-auto'>
                <input
                  value={qtyLevelCount}
                  onChange={(e) => setQtyLevelCount(e.target.value)}
                  placeholder='Quantity'
                  type='number'
                  min={1}
                  className='form-control'
                />
              </div>
              <button onClick={handleQtyChange} className='col-1 btn btn-sm btn-success my-auto'>
                Set
              </button>
            </div>

            <div className='row mb-1'>
              <label className='  my-auto col-3'>Additional Cost Per</label>
              <div className='col-2 my-auto'>
                <input
                  placeholder='extra %'
                  value={additionalCost}
                  onChange={(e) => setAdditionalCost(parseFloat(e.target.value))}
                  type='number'
                  className='form-control'
                />
              </div>
              <div className='col-1 text-left my-auto'> {tableHead[1].split(' ')[1]} At</div>
              <div className='col-2 px-2 my-auto'>
                <input
                  placeholder='Limit'
                  value={additionalCostRate}
                  min={0}
                  step={0.01}
                  onChange={(e) => setAdditionalCostRate(parseFloat(e.target.value))}
                  type='number'
                  className='form-control'
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className=' bg-white custom-shadow my-3 px-3 py-3 custom-border-radius '
          style={{ width: '50%' }}
        >
          <div className='d-flex gap-1'>
            <div className='w-50'>
              Enter Quantity
              <input
                name='quantityRow'
                type='number'
                min={1}
                value={selectedQuantity}
                onChange={(e) => {
                  setSelectedQuantity(parseInt(e.target.value));
                }}
                className='form-control'
              />
            </div>
            <div className='w-50'>
              Stitch Count
              <input
                type='number'
                placeholder='Number of stitches'
                value={stitchCount}
                onChange={(e) => {
                  setStitchCount(parseFloat(e.target.value));
                }}
                className='form-control'
              />
            </div>
          </div>

          <div className='d-flex mt-2 align-items-center gap-2'>
            <div>Price</div>
            <div className='w-100 bg-primary bg-opacity-25  custom-border-radius-sm p-1 text-center'>
              {generatedPrice}
            </div>
          </div>
        </div>
      </div>
      {/*   Matrix Data Table
       ********************************************* */}
      {tableBody && (
        <table className='table table-bordered'>
          <thead className='thead-dark'>
            <tr>
              {tableHead?.map((item) => (
                <th
                  contentEditable={!(item === defaultQuantityName)}
                  onBlur={(e) => {
                    handleColumnNameChange(e, item);
                  }}
                  suppressContentEditableWarning={true}
                  key={item}
                >
                  {item}
                </th>
              ))}
              {/* {Object.keys(tableBody[0])?.map((item) => (
          <th
            contentEditable={!(item === defaultQuantityName)}
            onBlur={(e) => {
              handleColumnNameChange(e, item);
            }}
            suppressContentEditableWarning={true}
            key={item}
          >
            {item}
          </th>
        ))} */}
            </tr>
          </thead>
          <tbody>
            {tableBody.map((item, index) => (
              <tr key={index}>
                {tableHead?.map((i, index) => (
                  <td
                    suppressContentEditableWarning={true}
                    contentEditable={true}
                    onBlur={(e) => handleCellEdit(e, item[`${defaultQuantityName}`], i)}
                    key={index}
                  >
                    {item[i]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <div className='d-flex justify-content-end'>
        <button
          onClick={() => {
            formData?.id
              ? updateMatrix({
                  data: {
                    ...formData,
                    type: 'By Stitch',
                    matrixJson: JSON.stringify({
                      tableBody,
                      tableHead,
                      additionalCostQuantity: additionalCost,
                      additionalCostPrice: additionalCostRate,
                    }),
                  },
                })
              : addMatrix({
                  data: {
                    ...formData,
                    type: 'By Stitch',
                    matrixJson: JSON.stringify({
                      tableBody,
                      tableHead,
                      additionalCostQuantity: additionalCost,
                      additionalCostPrice: additionalCostRate,
                    }),
                  },
                });
          }}
          className='btn btn-primary'
        >
          {formData?.id ? 'Update' : 'Add'}
        </button>
      </div>
    </div>
  );
}
