import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import QuoteProducts from "../QuoteProducts/QuoteProducts";
import QuoteInfo from "./QuoteInfo";

function ManageQuotes({ formData, setFormData, selectedQuote, refreshFunc }) {
  /*   All States Below
   ********************************************* */
  const user = useSelector((state) => state.user?.userInfo);

  const disableCustomerSelect =
    user?.permissions?.includes("quotes_add") ||
    user?.permissions?.includes("quotes_update") ||
    user?.permissions?.includes("quotes_delete") ||
    user?.roles === "admin";

  /*   All Functions Below
   ********************************************* */

  /*   All UseEffects
   ********************************************* */

  return (
    <div className="row">
      <div className="col-12">
        <div className="d-flex gap-2 mb-2">
          {selectedQuote && (
            <QuoteInfo
              formData={formData}
              setFormData={setFormData}
              refreshFunc={refreshFunc}
              selectedQuote={selectedQuote}
              disableCustomerSelect={disableCustomerSelect}
            />
          )}
        </div>
        <div className="d-flex gap-2 mb-2 pt-3 bg-white custom-shadow custom-border-radius py-2">
          {selectedQuote && (
            <QuoteProducts
              refreshFunc={refreshFunc}
              selectedQuote={selectedQuote}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ManageQuotes;
