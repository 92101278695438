import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import authHeader from 'services/auth-header';
import API from 'services/axios';

function PaymentsTermsUpdate({ data, showUpdatePage, refreshFunc }) {
  /*   All Satates Below
   ********************************************* */
  const { register, handleSubmit } = useForm();
  const [formError, setFormError] = useState({});

  /*   All Functions Below
   ********************************************* */
  const updateSalesPersonsData = async (formData, e) => {
    setFormError({});
    e.preventDefault();
    if (formData.name === '') {
      setFormError({
        name: { type: 'name', message: 'Name is required' },
      });
      return;
    }
    try {
      const resp = await API.patch(
        '/salesPersons/',
        { data: { ...formData, id: data.id } },
        { headers: authHeader() }
      );

      if (resp.data.message.type === 'success') {
        refreshFunc();
        showUpdatePage(false);
        return;
      }
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <div className='row'>
      <div className='col-12'>
        {/*    Update FORM START
         ********************************************* */}
        {data && (
          <form className='ps-3 pe-3' onSubmit={handleSubmit(updateSalesPersonsData)}>
            <div className='mb-3'>
              <h3>UPDATE {data?.term_name?.toUpperCase()}</h3>
            </div>
            {/* ----------  Fields  ----------- */}
            <div className='mb-3'>
              <label htmlFor='name' className='form-label'>
                Name
              </label>
              <input
                className='form-control'
                type='text'
                id='name'
                defaultValue={data?.name}
                placeholder='Enter term name'
                {...register('name')}
              />
              {formError && formError.name && (
                <p className='text-danger'>{formError.name.message}</p>
              )}
            </div>
            <div className='mb-3'>
              <label htmlFor='email' className='form-label'>
                Email
              </label>
              <input
                className='form-control'
                type='text'
                id='email'
                placeholder='Enter email'
                defaultValue={data?.email}
                {...register('email')}
              />
            </div>
            <div className='mb-3'>
              <label htmlFor='phone' className='form-label'>
                Phone
              </label>
              <input
                className='form-control'
                type='text'
                id='phone'
                placeholder='Enter phone'
                defaultValue={data?.phone}
                {...register('phone')}
              />
            </div>

            <div className={`mt-4 mb-2 d-flex justify-content-end`}>
              <div>
                <button
                  className='btn px-1 btn-secondary'
                  type='button'
                  onClick={() => {
                    showUpdatePage(false);
                  }}
                >
                  Cancel
                </button>
                <button className='btn btn-primary ms-1' type='submit'>
                  UPDATE
                </button>
              </div>
            </div>
          </form>
        )}
        {/*    Update FORM END
         ********************************************* */}
      </div>
    </div>
  );
}

export default PaymentsTermsUpdate;
