import { Image, Text, View } from "@react-pdf/renderer";
import React from "react";

export default function QuotePdfHeader({ locationLogo, logo, selectedQuote }) {
  /*   All States
   ********************************************* */

  /*   All Functions
   ********************************************* */
  const convertSqlTimestampToDate = (sqlTime) => {
    const timestamp = sqlTime;
    const date = new Date(timestamp);
    const time = date.toLocaleString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    return time.replaceAll("/", "-");
  };

  /*   All UseEffects
   ********************************************* */
  return (
    <View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <View style={{}}>
          {locationLogo ? (
            <Image
              src={locationLogo}
              style={{
                width: 140,
                height: 60,
              }}
            />
          ) : (
            <Image
              src={logo}
              style={{
                width: 140,
                height: 60,
              }}
            />
          )}
        </View>
        <View
          style={{
            minWidth: 80,
            alignItems: "flex-end",
          }}
        >
          <View
            style={{
              gap: 5,
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            {/*   Column Names
             ********************************************* */}

            <View style={{}}>
              <Text style={{ fontSize: 12, fontWeight: "extrabold" }}>
                {selectedQuote?.bidId ? "Bid ID" : "Quote ID"}
              </Text>
              <Text style={{ fontSize: 12, fontWeight: "extrabold" }}>
                Date
              </Text>
              <Text style={{ fontSize: 12, fontWeight: "extrabold" }}>
                Print Date
              </Text>
            </View>
            <View style={{}}>
              <Text style={{ fontSize: 12, fontWeight: "extrabold" }}>:</Text>
              <Text style={{ fontSize: 12, fontWeight: "extrabold" }}>:</Text>
              <Text style={{ fontSize: 12, fontWeight: "extrabold" }}>:</Text>
            </View>
            {/*   Column Value
             ********************************************* */}
            <View
              style={{
                flexGrow: 1,
              }}
            >
              <Text
                style={{
                  alignSelf: "flex-end",
                  fontSize: 12,
                  fontWeight: "extrabold",
                }}
              >
                {selectedQuote?.id}
              </Text>
              <Text
                style={{
                  alignSelf: "flex-end",
                  fontSize: 12,
                  fontWeight: "extrabold",
                }}
              >
                {convertSqlTimestampToDate(selectedQuote?.createdAt)}
              </Text>
              <Text
                style={{
                  alignSelf: "flex-end",
                  fontSize: 12,
                  fontWeight: "extrabold",
                }}
              >
                {convertSqlTimestampToDate(new Date())}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}
