import React, { useEffect, useState } from 'react';

export default function ByStitchCalculator({ matrix, setLocation, setColors, setCalculatedPrice }) {
  /*   All States
   ********************************************* */
  const [tableHead, setTableHead] = useState(null);
  const [tableBody, setTableBody] = useState(null);

  const [additionalCost, setAdditionalCost] = useState(0);
  const [additionalCostRate, setAdditionalCostRate] = useState(0);

  const [selectedQuantity, setSelectedQuantity] = useState(null);
  // const [selectedColumn, setSelectedColumn] = useState(null);
  const [stitchCount, setStitchCount] = useState(0);

  const [generatedPrice, setGeneratedPrice] = useState(0);

  /*   All Functions
   ********************************************* */

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    let data = matrix?.matrixJson ? JSON.parse(matrix?.matrixJson) : null;
    if (data) {
      let tHead = data?.tableHead;
      let tbody = data?.tableBody;
      setTableHead(tHead);
      setTableBody(tbody);
      setAdditionalCost(data?.additionalCostQuantity);
      setAdditionalCostRate(data?.additionalCostPrice);
    }
  }, [matrix]);
  useEffect(() => {
    if (tableBody) {
      let quantityList = [];
      tableBody.map((item) => {
        quantityList.push(item[Object.keys(item)[0]]);
      });
      setSelectedQuantity(quantityList[0]);
    }
  }, [tableBody]);

  useEffect(() => {
    if (tableBody) {
      let quantityList = [];
      tableBody.map((item) => {
        quantityList.push(item[Object.keys(item)[0]]);
      });

      /* ******************** Get Column  ************************* */
      let selectedColumn = '';
      for (let i = 1; i < tableHead?.length; i++) {
        if (stitchCount < parseInt(tableHead[i].split(' ')[0])) {
          selectedColumn = tableHead[i - 1];
          break;
        }
      }
      if (stitchCount >= parseInt(tableHead[tableHead.length - 1].split(' ')[0])) {
        selectedColumn = tableHead[tableHead.length - 1];
      }

      /* *********** Get Price By Quantity Row ********************* */
      let totalPrice = 0;
      for (let i = 1; i < tableBody?.length; i++) {
        if (selectedQuantity < quantityList[i]) {
          let price = parseFloat(tableBody[i - 1][selectedColumn]);
          totalPrice = price;
          break;
        }
      }
      if (selectedQuantity >= quantityList[quantityList.length - 1]) {
        let price = parseFloat(tableBody[tableBody.length - 1][selectedColumn]);
        totalPrice = price;
      }

      if (stitchCount > parseInt(tableHead[tableHead.length - 1].split(' ')[0])) {
        let noOfUnits = (stitchCount - parseInt(tableHead[tableHead.length - 1])) / additionalCost;
        totalPrice = totalPrice + additionalCostRate * noOfUnits;
      }
      setGeneratedPrice(Math.round(parseFloat(totalPrice) * 100) / 100);
    }
  }, [selectedQuantity, stitchCount, tableBody, additionalCost, additionalCostRate]);

  useEffect(() => {
    setCalculatedPrice(generatedPrice);
  }, [generatedPrice]);

  return (
    <div>
      <div className='  '>
        <div className='d-flex gap-1'>
          <div className='w-50'>
            Enter Quantity
            <input
              name='quantityRow'
              type='number'
              min={1}
              value={selectedQuantity}
              onChange={(e) => {
                setSelectedQuantity(parseInt(e.target.value));
              }}
              className='form-control'
            />
          </div>
          <div className='w-50'>
            Stitch Count
            <input
              type='number'
              placeholder='Number of stitches'
              value={stitchCount}
              onChange={(e) => {
                setStitchCount(parseFloat(e.target.value));
              }}
              className='form-control'
            />
          </div>
        </div>

        <div className='d-flex mt-2 align-items-center'>
          <div className='w-25'>Price</div>
          <div className='w-100 bg-primary bg-opacity-25  custom-border-radius-sm p-1 text-center'>
            {generatedPrice}
          </div>
        </div>
        <div className='row mt-2 align-items-center'>
          <div className='w-25'>Location</div>
          <div className='flex-grow-1 w-100'>
            <input
              type='text'
              onChange={(e) => setLocation(e.target.value)}
              placeholder='Enter location'
              className='form-control'
            />
          </div>
        </div>
        <div className='row mt-3 align-items-center'>
          <div className='col-3'>Colors</div>
          <div className='flex-grow-1 w-100'>
            <input
              type='text'
              onChange={(e) => setColors(e.target.value)}
              placeholder='Enter location'
              className='form-control'
            />
          </div>
        </div>
      </div>
    </div>
  );
}
