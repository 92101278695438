import Spinner from "components/Spinner";
import Toast from "components/Toast/ToastComponent";
import { setNotification } from "features/Notification/notificationSlice";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function WODetails({ selectedWorkOrder, refreshFunc }) {
  const dispatch = useDispatch();

  /*   All States
   ********************************************* */
  const [showUpdateModal, setShowUpdateModal] = useState(null);
  const [WODetails, setWODetails] = useState(null);

  const [paymentTermsList, setPaymentTermsList] = useState(null);
  const [salesPersonsList, setSalesPersonsList] = useState(null);
  const [shippingMethodsList, setShippingMethodsList] = useState(null);

  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(false);

  /*   All Functions
   ********************************************* */
  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  const getDate = (inputDate) => {
    const date = new Date(inputDate);
    return date.toISOString().slice(0, 10).replace("T", " ");
  };

  const handleUpdateWoDetails = async (e) => {
    e.preventDefault();
    try {
      loadingOn();
      let WODetailsData = {
        locationId: formData?.locationId,
        WODate: formData?.WODate,
        WOShipDate: formData?.WOShipDate,
        paymentTermsId: formData?.paymentTermsId,
        salesPersonId: formData?.salesPersonId,
        shipmentMethod: formData?.shipmentMethod,
      };
      let apiData = {
        id: selectedWorkOrder?.id,
        data: WODetailsData,
      };
      const { data } = await API.patch("/work-orders", apiData, {
        headers: authHeader(),
      });
      if (data?.message?.type === "success") {
        await refreshFunc();
        loadingOff();
        setShowUpdateModal(false);
        return;
      }
      console.log(data);
      alert("something went wrong check logs");
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const getWODetailsData = async () => {
    try {
      loadingOn();
      const { data } = await API.get("/work-order/drop-down-details", {
        headers: authHeader(),
      });
      if (data?.type === "success") {
        setPaymentTermsList(data?.result?.paymentTermsList);
        setSalesPersonsList(data?.result?.salesPersonsList);
        setShippingMethodsList(data?.result?.shipmentMethodsList);
        loadingOff();
        return;
      }
      console.log(data);

      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    setWODetails({
      location: selectedWorkOrder?.location,
      WODate: selectedWorkOrder?.WODate,
      WOShipDate: selectedWorkOrder?.WOShipDate,
      salesPerson: selectedWorkOrder?.salesPerson,
      taxRate: selectedWorkOrder?.taxrate,
      paymentTerm: selectedWorkOrder?.paymentTerm,
      shippingMethod: selectedWorkOrder?.shippingMethod,
    });
    setFormData({
      WODate: getDate(selectedWorkOrder?.WODate),
      WOShipDate: getDate(selectedWorkOrder?.WOShipDate),
      salesPersonId: selectedWorkOrder?.salesPersonId,
      paymentTermsId: selectedWorkOrder?.paymentTermsId,
      shipmentMethod: selectedWorkOrder?.shipmentMethod,
    });
  }, [selectedWorkOrder, showUpdateModal]);

  useEffect(() => {
    getWODetailsData();
  }, []);

  if (!WODetails) return <Spinner />;
  return (
    <div className="bg-white shadow-lg w-50 px-3 pb-2 custom-border-radius-sm">
      {loading && <Spinner />}
      {/*   WO Info
       ********************************************* */}
      <div className="d-flex inline-block justify-content-between align-items-center">
        <h4 className="mb-0">WO Details</h4>
        <h4 className="mb-0">
          <button
            type="button"
            className="btn btn-info btn-sm"
            onClick={() => setShowUpdateModal(true)}
          >
            Edit
          </button>
        </h4>
      </div>
      <hr className="mt-0 mb-1 w-50" />
      {/* Location */}
      <div className="row">
        <div className="col-4 fw-bold">Location</div>
        <div className="col-8">{WODetails?.location?.name}</div>
      </div>
      {/* WO Date */}
      <div className="row">
        <div className="col-4 fw-bold">WO Date</div>
        <div className="col-8">{getDate(WODetails?.WODate)}</div>
      </div>
      {/* WO Ship date */}
      <div className="row">
        <div className="col-4 fw-bold">Due date</div>
        <div className="col-8">{getDate(WODetails?.WOShipDate)}</div>
      </div>
      {/* Tax Rate */}
      <div className="row">
        <div className="col-4 fw-bold">Tax Rate</div>
        <div className="col-8">{WODetails?.taxRate?.rate}</div>
      </div>
      {/* Payment Term */}
      <div className="row">
        <div className="col-4 fw-bold">Payment Term</div>
        <div className="col-8">{WODetails?.paymentTerm?.name}</div>
      </div>
      {/* Sales Person */}
      <div className="row">
        <div className="col-4 fw-bold">Sales Person</div>
        <div className="col-8">{WODetails?.salesPerson?.name}</div>
      </div>
      {/* Shipment Method */}
      <div className="row">
        <div className="col-4 fw-bold">Shipment Method</div>
        <div className="col-8">{WODetails?.shippingMethod?.name}</div>
      </div>

      {/*   Customer Select Modal
       ********************************************* */}
      <Modal
        size=""
        contentClassName="bg-transparent"
        show={showUpdateModal}
        onHide={() => setShowUpdateModal(false)}
      >
        <Modal.Body>
          <div
            className="bg-white shadow-lg px-3 pb-2 pt-2 mb-2 me-2"
            style={{ borderRadius: 20 }}
          >
            <div className="fw-bold fs-4 mt-3 d-flex justify-content-between">
              <div>WO Details</div>
            </div>
            <hr className="my-0 mb-2" />
            <form onSubmit={handleUpdateWoDetails} className=" p-1">
              <div className=" p-1">
                <div className="row mb-1">
                  <label className="col-5">WO Date</label>
                  <div className="col-7">
                    <input
                      className="form-control flex-grow-1 mx-0"
                      placeholder="Enter address company"
                      value={formData?.WODate}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          WODate: e.target.value,
                        })
                      }
                      type="date"
                    />
                  </div>
                </div>
                <div className="row mb-1">
                  <label className="col-5">Due date</label>
                  <div className="col-7">
                    <input
                      className="form-control flex-grow-1 mx-0"
                      placeholder="Enter address company"
                      type="date"
                      value={formData?.WOShipDate}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          WOShipDate: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="row mb-1">
                  <label className="col-5">Payment Term</label>
                  <div className="col-7">
                    <select
                      value={
                        formData?.paymentTermsId
                          ? formData?.paymentTermsId
                          : "None"
                      }
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          paymentTermsId: parseInt(e.target.value),
                        })
                      }
                      className="form-select"
                    >
                      <option value={"null"}>None</option>
                      {paymentTermsList?.map((term) => (
                        <option key={term.id} value={term.id}>
                          {term.termName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row mb-1">
                  <label className="col-5">Sales Person</label>
                  <div className="col-7">
                    <select
                      value={
                        formData?.salesPersonId
                          ? formData?.salesPersonId
                          : "None"
                      }
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          salesPersonId: parseInt(e.target.value),
                        })
                      }
                      className="form-select"
                    >
                      <option value={"null"}>None</option>
                      {salesPersonsList?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row mb-1">
                  <label className="col-5">Shipment Method</label>
                  <div className="col-7">
                    <select
                      value={formData?.shipmentMethod || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          shipmentMethod: parseInt(e.target.value),
                        })
                      }
                      className="form-select"
                    >
                      <option value={"null"}>None</option>
                      {shippingMethodsList?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.shippingMethod}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="text-end mt-3">
                <button
                  onClick={() => {
                    setShowUpdateModal(false);
                  }}
                  type="button"
                  className="btn btn-secondary"
                >
                  Close
                </button>
                <button type="submit" className="btn btn-success ms-1">
                  Save
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
