import React, { useState } from 'react';
import ByQuantityCalculator from './ByQuantityCalculator/ByQuantityCalculator';
import ByAreaCalculator from './ByAreaCalculator/ByAreaCalculator';
import BySheetCalculator from './BySheetCalculator/BySheetCalculator';
import ByStitchCalculator from './ByStitchCalculator/ByStitchCalculator';

export default function PrizeCalculatorModal({
  selectedMatrix,
  setAllSelectedMatrixes,
  closeModal,
}) {
  /*   All States
   ********************************************* */

  const [location, setLocation] = useState('');
  const [colors, setColors] = useState('');
  const [calculatedPrice, setCalculatedPrice] = useState(0);

  /*   All Functions
   ********************************************* */
  let MATRIX = {
    'By Quantity': (
      <ByQuantityCalculator
        matrix={selectedMatrix}
        setColors={setColors}
        setLocation={setLocation}
        setCalculatedPrice={setCalculatedPrice}
      />
    ),
    'By Area': (
      <ByAreaCalculator
        matrix={selectedMatrix}
        setColors={setColors}
        setLocation={setLocation}
        setCalculatedPrice={setCalculatedPrice}
      />
    ),
    'By Sheet': (
      <BySheetCalculator
        matrix={selectedMatrix}
        setColors={setColors}
        setLocation={setLocation}
        setCalculatedPrice={setCalculatedPrice}
      />
    ),
    'By Stitch': (
      <ByStitchCalculator
        matrix={selectedMatrix}
        setColors={setColors}
        setLocation={setLocation}
        setCalculatedPrice={setCalculatedPrice}
      />
    ),
  };

  /*   All UseEffects
   ********************************************* */
  return (
    <div>
      <div className=''>
        <h4>Calculate Price {selectedMatrix?.type}</h4>
      </div>
      <hr className='mt-0' />
      <div>
        <div>{MATRIX[selectedMatrix?.type]}</div>
      </div>
      <div className='mt-3 text-end'>
        <button
          onClick={() => {
            closeModal(false);
          }}
          className='btn btn-secondary me-1'
        >
          Close
        </button>
        <button
          onClick={() => {
            setAllSelectedMatrixes((prev) => [
              ...prev,
              { type: selectedMatrix?.type, location, colors, calculatedPrice },
            ]);
            closeModal(false);
          }}
          className='btn btn-primary'
        >
          Add
        </button>
      </div>
    </div>
  );
}
