import Table from "components/DataTable/Table";
import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import { useState } from "react";
import { useDispatch } from "react-redux";

function ContactSelectModal({ setFormData, contactsList, showModal }) {
  const dispatch = useDispatch();

  /*   All States
   ********************************************* */
  const [filterBy, setFilterBy] = useState();

  const [loading, setLoading] = useState(false);

  /*   All functions
   ********************************************* */

  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  const handleUpdateWOContactData = async (e) => {
    e.preventDefault();
    try {
      loadingOn();
      // let contactData = {
      //   firstName: formData?.firstName,
      //   lastName: formData?.lastName,
      //   department: formData?.department,
      //   phone: formData?.phone,
      //   email: formData?.email,
      // };
      // let apiData = {
      //   id: selectedWorkOrder?.id,
      //   data: { contactData },
      // };
      // const { data } = await API.patch("/work-orders", apiData, {
      //   headers: authHeader(),
      // });
      // if (data?.message?.type === "success") {
      //   await refreshFunc();
      //   loadingOff();
      //   showModal(false);
      //   return;
      // }
      // console.log(data);
      alert("something went wrong check logs");
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const handleContactSelect = (item) => {
    setFormData((prev) => {
      return {
        firstName: item?.firstName,
        lastName: item?.lastName,
        department: item?.department,
        phone: item?.phone,
        email: item?.email,
      };
    });
  };

  // const handleChange = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  // useEffect(() => {
  //   setFormData(
  //     typeof selectedWorkOrder?.contactData === "string"
  //       ? JSON.parse(selectedWorkOrder?.contactData)
  //       : selectedWorkOrder?.contactData
  //   );
  // }, [selectedWorkOrder]);

  /*   ALL TABLES FUNCTIONS
   ********************************************* */
  const btn1 = () => {
    return (
      <button
        className=" ms-2 btn btn-secondary "
        type="button"
        onClick={() => {
          showModal(false);
        }}
      >
        Close
      </button>
    );
  };
  const buttons = () => (
    <div className=" d-flex justify-content-end ">
      {/* FilterBy */}
      <button
        type="button"
        className="px-1 bg-primary btn text-dark bg-opacity-10 border border-primary rounded-end mx-2 rounded-start"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {filterBy
          ? filterBy.toUpperCase().replaceAll("_", " ")
          : "Filter by All"}
      </button>
      <div className="dropdown-menu">
        <button onClick={() => setFilterBy(null)} className="dropdown-item">
          Filter by All
        </button>
        <button
          onClick={() => setFilterBy("firstName")}
          className="dropdown-item"
        >
          First Name
        </button>
        <button
          onClick={() => setFilterBy("lastName")}
          className="dropdown-item"
        >
          Last Name
        </button>
        <button
          onClick={() => setFilterBy("department")}
          className="dropdown-item"
        >
          Department
        </button>
        <button onClick={() => setFilterBy("email")} className="dropdown-item">
          Email
        </button>
        <button onClick={() => setFilterBy("phone")} className="dropdown-item">
          Phone
        </button>
      </div>
    </div>
  );

  const table_head = [
    "First Name",
    "Last Name",
    "Department",
    "Phone",
    "Email",
    "Action",
  ];

  const table_body = (item) => (
    <tr className="" key={item.id}>
      <td className={"py-1 align-middle"}>{item.firstName}</td>
      <td className={"py-1 align-middle"}>{item.lastName}</td>
      <td className={"py-1 align-middle"}>{item.department} </td>
      <td className={"py-1 align-middle"}>{item.phone} </td>
      <td className={"py-1 align-middle"}>{item.email} </td>
      <td className="table-action py-1 align-middle">
        <div
          className="btn btn-sm btn-success"
          onClick={() => {
            handleContactSelect(item);
            showModal(false);
          }}
        >
          SELECT
        </div>
      </td>
    </tr>
  );
  return (
    <div className="shadow-lg bg-white px-4 py-2" style={{ borderRadius: 10 }}>
      {loading && <Spinner />}

      <h3>Customer Contacts List</h3>
      <Table
        btn1={btn1}
        filterBy={filterBy}
        buttons={buttons}
        table_head={table_head}
        table_body={table_body}
        table_data={contactsList}
      />
    </div>
  );
}

export default ContactSelectModal;
