import Spinner from "components/Spinner";
import React, { useEffect, useState } from "react";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function ArtAudioWIthSignedUrl({ path }) {
  const [url, setUrl] = useState(null);

  const getAudioUrl = async () => {
    const { data } = await API.post(
      "/art-approval/get-image",
      { path },
      {
        headers: authHeader(),
      }
    );

    setUrl(data);
    return data;
  };

  useEffect(() => {
    getAudioUrl(path);
  }, [path]);
  return (
    <>
      {!url && <Spinner />}
      {url && (
        <audio controls className="w-100">
          <source src={url} type="audio/mp3" />
        </audio>
      )}
    </>
  );
}
