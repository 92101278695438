import PrintProductPdf from "components/ProductPdfPrintButton/PrintProductPdf";
import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";
import { useNavigate } from "react-router-dom";
import Toast from "components/Toast/ToastComponent";
import WOOrderEmail from "components/WorkOrderOrderEmail/WOOrderEmail";
import useWorkOrder from "features/Contexts/WorkOrderContext";
import WOPdfButton from "components/Pdfs/WOPdf/WOPdfButton";

function ViewWOProducts({
  refreshFunc,
  active,
  allWOsData,
  selectedId,
  setShowWorkOrderProductModal,
}) {
  /*   All States
   ********************************************* */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));

  const [allSizes, setAllSizes] = useState(null);
  const [allWOData, setAllWOData] = useState(null);
  const [generalData, setGeneralData] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [wOProductsData, setWOProductsData] = useState(null);
  const [count, setCount] = useState(1);

  const [selectedWorkOrder, setSelectedWorkOrder] = useState(null);
  const [wOId, setWOId] = useState(null);
  const [wOIndex, setWOIndex] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [productTypesData, setProductTypesData] = useState(false);

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["clean"],
    ],
  };

  /*   All Function
   ********************************************* */

  const prevWO = () => {
    if (wOIndex == 0) {
      return;
    } else {
      setWOIndex((prevState) => prevState - 1);
      setCount(1);
      setCurrentIndex(0);
    }
  };
  const nextWO = () => {
    if (wOIndex == allWOData.length - 1) {
      return;
    } else {
      setWOIndex((prevState) => prevState + 1);
      setCount(1);
      setCurrentIndex(0);
    }
  };

  const getWorkOrdersProducts = async (wOId) => {
    setIsLoading(true);
    try {
      const { data } = await API.get(`/wo-products/${wOId}`, {
        headers: authHeader(),
      });

      setWOProductsData(data);
      setAllSizes(
        data?.productSizes
          ? typeof data?.productSizes === "string"
            ? JSON.parse(data?.productSizes)
            : data?.productSizes
          : []
      );
      setIsLoading(false);
      return data;
    } catch (err) {
      setIsLoading(false);
      alert(err.message);
    }
  };

  const handleWOStatusChange = async (e) => {
    let WOStatus = e.target.value;

    let newAllWOData = allWOData.map((item) => {
      if (item.id === selectedWorkOrder.id) {
        return { ...item, WOStatus };
      } else {
        return item;
      }
    });
    setAllWOData(newAllWOData);
    setIsLoading(true);
    try {
      const { data } = await API.patch(
        "/work-orders",
        {
          data: { WOStatus: WOStatus },
          id: selectedWorkOrder.id,
        },
        { headers: authHeader() }
      );

      if (data.message.type === "success") {
        setIsLoading(false);
        return;
      }
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      setIsLoading(false);
    }
  };

  const { clearWorkOrderData, setCustomerData } = useWorkOrder();
  const handleViewCustomer = () => {
    setCustomerData({ id: selectedWorkOrder?.customerId });
    clearWorkOrderData();
    navigate("/customer");
  };

  const getAllProductTypes = async () => {
    try {
      const { data } = await API.get("/productTypes", {
        headers: authHeader(),
      });
      setProductTypesData(data);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const handleProductTypeSelection = async (e) => {
    let selectedType = e.target.value;

    if (!generalData) {
      return;
    }

    setIsLoading(true);
    let updateData = {
      id: generalData.id,
      productType: selectedType,
    };

    try {
      const { data } = await API.patch(
        "/wo-products",
        { data: updateData },
        {
          headers: authHeader(),
        }
      );
      if (data?.message?.type === "success") {
        setWOProductsData((prev) => {
          prev[currentIndex]["productType"] = selectedType;
          return prev;
        });

        setIsLoading(false);
      }

      if (data?.message?.type === "error") {
        dispatch(
          setNotification({
            message: data?.message?.message,
            type: "error",
          })
        );
        setIsLoading(false);
      }
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      setIsLoading(false);
    }
  };

  /*   All Use effects
   ********************************************* */

  useEffect(() => {
    if (allWOsData) setAllWOData(allWOsData);
  }, [allWOsData]);

  useEffect(() => {
    if (allWOsData && selectedId !== null) {
      for (let index = 0; index < allWOsData.length; index++) {
        const element = allWOsData[index];

        if (element.id === selectedId) {
          setWOIndex(index);
          break;
        }
      }
    }
  }, [selectedId, allWOsData]);

  useEffect(() => {
    if (wOIndex !== null && allWOData) {
      setSelectedWorkOrder(allWOData[wOIndex]);
      setWOId(allWOData[wOIndex]?.id);
      setCurrentIndex(0);
    }
  }, [wOIndex, allWOData]);

  useEffect(() => {
    if (wOId) getWorkOrdersProducts(wOId);
  }, [wOId]);

  useEffect(() => {
    if (wOProductsData) {
      let selected = wOProductsData[currentIndex];
      setGeneralData(selected);
      setAllSizes(
        selected?.productSizes
          ? typeof selected?.productSizes === "string"
            ? JSON.parse(selected?.productSizes)
            : selected?.productSizes
          : []
      );
    }
  }, [currentIndex, wOProductsData]);

  const prevProduct = () => {
    if (currentIndex == 0) {
      return;
    } else {
      setCurrentIndex((prevState) => prevState - 1);
      setCount((prev) => prev - 1);
    }
  };
  const nextProduct = () => {
    if (currentIndex == wOProductsData.length - 1) {
      return;
    } else {
      setCurrentIndex((prevState) => prevState + 1);
      setCount((prev) => prev + 1);
    }
  };

  const sortArrBySortId = (arr) => {
    let sortedArr = arr?.sort((a, b) => a.sortId - b.sortId);
    return sortedArr;
  };

  useEffect(() => {
    if (!productTypesData) {
      getAllProductTypes();
    }
  }, [productTypesData]);

  // if (!selectedWorkOrder || isLoading) return <Spinner />;
  return (
    <div>
      {isLoading && <Spinner />}
      <div className="mx-1 me-2 d-flex justify-content-between">
        <h4 className="d-flex flex-column">
          <div className="d-flex">
            {(user?.permissions?.includes("work_orders_add") ||
              user?.permissions?.includes("work_orders_update") ||
              user?.permissions?.includes("work_orders_delete") ||
              user?.permissions?.includes("work_orders_dataEntry") ||
              user?.roles === "admin") && (
              <button
                className="btn btn-sm btn-success me-2 py-0 px-1 mb-1"
                onClick={() => {
                  let status =
                    selectedWorkOrder?.WOStatus === "pending" ||
                    selectedWorkOrder?.WOStatus === "processing"
                      ? "pending-processing"
                      : selectedWorkOrder.WOStatus;
                  navigate(
                    `/work-order/details/${status}/${selectedWorkOrder?.id}?page=1`
                  );
                }}
              >
                View Work Order
              </button>
            )}
            <div>
              WO ID {wOId} -
              {selectedWorkOrder?.jobName?.length <= 20
                ? selectedWorkOrder?.jobName
                : `${selectedWorkOrder?.jobName?.slice(0, 20)}..`}
            </div>
          </div>
          <div className="d-flex">
            {(user?.permissions?.includes("work_orders_add") ||
              user?.permissions?.includes("work_orders_update") ||
              user?.permissions?.includes("work_orders_delete") ||
              user?.permissions?.includes("work_orders_dataEntry") ||
              user?.roles === "admin") && (
              <button
                onClick={handleViewCustomer}
                className="btn btn-sm btn-info me-2 py-0 px-1 mb-1 "
              >
                View Customer
              </button>
            )}
            <div className="">
              Customer : {selectedWorkOrder?.customer?.customerId} -{" "}
              {selectedWorkOrder?.customerName.length <= 20
                ? selectedWorkOrder?.customerName
                : `${selectedWorkOrder?.customerName.slice(0, 20)}..`}
            </div>
          </div>
        </h4>
        <div className="d-flex justify-content-end align-items-center flex-grow-1 my-2">
          <h5 className="me-1">WO Status</h5>
          <div className="me-1 ">
            {user?.roles === "customer" ? (
              <input
                className="form-control"
                readOnly
                value={selectedWorkOrder?.WOStatus}
              />
            ) : (
              <select
                value={selectedWorkOrder?.WOStatus}
                onChange={handleWOStatusChange}
                className="form-select"
              >
                <option value="pending">Pending</option>
                <option value="processing">Processing</option>
                <option value="completed">Completed</option>
              </select>
            )}
          </div>
          <div className="d-flex gap-1">
            <>
              {wOProductsData && selectedWorkOrder && (
                <WOPdfButton
                  btnTitle={"Print Pdf"}
                  btnClasses={"btn btn-success"}
                  selectedWO={selectedWorkOrder}
                />
              )}
              {user?.roles !== "customer" && (
                <WOOrderEmail
                  btnClass={"btn btn-info"}
                  btnTitle={"Email"}
                  selectedWorkOrder={selectedWorkOrder}
                  wOProductsData={wOProductsData}
                />
              )}
            </>
            <button
              onClick={() => {
                prevWO();
              }}
              disabled={allWOData?.length === 0 && isLoading}
              className={"btn btn-success btn-sm p-0 px-1 "}
            >
              <i className="mdi mdi-arrow-left-bold fs-3 py-0 my-0"></i>
            </button>
            <button
              onClick={() => {
                nextWO();
              }}
              disabled={allWOData?.length === 0 && isLoading}
              className={"btn btn-success btn-sm p-0  px-1"}
            >
              <i className="mdi mdi-arrow-right-bold fs-3 py-0 my-0"></i>
            </button>
            <button
              className="btn btn-danger"
              onClick={async () => {
                setShowWorkOrderProductModal(false);

                await refreshFunc(active);
              }}
            >
              Back
            </button>
          </div>
        </div>
      </div>
      <hr className="my-0" />
      <div className="d-flex">
        {/*   Left Panel
         ********************************************* */}
        <div
          className=" bg-white shadow p-2 w-50  mb-2 me-2"
          style={{ borderRadius: 15 }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <h3>
              Product
              {` {${wOProductsData?.length === 0 ? " 0 " : ` ${count} `} of ${
                wOProductsData?.length
              } }`}
            </h3>
            <div className="d-flex gap-1">
              {/*   WO Product Buttons
               ********************************************* */}
              <button
                onClick={() => {
                  prevProduct();
                }}
                disabled={wOProductsData?.length === 0}
                className={"btn btn-success btn-sm p-0 px-1 "}
              >
                <i className="mdi mdi-arrow-left-bold fs-3 py-0 my-0"></i>
              </button>
              <button
                onClick={() => {
                  nextProduct();
                }}
                disabled={wOProductsData?.length === 0}
                className={"btn btn-success btn-sm p-0 px-1"}
              >
                <i className="mdi mdi-arrow-right-bold fs-3 py-0 my-0"></i>
              </button>
            </div>
          </div>
          <div className=" p-1">
            <div className="row mb-1">
              <label className="col-5">Product Type</label>
              {user?.roles === "customer" ? (
                <input
                  className="form-control col-7 w-50 form-control w-fit"
                  readOnly
                  value={selectedWorkOrder?.WOStatus}
                />
              ) : (
                <select
                  disabled={!generalData}
                  value={
                    generalData?.productType ? generalData.productType : ""
                  }
                  className="col-7 w-50 form-control w-fit"
                  onChange={handleProductTypeSelection}
                >
                  {productTypesData &&
                    productTypesData?.map((item) => (
                      <option key={item?.id} value={item?.productTypes}>
                        {item?.productTypes}
                      </option>
                    ))}
                </select>
              )}
            </div>
            <div className="row mb-1">
              <label className="col-5">Product Style</label>
              <div className="col-7 d-flex align-items-center">
                {generalData?.productStyle}
              </div>
            </div>
            <div className="row mb-1">
              <label className="col-5">Product Description</label>
              <div className="col-7 ">{generalData?.productDescription}</div>
            </div>
            <div className="row mb-1">
              <label className="col-5">Product Fabric</label>
              <div className="col-7 ">{generalData?.productFabric}</div>
            </div>
            <div className="row mb-1">
              <label className="col-5">Product Color</label>
              <div className="col-7 ">{generalData?.productColor}</div>
            </div>
            <div className="row mb-1">
              <label className="col-5">Team Name</label>
              <div className="col-7 ">{generalData?.teamName}</div>
            </div>

            <div className="row">
              <div className=" col-5 d-flex mb-1">
                <label className="text-nowrap ">Has Names</label>
                <div
                  className="d-flex form-switch "
                  style={{ paddingLeft: 48 }}
                >
                  <input
                    type="checkbox"
                    checked={generalData?.hasName ? true : false}
                    readOnly
                    className="form-check-input "
                    style={{ width: 40 }}
                  />
                </div>
              </div>
              <div className="col-7 d-flex mb-1">
                <label className="text-nowrap">Has Numbers</label>
                <div
                  className="d-flex form-switch "
                  style={{ paddingLeft: 48 }}
                >
                  <input
                    type="checkbox"
                    checked={generalData?.hasNumbers ? true : false}
                    readOnly
                    className="form-check-input "
                    style={{ width: 40 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*   Right Panel
         ********************************************* */}
        <div className="w-50 flex-grow-1 pb-2 d-flex flex-column">
          <div
            className="bg-white shadow px-3 mb-2 me-2 d-flex flex-column py-2"
            style={{ borderRadius: 15, height: 250 }}
          >
            <div className="" style={{ overflowY: "scroll", height: "99%" }}>
              <div className="table-responsive  ">
                <table className="table ">
                  <thead>
                    <tr>
                      <th scope="col">Size</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortArrBySortId(allSizes)?.map((item, i) => (
                      <tr key={i}>
                        <td className="py-0">{item?.size?.toUpperCase()}</td>
                        <td className="py-0">{item?.quantity}</td>
                        <td className="py-0">
                          $ {parseFloat(item?.price).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="d-flex">
            <div
              className="d-flex justify-content-evenly flex-grow-1 py-1 align-items-center bg-white shadow me-2"
              style={{ borderRadius: 15 }}
            >
              <div>
                Total Qty
                <span className="border ms-1 px-2">
                  {parseFloat(generalData?.totalQuantity)}
                </span>
              </div>
              <div>
                Total Price
                <span className="border ms-1 px-2">
                  $ {parseFloat(generalData?.totalPrice).toFixed(2)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Bottom Pane */}
      <div className="d-flex">
        {/*   Image select
         ********************************************* */}
        <div
          className="bg-white shadow p-2 w-50 mb-2 me-2 flex-center"
          style={{ borderRadius: 15, minHeight: 300 }}
        >
          {!isLoading ? (
            <div>
              {generalData?.productImage ? (
                <img
                  width={500}
                  className=" mb-2"
                  height={300}
                  src={generalData?.productImage}
                  alt="Product"
                />
              ) : (
                <div className="fs-3 text-muted  text-center my-auto">
                  NO Image{" "}
                </div>
              )}
            </div>
          ) : (
            <Spinner />
          )}
        </div>

        {/*   Notes
         ********************************************* */}
        <div
          className="bg-white shadow p-2 w-50 mb-2 me-2 d-flex flex-column justify-content-between "
          style={{ borderRadius: 15, minHeight: 300 }}
        >
          <div className="">
            <ReactQuill
              modules={modules}
              theme="snow"
              value={generalData?.notes}
            />
          </div>
        </div>
      </div>
      <Toast />
    </div>
  );
}

export default ViewWOProducts;
