import { Image, Page, Text, View } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import WOPdfHeader from "./WOPdfHeader";
import WOPdfInfo from "./WOPdfInfo";

export default function WOPdfProducts({
  styles,
  locationLogo,
  logo,
  selectedWO,
  productsList,
}) {
  /*   All States
   ********************************************* */

  /*   All Functions
   ********************************************* */
  let limit = 2;
  const getTotalPages = (list) => {
    let totalItems = list.length === null ? 0 : list.length - 1;
    if (totalItems === null) {
      return 0;
    }

    let newLimit = list.length === limit ? 2 : limit;

    let itemsPerPage = Math.ceil(list.length / newLimit);

    return itemsPerPage;
  };

  /*   All UseEffects
   ********************************************* */
  let productStyle = 0;

  return (
    <>
      {productsList &&
        Array.from({ length: getTotalPages(productsList) }, (_, pagesIndex) => {
          let currentProductList = productsList;
          // Page Can have 2 items with header.
          // Page Can have limit=3 items without header

          // Checking if totalItems are greater than 2
          // if less than 2 than do nothing

          // if greater than 2 than splice them on every iteration
          // so that we can have 2 items on page with header and
          // limit= 3 no of items in page without header.

          // let newLimit =
          //   currentProductList?.length === limit ? 2 : limit;

          let sliceStart = pagesIndex * limit;
          let sliceEnd = (pagesIndex + 1) * limit;

          let itemsInPage =
            currentProductList?.length > 2
              ? currentProductList.slice(sliceStart, sliceEnd)
              : currentProductList;

          return (
            <Page key={currentProductList} size="A4" style={styles.page}>
              {pagesIndex === 0 && (
                <WOPdfHeader
                  locationLogo={locationLogo}
                  logo={logo}
                  styles={styles}
                  selectedWO={selectedWO}
                />
              )}

              {/*   Contact and Shipping Info
               ********************************************* */}
              {pagesIndex === 0 && (
                <WOPdfInfo
                  styles={styles}
                  selectedWO={selectedWO}
                  productsList={productsList}
                />
              )}
              {/*   ALL Product Details
               ********************************************* */}
              <View style={[styles.productDetails, { marginTop: 10 }]}>
                {/* ----------  Looping Products   ---------- */}
                {itemsInPage
                  ? itemsInPage?.map((item) => {
                      // Increment By 1 on every new item
                      productStyle += 1;
                      return (
                        <View style={styles.mainContainer} key={item?.id}>
                          <View style={styles.prodMain}>
                            <Text
                              style={{
                                ...styles.prodTitle,
                                width: "30%",
                                fontSize: "12px",
                                borderRight: "1px solid #000",
                              }}
                            >
                              {item?.productType}
                            </Text>
                            <Text style={styles.prodTitle}>
                              {productStyle}: {item?.productDescription}
                            </Text>
                          </View>
                          <View style={styles.prodMain}>
                            <View style={styles.prodContent}>
                              <View style={styles.prodInfoRow}>
                                <View
                                  style={[styles.prodCol, styles.borderRight]}
                                >
                                  <Text style={styles.prodKey}>
                                    Style Number:
                                  </Text>
                                  <Text style={styles.prodVal}>
                                    {item?.productStyle}
                                  </Text>
                                </View>
                                <View style={styles.prodCol}>
                                  <Text style={styles.prodKey}>Fabrics:</Text>
                                  <Text style={styles.prodVal}>
                                    {item?.productFabric}
                                  </Text>
                                </View>
                              </View>
                              <View style={styles.prodInfoRow}>
                                <View
                                  style={[styles.prodCol, styles.borderRight]}
                                >
                                  <Text style={styles.prodKey}>Color:</Text>
                                  <Text style={styles.prodVal}>
                                    {item?.productColor}
                                  </Text>
                                </View>
                                <View style={styles.prodCol}>
                                  <Text style={styles.prodKey}>Qty:</Text>
                                  <Text style={styles.prodVal}>
                                    {item?.totalQuantity}
                                  </Text>
                                </View>
                              </View>
                              <View
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {item?.productImage && (
                                  <Image
                                    style={{ width: 300, height: 160 }}
                                    src={item?.productImage}
                                  />
                                )}
                              </View>
                            </View>

                            <View style={styles.prodSizes}>
                              <Text style={styles.prodSubHeading}>
                                Size Details
                              </Text>
                              {item?.productSizes &&
                                JSON.parse(item?.productSizes).map((d) => (
                                  <Text key={d.id} style={styles.prodTxt}>
                                    {d?.size} = {d?.quantity}
                                  </Text>
                                ))}
                            </View>
                          </View>
                          <Text style={styles.prodComments}>
                            COMMENTS: {item?.notes?.replace(/<[^>]+>/g, "")}
                          </Text>
                        </View>
                      );
                    })
                  : itemsInPage.map((item) => (
                      <View
                        key={item?.id}
                        style={{
                          padding: 5,
                          flexDirection: "row",
                          justifyContent: "space-between",
                          borderBottom: 1,
                        }}
                      >
                        <View
                          style={{
                            width: "15%",
                            backgroundColor: "red",
                          }}
                        >
                          <Text style={styles.headingSecond}>
                            {item?.product_number}
                          </Text>
                        </View>
                        <View style={{ width: "15%" }}>
                          <Text style={styles.headingSecond}>
                            {item?.product_color}
                          </Text>
                        </View>
                        <View style={{ width: "40%" }}>
                          <Text
                            style={[
                              styles.headingSecond,
                              { flexWrap: "wrap", marginRight: 5 },
                            ]}
                          >
                            {item?.product_description}
                          </Text>
                        </View>
                        <View style={{ width: "5%" }}>
                          <Text style={styles.headingSecond}>
                            {item?.total_quantity}
                          </Text>
                        </View>
                        <View style={{ width: "10%" }}>
                          <Text
                            style={[
                              styles.headingSecond,
                              { alignSelf: "flex-end" },
                            ]}
                          >
                            ---
                          </Text>
                        </View>
                        <View style={{ width: "15%" }}>
                          <Text
                            style={[
                              styles.headingSecond,
                              { alignSelf: "flex-end" },
                            ]}
                          >
                            {item?.total_price}
                          </Text>
                        </View>
                      </View>
                    ))}
              </View>
            </Page>
          );
        })}
    </>
  );
}
