import Table from "components/DataTable/Table";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import authHeader from "services/auth-header";
import API from "services/axios";

import Spinner from "components/Spinner";
import EmailSmtpAdd from "./EmailSmtpAdd";
import EmailSmtpUpdate from "./EmailSmtpUpdate";

function EmailSmtp() {
  const user = useSelector((state) => state.user.userInfo);
  const dispatch = useDispatch();

  /*   All States Below
   ********************************************* */
  const [emailSmtps, setEmailSmtps] = useState(null);

  // Modals
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  // below update data is used to set a variable before moving to update component
  const [updateData, setUpdateData] = useState(null);

  const [selectedDelete, setSelectedDelete] = useState(null);

  /*   All Functions Below
   ********************************************* */

  // get All Email Format
  const getAllEmailSmtp = async () => {
    try {
      const { data } = await API.get("/email-smtp/", {
        headers: authHeader(),
      });
      setEmailSmtps(data);
    } catch (err) {
      alert(err.message);
    }
  };

  // Delete password Data
  const deleteEmailSmtp = async (id) => {
    const { data } = await API.delete("/email-smtp/delete/" + id, {
      headers: authHeader(),
    });
    if (data.message.type == "success") {
      await getAllEmailSmtp();
      setDeleteModal(false);
      return;
    }
  };

  useEffect(() => {
    getAllEmailSmtp();
  }, []);

  /*   Table Functions below
   ********************************************* */
  const buttons = () => (
    <div className="d-flex justify-content-end ms-2">
      <button onClick={() => setShowAddModal(true)} className="btn btn-primary">
        ADD NEW
      </button>
    </div>
  );
  const table_head = ["Name", "Email", "Password", "Action"];
  const table_body = (item, index) => (
    <tr key={item.id}>
      <td>{item?.name}</td>
      <td>{item?.email}</td>
      {/* <td>{item?.password}</td> */}
      <td>**********</td>
      <td className="table-action">
        <Link className="action-icon">
          <i
            onClick={() => {
              setShowUpdateModal(true);
              setUpdateData(item);
            }}
            className="mdi mdi-pencil"
          ></i>
        </Link>
        <Link className="action-icon">
          <i
            data-bs-toggle="modal"
            data-bs-target="#danger-alert-modal"
            className="mdi mdi-delete"
            onClick={() => {
              setSelectedDelete(item);
              setDeleteModal(true);
            }}
          ></i>
        </Link>
      </td>
    </tr>
  );

  return (
    <div className="row">
      <h3>Email Smtp ( Type Gmail )</h3>
      <div className="col-12">
        {emailSmtps ? (
          <Table
            btn1={buttons}
            table_body={table_body}
            table_data={emailSmtps}
            table_head={table_head}
          />
        ) : (
          <Spinner />
        )}

        {/*   ADD Modal
         ********************************************* */}
        <Modal
          contentClassName="bg-transparent"
          show={showAddModal}
          onHide={() => setShowAddModal(false)}
        >
          <Modal.Body
            className="shadow-lg bg-white py-3"
            style={{ borderRadius: 10 }}
          >
            <div>
              <EmailSmtpAdd
                showAddPage={setShowAddModal}
                refreshFunc={getAllEmailSmtp}
              />
            </div>
          </Modal.Body>
        </Modal>
        {/*   Update Modal
         ********************************************* */}
        <Modal
          contentClassName="bg-transparent"
          show={showUpdateModal}
          onHide={() => setShowUpdateModal(false)}
        >
          <Modal.Body
            className="shadow-lg bg-white py-3"
            style={{ borderRadius: 10 }}
          >
            <div>
              <EmailSmtpUpdate
                data={updateData}
                showUpdatePage={setShowUpdateModal}
                refreshFunc={getAllEmailSmtp}
              />
            </div>
          </Modal.Body>
        </Modal>

        {/*   Confirmation Modal
         ********************************************* */}
        {/*   Update Modal
         ********************************************* */}
        <Modal
          contentClassName="bg-transparent"
          show={deleteModal}
          onHide={() => setDeleteModal(false)}
        >
          <Modal.Body className="py-3" style={{ borderRadius: 10 }}>
            <div className="modal-dialog modal-sm ">
              <div className="modal-content modal-filled bg-danger custom-shadow custom-border-radius">
                <div className="modal-body p-4">
                  <div className="text-center">
                    <i className="ri-close-circle-line h1"></i>
                    <h4 className="mt-2">Confirm Delete!</h4>
                    <p className="mt-3">Do You want to delete ?</p>
                    <button
                      type="button"
                      className="btn btn-secondary me-1"
                      onClick={() => {
                        setDeleteModal(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-light my-2 "
                      data-bs-dismiss="modal"
                      onClick={() => {
                        deleteEmailSmtp(selectedDelete.id);
                      }}
                    >
                      DELETE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default EmailSmtp;
