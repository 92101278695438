import Table from "components/DataTable/Table";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import authHeader from "services/auth-header";
import API from "services/axios";
import CompanyPasswordAdd from "./passwordAdd";
import CompanyPasswordUpdate from "./passwordUpdate";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import Popover from "components/ToolTip/ToolTip";
import CryptoJS from "crypto-js";
import * as XLSX from "xlsx";

function CompanyPasswords({ passwordsData, filterBy, refreshFunc }) {
  const user = useSelector((state) => state.user?.userInfo);
  /*   All Satates Below
   ********************************************* */
  const [companyPassword, setCompanyPassword] = useState(passwordsData);
  const [selectedTitle, setSelectedTitle] = useState(false);
  const [selectedDelete, setSelectedDelete] = useState(false);

  const [shownPasswordList, setShownPasswordList] = useState([]);

  // Modals
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [hoveredRowId, setHoveredRowId] = useState(null);

  // below update data is used to set a varible before moving to update component
  const [updateData, setUpdateData] = useState(null);

  // Notes
  const [notes, setNotes] = useState("");
  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["clean"],
    ],
  };

  /*   All Functions Below
   ********************************************* */

  // Delete password Data
  const deletePasswordData = async (id) => {
    const { data } = await API.post(
      "/deletepassword",
      { id },
      { headers: authHeader() }
    );

    if (data.message.type == "success") {
      setTimeout(() => {
        refreshFunc();
      }, 300);
      return;
    }
  };

  // Show copy alert
  const copyContent = (val) => {
    navigator.clipboard.writeText(val);
  };

  // redirects to new tab with url and username copied
  const redirectUrl = (url, username) => {
    const newUrl = url.includes("https://")
      ? url
      : url.includes("http://")
      ? url
      : `https://${url}`;

    navigator.clipboard.writeText(username);
    setTimeout(() => {
      window.open(newUrl, "_blank");
    }, 100);
  };

  // Hides ALL passwords
  const hidePass = (pass) => {
    var str = "";
    for (var i = 0; i < pass.length; i++) {
      var str = str + "*";
    }
    return str.slice(1, 8);
  };

  const decryptPassword = (data) => {
    // Loop over data Arr and use function decryptPassword() on

    try {
      const decrypted = CryptoJS.AES.decrypt(
        data,
        process.env.REACT_APP_ENCRYPTION_KEY
      ).toString(CryptoJS.enc.Utf8);
      return decrypted;
    } catch (err) {
      alert(err.message);
    }
  };

  // Updates Notes in DB
  const UpdateNotes = async () => {
    const submitData = {
      notes,
      id: updateData.id,
    };

    const { data } = await API.patch(
      "/updateNotes",
      { data: submitData },
      { headers: authHeader() }
    );
    if (data.message.type === "success") {
      refreshFunc();
    }
    setShowNotesModal(false);
  };

  // Getting 12 Hours Time from SQL timestamp
  const convertSqlTimestampToDate = (sqlTime) => {
    const timestamp = sqlTime;
    const date = new Date(timestamp);
    const time = date.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    return time;
  };

  const showHidePassword = (id) => {
    if (!shownPasswordList.includes(id)) {
      setShownPasswordList([...shownPasswordList, id]);
    } else {
      setShownPasswordList((prev) => prev.filter((item) => item != id));
    }
  };

  const downloadExcel = (data) => {
    // Loop Over data and use function decryptPassword() on "passwordValue" property and save it to new field "decryptedPassword"
    const decryptedData = data.map((item) => {
      return {
        ...item,
        decryptedPassword: decryptPassword(item.passwordValue),
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(decryptedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `passwords-${new Date()}.xlsx`);
  };

  /*   Table Functions below
   ********************************************* */
  const buttons = () => (
    <div className="d-flex justify-content-end ">
      <div>
        <button
          onClick={(e) => {
            downloadExcel(companyPassword);
          }}
          className="btn btn-success mx-1"
        >
          TO Excel
        </button>
      </div>
      {(user?.permissions?.includes("password_add") ||
        user?.roles === "admin" ||
        filterBy === "_private") && (
        <button
          onClick={() => setShowAddModal(true)}
          className="btn btn-primary ms-1"
        >
          ADD NEW
        </button>
      )}
    </div>
  );
  const table_head = [
    "Title",
    "Url",
    "Username",
    "Password",
    "Last Updated",
    "Action",
  ];
  const table_body = (item) => (
    <tr
      onMouseEnter={() => {
        setHoveredRowId(item.id);
      }}
      className={hoveredRowId === item.id ? "bg-primary bg-opacity-25" : ""}
      key={item.id}
    >
      <td className="py-1 align-middle">
        {`${item?.title.substring(0, 20)} ${
          item.title.length > 20 ? "..." : ""
        }`}
      </td>

      <td className="py-1 align-middle">
        <Link
          onDoubleClick={() => {
            redirectUrl(item.url, item.username);
          }}
        >
          {`${item?.url.substring(0, 20)} ${item.url.length > 20 ? "..." : ""}`}
        </Link>
      </td>
      <td
        className="py-1 align-middle user-select-none"
        onDoubleClick={() => {
          copyContent(item.username);
        }}
      >
        <Popover
          btn_text={`${item?.username.substring(0, 20)} ${
            item.username.length > 20 ? "..." : ""
          }`}
          body={"copied"}
          doubleClick={true}
        />
      </td>
      <td
        className="py-1 user-select-none d-flex align-items-end"
        onDoubleClick={() => {
          copyContent(decryptPassword(item.passwordValue));
        }}
      >
        <Popover
          btn_text={
            shownPasswordList.includes(item.id)
              ? decryptPassword(item.passwordValue)
              : hidePass(item.passwordValue)
          }
          body={"copied"}
          doubleClick={true}
        />
        <Link className="action-icon" onClick={() => showHidePassword(item.id)}>
          <i
            className={`ms-1 mdi mdi-eye${
              shownPasswordList.includes(item.id) ? "" : "-off"
            }`}
          ></i>
        </Link>
      </td>

      <td className="py-1 align-middle">
        {convertSqlTimestampToDate(item.updatedAt)}
      </td>

      <td className="table-action py-1 align-middle">
        <button
          onClick={() => {
            setShowNotesModal(true);
            setUpdateData(item);
            setSelectedTitle(item.title);
          }}
          className={`btn btn-sm py-0 px-1 ${
            item.notes == "" ||
            item.notes == null ||
            item.notes == "<p><br></p>"
              ? "bg-secondary text-white"
              : "bg-success text-white"
          } mx-2 `}
        >
          Notes
        </button>

        {(user?.permissions?.includes("password_update") ||
          user?.roles === "admin" ||
          filterBy === "_private") && (
          <Link className="action-icon">
            <i
              onClick={() => {
                setShowUpdateModal(true);
                setUpdateData(item);
              }}
              className={`mdi mdi-pencil`}
            ></i>
          </Link>
        )}
        {(user?.permissions?.includes("password_delete") ||
          user?.roles === "admin" ||
          filterBy === "_private") && (
          <Link className="action-icon">
            <i
              data-bs-toggle="modal"
              data-bs-target="#danger-alert-modal"
              className={`mdi mdi-delete`}
              onClick={() => setSelectedDelete(item)}
            ></i>
          </Link>
        )}
      </td>
    </tr>
  );

  useEffect(() => {
    setCompanyPassword(passwordsData);
  }, [passwordsData]);
  return (
    <div className="row">
      <div className="col-12">
        <div
          className="d-flex justify-content-center me-3 align-items-center "
          style={{ marginLeft: 285 }}
        >
          <Table
            btn1={buttons}
            table_body={table_body}
            table_data={companyPassword}
            table_head={table_head}
          />
        </div>
        {/*   Notes Modal
         ********************************************* */}
        <Modal show={showNotesModal} onHide={() => setShowNotesModal(false)}>
          <Modal.Body>
            <h3> {selectedTitle} Notes</h3>
            <div className="card mt-2 mb-3 ">
              <ReactQuill
                modules={modules}
                theme="snow"
                defaultValue={updateData?.notes}
                onChange={setNotes}
              />
            </div>
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-primary mx-1 "
                variant="secondary"
                onClick={() => setShowNotesModal(false)}
              >
                Close
              </button>
              {(user?.permissions?.includes("password_update") ||
                user?.roles === "admin" ||
                filterBy === "_private") && (
                <button
                  className="btn btn-primary"
                  variant="primary"
                  onClick={UpdateNotes}
                >
                  Save Changes
                </button>
              )}
            </div>
          </Modal.Body>
        </Modal>
        {/*   ADD Modal
         ********************************************* */}
        <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
          <Modal.Body>
            <div>
              <CompanyPasswordAdd
                showAddPage={setShowAddModal}
                filterBy={filterBy}
                refreshFunc={refreshFunc}
              />
            </div>
          </Modal.Body>
        </Modal>
        {/*   Update Modal
         ********************************************* */}
        <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
          <Modal.Body>
            <div>
              <CompanyPasswordUpdate
                data={updateData}
                showUpdatePage={setShowUpdateModal}
                filterBy={filterBy}
                refreshFunc={refreshFunc}
              />
            </div>
          </Modal.Body>
        </Modal>
        {/*   Confirmation Modal
         ********************************************* */}

        <div
          id="danger-alert-modal"
          className="modal fade"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-sm ">
            <div className="modal-content modal-filled bg-danger">
              <div className="modal-body p-4">
                <div className="text-center">
                  <i className="ri-close-circle-line h1"></i>
                  <h4 className="mt-2">Confirm Delete!</h4>
                  <p className="mt-3">
                    Do You want to delete {selectedTitle} ?
                  </p>
                  <button
                    type="button"
                    className="btn btn-light my-2 mx-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-light my-2 "
                    data-bs-dismiss="modal"
                    onClick={() => {
                      deletePasswordData(
                        selectedDelete.id,
                        selectedDelete.password_type
                      );
                    }}
                  >
                    DELETE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyPasswords;
