import PendingJobs from "components/DisplayBoard/PendingJobs";
import Spinner from "components/Spinner";
import Toast from "components/Toast/ToastComponent";
import { useEffect, useState } from "react";
import authHeader from "services/auth-header";
import API from "services/axios";
import JobDashBoard from "./JobDashBoard";

export default function JobBoard() {
  /*   All States
   ********************************************* */
  const [active, setActive] = useState("job_dashboard");

  const [searchText, setSearchText] = useState(null);
  const [loading, setLoading] = useState(false);

  const [locationsData, setLocationsData] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const woByOrderStatus = ["pending", "processing"];

  const [WOstatus, setWOstatus] = useState("pending");

  const [productTypesData, setProductTypesData] = useState(false);
  const [productTypes, setProductTypes] = useState(false);

  const [refreshStatusList, setRefreshStatusList] = useState({});

  /*   All Functions
   ********************************************* */

  const getAllLocations = async () => {
    try {
      setLoading(true);
      const { data } = await API.get("/locations", { headers: authHeader() });

      setLocationsData(data);

      setLoading(false);
    } catch (err) {
      alert(err.message);
      setLoading(false);
    }
  };
  const getAllProductTypes = async () => {
    try {
      const { data } = await API.get("/productTypes", {
        headers: authHeader(),
      });
      // Setting This Product Types Data for dropdown and changing productTypes in quick view
      setProductTypesData(data);

      let refreshList = {};

      let productTypeList = data?.map((item) => item?.productTypes);

      let updatedProductTypeList = [
        "Pending",
        "READY TO BILL",
        ...productTypeList,
      ];

      // Getting Refresh List From Possible Product Types.
      updatedProductTypeList?.map((item) => {
        refreshList[item] = false;
      });
      setRefreshStatusList(refreshList);

      // Setting This to loop over Product Wise Card
      setProductTypes(updatedProductTypeList);
    } catch (err) {
      alert(err.message);
      setLoading(false);
    }
  };

  const refreshAll = () => {
    setRefreshStatusList((prev) => {
      let newList = {};
      Object.keys(prev)?.map((item) => {
        newList[item] = true;
      });

      return newList;
    });
  };

  /*   All UseEffects
   ********************************************* */

  useEffect(() => {
    getAllLocations();
    getAllProductTypes();
  }, []);

  return (
    <div
      className=""
      style={{ paddingLeft: 280, paddingTop: 20, height: "90vh" }}
    >
      {loading && <Spinner />}
      <Toast />
      <div
        className="nav nav-tabs nav-bordered mb-3 bg-white shadow-sm py-2  d-flex justify-content-between flex-grow-1"
        style={{
          position: "fixed",
          top: 0,
          width: "100vw",
          left: 0,
          paddingLeft: 280,
          zIndex: 101,
        }}
      >
        <div className="d-flex">
          <div
            onClick={() => {
              setActive("job_dashboard");
            }}
          >
            <button
              className={
                active === "job_dashboard"
                  ? `btn rounded-0 active bg-primary text-white`
                  : "btn rounded-0"
              }
            >
              <span className="d-none d-md-block">Job Dashboard</span>
            </button>
          </div>
          <div
            onClick={() => {
              setActive("pending_jobs");
            }}
          >
            <button
              className={
                active == "pending_jobs"
                  ? `btn rounded-0 active bg-primary text-white`
                  : "btn rounded-0"
              }
            >
              <span className="d-none d-md-block">Pending Jobs</span>
            </button>
          </div>
        </div>
        <div className="d-flex align-items-center gap-4">
          <button onClick={refreshAll} className="btn btn-success">
            Refresh All
          </button>
          {active === "pending_jobs" && (
            <div className="d-flex align-items-center gap-2 text-end">
              <h4>Status:</h4>
              <select
                className="form-control form-control-sm"
                value={WOstatus}
                onChange={(e) => {
                  setWOstatus(e.target.value);
                }}
              >
                <option value={"pending"}> Pending </option>
                <option value={"processing"}> Processing </option>
                <option value={"completed"}> Completed </option>
              </select>
            </div>
          )}
          <div className="d-flex align-items-center gap-2 text-end">
            <h4>Location:</h4>
            <select
              className="form-control form-control-sm"
              defaultValue={""}
              onChange={(e) => {
                setSelectedLocation(e.target.value);
              }}
            >
              <option value={""}> All </option>
              {locationsData?.map((item) => (
                <option key={item?.id} value={item?.id}>
                  {item?.locationName}
                </option>
              ))}
            </select>
          </div>
          <div className="text-end me-3">
            <div className="d-flex justify-content-center align-items-center">
              <input
                type="search"
                placeholder="Search..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                className="form-control me-3"
              />
            </div>
          </div>
        </div>
      </div>
      {active === "job_dashboard" && (
        <div className="">
          <JobDashBoard
            selectedLocation={selectedLocation}
            searchText={searchText}
            productTypes={productTypes}
            productTypesData={productTypesData}
            refreshStatusList={refreshStatusList}
            setRefreshStatusList={setRefreshStatusList}
          />
        </div>
      )}
      {active === "pending_jobs" && (
        <div
          className="d-flex flex-wrap p-3 gap-2 align-items-top mt-5 justify-content-between overflow-auto "
          style={{ height: "85vh" }}
        >
          {productTypesData.map((item) => (
            <PendingJobs
              key={item?.id}
              productType={item?.productTypes}
              filterBy={WOstatus}
              filterText={searchText}
              refreshStatusList={refreshStatusList}
              setRefreshStatusList={setRefreshStatusList}
              productTypesData={productTypesData}
            />
          ))}
        </div>
      )}
    </div>
  );
}
