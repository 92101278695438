import Spinner from "components/Spinner";
import { useRef, useState } from "react";
import authHeader from "services/auth-header";
import API from "services/axios";
import * as xlsx from "xlsx";

function ImportWOProductsExcel({ selectedWO, refreshFunc, showModal }) {
  /*   ALl States
   ********************************************* */
  const [woProductsData, setWoProductsData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const inputRef = useRef();

  /*   All functions
   ********************************************* */
  const handleFileImport = (e) => {
    const reader = new FileReader();

    reader.readAsArrayBuffer(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = xlsx.read(data, { type: "array" });
      const jsonData = xlsx.utils.sheet_to_json(workbook.Sheets["wo_products"]);

      let sortedProductsBySortId = selectedWO?.WOProducts?.sort(
        (a, b) => b.sortId - a.sortId
      );
      let lastSortId = sortedProductsBySortId?.length
        ? sortedProductsBySortId[0]?.sortId
        : 0;

      let updatedJsonData = jsonData?.map((item, index) => ({
        ...item,
        sortId: lastSortId + (index + 1),
        WOId: selectedWO?.id,
        userId: selectedWO?.userId,
        companyId: selectedWO?.companyId,
      }));

      setWoProductsData(updatedJsonData);
    };
  };

  const addCustomerFromFile = async () => {
    setIsLoading(true);

    try {
      let { data } = await API.post(
        "/wo-products/bulk-insert",
        woProductsData,
        {
          headers: authHeader(),
        }
      );
      if (data?.type === "success") {
        await refreshFunc();
        showModal(false);
        return;
      }
      console.log(data);
      return;
    } catch (err) {
      setIsLoading(false);
      alert(err.message);
    }
  };

  return (
    <div className="bg-white px-3 py-2 custom-border-radius shadow-lg">
      <div className="">
        <h4 className="">IMPORT DATA FILE</h4>
      </div>
      <hr className="mb-3 mt-0" />

      <div className="d-flex">
        <input
          ref={inputRef}
          type="file"
          className="form-control"
          onChange={handleFileImport}
        />
        <div className="d-flex">
          <button
            onClick={() => {
              inputRef.current.value = null;
              setWoProductsData(null);
            }}
            className="btn btn-danger ms-1"
          >
            CLEAR
          </button>
        </div>
      </div>

      {isLoading && <Spinner />}

      {/*   Buttons
       ********************************************* */}
      <div className="mt-3 flex-grow-1 d-flex justify-content-end align-items-end">
        <div>
          <button
            onClick={() => {
              showModal(false);
            }}
            type="button"
            className="btn btn-secondary me-2 px-1"
          >
            CLOSE
          </button>
          <button
            disabled={!woProductsData}
            onClick={addCustomerFromFile}
            className="btn btn-primary"
          >
            ADD
          </button>
        </div>
      </div>
    </div>
  );
}

export default ImportWOProductsExcel;
