import JobBoardPdf from "components/ProductPdfPrintButton/JobBoardPdf";
import Toast from "components/Toast/ToastComponent";
import { setNotification } from "features/Notification/notificationSlice";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";
import "./DisplayBoard.css";
import JobBoardFullViewData from "./JobBoardFullViewData";
import WOProductQuickView from "./WOProductQuickView";
import Spinner from "components/Spinner";

export default function ProductWiseItem({
  filterBy,
  filterText,
  selectedLocation,
  productTypesData,
  refreshStatusList,
  setRefreshStatusList,
}) {
  const dispatch = useDispatch();
  /*   All States
   ********************************************* */

  const [loading, setLoading] = useState(false);

  const [showProductsModal, setShowProductsModal] = useState(false);
  const [openFullView, setOpenFullView] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [WOData, setWOData] = useState(null);
  const [filteredSearchedData, setFilteredSearchedData] = useState(null);

  const [refresh, setRefresh] = useState(false);

  /*   All Functions
   ********************************************* */
  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  const getTotalQty = (products) => {
    if (products && products.length > 0) {
      let totalQty = 0;
      products?.map((item) => {
        totalQty += item?.totalQuantity;
      });
      return totalQty;
    } else {
      return 0;
    }
  };

  const getWOByproductType = async () => {
    try {
      loadingOn();
      let apiPath = "/job-board/product-type";
      if (filterBy === "READY TO BILL") {
        apiPath = "/job-board/ready-without-complete";
      } else if (filterBy === "Pending") {
        apiPath = "/job-board/pending";
      }
      const { data } = await API.post(
        apiPath,
        { productType: filterBy },
        { headers: authHeader() }
      );
      if (data.type === "success") {
        setWOData(data.result);
        setRefresh(false);
        setRefreshStatusList((prev) => {
          prev[filterBy] = false;
          return prev;
        });
        loadingOff();
        return data.result;
      }
      if (data.type === "error") {
        dispatch(
          setNotification({
            message: data.message,
            type: "error",
          })
        );
        setRefresh(false);
        loadingOff();
        return;
      }

      loadingOff();
    } catch (err) {
      loadingOff();
      console.log(err.message);
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      setRefresh(false);
    }
  };
  /*   All UseEffects
   ********************************************* */

  useEffect(() => {
    if (filterText || selectedLocation) {
      let filteredData = null;
      if (filterText && selectedLocation) {
        filteredData = WOData?.filter((item) => {
          return (
            item?.locationId === parseInt(selectedLocation) &&
            (item?.id.toString().includes(filterText) ||
              item?.jobName?.toLowerCase().includes(filterText.toLowerCase()) ||
              item?.customerName
                ?.toLowerCase()
                .includes(filterText.toLowerCase()))
          );
        });
        return setFilteredSearchedData(filteredData);
      }
      if (filterText) {
        filteredData = WOData?.filter((item) => {
          return (
            item?.id.toString().includes(filterText) ||
            item?.jobName?.toLowerCase().includes(filterText.toLowerCase()) ||
            item?.customerName?.toLowerCase().includes(filterText.toLowerCase())
          );
        });
        return setFilteredSearchedData(filteredData);
      }
      if (selectedLocation) {
        filteredData = WOData?.filter((item) => {
          return item?.locationId === parseInt(selectedLocation);
        });
        return setFilteredSearchedData(filteredData);
      }
    } else {
      setFilteredSearchedData(null);
    }
  }, [filterText, selectedLocation, WOData]);

  useEffect(() => {
    getWOByproductType();
  }, []);

  useEffect(() => {
    if (refresh) {
      getWOByproductType();
    }
  }, [refresh]);

  useEffect(() => {
    if (refreshStatusList[filterBy]) {
      getWOByproductType();
    }
  }, [refreshStatusList[filterBy]]);

  return (
    <div
      className="col-6 p-2 overflow-hidden"
      style={{ minHeight: "35%", maxHeight: "45%" }}
    >
      {loading && <Spinner />}
      <Toast />
      <div className="flex-grow-1 pb-5 bg-white h-100 custom-shadow-sm custom-border-radius ">
        {/* ******************** FilterBy Header ************************* */}
        <div className="topHeader bg-primary text-white custom-border-radius d-flex justify-content-between align-items-center">
          <div>{filterBy}</div>
          <div>
            {WOData && WOData?.length && filterBy ? (
              <JobBoardPdf
                filterBy={filterBy}
                workOrderList={
                  filteredSearchedData ? filteredSearchedData : WOData
                }
              />
            ) : (
              <div></div>
            )}
            <button
              onClick={() => {
                setWOData(null);
                setRefresh(true);
              }}
              className="btn btn-sm btn-info"
            >
              Refresh
            </button>
            <button
              onClick={() => {
                setOpenFullView(true);
              }}
              className="btn btn-sm btn-success mx-2"
            >
              Open
            </button>
            {filterText || selectedLocation
              ? filteredSearchedData?.length
              : WOData?.length}
          </div>
        </div>
        <div className="bodyHeader row">
          <div className="col-1">ID</div>
          <div className="col-5">Job Name</div>
          <div className="col-4">Customer Name</div>
          <div className="col-2">Quantity</div>
        </div>
        {WOData && WOData?.length ? (
          <div className="bodyContent overflow-auto ">
            {filteredSearchedData
              ? filteredSearchedData?.map((item, index) => (
                  <div
                    onDoubleClick={() => {
                      setShowProductsModal(true);
                      setSelectedItem(item);
                    }}
                    key={index}
                    className="row item"
                  >
                    <div className="col-1">{item?.id}</div>
                    <div className=" col-5 text-nowrap">
                      {item?.jobName?.slice(0, 30)}
                      {item?.jobName?.length >= 30 ? "..." : ""}
                    </div>
                    <div className=" col-4">
                      {item?.customerName?.slice(0, 30)}
                      {item?.customerName?.length >= 30 ? "..." : ""}
                    </div>
                    <div className="col-2 text-end">
                      {getTotalQty(item?.WOProducts)}
                    </div>
                  </div>
                ))
              : WOData &&
                WOData?.map((item, index) => (
                  <div
                    onDoubleClick={() => {
                      setShowProductsModal(true);
                      setSelectedItem(item);
                    }}
                    key={index}
                    className="row item"
                  >
                    <div className="col-1">{item?.id}</div>
                    <div className=" col-5 text-nowrap">
                      {item?.jobName?.slice(0, 30)}
                      {item?.jobName?.length >= 30 ? "..." : ""}
                    </div>
                    <div className=" col-4">
                      {item?.customerName?.slice(0, 30)}
                      {item?.customerName?.length >= 30 ? "..." : ""}
                    </div>
                    <div className="col-2 text-end">
                      {getTotalQty(item?.WOProducts)}
                    </div>
                  </div>
                ))}
          </div>
        ) : (
          <div className="flex-center h-50">No Orders</div>
        )}
        {/*   View ALL Modal
         ********************************************* */}
        <Modal
          size="xl"
          show={openFullView}
          onHide={() => setOpenFullView(false)}
        >
          <Modal.Body className="scrollbar-hide ">
            <div>
              <JobBoardFullViewData
                filterBy={filterBy}
                WOData={filterText ? filteredSearchedData : WOData}
              />
            </div>
            <div className="text-end">
              <button
                className="btn btn-secondary"
                onClick={() => {
                  setOpenFullView(false);
                }}
              >
                Close
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {/*   Quick View Modal
         ********************************************* */}
        <Modal
          size="xl"
          show={showProductsModal}
          onHide={() => setShowProductsModal(false)}
        >
          <Modal.Body>
            {selectedItem && (
              <WOProductQuickView
                filterBy={filterBy}
                selectedWOID={selectedItem?.id}
                allWOData={filterText ? filteredSearchedData : WOData}
                refreshFunc={getWOByproductType}
                setShowProductsModal={setShowProductsModal}
                productTypesData={productTypesData}
              />
            )}
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}
