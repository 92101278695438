import { BlobProvider } from "@react-pdf/renderer";
import { useSelector } from "react-redux";
import { JobBoardPdfDocument } from "./JobBoardPdfDocument";
import { useEffect } from "react";

function JobBoardPdf({ filterBy, workOrderList }) {
  /*   All States
   ********************************************* */
  const user = useSelector((state) => state.user?.userInfo);

  return (
    <BlobProvider
      document={
        <JobBoardPdfDocument
          filterBy={filterBy}
          workOrderList={workOrderList}
        />
      }
    >
      {({ url, blob }) => {
        return (
          <>
            <button disabled={!blob} className="btn btn-success btn me-1  ms-1">
              <a
                className="text-decoration-none text-white"
                href={url}
                target="_blank"
                rel="noreferrer"
              >
                <i className="mdi mdi-download"></i>Download List
                {!url && (
                  <div
                    className="spinner-border spinner-border-sm text-white ms-2 mb-1"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </a>
            </button>
          </>
        );
      }}
    </BlobProvider>
  );
}

export default JobBoardPdf;
