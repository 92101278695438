import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import authHeader from 'services/auth-header';
import API from 'services/axios';

function TaxRateAdd({ showAddPage, refreshFunc, lastSortId }) {
  /*   All Satates Below
   ********************************************* */
  const { register, handleSubmit } = useForm();
  const [formError, setFormError] = useState({});
  // redux

  /*   All Functions Below
   ********************************************* */
  const addTaxRateData = async (formData, e) => {
    setFormError({});
    e.preventDefault();
    if (formData.termName === '') {
      setFormError({
        termName: { type: 'termName', message: 'Term Name is required' },
      });
      return;
    }

    try {
      const { data } = await API.post(
        '/taxrate',
        { data: { ...formData, sortId: lastSortId + 1 } },
        { headers: authHeader() }
      );
      if (data.message.type === 'success') {
        refreshFunc();
        showAddPage(false);
        return;
      }
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <div className='row'>
      <div className='col-12 px-3'>
        {/*    ADD FORM START
         ********************************************* */}
        <div className='mb-3'>
          <h3>ADD Tax Rate</h3>
        </div>
        <form className='' onSubmit={handleSubmit(addTaxRateData)}>
          <div className='row'>
            {/* ----------  Left Section  ----------- */}
            <div className='col-12'>
              <div className='mb-3'>
                <label htmlFor='taxAccount' className='form-label mb-0'>
                  Tax Account Number
                </label>
                <input
                  className='form-control'
                  type='text'
                  required
                  id='taxAccount'
                  placeholder='Tax Account'
                  {...register('taxAccountNumber')}
                />
              </div>

              <div className='mb-3'>
                <label htmlFor='taxRegionName' className='form-label mb-0'>
                  Tax Account Name
                </label>
                <input
                  className='form-control'
                  type='text'
                  required
                  id='taxAccountName'
                  placeholder=' Tax Account Name'
                  {...register('taxAccountName')}
                />
              </div>
              <div className=''>
                <label htmlFor='taxRate' className='form-label mb-0'>
                  Tax Rate
                </label>
                <input
                  className='form-control'
                  type='text'
                  required
                  id='taxRate'
                  placeholder='Tax Rate'
                  {...register('taxRate')}
                />
              </div>
            </div>
          </div>

          {/*   Buttons
           ********************************************* */}
          <div className={`mt-4 mb-2 d-flex justify-content-end`}>
            <div>
              <button
                className='btn px-1 btn-secondary'
                type='button'
                onClick={() => {
                  showAddPage(false);
                }}
              >
                Cancel
              </button>
              <button className='btn btn-primary ms-1' type='submit'>
                ADD
              </button>
            </div>
          </div>
        </form>

        {/*    ADD FORM END
         ********************************************* */}
      </div>
    </div>
  );
}

export default TaxRateAdd;
