import Table from "components/DataTable/Table";
import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import { useEffect, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import authHeader from "services/auth-header";
import API from "services/axios";
import {
  convertAttendanceRecordToTime,
  convertSqlTimestampToDate,
} from "utils/UtilityFuncs";
import AttUserInfo from "./AttUserInfo";
import GenerateAttendance from "./GenerateAttendance";
import DeleteModalButton from "pages/artApproval/Mockups/components/DeleteButton/DeleteModalButton";
import AttAddUpdateRecord from "./AttAddUpdateRecord";

export default function AttendanceDetailsPage({}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  /*   All States
   ********************************************* */

  const [userAttendanceLogs, setUserAttendanceLogs] = useState(null);
  const [userData, setUserData] = useState(null);

  const [showDateFilter, setShowDateFilter] = useState(false);

  const [startDate, setStartDate] = useState(() => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), 2)
      .toISOString()
      .slice(0, 10)
      .replace("T", " ");
  });

  const [endDate, setEndDate] = useState(
    new Date().toISOString().slice(0, 10).replace("T", " ")
  );

  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [updateData, setUpdateData] = useState(null);
  const [holidaysList, setHolidaysList] = useState(null);
  const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  /*   All Functions
   ********************************************* */

  const getLogsByDate = async () => {
    try {
      loadingOn();
      const { data } = await API.post(
        "/get-user-logs/" + params.userId,
        {
          deviceId: params.deviceId,
          startDate,
          endDate,
        },
        {
          headers: authHeader(),
        }
      );

      if (data?.success) {
        setUserAttendanceLogs(data?.data);
      } else {
        dispatch(
          setNotification({
            message: data?.message,
            type: "error",
          })
        );
      }
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const getUserInfoByID = async () => {
    try {
      let userId = params.userId;
      let deviceId = params.deviceId;

      if (!userId || !deviceId) {
        return;
      }
      loadingOn();
      const { data } = await API.get(
        "/device-user-id/" + deviceId + "/" + userId,
        {
          headers: authHeader(),
        }
      );
      if (data?.success) {
        setUserData(data?.data);
        setHolidaysList(JSON.parse(data?.deviceInfo?.holidaysList));
      }
      if (!data?.success) {
        dispatch(
          setNotification({
            message: data?.message,
            type: "error",
          })
        );
      }

      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const syncWithDatabaseLogs = async () => {
    try {
      loadingOn();
      const { data } = await API.get("/sync-database-logs/" + params.deviceId, {
        headers: authHeader(),
      });
      if (data?.success) {
        await getLogsByDate();
        dispatch(
          setNotification({
            message: data?.message,
            type: "success",
          })
        );
      } else {
        dispatch(
          setNotification({
            message: data?.message,
            type: "error",
          })
        );
      }

      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const parsedRecordTime = (recordTime) => {
    //  Get Record time of format "18:36:05" as 6:36 PM
    const timeFromRecord = convertAttendanceRecordToTime(recordTime);

    const time = timeFromRecord.split(":");
    let hours = parseInt(time[0]);
    let minutes = parseInt(time[1]);
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  // Delete Record
  const deleteRecord = async (id) => {
    try {
      loadingOn();
      const { data } = await API.delete("/attendance-logs/" + id, {
        headers: authHeader(),
      });
      if (data?.success) {
        dispatch(
          setNotification({
            message: data?.message,
            type: "success",
          })
        );
        getLogsByDate();
      } else {
        dispatch(
          setNotification({
            message: data?.message,
            type: "error",
          })
        );
      }
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    let timeout = setTimeout(() => {
      getLogsByDate();
    }, 0.5);
    return () => clearTimeout(timeout);
  }, [params]);

  useEffect(() => {
    let clearTimeoutId = setTimeout(() => {
      getUserInfoByID();
    }, 500);
    return () => clearTimeout(clearTimeoutId);
  }, [params]);

  /*   Table functions
   ********************************************* */
  const btn1 = () => (
    <div className="flex-grow-1">
      <button
        className="btn btn-primary me-1"
        onClick={() => {
          syncWithDatabaseLogs();
        }}
      >
        Sync with Database
      </button>
      <button
        className="btn btn-primary"
        onClick={() => {
          setShowAddUpdateModal(true);
        }}
      >
        ADD
      </button>
      {userData && (
        <GenerateAttendance
          deviceId={params.deviceId}
          holidaysList={holidaysList}
          userData={userData}
        />
      )}

      <Dropdown
        className="d-inline"
        align={{ lg: "end" }}
        show={showDateFilter}
        autoClose={false}
      >
        <Dropdown.Toggle
          onClick={() => setShowDateFilter(!showDateFilter)}
          className="btn btn-success mx-2"
          id="dropdown-autoclose-false"
        >
          Filter by Date
        </Dropdown.Toggle>

        <Dropdown.Menu className="bg-white shadow-lg pt-3 rounded mt-1">
          <div className="m-2 my-1 " style={{ minWidth: 250 }}>
            {/* From */}
            <div className="row mt-2">
              <div className="col-3  d-flex justify-content-start ms-1 align-items-center ">
                <div className="">From</div>
              </div>
              <div className="col-8 px-0">
                <input
                  className="form-control"
                  value={startDate || ""}
                  onChange={(e) => setStartDate(e.target.value)}
                  type={"date"}
                />
              </div>
            </div>
            {/* To */}
            <div className="row">
              <div className="col-3  d-flex justify-content-start ms-1 align-items-center ">
                <div className="">TO</div>
              </div>
              <div className="col-8 px-0">
                <input
                  className="form-control"
                  value={endDate || ""}
                  onChange={(e) => setEndDate(e.target.value)}
                  type={"date"}
                />
              </div>
            </div>
            <div className="d-flex justify-content-end mt-2 ">
              <button
                onClick={() => {
                  getLogsByDate();
                  setShowDateFilter(!showDateFilter);
                }}
                className="btn btn-success mb-1 btn-sm mx-2"
              >
                FILTER
              </button>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );

  const table_head = ["ID", "Attendance Time", "Attendance Date", "Actions"];

  const table_body = (item, index) => (
    <tr className="" key={item.id}>
      <td>{item?.id}</td>
      <td>{parsedRecordTime(item.recordTime)}</td>
      <td>{convertSqlTimestampToDate(item.recordTime)}</td>
      <td className="table-action">
        <button
          onClick={(e) => {
            setIsUpdateMode(true);
            setUpdateData(item);
            setShowAddUpdateModal(true);
          }}
          className="btn btn-sm btn-primary me-1"
        >
          <i className="mdi mdi-pencil"></i>
        </button>
        <DeleteModalButton
          deleteFunc={deleteRecord}
          deleteParams={item.id}
          disableConditions={loading}
        />
      </td>
    </tr>
  );

  return (
    <div
      className=""
      style={{
        marginLeft: 280,
        height: "100vh",
        paddingTop: 20,
        paddingRight: 30,
      }}
    >
      {loading && <Spinner />}
      <div className="d-flex justify-content-between align-items-center">
        <h1>Detail Page</h1>
        <button
          type="button"
          className="btn btn-danger"
          onClick={() => {
            navigate("/attendance?deviceId=" + params.deviceId);
          }}
        >
          Back
        </button>
      </div>

      {/*   User Info
       ********************************************* */}

      <AttUserInfo userData={userData} refreshFunc={getUserInfoByID} />

      {/*   Table
       ********************************************* */}
      <div
        className="px-2 flex-grow-1 d-flex"
        style={{ minHeight: "40vh", borderRadius: 15 }}
      >
        <div className="horizontal-scrollable d-flex flex-grow-1">
          <Table
            btn1={btn1}
            defaultLimit={5}
            disableSearch={true}
            table_head={table_head}
            table_body={table_body}
            table_data={userAttendanceLogs}
          />
        </div>
      </div>

      <Modal
        contentClassName="bg-transparent"
        show={showAddUpdateModal}
        onHide={() => {
          setShowAddUpdateModal(false);
        }}
      >
        <Modal.Body className="bg-white custom-border-radius px-4">
          <AttAddUpdateRecord
            isUpdateMode={isUpdateMode}
            updateData={updateData}
            showModal={setShowAddUpdateModal}
            setIsUpdateMode={setIsUpdateMode}
            setUpdateData={setUpdateData}
            refreshFunc={getLogsByDate}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
