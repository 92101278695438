import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ImageWIthSignedUrl from "../../../ImageWIthSignedUrl";
import AddNewProduct from "./AddNewProduct";
import authHeader from "services/auth-header";
import API from "services/axios";
import { useSelector } from "react-redux";
import DeleteModalButton from "pages/artApproval/Mockups/components/DeleteButton/DeleteModalButton";
import Spinner from "components/Spinner";
import DesRUpdateProduct from "./DesRUpdateProduct";

export default function ProductSlider({
  selectedDesign,
  setSelectedProduct,
  selectedProduct,
  setSelectedDesign,
  setDesignRequests,
  updateSelectedProduct,
}) {
  /*   All States
   ********************************************* */
  const user = useSelector((state) => state.user?.userInfo);
  const [selectedProductIndex, setSelectedProductIndex] = useState(0);
  const [showAddModal, setShowAddModal] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  /*   All Functions
   ********************************************* */

  const deleteProduct = async () => {
    try {
      let updatedProducts = selectedDesign?.products.filter(
        (item) => item.id !== selectedProduct.id
      );

      setDesignRequests((prev) => {
        return prev.map((item) => {
          if (item.id === selectedDesign.id) {
            let newItem = { ...item, products: updatedProducts };
            setSelectedDesign((prev) => newItem);
            return newItem;
          } else {
            return item;
          }
        });
      });

      let deletePath = `kms/design-requests/${user?.companyId}/${selectedDesign?.id}/${selectedProduct?.id}`;

      const { data } = await API.patch(
        "/design-request/delete-product",
        {
          id: selectedDesign?.id,
          products: JSON.stringify(updatedProducts),
          path: deletePath,
        },
        { headers: authHeader() }
      );
      if (data?.success) {
        // showModal(false);
      } else {
        alert("Something went wrong!");
        console.log(data);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    if (selectedDesign?.products) {
      console.log(selectedDesign?.products);

      setSelectedProduct(
        (prev) => selectedDesign?.products[selectedProductIndex]
      );
    }
  }, [selectedDesign, selectedProductIndex]);

  return (
    <div
      className="bg-white scrollbar-hide custom-border-radius shadow-lg p-2 pt-3  "
      style={{ height: "76vh", flexShrink: 0 }}
    >
      <button
        onClick={() => {
          setShowAddModal(true);
        }}
        className="btn btn-success mb-3 btn-sm w-100"
      >
        Add Design
      </button>
      {selectedDesign?.products?.map((product, index) => (
        <div
          className={`${
            selectedProduct === product
              ? "bg-primary bg-opacity-50"
              : " bg-white"
          } mb-2 custom-shadow-sm custom-border-radius-sm`}
          onClick={async () => {
            if (product?.newGuestMsg) {
              loadingOn();
              await updateSelectedProduct({ ...product, newGuestMsg: false });
              loadingOff();
            }
            setSelectedProductIndex((prev) => index);
          }}
          key={index}
        >
          <div
            className="flex-center p-2 position-relative"
            style={{ width: "auto", height: 100 }}
          >
            <div className="btn btn-sm btn-primary position-absolute top-0 start-0 ">
              {product?.id}
            </div>
            <div className="dropdown dropend position-absolute top-0 end-0 ">
              <button
                className="btn btn-sm p-0 btn-primary "
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-haspopup="false"
                aria-expanded="true"
              >
                <i className="mdi mdi-dots-vertical"> </i>
              </button>
              <div
                style={{ zIndex: 999 }}
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <button
                  onClick={(e) => {
                    setShowEditModal(true);
                  }}
                  className="dropdown-item"
                >
                  Edit
                </button>
                <button
                  onClick={(e) => {
                    setShowDeleteModal(true);
                  }}
                  className="dropdown-item text-danger"
                >
                  Delete
                </button>
              </div>
            </div>
            {product?.newGuestMsg && (
              <small
                style={{ bottom: 0, right: -5 }}
                className="position-absolute bg-danger text-white p-0 px-1"
              >
                New
              </small>
            )}

            <ImageWIthSignedUrl
              imgStyle={{ width: "100%", height: "100%", objectFit: "cover" }}
              path={product?.images[0].img}
            />
          </div>
        </div>
      ))}
      {/*   Add New Product
       ********************************************* */}
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Body
          className="shadow-lg bg-white px-3 "
          style={{ borderRadius: 15 }}
        >
          <AddNewProduct
            setShowAddModal={setShowAddModal}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            selectedDesign={selectedDesign}
            setSelectedDesign={setSelectedDesign}
            setDesignRequests={setDesignRequests}
          />
        </Modal.Body>
      </Modal>
      {/*   Delete Modal
       ********************************************* */}
      <Modal
        contentClassName="bg-transparent text-white"
        show={showDeleteModal}
        size="sm"
        onHide={() => setShowDeleteModal(false)}
      >
        <Modal.Body
          className="shadow-lg bg-danger px-3 py-3"
          style={{ borderRadius: 15 }}
        >
          {loading && <Spinner />}
          <div className=" text-center">
            <i className="ri-close-circle-line h1"></i>
            <h4 className="mt-2">Confirm Delete!</h4>
            <p className="mt-3">Do You want to delete ?</p>
          </div>
          <div className="text-center">
            <button
              onClick={() => {
                setShowDeleteModal(false);
              }}
              disabled={loading}
              className="btn btn-sm btn-secondary"
            >
              Cancel
            </button>
            <button
              onClick={async () => {
                loadingOn();
                await deleteProduct();
                loadingOff();
                setShowDeleteModal(false);
              }}
              className="btn btn-sm btn-info ms-1"
            >
              Delete
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {/*   Edit Modal
       ********************************************* */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Body
          className="shadow-lg bg-white px-3 "
          style={{ borderRadius: 15 }}
        >
          <h3>Edit {selectedProduct?.title}</h3>
          <DesRUpdateProduct
            selectedDesign={selectedDesign}
            setSelectedDesign={setSelectedDesign}
            setDesignRequests={setDesignRequests}
            selectedProduct={selectedProduct}
            showModal={setShowEditModal}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
