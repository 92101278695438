import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  password: null,
  includeLowerCase: true,
  includeUpperCase: true,
  includeNumer: true,
  includeSpecialChars: true,
  passwordLength: 8,
};

const passwordSlice = createSlice({
  name: 'password',
  initialState,
  reducers: {
    generatePassword: (state) => {
      let newPassword = '';
      const possibleChars = [];

      if (state.includeUpperCase == true) {
        possibleChars.push('ABCDEFGHIJKLMNOPQRSTUVWXYZ');
      }

      if (state.includeLowerCase == true) {
        possibleChars.push('abcdefghijklmnopqrstuvwxyz');
      }

      if (state.includeNumer == true) {
        possibleChars.push('0123456789');
      }
      if (state.includeSpecialChars) {
        possibleChars.push('{)!}?(*&$#@');
      }

      if (possibleChars.length === 0) {
        state.password = 'Please select at least one option';

        return;
      }

      for (let i = 0; i < state.passwordLength; i++) {
        const randomCharSet = possibleChars[Math.floor(Math.random() * possibleChars.length)];
        const randomCharIndex = Math.floor(Math.random() * randomCharSet.length);
        newPassword += randomCharSet[randomCharIndex];
      }
      state.password = newPassword;
    },
    changeLowerCase: (state, { payload }) => {
      state.includeLowerCase = payload;
    },
    changeUpperCase: (state, { payload }) => {
      state.includeUpperCase = payload;
    },
    changeNumber: (state, { payload }) => {
      state.includeNumer = payload;
    },
    changeSpecialChars: (state, { payload }) => {
      state.includeSpecialChars = payload;
    },
    changePasswordLength: (state, { payload }) => {
      state.passwordLength = payload;
    },
  },
});

export const {
  generatePassword,
  changePasswordLength,
  changeLowerCase,
  changeUpperCase,
  changeNumber,
  changeSpecialChars,
} = passwordSlice.actions;

export default passwordSlice.reducer;
