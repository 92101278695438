import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import authHeader from 'services/auth-header';
import API from 'services/axios';

function DiscountLevelAdd({ showAddPage, refreshFunc, lastSortId }) {
  /*   All Satates Below
   ********************************************* */
  const { register, handleSubmit } = useForm();
  const [formError, setFormError] = useState({});
  // redux

  /*   All Functions Below
   ********************************************* */
  const addDiscountLevel = async (formData, e) => {
    setFormError({});
    e.preventDefault();
    if (formData.discountName === '') {
      setFormError({
        discountName: { type: 'discountLevel', message: 'Discount Level is required' },
      });
      return;
    }

    try {
      const { data } = await API.post(
        '/discountlevels',
        { data: { ...formData, sortId: lastSortId + 1 } },
        { headers: authHeader() }
      );
      if (data.message.type === 'success') {
        refreshFunc();
        showAddPage(false);
        return;
      }
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <div className='row'>
      <div className='col-12'>
        {/*    ADD FORM START
         ********************************************* */}
        <div className='mb-13 ms-3'>
          <h3>ADD Discount Level</h3>
        </div>
        <form className='ps-3 pe-3' onSubmit={handleSubmit(addDiscountLevel)}>
          {/* ----------  Fields  ----------- */}
          <div className='mt-3 mb-3'>
            <label htmlFor='discountName' className='form-label'>
              Discount Name
            </label>
            <input
              className='form-control'
              type='text'
              id='discountName'
              placeholder='Enter Discount Name'
              {...register('discountName')}
            />
            {formError && formError.discountName && (
              <p className='text-danger'>{formError.discountName.message}</p>
            )}
          </div>

          <div className={`mt-4 mb-2 d-flex justify-content-end`}>
            <div>
              <button
                className='btn px-1 btn-secondary'
                type='button'
                onClick={() => {
                  showAddPage(false);
                }}
              >
                Cancel
              </button>
              <button className='btn btn-primary ms-1' type='submit'>
                ADD
              </button>
            </div>
          </div>
        </form>

        {/*    ADD FORM END
         ********************************************* */}
      </div>
    </div>
  );
}

export default DiscountLevelAdd;
