// import axios from 'axios';

// const API = axios.create({
//   baseURL: process.env.REACT_APP_API_URI,
//   headers: {
//     'Content-Type': 'application/json',
//   },
// });

// export default API;
import axios from "axios";

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URI,
  headers: {
    "Content-Type": "application/json",
  },
  responseInterceptor: (response) => {
    return response;
  },
  requestInterceptor: (request) => {
    // Add your authentication logic here, e.g., attaching the authentication token to the request headers
    return request;
  },
});

API.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error);

    if (
      (error.response.status === 401 || error.response.status === 403) &&
      (error.response.data.message === "Token Expired!" ||
        error.response.data.message === "No token provided!")
    ) {
      // reset key user, userinfo, persist:root from localstorage
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("userinfo");
      localStorage.removeItem("persist:root");

      // Token expired, handle the error here
      // alert("Token Expired! Please log in again.");

      // Optionally, redirect the user to the login page

      window.location.href = "/brick-cloud/client-login";
    }
    return Promise.reject(error);
  }
);

export default API;
