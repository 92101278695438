import { createSlice } from "@reduxjs/toolkit";
import { getSettings } from "./settingsActions";

const initialState = {
  loading: false,
  settingsData: null,
  error: null,
  success: false,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    resetSettings: (state) => {
      state.settingsData = null;
    },
  },
  extraReducers: {
    // settigs data
    [getSettings.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getSettings.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.settingsData = payload;
    },
    [getSettings.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const { resetSettings } = settingsSlice.actions;

export default settingsSlice.reducer;
