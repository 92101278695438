import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function CustomerDropDowns({
  formData,
  setFormData,
  handleInputChange,
}) {
  /*   All States
   ********************************************* */
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [taxRateList, setTaxRateList] = useState(null);
  const [locationList, setLocationList] = useState(null);
  const [paymentTermsList, setPaymentTermsList] = useState(null);
  const [salesPersonsList, setSalesPersonsList] = useState(null);

  const [salesManagers, setSalesManagers] = useState(null);

  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  /*   All Functions
   ********************************************* */

  const getCustomerDropDowns = async () => {
    try {
      loadingOn();
      const { data } = await API.get("/customer/dropdowns", {
        headers: authHeader(),
      });
      if (data?.type === "success") {
        setTaxRateList(data?.result?.taxRateList);
        setLocationList(data?.result?.locationList);
        setPaymentTermsList(data?.result?.paymentTermsList);
        setSalesPersonsList(data?.result?.salesPersonsList);
      }
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const getSalesManger = async () => {
    try {
      loadingOn();
      const { data } = await API.get("/salesmanagers", {
        headers: authHeader(),
      });
      setSalesManagers(data);
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    getCustomerDropDowns();
    getSalesManger();
  }, []);
  return (
    <div className="col-6 flex-grow-1 px-3 py-2 my-1">
      {loading && <Spinner />}
      <div className="flex-grow-1 row mb-1">
        <div className="col-4 flex-center justify-content-start align-items-center">
          <label htmlFor={"locationId"}>Location</label>
        </div>
        <div className="col-8">
          <div className="d-flex align-items-center">
            <select
              name="locationId"
              className="form-select"
              value={formData?.locationId}
              onChange={(e) => {
                handleInputChange(e, "number");
              }}
            >
              <option value="">None</option>
              {locationList?.map((item) => (
                <option key={item?.id} value={item?.id}>
                  {item?.locationName}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="flex-grow-1 row mb-1">
        <div className="col-4 flex-center justify-content-start align-items-center">
          <label htmlFor={"taxAccountId"}>Tax Rate</label>
        </div>
        <div className="col-8">
          <div className="d-flex align-items-center">
            <select
              name="taxAccountId"
              className="form-select"
              value={formData?.taxAccountId}
              onChange={(e) => {
                handleInputChange(e, "number");
              }}
            >
              <option value="">None</option>
              {taxRateList?.map((item) => (
                <option key={item?.id} value={item?.id}>
                  {item?.taxAccountName} - {item?.taxRate}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="flex-grow-1 row mb-1">
        <div className="col-4 flex-center justify-content-start align-items-center">
          <label htmlFor={"paymentTermsId"}>Payment Terms</label>
        </div>
        <div className="col-8">
          <div className="d-flex align-items-center">
            <select
              name="paymentTermsId"
              className="form-select"
              value={formData?.paymentTermsId}
              onChange={(e) => {
                handleInputChange(e, "number");
              }}
            >
              <option value="">None</option>
              {paymentTermsList?.map((item) => (
                <option key={item?.id} value={item?.id}>
                  {item?.termName}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="flex-grow-1 row mb-1">
        <div className="col-4 flex-center justify-content-start align-items-center">
          <label htmlFor={"salesPersonId"}>Outside Sales Person</label>
        </div>
        <div className="col-8">
          <div className="d-flex align-items-center">
            <select
              name="salesPersonId"
              className="form-select"
              value={formData?.salesPersonId}
              onChange={(e) => {
                handleInputChange(e, "number");
              }}
            >
              <option value="">None</option>
              {salesPersonsList?.map((item) => (
                <option key={item?.id} value={item?.id}>
                  {item?.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="flex-grow-1 row mb-1">
        <div className="col-4 flex-center justify-content-start align-items-center">
          <label htmlFor={"salesManager"}>Inside Sales Manager</label>
        </div>
        <div className="col-8">
          <div className="d-flex align-items-center">
            <select
              name="salesManager"
              className="form-select"
              value={formData?.salesManager}
              onChange={(e) => {
                handleInputChange(e, "number");
              }}
            >
              <option value="">None</option>
              {salesManagers?.map((item) => (
                <option key={item?.id} value={item?.id}>
                  {item?.firstName} {item?.lastName}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="flex-grow-1 row mb-1">
        <div className="col-4 flex-center justify-content-start align-items-center">
          <label htmlFor={"poRequired"}>Po Required</label>
        </div>
        <div className="col-8">
          <div className="d-flex align-items-center">
            <select
              name="poRequired"
              className="form-select"
              value={formData?.poRequired}
              onChange={(e) => {
                handleInputChange(e, "text");
              }}
            >
              <option value="">None</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}
