import React, { useEffect, useState } from "react";

export default function AttRules({ formData, setFormData, handleChange }) {
  /*   All States
   ********************************************* */
  /** Rule 1:
   *      Calculate Overtime AFter Expected Hours + overtimeAfterMinutes
   * Rule 2:
   *      Only Calculate lateTime when inTime is greater than inTimeWithRelaxationFormatted
   *      Count lateTime from userInTime when inTime is less than inTimeWithRelaxationFormatted
   * */

  const [rules, setRules] = useState(formData?.rules);

  /*   All Functions
   ********************************************* */

  const handleRuleChange = (e) => {
    const { name, checked } = e.target;
    setRules((prevRules) => ({
      ...prevRules,
      [name]: { ...prevRules[name], isChecked: checked },
    }));
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    setFormData((prev) => {
      return { ...prev, rules: rules };
    });
  }, [rules]);

  return (
    <div>
      <h4>Attendance Rules</h4>
      {Object.keys(rules).map((rule) => (
        <div key={rule} className="mb-3">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id={rule}
              name={rule}
              onChange={handleRuleChange}
              checked={rules[rule].isChecked}
            />
            <label className="form-check-label" htmlFor={rule}>
              {rules[rule].name}
            </label>
            <p className="form-text">{rules[rule].description}</p>
          </div>
        </div>
      ))}
    </div>
  );
}
