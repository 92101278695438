import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function Pagination({ totalPages, currPage, apiPath }) {
  /*   All States
   ********************************************* */
  const navigate = useNavigate();
  const [noOfPages, setNoOfPages] = useState(null);
  const [searchParams] = useSearchParams();

  /*   All Functions
   ********************************************* */
  const navigateTo = (pageNo) => {
    let query = searchParams.get("query");
    let newPageNo = parseInt(pageNo) + 1;
    if (query) {
      navigate(apiPath + newPageNo + "&query=" + query);
    } else {
      navigate(apiPath + newPageNo);
    }
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    let pageArr = Array.from({ length: totalPages }, (_, index) => index);
    let currPageAr;

    if (currPage > pageArr.length) {
      currPageAr = [...pageArr.slice(pageArr.length - 10, pageArr.length)];
    } else if (currPage > 8) {
      currPageAr = [
        ...pageArr.slice(currPage - 5, currPage),
        ...pageArr.slice(currPage, currPage + 5),
      ];
    } else {
      currPageAr = [...pageArr.slice(0, 10)];
    }

    setNoOfPages(Array.from(currPageAr));
  }, [totalPages, currPage]);

  return (
    <div>
      {noOfPages && (
        <nav>
          <ul className="pagination flex-wrap justify-content-end">
            {totalPages?.length !== 0 && (
              <li className="page-item">
                <button
                  onClick={() => {
                    navigateTo(0);
                  }}
                  className="page-link"
                  aria-label="Previous"
                >
                  <span aria-hidden="true">&laquo;</span>
                </button>
              </li>
            )}

            {noOfPages?.map((el) => (
              <li key={el} className={`page-item `}>
                <button
                  onClick={() => {
                    navigateTo(el);
                  }}
                  className={`page-link  ${
                    currPage - 1 === el
                      ? "bg-primary text-white"
                      : "text-black bg-white"
                  } `}
                >
                  {el + 1}
                </button>
              </li>
            ))}

            {totalPages?.length !== 0 && (
              <li className="page-item">
                <button
                  onClick={() => {
                    navigateTo(totalPages - 1);
                  }}
                  className="page-link"
                  aria-label="Next"
                >
                  <span aria-hidden="true">&raquo;</span>
                </button>
              </li>
            )}
          </ul>
        </nav>
      )}
    </div>
  );
}
