import Table from "components/DataTable/Table";
import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Toast from "components/Toast/ToastComponent";
import useWorkOrder from "features/Contexts/WorkOrderContext";
import authHeader from "services/auth-header";
import API from "services/axios";
import CustomerWOProduct from "./CustomerWorkOrderProduct";

function CustomerWorkOrder({ id }) {
  const { setWorkOrderData } = useWorkOrder();
  const dispatch = useDispatch();

  let navigate = useNavigate();
  /*   All States
   ********************************************* */
  const [loading, setLoading] = useState(false);
  const [customerWO, setCustomerWO] = useState(null);
  const [filterBy, setFilterBy] = useState(null);

  const [groupBy, setGroupBy] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [showProductsModal, setShowProductsModal] = useState(null);
  const [WOId, setWOId] = useState(null);

  /*   All Functions
   ********************************************* */
  const getCustomerWOs = async () => {
    try {
      setLoading(true);
      const { data } = await API.post(
        "customer/work-order",
        {
          id,
        },
        { headers: authHeader() }
      );
      setFilteredData(null);
      setCustomerWO(data);
      setLoading(false);
      return data;
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      setLoading(false);
    }
  };

  // Getting 12 Hours Time from SQL timestamp
  const convertSqlTimestampToDate = (sqlTime) => {
    const timestamp = sqlTime;
    const date = new Date(timestamp);
    const time = date.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    return time;
  };

  const handleGroupBy = (value) => {
    if (!value) {
      setGroupBy(value);
      setFilteredData(null);
      return;
    }
    setGroupBy(value);
  };

  const setWorkOrderContext = async (selectedWorkOrder) => {
    await setWorkOrderData({
      data: selectedWorkOrder,
    });
    let status =
      selectedWorkOrder?.WOStatus === "pending" ||
      selectedWorkOrder?.WOStatus === "processing"
        ? "pending-processing"
        : selectedWorkOrder.WOStatus;
    return navigate(
      `/work-order/details/${status}/${selectedWorkOrder?.id}?page=1`
    );
  };

  useEffect(() => {
    if (groupBy) {
      let groupedData = customerWO.filter((item) => item.WOStatus === groupBy);
      setFilteredData(groupedData);
    }
  }, [groupBy, customerWO]);

  /*   Table Functions
   ********************************************* */

  const buttons = () => (
    <div className=" d-flex justify-content-end ">
      {/* FilterBy */}

      <select
        onChange={(e) => {
          if (customerWO && e.target.value === "all") {
            handleGroupBy(null);
          } else if (customerWO && e.target.value === "pending") {
            handleGroupBy("pending");
          } else if (customerWO && e.target.value === "processing") {
            handleGroupBy("processing");
          } else if (customerWO && e.target.value === "completed") {
            handleGroupBy("completed");
          }
        }}
        defaultValue={"all"}
        className="form-select w-50"
      >
        <option value={"all"}>All</option>
        <option value={"pending"}>Pending</option>
        <option value={"processing"}>Processing</option>
        <option value={"completed"}>Completed</option>
      </select>
      {/* FilterBy */}
      <button
        type="button"
        className="px-1 bg-primary btn text-dark bg-opacity-10 border border-primary rounded-end mx-2 rounded-start"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {filterBy
          ? filterBy.toUpperCase().replaceAll("_", " ")
          : "Filter by All"}
      </button>
      <div className="dropdown-menu">
        <button onClick={() => setFilterBy(null)} className="dropdown-item">
          Filter by All
        </button>
        <button
          onClick={() => setFilterBy("customerName")}
          className="dropdown-item"
        >
          Customer Name
        </button>
        <button
          onClick={() => setFilterBy("jobName")}
          className="dropdown-item"
        >
          Job Name
        </button>
        <button
          onClick={() => setFilterBy("WOStatus")}
          className="dropdown-item"
        >
          WO Status
        </button>
        <button
          onClick={() => setFilterBy("createdBy")}
          className="dropdown-item"
        >
          Created By
        </button>
      </div>
    </div>
  );
  const table_head = [
    "WO ID",
    "Customer Name",
    "Job Name",
    "Created Date",
    "Created By",
    "Status",
    "Actions",
  ];

  const table_body = (item, index) => (
    <tr className="" key={item.id}>
      <td className="py-1">{item.id}</td>
      <td className="py-1">{item.customerName}</td>
      <td className="py-1">{item.jobName}</td>
      <td className="py-1">{convertSqlTimestampToDate(item.createdAt)} </td>
      <td className="py-1">{item.createdBy} </td>
      <td className="py-1">{item.WOStatus}</td>
      <td className="table-action py-1">
        <button
          onClick={() => {
            setShowProductsModal(true);
            setWOId(item?.id);
          }}
          className={`btn btn-sm bg-success text-white`}
        >
          Quick View
        </button>
        <button
          onClick={() => {
            setWorkOrderContext(item);
          }}
          className={`btn btn-sm bg-success text-white mx-2 `}
        >
          Details
        </button>
      </td>
    </tr>
  );

  useEffect(() => {
    getCustomerWOs();
  }, [id]);

  useEffect(() => {
    if (showProductsModal === false) {
      getCustomerWOs();
    }
  }, [showProductsModal]);

  // if (loading) return <Spinner />;

  return (
    <div
      className="py-4 px-3 horizontal-scrollable d-flex flex-grow-1"
      style={{ borderRadius: 10 }}
    >
      <Toast />
      {loading && <Spinner />}
      <Table
        filterBy={filterBy}
        buttons={buttons}
        table_head={table_head}
        table_body={table_body}
        table_data={filteredData ? filteredData : customerWO}
      />
      {loading && <Spinner />}
      <Modal
        size="xl"
        show={showProductsModal}
        onHide={() => setShowProductsModal(false)}
      >
        <Modal.Body>
          {WOId && customerWO && (
            <CustomerWOProduct
              selectedWOId={WOId}
              groupBy={groupBy}
              refreshFunc={getCustomerWOs}
              customerWO={filteredData ? filteredData : customerWO}
              setWorkOrderContext={setWorkOrderContext}
              setShowProductsModal={setShowProductsModal}
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CustomerWorkOrder;
