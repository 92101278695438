import { createSlice } from "@reduxjs/toolkit";
import { getUserDetails, registerUser, userLogin } from "./userActions";

const initialState = {
  loading: false,
  userInfo: null,
  error: null,
  success: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem("user");
      localStorage.removeItem("persist:root");
      window.history.replaceState({}, document.title);
      state.loading = false;
      state.userInfo = null;
      state.error = null;
    },
    resetState: (state) => {
      state.loading = false;
    },
    updateUser: (state, action) => {
      state.userInfo = action.payload;
    },
  },

  extraReducers: {
    // login user
    [userLogin.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = payload;
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    // register user
    [registerUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [registerUser.fulfilled]: (state, { payload }) => {
      state.error = null;
      state.loading = false;
      state.success = true; // registration successful
      state.userInfo = payload; // send user info back
    },
    [registerUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    // get user details
    [getUserDetails.pending]: (state) => {
      state.loading = true;
    },
    [getUserDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = payload;
    },
    [getUserDetails.rejected]: (state, { payload }) => {
      state.loading = false;
    },
  },
});

export const { logout, resetState, updateUser } = userSlice.actions;

export default userSlice.reducer;
