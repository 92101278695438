import React, { useEffect, useState } from "react";
import MProductsList from "../MProductsList/MProductsList";
import MFooter from "../Footer/MFooter";
import axios from "axios";
import Spinner from "components/Spinner";
import ArtImageWIthSignedUrl from "pages/artApproval/Mockups/components/ArtImageWIthSignedUrl";

export default function MCenterButtons({
  selectedImg,
  selectedImgIndex,
  setSelectedImgIndex,
  setSelectedComment,
  selectedProduct,
  selectedDesign,
  setSelectedProduct,
  setSelectedDesign,
  setDesignRequests,
}) {
  /*   All States
   ********************************************* */

  const [loading, setLoading] = useState(false);
  const [selectedProductIndex, setSelectedProductIndex] = useState(0);

  /*   All Functions
   ********************************************* */

  /*   All UseEffects
   ********************************************* */

  useEffect(() => {
    if (selectedDesign?.products) {
      setSelectedProduct(
        (prev) => selectedDesign?.products[selectedProductIndex]
      );
    }
  }, [selectedDesign, selectedProductIndex]);

  return (
    <div className="d-flex flex-column align-items-start">
      {loading && <Spinner />}

      <div className="d-flex  gap-2 align-items-center">
        <div className="btn btn-sm btn-primary">{selectedProduct?.id}</div>
        <div className="me-3">
          <h3>{selectedProduct?.title}</h3>
        </div>
      </div>
      <h3 className="mt-3">Select Product</h3>
      <hr className="mb-2 mt-0 border border-1 w-50 border-secondary" />
      <div className="overflow-scroll w-100" style={{ height: "40dvh" }}>
        {/* <MProductsList
          selectedDesign={selectedDesign}
          setSelectedProduct={setSelectedProduct}
          selectedProduct={selectedProduct}
          setSelectedDesign={setSelectedDesign}
          setDesignRequests={setDesignRequests}
        /> */}
        {selectedDesign?.products?.map((product, index) => (
          <div
            className="mb-2 w-50"
            onClick={() => {
              setSelectedProductIndex((prev) => index);
            }}
            key={index}
          >
            <div
              onClick={() => {
                setSelectedProduct(product);
                setSelectedProductIndex(index);
                // handleClose(true);
              }}
              className={`${
                selectedProduct === product ? "bg-info" : "bg-light"
              } flex-center custom-border-radius custom-shadow-sm p-2 position-relative`}
              style={{ width: "auto", height: 100 }}
            >
              <div className="btn btn-sm btn-primary position-absolute top-0 start-0">
                {product?.id}
              </div>
              <ArtImageWIthSignedUrl
                imgStyle={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                imgClasses={"custom-border-radius-sm"}
                path={product?.images[0].img}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
