import { useEffect, useState } from "react";

import Spinner from "components/Spinner";
import Toast from "components/Toast/ToastComponent";
import { setNotification } from "features/Notification/notificationSlice";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";
import { socket } from "../../socket";
import MDetails from "./MobileLayout/MDetails";
import ArtApprovalDetails from "./Mockups/ArtApprovalDetails";

export default function ArtApprovalLayout({
  selectedDesign,
  setSelectedDesign,
  designRequests,
  setDesignRequests,
  setShowDetailsPage,
  getDesignByToken,
  user,
  setUser,
}) {
  const dispatch = useDispatch();

  /*   All States
   ********************************************* */
  const [activeTab, setActiveTab] = useState("mockups");
  const [loading, setLoading] = useState(false);
  const [questionsList, setQuestionsList] = useState(null);

  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  /*   All Functions
   ********************************************* */
  const getQuestionsList = async () => {
    try {
      loadingOn();
      const { data } = await API.post(
        "/questions",
        { mockupId: selectedDesign?.id },
        {
          headers: authHeader(),
        }
      );

      if (data.type === "success") {
        setQuestionsList(data?.data);
      }

      if (data.type === "error") {
        dispatch(
          setNotification({
            message: data.message,
            type: "error",
          })
        );
      }
      loadingOff();
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      loadingOff();
    }
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    getQuestionsList();
    socket.on("question-new", (data) => {
      console.log("question-new received: " + JSON.stringify(data));
      if (selectedDesign?.id === data.id) {
        getQuestionsList();
      }
    });
  }, [socket]);
  return (
    <div className="">
      {loading && <Spinner />}
      <Toast />

      <div className="d-none d-md-block mt-2">
        {activeTab === "mockups" && (
          <ArtApprovalDetails
            selectedDesign={selectedDesign}
            setSelectedDesign={setSelectedDesign}
            designRequests={designRequests}
            setDesignRequests={setDesignRequests}
            getDesignByToken={getDesignByToken}
            user={user}
            setUser={setUser}
          />
        )}
      </div>
      <div>
        <div className="d-md-none">
          <MDetails
            selectedDesign={selectedDesign}
            setSelectedDesign={setSelectedDesign}
            designRequests={designRequests}
            setDesignRequests={setDesignRequests}
            setShowDetailsPage={setShowDetailsPage}
            user={user}
            setUser={setUser}
            getDesignByToken={getDesignByToken}
          />
        </div>
      </div>
    </div>
  );
}
