import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import logo from "../../../../assets/images/logo.png";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    padding: 20,
  },
  topHeader: {
    height: 75,
  },
  shippingDescription: {
    marginVertical: 0,
  },
  headingMain: { fontSize: 20, fontWeight: "extrabold" },
  headingSecond: { fontSize: 12, fontWeight: "extrabold" },
  headingThird: { fontSize: 15, fontWeight: "extrabold" },
  textbody: { fontSize: 10 },
  mainContainer: {
    marginBottom: "25px",
    flexDirection: "column",
    border: "1px solid grey",
  },
  prodTitle: {
    width: "100%",
    textAlign: "left",
    padding: "5px 10px",

    borderBottom: "1px solid grey",
    fontSize: "14px",
  },
  prodMain: {
    width: "100%",
    flexDirection: "row",
  },
  prodContent: {
    flexGrow: 1,
  },
  prodSizes: {
    padding: "5px",
    width: "150px",
    borderLeft: "1px solid grey",
  },
  prodInfoRow: {
    display: "flex",
    flexDirection: "row",
    fontSize: "10px",
  },
  prodCol: {
    display: "flex",
    flexDirection: "row",
    width: "50%",
  },
  prodKey: {
    padding: "3px 5px",
    width: "40%",
    borderRight: "1px solid grey",
    borderBottom: "1px solid grey",
  },
  prodVal: {
    padding: "3px 5px",
    width: "60%",
    borderBottom: "1px solid grey",
  },
  prodComments: {
    padding: "5px 10px",
    fontSize: "12px",
    borderTop: "1px solid grey",
  },
  prodSubHeading: {
    fontSize: "12px",
  },
  prodTxt: {
    fontSize: "10px",
  },
  borderRight: { borderRight: "1px solid grey" },
  colWithLong: {
    width: "200px",
    marginTop: 5,
    marginBottom: 3,
  },
  colWith: {
    width: "60px",
    marginTop: 5,
    marginBottom: 3,
    marginHorizontal: 5,
  },
  colWithSm: {
    width: "30px",
    marginTop: 5,
    marginBottom: 3,
  },
  colWithXs: {
    width: "10px",
    marginTop: 5,
    marginBottom: 3,
  },
});

// Create Document Component
export const ProductPdf = ({
  productList,
  allProdList,
  selectedWorkOrder,
  onlyProducts,
  locationLogo,
  showInvoicePage,
}) => {
  /*   ALL STATES
   ********************************************* */
  const customerInfo =
    typeof selectedWorkOrder?.contactData === "string"
      ? JSON.parse(selectedWorkOrder?.contactData)
      : selectedWorkOrder?.contactData;
  const billingAddress =
    typeof selectedWorkOrder?.billingAddress === "string"
      ? JSON.parse(selectedWorkOrder?.billingAddress)
      : selectedWorkOrder?.billingAddress;
  const shippingAddress =
    typeof selectedWorkOrder?.shippingAddress === "string"
      ? JSON.parse(selectedWorkOrder?.shippingAddress)
      : selectedWorkOrder?.shippingAddress;

  const [productsList, setProductsList] = useState(JSON.stringify([]));
  const [totalQty, setTotalQty] = useState(0);
  const [priceTotal, setPriceTotal] = useState(0);
  const [salesTax, setSalesTax] = useState(0);

  // Product details
  /*   ALL FUnctions
   ********************************************* */
  const convertSqlTimestampToDate = (sqlTime) => {
    const timestamp = sqlTime;
    const date = new Date(timestamp);
    const time = date.toLocaleString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    return time.replaceAll("/", "-");
  };

  const getProductDesc = (product) => {
    let sizeList = JSON.parse(product?.productSizes);
    let possibleSizes = [
      "xs",
      "axs",
      "yxs",
      "s",
      "as",
      "ys",
      "as",
      "ys",
      "m",
      "am",
      "ym",
      "l",
      "al",
      "yl",
      "xl",
      "axl",
      "yxl",
      "2xl",
      "a2xl",
      "y2xl",
    ];

    let groupedByPrice = {};
    sizeList?.map((size) => {
      let old = groupedByPrice[size.price]
        ? [...groupedByPrice[size.price]]
        : [];
      old.push(size);
      groupedByPrice[size.price] = old;
    });

    let allProd = [];
    Array.from(Object.keys(groupedByPrice))?.map((price) => {
      // const sortedArr = groupedByPrice[price];
      const sortedArr = groupedByPrice[price]?.sort((a, b) => {
        return possibleSizes.indexOf(b.size) - possibleSizes.indexOf(a.size);
      });

      let totalQty = 0;

      sortedArr.map((item) => {
        totalQty += parseInt(item?.quantity);
      });

      let updatedSize =
        sortedArr.length > 1
          ? `${sortedArr[0]?.size?.toUpperCase()}-${sortedArr[
              sortedArr.length - 1
            ]?.size?.toUpperCase()}`
          : sortedArr[0]?.size?.toUpperCase();

      let updatedDescription = `${product?.productDescription} ${updatedSize}`;

      let newItem = {
        size: updatedSize,
        price: parseFloat(sortedArr[0]?.price),
        productDescription: updatedDescription,
        productStyle: product?.productStyle,
        productColor: product?.productColor,
        productType: product?.productType,
        quantity: totalQty,
        id: product.id,
        sortId: sortedArr[0].sortId,
      };
      allProd.push(newItem);
    });

    const sortedProds = allProd?.sort((a, b) => {
      return a.sortId - b.sortId;
    });

    return sortedProds;
  };

  const PageHeader = () => {
    return (
      <>
        <View style={{ ...styles.topHeader }}>
          {/*   Top Header Section
           ********************************************* */}
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <View style={{}}>
              {locationLogo ? (
                <Image
                  src={locationLogo}
                  style={{
                    width: 140,
                    height: 60,
                  }}
                />
              ) : (
                <Image
                  src={logo}
                  style={{
                    width: 140,
                    height: 60,
                  }}
                />
              )}
            </View>
            <View
              style={{
                flexGrow: 1,
                alignItems: "flex-end",
              }}
            >
              <Text style={[styles.headingMain, { marginBottom: 2 }]}>
                WO : {selectedWorkOrder?.id}
              </Text>
              <View
                style={{
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <Text style={[styles.headingSecond]}>
                  WO Date: &nbsp;&nbsp;&nbsp;
                  {convertSqlTimestampToDate(selectedWorkOrder?.WODate)}
                </Text>
                <Text style={[styles.headingSecond]}>
                  Print Date:&nbsp;&nbsp;&nbsp;
                  {convertSqlTimestampToDate(Date.now())}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.shippingDescription}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View style={{ flexGrow: 1 }}>
              <Text style={styles.headingSecond}>Ordered By:</Text>
              <View
                style={{
                  border: 1,
                  borderRight: 0,
                  paddingVertical: 2,
                  paddingHorizontal: 5,
                }}
              >
                <Text style={styles.textbody}>
                  {customerInfo?.firstName} {customerInfo?.lastName}
                </Text>
              </View>
            </View>
            <View style={{ flexGrow: 1 }}>
              <Text style={styles.headingSecond}>Phone:</Text>
              <View
                style={{
                  border: 1,
                  borderRight: 0,
                  paddingVertical: 2,
                  paddingHorizontal: 5,
                }}
              >
                <Text style={styles.textbody}>
                  {customerInfo?.phone ? customerInfo?.phone : " "}
                </Text>
              </View>
            </View>
            <View style={{ flexGrow: 2 }}>
              <Text style={styles.headingSecond}>Email:</Text>
              <View
                style={{ border: 1, paddingVertical: 2, paddingHorizontal: 5 }}
              >
                <Text style={styles.textbody}>
                  {customerInfo?.email ? customerInfo?.email : " "}
                </Text>
              </View>
            </View>
          </View>

          {/* ----------- billing SHipping Address ---------------- */}
          <View style={{ flexDirection: "row", marginVertical: 5 }}>
            {/* ---------- Billing Area ---------- */}
            <View style={{ flexGrow: 1 }}>
              <View
                style={{
                  flexGrow: 1,
                  paddingVertical: 2,
                  paddingHorizontal: 5,
                }}
              >
                <Text style={styles.headingSecond}>Bill To:</Text>
                <Text style={styles.headingSecond}>
                  {billingAddress?.addressCompany}
                </Text>
                <Text style={styles.headingSecond}>
                  {billingAddress?.addressLine1}
                </Text>
                <Text style={styles.headingSecond}>
                  {billingAddress?.addressLine2}
                </Text>
                <Text style={styles.headingSecond}>
                  {billingAddress?.city}&nbsp;{billingAddress?.state}&nbsp;
                  {billingAddress?.zip}
                  &nbsp;
                </Text>
                <Text style={styles.headingSecond}>
                  {billingAddress?.country}
                </Text>
              </View>
            </View>
            {/* ---------- Shipping Area ---------- */}
            <View style={{ flexGrow: 1 }}>
              <View
                style={{
                  flexGrow: 1,
                  paddingVertical: 2,
                  paddingHorizontal: 5,
                }}
              >
                <Text style={styles.headingSecond}>Ship To:</Text>
                <Text style={styles.headingSecond}>
                  {shippingAddress?.addressCompany}
                </Text>
                <Text style={styles.headingSecond}>
                  {shippingAddress?.addressLine1}
                </Text>
                <Text style={styles.headingSecond}>
                  {shippingAddress?.addressLine2}
                </Text>
                <Text style={styles.headingSecond}>
                  {shippingAddress?.city}&nbsp;{shippingAddress?.state}
                  &nbsp;
                  {shippingAddress?.zip}&nbsp;
                </Text>
                <Text style={styles.headingSecond}>
                  {shippingAddress?.country}
                </Text>
              </View>
            </View>
          </View>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View style={{ flexGrow: 1 }}>
              <Text style={styles.headingSecond}>Customer #:</Text>
              <View
                style={{
                  border: 1,
                  borderRight: 0,
                  paddingVertical: 2,
                  paddingHorizontal: 5,
                }}
              >
                <Text style={styles.textbody}>
                  {selectedWorkOrder?.customerId}
                </Text>
              </View>
            </View>

            <View style={{ flexGrow: 1 }}>
              <Text style={styles.headingSecond}>Term:</Text>
              <View
                style={{
                  border: 1,
                  borderRight: 0,
                  paddingVertical: 2,
                  paddingHorizontal: 5,
                }}
              >
                <Text style={styles.textbody}>
                  {selectedWorkOrder?.paymentTerm?.name
                    ? selectedWorkOrder?.paymentTerm?.name
                    : " "}
                </Text>
              </View>
            </View>
            <View style={{ flexGrow: 1 }}>
              <Text style={styles.headingSecond}>Sales Person:</Text>
              <View
                style={{
                  border: 1,
                  borderRight: 1,
                  paddingVertical: 2,
                  paddingHorizontal: 5,
                }}
              >
                <Text style={styles.textbody}>
                  {selectedWorkOrder?.salesPerson?.name
                    ? selectedWorkOrder?.salesPerson?.name
                    : " "}
                </Text>
              </View>
            </View>
            <View style={{ flexGrow: 1 }}>
              <Text style={styles.headingSecond}>Shipment Method:</Text>
              <View
                style={{
                  border: 1,
                  borderLeft: 0,
                  paddingVertical: 2,
                  paddingHorizontal: 5,
                }}
              >
                <Text style={styles.textbody}>
                  {selectedWorkOrder?.shippingMethod?.name
                    ? selectedWorkOrder?.shippingMethod?.name
                    : " "}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </>
    );
  };

  const PageHeaderWithoutAddress = ({ productCategory }) => {
    const [productDetails, setProductDetails] = useState(null);

    useEffect(() => {
      let uniqueGroupedproductStyle = productCategory.reduce((acc, item) => {
        if (acc[item.productStyle]) {
          acc[item.productStyle] += parseInt(item.totalQuantity);
        } else {
          acc[item.productStyle] = item?.totalQuantity;
        }
        return acc;
      }, {});

      let productDetail = "";
      for (const [productStyle, totalQuantity] of Object.entries(
        uniqueGroupedproductStyle
      )) {
        productDetail += `${productStyle} = ${totalQuantity}, `;
      }

      setProductDetails(productDetail);
    }, [productCategory]);
    return (
      <>
        <View style={{ ...styles.topHeader, marginBottom: 10 }}>
          {/*   Top Header Section
           ********************************************* */}
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <View style={{}}>
              {locationLogo ? (
                <Image
                  src={locationLogo}
                  style={{
                    width: 140,
                    height: 60,
                  }}
                />
              ) : (
                <Image
                  src={logo}
                  style={{
                    width: 140,
                    height: 60,
                  }}
                />
              )}
            </View>
            <View
              style={{
                flexGrow: 1,
                alignItems: "flex-end",
              }}
            >
              <Text style={[styles.headingMain, { marginBottom: 2 }]}>
                WO: {selectedWorkOrder?.id}
              </Text>
              <View
                style={{
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <Text style={[styles.headingSecond]}>
                  WO Date: &nbsp;&nbsp;&nbsp;
                  {convertSqlTimestampToDate(selectedWorkOrder?.WODate)}
                </Text>
                <Text style={[styles.headingSecond]}>
                  Print Date:&nbsp;&nbsp;&nbsp;
                  {convertSqlTimestampToDate(Date.now())}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.shippingDescription}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View style={{ flexGrow: 1 }}>
              <Text style={styles.headingSecond}>Ordered By:</Text>
              <View
                style={{
                  border: 1,
                  borderRight: 0,
                  paddingVertical: 2,
                  paddingHorizontal: 5,
                }}
              >
                <Text style={styles.textbody}>
                  {customerInfo?.firstName} {customerInfo?.lastName}
                </Text>
              </View>
            </View>
            <View style={{ flexGrow: 1 }}>
              <Text style={styles.headingSecond}>Phone:</Text>
              <View
                style={{
                  border: 1,
                  borderRight: 0,
                  paddingVertical: 2,
                  paddingHorizontal: 5,
                }}
              >
                <Text style={styles.textbody}>
                  {customerInfo?.phone ? customerInfo?.phone : " "}
                </Text>
              </View>
            </View>
            <View style={{ flexGrow: 2 }}>
              <Text style={styles.headingSecond}>Email:</Text>
              <View
                style={{ border: 1, paddingVertical: 2, paddingHorizontal: 5 }}
              >
                <Text style={styles.textbody}>
                  {customerInfo?.email ? customerInfo?.email : " "}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={{ ...styles.shippingDescription, marginTop: 5 }}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text
              style={{
                ...styles.headingSecond,
                textAlign: "left",
              }}
            >
              Job Name:{" "}
              <Text style={{ textDecoration: "underline" }}>
                {selectedWorkOrder?.jobName}
              </Text>
            </Text>
          </View>
        </View>

        <View style={{ ...styles.shippingDescription }}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text
              style={{
                ...styles.headingSecond,
                textAlign: "left",
              }}
            >
              Customer Name:{" "}
              <Text style={{ textDecoration: "underline" }}>
                {selectedWorkOrder?.customerName}
              </Text>
            </Text>
          </View>
        </View>

        <View style={{ ...styles.shippingDescription }}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text
              style={{
                ...styles.headingSecond,
                textAlign: "left",
              }}
            >
              Shipping Address:
              {shippingAddress && (
                <Text style={{ textDecoration: "underline" }}>
                  {shippingAddress?.addressCompany &&
                    shippingAddress?.addressCompany + ", "}
                  {shippingAddress?.addressLine1 &&
                    shippingAddress?.addressLine1 + ", "}
                  {shippingAddress?.addressLine2 &&
                    shippingAddress?.addressLine2 + ", "}
                  {shippingAddress?.city && shippingAddress?.city + ", "}
                  {shippingAddress?.state && shippingAddress?.state + ", "}
                  {shippingAddress?.zip && shippingAddress?.zip + ", "}
                  {shippingAddress?.country && shippingAddress?.country + ", "}
                </Text>
              )}
            </Text>
          </View>
        </View>
        <View style={{ ...styles.shippingDescription, marginTop: 10 }}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Text
              style={{
                ...styles.headingSecond,
                textAlign: "left",
              }}
            >
              Product Details: ( Quantity: {productCategory.length}){" "}
              {productDetails}
            </Text>
          </View>
        </View>
      </>
    );
  };

  let limit = 2;
  const getTotalPages = (list) => {
    let totalItems = list.length === null ? 0 : list.length - 1;
    if (totalItems === null) {
      return 0;
    }

    let newLimit = list.length === limit ? 2 : limit;

    let itemsPerPage = Math.ceil(list.length / newLimit);
    return itemsPerPage;
  };

  useEffect(() => {
    if (productList) {
      let updatedProductList = [];
      let products = allProdList;
      products?.map((item) => {
        updatedProductList = [...updatedProductList, ...getProductDesc(item)];
      });
      let TotalQty = updatedProductList?.reduce((prev, curr) => {
        let price = parseFloat(
          (parseFloat(curr?.quantity) * parseFloat(curr?.price) * 100) / 100
        ).toFixed(2);
        let total = parseFloat(prev) + parseFloat(price);

        return total;
      }, 0);

      let taxRate = selectedWorkOrder?.taxrate?.rate
        ? selectedWorkOrder?.taxrate?.rate
        : 0;

      setSalesTax(((parseFloat(taxRate * TotalQty) * 100) / 100).toFixed(2));

      let total = TotalQty * taxRate + TotalQty;
      let parsedTotal = ((parseFloat(total) * 100) / 100).toFixed(2);

      setPriceTotal(parsedTotal);
      setTotalQty(TotalQty);
      setProductsList(JSON.stringify(updatedProductList));

      // Product Details
    }
  }, [productList, selectedWorkOrder]);

  return (
    <>
      <Document title={selectedWorkOrder?.id}>
        {showInvoicePage && (
          <Page size="A4" style={styles.page}>
            <PageHeader />

            <View style={{ marginTop: "10px" }}>
              {/*   Table Head
               ********************************************* */}
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                  borderBottom: "2px solid gray",

                  paddingHorizontal: 10,
                }}
              >
                <View style={styles.colWithXs}>
                  <Text style={styles.textbody}>Ln#</Text>
                </View>
                <View style={styles.colWith}>
                  <Text style={styles.textbody}>Product Code</Text>
                </View>
                <View style={styles.colWith}>
                  <Text style={styles.textbody}>Color</Text>
                </View>
                <View style={styles.colWithLong}>
                  <Text style={styles.textbody}>Description</Text>
                </View>

                <View style={styles.colWithSm}>
                  <Text style={styles.textbody}>Qty</Text>
                </View>
                <View style={styles.colWithSm}>
                  <Text style={styles.textbody}>Unit Price</Text>
                </View>
                <View style={styles.colWithSm}>
                  <Text style={styles.textbody}>Total Price</Text>
                </View>
              </View>
              {/*   Table Body
               ********************************************* */}
              {productsList &&
                typeof productsList === "string" &&
                JSON.parse(productsList).map((item, index) => (
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom: "1px solid gray",

                      paddingHorizontal: 10,
                    }}
                    key={item?.id}
                  >
                    <View style={styles.colWithXs}>
                      <Text style={styles.textbody}>{index + 1}</Text>
                    </View>

                    <View style={styles.colWith}>
                      <Text style={[styles.textbody, { textAlign: "left" }]}>
                        {item?.productStyle}
                      </Text>
                    </View>
                    <View style={styles.colWith}>
                      <Text style={[styles.textbody, { textAlign: "left" }]}>
                        {item?.productColor}
                      </Text>
                    </View>
                    <View style={styles.colWithLong}>
                      <Text style={styles.textbody}>
                        {item?.productDescription}
                      </Text>
                    </View>

                    <View style={styles.colWithSm}>
                      <Text style={styles.textbody}>{item?.quantity}</Text>
                    </View>
                    <View style={styles.colWithSm}>
                      <Text style={styles.textbody}>
                        {item?.price.toFixed(2)}
                      </Text>
                    </View>
                    <View style={styles.colWithSm}>
                      <Text style={[styles.textbody, { textAlign: "right" }]}>
                        {parseFloat(
                          (parseFloat(item?.quantity) *
                            parseFloat(item?.price) *
                            100) /
                            100
                        ).toFixed(2)}
                      </Text>
                    </View>
                  </View>
                ))}
            </View>
            <View style={{ flexDirection: "row" }}>
              <View style={{ flex: 1, marginVertical: 30 }}>
                <Text style={styles.headingThird}>Note:</Text>
              </View>
              <View
                style={{
                  width: "30%",
                  marginVertical: 30,
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <View
                  style={{ flexDirection: "row", gap: 3, alignItems: "center" }}
                >
                  <View>
                    <Text style={styles.headingSecond}>Subtotal</Text>
                  </View>
                  <View
                    style={{
                      border: 1.5,
                      borderBottom: 0,

                      marginLeft: 5,
                      height: 15,
                      width: "60%",
                    }}
                  >
                    <Text
                      style={[styles.headingSecond, { textAlign: "right" }]}
                    >
                      {((parseFloat(totalQty) * 100) / 100).toFixed(2)}
                    </Text>
                  </View>
                </View>
                <View
                  style={{ flexDirection: "row", gap: 3, alignItems: "center" }}
                >
                  <View>
                    <Text
                      style={[styles.headingSecond, { textAlign: "right" }]}
                    >
                      Sales Tax
                    </Text>
                  </View>
                  <View
                    style={{
                      border: 1.5,
                      borderBottom: 0,

                      marginLeft: 5,
                      height: 15,
                      width: "60%",
                    }}
                  >
                    <Text
                      style={[styles.headingSecond, { textAlign: "right" }]}
                    >
                      {salesTax}
                    </Text>
                  </View>
                </View>
                <View
                  style={{ flexDirection: "row", gap: 3, alignItems: "center" }}
                >
                  <View>
                    <Text
                      style={[styles.headingSecond, { textAlign: "right" }]}
                    >
                      Shipping
                    </Text>
                  </View>
                  <View
                    style={{
                      border: 1.5,
                      borderBottom: 0,

                      marginLeft: 5,
                      height: 15,
                      width: "60%",
                    }}
                  >
                    <Text
                      style={[styles.headingSecond, { textAlign: "right" }]}
                    ></Text>
                  </View>
                </View>
                <View
                  style={{ flexDirection: "row", gap: 3, alignItems: "center" }}
                >
                  <View>
                    <Text
                      style={[styles.headingSecond, { textAlign: "right" }]}
                    >
                      Total
                    </Text>
                  </View>
                  <View
                    style={{
                      border: 1.5,
                      marginLeft: 5,
                      height: 18,
                      width: "60%",
                    }}
                  >
                    <Text
                      style={[styles.headingSecond, { textAlign: "right" }]}
                    >
                      {priceTotal}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </Page>
        )}
        {/*   2nd Page
         ********************************************* */}
        {productList &&
          Object.keys(productList)?.map((key) => {
            let currentProductList = productList[key];

            // Initializing Product Style 0
            // It will be incremented every time item is rendered
            // in currentProductList
            // It will be reset to 0 for new category
            let productStyle = 0;

            return (
              currentProductList &&
              Array.from(
                { length: getTotalPages(currentProductList) },
                (_, pagesIndex) => {
                  // Page Can have 2 items with header.
                  // Page Can have limit=3 items without header

                  // Checking if totalItems are greater than 2
                  // if less than 2 than do nothing

                  // if greater than 2 than splice them on every iteration
                  // so that we can have 2 items on page with header and
                  // limit= 3 no of items in page without header.

                  // let newLimit =
                  //   currentProductList?.length === limit ? 2 : limit;

                  let sliceStart = pagesIndex * limit;
                  let sliceEnd = (pagesIndex + 1) * limit;

                  let itemsInPage =
                    currentProductList?.length > 2
                      ? currentProductList.slice(sliceStart, sliceEnd)
                      : currentProductList;

                  return (
                    <Page key={key} size="A4" style={styles.page}>
                      {!onlyProducts && pagesIndex === 0 && (
                        <PageHeaderWithoutAddress
                          productCategory={currentProductList}
                        />
                      )}
                      <Text style={{ ...styles.headingSecond, marginTop: 5 }}>
                        Order Product Details:
                      </Text>

                      {/*   ALL Product Details
                       ********************************************* */}
                      <View style={styles.productDetails}>
                        {/* ----------  Looping Products   ---------- */}
                        {itemsInPage
                          ? itemsInPage?.map((item) => {
                              // Increment By 1 on every new item
                              productStyle += 1;
                              return (
                                <View
                                  style={styles.mainContainer}
                                  key={item?.id}
                                >
                                  <View style={styles.prodMain}>
                                    <Text
                                      style={{
                                        ...styles.prodTitle,
                                        width: "30%",
                                        fontSize: "12px",
                                        borderRight: "1px solid #000",
                                      }}
                                    >
                                      {item?.productType}
                                    </Text>
                                    <Text style={styles.prodTitle}>
                                      {productStyle}: {item?.productDescription}
                                    </Text>
                                  </View>
                                  <View style={styles.prodMain}>
                                    <View style={styles.prodContent}>
                                      <View style={styles.prodInfoRow}>
                                        <View
                                          style={[
                                            styles.prodCol,
                                            styles.borderRight,
                                          ]}
                                        >
                                          <Text style={styles.prodKey}>
                                            Style Number:
                                          </Text>
                                          <Text style={styles.prodVal}>
                                            {item?.productStyle}
                                          </Text>
                                        </View>
                                        <View style={styles.prodCol}>
                                          <Text style={styles.prodKey}>
                                            Fabrics:
                                          </Text>
                                          <Text style={styles.prodVal}>
                                            {item?.productFabric}
                                          </Text>
                                        </View>
                                      </View>
                                      <View style={styles.prodInfoRow}>
                                        <View
                                          style={[
                                            styles.prodCol,
                                            styles.borderRight,
                                          ]}
                                        >
                                          <Text style={styles.prodKey}>
                                            Color:
                                          </Text>
                                          <Text style={styles.prodVal}>
                                            {item?.productColor}
                                          </Text>
                                        </View>
                                        <View style={styles.prodCol}>
                                          <Text style={styles.prodKey}>
                                            Qty:
                                          </Text>
                                          <Text style={styles.prodVal}>
                                            {item?.totalQuantity}
                                          </Text>
                                        </View>
                                      </View>
                                      <View
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        {item?.productImage && (
                                          <Image
                                            style={{ width: 300, height: 160 }}
                                            src={item?.productImage}
                                          />
                                        )}
                                      </View>
                                    </View>

                                    <View style={styles.prodSizes}>
                                      <Text style={styles.prodSubHeading}>
                                        Size Details
                                      </Text>
                                      {item?.productSizes &&
                                        JSON.parse(item?.productSizes).map(
                                          (d) => (
                                            <Text
                                              key={d.id}
                                              style={styles.prodTxt}
                                            >
                                              {d?.size} = {d?.quantity}
                                            </Text>
                                          )
                                        )}
                                    </View>
                                  </View>
                                  <Text style={styles.prodComments}>
                                    COMMENTS:{" "}
                                    {item?.notes?.replace(/<[^>]+>/g, "")}
                                  </Text>
                                </View>
                              );
                            })
                          : itemsInPage.map((item) => (
                              <View
                                key={item?.id}
                                style={{
                                  padding: 5,
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  borderBottom: 1,
                                }}
                              >
                                <View
                                  style={{
                                    width: "15%",
                                    backgroundColor: "red",
                                  }}
                                >
                                  <Text style={styles.headingSecond}>
                                    {item?.product_number}
                                  </Text>
                                </View>
                                <View style={{ width: "15%" }}>
                                  <Text style={styles.headingSecond}>
                                    {item?.product_color}
                                  </Text>
                                </View>
                                <View style={{ width: "40%" }}>
                                  <Text
                                    style={[
                                      styles.headingSecond,
                                      { flexWrap: "wrap", marginRight: 5 },
                                    ]}
                                  >
                                    {item?.product_description}
                                  </Text>
                                </View>
                                <View style={{ width: "5%" }}>
                                  <Text style={styles.headingSecond}>
                                    {item?.total_quantity}
                                  </Text>
                                </View>
                                <View style={{ width: "10%" }}>
                                  <Text
                                    style={[
                                      styles.headingSecond,
                                      { alignSelf: "flex-end" },
                                    ]}
                                  >
                                    ---
                                  </Text>
                                </View>
                                <View style={{ width: "15%" }}>
                                  <Text
                                    style={[
                                      styles.headingSecond,
                                      { alignSelf: "flex-end" },
                                    ]}
                                  >
                                    {item?.total_price}
                                  </Text>
                                </View>
                              </View>
                            ))}
                      </View>
                    </Page>
                  );
                }
              )
            );
          })}
      </Document>
    </>
  );
};
