import Table from "components/DataTable/Table";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import authHeader from "services/auth-header";
import Modal from "react-bootstrap/Modal";
import API from "services/axios";
import { useDispatch } from "react-redux";
import axios from "axios";
import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";

export default function BidFileManager({ selectedBid }) {
  const user = useSelector((state) => state.user?.userInfo);
  const dispatch = useDispatch();

  /*   ALL STATES
   ********************************************* */
  const [filterBy, setFilterBy] = useState(null);
  const [bidFiles, setBidFiles] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteID, setDeleteID] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const [files, setFiles] = useState(null);
  const [uploadedIDList, setUploadedIDList] = useState([]);
  const [lastSortId, setLastSortId] = useState(null);

  const [newFileName, setNewFileName] = useState("");
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(false);

  const handleFileChange = (event) => {
    let allFiles = [];
    Array.from(event.target.files).map((i, index) => {
      let item = { id: index, file: event.target.files[index] };
      allFiles.push(item);
    });

    setFiles(allFiles);
  };

  /*   All Functions
   ********************************************* */
  //get Data
  const getBidFiles = async () => {
    try {
      setLoading(true);
      const { data } = await API.post(
        "bid-file/listfiles",
        {
          id: selectedBid?.id,
        },
        { headers: authHeader() }
      );

      setBidFiles(data);
      setLoading(false);
      setLastSortId(
        data[data.length - 1]?.sortId ? data[data.length - 1]?.sortId : 0
      );
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      setLoading(false);
    }
  };

  const handleRenameFile = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const fileName = getFileName(selectedFile?.fileName);
      const length = selectedFile?.fileName.indexOf(fileName);

      const fileExt =
        "." + fileName.split(".")[(fileName?.split(".")).length - 1];

      let filePath = selectedFile?.fileName.slice(0, length);
      let oldFileName = selectedFile?.fileName;
      let updatedFileName = filePath + newFileName + fileExt;

      const { data } = await API.post(
        "/bid-file/rename",
        {
          oldFileName,
          newFileName: updatedFileName,
          id: selectedFile?.id,
        },
        { headers: authHeader() }
      );
      getBidFiles();
      setLoading(true);
      setShowRenameModal(false);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      setLoading(false);
    }
  };

  const uploadFiles = async (file, sortID) => {
    const formData = new FormData();
    formData.append("file", file?.file);
    formData.append("folder", `kms/bid/${selectedBid?.id}/attachments`);
    formData.append("sortId", sortID + 1);
    formData.append("uploadedBy", user?.id);
    formData.append("companyId", user?.companyId);
    formData.append("bidId", selectedBid?.id);

    if (file?.file == null) {
      dispatch(
        setNotification({
          message: "No file selected for upload.",
          type: "error",
        })
      );
      return;
    }

    await API.post("/bid-file/upload", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    setUploadedIDList((prev) => prev?.concat(file?.id));
  };

  // Adds Button Function
  const handleUploadFiles = async (e) => {
    e.preventDefault();
    setLoading(true);

    const uploadPromises = files.map((file, index) =>
      uploadFiles(file, lastSortId + index)
    );

    try {
      // Wait for all file uploads to complete
      setLoading(true);
      await Promise.all(uploadPromises);
    } catch (error) {
      dispatch(
        setNotification({
          message: error.message,
          type: "error",
        })
      );
      setLoading(false);
    }
    getBidFiles();
    setFiles(null);
    setUploadedIDList([]);
    setLastSortId(null);
    setLoading(false);
    setShowAddModal(false);
  };

  const downloadFile = (fileKey) => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URI}/aws/images`, {
        key: fileKey,
      })
      .then((response) => {
        const url = response.data[0];
        setLoading(false);
        const link = document.createElement("a");
        link.href = url;
        link.download = fileKey;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })

      .catch((error) => {
        console.log("Error downloading file:", error);
      });
  };

  const deleteFile = async ({ id, file }) => {
    try {
      setLoading(true);
      const { data } = await API.post(
        "/bid-file/delete-file",
        {
          file: file,
          id: id,
        },
        { headers: authHeader() }
      );
      dispatch(
        setNotification({
          message: data,
          type: "success",
        })
      );
      getBidFiles();
      setLoading(true);
      setShowDeleteModal();
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      setLoading(false);
    }
  };

  const getFileName = (fileName) => {
    return fileName?.split("/")[fileName?.split("/").length - 1];
  };

  // Sorting Up
  const sortUp = async (index) => {
    const currentSortId = bidFiles[index].sortId;
    const currentId = bidFiles[index].id;
    const upSortId = bidFiles[index - 1].sortId;
    const upId = bidFiles[index - 1].id;
    try {
      setLoading(true);
      const { data } = await API.post(
        "/bid-file/sortup",
        { currentSortId, currentId, upSortId, upId },
        { headers: authHeader() }
      );
      if (data.success) {
        getBidFiles();
        setLoading(false);
      }
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      setLoading(false);
    }
  };

  // Sorting Up
  const sortDown = async (index) => {
    const currentSortId = bidFiles[index].sortId;
    const currentId = bidFiles[index].id;
    const downSortId = bidFiles[index + 1].sortId;
    const downId = bidFiles[index + 1].id;
    try {
      setLoading(true);
      const { data } = await API.post(
        "/bid-file/sortDown",
        { currentSortId, currentId, downSortId, downId },
        { headers: authHeader() }
      );
      if (data.success) {
        getBidFiles();
      }
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      setLoading(false);
    }
  };

  // Getting 12 Hours Time from SQL timestamp
  const convertSqlTimestampToDate = (sqlTime) => {
    const timestamp = sqlTime;
    const date = new Date(timestamp);
    const time = date.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    return time;
  };

  useEffect(() => {
    setFiles((prev) =>
      prev?.filter((item) => uploadedIDList.includes(item.id))
    );
  }, [uploadedIDList]);

  useEffect(() => {
    getBidFiles();
  }, [selectedBid]);

  useEffect(() => {
    let fileNameWithExt = getFileName(selectedFile?.fileName);
    setNewFileName(fileNameWithExt?.slice(0, fileNameWithExt.length - 4));
  }, [selectedFile]);

  /*   Table functions
   ********************************************* */
  const btn1 = () => (
    <div>
      <>
        <button
          onClick={() => setShowAddModal(true)}
          type="button"
          className="btn btn-primary  ms-1"
        >
          ADD FILES
        </button>
      </>
    </div>
  );

  const buttons = () => (
    <div className=" d-flex justify-content-end ">
      {/* FilterBy */}
      <button
        type="button"
        className="px-1 bg-primary btn text-dark bg-opacity-10 border border-primary rounded-end mx-2 rounded-start"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {filterBy
          ? filterBy.toUpperCase().replaceAll("_", " ")
          : "Filter by All"}
      </button>
      <div className="dropdown-menu">
        <button onClick={() => setFilterBy(null)} className="dropdown-item">
          Filter by All
        </button>
      </div>
    </div>
  );
  const table_head = ["File Name", "Last Updated", "Uploaded By", "Actions"];

  const table_body = (item, index) => (
    <tr key={item?.id}>
      <td className="py-1 align-middle">{getFileName(item?.fileName)}</td>
      <td className="py-1 align-middle">
        {convertSqlTimestampToDate(item.updatedAt)}
      </td>
      <td className="py-1 align-middle">{item.uploadedBy}</td>
      <td className="py-1 align-middle">
        {user?.roles === "admin" && (
          <Link
            onClick={() => {
              item.sortId != 1 && sortUp(index);
            }}
            className="action-icon"
          >
            <i className="mdi mdi-chevron-double-up"></i>
          </Link>
        )}
        {user?.roles === "admin" && (
          <div
            role="button"
            onClick={() => {
              item.sortId != bidFiles?.length && sortDown(index);
            }}
            className="action-icon"
          >
            <i className="mdi mdi-chevron-double-down"></i>
          </div>
        )}
        <Link
          onClick={async () => {
            let fileNameWithExt = await getFileName(selectedFile?.fileName);
            setNewFileName(
              fileNameWithExt?.slice(0, fileNameWithExt.length - 4)
            );
            setSelectedFile(item);

            setShowRenameModal(true);
          }}
          className=" btn btn-success btn-sm"
        >
          RN
        </Link>
        <button
          className="btn btn-success btn-sm px-1 mx-1"
          onClick={() => downloadFile(item.fileName)}
        >
          Download
        </button>
        {(user?.permissions?.includes("work_orders_delete") ||
          user?.roles === "admin") && (
          <Link
            onClick={() => {
              setShowDeleteModal(true);
              setDeleteID({ id: item?.id, file: item.fileName });
            }}
            className="action-icon"
          >
            <i className="mdi mdi-delete"></i>
          </Link>
        )}
      </td>
    </tr>
  );
  return (
    <div className="flex-grow-1 py-4">
      {/*   Table
       ********************************************* */}
      {!loading ? (
        <div
          className="px-2 flex-grow-1 d-flex "
          style={{ minHeight: "50vh", borderRadius: 15 }}
        >
          <div className="horizontal-scrollable d-flex flex-grow-1">
            {bidFiles && (
              <Table
                filterBy={filterBy}
                buttons={buttons}
                btn1={btn1}
                table_head={table_head}
                table_body={table_body}
                table_data={bidFiles}
              />
            )}
          </div>
        </div>
      ) : (
        <Spinner />
      )}
      {/*   ADD Modal
       ********************************************* */}
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Body
          className="shadow-lg bg-white px-3 "
          style={{ borderRadius: 15 }}
        >
          <div>
            <h4>ADD FILES</h4>
          </div>
          <hr className="mt-0" />
          <form onSubmit={handleUploadFiles}>
            <div className="row mb-1">
              <div className="col-12">
                <input
                  type="file"
                  multiple
                  onChange={handleFileChange}
                  className="form-control"
                  placeholder="Enter id"
                />
              </div>
            </div>

            <div className="py-2">
              {files &&
                files.map((file, index) => (
                  <div
                    key={file.id}
                    className="bg-white px-2 rounded mb-2 d-flex justify-content-between align-items-center "
                    style={{
                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                    }}
                  >
                    <div>{file.file.name}</div>
                    <i
                      onClick={() => {
                        setFiles((prev) =>
                          prev?.filter((item) => file.id != item.id)
                        );
                      }}
                      className="ri-close-circle-fill text-danger fs-3"
                    ></i>
                  </div>
                ))}
            </div>

            {/*   buttons
             ********************************************* */}
            <div className="my-2">
              <div className="d-flex justify-content-end">
                <button
                  onClick={() => {
                    setShowAddModal(false);
                    setFiles(null);
                    setUploadedIDList([]);
                    setLastSortId(null);
                    setLoading(false);
                  }}
                  className="btn btn-secondary btn-sm"
                  type="button"
                >
                  Close
                </button>
                <button
                  disabled={loading}
                  className="btn btn-primary btn-sm ms-1"
                  type="submit"
                >
                  ADD
                </button>
              </div>
            </div>
          </form>
          {loading && <Spinner />}
        </Modal.Body>
      </Modal>
      {/*   Delete Modal
       ********************************************* */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Body className="bg-danger">
          <div>
            <div className="text-center text-white">
              <i className="ri-close-circle-line h1"></i>
              <h4 className="mt-2">Confirm Delete!</h4>
              <p className="mt-3">Do You want to delete ?</p>
              <button
                type="button"
                onClick={() => setShowDeleteModal(false)}
                className="btn btn-light my-2 mx-2"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-outline-light my-2 "
                data-bs-dismiss="modal"
                onClick={() => {
                  deleteFile(deleteID);
                }}
              >
                DELETE
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/*   Rename Modal
       ********************************************* */}
      <Modal
        show={showRenameModal}
        contentClassName="bg-transparent"
        onHide={() => setShowRenameModal(false)}
      >
        <Modal.Body className="bg-white custom-border-radius">
          <div className="px-2">
            <h4>Rename File</h4>
            <h5 className="my-2"> {getFileName(selectedFile?.fileName)} </h5>
            <input
              onChange={(e) => {
                setNewFileName(e.target.value);
              }}
              value={newFileName}
              placeholder="Enter new filename"
              className="form-control"
            />
            {/*   buttons
             ********************************************* */}
            <div className="my-2">
              <div className="d-flex justify-content-end">
                <button
                  onClick={() => {
                    setShowRenameModal(false);
                  }}
                  className="btn btn-secondary btn-sm"
                  type="button"
                >
                  Close
                </button>
                <button
                  onClick={handleRenameFile}
                  className="btn btn-primary btn-sm ms-1"
                  type="button"
                >
                  Rename
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
