import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import authHeader from 'services/auth-header';
import API from 'services/axios';

function SalesPersonsAdd({ showAddPage, refreshFunc, lastSortId }) {
  /*   All Satates Below
   ********************************************* */
  const { register, handleSubmit } = useForm();
  const [formError, setFormError] = useState({});
  // redux

  /*   All Functions Below
   ********************************************* */
  const addSalesPersonsData = async (formData, e) => {
    setFormError({});
    e.preventDefault();
    if (formData.name === '') {
      setFormError({
        name: { type: 'name', message: 'Name is required' },
      });
      return;
    }

    try {
      const { data } = await API.post(
        '/salesPersons',
        { data: { ...formData, sort_id: lastSortId + 1 } },
        { headers: authHeader() }
      );
      if (data.message.type === 'success') {
        refreshFunc();
        showAddPage(false);
        return;
      }
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <div className='row'>
      <div className='col-12'>
        {/*    ADD FORM START
         ********************************************* */}
        <div className='mb-13 ms-3'>
          <h3>ADD Sales Person</h3>
        </div>
        <form className='ps-3 pe-3' onSubmit={handleSubmit(addSalesPersonsData)}>
          {/* ----------  Fields  ----------- */}
          <div className='mt-3 mb-3'>
            <label htmlFor='name' className='form-label'>
              Name
            </label>
            <input
              className='form-control'
              type='text'
              id='name'
              placeholder='Enter name'
              {...register('name')}
            />
            {formError && formError.name && <p className='text-danger'>{formError.name.message}</p>}
          </div>
          <div className='mb-3'>
            <label htmlFor='email' className='form-label'>
              Email
            </label>
            <input
              className='form-control'
              type='email'
              id='email'
              placeholder='Enter email'
              {...register('email')}
            />
          </div>
          <div className='mb-3'>
            <label htmlFor='phone' className='form-label'>
              Phone
            </label>
            <input
              className='form-control'
              type='phone'
              id='phone'
              placeholder='Enter phone'
              {...register('phone')}
            />
          </div>

          <div className={`mt-4 mb-2 d-flex justify-content-end`}>
            <div>
              <button
                className='btn px-1 btn-secondary'
                type='button'
                onClick={() => {
                  showAddPage(false);
                }}
              >
                Cancel
              </button>
              <button className='btn btn-primary ms-1' type='submit'>
                ADD
              </button>
            </div>
          </div>
        </form>

        {/*    ADD FORM END
         ********************************************* */}
      </div>
    </div>
  );
}

export default SalesPersonsAdd;
