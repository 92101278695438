import Spinner from "components/Spinner";
import { useEffect, useState } from "react";
import authHeader from "services/auth-header";
import API from "services/axios";

function EmailSmtpUpdate({ data, showUpdatePage, refreshFunc }) {
  /*   All States Below
   ********************************************* */
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [locations, setLocations] = useState(null);

  /*   All Functions
   ********************************************* */

  // get All Location
  const getAllLocations = async () => {
    try {
      const { data } = await API.get("/locations", {
        headers: authHeader(),
      });
      setLocations(data);
    } catch (err) {
      alert(err.message);
    }
  };

  const updateLocationsData = async (e) => {
    setLoading(true);
    e.preventDefault();

    try {
      const { data } = await API.patch("/email-smtp/", formData, {
        headers: { ...authHeader() },
      });

      if (data.message.type === "success") {
        await refreshFunc();
        setLoading(false);
        showUpdatePage(false);
        return;
      }
      alert(data);
    } catch (err) {
      alert(err.message);
      setLoading(false);
    }
  };

  const updateInput = (e) => {
    setFormData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  useEffect(() => {
    setFormData(data);
  }, [data]);

  useEffect(() => {
    getAllLocations();
  }, []);

  if (!locations) return <Spinner />;

  return (
    <div className="row">
      <div className="col-12">
        {loading && <Spinner />}
        {/*    Update FORM START
         ********************************************* */}
        {data && (
          <form className="ps-3 pe-3" onSubmit={updateLocationsData}>
            <div className="mb-3">
              <h3>UPDATE {data?.locationName?.toUpperCase()}</h3>
            </div>
            {/* ----------  Fields  ----------- */}
            <div className="mt-3 mb-3">
              <label htmlFor="accountName" className="form-label">
                Account Name
              </label>
              <input
                className="form-control"
                type="text"
                id="accountName"
                placeholder="Enter Account title/name"
                name="name"
                required
                defaultValue={data?.name}
                onChange={updateInput}
              />
            </div>
            <div className="mt-3 mb-3">
              <label htmlFor="locationId" className="form-label">
                Location
              </label>
              <select
                name="locationId"
                onChange={(e) => {
                  setFormData((prev) => {
                    return { ...prev, locationId: parseInt(e.target.value) };
                  });
                }}
                defaultValue={formData?.locationId}
                className="form-control"
                required
              >
                <option disabled selected>
                  Select Location
                </option>
                {locations?.map((item) => (
                  <option key={item?.id} value={item?.id}>
                    {item?.locationName}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="emailSmtpName" className="form-label">
                Smtp Email
              </label>
              <input
                className="form-control"
                type="email"
                id="emailSmtpName"
                placeholder="Enter Email"
                name="email"
                required
                defaultValue={data?.email}
                onChange={updateInput}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="smtp-password" className="form-label">
                Smtp Password
              </label>
              <div className="d-flex gap-2 justify-content-between align-items-center">
                <input
                  className="form-control"
                  type={showPassword ? "text" : "password"}
                  required
                  id="smtp-password"
                  placeholder="Enter Password"
                  name="password"
                  defaultValue={data?.password}
                  onChange={updateInput}
                />
                <div>
                  <button
                    type="button"
                    className="btn btn-info"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? "Hide" : "Show"}
                  </button>
                </div>
              </div>
            </div>

            <div className={`mt-4 mb-2 d-flex justify-content-end`}>
              <div>
                <button
                  className="btn px-1 btn-secondary"
                  type="button"
                  onClick={() => {
                    showUpdatePage(false);
                  }}
                >
                  Cancel
                </button>
                <button className="btn btn-primary ms-1" type="submit">
                  UPDATE
                </button>
              </div>
            </div>
          </form>
        )}
        {/*    Update FORM END
         ********************************************* */}
      </div>
    </div>
  );
}

export default EmailSmtpUpdate;
