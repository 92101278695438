import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { v1 as uuid } from "uuid";

export default function AddVariationForm({
  setSelectedDesignData,
  selectedDesignData,
  selectedVariation,
}) {
  /*   All States
   ********************************************* */
  const [formData, setFormData] = useState({});

  let inputList = [
    { name: "Design Code", type: "text", value: "designCode" },
    { name: "color", type: "text", value: "color" },
    { name: "Style No", type: "text", value: "styleNo" },
  ];

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["clean"],
    ],
  };

  /*   All Functions
   ********************************************* */
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let variationsList = selectedDesignData?.designVariations;

    let updatedVariationList = [...variationsList, { id: uuid(), ...formData }];

    setSelectedDesignData((prev) => ({
      ...prev,
      designVariations: updatedVariationList,
    }));
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    if (selectedVariation) {
      setFormData(selectedVariation);
    }
  }, [selectedVariation]);
  return (
    <div className="overflow-auto scrollbar-hide" style={{ height: "65vh" }}>
      <h3 className=" mt-1 mb-3">
        <span
          style={{
            borderBottom: "2px solid lightgray",
          }}
          className="pe-3"
        >
          Add Variation
        </span>
      </h3>
      <form onSubmit={handleSubmit}>
        {inputList.map((item) => (
          <div key={item.value} className="row mb-1">
            <div className="col-4 flex-center justify-content-start">
              <label className="form-label">{item.name}</label>
            </div>
            <div className="col-8">
              <input
                type={item.type}
                name={item.value}
                className="form-control"
                placeholder={`Enter ${item.name}`}
                value={formData[item.value] || ""}
                onChange={handleChange}
              />
            </div>
          </div>
        ))}
        <div className="row mb-1">
          <div className="col-4 flex-center justify-content-start">
            <label className="form-label">Product Image</label>
          </div>
          <div className="col-8">
            <input
              type={"file"}
              name={"productImg"}
              className="form-control"
              onChange={(e) => {
                setFormData({ ...formData, productImg: e.target.files[0] });
              }}
            />
          </div>
        </div>
        <div>
          <textarea
            className="form-control"
            placeholder="Enter Notes"
            value={formData.notes || ""}
            onChange={handleChange}
          >
            {formData?.notes || ""}
          </textarea>
          {/* <ReactQuill
            modules={modules}
            theme="snow"
            value={formData?.notes || ""}
            onChange={(val) => formData({ ...formData, notes: val })}
          /> */}
        </div>
        <div className="text-end mt-3">
          <button type="submit" className="btn btn-sm btn-success">
            Add Variation
          </button>
        </div>
      </form>
    </div>
  );
}
