import Table from 'components/DataTable/Table';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import authHeader from 'services/auth-header';
import API from 'services/axios';
import SalesPersonsAdd from './salesPersonsAdd';
import SalesPersonsUpdate from './salesPersonsUpdate';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';

function SalesPersons() {
  const user = useSelector((state) => state.user.userInfo);
  /*   All Satates Below
   ********************************************* */
  const [salesPersons, setSalesPersons] = useState(null);
  const [lastSortId, setLastSortId] = useState(null);

  // Modals
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  // below update data is used to set a varible before moving to update component
  const [updateData, setUpdateData] = useState(null);

  const [selectedDelete, setSelectedDelete] = useState();

  /*   All Functions Below
   ********************************************* */

  // get All salesPersons
  const getAllSalesPersons = async () => {
    try {
      const { data } = await API.get('/salespersons', { headers: authHeader() });
      setSalesPersons(data);

      setLastSortId(data[data.length - 1]?.sort_id ? data[data.length - 1]?.sort_id : 0);
    } catch (err) {
      alert(err.message);
    }
  };

  // Delete password Data
  const deleteSalesPersonsData = async (id) => {
    const { data } = await API.delete('/salesPersons/delete/' + id, { headers: authHeader() });
    if (data.message.type == 'success') {
      getAllSalesPersons();
      return;
    }
  };

  // Sorting Up
  const sortUp = async (index) => {
    const current_sort_id = salesPersons[index].sort_id;
    const current_id = salesPersons[index].id;
    const up_sort_id = salesPersons[index - 1].sort_id;
    const up_id = salesPersons[index - 1].id;
    try {
      const { data } = await API.post(
        '/salesPersons/sortup',
        { current_sort_id, current_id, up_sort_id, up_id },
        { headers: authHeader() }
      );
      if (data.success) {
        getAllSalesPersons();
      }
    } catch (err) {
      alert(err.message);
    }
  };

  // Sorting Up
  const sortDown = async (index) => {
    const current_sort_id = salesPersons[index].sort_id;
    const current_id = salesPersons[index].id;
    const down_sort_id = salesPersons[index + 1].sort_id;
    const down_id = salesPersons[index + 1].id;
    try {
      const { data } = await API.post(
        '/salesPersons/sortDown',
        { current_sort_id, current_id, down_sort_id, down_id },
        { headers: authHeader() }
      );
      if (data.success) {
        getAllSalesPersons();
      }
    } catch (err) {
      alert(err.message);
    }
  };

  useEffect(() => {
    getAllSalesPersons();
  }, []);

  /*   Table Functions below
   ********************************************* */
  const buttons = () => (
    <div className='d-flex justify-content-end ms-2'>
      <button onClick={() => setShowAddModal(true)} className='btn btn-primary'>
        ADD NEW
      </button>
    </div>
  );
  const table_head = ['Name', 'email', 'phone', 'Action'];
  const table_body = (item, index) => (
    <tr key={item.id}>
      <td>{item.name}</td>
      <td>{item.email}</td>
      <td>{item.phone}</td>
      <td className='table-action'>
        {user?.roles === 'admin' && (
          <Link
            onClick={() => {
              item.sort_id != 1 && sortUp(index);
            }}
            className='action-icon'
          >
            <i className='mdi mdi-chevron-double-up'></i>
          </Link>
        )}
        {user?.roles === 'admin' && (
          <Link
            onClick={() => {
              item.sort_id != salesPersons.length && sortDown(index);
            }}
            className='action-icon'
          >
            <i className='mdi mdi-chevron-double-down'></i>
          </Link>
        )}
        <Link className='action-icon'>
          <i
            onClick={() => {
              setShowUpdateModal(true);
              setUpdateData(item);
            }}
            className='mdi mdi-pencil'
          ></i>
        </Link>
        <Link className='action-icon'>
          <i
            data-bs-toggle='modal'
            data-bs-target='#danger-alert-modal'
            className='mdi mdi-delete'
            onClick={() => setSelectedDelete(item)}
          ></i>
        </Link>
      </td>
    </tr>
  );

  return (
    <div className='row'>
      <h3>Sales Persons</h3>
      <div className='col-12'>
        <Table
          btn1={buttons}
          table_body={table_body}
          table_data={salesPersons}
          table_head={table_head}
        />

        {/*   ADD Modal
         ********************************************* */}
        <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
          <Modal.Body className='custom-shadow'>
            <div>
              <SalesPersonsAdd
                showAddPage={setShowAddModal}
                refreshFunc={getAllSalesPersons}
                lastSortId={lastSortId}
              />
            </div>
          </Modal.Body>
        </Modal>
        {/*   Update Modal
         ********************************************* */}
        <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
          <Modal.Body className='custom-shadow'>
            <div>
              <SalesPersonsUpdate
                data={updateData}
                showUpdatePage={setShowUpdateModal}
                refreshFunc={getAllSalesPersons}
              />
            </div>
          </Modal.Body>
        </Modal>
        {/*   Confirmation Modal
         ********************************************* */}

        <div
          id='danger-alert-modal'
          className='modal fade'
          tabIndex='-1'
          role='dialog'
          aria-hidden='true'
        >
          <div className='modal-dialog modal-sm '>
            <div className='modal-content modal-filled bg-danger'>
              <div className='modal-body p-4'>
                <div className='text-center'>
                  <i className='ri-close-circle-line h1'></i>
                  <h4 className='mt-2'>Confirm Delete!</h4>
                  <p className='mt-3'>Do You want to delete ?</p>
                  <button type='button' className='btn btn-light my-2 mx-2' data-bs-dismiss='modal'>
                    Cancel
                  </button>
                  <button
                    type='button'
                    className='btn btn-outline-light my-2 '
                    data-bs-dismiss='modal'
                    onClick={() => {
                      deleteSalesPersonsData(selectedDelete.id);
                    }}
                  >
                    DELETE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SalesPersons;
