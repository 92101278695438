import React from "react";

export default function DesignInfo({ selectedDesignData }) {
  /*   All States
   ********************************************* */

  /*   All Functions
   ********************************************* */

  /*   All UseEffects
   ********************************************* */
  return (
    <div>
      <h3 className=" mt-1 mb-3">
        <span
          style={{
            borderBottom: "2px solid lightgray",
          }}
          className="pe-3"
        >
          Design Info
        </span>
      </h3>
      <div className="d-flex gap-2 align-items-center mb-2">
        <label style={{ width: "8%" }} className="text-nowrap">
          Design :
        </label>
        <input
          value={selectedDesignData?.id}
          style={{ width: "5%" }}
          className="form-control "
          placeholder="ID"
        />
        <input
          value={selectedDesignData?.name}
          className="w-100 form-control"
          placeholder="Enter Design Name"
        />
      </div>
      <div className="d-flex gap-2 align-items-center">
        <label style={{ width: "8%" }} className="text-nowrap">
          Customer :
        </label>
        <input
          value={selectedDesignData?.customer?.id}
          style={{ width: "5%" }}
          className="form-control "
          placeholder="ID"
        />
        <input
          value={selectedDesignData?.customer?.customerName}
          className="w-100 form-control"
          placeholder="Enter Customer Name"
        />
      </div>
    </div>
  );
}
