import React from "react";

export default function ContactAndBilling({
  formData,
  setFormData,
  handleInputChange,
}) {
  /*   All States
   ********************************************* */

  let contactFields = [
    { name: "External Customer ID", value: "externalCustomerId", type: "text" },
    { name: "Website", value: "website", type: "text" },
    { name: "Contact Name", value: "accountPayableContact", type: "text" },
    { name: "Phone", value: "accountPayablePhone", type: "text" },
    { name: "Email", value: "accountPayableEmail", type: "text" },
  ];

  let billingFields = [
    { name: "Company", value: "addressCompany", type: "text" },
    { name: "Address 1", value: "addressLine1", type: "text" },
    { name: "Address 2", value: "addressLine2", type: "text" },
  ];

  /*   All Functions
   ********************************************* */

  /*   All UseEffects
   ********************************************* */
  return (
    <div className="row bg-white custom-border-radius-sm custom-shadow mx-1">
      <div className="col-6 flex-grow-1 px-3 py-2 my-1">
        <div className="flex-grow-1 row mb-1">
          <div className="col-4 flex-center justify-content-start align-items-center">
            <label htmlFor={"customerName"} className="customer">
              Customer Name
            </label>
          </div>
          <div className="col-8">
            <div className="d-flex align-items-center">
              <input
                name="customerId"
                type="number"
                value={formData["customerId"] || ""}
                onChange={(e) => {
                  handleInputChange(e, "number");
                }}
                className="form-control form-control-sm w-25 flex-shrink-1"
                placeholder="ID"
              />
              <input
                name="customerName"
                type="text"
                value={formData["customerName"] || ""}
                onChange={(e) => {
                  handleInputChange(e, "text");
                }}
                className="form-control form-control-sm w-100 flex-grow-1"
                placeholder="🔎 Customer Name"
              />
            </div>
          </div>
        </div>

        {contactFields.map((item) => (
          <div key={item.value} className="flex-grow-1 row mb-1">
            <div className="col-4 flex-center justify-content-start align-items-center">
              <label htmlFor={item?.value} className="customer">
                {item?.name}
              </label>
            </div>
            <div className="col-8">
              <input
                name={item?.value}
                type={item?.type}
                value={formData[item?.value] || ""}
                onChange={(e) => {
                  handleInputChange(e, item?.type);
                }}
                className="form-control form-control-sm w-100"
                placeholder={`🔎 ${item?.name}`}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="col-6 flex-grow-1 px-3 py-2 my-1">
        {billingFields.map((item) => (
          <div key={item.value} className="flex-grow-1 row mb-1">
            <div className="col-4 flex-center justify-content-start align-items-center">
              <label htmlFor={item?.value} className="customer">
                {item?.name}
              </label>
            </div>
            <div className="col-8">
              <input
                name={item?.value}
                type={item?.type}
                value={formData[item?.value] || ""}
                onChange={(e) => {
                  handleInputChange(e, item?.type);
                }}
                className="form-control form-control-sm w-100"
                placeholder={`🔎 ${item?.name}`}
              />
            </div>
          </div>
        ))}
        <div className="flex-grow-1 row mb-1">
          <div className="col-4 flex-center justify-content-start align-items-center">
            <label htmlFor={"customerName"} className="customer">
              City | State | Zip
            </label>
          </div>
          <div className="col-8">
            <div className="d-flex align-items-center gap-1">
              <input
                name={"addressCity"}
                type={"text"}
                value={formData?.addressCity || ""}
                onChange={(e) => {
                  handleInputChange(e, "text");
                }}
                className="form-control form-control-sm w-100"
                placeholder={`🔎 city`}
              />
              <input
                name={"addressState"}
                type={"text"}
                value={formData?.addressState || ""}
                onChange={(e) => {
                  handleInputChange(e, "text");
                }}
                className="form-control form-control-sm w-100"
                placeholder={`🔎 state`}
              />
              <input
                name={"addressZipCode"}
                type={"text"}
                value={formData?.addressZipCode || ""}
                onChange={(e) => {
                  handleInputChange(e, "text");
                }}
                className="form-control form-control-sm w-100"
                placeholder={`🔎 zip code`}
              />
            </div>
          </div>
        </div>
        <div className="flex-grow-1 row mb-1">
          <div className="col-4 flex-center justify-content-start align-items-center">
            <label htmlFor={"customerName"} className="customer">
              Country
            </label>
          </div>
          <div className="col-8">
            <div className="d-flex align-items-center">
              <input
                name={"addressCountry"}
                type={"text"}
                value={formData?.addressCountry || ""}
                onChange={(e) => {
                  handleInputChange(e, "text");
                }}
                className="form-control form-control-sm w-100"
                placeholder={`🔎 country`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
