import Spinner from 'components/Spinner';
import React, { useEffect, useRef, useState } from 'react';
import authHeader from 'services/auth-header';
import API from 'services/axios';
import * as xlsx from 'xlsx';

function ImportCustomerInfo({
  id,
  customerContactData,
  customersAddressData,
  refreshFunc,
  setShowImportModal,
}) {
  /*   ALl States
   ********************************************* */
  const [jsonContactsData, setJsonContactsData] = useState(null);
  const [JsonAddressData, setJsonAddressData] = useState(null);

  const [includeContacts, setIncludeContacts] = useState(true);
  const [includeAddress, setIncludeAddress] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [duplicateName, setDuplicateName] = useState([]);

  const [contactLastSortId, setContactLastSortId] = useState(null);
  const [addressLastSortId, setAddressLastSortId] = useState(null);

  const inputRef = useRef();

  /*   All functions
   ********************************************* */
  const handleFileImport = (e) => {
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = xlsx.read(data, { type: 'binary' });
      const customerContacts = xlsx.utils.sheet_to_json(workbook.Sheets['customerContacts']);
      const customerAddress = xlsx.utils.sheet_to_json(workbook.Sheets['customerAddress']);
      setJsonContactsData(customerContacts);
      setJsonAddressData(customerAddress);
    };
  };

  const addCustomerFromFile = async () => {
    setIsLoading(true);

    try {
      if (includeContacts) {
        await Promise.all(
          jsonContactsData?.map(async (item, index) => {
            const submitData = {
              sortId: contactLastSortId + index + 1,
              type: 'contact',
              customerId: id,
              data: JSON.stringify(item),
            };

            await API.post(
              '/customers/contacts/insert',
              { data: submitData },
              { headers: authHeader() }
            );
          })
        );
      }
      if (includeAddress) {
        await Promise.all(
          JsonAddressData?.map(async (item, index) => {
            const submitData = {
              sortId: addressLastSortId + index + 1,
              type: 'address',
              customerId: id,
              data: JSON.stringify(item),
            };

            await API.post(
              '/customers/contacts/insert',
              { data: submitData },
              { headers: authHeader() }
            );
          })
        );
      }
      await refreshFunc();
      setShowImportModal(false);
    } catch (err) {
      console.log(err.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setContactLastSortId(
      customerContactData[customerContactData?.length - 1]?.sortId
        ? customerContactData[customerContactData?.length - 1]?.sortId
        : 0
    );
    setAddressLastSortId(
      customersAddressData[customersAddressData?.length - 1]?.sortId
        ? customersAddressData[customersAddressData?.length - 1]?.sortId
        : 0
    );
  }, [customerContactData, customersAddressData]);

  return (
    <div>
      <div className=''>
        <h4 className=''>IMPORT DATA FILE</h4>
      </div>
      <hr className='mb-3 mt-0' />

      <div className='d-flex'>
        <input ref={inputRef} type='file' className='form-control' onChange={handleFileImport} />
        <div className='d-flex'>
          <button
            onClick={() => {
              inputRef.current.value = null;
              setJsonContactsData(null);
              setJsonAddressData(null);
            }}
            className='btn btn-danger ms-1'
          >
            CLEAR
          </button>
        </div>
      </div>

      {isLoading && <Spinner />}

      <div className='mt-3 d-flex justtify-contents-center'>
        <div>
          <label>Inclue Contacts ? &nbsp;&nbsp;&nbsp;</label>
        </div>
        <div>
          <input
            type='checkbox'
            checked={includeContacts}
            onChange={(e) => setIncludeContacts(e.target.checked)}
            id='switch1'
            data-switch='bool'
          />
          <label for='switch1' data-on-label='Yes' data-off-label='No'></label>
        </div>
      </div>

      <div className='d-flex justtify-contents-center'>
        <div>
          <label>Inclue Address ? &nbsp;&nbsp;&nbsp;&nbsp;</label>
        </div>
        <div>
          <input
            type='checkbox'
            checked={includeAddress}
            onChange={(e) => setIncludeAddress(e.target.checked)}
            id='switch2'
            data-switch='bool'
          />
          <label for='switch2' data-on-label='Yes' data-off-label='No'></label>
        </div>
      </div>

      {/*   Buttons
       ********************************************* */}
      <div className='mt-3 flex-grow-1 d-flex justify-content-end align-items-end'>
        <div>
          <button
            onClick={() => {
              setShowImportModal(false);
              setJsonContactsData(null);
              setJsonAddressData(null);
            }}
            type='button'
            className='btn btn-secondary me-2 px-1'
          >
            CLOSE
          </button>
          <button
            disabled={!jsonContactsData || !JsonAddressData}
            onClick={addCustomerFromFile}
            className='btn btn-primary'
          >
            ADD
          </button>
        </div>
      </div>
    </div>
  );
}

export default ImportCustomerInfo;
