import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Spinner from 'components/Spinner';
import authHeader from 'services/auth-header';
import API from 'services/axios';
import { useDispatch } from 'react-redux';

import { deleteUser } from '../../features/user/userActions';
import Table from 'components/DataTable/Table';

function Users({ setShowAddUser, setSelectedUser }) {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [employees, setEmployees] = useState([]);

  const [selectedDelete, setSelectedDelete] = useState();

  const handleDelete = (id) => {
    dispatch(deleteUser({ id })).then((res) => {
      if (res.type === 'auth/register/rejected') {
        console.log('Rejected:', res);
      } else {
        setEmployees(employees.filter((item) => item.id !== id));
      }
    });
  };

  /*   Table Functions below
   ********************************************* */
  const buttons = () => (
    <div className='d-flex justify-content-end ms-2'>
      <button onClick={() => setShowAddUser(true)} className='btn btn-primary'>
        ADD NEW
      </button>
    </div>
  );

  const table_head = ['Full Name', 'Phone', 'Email', 'Status', 'Action'];
  const table_body = (user, i) => (
    <tr key={i}>
      <td className='table-user'>{user.firstName + ' ' + user.lastName}</td>
      <td>{user.phone}</td>
      <td>{user.email}</td>
      <td>
        {user.deactivatedAt ? (
          <span className='badge bg-danger-lighten text-danger'>Outgoing</span>
        ) : (
          <span className='badge bg-success-lighten text-success'>Active</span>
        )}
      </td>
      <td className='table-action'>
        <div
          onClick={() => {
            setSelectedUser(user);
            setShowAddUser(true);
          }}
          className='action-icon'
          role={'button'}
        >
          <i className='mdi mdi-pencil'></i>
        </div>

        <a
          href='#'
          data-bs-toggle='modal'
          data-bs-target='#danger-alert-modal'
          className='action-icon'
          onClick={() => setSelectedDelete(user)}
        >
          <i className='mdi mdi-delete'></i>
        </a>
      </td>
    </tr>
  );

  useEffect(() => {
    API.get('/users/list', { headers: authHeader() }).then(({ data }) => {
      setIsLoading(false);
      setEmployees(data.users);
    });
  }, []);

  if (isLoading) return <Spinner />;

  return (
    <>
      <div className='row'>
        <h3>User Management</h3>
        <div className='col-12'>
          <Table
            btn1={buttons}
            table_body={table_body}
            table_data={employees}
            table_head={table_head}
          />
          {/*   Confirmation Modal
           ********************************************* */}

          <div
            id='danger-alert-modal'
            className='modal fade'
            tabIndex='-1'
            role='dialog'
            aria-hidden='true'
          >
            <div className='modal-dialog modal-sm '>
              <div className='modal-content modal-filled bg-danger'>
                <div className='modal-body p-4'>
                  <div className='text-center'>
                    <i className='ri-close-circle-line h1'></i>
                    <h4 className='mt-2'>Confirm Delete!</h4>
                    <p className='mt-3'>Do You want to delete ?</p>
                    <button
                      type='button'
                      className='btn btn-light my-2 mx-2'
                      data-bs-dismiss='modal'
                    >
                      Cancel
                    </button>
                    <button
                      type='button'
                      className='btn btn-outline-light my-2 '
                      data-bs-dismiss='modal'
                      onClick={() => {
                        handleDelete(selectedDelete.id);
                      }}
                    >
                      DELETE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Users;
