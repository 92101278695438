import { Document, Page, StyleSheet, View, Text } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    padding: 10,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
    paddingBottom: 2,
    borderBottomWidth: 2,
    marginBottom: 5,
  },
  // Users Styles
  userDataContainer: {
    width: "95%",
    margin: "0 auto",
    marginBottom: 10,
    gap: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  userRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 2,
  },

  userSubRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 30,
  },

  userHeadingMain: {
    fontSize: 15,
    fontWeight: "extrabold",
    marginBottom: 2,
  },

  userHeading: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "left",
  },

  userBody: {
    fontSize: 10,
    fontWeight: "semibold",
    textAlign: "right",
  },
  tableContainer: {
    width: "100%",
    borderStyle: "solid",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    fontSize: 12,
    width: "14%",
    fontWeight: "extrabold",
    textAlign: "center",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderBottomWidth: 1,
  },
  tableCol: {
    fontSize: 9,
    width: "14%",
    textAlign: "center",
    borderStyle: "solid",
    justifyContent: "center",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    fontSize: 12,
    padding: 1,
    paddingLeft: 18,
    borderLeftWidth: 1,
    textAlign: "left",
    fontWeight: "bold",
  },
  tableCell: {
    fontSize: 9,
    textAlign: "center",
  },
  tableFirstCol: {
    fontSize: 9,
    width: "12.5%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#d3d3d3",
    textAlign: "left",
  },

  // Summary
  summaryContainer: {
    width: "100%",
    marginTop: 10,
    border: "2px solid #000",
  },
});

// Create Document Component
export const AttendanceReport = ({ selectedReport }) => {
  /*   All Functions   ********************************************* */
  function getDatesArrByMonth(value) {
    if (!value) {
      return;
    }
    const [month, year] = value?.split("-").map(Number);
    const date = new Date(year, month - 1, 1);
    const dates = [];
    while (date.getMonth() === month - 1) {
      const day = date.getDate();
      dates.push(`${month}-${day}-${year}`);
      date.setDate(date.getDate() + 1);
    }
    return dates;
  }

  const getDayNameByDate = (rawDate) => {
    let date = new Date(rawDate);
    const days = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    const dayOfWeek = days[date.getUTCDay()];

    return dayOfWeek;
  };

  // Create a function to convert "18:11:56" to "6:11 PM"
  function formatTime(timeString) {
    if (typeof timeString !== "string") {
      return null;
    }

    // Split the time string into hours, minutes, and seconds
    let [hours, minutes, seconds] = timeString?.split(":").map(Number);
    // if secounds are greater than 0 than add 1 in minutes

    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Handle midnight (0) and 12 PM
    const formattedMinutes = minutes.toString().padStart(2, "0");
    return `${formattedHours}:${formattedMinutes} ${period}`;
  }

  /*   All UseEffects   ********************************************* */
  const DatesList = getDatesArrByMonth(selectedReport?.monthYear);

  let headings = [
    "Date",
    "In Time",
    "Out Time",
    "Late Time",
    "Over Time",
    "Total Hours",
  ];

  return (
    <Document title={"Attendance Report"}>
      <Page style={styles.page}>
        <View style={styles.title}>
          <Text>Pay Period Timecard Report</Text>
        </View>
        {/* User Data */}
        <View style={styles.userDataContainer}>
          <View style={{ width: "40%" }}>
            <Text style={[styles.userHeadingMain, { marginBottom: 10 }]}>
              {selectedReport?.userData.name}
            </Text>

            <View style={styles.userSubRow}>
              <Text style={styles.userHeading}>Relaxation Minutes</Text>
              <Text style={styles.userBody}>
                {selectedReport?.userData?.relaxationMinutes &&
                  selectedReport?.userData?.relaxationMinutes}
              </Text>
            </View>
          </View>

          <View style={{ width: "40%" }}>
            <Text style={[styles.userHeadingMain, { marginBottom: 10 }]}>
              {DatesList[0]} To {DatesList.slice(-1)}
            </Text>
            <View style={styles.userRow}>
              <Text style={styles.userHeading}>Badge No</Text>
              <Text style={styles.userBody}>
                {selectedReport?.userData.badgeNo}
              </Text>
            </View>
            <View style={styles.userSubRow}>
              <Text style={styles.userBody}>Employee ID: </Text>
              <Text style={styles.userBody}>
                {selectedReport?.userData.userId}
              </Text>
            </View>
          </View>
        </View>

        {/* Attendance Records */}
        <View style={[styles.tableContainer]}>
          <View style={styles.tableRow}>
            {headings?.map((heading, i) => (
              <View
                key={heading}
                style={[
                  styles.tableColHeader,
                  {
                    borderLeftWidth: i === 0 ? 1 : 0,
                    width: i === 0 ? "30%" : "14%",
                  },
                ]}
              >
                <Text style={styles.tableCellHeader}>{heading}</Text>
              </View>
            ))}
          </View>
        </View>
        <View style={styles.tableContainer}>
          {DatesList.map((date, index) => (
            <View
              key={index}
              style={[
                styles.tableRow,
                {
                  backgroundColor:
                    getDayNameByDate(date) === "Sunday" && "#d3d3d3",
                },
              ]}
            >
              <View style={[styles.tableCol, { width: "30%" }]}>
                <Text style={[styles.tableCellHeader]}>
                  {/* {date} ( {getDayNameByDate(date)} ) */}
                  {date} ({" "}
                  {selectedReport?.attendanceRecords[date].dayNameOfWeek
                    ? selectedReport?.attendanceRecords[date].dayNameOfWeek
                    : getDayNameByDate(date)}{" "}
                  )
                </Text>
              </View>
              <View
                style={[
                  styles.tableCol,
                  {
                    backgroundColor:
                      selectedReport?.attendanceRecords[date]?.inTime &&
                      selectedReport?.attendanceRecords[date]?.inTime ===
                        selectedReport?.attendanceRecords[date]?.outTime &&
                      "#f1aeb5",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.tableCell,
                    {
                      color:
                        (selectedReport?.attendanceRecords[date]?.message ===
                          "Absent" ||
                          selectedReport?.attendanceRecords[date]?.type ===
                            "Holiday") &&
                        "red",
                    },
                  ]}
                >
                  {selectedReport?.attendanceRecords[date]?.message === "Absent"
                    ? "Absent"
                    : selectedReport?.attendanceRecords[date]?.type ===
                      "Holiday"
                    ? "Holiday"
                    : formatTime(
                        selectedReport?.attendanceRecords[date]?.inTime
                      ) || "-"}
                </Text>
              </View>
              <View
                style={[
                  styles.tableCol,
                  {
                    backgroundColor:
                      selectedReport?.attendanceRecords[date]?.inTime &&
                      selectedReport?.attendanceRecords[date]?.inTime ===
                        selectedReport?.attendanceRecords[date]?.outTime &&
                      "#f1aeb5",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.tableCell,
                    {
                      color:
                        (selectedReport?.attendanceRecords[date]?.message ===
                          "Absent" ||
                          selectedReport?.attendanceRecords[date]?.type ===
                            "Holiday") &&
                        "red",
                    },
                  ]}
                >
                  {selectedReport?.attendanceRecords[date]?.message === "Absent"
                    ? "Absent"
                    : selectedReport?.attendanceRecords[date]?.type ===
                      "Holiday"
                    ? "Holiday"
                    : formatTime(
                        selectedReport?.attendanceRecords[date]?.outTime
                      ) || "-"}
                </Text>
              </View>
              <View
                style={[
                  styles.tableCol,
                  {
                    backgroundColor:
                      selectedReport?.attendanceRecords[date]?.inTime &&
                      selectedReport?.attendanceRecords[date]?.inTime ===
                        selectedReport?.attendanceRecords[date]?.outTime &&
                      "#f1aeb5",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.tableCell,
                    {
                      color:
                        (selectedReport?.attendanceRecords[date]?.message ===
                          "Absent" ||
                          selectedReport?.attendanceRecords[date]?.type ===
                            "Holiday") &&
                        "red",
                    },
                  ]}
                >
                  {selectedReport?.attendanceRecords[date]?.message === "Absent"
                    ? "Absent"
                    : selectedReport?.attendanceRecords[date]?.type ===
                      "Holiday"
                    ? "Holiday"
                    : selectedReport?.attendanceRecords[date]?.totalLateTime ||
                      "-"}
                </Text>
              </View>
              <View
                style={[
                  styles.tableCol,
                  {
                    backgroundColor:
                      selectedReport?.attendanceRecords[date]?.inTime &&
                      selectedReport?.attendanceRecords[date]?.inTime ===
                        selectedReport?.attendanceRecords[date]?.outTime &&
                      "#f1aeb5",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.tableCell,
                    {
                      color:
                        selectedReport?.attendanceRecords[date]?.message ===
                          "Absent" && "red",
                    },
                  ]}
                >
                  {selectedReport?.attendanceRecords[date]?.message === "Absent"
                    ? "Absent"
                    : selectedReport?.attendanceRecords[date]?.overTime || "-"}
                </Text>
              </View>
              <View
                style={[
                  styles.tableCol,
                  {
                    backgroundColor:
                      selectedReport?.attendanceRecords[date]?.inTime &&
                      selectedReport?.attendanceRecords[date]?.inTime ===
                        selectedReport?.attendanceRecords[date]?.outTime &&
                      "#f1aeb5",
                  },
                ]}
              >
                <Text
                  style={[
                    styles.tableCell,
                    {
                      color:
                        selectedReport?.attendanceRecords[date]?.message ===
                          "Absent" && "red",
                    },
                  ]}
                >
                  {selectedReport?.attendanceRecords[date]?.message === "Absent"
                    ? "Absent"
                    : selectedReport?.attendanceRecords[date]?.totalHours ||
                      "-"}
                </Text>
              </View>
            </View>
          ))}
        </View>
        <View>
          <View style={styles.summaryContainer}>
            {/* Layout with 2 columns*/}

            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                paddingHorizontal: 10,
                paddingVertical: 5,
              }}
            >
              <View style={{ width: "50%" }}>
                <Text style={styles.userHeading}>Total Late Time</Text>
              </View>
              <View style={{ width: "50%" }}>
                <Text style={styles.userBody}>
                  {selectedReport?.summaryData?.totalLateTime || "00:00"}
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                paddingHorizontal: 10,
                paddingVertical: 5,
              }}
            >
              <View style={{ width: "50%" }}>
                <Text style={styles.userHeading}>Absent Days</Text>
              </View>
              <View style={{ width: "50%" }}>
                <Text style={styles.userBody}>
                  {selectedReport?.summaryData?.absentDaysCount || 0}
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                paddingHorizontal: 10,
                paddingVertical: 5,
              }}
            >
              <View style={{ width: "50%" }}>
                <Text style={styles.userHeading}>Total Over Time</Text>
              </View>
              <View style={{ width: "50%" }}>
                <Text style={styles.userBody}>
                  {selectedReport?.summaryData?.totalOverTime || "00:00"}
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                paddingHorizontal: 10,
                paddingVertical: 5,
              }}
            >
              <View style={{ width: "50%" }}>
                <Text style={styles.userHeading}>Total Working Hours</Text>
              </View>
              <View style={{ width: "50%" }}>
                <Text style={styles.userBody}>
                  {selectedReport?.summaryData?.totalWorkingHours || "00:00"}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
