import Table from "components/DataTable/Table";
import Spinner from "components/Spinner";
import Toast from "components/Toast/ToastComponent";
import { setNotification } from "features/Notification/notificationSlice";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";
import {
  AddMockupCatalogue,
  UpdateMockupCatalogue,
} from "./MockupCatalogueCrud";
import MockupCategories from "./MockupCategories/MockupCategories";
import MockupFabrics from "./MockupFabrics/MockupFabrics";

export default function MockupCatalogue() {
  const user = useSelector((state) => state.user?.userInfo);
  const dispatch = useDispatch();
  /*   ALL STATES
   ********************************************* */
  const [filterBy, setFilterBy] = useState(null);
  const [mockupCatalogue, setMockupCatalogue] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteID, setDeleteID] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showCategoriesModal, setShowCategoriesModal] = useState(false);
  const [showFabricsModal, setShowFabricsModal] = useState(false);
  const [updateData, setUpdateData] = useState(null);

  const [loading, setLoading] = useState(false);

  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  /*   All Functions
   ********************************************* */
  //get Data
  const getMockupCatalogue = async () => {
    try {
      loadingOn();
      const { data } = await API.get("/mockup-catalogues", {
        headers: authHeader(),
      });
      setMockupCatalogue(data);
      loadingOff();
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      loadingOff();
    }
  };

  // Delete Quotations
  const deleteMockupCatalogue = async (deleteData) => {
    try {
      loadingOn();
      const { data } = await API.post("/mockup-catalogue/delete", deleteData, {
        headers: authHeader(),
      });
      if (data?.message?.type === "success") {
        getMockupCatalogue();
        setShowDeleteModal(false);
        return;
      }
      loadingOff();
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      loadingOff();
    }
  };

  useEffect(() => {
    getMockupCatalogue();
  }, []);

  /*   Table functions
   ********************************************* */
  const btn1 = () => (
    <div>
      <>
        <button
          onClick={() => setShowAddModal(true)}
          type="button"
          className="btn btn-primary  ms-1"
        >
          ADD Mockup
        </button>
        <button
          onClick={() => setShowCategoriesModal(true)}
          type="button"
          className="btn btn-info  ms-1"
        >
          Categories
        </button>
        <button
          onClick={() => setShowFabricsModal(true)}
          type="button"
          className="btn btn-info  ms-1"
        >
          Fabrics
        </button>
      </>
    </div>
  );

  const buttons = () => (
    <div className=" d-flex justify-content-end ">
      {/* FilterBy */}
      <button
        type="button"
        className="px-1 bg-primary btn text-dark bg-opacity-10 border border-primary rounded-end mx-2 rounded-start"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {filterBy
          ? filterBy.toUpperCase().replaceAll("_", " ")
          : "Filter by All"}
      </button>
      <div className="dropdown-menu">
        <a
          onClick={() => setFilterBy(null)}
          className="dropdown-item"
          role={"button"}
        >
          Filter by All
        </a>
        <a
          onClick={() => setFilterBy("company_name")}
          className="dropdown-item"
          role={"button"}
        >
          Company Name
        </a>
        <a
          onClick={() => setFilterBy("qxternal_Quotations_id")}
          className="dropdown-item"
          role={"button"}
        >
          External ID
        </a>
        <a
          onClick={() => setFilterBy("website")}
          className="dropdown-item"
          role={"button"}
        >
          Website
        </a>
        <a
          onClick={() => setFilterBy("transit_days")}
          className="dropdown-item"
          role={"button"}
        >
          Transit Days
        </a>
        <a
          onClick={() => setFilterBy("is_tax_exemption")}
          className="dropdown-item"
          role={"button"}
        >
          Tax Exampted
        </a>
        <a
          onClick={() => setFilterBy("quotations_type")}
          className="dropdown-item"
          role={"button"}
        >
          Customer Type
        </a>
      </div>
    </div>
  );
  const table_head = [
    "id",
    "Style #",
    "Description",
    "Product Image",
    "Pdf Catalogue",
    "Actions",
  ];

  const table_body = (item, index) => (
    <tr className="bg-none" key={item.id}>
      <td className="py-0 align-middle">{item.id}</td>
      <td className="py-0 align-middle">{item.styleNo}</td>
      <td className="py-0 align-middle ">{item.description}</td>
      <td
        className={`py-0 align-middle ms-5   ${
          item.productImage ? "text-success " : "text-danger"
        }`}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        {item.productImage ? "Yes" : "No"}
      </td>
      <td
        className={`py-0 align-middle ms-5  ${
          item.pdfCatalogue ? "text-success " : "text-danger"
        }`}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {item.pdfCatalogue ? "Yes" : "No"}
      </td>
      <td className="py-0 align-middle">
        <div className="action-icon">
          <i
            onClick={() => {
              setShowUpdateModal(true);
              setUpdateData(item);
            }}
            className="mdi mdi-pencil"
          ></i>
        </div>
        {(user?.permissions?.includes("work_orders_delete") ||
          user?.roles === "admin") && (
          <div
            onClick={() => {
              setShowDeleteModal(true);
              setDeleteID({
                id: item.id,
                productImage: item.productImage,
                productCatalogue: item.productCatalogue,
              });
            }}
            className="action-icon"
          >
            <i className="mdi mdi-delete"></i>
          </div>
        )}
      </td>
    </tr>
  );
  return (
    <div
      className=""
      style={{
        marginLeft: 280,
        height: "90vh",
        paddingTop: 10,
        paddingRight: 30,
      }}
    >
      {loading && <Spinner />}
      <Toast />
      <h3 className="mb-4">Mockups Catalogue</h3>
      {/*   Table
       ********************************************* */}
      <div
        className="px-2 flex-grow-1 d-flex"
        style={{ minHeight: "50vh", borderRadius: 15 }}
      >
        <div className="horizontal-scrollable d-flex flex-grow-1">
          <Table
            filterBy={filterBy}
            buttons={buttons}
            btn1={btn1}
            table_head={table_head}
            table_body={table_body}
            table_data={mockupCatalogue}
          />
        </div>
      </div>
      {/*   Mockup Categories Modal
       ********************************************* */}
      <Modal
        show={showCategoriesModal}
        onHide={() => setShowCategoriesModal(false)}
      >
        <Modal.Body
          className="shadow-lg bg-white px-3 "
          style={{ borderRadius: 15 }}
        >
          <MockupCategories
            showModal={setShowCategoriesModal}
            refreshFunc={getMockupCatalogue}
          />
        </Modal.Body>
      </Modal>
      {/*   Mockup Fabrics Modal
       ********************************************* */}
      <Modal show={showFabricsModal} onHide={() => setShowFabricsModal(false)}>
        <Modal.Body
          className="shadow-lg bg-white px-3 "
          style={{ borderRadius: 15 }}
        >
          <MockupFabrics
            showModal={setShowFabricsModal}
            refreshFunc={getMockupCatalogue}
          />
        </Modal.Body>
      </Modal>
      {/*   ADD Modal
       ********************************************* */}
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Body
          className="shadow-lg bg-white px-3 "
          style={{ borderRadius: 15 }}
        >
          <AddMockupCatalogue
            showModal={setShowAddModal}
            refreshFunc={getMockupCatalogue}
          />
        </Modal.Body>
      </Modal>

      {/*   Update Modal
       ********************************************* */}
      <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
        <Modal.Body
          className="shadow-lg bg-white px-3 "
          style={{ borderRadius: 15 }}
        >
          {updateData && (
            <UpdateMockupCatalogue
              selectedItem={updateData}
              showModal={setShowUpdateModal}
              refreshFunc={getMockupCatalogue}
            />
          )}
        </Modal.Body>
      </Modal>
      {/*   Delete Modal
       ********************************************* */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Body className="bg-danger">
          <div className="">
            <div className="text-center text-white">
              <i className="ri-close-circle-line h1"></i>
              <h4 className="mt-2">Confirm Delete!</h4>
              <p className="mt-3">Do You want to delete ?</p>
              <button
                type="button"
                onClick={() => setShowDeleteModal(false)}
                className="btn btn-light my-2 mx-2"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-outline-light my-2 "
                data-bs-dismiss="modal"
                onClick={() => {
                  deleteMockupCatalogue(deleteID);
                }}
              >
                DELETE
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
