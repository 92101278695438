import { useEffect, useState } from "react";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function CustomerSelectModal({
  showModal,
  modalStatus,
  handleCustomerSelection,
}) {
  /*   All States
   ********************************************* */
  const [searchResults, setSearchResults] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [customerData, setCustomerData] = useState(null);
  const [selected, setSelected] = useState(null);

  /*   All Functions
   ********************************************* */
  const handleSelectedCustomer = async (customerData) => {
    setSelected(customerData);
    setSearchText("");
  };

  const getCustomersData = async () => {
    try {
      const { data } = await API.get("/customers", { headers: authHeader() });
      setCustomerData(data);
    } catch (err) {
      alert(err.message);
    }
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    if (modalStatus) {
      getCustomersData();
    }
  }, [modalStatus]);

  useEffect(() => {
    let results = customerData?.filter((item) => {
      return (
        item.id.toString().includes(searchText.toString()) ||
        item.customerName
          .toLowerCase()
          .includes(searchText.toString().toLowerCase())
      );
    });
    setSearchResults(results);

    return () => {
      setSearchResults(null);
    };
  }, [searchText]);
  return (
    <div>
      <h3>Change Customer</h3>
      <div className="fw-bold fs-4 mt-3 mb-1 px-1">Search Customer</div>

      <div className="d-flex align-items-center mb-2">
        <div className="flex-grow-1 ">
          <input
            className="form-control bg-white shadow-sm"
            placeholder="Search Customer"
            type="search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
      </div>
      {selected && (
        <div className="my-3">
          <div className="fw-bold fs-4 mb-1 px-1">Selected Customer</div>
          <div
            className="bg-white border-primary border py-2 shadow-lg"
            style={{ borderRadius: 8 }}
          >
            <div className="row ps-3 pe-1">
              <div className="col-2">{selected?.id}</div>
              <div className="col-8">{selected?.customerName}</div>
              <div className="col-2">
                <div
                  onClick={() => setSelected(null)}
                  className="btn btn-sm btn-danger p-0 px-1"
                >
                  <i className="mdi mdi-delete"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {searchText && (
        <div
          className="bg-white px-2 shadow-lg scroll py-2 mt-1"
          style={{
            maxHeight: "50%",
            scrollBehavior: "auto",
            borderRadius: 10,
          }}
        >
          {searchResults?.map((item, index) => (
            <div key={index} className="px-2">
              <div
                className={` rounded py-1  ${
                  item.customerName.toLowerCase() === searchText.toLowerCase()
                    ? "bg-primary bg-opacity-25"
                    : ""
                } row`}
              >
                <div className="col-2">{item?.id}</div>
                <div className="col-8">{item?.customerName}</div>
                <div className="col-2">
                  <div
                    onClick={() => handleSelectedCustomer(item)}
                    className="btn btn-sm btn-success p-0 px-1"
                  >
                    Select
                  </div>
                </div>
              </div>
              {!(item.id === searchResults[searchResults.length - 1].id) &&
                !(searchResults.length === 1) && <hr className="my-0 " />}
            </div>
          ))}
        </div>
      )}
      {/*   Action Buttons
       ********************************************* */}
      <div className={`mt-4 mb-2 d-flex justify-content-end`}>
        <div>
          <button
            className="btn px-1 btn-secondary"
            type="button"
            onClick={() => {
              showModal(false);
            }}
          >
            Cancel
          </button>
          <button
            disabled={!selected}
            onClick={() => {
              handleCustomerSelection(selected);
            }}
            className="btn btn-primary ms-1"
            type="button"
          >
            Select
          </button>
        </div>
      </div>
    </div>
  );
}
