import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import WOinfo from "./ManageWo/WOinfo";
import BillingAddress from "./ManageWo/BillingAddress";
import ShippingAddress from "./ManageWo/ShippingAddress";
import WODetails from "./ManageWo/WODetails";
import WONotes from "./ManageWo/WONotes";

function ManageWOs({ formData, setFormData, selectedWorkOrder, refreshFunc }) {
  /*   All States Below
   ********************************************* */
  const user = useSelector((state) => state.user?.userInfo);

  const disableCustomerSelect =
    user?.permissions?.includes("work_orders_add") ||
    user?.permissions?.includes("work_orders_update") ||
    user?.permissions?.includes("work_orders_delete") ||
    user?.roles === "admin";

  /*   All Functions Below
   ********************************************* */

  /*   All UseEffects
   ********************************************* */

  return (
    <div className="row">
      <div className="col-12">
        <div className="d-flex gap-2 mb-2">
          {selectedWorkOrder && (
            <WOinfo
              formData={formData}
              setFormData={setFormData}
              refreshFunc={refreshFunc}
              selectedWorkOrder={selectedWorkOrder}
              disableCustomerSelect={disableCustomerSelect}
            />
          )}
        </div>
        <div className="d-flex gap-2 mb-2">
          {selectedWorkOrder && (
            <BillingAddress
              selectedWorkOrder={selectedWorkOrder}
              refreshFunc={refreshFunc}
            />
          )}
          {selectedWorkOrder && (
            <WODetails
              selectedWorkOrder={selectedWorkOrder}
              refreshFunc={refreshFunc}
            />
          )}
        </div>
        <div className="d-flex gap-2 mb-2">
          {selectedWorkOrder && (
            <ShippingAddress
              selectedWorkOrder={selectedWorkOrder}
              refreshFunc={refreshFunc}
              disableCustomerSelect={disableCustomerSelect}
            />
          )}
          {selectedWorkOrder && (
            <WONotes
              selectedWorkOrder={selectedWorkOrder}
              refreshFunc={refreshFunc}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ManageWOs;
