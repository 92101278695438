import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    padding: 20,
  },
  topHeader: {
    height: 75,
  },
  shippingDescription: {
    marginVertical: 5,
  },
  headingMain: { fontSize: 20, fontWeight: "extrabold" },
  headingSecond: { fontSize: 12, fontWeight: "extrabold" },
  headingThird: { fontSize: 15, fontWeight: "extrabold" },
  textbody: { fontSize: 15 },
  mainContainer: {
    marginBottom: "20px",
    flexDirection: "column",
    border: "1px solid grey",
  },
  prodTitle: {
    width: "100%",
    textAlign: "left",
    padding: "5px 10px",

    borderBottom: "1px solid grey",
    fontSize: "14px",
  },
  prodMain: {
    width: "100%",
    flexDirection: "row",
  },
  prodContent: {
    flexGrow: 1,
  },
  prodSizes: {
    padding: "5px",
    width: "150px",
    borderLeft: "1px solid grey",
  },
  prodInfoRow: {
    display: "flex",
    flexDirection: "row",
    fontSize: "10px",
  },
  prodCol: {
    display: "flex",
    flexDirection: "row",
    width: "50%",
  },
  prodKey: {
    padding: "3px 5px",
    width: "40%",
    borderRight: "1px solid grey",
    borderBottom: "1px solid grey",
  },
  prodVal: {
    padding: "3px 5px",
    width: "60%",
    borderBottom: "1px solid grey",
  },
  prodComments: {
    padding: "5px 10px",
    fontSize: "12px",
    borderTop: "1px solid grey",
  },
  prodSubHeading: {
    fontSize: "12px",
  },
  prodTxt: {
    fontSize: "10px",
  },
  borderRight: { borderRight: "1px solid grey" },
  colWithLong: {
    width: "200px",
    marginTop: 5,
    marginBottom: 3,
  },
  colWith: {
    width: "60px",
    marginTop: 5,
    marginBottom: 3,
    marginHorizontal: 5,
  },
  colWithSm: {
    width: "30px",
    marginTop: 5,
    marginBottom: 3,
  },
  colWithXs: {
    width: "10px",
    marginTop: 5,
    marginBottom: 3,
  },
});

// Create Document Component
export const JobBoardPdfDocument = ({ filterBy, workOrderList }) => {
  /*   ALL STATES
   ********************************************* */

  /*   ALL FUnctions
   ********************************************* */

  const getTotalQty = (products) => {
    if (products && products.length > 0) {
      let totalQty = 0;
      products?.map((item) => {
        totalQty += item?.totalQuantity;
      });
      return totalQty;
    } else {
      return 0;
    }
  };

  const getSumOfAllQty = (workOrderList) => {
    let totalQty = 0;
    workOrderList?.map((item) => {
      totalQty += getTotalQty(item?.WOProducts);
    });
    return totalQty;
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    const date = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    const formattedDate = date + "-" + month + "-" + year;
    return formattedDate;
  };

  const getCurrentTime = () => {
    const currentDate = new Date();

    let hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();

    let ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12; // If hours is 0, set it to 12

    const formattedTime = hours + ":" + minutes + " " + ampm;

    return formattedTime;
  };

  return (
    <Document title={filterBy}>
      <Page size="A4" orientation="landscape" style={styles.page}>
        {/*   Header
         ********************************************* */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            marginBottom: 20,
          }}
        >
          <View>
            <Text style={{ fontSize: 25 }}>{filterBy}</Text>
          </View>
          <View>
            <Text style={{ fontSize: 15 }}>
              Date : {getCurrentDate()} Time : {getCurrentTime()}
            </Text>
          </View>
        </View>
        <View style={{ marginTop: "5px" }}>
          {/*   Table Head
           ********************************************* */}
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-end",
              borderBottom: "2px solid gray",

              paddingHorizontal: 10,
            }}
          >
            <View style={{ width: "15%" }}>
              <Text style={styles.textbody}>ID#</Text>
            </View>
            <View style={{ width: "35%" }}>
              <Text style={styles.textbody}>Job Name</Text>
            </View>
            <View style={{ width: "35%" }}>
              <Text style={styles.textbody}>Customer Name</Text>
            </View>
            <View style={{ width: "15%" }}>
              <Text style={styles.textbody}>
                Quantity ( {getSumOfAllQty(workOrderList)} )
              </Text>
            </View>
          </View>
          {/*   Table Body
           ********************************************* */}
          {workOrderList.map((item) => (
            <View
              key={item?.id}
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid gray",

                paddingHorizontal: 10,
              }}
            >
              <View style={{ width: "15%" }}>
                <Text style={styles.textbody}>{item?.id}</Text>
              </View>

              <View style={{ width: "35%" }}>
                <Text style={[styles.textbody, { textAlign: "left" }]}>
                  {item?.jobName?.slice(0, 30)}
                  {item?.jobName?.length >= 30 ? "..." : ""}
                </Text>
              </View>
              <View style={{ width: "35%" }}>
                <Text style={[styles.textbody, { textAlign: "left" }]}>
                  {item?.customerName?.slice(0, 30)}
                  {item?.customerName?.length >= 30 ? "..." : ""}
                </Text>
              </View>
              <View style={{ width: "15%" }}>
                <Text style={styles.textbody}>
                  {getTotalQty(item?.WOProducts)}
                </Text>
              </View>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};
