import { setNotification } from 'features/Notification/notificationSlice';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import authHeader from 'services/auth-header';
import API from 'services/axios';
import { v4 as uid } from 'uuid';
import AddByQuantity from './AddByQuantity';
import AddByArea from './AddByArea';
import AddBySheet from './AddBySheet';
import AddByStitch from './AddByStitch';
import Spinner from 'components/Spinner';

export default function PriceMatrixAdd({
  refreshFunc,
  setShowAddModal,
  discountLevel,
  lastSortId,
}) {
  const dispatch = useDispatch();
  /*   All States
   ********************************************* */

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [active, setActive] = useState('By Quantity');

  /*   All Functions
   ********************************************* */
  // Adds Button Function
  const addMatrix = async ({ data }) => {
    let addData = {
      ...data,
      sortId: lastSortId + 1,
      discountLevelId: formData.discountLevelId
        ? parseInt(formData.discountLevelId)
        : discountLevel[0]?.id,
    };
    setIsLoading(true);
    try {
      const { data } = await API.post('/priceMatrix', { data: addData }, { headers: authHeader() });

      await refreshFunc();
      setIsLoading(false);
      setShowAddModal(false);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: 'error',
        })
      );
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  /*   All UseEffects
   ********************************************* */

  return (
    <div>
      <h3 className='mb-3'>Price Matrix</h3>
      {isLoading && <Spinner />}
      <hr />
      {/*   Name And Discount
       ********************************************* */}
      <div className='d-flex gap-2'>
        <div className='d-flex align-items-center gap-2 flex-grow-1'>
          <label>Name</label>
          <input
            type='text'
            name='name'
            value={formData?.name}
            onChange={handleChange}
            className='form-control'
            placeholder='Enter Matrix Name'
          />
        </div>
        <div className='d-flex align-items-center gap-2 flex-grow-1'>
          <label className='text-nowrap'>Discount Level</label>
          <select onChange={handleChange} name='discountLevelId' className='form-control'>
            {discountLevel?.map((item) => (
              <option value={item.id} key={item.id}>
                {item.discountName}
              </option>
            ))}
          </select>
        </div>
      </div>
      {/*  Options Select
       ********************************************* */}

      <div className='mt-3 d-flex gap-1'>
        <button
          onClick={() => {
            setActive('By Quantity');
          }}
          className={`btn btn-sm ${
            active === 'By Quantity' ? 'btn-primary text-white' : 'btn-outline-primary'
          } btn-outline-primary rounded-pill`}
        >
          By Quantity
        </button>
        <button
          onClick={() => {
            setActive('By Area');
          }}
          className={`btn btn-sm ${
            active === 'By Area' ? 'btn-primary  text-white' : 'btn-outline-primary'
          } rounded-pill`}
        >
          By Area
        </button>
        <button
          onClick={() => {
            setActive('By Sheet');
          }}
          className={`btn btn-sm ${
            active === 'By Sheet' ? 'btn-primary  text-white' : 'btn-outline-primary'
          } rounded-pill`}
        >
          By Sheet
        </button>
        <button
          onClick={() => {
            setActive('By Stitch');
          }}
          className={`btn btn-sm ${
            active === 'By Stitch' ? 'btn-primary  text-white' : 'btn-outline-primary'
          } rounded-pill`}
        >
          By Stitch
        </button>
      </div>

      <div className='mt-3'>
        {active === 'By Quantity' && <AddByQuantity formData={formData} addMatrix={addMatrix} />}
      </div>
      <div className='mt-3'>
        {active === 'By Area' && <AddByArea formData={formData} addMatrix={addMatrix} />}
      </div>
      <div className='mt-3'>
        {active === 'By Sheet' && <AddBySheet formData={formData} addMatrix={addMatrix} />}
      </div>
      <div className='mt-3'>
        {active === 'By Stitch' && <AddByStitch formData={formData} addMatrix={addMatrix} />}
      </div>
    </div>
  );
}
