import { configureStore, combineReducers } from "@reduxjs/toolkit";
import NotificationSlice from "./features/Notification/notificationSlice";
import userReducer from "./features/user/userSlice";
import companyReducer from "./features/company/companySlice";
import settingsReducer from "./features/settings/settingsSlice";
import passwordReducer from "./features/passwordGeneraotr/passwordSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  notifications: NotificationSlice,
  user: userReducer,
  company: companyReducer,
  settings: settingsReducer,
  password: passwordReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export const persistor = persistStore(store);

export default store;
