import Spinner from "components/Spinner";
import Toast from "components/Toast/ToastComponent";
import { setNotification } from "features/Notification/notificationSlice";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function DuplicateWO({
  selectedWorkOrder,
  showModal,
  searchUrl,
}) {
  /*   All States
   ********************************************* */
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({
    "Job Name": true,
    Contact: true,
    "Shipping Address": true,
    "Billing Address": true,
    "WO Products": true,
  });

  let copyOptions = [
    "Job Name",
    "Contact",
    "Billing Address",
    "Shipping Address",
    "WO Products",
  ];

  /*   All Functions
   ********************************************* */
  let handleOptionSelect = (e) => {
    setSelectedOptions((prev) => {
      return { ...prev, [e.target.name]: e.target.checked };
    });
  };

  let handleUpdateWOProducts = (products) => {
    let updatedProducts = products?.map((item) => {
      delete item.id;
      delete item.createdAt;
      delete item.updatedAt;
      delete item.totalPrice;
      delete item.totalQuantity;

      let productSizes = JSON.parse(item.productSizes);
      let updatedProductSizes = JSON.stringify(
        productSizes?.map((product) => {
          return { ...product, price: 0 };
        })
      );

      item.productSizes = updatedProductSizes;
      return item;
    });

    return updatedProducts;
  };

  let handleDuplicateWO = async () => {
    setLoading(true);

    let duplicatedWO = JSON.parse(JSON.stringify(selectedWorkOrder));
    delete duplicatedWO.id;
    delete duplicatedWO.createdAt;
    delete duplicatedWO.updatedAt;

    delete duplicatedWO.WODate;
    duplicatedWO.WODate = new Date();

    delete duplicatedWO.WOShipDate;
    duplicatedWO.WOShipDate = new Date();

    // Check for Job Name : jobName
    if (!selectedOptions["Job Name"]) {
      delete duplicatedWO.jobName;
    }

    // Check for Contact : contactData
    if (!selectedOptions.Contact) {
      delete duplicatedWO.contactData;
    } else {
      duplicatedWO.contactData =
        typeof duplicatedWO.contactData === "string"
          ? JSON.parse(duplicatedWO.contactData)
          : duplicatedWO.contactData;
    }

    // Check for Billing Address : billingAddress
    if (!selectedOptions["Billing Address"]) {
      delete duplicatedWO.billingAddress;
    } else {
      duplicatedWO.billingAddress =
        typeof duplicatedWO.contactData === "string"
          ? JSON.parse(duplicatedWO.billingAddress)
          : duplicatedWO.billingAddress;
    }

    // Check for Shipping Address : shippingAddress
    if (!selectedOptions["Shipping Address"]) {
      delete duplicatedWO.shippingAddress;
    } else {
      duplicatedWO.shippingAddress =
        typeof duplicatedWO.contactData === "string"
          ? JSON.parse(duplicatedWO.shippingAddress)
          : duplicatedWO.shippingAddress;
    }

    // Check for WO Products : WOProducts`
    if (!selectedOptions["WO Products"]) {
      delete duplicatedWO.WOProducts;
    } else {
      const updatedProduct = handleUpdateWOProducts(duplicatedWO.WOProducts);
      duplicatedWO.WOProducts = updatedProduct;
    }

    try {
      const { data } = await API.post("/duplicate-WO", duplicatedWO, {
        headers: authHeader(),
      });
      if (data?.type === "success") {
        dispatch(
          setNotification({
            message: "Added Successfully",
            type: "success",
          })
        );

        setLoading(false);
        showModal(false);
        let newID = data.data?.id;
        navigate(
          `/work-order/details/${params?.filterBy}/${newID}/${searchUrl}`
        );
        // setAllWorkOrdersData([data.data]);

        return data.data;
      }
      if (data?.type === "error") {
        dispatch(
          setNotification({
            message: data?.message,
            type: "error",
          })
        );
        setLoading(false);
        return;
      }
      dispatch(
        setNotification({
          message: "Something Went wrong check console.",
          type: "error",
        })
      );
      console.log(data);
      setLoading(false);
    } catch (err) {
      console.log(err.message);
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      setLoading(false);
    }
  };

  /*   All UseEffects
   ********************************************* */

  useEffect(() => {
    if (selectedWorkOrder) {
    }
  }, [selectedWorkOrder]);
  return (
    <div className="my-3">
      {loading && <Spinner />}
      <Toast />
      {copyOptions.map((item) => (
        <div key={item} className="d-flex gap-2">
          <input
            type="checkbox"
            checked={selectedOptions[item]}
            onChange={handleOptionSelect}
            name={item}
          />
          <label htmlFor={item}>{item}</label>
        </div>
      ))}
      <div className="text-end">
        <button
          onClick={() => {
            handleDuplicateWO();
          }}
          className="btn btn-success me-2"
        >
          Duplicate
        </button>
        <button
          onClick={() => {
            showModal(false);
          }}
          className="btn btn-secondary"
        >
          Close
        </button>
      </div>
    </div>
  );
}
