import Modal from "react-bootstrap/Modal";
import API from "services/axios";
import authHeader from "services/auth-header";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setNotification } from "features/Notification/notificationSlice";

function CustomerAdd({
  customersData,
  refreshFunc,
  showAddModal,
  setShowAddModal,
  setSelectedId,
  setShowDetailsPage,
}) {
  const dispatch = useDispatch();

  /*   ALL STATES
   ********************************************* */
  const { register, handleSubmit } = useForm();
  const [customerName, setCustomerName] = useState("");
  const [companyError, setCompanyError] = useState(null);
  const [searchData, setSearchData] = useState(null);

  const [loading, setLoading] = useState(false);

  /*   ALL FUNCTIONS
   ********************************************* */
  const addCustomer = async (formData, e) => {
    e.preventDefault();
    setLoading(true);
    const customerId = customersData[customersData?.length - 1]?.customerId
      ? customersData[customersData?.length - 1]?.customerId + 1
      : 1;
    try {
      const { data } = await API.post(
        "/customers",
        {
          data: {
            customerName: customerName,
            customerId,
          },
        },
        { headers: authHeader() }
      );
      if (data.success) {
        const returnData = await refreshFunc();
        setSelectedId(returnData[returnData?.length - 1]?.id);
        setShowDetailsPage(true);
        setCustomerName("");
        setShowAddModal(false);
        setLoading(false);
        return;
      }
      setLoading(false);
    } catch (err) {
      alert(err.message);
      setLoading(false);
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  useEffect(() => {
    setSearchData(customersData);
  }, [customersData]);

  useEffect(() => {
    let searchResults = customersData?.filter((item) =>
      item.customerName.toLowerCase().includes(customerName.toLocaleLowerCase())
    );
    setSearchData(searchResults);
  }, [customerName]);

  useEffect(() => {
    setCompanyError("");
    customersData?.find((item) => {
      if (
        item?.customerName?.toLowerCase() === customerName?.toLowerCase() &&
        customerName !== null
      ) {
        setCompanyError(`${customerName} already exists`);
      }
      if (item?.customerName === customerName) return;
    });
  }, [customersData, customerName]);

  return (
    <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
      <Modal.Body>
        <div className="d-flex justify-content-center flex-column px-2">
          <h3 className="mb-3"> ADD CUSTOMER </h3>
          <form onSubmit={handleSubmit(addCustomer)}>
            {/* Customer Name */}
            <div className="">
              <label className="form-label mb-0">Customer Name</label>
              <input
                placeholder="Enter company name"
                onChange={(e) => setCustomerName(e.target.value)}
                value={customerName}
                type="text"
                required
                className="form-control"
              />
              <p className="text-danger fs-6">{companyError}</p>
            </div>
            {customerName && (
              <div
                className="bg-white px-2 shadow-lg scroll py-2"
                style={{
                  maxHeight: "50%",
                  scrollBehavior: "auto",
                  borderRadius: 10,
                }}
              >
                {customerName &&
                  searchData?.map((item) => (
                    <div key={item.id} className="">
                      <div
                        className={`px-2 rounded py-1 ${
                          item.customerName.toLocaleLowerCase() ===
                          customerName.toLocaleLowerCase()
                            ? "bg-primary bg-opacity-25"
                            : ""
                        }`}
                      >
                        {item?.customerName}
                      </div>
                      {!(item.id === searchData[searchData.length - 1].id) &&
                        !(searchData.length === 1) && <hr className="my-0 " />}
                    </div>
                  ))}
              </div>
            )}

            {/*   Buttons
             ********************************************* */}
            <div className="d-flex justify-content-end align-items-end flex-grow-1 mt-2">
              <div>
                <button
                  type="button"
                  className="btn btn-secondary me-2"
                  onClick={() => setShowAddModal(false)}
                >
                  CLOSE
                </button>

                <button
                  disabled={loading}
                  type="submit"
                  className="btn btn-primary px-3"
                >
                  ADD
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default CustomerAdd;
