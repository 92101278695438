import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import authHeader from 'services/auth-header';
import API from 'services/axios';

function TaxRateUpdate({ data, showUpdatePage, refreshFunc }) {
  /*   All Satates Below
   ********************************************* */
  const { register, handleSubmit } = useForm();
  const [formError, setFormError] = useState({});

  /*   All Functions Below
   ********************************************* */
  const updateTaxRateData = async (formData, e) => {
    setFormError({});
    e.preventDefault();
    if (formData.termName === '') {
      setFormError({
        termName: { type: 'termName', message: 'Location name is required' },
      });
      return;
    }
    try {
      const resp = await API.patch(
        '/taxrate/',
        { data: { ...formData, id: data.id } },
        { headers: authHeader() }
      );

      if (resp.data.message.type === 'success') {
        refreshFunc();
        showUpdatePage(false);
        return;
      }
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <div className='row'>
      <div className='col-12'>
        {/*    Update FORM START
         ********************************************* */}
        {data && (
          <form className='ps-3 pe-3' onSubmit={handleSubmit(updateTaxRateData)}>
            <div className='mb-3'>
              <h3>UPDATE {data?.taxAccount?.toUpperCase()}</h3>
            </div>
            {/* ----------  Fields  ----------- */}
            <div className='row'>
              <div className='col-12'>
                <div className='mb-3'>
                  <label htmlFor='taxAccount' className='form-label mb-0'>
                    Tax Account Number
                  </label>
                  <input
                    className='form-control'
                    type='text'
                    required
                    id='taxAccount'
                    placeholder='Tax Account'
                    defaultValue={data?.taxAccountNumber}
                    {...register('taxAccountNumber')}
                  />
                </div>

                <div className='mb-3'>
                  <label htmlFor='taxRegionName' className='form-label mb-0'>
                    Tax Account Name
                  </label>
                  <input
                    className='form-control'
                    type='text'
                    required
                    id='taxAccountName'
                    placeholder=' Tax Account Name'
                    defaultValue={data?.taxAccountName}
                    {...register('taxAccountName')}
                  />
                </div>
                <div className=''>
                  <label htmlFor='taxRate' className='form-label mb-0'>
                    Tax Rate
                  </label>
                  <input
                    className='form-control'
                    type='text'
                    required
                    id='taxRate'
                    placeholder='Tax Rate'
                    defaultValue={data?.taxRate}
                    {...register('taxRate')}
                  />
                </div>
              </div>
            </div>

            {/*   Buttons
             ********************************************* */}
            <div className={`mt-4 mb-2 d-flex justify-content-end`}>
              <div>
                <button
                  className='btn px-1 btn-secondary'
                  type='button'
                  onClick={() => {
                    showUpdatePage(false);
                  }}
                >
                  Cancel
                </button>
                <button className='btn btn-primary ms-1' type='submit'>
                  UPDATE
                </button>
              </div>
            </div>
          </form>
        )}
        {/*    Update FORM END
         ********************************************* */}
      </div>
    </div>
  );
}

export default TaxRateUpdate;
