import React, { useEffect, useState } from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import { Link } from "react-router-dom";
import api from "services/axios";

function Companies() {
  const [companiesData, setCompaniesData] = useState(null);
  let token = localStorage.getItem("userToken");
  const getCompanies = () => {
    api
      .get("/companies", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        setCompaniesData(res.data);
      });
  };

  const deleteCompanies = (id) => {
    api
      .delete(`/companies/delete/${id}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(alert("Record deleted successfully"))
      .catch((e) => {
        alert(e.message);
      });
  };

  useEffect(() => getCompanies, []);

  return (
    <div className="row">
      <div className="col-12">
        <div className="page-title-box">
          <Breadcrumbs current="Companies Management" />
          <h4 className="page-title">Companies Management</h4>
        </div>
        <div className="card mt-1">
          <div className="card-header">
            <div className="d-flex flex-row-reverse">
              <Link
                to={"/companies/register/"}
                className="action-icon btn btn-primary"
              >
                Register New
              </Link>
            </div>
          </div>
          <div className="card-body">
            <table
              id="companies-datatable"
              className="table dt-responsive nowrap w-100 tab"
            >
              <thead>
                <tr>
                  <th>Email Company id </th>
                  <th>Street 1</th>
                  <th>Street 2</th>
                  <th>City</th>
                  <th>State</th>
                  <th>Zip</th>
                  <th>Country</th>
                  {/* <th>Timezone</th>
              <th>Total Users</th>
              <th>Account Executive id</th>
              <th>Account Manager id</th>
              <th>Preference</th>
              <th>Utm Campaign</th>
              <th>Utm Medium</th>
              <th>Utm Source</th>
              <th>Deactivated at</th>
              <th>Balance</th>
              <th>Credit Balance</th>
              <th>Last Invoice on</th>
              <th>Subscription Plan</th>
              <th>Subscription Cancelled on</th>
              <th>Enabled Features</th>
              <th>Segment Type</th>
              <th>Industry</th>
              <th>Is Active</th>
              <th>Reactivated at</th> */}
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {companiesData &&
                  companiesData.map((company) => {
                    return (
                      <tr key={company.id}>
                        <td>{company.emailcompany_id}</td>
                        <td>{company.street_1}</td>
                        <td>{company.street_2}</td>
                        <td>{company.city}</td>
                        <td>{company.state}</td>
                        <td>{company.zip}</td>
                        <td>{company.country}</td>
                        {/* <td>{company.time_zone}</td>
                    <td>{company.total_num_users}</td>
                    <td>{company.account_executive_id}</td>
                    <td>{company.account_manager_id}</td>
                    <td>{company.preference}</td>
                    <td>{company.utm_campaign}</td>
                    <td>{company.utm_medium}</td>
                    <td>{company.utm_source}</td>
                    <td>{company.deactivated_at}</td>
                    <td>{company.balance}</td>
                    <td>{company.credit_balance}</td>
                    <td>{company.last_invoice_on}</td>
                    <td>{company.subscription_plan}</td>
                    <td>{company.subscription_cancelled_on}</td>
                    <td>{company.enabled_features}</td>
                    <td>{company.segment_type}</td>
                    <td>{company.industry}</td>
                    <td>{company.is_active}</td>
                    <td>{company.reactivated_at}</td> */}
                        <td className="table-action">
                          <Link
                            to={"/companies/update/" + company.id}
                            className="action-icon"
                          >
                            <i
                              className="mdi mdi-pencil"
                              // onClick={() => showUpdateForm(company.id)}
                            ></i>
                          </Link>
                          <a href="" className="action-icon">
                            <i
                              className="mdi mdi-delete"
                              onClick={() => {
                                if (window.confirm("Are you sure?")) {
                                  deleteCompanies(company.id);
                                }
                              }}
                            ></i>
                          </a>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Companies;
