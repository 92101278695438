import Popover from 'components/ToolTip/ToolTip';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import authHeader from 'services/auth-header';
import API from 'services/axios';

function SmsSettingsUpdate({ data, showUpdatePage, refreshFunc }) {
  /*   All Satates Below
   ********************************************* */
  const { register, handleSubmit } = useForm();
  const [formError, setFormError] = useState({});

  const [phone, setPhone] = useState(null);

  /*   All Functions Below
   ********************************************* */

  const updateLocationsData = async (formData, e) => {
    setFormError({});
    e.preventDefault();

    if (formData.apiKey === '') {
      setFormError({
        apiKey: { type: 'apiKey', message: 'Api Key is required' },
      });
      return;
    }

    if (formData.phoneNumber === '') {
      setFormError({
        phoneNumber: { type: 'phoneNumber', message: 'Phone Number is required' },
      });
      return;
    }
    try {
      const resp = await API.patch(
        '/smssettings/',
        { data: { ...formData, id: data.id, phoneNumber: phone } },
        { headers: authHeader() }
      );

      if (resp.data.message.type === 'success') {
        refreshFunc();
        showUpdatePage(false);
        return;
      }
    } catch (err) {
      alert(err.message);
    }
  };

  useEffect(() => {
    setPhone(data?.phoneNumber);
  }, [data]);

  return (
    <div className='row'>
      <div className='col-12'>
        {/*    Update FORM START
         ********************************************* */}
        {data && (
          <form className='ps-3 pe-3' onSubmit={handleSubmit(updateLocationsData)}>
            <div className='mb-3'>
              <h3>UPDATE CONFIGURATION {data?.location_name?.toUpperCase()}</h3>
            </div>
            {/* ----------  Fields  ----------- */}
            <div className='mt-3 mb-3'>
              <label htmlFor='phoneNumber' className='form-label'>
                Telnyx Phone Number <small>( +1 XXXXXXXXXX )</small>
              </label>
              <input
                placeholder='Enter Phone'
                className='form-control'
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
              />
              {formError && formError.phoneNumber && (
                <p className='text-danger'>{formError.phoneNumber.message}</p>
              )}
            </div>
            <div className='mt-3 mb-3'>
              <label htmlFor='apiKey' className='form-label'>
                Telnyx Api Key&nbsp;&nbsp;
                <small>
                  <a
                    href='https://developers.telnyx.com/docs/v2/messaging/quickstarts/portal-setup?lang=node'
                    target={'_blank'}
                  >
                    how to get api key?
                  </a>
                </small>
              </label>
              <input
                className='form-control'
                type='text'
                id='apiKey'
                placeholder='Enter Api Key'
                defaultValue={data?.apiKey}
                {...register('apiKey')}
              />
              {formError && formError.apiKey && (
                <p className='text-danger'>{formError.apiKey.message}</p>
              )}
            </div>
            <div>
              <label htmlFor='apiKey' className='form-label'>
                Webhook URL
              </label>
              <div className='d-flex justify-content-between align-items-center'>
                <input
                  className='form-control'
                  type='text'
                  id='apiKey'
                  value='https://api.brickcloud.io/sms/webhook'
                />
                <div onClick={() => navigator.clipboard('https://api.brickcloud.io/sms/webhook')}>
                  <Popover
                    btn_text={'Copy'}
                    body={'copied'}
                    btn_classes='btn btn-success btn-sm ms-2'
                  />
                </div>
              </div>
            </div>

            <div className={`mt-4 mb-2 d-flex justify-content-end`}>
              <div>
                <button
                  className='btn px-1 btn-secondary'
                  type='button'
                  onClick={() => {
                    showUpdatePage(false);
                  }}
                >
                  Cancel
                </button>
                <button className='btn btn-primary ms-1' type='submit'>
                  UPDATE
                </button>
              </div>
            </div>
          </form>
        )}
        {/*    Update FORM END
         ********************************************* */}
      </div>
    </div>
  );
}

export default SmsSettingsUpdate;
