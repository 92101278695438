import { useEffect, useState } from 'react';

export default function AddBySheet({ formData, addMatrix, updateMatrix }) {
  /*   All States
   ********************************************* */
  const [labelCount, setLabelCount] = useState(1);
  const [qtyLevelCount, setQtyLevelCount] = useState(1);

  const [sheetWidth, setSheetWidth] = useState(0);
  const [sheetHeight, setSheetHeight] = useState(0);
  const [printMargin, setPrintMargin] = useState(0.125);
  const [areaOfSheet, setAreaOfSheet] = useState(0);

  useEffect(() => {
    setAreaOfSheet(sheetWidth * sheetHeight);
  }, [sheetWidth, sheetHeight]);

  let defaultQuantityName = 'Quantity';
  const [columnName, setColumnName] = useState('SqIn');
  const [tableHead, setTableHead] = useState([defaultQuantityName, `1 ${columnName}`]);
  const [tableBody, setTableBody] = useState([
    {
      [`${defaultQuantityName}`]: 1,
      [`1 ${columnName}`]: '',
    },
  ]);

  /*   All Functions
   ********************************************* */
  const handleLabelChange = () => {
    let itemsLength = Object.keys(tableBody[0]).length;
    let labelCountInt = parseFloat(labelCount) + 1;

    if (labelCountInt === itemsLength) return;

    if (labelCountInt < itemsLength) {
      let tableData = tableBody.map((item) => {
        let newItem = {};
        Object.keys(item).forEach((key, index) => {
          let limit = isNaN(parseFloat(key.split(' ')[1])) ? 0 : parseFloat(key.split(' ')[1]);

          if (limit < labelCountInt || key.includes(defaultQuantityName)) {
            newItem[key] = item[key];
          }
        });

        return newItem;
      });
      setTableHead(tableHead.slice(0, labelCountInt));

      return setTableBody(tableData);
    } else {
      let lastCountNumber = itemsLength - 1;

      let tableData = tableBody?.map((item, index) => {
        let newItem = { ...item };
        Array.from(Array(labelCountInt - (lastCountNumber + 1)).keys()).map((item, index) => {
          newItem[`${lastCountNumber + (index + 1)} ${columnName}`] = '';
        });

        return newItem;
      });
      Array.from(Array(labelCountInt - (lastCountNumber + 1)).keys()).map((item, index) => {
        setTableHead((prev) => {
          return [...prev, `${lastCountNumber + (index + 1)} ${columnName}`];
        });
      });

      setTableBody(tableData);
    }
  };

  const handleQtyChange = () => {
    let tableData = [];
    if (tableBody.length > qtyLevelCount) {
      let tableData = tableBody.slice(0, qtyLevelCount);
      return setTableBody(tableData);
    } else {
      Array.from(Array(parseFloat(qtyLevelCount - tableBody.length)).keys()).map((item, index) => {
        let data = {};
        Object.keys(tableBody[0])?.map((item) => {
          data[item] = '';
        });
        tableData.push({ ...data, [`${defaultQuantityName}`]: tableBody.length + (index + 1) });
      });

      setTableBody([...tableBody, ...tableData]);
    }
  };

  const handleCellEdit = (e, count, field) => {
    // Condition :: Value Must be Number and have 2 decimal number
    // Solution  :: converting received value to number and
    //              using method "toFixed()" to get 2 decimal number
    let rawValue = e.target.innerText;
    const getFloatValue = (rawValue) => {
      let value = 0;
      if (rawValue.includes('.')) {
        let decimalSplit = rawValue.split('.');
        value = parseFloat(decimalSplit[0] + '.' + decimalSplit[1].slice(0, 2));
      } else {
        value = parseFloat(e.target.innerText);
      }
      return value;
    }; // =================================================

    // Condition :: Value Must be Number and have 2 decimal number
    // Solution  :: converting received value to number and
    //              using method "toFixed()" to get 2 decimal number

    // let value = Math.round(parseFloat(rawValue) * 100) / 100;

    // Update :: Not using this method because it automatically rounds off
    //           1.565 to 1.57. Need to exclude all number after 2 decimals
    //           placed without rounding off.
    // =================================================

    // Condition :: New Value Must be greater than Previous Value
    // Solution  :: Getting previous Value using combination of parentNode and
    //              FirstChild properties
    let previousCountValue = 0;
    if (e.target === e.target.parentNode.firstChild) {
      previousCountValue =
        e.target?.parentNode?.previousSibling &&
        parseFloat(e.target?.parentNode?.previousSibling?.firstChild?.innerText);
    } /*  ============================================================= */

    const value = getFloatValue(rawValue);

    // Update Value in JSON
    if (!isNaN(value) && value > previousCountValue) {
      const updatedData = tableBody.map((item) => {
        if (item[`${defaultQuantityName}`] === count) {
          return { ...item, [field]: value };
        }
        e.target.innerText = value;
        return item;
      });
      setTableBody(updatedData);
    } else {
      const updatedData = tableBody.map((item) => {
        if (item[`${defaultQuantityName}`] === count) {
          return { ...item, [field]: '' };
        }
        e.target.innerText = value;
        return item;
      });
      setTableBody(updatedData);
      e.target.innerText = '';
    }
  };

  const handleColumnNameChange = (e, columnName) => {
    let newValue = e.target.innerText;
    if (newValue === columnName || tableHead.includes(newValue)) {
      e.target.innerText = columnName;
      return;
    }
    let updatedColumnJson = tableBody?.map((item) => {
      let newItem = { ...item };
      Object.keys(item).map((key) => {
        if (key === columnName && !(newValue === columnName)) {
          newItem[newValue] = item[key];
          delete newItem[key];
        }
      });

      return newItem;
    });

    setTableHead((prev) => {
      prev[prev.indexOf(columnName)] = newValue;
      return prev;
    });

    setTableBody(updatedColumnJson);
  };
  const handleChangeAllColumnName = (colName) => {
    /*   Changing the table Column
     ********************************************* */
    let newArr = [];
    tableHead?.map((item) => {
      if (item != 'Quantity') {
        newArr.push(`${item.split(' ')[0]} ${colName}`);
      }
    });
    setTableHead(['Quantity', ...newArr]);

    /*   Changing the table Body
     ********************************************* */
    const newTableBody = tableBody?.reduce((result, currItem) => {
      let newItem = {};
      Object.keys(currItem).map((key) => {
        if (key === 'Quantity') {
          newItem[key] = currItem[key];
        } else {
          newItem[`${key.split(' ')[0]} ${colName}`] = currItem[key];
        }
      });
      result.push(newItem);

      return result;
    }, []);
    setTableBody(newTableBody);
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    if (formData) {
      let data = formData?.matrixJson ? JSON.parse(formData?.matrixJson) : null;
      if (data) {
        let tHead = data?.tableHead;
        let tbody = data?.tableBody;
        setLabelCount(Object.keys(tbody[0])?.length - 1);
        setQtyLevelCount(tbody?.length);
        setTableHead(tHead);
        setTableBody(tbody);
        setColumnName(tHead[1].split(' ')[1]);
        setSheetWidth(data?.width);
        setSheetHeight(data?.height);
        setPrintMargin(data?.printMargin);
      }
    }
  }, [formData]);

  /*   Temporarily getting values
   ********************************************* */

  const [quantity, setQuantity] = useState(0);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const [totalArea, setTotalArea] = useState('');
  const [noOfSheets, setNoOfSheets] = useState('');

  const [isRotated, setIsRotated] = useState('No');

  const [sheetPrice, setSheetPrice] = useState(0);
  const [logoPrice, setLogoPrice] = useState(0);

  // :: Getting Area of 1 Pcs
  useEffect(() => {
    const getSheetsOFStraightLogo = (paramWidth, paramHeight) => {
      let logoWidth = paramWidth + printMargin;
      let logoHeight = paramHeight + printMargin;

      let noOfColumns = Math.floor(sheetWidth / logoWidth);
      let noOfRows = Math.floor(sheetHeight / logoHeight);

      let extraSpace = sheetHeight - logoHeight * noOfRows;

      let extraRotatedItems = 0;
      if (logoWidth <= extraSpace) {
        let newSheetWidth = sheetWidth;
        let newSheetHeight = extraSpace;

        let newLogoWidth = logoHeight;
        let newLogoHeight = logoWidth;

        let newNoOfColumns = Math.floor(newSheetWidth / newLogoWidth);
        let newNoOfRows = Math.floor(newSheetHeight / newLogoHeight);

        extraRotatedItems = newNoOfRows * newNoOfColumns;
      }

      let totalLogosOfGivenSize = noOfRows * noOfColumns + extraRotatedItems;
      let sheets = Math.ceil(quantity / totalLogosOfGivenSize);

      let roundedOffTotalArea =
        Math.round(parseFloat(logoWidth * logoHeight * quantity) * 100) / 100;
      setTotalArea(roundedOffTotalArea);

      return sheets;
    };

    if (width && height && quantity) {
      let sheets1 = getSheetsOFStraightLogo(width, height);
      let sheets2 = getSheetsOFStraightLogo(height, width);
      if (sheets1 <= sheets2) {
        setNoOfSheets(sheets1);
        getTotalPrice(sheets1);
        setIsRotated('No');
      } else {
        setIsRotated('Yes');
        setNoOfSheets(sheets2);
        getTotalPrice(sheets2);
      }
    }
  }, [width, height, quantity, printMargin, sheetWidth, sheetHeight]);

  const getTotalPrice = (sheets) => {
    // Getting Correct Column Row =================================================================
    let selectedColumn = '';

    for (let i = 1; i < tableHead?.length; i++) {
      if (sheets < parseFloat(tableHead[i].split(' ')[0])) {
        selectedColumn = tableHead[i - 1];
        break;
      }
    }
    if (sheets >= parseFloat(tableHead[tableHead.length - 1].split(' ')[0])) {
      selectedColumn = tableHead[tableHead.length - 1];
    }
    let roundedOffSheetPrice = Math.round(parseFloat(tableBody[0][selectedColumn]) * 100) / 100;
    setSheetPrice(roundedOffSheetPrice);

    let roundedOffLogoPrice =
      Math.round(parseFloat((roundedOffSheetPrice * sheets) / quantity) * 100) / 100;
    setLogoPrice(roundedOffLogoPrice);
  };

  return (
    <div>
      <div className='d-flex'>
        <div className='w-75'>
          {/*   Buttons & Inputs
           ********************************************* */}
          <div className='row'>
            <div className='d-flex gap-2 col-6'>
              <div className=''>
                Width
                <input
                  min={0}
                  step={0.01}
                  value={sheetWidth}
                  onChange={(e) => setSheetWidth(parseFloat(e.target.value))}
                  placeholder='Label'
                  type='number'
                  className={`form-control ${sheetWidth === 0 && 'border-danger'}`}
                />
              </div>
              <div className=''>
                Height
                <input
                  min={0}
                  value={sheetHeight}
                  step={0.01}
                  onChange={(e) => setSheetHeight(parseFloat(e.target.value))}
                  placeholder='Label'
                  type='number'
                  className={`form-control ${sheetHeight === 0 && 'border-danger'}`}
                />
              </div>
              <div className=''>
                Area of Sheet
                <input
                  min={0}
                  value={Math.round(parseFloat(areaOfSheet) * 100) / 100}
                  step={0.01}
                  onChange={(e) => setAreaOfSheet(parseFloat(e.target.value))}
                  placeholder='Label'
                  type='number'
                  className='form-control'
                />
              </div>
            </div>

            <div className='row mt-2'>
              <label className='text-nowrap my-auto col-2'>Columns Name</label>
              <div className='col-2 my-auto'>
                <input
                  value={columnName}
                  onChange={(e) => setColumnName(e.target.value)}
                  placeholder='Label'
                  type='text'
                  className='form-control '
                />
              </div>
              <button
                onClick={() => {
                  handleChangeAllColumnName(columnName);
                }}
                className='col-1 btn btn-sm btn-success my-auto'
              >
                Set
              </button>
            </div>

            <div className='row my-1'>
              <label className='text-nowrap my-auto col-2'>Columns Count</label>
              <div className='col-2 my-auto'>
                <input
                  min={1}
                  value={labelCount}
                  onChange={(e) => setLabelCount(parseInt(e.target.value))}
                  placeholder='Label'
                  type='number'
                  className='form-control'
                />
              </div>
              <button onClick={handleLabelChange} className='col-1 btn btn-sm btn-success my-auto'>
                Set
              </button>
            </div>
            <div className='row'>
              <label className=' my-auto col-2'>Print Margin (0.125)</label>
              <div className='col-4 my-auto'>
                <input
                  min={0}
                  step={0.01}
                  value={printMargin}
                  onChange={(e) => setPrintMargin(parseFloat(e.target.value))}
                  placeholder='Print Margin'
                  type='number'
                  className='form-control'
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className=' bg-white custom-shadow mb-3 px-3 py-3 custom-border-radius '
          style={{ width: '50%' }}
        >
          <div className='d-flex gap-1'>
            <div className='w-50'>
              Logo Quantity
              <input
                name='quantityRow'
                type='number'
                min={1}
                value={quantity}
                onChange={(e) => {
                  setQuantity(parseInt(e.target.value));
                }}
                className='form-control'
              />
            </div>
            <div className='w-50'>
              Width
              <input
                type='number'
                min={1}
                step={0.01}
                max={sheetWidth}
                value={width}
                onChange={(e) => {
                  let value = parseFloat(e.target.value);
                  setWidth(
                    parseFloat(value <= sheetWidth - printMargin ? value : sheetWidth - printMargin)
                  );
                }}
                className='form-control'
              />
            </div>
            <div className='w-50'>
              Height
              <input
                type='number'
                min={1}
                step={0.01}
                value={height}
                max={sheetHeight - printMargin}
                onChange={(e) => {
                  let value = parseFloat(e.target.value);
                  setHeight(
                    parseFloat(
                      value <= sheetHeight - printMargin ? value : sheetHeight - printMargin
                    )
                  );
                }}
                className='form-control'
              />
            </div>
          </div>

          <div className='d-flex gap-1 my-3'>
            <div className='w-50'>
              Total Area
              <input
                name='quantityRow'
                type='number'
                min={1}
                value={totalArea}
                disabled
                readOnly
                className='form-control'
              />
            </div>
            <div className='w-50'>
              IS Rotated ?
              <input
                name='quantityRow'
                type='text'
                value={isRotated}
                disabled
                readOnly
                className='form-control'
              />
            </div>
            <div className='w-50'>
              No of Sheets
              <input
                name='quantityRow'
                type='number'
                min={1}
                value={noOfSheets}
                disabled
                readOnly
                className='form-control'
              />
            </div>
          </div>

          <div className='row mt-2 align-items-center '>
            <div className='col-5'>Single Sheet Price</div>
            <div className='col-7 bg-primary bg-opacity-25  custom-border-radius-sm p-1 text-center'>
              {sheetPrice}
            </div>
          </div>
          <div className='row mt-2 align-items-center'>
            <div className='col-5'>Single Logo Price</div>
            <div className='col-7 bg-primary bg-opacity-25  custom-border-radius-sm p-1 text-center'>
              {logoPrice}
            </div>
          </div>
        </div>
      </div>
      {/*   Matrix Data Table
       ********************************************* */}

      {tableBody && (
        <table className='table table-bordered'>
          <thead className='thead-dark'>
            <tr>
              {tableHead?.map((item) => (
                <th
                  contentEditable={!(item === defaultQuantityName)}
                  onBlur={(e) => {
                    handleColumnNameChange(e, item);
                  }}
                  suppressContentEditableWarning={true}
                  key={item}
                >
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableBody.map((item, index) => (
              <tr key={index}>
                {tableHead?.map((i, index) => (
                  <td
                    suppressContentEditableWarning={true}
                    contentEditable={true}
                    onBlur={(e) => handleCellEdit(e, item[`${defaultQuantityName}`], i)}
                    key={index}
                  >
                    {item[i]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <div className='d-flex justify-content-end'>
        <button
          onClick={() => {
            formData?.id
              ? updateMatrix({
                  data: {
                    ...formData,
                    type: 'By Sheet',
                    matrixJson: JSON.stringify({
                      tableBody,
                      tableHead,
                      width: sheetWidth,
                      height: sheetHeight,
                      printMargin,
                    }),
                  },
                })
              : addMatrix({
                  data: {
                    ...formData,
                    type: 'By Sheet',
                    matrixJson: JSON.stringify({
                      tableBody,
                      tableHead,
                      width: sheetWidth,
                      height: sheetHeight,
                      printMargin,
                    }),
                  },
                });
          }}
          className='btn btn-primary'
        >
          {formData?.id ? 'Update' : 'Add'}
        </button>
      </div>
    </div>
  );
}
