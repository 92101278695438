import React, { useEffect, useState } from 'react';

import Breadcrumbs from '../components/Breadcrumbs';
import Table from 'components/DataTable/Table';
import API from 'services/axios';
import authHeader from 'services/auth-header';
import Popover from 'components/ToolTip/ToolTip';
import { Dropdown } from 'react-bootstrap';
import { setNotification } from '../features/Notification/notificationSlice';
import { useDispatch } from 'react-redux';

function Tracking() {
  const dispatch = useDispatch();
  /*   All States
   ********************************************* */
  const [trackingData, setTrackingData] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showDateFilter, setShowDateFilter] = useState(false);

  const [startDate, setStartDate] = useState(
    new Date().toISOString().slice(0, 10).replace('T', ' ')
  );
  const [endDate, setEndDate] = useState(null);

  const [filterBy, setFilterBy] = useState(null);

  /*   All Functions
   ********************************************* */
  const getTrackingData = async () => {
    // getting today date and two month old date
    setTrackingData(null);
    try {
      const { data } = await API.post(
        '/trackings',
        { data: { startDate, endDate } },
        { headers: authHeader() }
      );
      setTrackingData(data);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: 'error',
        })
      );
    }
  };

  // sets the Inital endDate to prior two monts
  const setInitalDate = () => {
    const today = new Date();
    const twoMonthsAgo = new Date();
    twoMonthsAgo.setMonth(today.getMonth() - 2);
    setEndDate(twoMonthsAgo.toISOString().slice(0, 10).replace('T', ' '));
  };

  // Adds the selected row to list of selected rows
  const handleRowClick = (row) => {
    setSelectedRows(
      selectedRows.includes(row)
        ? selectedRows.filter((selectedRow) => selectedRow !== row)
        : [...selectedRows, row]
    );
  };

  // redirects to tracking page using tracking
  // number in selected rows
  const handleTracking = () => {
    if (selectedRows.length === 1) {
      window.open(
        `https://wwwapps.ups.com/WebTracking?loc=en_US&TypeOfInquiryNumber=T&Requester=WS&InquiryNumber1=${selectedRows[0]}&/trackdetails`,
        '_blank',
        'noreferrer'
      );
    } else if (selectedRows.length > 1) {
      let trackingCount = '';
      for (let i = 0; i < selectedRows.length; i++) {
        trackingCount += `InquiryNumber${i + 1}=${selectedRows[i]}&`;
      }
      window.open(
        `https://wwwapps.ups.com/WebTracking?loc=en_US&TypeOfInquiryNumber=T&Requester=WS&${trackingCount}`,
        '_blank',
        'noreferrer'
      );
    }
  };

  const handleCopyInfo = () => {
    let copyData = `
${selectedData?.trackingId}
${selectedData?.serviceType}
${selectedData?.companyName}
${selectedData?.attention}
${selectedData?.address_1}
${selectedData?.address_2}
${selectedData?.address_3}
${selectedData?.city}
${selectedData?.state}
${selectedData?.zipcode}
${selectedData?.country}
    `;
    navigator.clipboard.writeText(copyData);
  };

  /*   Table Functions
   ********************************************* */
  const TrackButton = () => (
    <button
      disabled={selectedRows.length === 0 ? true : false}
      className='btn btn-primary mx-2'
      onClick={handleTracking}
    >
      Track
    </button>
  );
  const buttons = () => (
    <div className=' d-flex justify-content-end '>
      {/* Filter Button */}

      <Dropdown className='d-inline mx-2' show={showDateFilter} autoClose={false}>
        <Dropdown.Toggle
          onClick={() => setShowDateFilter(!showDateFilter)}
          className='btn btn-success mx-2'
          id='dropdown-autoclose-false'
        >
          Filter by Date
        </Dropdown.Toggle>

        <Dropdown.Menu className='bg-white shadow-lg pt-3 rounded mt-1'>
          <div className='m-2 my-1 ' style={{ minWidth: 250 }}>
            {/* From */}
            <div className='row'>
              <div className='col-3  d-flex justify-content-start ms-1 align-items-center '>
                <div className=''>FROM</div>
              </div>
              <div className='col-8 px-0'>
                <input
                  className='form-control'
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  type={'date'}
                />
              </div>
            </div>
            {/* to */}
            <div className='row mt-2'>
              <div className='col-3  d-flex justify-content-start ms-1 align-items-center '>
                <div className=''>TO</div>
              </div>
              <div className='col-8 px-0'>
                <input
                  className='form-control'
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  type={'date'}
                />
              </div>
            </div>
            <div className='d-flex justify-content-end mt-2 '>
              <button
                onClick={() => {
                  getTrackingData();
                  setShowDateFilter(!showDateFilter);
                }}
                className='btn btn-success mb-1 btn-sm mx-2'
              >
                FILTER
              </button>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
      {/* FilterBy */}
      <button
        type='button'
        className='px-1 bg-primary bg-opacity-10 border border-primary rounded-end mx-2 rounded-start'
        data-bs-toggle='dropdown'
        aria-haspopup='true'
        aria-expanded='false'
      >
        {filterBy ? filterBy.toUpperCase().replace('_', ' ') : 'Filter by All'}
      </button>
      <div className='dropdown-menu'>
        <a onClick={() => setFilterBy(null)} className='dropdown-item' role={'button'}>
          Filter by All
        </a>
        <a onClick={() => setFilterBy('trackingId')} className='dropdown-item' role={'button'}>
          Tracking ID
        </a>
        <a onClick={() => setFilterBy('serviceType')} className='dropdown-item' role={'button'}>
          Service Type
        </a>
        <a onClick={() => setFilterBy('companyName')} className='dropdown-item' role={'button'}>
          Company Name
        </a>
        <a onClick={() => setFilterBy('attention')} className='dropdown-item' role={'button'}>
          Attention
        </a>
        <a onClick={() => setFilterBy('address_1')} className='dropdown-item' role={'button'}>
          Address 1
        </a>
        <a onClick={() => setFilterBy('address_2')} className='dropdown-item' role={'button'}>
          Address 2
        </a>
        <a onClick={() => setFilterBy('address_3')} className='dropdown-item' role={'button'}>
          Address 3
        </a>
        <a onClick={() => setFilterBy('city')} className='dropdown-item' role={'button'}>
          City
        </a>
        <a onClick={() => setFilterBy('state')} className='dropdown-item' role={'button'}>
          State
        </a>
        <a onClick={() => setFilterBy('zip')} className='dropdown-item' role={'button'}>
          Zip
        </a>
        <a onClick={() => setFilterBy('country')} className='dropdown-item' role={'button'}>
          Country
        </a>
        <a onClick={() => setFilterBy('voidData')} className='dropdown-item' role={'button'}>
          Void
        </a>
        <a onClick={() => setFilterBy('reference_no')} className='dropdown-item' role={'button'}>
          Reference No
        </a>
        <a onClick={() => setFilterBy('weight')} className='dropdown-item' role={'button'}>
          Weight
        </a>
        <a onClick={() => setFilterBy('no_of_packages')} className='dropdown-item' role={'button'}>
          Total Packages
        </a>
        <a
          onClick={() => setFilterBy('shipment_charges')}
          className='dropdown-item'
          role={'button'}
        >
          Shipment Cost
        </a>
        <a onClick={() => setFilterBy('serviceType')} className='dropdown-item' role={'button'}>
          Service Type
        </a>
      </div>
    </div>
  );
  const table_head = [
    'ID',
    'Notify',
    'Void Data',
    'Tracking ID',
    'Service Type',
    'Company Name',
    'Attention',
  ];

  const table_body = (item, index, data) => (
    <tr
      key={item.id}
      onClick={() => {
        setSelectedData(item);
        handleRowClick(item.trackingId);
      }}
      className={`${item?.voidData == 'Y' ? 'text-danger' : null} ${
        index != 0 ? (data[index - 1].voidData == 'Y' ? 'text-danger' : null) : null
      } ${selectedRows.includes(item.trackingId) ? 'bg-primary bg-opacity-25' : ''}`}
    >
      <td className='py-1  align-middle'>
        <div className='form-check form-check-inline'>
          <input
            type='checkbox'
            className='form-check-input'
            id='customCheck3'
            checked={selectedRows.includes(item.trackingId) ? true : false}
          />
          {item.id}
        </div>
      </td>
      <td className='py-1  align-middle text-center'>{item.notify == '' ? item.notify : '-'} </td>
      <td className='py-1  align-middle'>{item.voidData}</td>
      <td
        className='py-1  align-middle'
        onDoubleClick={() => {
          window.open(
            `https://wwwapps.ups.com/WebTracking?loc=en_US&TypeOfInquiryNumber=T&Requester=WS&InquiryNumber1=${item.trackingId}&/trackdetails`,
            '_blank',
            'noreferrer'
          );
        }}
      >
        {item.trackingId}
      </td>
      <td className='py-1  align-middle'>{item.serviceType} </td>

      <td className='py-1  align-middle'>{item.companyName} </td>
      <td className='py-1  align-middle'>{item.attention} </td>
    </tr>
  );

  useEffect(() => {
    setInitalDate();
    getTrackingData();
  }, [filterBy]);

  /*   test
   ********************************************* */

  return (
    <div className='row'>
      <div className='col-12'>
        <div className='row'>
          <div className='col-12 '>
            <div className='d-flex justify-content-between mt-3 me-2' style={{ marginLeft: 260 }}>
              {/*    Table Card
               ********************************************* */}
              <div className='flex-grow-1 px-2 pb-2  rounded '>
                <div className='horizontal-scrollable'>
                  {trackingData && (
                    <Table
                      filterBy={filterBy}
                      btn1={TrackButton}
                      buttons={buttons}
                      table_head={table_head}
                      table_body={table_body}
                      table_data={trackingData}
                    />
                  )}
                </div>
              </div>

              {/*    Details Card
               ********************************************* */}
              {selectedData && (
                <div className=''>
                  {/*   ------------ Tracking Info -----------  */}
                  <div className='flex-grow-1 bg-white shadow-lg p-3 pt-2 ms-3 mb-3 rounded'>
                    <div className='' onClick={handleCopyInfo}>
                      <Popover
                        btn_text={'COPY INFORMATION'}
                        body={'copied'}
                        btn_classes={'btn btn-success w-100 mb-1'}
                      />
                    </div>
                    <div className=''>
                      <h3 className=''>SHIPMENT INFO</h3>
                    </div>

                    {/* ID */}
                    <div className='d-flex justify-content-between align-items-center '>
                      <div className='me-2'>
                        <b>ID :</b>
                      </div>
                      <div className='d-flex align-items-center justify-content-between flex-grow-1'>
                        {selectedData && (
                          <div
                            onDoubleClick={() => navigator.clipboard.writeText(selectedData?.id)}
                          >
                            <Popover
                              btn_text={selectedData.id}
                              body={'copied'}
                              btn_classes={'user-select-none'}
                              doubleClick={true}
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Tracking ID */}
                    <div className='d-flex justify-content-between align-items-center '>
                      <div className='me-2'>
                        <b>Tracking ID :</b>
                      </div>
                      <div className='d-flex align-items-center justify-content-between flex-grow-1'>
                        <div
                          onDoubleClick={() =>
                            navigator.clipboard.writeText(selectedData?.trackingId)
                          }
                        >
                          <Popover
                            btn_text={selectedData?.trackingId ? selectedData.trackingId : '-'}
                            body={'copied'}
                            btn_classes={'user-select-none'}
                            doubleClick={true}
                          />
                        </div>
                      </div>
                    </div>
                    {/* Void  */}
                    <div className='d-flex justify-content-between align-items-center '>
                      <div className='me-2'>
                        <b>Void :</b>
                      </div>
                      <div className='d-flex align-items-center justify-content-between flex-grow-1'>
                        <div
                          onDoubleClick={() =>
                            navigator.clipboard.writeText(selectedData?.voidData)
                          }
                        >
                          <Popover
                            btn_text={selectedData?.voidData ? selectedData.voidData : '-'}
                            body={'copied'}
                            btn_classes={'user-select-none'}
                            doubleClick={true}
                          />
                        </div>
                      </div>
                    </div>
                    {/* Refference  */}
                    <div className='d-flex justify-content-between align-items-center '>
                      <div className='me-2'>
                        <b>Reference No :</b>
                      </div>
                      <div className='d-flex align-items-center justify-content-between flex-grow-1'>
                        <div
                          onDoubleClick={() =>
                            navigator.clipboard.writeText(selectedData?.reference_no)
                          }
                        >
                          <Popover
                            btn_text={selectedData?.reference_no ? selectedData.reference_no : '-'}
                            body={'copied'}
                            btn_classes={'user-select-none'}
                            doubleClick={true}
                          />
                        </div>
                      </div>
                    </div>
                    {/* Weight  */}
                    <div className='d-flex justify-content-between align-items-center '>
                      <div className='me-2'>
                        <b>Weight :</b>
                      </div>
                      <div className='d-flex align-items-center justify-content-between flex-grow-1'>
                        <div
                          onDoubleClick={() => navigator.clipboard.writeText(selectedData?.weight)}
                        >
                          <Popover
                            btn_text={selectedData?.weight ? selectedData.weight : '-'}
                            body={'copied'}
                            btn_classes={'user-select-none'}
                            doubleClick={true}
                          />
                        </div>
                      </div>
                    </div>
                    {/* No of Packages  */}
                    <div className='d-flex justify-content-between align-items-center '>
                      <div className='me-2'>
                        <b>Total packages :</b>
                      </div>
                      <div className='d-flex align-items-center justify-content-between flex-grow-1'>
                        <div
                          onDoubleClick={() =>
                            navigator.clipboard.writeText(selectedData?.no_of_packages)
                          }
                        >
                          <Popover
                            btn_text={
                              selectedData?.no_of_packages ? selectedData.no_of_packages : '-'
                            }
                            body={'copied'}
                            btn_classes={'user-select-none'}
                            doubleClick={true}
                          />
                        </div>
                      </div>
                    </div>
                    {/* Shipment Cost  */}
                    <div className='d-flex justify-content-between align-items-center '>
                      <div className='me-2'>
                        <b>Shipment Cost :</b>
                      </div>
                      <div className='d-flex align-items-center justify-content-between flex-grow-1'>
                        <div
                          onDoubleClick={() =>
                            navigator.clipboard.writeText(selectedData?.shipment_charges)
                          }
                        >
                          <Popover
                            btn_text={
                              selectedData?.shipment_charges ? selectedData.shipment_charges : '-'
                            }
                            body={'copied'}
                            btn_classes={'user-select-none'}
                            doubleClick={true}
                          />
                        </div>
                      </div>
                    </div>
                    {/* Service type  */}
                    <div className='d-flex justify-content-between align-items-center '>
                      <div className='me-2'>
                        <b>Service Type :</b>
                      </div>
                      <div className='d-flex align-items-center justify-content-between flex-grow-1'>
                        <div
                          onDoubleClick={() =>
                            navigator.clipboard.writeText(selectedData?.serviceType)
                          }
                        >
                          <Popover
                            btn_text={selectedData?.serviceType ? selectedData.serviceType : '-'}
                            body={'copied'}
                            btn_classes={'user-select-none'}
                            doubleClick={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*   ------------ Addess Info -----------  */}
                  <div className='flex-grow-1 bg-white shadow-lg p-3 pt-2 ms-3 rounded'>
                    <div className=''>
                      <h3 className='mb-2'>ADRESSES</h3>
                    </div>

                    {/* Company Name */}
                    <div className='d-flex justify-content-between align-items-center '>
                      <div className='me-2'>
                        <b>Company Name :</b>
                      </div>
                      <div className='d-flex align-items-center justify-content-between flex-grow-1'>
                        <div
                          onDoubleClick={() =>
                            navigator.clipboard.writeText(selectedData?.companyName)
                          }
                        >
                          <Popover
                            btn_text={selectedData?.companyName ? selectedData.companyName : '-'}
                            body={'copied'}
                            btn_classes={'user-select-none'}
                            doubleClick={true}
                          />
                        </div>
                      </div>
                    </div>
                    {/* Attenction Name */}
                    <div className='d-flex justify-content-between align-items-center mb-1'>
                      <div className='me-2'>
                        <b>Attention :</b>
                      </div>
                      <div className='d-flex align-items-center justify-content-between flex-grow-1'>
                        <div
                          onDoubleClick={() =>
                            navigator.clipboard.writeText(selectedData?.attention)
                          }
                        >
                          <Popover
                            btn_text={selectedData?.attention ? selectedData.attention : '-'}
                            body={'copied'}
                            btn_classes={'user-select-none'}
                            doubleClick={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='d-flex flex-column '>
                      <div className='d-flex justify-content-between align-items-center '>
                        <div className='me-2'>
                          <b>Address 1 :</b>
                        </div>
                        <div className='d-flex align-items-center justify-content-between flex-grow-1'>
                          <div
                            onDoubleClick={() =>
                              navigator.clipboard.writeText(selectedData?.address_1)
                            }
                          >
                            <Popover
                              btn_text={selectedData?.address_1 ? selectedData.address_1 : '-'}
                              body={'copied'}
                              btn_classes={'user-select-none'}
                              doubleClick={true}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='d-flex justify-content-between align-items-center '>
                        <div className='me-2'>
                          <b>Address 2 :</b>
                        </div>
                        <div className='d-flex align-items-center justify-content-between flex-grow-1'>
                          <div
                            onDoubleClick={() =>
                              navigator.clipboard.writeText(selectedData?.address_2)
                            }
                          >
                            <Popover
                              btn_text={selectedData?.address_2 ? selectedData.address_2 : '-'}
                              body={'copied'}
                              btn_classes={'user-select-none'}
                              doubleClick={true}
                            />
                          </div>
                        </div>
                      </div>

                      <div className='d-flex justify-content-between align-items-center mb-1'>
                        <div className='me-2'>
                          <b>Address 3 :</b>
                        </div>
                        <div className='d-flex align-items-center justify-content-between flex-grow-1'>
                          <div
                            onDoubleClick={() =>
                              navigator.clipboard.writeText(selectedData?.address_3)
                            }
                          >
                            <Popover
                              btn_text={selectedData?.address_3 ? selectedData.address_3 : '-'}
                              body={'copied'}
                              btn_classes={'user-select-none'}
                              doubleClick={true}
                            />
                          </div>
                        </div>
                      </div>
                      {/* City */}
                      <div className='d-flex justify-content-between align-items-center '>
                        <div className='me-2'>
                          <b>City :</b>
                        </div>
                        <div className='d-flex align-items-center justify-content-between flex-grow-1'>
                          <div
                            onDoubleClick={() => navigator.clipboard.writeText(selectedData?.city)}
                          >
                            <Popover
                              btn_text={selectedData?.city ? selectedData.city : '-'}
                              body={'copied'}
                              btn_classes={'user-select-none'}
                              doubleClick={true}
                            />
                          </div>
                        </div>
                      </div>
                      {/* state */}
                      <div className='d-flex justify-content-between align-items-center '>
                        <div className='me-2'>
                          <b>State :</b>
                        </div>
                        <div className='d-flex align-items-center justify-content-between flex-grow-1'>
                          <div
                            onDoubleClick={() => navigator.clipboard.writeText(selectedData?.state)}
                          >
                            <Popover
                              btn_text={selectedData?.state ? selectedData.state : '-'}
                              body={'copied'}
                              btn_classes={'user-select-none'}
                              doubleClick={true}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='d-flex justify-content-between align-items-center '>
                        <div className='me-2'>
                          <b>Zip Code :</b>
                        </div>
                        <div className='d-flex align-items-center justify-content-between flex-grow-1'>
                          <div
                            onDoubleClick={() =>
                              navigator.clipboard.writeText(selectedData?.zipcode)
                            }
                          >
                            <Popover
                              btn_text={selectedData?.zipcode ? selectedData.zipcode : '-'}
                              body={'copied'}
                              btn_classes={'user-select-none'}
                              doubleClick={true}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='d-flex justify-content-between align-items-center '>
                        <div className='me-2'>
                          <b>Country :</b>
                        </div>
                        <div className='d-flex align-items-center justify-content-between flex-grow-1'>
                          <div
                            onDoubleClick={() =>
                              navigator.clipboard.writeText(selectedData?.country)
                            }
                          >
                            <Popover
                              btn_text={selectedData?.country ? selectedData.country : '-'}
                              body={'copied'}
                              btn_classes={'user-select-none'}
                              doubleClick={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tracking;
