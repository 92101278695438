import Spinner from "components/Spinner";
import { useRef, useState } from "react";
import authHeader from "services/auth-header";
import API from "services/axios";
import * as xlsx from "xlsx";

function ImportCustomersExcel({
  allCustomersData,
  refreshFunc,
  setShowImportForm,
}) {
  /*   ALl States
   ********************************************* */
  const [customerData, setCustomerData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [duplicateName, setDuplicateName] = useState([]);

  const inputRef = useRef();

  /*   All functions
   ********************************************* */
  const handleFileImport = (e) => {
    setDuplicateName([]);
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = xlsx.read(data, { type: "binary" });
      const jsonData = xlsx.utils.sheet_to_json(
        workbook.Sheets["customerData"]
      );
      if (
        jsonData[0]?.customerName === null ||
        jsonData[0]?.customerName === undefined ||
        jsonData[0]?.customerName == ""
      ) {
        alert("No Customer Name added");
        inputRef.current.value = null;
        setCustomerData(null);
        setDuplicateName([]);
        return;
      }
      let allExistingCustomerNames = allCustomersData?.map((item) =>
        item?.customerName?.toLowerCase()
      );
      jsonData?.map((item) => {
        if (
          allExistingCustomerNames.includes(item.customerName?.toLowerCase())
        ) {
          setDuplicateName((prev) => [...prev, item.customerName]);
          return item.customerName;
        }
      });

      setCustomerData(jsonData);
    };
  };

  const addCustomerFromFile = async () => {
    setIsLoading(true);
    const customerId = allCustomersData[allCustomersData?.length - 1]
      ?.customerId
      ? allCustomersData[allCustomersData?.length - 1]?.customerId + 1
      : 1;

    try {
      let data = await Promise.all(
        customerData?.map(async (item, index) => {
          await API.post(
            "/customers",
            {
              data: { ...item, customerId: customerId + index, isActive: true },
            },
            { headers: authHeader() }
          );
        })
      );
      refreshFunc();
      setShowImportForm(false);
      return;
    } catch (err) {
      setIsLoading(false);
      alert(err.message);
    }
  };

  return (
    <div>
      <div className="">
        <h4 className="">IMPORT DATA FILE</h4>
      </div>
      <hr className="mb-3 mt-0" />

      <div className="d-flex">
        <input
          ref={inputRef}
          type="file"
          className="form-control"
          onChange={handleFileImport}
        />
        <div className="d-flex">
          <button
            onClick={() => {
              inputRef.current.value = null;
              setCustomerData(null);
              setDuplicateName([]);
            }}
            className="btn btn-danger ms-1"
          >
            CLEAR
          </button>
        </div>
      </div>

      {isLoading && <Spinner />}
      {/* {customerData && customerData[0]?.customerName} */}
      {duplicateName.length > 0 &&
        duplicateName?.map((item) => (
          <div className="mt-2" key={item}>
            Customer Name : <span className="text-danger">{item}</span> already
            Exists
          </div>
        ))}

      {/*   Buttons
       ********************************************* */}
      <div className="mt-3 flex-grow-1 d-flex justify-content-end align-items-end">
        <div>
          <button
            onClick={() => {
              setShowImportForm(false);
            }}
            type="button"
            className="btn btn-secondary me-2 px-1"
          >
            CLOSE
          </button>
          <button
            disabled={!duplicateName.length == 0 || !customerData}
            onClick={addCustomerFromFile}
            className="btn btn-primary"
          >
            ADD
          </button>
        </div>
      </div>
    </div>
  );
}

export default ImportCustomersExcel;
