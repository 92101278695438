import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import Modal from "react-bootstrap/Modal";

export default function GeneralDataSizes({
  updateGeneralData,
  updateFunc,
  generalData,
  isViewOnly,
}) {
  /*   All States
   ********************************************* */
  const [allSizes, setAllSizes] = useState([]);
  const [allPrice, setAllPrice] = useState(0);

  const [isUpdateMode, setIsUpdateMode] = useState(true);
  const [showAddModal, setShowAddModal] = useState(false);
  const [updatePriceModal, setUpdatePriceModal] = useState(false);
  const [updateData, setUpdateData] = useState(null);

  const [selectedIndex, setSelectedIndex] = useState(null);

  const [sizeData, setSizeData] = useState({
    size: "",
    quantity: 0,
    price: 0,
  });

  /*   All Functions
   ********************************************* */
  const handleAddSize = (e) => {
    e.preventDefault();
    let data = [
      ...allSizes,
      {
        ...sizeData,
        id: uuid(),
        sortId: allSizes.length ? allSizes[allSizes.length - 1].sortId + 1 : 1,
      },
    ];
    setAllSizes(data);
    updateGeneralData(data);

    setShowAddModal(false);
    resetSizeForm();
  };

  const handleUpdateSize = async (e) => {
    e.preventDefault();
    let newArray = [];
    allSizes?.map((item, index) => {
      if (updateData.id === item.id) {
        newArray.push({ ...sizeData, id: item.id, sortId: updateData.sortId });
      } else {
        newArray.push(item);
      }
    });

    setAllSizes(newArray);
    updateGeneralData(newArray);
    await resetSizeForm();
    setShowAddModal(false);
  };

  const resetSizeForm = async () => {
    await setUpdateData(null);
    await setIsUpdateMode(false);

    await setSizeData({
      size: "",
      quantity: 0,
      price: 0,
      cost: 0,
    });
  };

  const handleUpdateAllSize = async (e) => {
    e.preventDefault();
    let updatedArray = allSizes.reduce((acc, cur) => {
      let updatedPriceArray = { ...cur, price: allPrice };
      acc.push(updatedPriceArray);
      return acc;
    }, []);
    setAllSizes(updatedArray);

    let updatedGeneralData = updateGeneralData(updatedArray);
    setAllPrice(0);
    await updateFunc(e, updatedGeneralData);
    setUpdatePriceModal(false);
  };
  const sortUp = (index) => {
    let newData = allSizes;
    let tempData = newData[index - 1].sortId;
    newData[index - 1].sortId = newData[index].sortId;
    newData[index].sortId = tempData;
    setAllSizes(newData);
    updateGeneralData(newData);
  };

  const sortDown = (index) => {
    let newData = allSizes;
    let tempData = newData[index + 1].sortId;
    newData[index + 1].sortId = newData[index].sortId;
    newData[index].sortId = tempData;
    setAllSizes(newData);
    updateGeneralData(newData);
  };

  const sortArrBySortId = (arr) => {
    let sortedArr = arr?.sort((a, b) => a.sortId - b.sortId);
    return sortedArr;
  };

  const deleteSize = (id) => {
    setAllSizes((prev) => {
      const filteredArray = prev.filter((pre) => pre.id !== id);

      // loop through allSizes and reset sortId ascending order
      let sortedArr = filteredArray?.map((item, i) => {
        return { ...item, sortId: i + 1 };
      });

      updateGeneralData(sortedArr);
      return filteredArray;
    });
  };

  const prevSize = () => {
    if (selectedIndex == 0) return;
    setSelectedIndex((pre) => pre - 1);
  };
  const nextSize = () => {
    if (selectedIndex == allSizes?.length - 1) return;
    setSelectedIndex((pre) => pre + 1);
  };

  /*   All UseEffects
   ********************************************* */

  useEffect(() => {
    let selectedSize = allSizes[selectedIndex];
    setSizeData({
      size: selectedSize?.size?.toUpperCase(),
      quantity: selectedSize?.quantity,
      price: selectedSize?.price,
    });
  }, [allSizes, selectedIndex, updateData]);

  useEffect(() => {
    setAllSizes((prev) =>
      generalData?.productSizes
        ? typeof generalData?.productSizes === "string"
          ? JSON.parse(generalData?.productSizes)
          : generalData?.productSizes
        : []
    );
  }, [generalData]);

  return (
    <div className="w-50  flex-grow-1 pb-2 d-flex flex-column">
      <div
        className="bg-white shadow-lg px-3 mb-2 me-2 d-flex flex-column py-2"
        style={{ borderRadius: 15, height: 280 }}
      >
        <div className="" style={{ overflowY: "scroll", height: "99%" }}>
          <div className="table-responsive  ">
            <table className="table ">
              <thead>
                <tr>
                  <th scope="col">Size</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Price</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {sortArrBySortId(allSizes)?.map((item, i) => (
                  <tr key={i}>
                    <td className="py-0">{item?.size?.toUpperCase()}</td>
                    <td className="py-0">{item?.quantity}</td>
                    <td className="py-0">
                      $ {parseFloat(item?.price).toFixed(2)}
                    </td>
                    <td className="py-0">
                      {!isViewOnly && (
                        <>
                          <div className="action-icon">
                            <div
                              onClick={() => {
                                item.sortId != 1 && sortUp(i);
                              }}
                              className="action-icon"
                            >
                              <i className="mdi mdi-chevron-double-up"></i>
                            </div>
                            <div
                              onClick={() => {
                                item.sortId != allSizes?.length && sortDown(i);
                              }}
                              className="action-icon"
                            >
                              <i className="mdi mdi-chevron-double-down"></i>
                            </div>
                            <i
                              onClick={() => {
                                setUpdateData(item);
                                setIsUpdateMode(true);
                                setSelectedIndex(i);
                                setShowAddModal(true);
                              }}
                              className="mdi mdi-pencil"
                            ></i>
                          </div>
                          <div
                            onClick={() => deleteSize(item.id)}
                            className="action-icon"
                          >
                            <i className="mdi mdi-delete"></i>
                          </div>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="d-flex">
        <div
          className="d-flex justify-content-evenly flex-grow-1 py-1 align-items-center bg-white shadow-lg me-2"
          style={{ borderRadius: 15 }}
        >
          <div>
            Total Qty
            <span className="border ms-1 px-2">
              {parseFloat(generalData?.totalQuantity)}
            </span>
          </div>
          <div>
            Total Price
            <span className="border ms-1 px-2">
              $ {parseFloat(generalData?.totalPrice).toFixed(2)}
            </span>
          </div>
        </div>
        {!isViewOnly && (
          <>
            <div className="d-flex">
              <button
                onClick={(e) => {
                  setUpdatePriceModal(true);
                }}
                className="btn btn-primary me-1"
                style={{ borderRadius: 10 }}
                type="button"
              >
                Update Price
              </button>
            </div>
            <div className="d-flex">
              <button
                onClick={() => {
                  resetSizeForm();
                  setShowAddModal(true);
                }}
                className="btn btn-primary"
                style={{ borderRadius: 10 }}
                type="button"
              >
                Add Sizes
              </button>
            </div>
          </>
        )}
      </div>
      {/*   ADD Size Modal
       ********************************************* */}
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Body className="bg-white shadow-lg" style={{ borderRadius: 15 }}>
          <div className="mb-13 d-flex justify-content-between mb-2">
            <h4>{isUpdateMode ? "UPDATE" : "ADD"} SIZES</h4>
            {isUpdateMode && (
              <div>
                <button
                  onClick={() => {
                    prevSize();
                  }}
                  type="button"
                  className={"btn btn-success btn-sm p-0 px-1 mx-1"}
                >
                  <i className="mdi mdi-arrow-left-bold fs-3 py-0 my-0"></i>
                </button>
                <button
                  onClick={() => {
                    nextSize();
                  }}
                  type="button"
                  className={"btn btn-success btn-sm p-0 px-1"}
                >
                  <i className="mdi mdi-arrow-right-bold fs-3 py-0 my-0"></i>
                </button>
              </div>
            )}
          </div>
          <hr className="mt-0" />
          <form>
            {/* sizeData */}
            {sizeData ? (
              <>
                <div className="row mb-1">
                  <label className="col-2 my-auto">Size</label>
                  <div className="col-10 ">
                    <input
                      type="text"
                      value={sizeData?.size}
                      autoFocus
                      required
                      onChange={(e) => {
                        setSizeData({
                          ...sizeData,
                          size: e.target.value?.toUpperCase(),
                        });
                      }}
                      className="form-control"
                      placeholder="Enter Size"
                    />
                  </div>
                </div>
                <div className="row mb-1">
                  <label className="col-2 my-auto">Quantity</label>
                  <div className="col-10 ">
                    <input
                      type="number"
                      min={0}
                      step={1}
                      required
                      value={sizeData?.quantity}
                      onChange={(e) => {
                        setSizeData({
                          ...sizeData,
                          quantity: e.target.value,
                          cost:
                            parseInt(sizeData?.price) *
                            parseInt(e.target.value),
                        });
                      }}
                      className="form-control"
                      placeholder="Enter Quantity"
                    />
                  </div>
                </div>
                <div className="row mb-1">
                  <label className="col-2 my-auto">Price</label>
                  <div className="col-10 ">
                    <input
                      type="text"
                      required
                      value={sizeData?.price}
                      onChange={(e) => {
                        setSizeData({
                          ...sizeData,
                          price: e.target.value,
                          cost:
                            parseFloat(e.target.value) *
                            parseFloat(sizeData?.quantity),
                        });
                      }}
                      className="form-control"
                      placeholder="Enter Price"
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="row mb-1">
                  <label className="col-2 my-auto">Size</label>
                  <div className="col-10 ">
                    <input
                      type="text"
                      value={sizeData?.size}
                      required
                      onChange={(e) => {
                        setSizeData({ ...sizeData, size: e.target.value });
                      }}
                      className="form-control"
                      placeholder="Enter Size"
                    />
                  </div>
                </div>
                <div className="row mb-1">
                  <label className="col-2 my-auto">Quantity</label>
                  <div className="col-10 ">
                    <input
                      type="number"
                      min={0}
                      step={1}
                      required
                      value={sizeData?.quantity}
                      onChange={(e) => {
                        setSizeData({
                          ...sizeData,
                          quantity: e.target.value,
                          cost:
                            parseInt(sizeData?.price) *
                            parseInt(e.target.value),
                        });
                      }}
                      className="form-control"
                      placeholder="Enter Quantity"
                    />
                  </div>
                </div>
                <div className="row mb-1">
                  <label className="col-2 my-auto">Price</label>
                  <div className="col-10 ">
                    <input
                      type="text"
                      required
                      value={sizeData?.price}
                      onChange={(e) => {
                        setSizeData({
                          ...sizeData,
                          price: e.target.value,
                          cost:
                            parseFloat(e.target.value) *
                            parseFloat(sizeData?.quantity),
                        });
                      }}
                      className="form-control"
                      placeholder="Enter Price"
                    />
                  </div>
                </div>
              </>
            )}

            {/*   buttons
             ********************************************* */}
            <div className="my-2">
              <div className="d-flex justify-content-end">
                <button
                  onClick={() => {
                    setShowAddModal(false);
                    resetSizeForm();

                    resetSizeForm();
                  }}
                  className="btn btn-secondary btn-sm"
                  type="button"
                >
                  Close
                </button>
                {!updateData && (
                  <button
                    className="btn btn-primary btn-sm ms-1"
                    onClick={handleAddSize}
                    type="submit"
                  >
                    ADD
                  </button>
                )}
                {updateData && (
                  <button
                    className="btn btn-primary btn-sm ms-1"
                    onClick={handleUpdateSize}
                    type="submit"
                  >
                    UPDATE
                  </button>
                )}
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {/*   Update Size Modal
       ********************************************* */}
      <Modal show={updatePriceModal} onHide={() => setUpdatePriceModal(false)}>
        <Modal.Body className="bg-white shadow-lg" style={{ borderRadius: 15 }}>
          <div className="mb-13 d-flex justify-content-between mb-2">
            <h4>UPDATE PRICE FOR ALL SIZES</h4>
          </div>
          <hr className="mt-0" />
          <form onSubmit={handleUpdateAllSize}>
            <div className="row mb-1">
              <label className="col-2 my-auto">Price</label>
              <div className="col-10 ">
                <input
                  type="text"
                  value={allPrice}
                  required
                  autoFocus
                  onFocus={(e) => {
                    e.target.select();
                  }}
                  onChange={(e) => {
                    setAllPrice(e.target.value);
                  }}
                  className="form-control"
                  placeholder="Enter Price"
                />
              </div>
            </div>

            {/*   buttons
             ********************************************* */}
            <div className="my-2">
              <div className="d-flex justify-content-end">
                <button
                  onClick={() => {
                    setUpdatePriceModal(false);
                    resetSizeForm();
                  }}
                  className="btn btn-secondary btn-sm"
                  type="button"
                >
                  Close
                </button>

                <button className="btn btn-primary btn-sm ms-1" type="submit">
                  UPDATE
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
