import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import Modal from "react-bootstrap/Modal";
import { v4 as uuid } from "uuid";
import ImageWIthSignedUrl from "pages/DesignRequests/DesignRequest/ImageWIthSignedUrl";

function QuickViewGeneralDetails({
  selectedWorkOrder,
  setSelectedCurrentProduct,
}) {
  /*   ALL STATES
   ********************************************* */

  const [allSizes, setAllSizes] = useState([]);

  const [showAddModal, setShowAddModal] = useState(false);
  const [updatePriceModal, setUpdatePriceModal] = useState(false);

  const [WOProductsData, setWOProductsData] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [count, setCount] = useState(1);

  const [updateData, setUpdateData] = useState(null);
  const [isUpdateMode, setIsUpdateMode] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const [allPrice, setAllPrice] = useState(0);

  const [generalData, setGeneralData] = useState(null);

  const [sizeData, setSizeData] = useState({
    size: "",
    quantity: 0,
    price: 0,
  });

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["clean"],
    ],
  };

  /*   ALL FUNCTIONS
   ********************************************* */

  const resetSizeForm = async () => {
    setUpdateData(null);
    setIsUpdateMode(false);
    setSizeData({
      size: "",
      quantity: 0,
      price: 0,
      cost: 0,
    });
  };

  const handleAddSize = (e) => {
    e.preventDefault();
    let data = [
      ...allSizes,
      {
        ...sizeData,
        id: uuid(),
        sortId: allSizes.length ? allSizes[allSizes.length - 1].sortId + 1 : 1,
      },
    ];
    setAllSizes(data);
    updateGeneralData(data);

    setShowAddModal(false);
    resetSizeForm();
  };

  const deleteSize = (id) => {
    setAllSizes((prev) => {
      const filteredArray = prev.filter((pre) => pre.id !== id);

      // loop through allSizes and reset sortId ascending order
      let sortedArr = filteredArray?.map((item, i) => {
        return { ...item, sortId: i + 1 };
      });

      updateGeneralData(sortedArr);
      return filteredArray;
    });
  };

  const handleUpdateSize = async (e) => {
    e.preventDefault();
    let newArray = [];
    allSizes?.map((item, index) => {
      if (updateData.id === item.id) {
        newArray.push({ ...sizeData, id: item.id, sortId: updateData.sortId });
      } else {
        newArray.push(item);
      }
    });

    setAllSizes(newArray);
    updateGeneralData(newArray);
    await resetSizeForm();
    setShowAddModal(false);
  };

  const handleUpdateAllSize = async (e) => {
    e.preventDefault();
    let updatedArray = allSizes.reduce((acc, cur) => {
      let updatedPriceArray = { ...cur, price: allPrice };
      acc.push(updatedPriceArray);
      return acc;
    }, []);
    setAllSizes(updatedArray);

    updateGeneralData(updatedArray);
    setAllPrice(0);
    setUpdatePriceModal(false);
  };

  const updateGeneralData = (data) => {
    let totalQuantity = 0;
    let totalPrice = 0;

    data.map((item) => {
      totalPrice +=
        parseFloat(item.price).toFixed(2) *
        parseFloat(item.quantity).toFixed(2);
      totalQuantity += parseInt(item.quantity);
    });
    let updatedGeneralData = {
      ...generalData,
      productSizes: JSON.stringify(data),
      totalPrice,
      totalQuantity,
    };
    setGeneralData(updatedGeneralData);
    setSelectedProduct((prev) => {
      return { ...prev, ...updatedGeneralData };
    });
    return updatedGeneralData;
  };

  const sortArrBySortId = (arr) => {
    let sortedArr = arr?.sort((a, b) => a.sortId - b.sortId);
    return sortedArr;
  };

  const prevProduct = () => {
    if (currentIndex == 0) {
      return;
    } else {
      setCurrentIndex((prevState) => prevState - 1);
      setCount((prev) => prev - 1);
    }
  };

  const nextProduct = () => {
    if (currentIndex == WOProductsData.length - 1) {
      return;
    } else {
      setCurrentIndex((prevState) => prevState + 1);
      setCount((prev) => prev + 1);
    }
  };

  const prevSize = () => {
    if (selectedIndex == 0) return;
    setSelectedIndex((pre) => pre - 1);
  };

  const nextSize = () => {
    if (selectedIndex == allSizes?.length - 1) return;
    setSelectedIndex((pre) => pre + 1);
  };

  const sortUp = (index) => {
    let newData = allSizes;
    let tempData = newData[index - 1].sortId;
    newData[index - 1].sortId = newData[index].sortId;
    newData[index].sortId = tempData;
    setAllSizes(newData);
    updateGeneralData(newData);
  };

  const sortDown = (index) => {
    let newData = allSizes;
    let tempData = newData[index + 1].sortId;
    newData[index + 1].sortId = newData[index].sortId;
    newData[index].sortId = tempData;
    setAllSizes(newData);
    updateGeneralData(newData);
  };

  useEffect(() => {
    if (selectedProduct) {
      setSelectedCurrentProduct(selectedProduct);
    }
  }, [selectedProduct]);

  /*   ALL Use Effects
   ********************************************* */
  useEffect(() => {
    if (selectedWorkOrder) {
      setWOProductsData(selectedWorkOrder?.WOProducts);
    }
  }, [selectedWorkOrder]);

  useEffect(() => {
    if (currentIndex !== null && WOProductsData) {
      let newSelectedProduct = WOProductsData[currentIndex];

      setSelectedProduct(newSelectedProduct);
      setSelectedCurrentProduct(newSelectedProduct);

      setGeneralData({
        productType: newSelectedProduct?.productType,
        productStyle: newSelectedProduct?.productStyle,
        productDescription: newSelectedProduct?.productDescription,
        productFabric: newSelectedProduct?.productFabric,
        productColor: newSelectedProduct?.productColor,
        totalQuantity: newSelectedProduct?.totalQuantity,
        totalPrice: newSelectedProduct?.totalPrice,
        notes: newSelectedProduct?.notes,
        hasNumbers: newSelectedProduct?.hasNumbers,
        hasName: newSelectedProduct?.hasName,
        productSizes: newSelectedProduct?.productSizes,
      });
    }
  }, [currentIndex, WOProductsData]);

  useEffect(() => {
    setAllSizes((prev) =>
      generalData?.productSizes
        ? typeof generalData?.productSizes === "string"
          ? JSON.parse(generalData?.productSizes)
          : generalData?.productSizes
        : []
    );
  }, [generalData]);

  useEffect(() => {
    let selectedSize = allSizes[selectedIndex];
    setSizeData({
      size: selectedSize?.size?.toUpperCase(),
      quantity: selectedSize?.quantity,
      price: selectedSize?.price,
    });
  }, [allSizes, selectedIndex, updateData]);

  return (
    <div>
      <div className="d-flex">
        {/*   Left Panel
         ********************************************* */}
        <div
          className=" bg-white shadow-lg p-2 w-50  mb-2 me-2"
          style={{ borderRadius: 15 }}
        >
          <div className="d-flex justify-content-between align-items-center">
            <h3>
              Product
              {` {${WOProductsData?.length === 0 ? " 0 " : ` ${count} `} of ${
                WOProductsData?.length
              } }`}
            </h3>
            <div className="d-flex gap-1">
              <button
                onClick={() => {
                  prevProduct();
                }}
                type="button"
                disabled={WOProductsData?.length === 0}
                className={"btn btn-success btn-sm p-0 px-1 "}
              >
                <i className="mdi mdi-arrow-left-bold fs-3 py-0 my-0"></i>
              </button>
              <button
                onClick={() => {
                  nextProduct();
                }}
                type="button"
                disabled={WOProductsData?.length === 0}
                className={"btn btn-success btn-sm p-0 px-1"}
              >
                <i className="mdi mdi-arrow-right-bold fs-3 py-0 my-0"></i>
              </button>
            </div>
          </div>
          <div className=" p-1">
            <div className="row mb-1">
              <label className="col-5">Product Type</label>
              <div className="col-7">
                <input
                  type="text"
                  className="form-control"
                  required
                  readOnly
                  value={generalData?.productType}
                />
              </div>
            </div>
            <div className="row mb-1">
              <label className="col-5">Product Style</label>
              <div className="col-7 d-flex align-items-center">
                <input
                  type="text"
                  className="form-control"
                  required
                  readOnly
                  value={generalData?.productStyle}
                  placeholder="Enter Product Style"
                />
                {/* <button  className='btn btn-success btn-sm ms-2 p-1 py-0'>
                  <i className='ri-checkbox-line fs-3'></i>
                </button> */}
              </div>
            </div>
            <div className="row mb-1">
              <label className="col-5">Product Description</label>
              <div className="col-7 ">
                <input
                  type="text"
                  required
                  value={generalData?.productDescription}
                  readOnly
                  className="form-control"
                  placeholder="Product description"
                />
              </div>
            </div>
            <div className="row mb-1">
              <label className="col-5">Product Fabric</label>
              <div className="col-7 ">
                <input
                  type="text"
                  required
                  value={generalData?.productFabric}
                  readOnly
                  className="form-control"
                  placeholder="Product fabric"
                />
              </div>
            </div>
            <div className="row mb-1">
              <label className="col-5">Product Color</label>
              <div className="col-7 ">
                <input
                  type="text"
                  required
                  value={generalData?.productColor}
                  readOnly
                  className="form-control"
                  placeholder="Product color"
                />
              </div>
            </div>

            <div className="row mb-1">
              <label className="col-5">Has Names</label>
              <div
                className="col-7 d-flex form-switch "
                style={{ paddingLeft: 48 }}
              >
                <input
                  type="checkbox"
                  checked={generalData?.hasName ? true : false}
                  readOnly
                  className="form-check-input "
                  style={{ width: 40 }}
                />
              </div>
            </div>
            <div className="row mb-1">
              <label className="col-5">Has Numbers</label>
              <div
                className="col-7 d-flex form-switch"
                style={{ paddingLeft: 48 }}
              >
                <input
                  type="checkbox"
                  checked={generalData?.hasNumbers ? true : false}
                  readOnly
                  className="form-check-input "
                  style={{ width: 40 }}
                />
              </div>
            </div>
          </div>
        </div>

        {/*   Right Panel
         ********************************************* */}
        <div className="w-50 flex-grow-1 pb-2 d-flex flex-column">
          <div
            className="bg-white shadow-lg px-3 mb-2 me-2 d-flex flex-column py-2"
            style={{ borderRadius: 15, height: 250 }}
          >
            <div className="" style={{ overflowY: "scroll", height: "99%" }}>
              <div className="table-responsive  ">
                <table className="table ">
                  <thead>
                    <tr>
                      <th scope="col">Size</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Price</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortArrBySortId(allSizes)?.map((item, i) => (
                      <tr key={i}>
                        <td className="py-0">{item?.size?.toUpperCase()}</td>
                        <td className="py-0">{item?.quantity}</td>
                        <td className="py-0">
                          $ {parseFloat(item?.price).toFixed(2)}
                        </td>
                        <td className="py-0">
                          {item.sortId}
                          <div className="action-icon">
                            <div
                              onClick={() => {
                                item.sortId != 1 && sortUp(i);
                              }}
                              className="action-icon"
                            >
                              <i className="mdi mdi-chevron-double-up"></i>
                            </div>
                            <div
                              onClick={() => {
                                item.sortId != allSizes?.length && sortDown(i);
                              }}
                              className="action-icon"
                            >
                              <i className="mdi mdi-chevron-double-down"></i>
                            </div>
                            <i
                              onClick={() => {
                                setUpdateData(item);
                                setIsUpdateMode(true);
                                setSelectedIndex(i);
                                setShowAddModal(true);
                              }}
                              className="mdi mdi-pencil"
                            ></i>
                          </div>
                          <div
                            onClick={() => deleteSize(item.id)}
                            className="action-icon"
                          >
                            <i className="mdi mdi-delete"></i>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="d-flex">
            <div
              className="d-flex justify-content-evenly flex-grow-1 py-1 align-items-center bg-white shadow-lg me-2"
              style={{ borderRadius: 15 }}
            >
              <div>
                Total Qty
                <span className="border ms-1 px-2">
                  {parseFloat(generalData?.totalQuantity)}
                </span>
              </div>
              <div>
                Total Price
                <span className="border ms-1 px-2">
                  $ {parseFloat(generalData?.totalPrice).toFixed(2)}
                </span>
              </div>
              <div className="d-flex">
                <button
                  onClick={(e) => {
                    setUpdatePriceModal(true);
                  }}
                  className="btn btn-primary me-1"
                  style={{ borderRadius: 10 }}
                  type="button"
                >
                  Update Price
                </button>
              </div>
              <div className="d-flex">
                <button
                  onClick={() => {
                    resetSizeForm();
                    setShowAddModal(true);
                  }}
                  className="btn btn-primary"
                  style={{ borderRadius: 10 }}
                  type="button"
                >
                  Add Sizes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom Pane */}
      <div className="d-flex">
        {/*   Image select
         ********************************************* */}
        <div
          className="bg-white shadow-lg p-2 w-50 mb-2 me-2 d-flex flex-column justify-content-between "
          style={{ borderRadius: 15, minHeight: 300 }}
        >
          {selectedProduct && (
            <ImageWIthSignedUrl
              path={selectedProduct?.productImage}
              width={350}
              imgClasses={"flex-center"}
            />
          )}
        </div>

        {/*   Notes
         ********************************************* */}
        <div
          className="bg-white shadow-lg p-2 w-50 mb-2 me-2 d-flex flex-column justify-content-between "
          style={{ borderRadius: 15, minHeight: 300 }}
        >
          <div className="">
            <ReactQuill
              modules={modules}
              theme="snow"
              value={generalData?.notes}
            />
          </div>
        </div>
      </div>

      {/*   ADD / Update Size Modal
       ********************************************* */}
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Body className="bg-white shadow-lg" style={{ borderRadius: 15 }}>
          <div className="mb-13 d-flex justify-content-between mb-2">
            <h4>{isUpdateMode ? "UPDATE" : "ADD"} SIZES</h4>
            {isUpdateMode && (
              <div>
                <button
                  onClick={() => {
                    prevSize();
                  }}
                  type="button"
                  className={"btn btn-success btn-sm p-0 px-1 mx-1"}
                >
                  <i className="mdi mdi-arrow-left-bold fs-3 py-0 my-0"></i>
                </button>
                <button
                  onClick={() => {
                    nextSize();
                  }}
                  type="button"
                  className={"btn btn-success btn-sm p-0 px-1"}
                >
                  <i className="mdi mdi-arrow-right-bold fs-3 py-0 my-0"></i>
                </button>
              </div>
            )}
          </div>
          <hr className="mt-0" />
          <form>
            {/* sizeData */}
            {sizeData ? (
              <>
                <div className="row mb-1">
                  <label className="col-2 my-auto">Size</label>
                  <div className="col-10 ">
                    <input
                      type="text"
                      value={sizeData?.size}
                      autoFocus
                      required
                      onChange={(e) => {
                        setSizeData({ ...sizeData, size: e.target.value });
                      }}
                      className="form-control"
                      placeholder="Enter Size"
                    />
                  </div>
                </div>
                <div className="row mb-1">
                  <label className="col-2 my-auto">Quantity</label>
                  <div className="col-10 ">
                    <input
                      type="number"
                      min={0}
                      step={1}
                      required
                      value={sizeData?.quantity}
                      onChange={(e) => {
                        setSizeData({
                          ...sizeData,
                          quantity: e.target.value,
                          cost:
                            parseInt(sizeData?.price) *
                            parseInt(e.target.value),
                        });
                      }}
                      className="form-control"
                      placeholder="Enter Quantity"
                    />
                  </div>
                </div>
                <div className="row mb-1">
                  <label className="col-2 my-auto">Price</label>
                  <div className="col-10 ">
                    <input
                      type="text"
                      required
                      value={sizeData?.price}
                      onChange={(e) => {
                        setSizeData({
                          ...sizeData,
                          price: e.target.value,
                          cost:
                            parseFloat(e.target.value) *
                            parseFloat(sizeData?.quantity),
                        });
                      }}
                      className="form-control"
                      placeholder="Enter Price"
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="row mb-1">
                  <label className="col-2 my-auto">Size</label>
                  <div className="col-10 ">
                    <input
                      type="text"
                      value={sizeData?.size}
                      required
                      onChange={(e) => {
                        setSizeData({ ...sizeData, size: e.target.value });
                      }}
                      className="form-control"
                      placeholder="Enter Size"
                    />
                  </div>
                </div>
                <div className="row mb-1">
                  <label className="col-2 my-auto">Quantity</label>
                  <div className="col-10 ">
                    <input
                      type="number"
                      min={0}
                      step={1}
                      required
                      value={sizeData?.quantity}
                      onChange={(e) => {
                        setSizeData({
                          ...sizeData,
                          quantity: e.target.value,
                          cost:
                            parseInt(sizeData?.price) *
                            parseInt(e.target.value),
                        });
                      }}
                      className="form-control"
                      placeholder="Enter Quantity"
                    />
                  </div>
                </div>
                <div className="row mb-1">
                  <label className="col-2 my-auto">Price</label>
                  <div className="col-10 ">
                    <input
                      type="text"
                      required
                      value={sizeData?.price}
                      onChange={(e) => {
                        setSizeData({
                          ...sizeData,
                          price: e.target.value,
                          cost:
                            parseFloat(e.target.value) *
                            parseFloat(sizeData?.quantity),
                        });
                      }}
                      className="form-control"
                      placeholder="Enter Price"
                    />
                  </div>
                </div>
              </>
            )}

            {/*   buttons
             ********************************************* */}
            <div className="my-2">
              <div className="d-flex justify-content-end">
                <button
                  onClick={() => {
                    setShowAddModal(false);
                    resetSizeForm();
                  }}
                  className="btn btn-secondary btn-sm"
                  type="button"
                >
                  Close
                </button>
                {!updateData && (
                  <button
                    className="btn btn-primary btn-sm ms-1"
                    onClick={handleAddSize}
                    type="submit"
                  >
                    ADD
                  </button>
                )}
                {updateData && (
                  <button
                    className="btn btn-primary btn-sm ms-1"
                    onClick={handleUpdateSize}
                    type="submit"
                  >
                    UPDATE
                  </button>
                )}
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {/*   Update All PRice Modal
       ********************************************* */}
      <Modal show={updatePriceModal} onHide={() => setUpdatePriceModal(false)}>
        <Modal.Body className="bg-white shadow-lg" style={{ borderRadius: 15 }}>
          <div className="mb-13 d-flex justify-content-between mb-2">
            <h4>UPDATE PRICE FOR ALL SIZES</h4>
          </div>
          <hr className="mt-0" />
          <form onSubmit={handleUpdateAllSize}>
            <div className="row mb-1">
              <label className="col-2 my-auto">Price</label>
              <div className="col-10 ">
                <input
                  type="text"
                  value={allPrice}
                  required
                  autoFocus
                  onFocus={(e) => {
                    e.target.select();
                  }}
                  onChange={(e) => {
                    setAllPrice(e.target.value);
                  }}
                  className="form-control"
                  placeholder="Enter Price"
                />
              </div>
            </div>

            {/*   buttons
             ********************************************* */}
            <div className="my-2">
              <div className="d-flex justify-content-end">
                <button
                  onClick={() => {
                    // setUpdatePriceModal(false);
                    // resetSizeForm();
                  }}
                  className="btn btn-secondary btn-sm"
                  type="button"
                >
                  Close
                </button>

                <button
                  className="btn btn-primary btn-sm ms-1"
                  // onClick={handleUpdateAllSize}
                  type="submit"
                >
                  UPDATE
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default QuickViewGeneralDetails;
