import React, { useState } from "react";
import ContactAndBilling from "./ContactAndBilling";
import CustomerDropDowns from "./CustomerDropDowns";
import CustomFields from "./CustomFields";
import API from "services/axios";
import authHeader from "services/auth-header";
import { useDispatch } from "react-redux";
import { setNotification } from "features/Notification/notificationSlice";
import Spinner from "components/Spinner";

export default function AdvanceSearchForm({ showModal, setSearchResult }) {
  /*   All States
   ********************************************* */
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({});

  const [loading, setLoading] = useState(false);

  /*   All Functions
   ********************************************* */
  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  const handleInputChange = (event, type) => {
    const { name, value } = event.target;
    let updatedValue = type === "number" ? parseInt(value) : value;
    setFormData({ ...formData, [name]: updatedValue });
  };

  const handleSearch = async () => {
    try {
      loadingOn();
      const { data } = await API.post("/customer/advance-search", formData, {
        headers: authHeader(),
      });

      // console.log(data);
      setSearchResult(data);
      loadingOff();
      showModal(false);
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  /*   All UseEffects
   ********************************************* */
  return (
    <div>
      {loading && <Spinner />}
      <h3 className="mt-0">Advance Customer Search</h3>
      <div className="">
        <h4 className="border-bottom border-2 d-inline-block mb-1 pe-2">
          Contact & Billing
        </h4>
        <ContactAndBilling
          formData={formData}
          setFormData={setFormData}
          handleInputChange={handleInputChange}
        />

        <h4 className="border-bottom border-2 d-inline-block mb-1 pe-2 mt-3">
          General Details & Custom Fields
        </h4>
        <div className="row bg-white custom-border-radius-sm custom-shadow mx-1">
          <CustomerDropDowns
            formData={formData}
            setFormData={setFormData}
            handleInputChange={handleInputChange}
          />
          <CustomFields
            formData={formData}
            setFormData={setFormData}
            handleInputChange={handleInputChange}
          />
        </div>
      </div>
      <div className="d-flex justify-content-end mt-3 gap-1">
        <button
          onClick={() => {
            showModal(false);
          }}
          className="btn btn-secondary"
        >
          Close
        </button>
        <button onClick={handleSearch} className="btn btn-success">
          Search
        </button>
      </div>
    </div>
  );
}
