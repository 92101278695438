import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { setNotification } from '../../features/Notification/notificationSlice';

const Toast = () => {
  const dispatch = useDispatch();
  const { alerts } = useSelector(state => state.notifications);
  const [alert, setAlert] = useState({ type: "", message: "" });
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (alerts.length > 0) {
      setAlert(alerts[alerts.length - 1]);
      setShow(true);
      setTimeout(() => {
        dispatch(
          setNotification({
            message: '',
            type: ''
          })
        )
        setShow(false);
      }, 3000);
    }
  }, [alerts]);

  return show ? (
    <div className="toast-container position-absolute p-3 top-0 start-50 translate-middle-x">
      <div className="toast show">
        <div className={`toast-body ${alert.type === 'error' ? 'text-white bg-danger' : 'text-white bg-success'}`}>
          {alert.message || ""}
        </div>
      </div>
    </div>
  ) : null;
};

export default Toast;
