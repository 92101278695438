import React, { useEffect, useState } from 'react';

export default function BySheetCalculator({ matrix, setLocation, setColors, setCalculatedPrice }) {
  /*   All States
   ********************************************* */
  const [tableHead, setTableHead] = useState(null);
  const [tableBody, setTableBody] = useState(null);

  const [printMargin, setPrintMargin] = useState(0.125);
  const [quantity, setQuantity] = useState(0);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const [totalArea, setTotalArea] = useState('');
  const [noOfSheets, setNoOfSheets] = useState('');

  const [isRotated, setIsRotated] = useState('No');
  const [sheetWidth, setSheetWidth] = useState(0);
  const [sheetHeight, setSheetHeight] = useState(0);

  // const [totalPrice, setSheetPrice] = useState(0);
  const [sheetPrice, setSheetPrice] = useState(0);
  const [logoPrice, setLogoPrice] = useState(0);

  /*   All Functions
   ********************************************* */

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    let data = matrix?.matrixJson ? JSON.parse(matrix?.matrixJson) : null;
    if (data) {
      let tHead = data?.tableHead;
      let tbody = data?.tableBody;
      setTableHead(tHead);
      setTableBody(tbody);
      setSheetWidth(data?.width);
      setSheetHeight(data?.height);
      setPrintMargin(data?.printMargin);
    }
  }, [matrix]);

  // :: Getting Area of 1 Pcs
  useEffect(() => {
    if (tableBody && tableHead) {
      const getSheetsOFStraightLogo = (paramWidth, paramHeight) => {
        let logoWidth = paramWidth + printMargin;
        let logoHeight = paramHeight + printMargin;

        let noOfColumns = Math.floor(sheetWidth / logoWidth);
        let noOfRows = Math.floor(sheetHeight / logoHeight);

        let extraSpace = sheetHeight - logoHeight * noOfRows;

        let extraRotatedItems = 0;
        if (logoWidth <= extraSpace) {
          let newSheetWidth = sheetWidth;
          let newSheetHeight = extraSpace;

          let newLogoWidth = logoHeight;
          let newLogoHeight = logoWidth;

          let newNoOfColumns = Math.floor(newSheetWidth / newLogoWidth);
          let newNoOfRows = Math.floor(newSheetHeight / newLogoHeight);

          extraRotatedItems = newNoOfRows * newNoOfColumns;
        }

        let totalLogosOfGivenSize = noOfRows * noOfColumns + extraRotatedItems;
        let sheets = Math.ceil(quantity / totalLogosOfGivenSize);

        let roundedOffTotalArea =
          Math.round(parseFloat(logoWidth * logoHeight * quantity) * 100) / 100;
        setTotalArea(roundedOffTotalArea);

        return sheets;
      };

      if (width && height && quantity) {
        let sheets1 = getSheetsOFStraightLogo(width, height);
        let sheets2 = getSheetsOFStraightLogo(height, width);
        if (sheets1 <= sheets2) {
          setNoOfSheets(sheets1);
          getTotalPrice(sheets1);
          setIsRotated('No');
        } else {
          setIsRotated('Yes');
          setNoOfSheets(sheets2);
          getTotalPrice(sheets2);
        }
      }
    }
  }, [width, height, quantity, printMargin, sheetWidth, sheetHeight]);

  const getTotalPrice = (sheets) => {
    // Getting Correct Column Row =================================================================
    let selectedColumn = '';

    for (let i = 1; i < tableHead?.length; i++) {
      if (sheets < parseFloat(tableHead[i].split(' ')[0])) {
        selectedColumn = tableHead[i - 1];
        break;
      }
    }
    if (sheets >= parseFloat(tableHead[tableHead?.length - 1].split(' ')[0])) {
      selectedColumn = tableHead[tableHead?.length - 1];
    }

    let roundedOffSheetPrice = Math.round(parseFloat(tableBody[0][selectedColumn]) * 100) / 100;
    setSheetPrice(roundedOffSheetPrice);

    let roundedOffLogoPrice =
      Math.round(parseFloat((roundedOffSheetPrice * sheets) / quantity) * 100) / 100;
    setLogoPrice(roundedOffLogoPrice);
  };

  useEffect(() => {
    setCalculatedPrice(logoPrice);
  }, [logoPrice]);

  return (
    <div>
      <div className='  '>
        <div className='d-flex gap-1'>
          <div className='w-50'>
            Logo Quantity
            <input
              name='quantityRow'
              type='number'
              min={1}
              value={quantity}
              onChange={(e) => {
                setQuantity(parseInt(e.target.value));
              }}
              className='form-control'
            />
          </div>
          <div className='w-50'>
            Width
            <input
              type='number'
              min={1}
              step={0.01}
              max={sheetWidth}
              value={width}
              onChange={(e) => {
                let value = parseFloat(e.target.value);
                setWidth(
                  parseFloat(value <= sheetWidth - printMargin ? value : sheetWidth - printMargin)
                );
              }}
              className='form-control'
            />
          </div>
          <div className='w-50'>
            Height
            <input
              type='number'
              min={1}
              step={0.01}
              value={height}
              max={sheetHeight - printMargin}
              onChange={(e) => {
                let value = parseFloat(e.target.value);
                setHeight(
                  parseFloat(value <= sheetHeight - printMargin ? value : sheetHeight - printMargin)
                );
              }}
              className='form-control'
            />
          </div>
        </div>

        <div className='d-flex gap-1 my-3'>
          <div className='w-50'>
            Total Area
            <input
              name='quantityRow'
              type='number'
              min={1}
              value={totalArea}
              disabled
              readOnly
              className='form-control'
            />
          </div>
          <div className='w-50'>
            IS Rotated ?
            <input
              name='quantityRow'
              type='text'
              value={isRotated}
              disabled
              readOnly
              className='form-control'
            />
          </div>
          <div className='w-50'>
            No of Sheets
            <input
              name='quantityRow'
              type='number'
              min={1}
              value={noOfSheets}
              disabled
              readOnly
              className='form-control'
            />
          </div>
        </div>

        <div className='row mt-2 align-items-center'>
          <div className='col-5'>Single Sheet Price</div>
          <div className='col-7 bg-primary bg-opacity-25  custom-border-radius-sm p-1 text-center'>
            {sheetPrice}
          </div>
        </div>
        <div className='row mt-2 align-items-center'>
          <div className='col-5'>Single Logo Price</div>
          <div className='col-7 bg-primary bg-opacity-25  custom-border-radius-sm p-1 text-center'>
            {logoPrice}
          </div>
        </div>
        <div className='row  mt-3 align-items-center'>
          <div className='col-3'>Location</div>
          <div className='flex-grow-1 w-100'>
            <input
              type='text'
              onChange={(e) => setLocation(e.target.value)}
              placeholder='Enter location'
              className='form-control'
            />
          </div>
        </div>
        <div className='row mt-3 align-items-center'>
          <div className='col-3'>Colors</div>
          <div className='flex-grow-1 w-100'>
            <input
              type='text'
              onChange={(e) => setColors(e.target.value)}
              placeholder='Enter location'
              className='form-control'
            />
          </div>
        </div>
      </div>
    </div>
  );
}
