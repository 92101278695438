import { Document, StyleSheet } from "@react-pdf/renderer";

import logo from "../../../assets/images/logo.png";
import InvoicePage from "./InvoicePage";
import WOPdfProducts from "./WOPdfProducts";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    padding: 20,
  },
  topHeader: {
    height: 75,
  },
  shippingDescription: {
    marginVertical: 0,
  },
  headingMain: { fontSize: 20, fontWeight: "extrabold" },
  headingSecond: { fontSize: 12, fontWeight: "extrabold" },
  headingThird: { fontSize: 15, fontWeight: "extrabold" },
  textbody: { fontSize: 10 },
  mainContainer: {
    marginBottom: "10px",
    flexDirection: "column",
    border: "1px solid grey",
  },
  prodTitle: {
    width: "100%",
    textAlign: "left",
    padding: "5px 10px",

    borderBottom: "1px solid grey",
    fontSize: "14px",
  },
  prodMain: {
    width: "100%",
    flexDirection: "row",
  },
  prodContent: {
    flexGrow: 1,
  },
  prodSizes: {
    padding: "5px",
    width: "150px",
    borderLeft: "1px solid grey",
  },
  prodInfoRow: {
    display: "flex",
    flexDirection: "row",
    fontSize: "10px",
  },
  prodCol: {
    display: "flex",
    flexDirection: "row",
    width: "50%",
  },
  prodKey: {
    padding: "3px 5px",
    width: "40%",
    borderRight: "1px solid grey",
    borderBottom: "1px solid grey",
  },
  prodVal: {
    padding: "3px 5px",
    width: "60%",
    borderBottom: "1px solid grey",
  },
  prodComments: {
    padding: "5px 10px",
    fontSize: "12px",
    borderTop: "1px solid grey",
  },
  prodSubHeading: {
    fontSize: "12px",
  },
  prodTxt: {
    fontSize: "10px",
  },
  borderRight: { borderRight: "1px solid grey" },
  colWithLong: {
    width: "200px",
    marginTop: 5,
    marginBottom: 3,
  },
  colWith: {
    width: "60px",
    marginTop: 5,
    marginBottom: 3,
    marginHorizontal: 5,
  },
  colWithMd: {
    width: "40px",
    marginTop: 5,
    marginBottom: 3,
  },
  colWithSm: {
    width: "30px",
    marginTop: 5,
    marginBottom: 3,
  },
  colWithXs: {
    width: "10px",
    marginTop: 5,
    marginBottom: 3,
  },
});

// Create Document Component
export const WOPdf = ({
  selectedWO,
  showInvoicePage,
  productsList,
  locationLogo,
}) => {
  /*   ALL STATES
   ********************************************* */

  /*   ALL FUnctions
   ********************************************* */

  /*   All Use Effects
   ********************************************* */

  return (
    <>
      <Document title={selectedWO?.id}>
        {showInvoicePage && (
          <InvoicePage
            selectedWO={selectedWO}
            styles={styles}
            productsList={selectedWO?.WOProducts}
            locationLogo={locationLogo}
          />
        )}

        <WOPdfProducts
          styles={styles}
          selectedWO={selectedWO}
          logo={logo}
          locationLogo={locationLogo}
          productsList={productsList}
        />
      </Document>
    </>
  );
};
