import React, { useState, useRef, useEffect } from 'react';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';

const Popover = ({ body, btn_classes, btn_text, doubleClick }) => {
  const [showPopover, setShowPopover] = useState(false);
  const target = useRef(null);

  const handleButtonClick = () => {
    setShowPopover(true);
    setTimeout(() => setShowPopover(false), 1000);
  };

  return (
    <>
      {doubleClick == undefined && (
        <div className={`${btn_classes}`} ref={target} onClick={handleButtonClick}>
          {btn_text ? btn_text : '-'}
        </div>
      )}
      {doubleClick != undefined && (
        <div className={`${btn_classes}`} ref={target} onDoubleClick={handleButtonClick}>
          {btn_text ? btn_text : '-'}
        </div>
      )}
      <Overlay target={target.current} show={showPopover} placement='top'>
        {(props) => (
          <Tooltip id='overlay-example' {...props}>
            {body ? body : '-'}
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};

export default Popover;
