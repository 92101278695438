import Table from "components/DataTable/Table";
import Spinner from "components/Spinner";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

import TeamViewPdfButton from "components/Pdfs/TeamViewPdf/TeamViewPdfButton";
import { useSearchParams } from "react-router-dom";
import WOProductUpdate from "../WOProducts/WOProductUpdate";

function WOTeamView({ refreshFunc, selectedWorkOrder }) {
  const user = useSelector((state) => state.user?.userInfo);
  const [searchParams, setSearchParams] = useSearchParams();

  /*   ALL STATES
   ********************************************* */
  const [wOProductsData, setWOProductsData] = useState(null);
  const [filteredProductsData, setFilteredProductsData] = useState(null);

  const [selected, setSelected] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  /*   All Functions
   ********************************************* */

  const setUpdateId = (id) => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.set("productId", id);
      return newParams.toString();
    });
  };

  const removeUpdateId = () => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.delete("productId");
      return newParams.toString();
    });
  };

  const getNoOfItemsBySameTeamName = (teamName) => {
    const filteredProducts = selectedWorkOrder?.WOProducts.filter(
      (product) => product.teamName === teamName
    );
    return filteredProducts?.length;
  };

  const getGroupedProductObject = (productsList) => {
    const groupedProducts = productsList?.reduce((acc, product) => {
      const teamName = product.teamName || "noTeam";
      acc[teamName] = acc[teamName] || [];
      acc[teamName].push(product);
      return acc;
    }, {});

    return groupedProducts;
  };

  /*   ALL UseEffects
   ********************************************* */
  useEffect(() => {
    if (!showUpdateModal) {
      removeUpdateId();
    }
  }, [showUpdateModal]);

  useEffect(() => {
    if (selectedWorkOrder) {
      let productsList = getGroupedProductObject(selectedWorkOrder?.WOProducts);

      const sortedProductGroups = Object.values(productsList).sort((a, b) => {
        const teamA = a[0].teamName || "noTeam";
        const teamB = b[0].teamName || "noTeam";
        return teamA === "noTeam"
          ? 1
          : teamB === "noTeam"
          ? -1
          : teamA.localeCompare(teamB);
      });

      let productsByTeamName = sortedProductGroups
        .flat()
        ?.reduce((acc, curr) => {
          if (!acc.length) {
            acc.push(curr);
          } else {
            let existingProduct = acc.find(
              (product) => product.teamName === curr.teamName
            );
            if (!existingProduct) {
              acc.push(curr);
            }
          }
          return acc;
        }, []);
      setWOProductsData(productsByTeamName);
    }
    setSelected(null);
  }, [selectedWorkOrder, showUpdateModal]);

  /*   Table functions
   ********************************************* */

  const table_head = ["Item No", "Team Name", "No of Items", "Actions"];

  const table_body = (item, index) => (
    <tr
      onClick={() => setSelected(item)}
      className={selected?.id === item.id ? "bg-primary bg-opacity-25" : ""}
      key={item.id}
      onDoubleClick={() => {
        setShowUpdateModal(true);
        setUpdateId(item?.id);
      }}
    >
      <td className="py-0 align-middle">{index + 1}</td>
      <td className="py-0 align-middle">
        {item?.teamName ? item?.teamName : "No Team"}{" "}
      </td>
      <td className="py-0 align-middle">
        {getNoOfItemsBySameTeamName(item?.teamName)}
      </td>
      <td className="table-action py-0">
        {(user?.permissions?.includes("work_orders_update") ||
          user?.permissions?.includes("work_orders_dataEntry") ||
          user?.roles === "admin") && (
          <div className="action-icon">
            {(user?.permissions?.includes("work_orders_add") ||
              user?.permissions?.includes("work_orders_dataEntry") ||
              user?.roles === "admin") && (
              <TeamViewPdfButton
                selectedWO={selectedWorkOrder}
                btnClasses={"btn btn-sm my-1 btn-primary ms-1"}
                teamName={item?.teamName}
              />
              // <PrintProductPdf
              //   btnTitle={"Print Team Products"}
              //   btnClass={"btn btn-sm my-1 btn-primary ms-1"}
              //   selectedWorkOrder={selectedWorkOrder}
              //   teamName={item?.teamName}
              // />
            )}
          </div>
        )}
      </td>
    </tr>
  );

  if (!wOProductsData) return <Spinner />;
  return (
    <div className="d-flex flex-grow-1" style={{ height: "auto" }}>
      {/*   Table
       ********************************************* */}
      <div
        className="px-3 d-flex flex-grow-1 "
        style={{ minHeight: "50vh", borderRadius: 15 }}
      >
        <div className="horizontal-scrollable  d-flex flex-grow-1 ">
          {wOProductsData && (
            <Table
              table_head={table_head}
              table_body={table_body}
              table_data={
                filteredProductsData ? filteredProductsData : wOProductsData
              }
            />
          )}
        </div>
      </div>

      {/*   Update Modal
       ********************************************* */}
      <Modal size="xl" show={showUpdateModal}>
        <Modal.Body>
          <div>
            <WOProductUpdate
              wOProductsData={
                filteredProductsData ? filteredProductsData : wOProductsData
              }
              selectedWorkOrder={selectedWorkOrder}
              setShowUpdateModal={setShowUpdateModal}
              refreshFunc={refreshFunc}
              setUpdateId={setUpdateId}
              isViewOnly={true}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default WOTeamView;
