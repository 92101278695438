import React, { useEffect, useState } from 'react';

export default function ByQuantityCalculator({
  matrix,
  setLocation,
  setColors,
  setCalculatedPrice,
}) {
  /*   All States
   ********************************************* */
  const [totalPriceColumns, setTotalPriceColumns] = useState(null);
  const [selectedQuantity, setSelectedQuantity] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [generatedPrice, setGeneratedPrice] = useState(0);

  const [tableHead, setTableHead] = useState(null);
  const [tableBody, setTableBody] = useState(null);

  /*   All Functions
   ********************************************* */

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    let data = matrix?.matrixJson ? JSON.parse(matrix?.matrixJson) : null;
    if (data) {
      let tHead = data?.tableHead;
      let tbody = data?.tableBody;
      setTableHead(tHead);
      setTableBody(tbody);
    }
  }, [matrix]);
  useEffect(() => {
    if (tableBody) {
      let columnsList = Object.keys(tableBody[0]);
      setTotalPriceColumns(columnsList.slice(1, columnsList.length));

      let quantityList = [];
      tableBody.map((item) => {
        quantityList.push(item[Object.keys(item)[0]]);
      });
      setSelectedQuantity(quantityList[0]);
      setSelectedColumn(columnsList.slice(1, columnsList.length)[0]);
    }
  }, [tableBody]);

  useEffect(() => {
    if (tableBody) {
      let quantityList = [];
      tableBody.map((item) => {
        quantityList.push(item[Object.keys(item)[0]]);
      });

      let totalPrice = 0;
      for (let i = 1; i < tableBody?.length; i++) {
        if (selectedQuantity < quantityList[i]) {
          let price = parseFloat(tableBody[i - 1][selectedColumn]);
          totalPrice = price;
          break;
        }
      }
      if (selectedQuantity >= quantityList[quantityList.length - 1]) {
        let price = parseFloat(tableBody[tableBody.length - 1][selectedColumn]);
        totalPrice = price;
      }
      setGeneratedPrice(totalPrice.toFixed(2));
    }
  }, [selectedQuantity, selectedColumn, tableBody]);

  useEffect(() => {
    setCalculatedPrice(generatedPrice);
  }, [generatedPrice]);
  return (
    <div>
      <div className=''>
        <div className='d-flex gap-1'>
          <div className='w-50'>
            Enter Quantity
            <input
              name='quantityRow'
              type='number'
              min={1}
              value={selectedQuantity}
              onChange={(e) => {
                setSelectedQuantity(parseInt(e.target.value));
              }}
              className='form-control'
            />
          </div>
          <div className='w-50'>
            Select Column
            <select
              name='labelColumn'
              value={selectedColumn}
              onChange={(e) => {
                setSelectedColumn(e.target.value);
              }}
              className='form-control'
            >
              {tableHead?.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className='d-flex mt-2 align-items-center'>
          <div className='w-25'>Price</div>
          <div className='w-100 flex-grow-1 bg-primary bg-opacity-25  custom-border-radius-sm p-1 text-center'>
            {generatedPrice}
          </div>
        </div>
        <div className=' mt-3 align-items-center'>
          <div className='w-25'>Location</div>
          <div className='flex-grow-1 w-100'>
            <input
              type='text'
              onChange={(e) => setLocation(e.target.value)}
              placeholder='Enter location'
              className='form-control'
            />
          </div>
        </div>
        <div className='row mt-2 align-items-center'>
          <div className='col-3'>Colors</div>
          <div className='flex-grow-1 w-100'>
            <input
              type='text'
              onChange={(e) => setColors(e.target.value)}
              placeholder='Enter location'
              className='form-control'
            />
          </div>
        </div>
      </div>
    </div>
  );
}
