import { setNotification } from "features/Notification/notificationSlice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function SearchComp({ setLoading, apiPath }) {
  /*   All States
   ********************************************* */
  const [searchText, setSearchText] = useState("");
  const [showClearButton, setShowClearButton] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  /*   All Functions
   ********************************************* */
  const updateSearchText = (text) => {
    setSearchText(text);
  };

  const searchData = async (e) => {
    e.preventDefault();
    if (searchText === "") {
      dispatch(
        setNotification({
          message: "Please enter a valid query",
          type: "error",
        })
      );
      return;
    }
    setLoading(true);

    try {
      navigate(`${apiPath}?query=` + searchText);

      setLoading(false);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      setLoading(false);
    }
  };

  const handleClearButton = () => {
    setShowClearButton(false);
    setSearchText("");
    navigate(apiPath);
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    let query = searchParams.get("query");
    if (query) {
      setShowClearButton(true);
    } else {
      setShowClearButton(false);
    }
  }, [searchParams]);

  useEffect(() => {
    setSearchText(searchParams.get("query"));
  }, [searchParams]);
  return (
    <div className="d-flex">
      <form onSubmit={searchData} className="me-1 d-flex gap-1">
        <input
          onChange={(e) => {
            updateSearchText(e.target.value.toLocaleLowerCase());
          }}
          className="form-control"
          placeholder="Search here..."
          value={searchText || ""}
          type="search"
        />

        <button type="submit" className="btn btn- btn-info">
          Search
        </button>
      </form>
      {showClearButton && (
        <button onClick={handleClearButton} className="btn btn- btn-danger">
          Clear
        </button>
      )}
    </div>
  );
}
