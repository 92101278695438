import { useForm } from "react-hook-form";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

const apiEnvUrl = process.env.REACT_APP_API_URI;

function Register() {
  const [showPass, setShowPass] = useState(false);
  const [alertData, setalertData] = useState(null);

  const { register, handleSubmit } = useForm();
  const { loading, userInfo } = useSelector((state) => state.user);

  const [step, setstep] = useState(1);
  const [phoneValue, setPhoneValue] = useState(null);
  const [termsCheck, setTermsCheck] = useState(false);

  const [formData, setFormData] = useState({
    userName: null,
    email: null,
    password: null,
    companyName: null,
    address1: null,
    address2: null,
    city: null,
    state: null,
    zip: null,
    country: null,
  });

  const nextStep = () => {
    if (termsCheck) {
      setstep(step + 1);
      setalertData(null);
    } else {
      // setalertData("You must agree to terms & conditions.");
      setTimeout(function() {
        // new Noty({
        //   text: "You must agree to terms & conditions.",
        //   timeout: 3000,
        //   type: "error",
        // }).show();
      }, );
    }
    return;
  };

  const prevStep = () => {
    setstep(step - 1);
  };

  const handleInputData = (input) => (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  const regUser = async (data) => {
    // console.log(data);

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      await axios.post(`${apiEnvUrl}/auth/register`, { username: data.username, email: data.email, password: data.password }, config).then((res) => {
        if (res.data.errors.length > 0) {
          alert(res.data.errors[0].message);
        }
      });
      // console.log(res.data.errors[0].message);
    } catch (error) {
      if (error.response && error.response.data.message) {
        return error.response.data.message;
      } else {
        return error.message;
      }
    }
  };

  switch (step) {
    case 1:
      return (
        <div className="App">
          <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <div className="card">
                    <div className="card-header pt-2 pb-2 text-center bg-primary">
                      <span>
                        <img src={require("../assets/images/logo.png")} alt="logo" height="100" />
                      </span>
                    </div>
                    {alertData && (
                      <div className="alert alert-danger bg-danger text-white border-0 fade show" role="alert">
                        <strong>Error - </strong> {alertData}
                      </div>
                    )}
                    {/* user signup start */}
                    <form className="needs-validation" noValidate>
                      <div className="card-body p-4">
                        <div className="text-center w-75 m-auto">
                          <h4 className="text-dark-50 text-center pb-0 fw-bold">User Info</h4>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <div className="mb-3">
                              <label htmlFor="firstname" className="form-label">
                                First Name
                              </label>
                              <input className="form-control" type="text" placeholder="Enter your First Name" name="firstname" {...register("firstname")} required />
                              <div className="invalid-feedback">Please enter First Name.</div>
                            </div>
                            <div className="mb-3" onChange={(e) => setPhoneValue(e.target.value)}>
                              <label htmlFor="phone" className="form-label">
                                Phone
                              </label>
                              {/* <PhoneInput value={phoneValue} {...register("phone")} name="phone" required className="form-control" placeholder="(999) 999-9999" /> */}
                              <input className="form-control" type="text" placeholder="Enter your Phone" name="phone" {...register("phone")} required />
                              <div className="invalid-feedback">Please enter Phone.</div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="mb-3">
                              <label htmlFor="lastname" className="form-label">
                                Last Name
                              </label>
                              <input className="form-control" type="text" placeholder="Enter your Last Name" name="lastname" {...register("lastname")} required />
                              <div className="invalid-feedback">Please enter Last Name.</div>
                            </div>

                            <div className="mb-3">
                              <label htmlFor="extension" className="form-label">
                                Extention
                              </label>
                              <input className="form-control" type="text" placeholder="Enter your extension" name="extension" {...register("extension")} required />
                              <div className="invalid-feedback">Please enter extension.</div>
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label htmlFor="username" className="form-label">
                            Username
                          </label>
                          <input className="form-control" type="text" placeholder="Enter your username" name="username" {...register("username")} required />
                          <div className="invalid-feedback">Please enter valid username.</div>
                        </div>
                        <div className="mb-3">
                          <label htmlFor="emailaddress" className="form-label">
                            Email address
                          </label>
                          <input className="form-control" type="text" placeholder="Enter your email" name="email" {...register("email")} required />
                          <div className="invalid-feedback">Please enter valid email.</div>
                        </div>
                        <div className="mb-3">
                          <label htmlFor="password" className="form-label">
                            Password
                          </label>
                          <div className="input-group input-group-merge">
                            <input className="form-control" type={showPass ? "text" : "password"} placeholder="Enter your password" {...register("password")} required />
                            <div className="input-group-text" data-password="false" onClick={() => setShowPass(!showPass)}>
                              <span className="password-eye"></span>
                            </div>
                          </div>
                        </div>

                        <div className="mb-3">
                          <div className="input-group input-group-merge">
                            <input type="checkbox" required name="termscheck" checked={termsCheck} onChange={() => setTermsCheck(!termsCheck)} />
                            <label htmlFor="termscheck" className="form-label" style={{ marginLeft: "10px", marginTop: "8px" }}>
                              I have read and and agre to terms and conditions.
                            </label>
                          </div>
                        </div>

                        <div className="mb-3 mb-0 text-center">
                          <button className="btn btn-primary" type="button" disabled={loading} onClick={nextStep}>
                            Next
                          </button>
                          {/* <button className="btn btn-primary" type="submit" disabled={loading}>
                            Next
                          </button> */}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );

    case 2:
      return (
        <div className="App">
          <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <div className="card">
                    <div className="card-header pt-2 pb-2 text-center bg-primary">
                      <span>
                        <img src={require("../assets/images/logo.png")} alt="logo" height="100" />
                      </span>
                    </div>
                    {/* Company info start */}
                    <form className="needs-validation" noValidate onSubmit={handleSubmit(regUser)}>
                      <div className="card-body p-4">
                        <div className="text-center w-75 m-auto">
                          <h4 className="text-dark-50 text-center pb-0 fw-bold">Company Info</h4>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <div className="mb-3">
                              <label htmlFor="emailaddress" className="form-label">
                                Company name
                              </label>
                              <input className="form-control" type="text" placeholder="Enter your compnay name" name="compnayname" {...register("compnayname")} required />
                              <div className="invalid-feedback">Please enter company name.</div>
                            </div>
                            <div className="mb-3">
                              <label htmlFor="emailaddress" className="form-label">
                                Address line 1
                              </label>
                              <input className="form-control" type="text" placeholder="Enter your address line 1" name="address1" {...register("address1")} required />
                              <div className="invalid-feedback">Please enter Address line 1.</div>
                            </div>
                            <div className="mb-3">
                              <label htmlFor="emailaddress" className="form-label">
                                Zip
                              </label>
                              <input className="form-control" type="text" placeholder="Enter your address line 2" name="address2" {...register("address2")} required />
                              <div className="invalid-feedback">Please enter Address Line 2.</div>
                            </div>
                            <div className="mb-3">
                              <label htmlFor="emailaddress" className="form-label">
                                country
                              </label>
                              <input className="form-control" type="text" placeholder="Enter your country" name="country" {...register("country")} required />
                              <div className="invalid-feedback">Please enter country.</div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="mb-3">
                              <label htmlFor="emailaddress" className="form-label">
                                City
                              </label>
                              <input className="form-control" type="text" placeholder="Enter your city" name="city" {...register("city")} required />
                              <div className="invalid-feedback">Please enter City.</div>
                            </div>
                            <div className="mb-3">
                              <label htmlFor="emailaddress" className="form-label">
                                State
                              </label>
                              <input className="form-control" type="text" placeholder="Enter your State" name="state" {...register("state")} required />
                              <div className="invalid-feedback">Please enter State.</div>
                            </div>
                            <div className="mb-3">
                              <label htmlFor="emailaddress" className="form-label">
                                Zip
                              </label>
                              <input className="form-control" type="text" placeholder="Enter your Zip" name="zip" {...register("zip")} required />
                              <div className="invalid-feedback">Please enter Zip.</div>
                            </div>
                            {/* <div className="mb-3">
                              <label htmlFor="emailaddress" className="form-label">
                                country
                              </label>
                              <input className="form-control" type="text" placeholder="Enter your country" name="country" {...register("country")} required />
                              <div className="invalid-feedback">Please enter country.</div>
                            </div> */}
                          </div>
                        </div>

                        <div className="holder d-flex justify-content-between">
                          <div className="mb-3 mb-0 text-center">
                            <button className="btn btn-primary" type="button" disabled={loading} onClick={prevStep}>
                              Back
                            </button>
                          </div>
                          <div className="mb-3 mb-0 text-center">
                            <button className="btn btn-primary" type="submit" disabled={loading}>
                              Register
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <StepOne nextStep={nextStep} handleFormData={handleInputData} values={formData} /> */}
          {/* <Container>
            <Row>
              <Col md={{ span: 6, offset: 3 }} className="custom-margin">
                <StepTwo
                  nextStep={nextStep}
                  prevStep={prevStep}
                  handleFormData={handleInputData}
                  values={formData}
                />
              </Col>
            </Row>
          </Container> */}
        </div>
      );
    // Only formData is passed as prop to show the final value at form submit
    // case 3:
    //   return (
    //     <div className="App">
    //       <Container>
    //         <Row>
    //           <Col md={{ span: 6, offset: 3 }} className="custom-margin">
    //             <Final values={formData} />
    //           </Col>
    //         </Row>
    //       </Container>
    //     </div>
    //   );
    // default case to show nothing
    default:
      return <div className="App">default app</div>;
  }
  // useEffect(() => {
  //   document.querySelectorAll(".needs-validation").forEach((form) => {
  //     form.addEventListener(
  //       "submit",
  //       (event) => {
  //         if (!form.checkValidity()) {
  //           event.preventDefault();
  //           event.stopPropagation();
  //         }

  //         form.classList.add("was-validated");
  //       },
  //       false
  //     );
  //   });

  //   if (userInfo) navigate("/dashboard");
  // }, [navigate, userInfo]);

  // const submitForm = (data) => {
  //   dispatch(registerUser(data));
  // };

  // return (
  //   <>
  // <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
  //   <div className="container">
  //     <div className="row justify-content-center">
  //       <div className="col-xxl-4 col-lg-5">
  //         <div className="card">
  //           <div className="card-header pt-2 pb-2 text-center bg-primary">
  //             <a href="index.html">
  //               <span>
  //                 <img
  //                   src={require("../assets/images/logo.png")}
  //                   alt="logo"
  //                   height="100"
  //                 />
  //               </span>
  //             </a>
  //           </div>
  //           {/* user signup start */}
  //           <form
  //             className="needs-validation"
  //             onSubmit={handleSubmit(submitForm)}
  //             noValidate
  //           >
  //             <div className="card-body p-4">
  //               <div className="text-center w-75 m-auto">
  //                 <h4 className="text-dark-50 text-center pb-0 fw-bold">
  //                   User Info
  //                 </h4>
  //                 {/* <p className='text-muted mb-4'>
  //                 Enter your email address and password to access admin panel.
  //               </p> */}
  //               </div>

  //               <div className="mb-3">
  //                 <label htmlFor="emailaddress" className="form-label">
  //                   Username
  //                 </label>
  //                 <input
  //                   className="form-control"
  //                   type="text"
  //                   placeholder="Enter your username"
  //                   name="username"
  //                   {...register("username")}
  //                   required
  //                 />
  //                 <div className="invalid-feedback">
  //                   Please enter valid email.
  //                 </div>
  //               </div>
  //               <div className="mb-3">
  //                 <label htmlFor="emailaddress" className="form-label">
  //                   Email address
  //                 </label>
  //                 <input
  //                   className="form-control"
  //                   type="text"
  //                   placeholder="Enter your email"
  //                   name="email"
  //                   {...register("email")}
  //                   required
  //                 />
  //                 <div className="invalid-feedback">
  //                   Please enter valid email.
  //                 </div>
  //               </div>

  //               <div className="mb-3">
  //                 <label htmlFor="password" className="form-label">
  //                   Password
  //                 </label>
  //                 <div className="input-group input-group-merge">
  //                   <input
  //                     className="form-control"
  //                     type={showPass ? "text" : "password"}
  //                     placeholder="Enter your password"
  //                     {...register("password")}
  //                     required
  //                   />
  //                   <div
  //                     className="input-group-text"
  //                     data-password="false"
  //                     onClick={() => setShowPass(!showPass)}
  //                   >
  //                     <span className="password-eye"></span>
  //                   </div>
  //                 </div>
  //               </div>

  //               <div className="mb-3 mb-0 text-center">
  //                 <button
  //                   className="btn btn-primary"
  //                   type="button"
  //                   onClick={() => setShowCompanyInfoForm(true)}
  //                   disabled={loading}
  //                 >
  //                   Next
  //                 </button>
  //               </div>
  //             </div>
  //                 {/* company signup start */}
  //                 <div className="card-body p-4">
  //                   <div className="text-center w-75 m-auto">
  //                     <h4 className="text-dark-50 text-center pb-0 fw-bold">
  //                       Company Info
  //                     </h4>
  //                     {/* <p className='text-muted mb-4'>
  //                     Enter your email address and password to access admin panel.
  //                   </p> */}
  //                   </div>

  //                   <div className="mb-3">
  //                     <label htmlFor="emailaddress" className="form-label">
  //                       Username
  //                     </label>
  //                     <input
  //                       className="form-control"
  //                       type="text"
  //                       placeholder="Enter your username"
  //                       name="username"
  //                       {...register("username")}
  //                       required
  //                     />
  //                     <div className="invalid-feedback">
  //                       Please enter valid email.
  //                     </div>
  //                   </div>
  //                   <div className="mb-3">
  //                     <label htmlFor="emailaddress" className="form-label">
  //                       Email address
  //                     </label>
  //                     <input
  //                       className="form-control"
  //                       type="text"
  //                       placeholder="Enter your email"
  //                       name="email"
  //                       {...register("email")}
  //                       required
  //                     />
  //                     <div className="invalid-feedback">
  //                       Please enter valid email.
  //                     </div>
  //                   </div>

  //                   <div className="mb-3">
  //                     <a
  //                       href="pages-recoverpw.html"
  //                       className="text-muted float-end"
  //                     >
  //                       <small>Forgot your password?</small>
  //                     </a>
  //                     <label htmlFor="password" className="form-label">
  //                       Password
  //                     </label>
  //                     <div className="input-group input-group-merge">
  //                       <input
  //                         className="form-control"
  //                         type={showPass ? "text" : "password"}
  //                         placeholder="Enter your password"
  //                         {...register("password")}
  //                         required
  //                       />
  //                       <div
  //                         className="input-group-text"
  //                         data-password="false"
  //                         onClick={() => setShowPass(!showPass)}
  //                       >
  //                         <span className="password-eye"></span>
  //                       </div>
  //                     </div>
  //                   </div>

  //                   <div className="mb-3 mb-3">
  //                     <div className="form-check">
  //                       <input
  //                         type="checkbox"
  //                         className="form-check-input"
  //                         id="checkbox-signin"
  //                         name="remember"
  //                       />
  //                       <label
  //                         className="form-check-label"
  //                         htmlFor="checkbox-signin"
  //                       >
  //                         Remember me
  //                       </label>
  //                     </div>
  //                   </div>

  //                   <div className="mb-3 mb-0 text-center">
  //                     <button
  //                       className="btn btn-primary"
  //                       type="submit"
  //                       disabled={loading}
  //                     >
  //                       Sign Up
  //                     </button>
  //                   </div>
  //                 </div>
  //               </form>
  //             </div>

  //             <div className="row mt-3">
  //               <div className="col-12 text-center">
  //                 <p className="text-muted">
  //                   Don't have an account? Contact Admin
  //                 </p>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );
}

export default Register;
