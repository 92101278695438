import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { setNotification } from "../../../../features/Notification/notificationSlice";
import API from "services/axios";
import authHeader from "services/auth-header";
import ReactQuill from "react-quill";
import ImageWIthSignedUrl from "pages/DesignRequests/DesignRequest/ImageWIthSignedUrl";
import { useSearchParams } from "react-router-dom";
import GeneralDataSizes from "./GeneralDataSizes";

function GeneralDetails({
  generalData,
  setGeneralData,
  updateFunc,
  isViewOnly,
}) {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  /*   ALL STATES
   ********************************************* */
  const [productTypes, setProductTypes] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["clean"],
    ],
  };

  /*   ALL FUNCTIONS
   ********************************************* */
  // get All ProductTypes
  const getAllProductTypes = async () => {
    try {
      const { data } = await API.get("/productTypes", {
        headers: authHeader(),
      });
      setProductTypes(data);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  // Delete Quotations
  // const deleteProductImage = async (id) => {
  //   if (!previewImage) {
  //     return;
  //   }
  //   try {
  //     const { data } = await API.delete(`/wo-products/deleteImage/${id}`, {
  //       headers: authHeader(),
  //     });
  //     if (data?.message?.type === "success") {
  //       setProductImage(null);
  //       refreshFunc();
  //       return;
  //     }
  //   } catch (err) {
  //     if (!previewImage) {
  //       alert(err.message);
  //     }
  //   }
  // };

  const updateGeneralData = (data) => {
    let totalQuantity = 0;
    let totalPrice = 0;

    data.map((item) => {
      totalPrice +=
        parseFloat(item.price).toFixed(2) *
        parseFloat(item.quantity).toFixed(2);
      totalQuantity += parseInt(item.quantity);
    });
    let updatedGeneralData = {
      ...generalData,
      productSizes: JSON.stringify(data),
      totalPrice,
      totalQuantity,
    };
    setGeneralData(updatedGeneralData);
    return updatedGeneralData;
  };

  useEffect(() => {
    getAllProductTypes();
  }, []);

  const getBase64Image = async (e) => {
    const file = e.target.files[0];

    // const MAX_FILE_SIZE = 300 * 1024; // 800 KB in bytes
    // if (!["image/jpeg", "image/png"].includes(file.type)) {
    //   alert("Only JPG and PNG image files are allowed.");
    //   e.target.value = "";
    //   return;
    // }
    // if (file.size > MAX_FILE_SIZE) {
    //   alert(`File size exceeds limit (300 KB). Please select a smaller file.`);
    //   e.target.value = "";
    //   return;
    // }

    setGeneralData((prev) => {
      return { ...prev, file };
    });
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewImage(reader?.result);
    };
  };

  useEffect(() => {
    setPreviewImage(null);
  }, [searchParams]);

  return (
    <div>
      {JSON.stringify(
        generalData?.productImage
          ?.split("?")[0]
          ?.replaceAll("%20", " ")
          ?.replaceAll("%26", " ")
      )}
      <div className="d-flex">
        {/*   Left Panel
         ********************************************* */}
        <div
          className=" bg-white shadow-lg p-2 w-50  mb-2 me-2"
          style={{ borderRadius: 15 }}
        >
          <div className=" p-1">
            <div className="row mb-1">
              <label className="col-5">Product Type</label>
              <div className="col-7">
                <select
                  disabled={isViewOnly}
                  onChange={(e) =>
                    setGeneralData({
                      ...generalData,
                      productType: e.target.value,
                    })
                  }
                  value={generalData?.productType || ""}
                  className="form-select"
                  id="commision_type"
                  required
                >
                  <option value={""}>Select Product Type</option>
                  {productTypes &&
                    productTypes.map((productType, i) => (
                      <option key={i} value={productType.productTypes}>
                        {productType.productTypes}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="row mb-1">
              <label className="col-5">Product Style</label>
              <div className="col-7 d-flex align-items-center">
                <input
                  type="text"
                  className="form-control"
                  required
                  readOnly={isViewOnly}
                  value={generalData?.productStyle}
                  onChange={(e) =>
                    setGeneralData({
                      ...generalData,
                      productStyle: e.target.value,
                    })
                  }
                  placeholder="Enter product style"
                />
              </div>
            </div>
            <div className="row mb-1">
              <label className="col-5">Product Description</label>
              <div className="col-7 ">
                <input
                  type="text"
                  required
                  readOnly={isViewOnly}
                  value={generalData?.productDescription}
                  onChange={(e) =>
                    setGeneralData({
                      ...generalData,
                      productDescription: e.target.value,
                    })
                  }
                  className="form-control"
                  placeholder="Product description"
                />
              </div>
            </div>
            <div className="row mb-1">
              <label className="col-5">Product Fabric</label>
              <div className="col-7 ">
                <input
                  type="text"
                  required
                  readOnly={isViewOnly}
                  value={generalData?.productFabric}
                  onChange={(e) =>
                    setGeneralData({
                      ...generalData,
                      productFabric: e.target.value,
                    })
                  }
                  className="form-control"
                  placeholder="Product fabric"
                />
              </div>
            </div>
            <div className="row mb-1">
              <label className="col-5">Product Color</label>
              <div className="col-7 ">
                <input
                  type="text"
                  required
                  readOnly={isViewOnly}
                  value={generalData?.productColor}
                  onChange={(e) =>
                    setGeneralData({
                      ...generalData,
                      productColor: e.target.value,
                    })
                  }
                  className="form-control"
                  placeholder="Product color"
                />
              </div>
            </div>
            <div className="row mb-1">
              <label className="col-5">Team Name</label>
              <div className="col-7 ">
                <input
                  type="text"
                  required
                  readOnly={isViewOnly}
                  value={generalData?.teamName || ""}
                  onChange={(e) =>
                    setGeneralData({
                      ...generalData,
                      teamName: e.target.value,
                    })
                  }
                  className="form-control"
                  placeholder="Team Name"
                />
              </div>
            </div>

            <div className="row">
              <div className=" col-5 d-flex mb-1">
                <label className="text-nowrap ">Has Names</label>
                <div
                  className="d-flex form-switch "
                  style={{ paddingLeft: 48 }}
                >
                  <input
                    type="checkbox"
                    disabled={isViewOnly}
                    checked={generalData?.hasName ? true : false}
                    onChange={(e) => {
                      setGeneralData({
                        ...generalData,
                        hasName: e.target.checked,
                      });
                    }}
                    className="form-check-input "
                    style={{ width: 40 }}
                  />
                </div>
              </div>
              <div className="col-7 d-flex mb-1">
                <label className="text-nowrap">Has Numbers</label>
                <div
                  className="d-flex form-switch "
                  style={{ paddingLeft: 48 }}
                >
                  <input
                    type="checkbox"
                    disabled={isViewOnly}
                    checked={generalData?.hasNumbers ? true : false}
                    onChange={(e) =>
                      setGeneralData({
                        ...generalData,
                        hasNumbers: e.target.checked,
                      })
                    }
                    className="form-check-input "
                    style={{ width: 40 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*   Right Panel
         ********************************************* */}
        <GeneralDataSizes
          generalData={generalData}
          updateGeneralData={updateGeneralData}
          updateFunc={updateFunc}
          isViewOnly={isViewOnly}
        />
      </div>

      {/* Bottom Pane */}
      <div className="d-flex">
        {/*   Image select
         ********************************************* */}
        <div
          className="bg-white shadow-lg p-2 w-50 mb-2 me-2 d-flex flex-column justify-content-between "
          style={{ borderRadius: 15, minHeight: 300 }}
        >
          {generalData?.productImage && !previewImage ? (
            <ImageWIthSignedUrl
              imgStyle={{
                width: 500,
                height: 300,
              }}
              imgClasses="mb-2"
              path={generalData?.productImage}
              alt="Image"
            />
          ) : (
            <div className="fs-3 text-muted  text-center my-auto">
              {!previewImage && "NO Image Selected"}
            </div>
          )}

          {!generalData?.productImage && previewImage && (
            <img width={500} height={300} src={previewImage} alt="preview" />
          )}

          {generalData?.productImage ? (
            <button
              onClick={() => {
                setPreviewImage(null);
                setGeneralData((prev) => {
                  return { ...prev, productImage: null };
                });
              }}
              type="button"
              disabled={isViewOnly}
              className="btn btn-sm btn-danger"
            >
              Delete Image
            </button>
          ) : (
            <div className="">
              <input
                onChange={getBase64Image}
                type="file"
                className="form-control"
              />
            </div>
          )}
        </div>

        {/*   Notes
         ********************************************* */}
        <div
          className="bg-white shadow-lg p-2 w-50 mb-2 me-2 d-flex flex-column justify-content-between "
          style={{ borderRadius: 15, minHeight: 300 }}
        >
          <div className="">
            <ReactQuill
              modules={modules}
              theme="snow"
              value={generalData?.notes}
              onChange={(val) => setGeneralData({ ...generalData, notes: val })}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GeneralDetails;
