import React from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import api from "services/axios";

function CompaniesRegister() {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  let token = localStorage.getItem("userToken");

  const submitForm = (data) => {
    api
      .post(
        "/companies/register",
        { data },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => navigate("/companies", { replace: true }))
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <div className="container-fluid">
      {/* start page title */}
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <h4 className="page-title">Register Company</h4>
          </div>
        </div>
      </div>
      {/* end page title */}
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="tab-content">
                <div className="tab-pane show active" id="input-types-preview">
                  <form onSubmit={handleSubmit(submitForm)}>
                    <div className="row">
                      {/* start col 1 */}
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="simpleinput" className="form-label">
                            Email Company ID
                          </label>
                          <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                            {...register("emailcompany_id")}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="simpleinput" className="form-label">
                            Street Address 1
                          </label>
                          <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                            {...register("street_1")}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="simpleinput" className="form-label">
                            Street Address 2
                          </label>
                          <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                            {...register("street_2")}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="simpleinput" className="form-label">
                            City
                          </label>
                          <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                            {...register("city")}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="simpleinput" className="form-label">
                            State
                          </label>
                          <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                            {...register("state")}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="simpleinput" className="form-label">
                            Zip
                          </label>
                          <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                            {...register("zip")}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="simpleinput" className="form-label">
                            Country
                          </label>
                          <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                            {...register("country")}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="simpleinput" className="form-label">
                            Timezone
                          </label>
                          <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                            {...register("time_zone")}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="simpleinput" className="form-label">
                            Total Users
                          </label>
                          <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                            {...register("total_num_users")}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="simpleinput" className="form-label">
                            Account Executive
                          </label>
                          <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                            {...register("account_executive_id")}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="simpleinput" className="form-label">
                            Account Manager ID
                          </label>
                          <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                            {...register("account_manager_id")}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="simpleinput" className="form-label">
                            Preference
                          </label>
                          <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                            {...register("preference")}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="simpleinput" className="form-label">
                            UTM Campaign
                          </label>
                          <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                            {...register("utm_campaign")}
                          />
                        </div>
                      </div>
                      {/* end col 1*/}

                      {/* start col 2 */}
                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="simpleinput" className="form-label">
                            UTM Medium
                          </label>
                          <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                            {...register("utm_medium")}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="simpleinput" className="form-label">
                            UTM Source
                          </label>
                          <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                            {...register("utm_source")}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="simpleinput" className="form-label">
                            Deactivated At
                          </label>
                          <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                            {...register("deactivated_at")}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="simpleinput" className="form-label">
                            Balance
                          </label>
                          <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                            {...register("balance")}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="simpleinput" className="form-label">
                            Credit Balance
                          </label>
                          <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                            {...register("credit_balance")}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="simpleinput" className="form-label">
                            Last Invoice on
                          </label>
                          <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                            {...register("last_invoice_on")}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="simpleinput" className="form-label">
                            Subscription Plan
                          </label>
                          <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                            {...register("subscription_plan")}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="simpleinput" className="form-label">
                            Subscription Cancelled on
                          </label>
                          <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                            {...register("subscription_cancelled_on")}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="simpleinput" className="form-label">
                            Enabled Features
                          </label>
                          <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                            {...register("enabled_features")}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="simpleinput" className="form-label">
                            Segment Type
                          </label>
                          <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                            {...register("segment_type")}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="simpleinput" className="form-label">
                            Industry
                          </label>
                          <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                            {...register("industry")}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="simpleinput" className="form-label">
                            Is Active
                          </label>
                          <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                            {...register("is_active")}
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="simpleinput" className="form-label">
                            Reactivated At
                          </label>
                          <input
                            type="text"
                            id="simpleinput"
                            className="form-control"
                            {...register("reactivated_at")}
                          />
                        </div>
                      </div>
                      {/* end col 2*/}
                    </div>
                    <div className="d-flex flex-row-reverse">
                      <Link
                        to="/companies"
                        className="btn btn-danger"
                        style={{ "margin-left": "10px" }}
                      >
                        Cancel
                      </Link>
                      <button type="submit" className="btn btn-primary">
                        Company Register
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompaniesRegister;
