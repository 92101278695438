import { setNotification } from 'features/Notification/notificationSlice';
import React, { useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { useDispatch } from 'react-redux';
import authHeader from 'services/auth-header';
import API from 'services/axios';

function EmailFormatUpdate({ emailVariables, data, showUpdatePage, refreshFunc }) {
  const dispatch = useDispatch();
  /*   All States Below
   ********************************************* */
  const quillRef = useRef(null);

  const [formData, setFormData] = useState({ ...data });
  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
      ['clean'],
    ],
  };

  /*   All Functions Below
   ********************************************* */
  const updateTaxRateData = async (e) => {
    e.preventDefault();
    try {
      const resp = await API.patch(
        '/emailformat/',
        { data: { ...formData, id: data.id } },
        { headers: authHeader() }
      );

      if (resp.data.message.type === 'success') {
        refreshFunc();
        showUpdatePage(false);
        return;
      }
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: 'error',
        })
      );
    }
  };

  const insertVariable = (variable) => {
    const editor = quillRef.current.getEditor();
    const currentCursor = editor.getSelection();
    editor.pasteHTML(currentCursor.index, `&nbsp;${variable}&nbsp;`);
  };

  return (
    <div className='row'>
      <div className='col-12'>
        {/*    Update FORM START
         ********************************************* */}
        {data && (
          <form className='ps-3 pe-3' onSubmit={updateTaxRateData}>
            <div className='mb-3'>
              <h3>UPDATE {data?.title?.toUpperCase()}</h3>
            </div>
            {/* ----------  Fields  ----------- */}
            <div className='row'>
              {/* ----------  Left Section  ----------- */}
              <div className='col-12'>
                <div className='mb-3'>
                  <label htmlFor='taxAccount' className='form-label mb-0'>
                    Title
                  </label>
                  <input
                    className='form-control'
                    type='text'
                    value={formData?.title}
                    onChange={(e) => {
                      setFormData({ ...formData, title: e.target.value });
                    }}
                    required
                    id='taxAccount'
                    placeholder='Enter Title'
                  />
                </div>
                <div className='mb-3'>
                  <label htmlFor='taxAccount' className='form-label mb-0'>
                    Subject
                  </label>
                  <input
                    className='form-control'
                    type='text'
                    value={formData?.subject}
                    onChange={(e) => {
                      setFormData({ ...formData, subject: e.target.value });
                    }}
                    required
                    id='taxAccount'
                    placeholder='Enter Subject'
                  />
                </div>
                <div
                  className='mb-3 d-flex p-1 px-2 bg-secondary bg-opacity-25'
                  style={{ borderRadius: 5 }}
                >
                  <label
                    htmlFor='taxAccount'
                    className='form-label mb-0 me-3 fw-bolder align-self-center'
                  >
                    VARIABLES
                  </label>
                  <div className='d-flex flex-wrap pt-1'>
                    {Object.keys(emailVariables).map((key) => (
                      <div
                        key={key}
                        className='bg-white me-1 mb-1 px-2 py-1 btn fw-bold'
                        style={{ borderRadius: 10, boxShadow: '1px 1px 3px gray' }}
                        onClick={(e) => {
                          insertVariable(emailVariables[key]);
                        }}
                      >
                        {key}
                      </div>
                    ))}
                  </div>
                </div>

                <div className='bg-white'>
                  <ReactQuill
                    ref={quillRef}
                    modules={modules}
                    theme='snow'
                    value={formData?.format}
                    onChange={(val) => setFormData({ ...formData, format: val })}
                  />
                </div>
              </div>
            </div>

            {/*   Buttons
             ********************************************* */}
            <div className={`mt-4 mb-2 d-flex justify-content-end`}>
              <div>
                <button
                  className='btn px-1 btn-secondary'
                  type='button'
                  onClick={() => {
                    showUpdatePage(false);
                  }}
                >
                  Cancel
                </button>
                <button className='btn btn-primary ms-1' type='submit'>
                  UPDATE
                </button>
              </div>
            </div>
          </form>
        )}
        {/*    Update FORM END
         ********************************************* */}
      </div>
    </div>
  );
}

export default EmailFormatUpdate;
