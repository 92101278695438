import ImgWithSignedUrl from "components/SignedUrl/ImgWithSignedUrl";
import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import { getCompany } from "features/company/companyActions";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import API from "services/axios";

export default function CompInfo({ companyInfo, refreshFunc }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.userInfo);

  /*   All States
   ********************************************* */
  const [showModal, setShowModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [companyData, setCompanyData] = useState({});
  const [file, setFile] = useState(null);

  /*   All Functions
   ********************************************* */
  const updateCompanyInfo = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("companyData", JSON.stringify(companyData));
    formData.append(
      "folder",
      `kms/companyLogos/${user?.companyId}/companyLogo`
    );
    formData.append("companyId", user?.companyId);

    try {
      await API.post("/companies/update-logo/" + user.companyId, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      await refreshFunc();
      dispatch(getCompany());
      setLoading(false);
      setShowModal(false);
    } catch (err) {
      setLoading(false);
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      setShowModal(false);
    }
  };

  const resetState = () => {};

  const updateField = (e) => {
    setCompanyData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  // Getting 12 Hours Time from SQL timestamp
  const convertSqlTimestampToDate = (sqlTime) => {
    const timestamp = sqlTime;
    const date = new Date(timestamp);
    const time = date.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    return time;
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    setCompanyData({
      email: companyInfo?.email,
      phone: companyInfo?.phone,
      taxId: companyInfo?.taxId,
      timeZone: companyInfo?.timeZone,
    });
  }, [companyInfo]);
  return (
    <div
      className="p-4 pt-2 d-flex mb-2 bg-white shadow-sm "
      style={{ borderRadius: 10 }}
    >
      <div className="row d-flex flex-grow-1">
        <div className="col-6 py-2 flex-grow-1 ">
          {/* Company Info */}
          <div className="d-flex justify-content-between">
            <div className="py-0 fw-bold fs-3 text-left">Company Info</div>
            <button
              onClick={() => {
                setShowModal(true);
              }}
              className="btn btn-sm btn-primary mb-1"
            >
              Edit
            </button>
          </div>
          <hr className="my-0 mb-3" />
          {/* Company Name */}
          <div className="row mb-3">
            <div className="col-12 fw-bold fs-2 text-left">
              {companyInfo?.name}
            </div>
          </div>
          {/* Subscription Plan */}
          <div className="row mb-2">
            <div className="col-3 fw-bold">Subscription Plan</div>
            <div className="col-9">{companyInfo?.subscriptionPlan}</div>
          </div>

          {/* Company Phone Number */}
          <div className="row mb-2">
            <div className="col-6 fw-bold">Phone No#</div>
            <div className="col-6">{companyInfo?.phone}</div>
          </div>
          {/* Company Phone Number */}
          <div className="row mb-2">
            <div className="col-6 fw-bold">Email</div>
            <div className="col-6">{companyInfo?.email}</div>
          </div>
          {/* Time Zone */}
          <div className="row mb-2">
            <div className="col-6 fw-bold">Tax Id</div>
            <div className="col-6">{companyInfo?.taxId}</div>
          </div>
          {/* Time Zone */}
          <div className="row mb-2">
            <div className="col-6 fw-bold">Time Zone</div>
            <div className="col-6">{companyInfo?.timeZone}</div>
          </div>
          {/* Created At */}
          <div className="row mb-2">
            <div className="col-6 fw-bold">Created At</div>
            <div className="col-6">
              {convertSqlTimestampToDate(companyInfo?.createdAt)}
            </div>
          </div>
        </div>
        {/* company Logo */}
        <div className="col-6 flex-grow-1 ">
          {/* Company Info */}
          <div className="py-0 fw-bold fs-3 text-left mt-1 ps-2 mb-1">
            Company Logo
          </div>
          <hr className="my-0 mb-3" />
          <span className="logo-lg">
            {companyInfo?.companyLogo && (
              <ImgWithSignedUrl
                width={300}
                height={300}
                path={companyInfo?.companyLogo}
              />
            )}
            {/* <img src={logo} alt="logo" height="50" /> */}
          </span>
        </div>
      </div>
      <Modal
        contentClassName="bg-transparent"
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
      >
        <Modal.Body className="bg-white px-4 py-3 pb-1 custom-border-radius custom-shadow-sm">
          {loading && <Spinner />}

          <h2>Update Company Info</h2>
          <form onSubmit={updateCompanyInfo} className="my-3">
            <div>
              <div className="mb-3">
                <label className="form-label mb-1">Phone</label>
                <input
                  className="form-control"
                  type="text"
                  name="phone"
                  value={companyData?.phone}
                  onChange={updateField}
                  placeholder="Phone"
                />
              </div>
              <div className="mb-3">
                <label className="form-label mb-1">Email</label>
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  value={companyData?.email}
                  onChange={updateField}
                  placeholder="Email"
                />
              </div>
              <div className="mb-3">
                <label className="form-label mb-1">Time Zone</label>
                <input
                  className="form-control"
                  type="text"
                  name="timeZone"
                  value={companyData?.timeZone}
                  onChange={updateField}
                  placeholder="Time Zone"
                />
              </div>
              <div className="mb-3">
                <label className="form-label mb-1">Tax Id</label>
                <input
                  className="form-control"
                  type="text"
                  name="taxId"
                  value={companyData?.taxId}
                  onChange={updateField}
                  placeholder="Tax Id"
                />
              </div>
              <div className="mb-3">
                <label className="form-label mb-1">
                  Logo ( W x H : 200px x 100px )
                </label>
                <input
                  className="form-control"
                  type="file"
                  name="taxId"
                  onChange={(e) => {
                    setFile(e.target.files[0]);
                  }}
                  placeholder="Tax Id"
                />
              </div>
            </div>
            {/* ******************** Buttons ************************* */}
            <div className="text-end mb-2">
              <button
                className="btn px-2 me-1 btn-secondary"
                type="button"
                onClick={() => {
                  setShowModal(false);
                  resetState();
                }}
              >
                Close
              </button>
              <button className="btn px-2 btn-primary" type="submit">
                Update
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
