// export default function HowToVideos() {
//   return (
//     <div style={{ marginLeft: 280, height: '90vh' }}>
//       <div>test</div>
//     </div>
//   );
// }

import Table from 'components/DataTable/Table';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import authHeader from 'services/auth-header';
import Modal from 'react-bootstrap/Modal';
import API from 'services/axios';
import { useDispatch } from 'react-redux';
import { setNotification } from 'features/Notification/notificationSlice';
import axios from 'axios';
import Spinner from 'components/Spinner';
import ReactQuill from 'react-quill';

export default function HowToVideos() {
  const user = useSelector((state) => state.user?.userInfo);
  const dispatch = useDispatch();

  /*   ALL STATES
   ********************************************* */
  const [filterBy, setFilterBy] = useState(null);
  const [howToVideosData, setHowToVideosData] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteID, setDeleteID] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showNotesModal, setShowNotesModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const [files, setFiles] = useState(null);
  const [newFile, setNewFile] = useState(null);
  const [uploadedIDList, setUploadedIDList] = useState([]);
  const [lastSortId, setLastSortId] = useState(null);

  const [howToTitle, setHowToTitle] = useState('');
  const [notes, setNotes] = useState('');

  const [newFileName, setNewFileName] = useState('');
  const [showViewVideoModal, setViewVideoModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(false);

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
      ['clean'],
    ],
  };
  /*   All Functions
   ********************************************* */
  //get Data
  const getHowToVideos = async () => {
    try {
      setLoading(true);
      const { data } = await API.get('howToVideos/listfiles', { headers: authHeader() });
      setHowToVideosData(data);
      setLoading(false);
      setLastSortId(data[data.length - 1]?.sortId ? data[data.length - 1]?.sortId : 0);
      setNotes('');
      setHowToTitle('');
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: 'error',
        })
      );
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    let allFiles = [];
    Array.from(event.target.files).map((i, index) => {
      let item = { id: index, file: event.target.files[index] };
      allFiles.push(item);
    });

    setFiles(allFiles);
  };

  const UpdateNotes = async () => {
    setLoading(true);
    const formData = new FormData();
    if (newFile) {
      formData.append('file', newFile);
    }
    formData.append('folder', `kms/howToVideos`);
    formData.append('key', selectedFile.howToVideo);
    formData.append('id', selectedFile.howToId);

    formData.append('title', howToTitle);
    formData.append('notes', notes);

    const { data } = await API.patch('/howToVideos/update', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    getHowToVideos();
    setLoading(false);
    setShowNotesModal(false);
  };

  const uploadFiles = async (file, sortID) => {
    const formData = new FormData();
    formData.append('file', file?.file);
    formData.append('folder', `kms/howToVideos`);

    formData.append('title', howToTitle);
    formData.append('notes', notes);
    formData.append('companyId', user?.companyId);

    if (file?.file == null) {
      dispatch(
        setNotification({
          message: 'No file selected for upload.',
          type: 'error',
        })
      );
      return;
    }
    await API.post('/howToVideos/upload', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    setUploadedIDList((prev) => prev?.concat(file?.id));
  };

  // Adds Button Function
  const handleUploadFiles = async (e) => {
    e.preventDefault();
    setLoading(true);

    const uploadPromises = files.map((file, index) => uploadFiles(file, lastSortId + index));

    try {
      // Wait for all file uploads to complete
      setLoading(true);
      await Promise.all(uploadPromises);
    } catch (error) {
      dispatch(
        setNotification({
          message: error.message,
          type: 'error',
        })
      );
      setLoading(false);
    }
    getHowToVideos();
    setFiles(null);
    setUploadedIDList([]);
    setLastSortId(null);
    setLoading(false);
    setShowAddModal(false);
  };

  const downloadFile = (url, fileKey) => {
    setLoading(true);

    const link = document.createElement('a');
    link.href = url;
    link.download = fileKey;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setLoading(false);
  };

  const deleteFile = async ({ id, file }) => {
    try {
      setLoading(true);
      const { data } = await API.post(
        '/howToVideos/delete-file',
        {
          file: file,
          id: id,
        },
        { headers: authHeader() }
      );
      dispatch(
        setNotification({
          message: data,
          type: 'success',
        })
      );
      getHowToVideos();
      setLoading(true);
      setShowDeleteModal();
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: 'error',
        })
      );
      setLoading(false);
    }
  };

  const getFileName = (fileName) => {
    return fileName?.split('/')[fileName?.split('/').length - 1];
  };

  // const UpdateNotes = async () => {
  //   const submitData = {
  //     notes,
  //     title: howToTitle,
  //     id: selectedFile.howToId,
  //   };

  //   const { data } = await API.patch(
  //     '/howToVideos/update',
  //     { data: submitData },
  //     { headers: authHeader() }
  //   );

  //   getHowToVideos();
  //   setShowNotesModal(false);
  // };

  // Sorting Up
  const sortUp = async (index) => {
    const currentSortId = howToVideosData[index].sortId;
    const currentId = howToVideosData[index].id;
    const upSortId = howToVideosData[index - 1].sortId;
    const upId = howToVideosData[index - 1].id;
    try {
      setLoading(true);
      const { data } = await API.post(
        '/howToVideos/sortup',
        { currentSortId, currentId, upSortId, upId },
        { headers: authHeader() }
      );
      if (data.success) {
        getHowToVideos();
        setLoading(false);
      }
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: 'error',
        })
      );
      setLoading(false);
    }
  };

  // Sorting Up
  const sortDown = async (index) => {
    const currentSortId = howToVideosData[index].sortId;
    const currentId = howToVideosData[index].id;
    const downSortId = howToVideosData[index + 1].sortId;
    const downId = howToVideosData[index + 1].id;
    try {
      setLoading(true);
      const { data } = await API.post(
        '/howToVideos/sortDown',
        { currentSortId, currentId, downSortId, downId },
        { headers: authHeader() }
      );
      if (data.success) {
        getHowToVideos();
      }
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: 'error',
        })
      );
      setLoading(false);
    }
  };

  // Getting 12 Hours Time from SQL timestamp
  const convertSqlTimestampToDate = (sqlTime) => {
    const timestamp = sqlTime;
    const date = new Date(timestamp);
    const time = date.toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    return time;
  };

  useEffect(() => {
    setFiles((prev) => prev?.filter((item) => uploadedIDList.includes(item.id)));
  }, [uploadedIDList]);

  useEffect(() => {
    getHowToVideos();
  }, []);

  useEffect(() => {
    setHowToTitle(selectedFile?.title);
    setNotes(selectedFile?.notes);
  }, [selectedFile]);

  const handleRenameFile = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const fileName = getFileName(selectedFile?.fileName);
      const length = selectedFile?.fileName.indexOf(fileName);

      const fileExt = '.' + fileName.split('.')[(fileName?.split('.')).length - 1];

      let filePath = selectedFile?.fileName.slice(0, length);
      let oldFileName = selectedFile?.fileName;
      let updatedFileName = filePath + newFileName + fileExt;

      const { data } = await API.post(
        '/howToVideos/rename',
        {
          oldFileName,
          newFileName: updatedFileName,
          id: selectedFile?.id,
        },
        { headers: authHeader() }
      );
      getHowToVideos();
      setLoading(true);
      setViewVideoModal(false);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: 'error',
        })
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    let fileNameWithExt = getFileName(selectedFile?.fileName);
    setNewFileName(fileNameWithExt?.slice(0, fileNameWithExt.length - 4));
  }, [selectedFile]);

  /*   Table functions
   ********************************************* */
  const btn1 = () => (
    <div>
      <>
        <button
          onClick={() => {
            setShowAddModal(true);
            setNotes('');
            setHowToTitle('');
          }}
          type='button'
          className='btn btn-primary  ms-1'
        >
          ADD FILES
        </button>
      </>
    </div>
  );

  const buttons = () => (
    <div className=' d-flex justify-content-end '>
      {/* FilterBy */}
      <button
        type='button'
        className='px-1 bg-primary btn text-dark bg-opacity-10 border border-primary rounded-end mx-2 rounded-start'
        data-bs-toggle='dropdown'
        aria-haspopup='true'
        aria-expanded='false'
      >
        {filterBy ? filterBy.toUpperCase().replaceAll('_', ' ') : 'Filter by All'}
      </button>
      <div className='dropdown-menu'>
        <a onClick={() => setFilterBy(null)} className='dropdown-item' role={'button'}>
          Filter by All
        </a>
        <a onClick={() => setFilterBy('title')} className='dropdown-item' role={'button'}>
          Title
        </a>
      </div>
    </div>
  );
  const table_head = ['Title', 'Actions'];

  const table_body = (item, index) => (
    <tr key={item?.id}>
      <td className='w-75'>{item.title}</td>
      <td className='w-25'>
        <Link
          onClick={() => {
            setSelectedFile(item);
            setNotes(item.notes);
            setHowToTitle(item.title);
            setShowNotesModal(true);
          }}
          className=' btn btn-success btn-sm'
        >
          Notes
        </Link>
        <Link
          onClick={async () => {
            let fileNameWithExt = await getFileName(selectedFile?.fileName);
            setNewFileName(fileNameWithExt?.slice(0, fileNameWithExt.length - 4));
            setSelectedFile(item);

            setViewVideoModal(true);
          }}
          className=' btn btn-info btn-sm mx-1'
        >
          View
        </Link>
        <button
          className='btn btn-success btn-sm px-1 '
          onClick={() => downloadFile(item.videoUrl, item.howToVideo)}
        >
          Downalod
        </button>

        <Link
          onClick={() => {
            setShowDeleteModal(true);
            setDeleteID({ id: item?.howToId, file: item.howToVideo });
          }}
          className='action-icon'
        >
          <i className='mdi mdi-delete'></i>
        </Link>
      </td>
    </tr>
  );
  return (
    <div
      className='flex-grow-1 py-4'
      style={{ marginLeft: 280, height: '90vh', paddingTop: 80, paddingRight: 30 }}
    >
      {/*   Table
       ********************************************* */}
      {!loading ? (
        <div className='px-2 flex-grow-1 d-flex '>
          <div className='horizontal-scrollable d-flex flex-grow-1'>
            {howToVideosData && (
              <Table
                filterBy={filterBy}
                buttons={buttons}
                btn1={btn1}
                table_head={table_head}
                table_body={table_body}
                table_data={howToVideosData}
              />
            )}
          </div>
        </div>
      ) : (
        <Spinner />
      )}
      {/*   ADD Modal
       ********************************************* */}
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Body className='shadow bg-white px-3 ' style={{ borderRadius: 15 }}>
          <div>
            <h4>ADD FILES</h4>
          </div>
          <hr className='mt-0' />
          <form onSubmit={handleUploadFiles}>
            <div className='row mb-3'>
              <label className='form-label my-auto col-1'>Title</label>
              <div className='col-11'>
                <input
                  type='text'
                  name='title'
                  value={howToTitle}
                  onChange={(e) => {
                    setHowToTitle(e.target.value);
                  }}
                  className='form-control'
                  placeholder='Enter Title For Video'
                />
              </div>
            </div>
            <div className='row mb-1'>
              <div className='col-12'>
                <input
                  type='file'
                  //   multiple
                  onChange={handleFileChange}
                  className='form-control'
                  placeholder='Enter id'
                />
              </div>
            </div>

            <div className='card mt-2 mb-3 '>
              <ReactQuill modules={modules} theme='snow' defaultValue={notes} onChange={setNotes} />
            </div>

            {/* <div className='py-2'>
              {files &&
                files.map((file, index) => (
                  <div
                    key={file.id}
                    className='bg-white px-2 rounded mb-2 d-flex justify-content-between align-items-center '
                    style={{
                      boxShadow:
                        'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                    }}
                  >
                    <div>{file.file.name}</div>
                    <i
                      onClick={() => {
                        setFiles((prev) => prev?.filter((item) => file.id != item.id));
                      }}
                      className='ri-close-circle-fill text-danger fs-3'
                    ></i>
                  </div>
                ))}
            </div> */}

            {/*   buttons
             ********************************************* */}
            <div className='my-2'>
              <div className='d-flex justify-content-end'>
                <button
                  onClick={() => {
                    setShowAddModal(false);
                    setFiles(null);
                    setUploadedIDList([]);
                    setLastSortId(null);
                    setLoading(false);
                  }}
                  className='btn btn-secondary btn-sm'
                  type='button'
                >
                  Close
                </button>
                <button disabled={loading} className='btn btn-primary btn-sm ms-1' type='submit'>
                  ADD
                </button>
              </div>
            </div>
          </form>
          {loading && <Spinner />}
        </Modal.Body>
      </Modal>
      {/*   Delete Modal
       ********************************************* */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Body className='bg-danger'>
          <div>
            <div className='text-center text-white'>
              <i className='ri-close-circle-line h1'></i>
              <h4 className='mt-2'>Confirm Delete!</h4>
              <p className='mt-3'>Do You want to delete ?</p>
              <button
                type='button'
                onClick={() => setShowDeleteModal(false)}
                className='btn btn-light my-2 mx-2'
              >
                Cancel
              </button>
              <button
                type='button'
                className='btn btn-outline-light my-2 '
                data-bs-dismiss='modal'
                onClick={() => {
                  deleteFile(deleteID);
                }}
              >
                DELETE
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/*   View Video Modal
       ********************************************* */}
      <Modal
        show={showViewVideoModal}
        contentClassName='bg-transparent'
        onHide={() => setViewVideoModal(false)}
        size='xl'
      >
        <Modal.Body className='bg-white custom-border-radius'>
          <div className='my-2 text-end'>
            <button
              type='button'
              onClick={() => {
                setViewVideoModal(false);
              }}
              className='btn btn-secondary align-self-end'
            >
              Close
            </button>
          </div>
          <div className='px-2 py-4 flex-center flex-column '>
            <video autoPlay width='1080' height='720' controls>
              <source src={selectedFile?.videoUrl} type='video/mp4' />
              Your browser does not support the video tag.
            </video>
          </div>
        </Modal.Body>
      </Modal>
      {/*   Notes Modal
       ********************************************* */}
      <Modal show={showNotesModal} onHide={() => setShowNotesModal(false)}>
        <Modal.Body>
          <h3> {selectedFile.title} Notes</h3>
          <div className='row my-3'>
            <label className='form-label my-auto col-1'>Title</label>
            <div className='col-11'>
              <input
                type='text'
                name='title'
                value={howToTitle}
                onChange={(e) => {
                  setHowToTitle(e.target.value);
                }}
                className='form-control'
                placeholder='Enter Title For Video'
              />
            </div>
          </div>
          <div className='row mb-1'>
            <div className='col-12'>
              <input
                type='file'
                //   multiple
                onChange={(e) => {
                  setNewFile(e.target.files[0]);
                }}
                className='form-control'
                placeholder='Enter id'
              />
            </div>
          </div>
          <div className='card mt-2 mb-3 '>
            <ReactQuill
              modules={modules}
              theme='snow'
              defaultValue={selectedFile?.notes}
              onChange={setNotes}
            />
          </div>
          <div className='d-flex justify-content-end'>
            <button className='btn btn-secondary  mx-1 ' onClick={() => setShowNotesModal(false)}>
              Close
            </button>

            <button
              disabled={loading}
              type='button'
              className='btn btn-primary'
              onClick={UpdateNotes}
            >
              Save Changes
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
