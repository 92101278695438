import Table from "components/DataTable/Table";
import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";

function UpdateWOCustomer({ selectedWorkOrder, showModal, refreshFunc }) {
  const dispatch = useDispatch();

  /*   All States
   ********************************************* */
  const [customerData, setCustomerData] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(
    selectedWorkOrder?.customer
  );
  const [filterBy, setFilterBy] = useState();

  const [loading, setLoading] = useState(false);

  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  /*   All functions
   ********************************************* */
  const getCustomersData = async () => {
    try {
      loadingOn();
      const { data } = await API.get("/customers", { headers: authHeader() });
      setCustomerData(data);
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const handleCustomerSelect = (item) => {
    setSelectedCustomer(item);
  };

  const handleWOInfo = async () => {
    if (!selectedCustomer) {
      return alert("Please Select a customer");
    }

    let apiData = { data: {} };

    if (
      !apiData?.id &&
      selectedCustomer?.id === selectedWorkOrder?.customerId
    ) {
      return showModal(false);
    }

    let billingAddress = {
      description: "",
      addressCompany: selectedCustomer?.addressCompany,
      addressLine1: selectedCustomer?.addressLine1,
      addressLine2: selectedCustomer?.addressLine2,
      city: selectedCustomer?.addressCity,
      state: selectedCustomer?.addressState,
      zip: selectedCustomer?.addressZipCode,
      country: selectedCustomer?.addressCountry,
    };
    let shippingAddress = {
      description: "",
      addressCompany: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zip: "",
      country: "",
    };

    var currentDate = new Date();
    var formattedDateTime = currentDate
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    let WoDetails = {
      locationId: selectedCustomer?.locationId,
      taxExamption: selectedCustomer?.isTaxExamption,
      taxRateId: selectedCustomer?.taxAccountId,
      paymentTermsId: selectedCustomer?.paymentTermsId,
      salesPersonId: selectedCustomer?.salesPersonId,
      WODate: formattedDateTime,
      WOShipDate: formattedDateTime,
    };

    apiData.id = selectedWorkOrder?.id;

    apiData.data = {
      ...apiData.data,
      ...WoDetails,
      customerId: selectedCustomer?.id,
      customerName: selectedCustomer?.customerName,
      billingAddress,
      shippingAddress,
    };

    try {
      loadingOn();
      const { data } = await API.patch("/work-orders", apiData, {
        headers: authHeader(),
      });

      if (data?.message?.type === "success") {
        await refreshFunc();
        loadingOff();
        showModal(false);
        return;
      }
      console.log(data);
      alert("something went wrong! Check Logs.");
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  useEffect(() => {
    getCustomersData();
  }, []);
  /*   ALL TABLES FUNCTIONS
   ********************************************* */
  const buttons = () => (
    <div className=" d-flex justify-content-end ">
      {/* FilterBy */}
      <button
        type="button"
        className="px-1 bg-primary btn text-dark bg-opacity-10 border border-primary rounded-end mx-2 rounded-start"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {filterBy
          ? filterBy.toUpperCase().replaceAll("_", " ")
          : "Filter by All"}
      </button>
      <div className="dropdown-menu">
        <button onClick={() => setFilterBy(null)} className="dropdown-item">
          Filter by All
        </button>
        <button
          onClick={() => setFilterBy("companyName")}
          className="dropdown-item"
        >
          Company Name
        </button>
        <button
          onClick={() => setFilterBy("externalCustomerId")}
          className="dropdown-item"
        >
          External ID
        </button>
        <button
          onClick={() => setFilterBy("website")}
          className="dropdown-item"
        >
          Website
        </button>
        <button
          onClick={() => setFilterBy("isTaxExemption")}
          className="dropdown-item"
        >
          Tax Exampted
        </button>
        <button
          onClick={() => setFilterBy("customerType")}
          className="dropdown-item"
        >
          Customer Type
        </button>
      </div>
    </div>
  );
  const table_head = [
    "ID",
    "Customer Name",
    "External ID",
    "Customer Website",
    "Tax Exempted",
    "Customer Type",
    "Actions",
  ];

  const table_body = (item) => (
    <tr className="" key={item.id}>
      <td className="py-1 my-0 align-middle">{item?.id}</td>
      <td className="py-1 my-0 align-middle">{item?.customerName} </td>
      <td className="py-1 my-0 align-middle">{item?.externalCustomerId}</td>
      <td className="py-1 my-0 align-middle">{item?.website}</td>
      <td className="py-1 my-0 align-middle">
        {item?.isTaxExemption?.toString()}{" "}
      </td>
      <td className="py-1 my-0 align-middle">{item?.customerType} </td>
      <td className="table-action py-1 my-0 align-middle">
        <div
          className="btn btn-sm btn-success"
          onClick={() => {
            handleCustomerSelect(item);
          }}
        >
          SELECT
        </div>
      </td>
    </tr>
  );
  return (
    <div className="px-4 py-2 bg-white shadow-lg" style={{ borderRadius: 10 }}>
      {loading && <Spinner />}
      <div className="d-flex mb-2 justify-content-between align-items-center">
        <h3>Update WO Info</h3>
        <div>
          <button
            disabled={!selectedCustomer}
            onClick={handleWOInfo}
            className="btn btn-success"
          >
            Save
          </button>
          <button
            onClick={() => {
              showModal(false);
            }}
            className="ms-1 btn btn-secondary"
          >
            Close
          </button>
        </div>
      </div>

      {selectedCustomer && (
        <div className="">
          <h4>Selected Customer</h4>
          <div className="bg-white w-full custom-shadow-sm custom-border-radius-sm px-2 py-1">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <span className="me-3">{selectedCustomer?.id}</span>
                <span>{selectedCustomer?.customerName}</span>
              </div>
              <div>
                <button
                  onClick={() => {
                    setSelectedCustomer(null);
                  }}
                  className="btn btn-sm py-0 btn-danger"
                >
                  <i className="mdi mdi-trash-can"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={`d-flex justify-content-end mt-3`}>
        <div className="horizontal-scrollable d-flex  flex-grow-1">
          {customerData && (
            <Table
              filterBy={filterBy}
              buttons={buttons}
              defaultLimit={5}
              table_head={table_head}
              table_body={table_body}
              table_data={customerData}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default UpdateWOCustomer;
