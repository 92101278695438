import React from 'react';
import { Link } from 'react-router-dom';

function Breadcrumbs({ current }) {
  return (
    <div className='page-title-right mb-0'>
      <ol className='breadcrumb m-0 py-0'>
        <li className='breadcrumb-item '>
          <Link to={`/`}> KMS </Link>
        </li>
        <li className='breadcrumb-item active '>{current}</li>
      </ol>
    </div>
  );
}

export default Breadcrumbs;
