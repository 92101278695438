import Table from "components/DataTable/Table";
import Spinner from "components/Spinner";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";
import * as XLSX from "xlsx";

import { useSearchParams } from "react-router-dom";
import GetWOProduct from "./AddFromPrevious/GetWOProduct";
import Email from "./email";
import WOProductAdd from "./WOProductAdd";
import WOProductUpdate from "./WOProductUpdate";
import ImportWOProductsExcel from "./ImportWOProductsExcel";

function WOProducts({ refreshFunc, selectedWorkOrder }) {
  const user = useSelector((state) => state.user?.userInfo);
  const [searchParams, setSearchParams] = useSearchParams();

  /*   ALL STATES
   ********************************************* */
  const [filterBy, setFilterBy] = useState(null);
  const [wOProductsData, setWOProductsData] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteID, setDeleteID] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showSelectProductModal, setShowSelectProductModal] = useState(false);
  const [showExcelImportModal, setShowExcelImportModal] = useState(false);

  const [selected, setSelected] = useState(null);
  const [lastSortId, setLastSortId] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const [productTypes, setProductTypes] = useState(null);

  /*   All Functions
   ********************************************* */

  const setUpdateId = (id) => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.set("productId", id);
      return newParams.toString();
    });
  };

  const removeUpdateId = () => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.delete("productId");
      return newParams.toString();
    });
  };

  // get All ProductTypes
  const getAllProductTypes = async () => {
    try {
      const { data } = await API.get("/productTypes", {
        headers: authHeader(),
      });
      setProductTypes(data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (!showUpdateModal) {
      removeUpdateId();
    }
  }, [showUpdateModal]);

  useEffect(() => {
    getAllProductTypes();
  }, []);

  // Delete WorkOrders
  const deleteWorkOrder = async (id) => {
    try {
      const { data } = await API.delete(`/wo-products/delete/${id}`, {
        headers: authHeader(),
      });
      if (data?.message?.type === "success") {
        await refreshFunc();
        setShowDeleteModal(false);
        setSelected(null);
        return;
      }
    } catch (err) {
      alert(err.message);
    }
  };

  // Sorting Up
  const sortUp = async (index) => {
    const currentSortId = wOProductsData[index]?.sortId;
    const currentId = wOProductsData[index]?.id;
    const upSortId = wOProductsData[index - 1].sortId;
    const upId = wOProductsData[index - 1].id;
    try {
      const { data } = await API.post(
        "/wo-products/sortup",
        { currentSortId, currentId, upSortId, upId },
        { headers: authHeader() }
      );
      if (data.success) {
        refreshFunc();
      }
    } catch (err) {
      alert(err.message);
    }
  };

  // Sorting Up
  const sortDown = async (index) => {
    const currentSortId = wOProductsData[index]?.sortId;
    const currentId = wOProductsData[index]?.id;
    const downSortId = wOProductsData[index + 1].sortId;
    const downId = wOProductsData[index + 1].id;
    try {
      const { data } = await API.post(
        "/wo-products/sortDown",
        { currentSortId, currentId, downSortId, downId },
        { headers: authHeader() }
      );
      if (data.success) {
        refreshFunc();
      }
    } catch (err) {
      alert(err.message);
    }
  };

  const getLastSortId = (products) => {
    return products[products.length - 1]?.sortId + 1 || 1;
  };

  function createAndDownloadExcel() {
    const productTypesList = productTypes.map((productType) => {
      let newItem = [productType.productTypes];
      return newItem;
    });

    // Create new workbook
    const workbook = XLSX.utils.book_new();

    // Create data for wo_products sheet
    const productSheetColumns = [
      [
        "productType",
        "productStyle",
        "productColor",
        "productDescription",
        "productFabric",
        "teamName",
        "notes",
      ],
    ];

    const woProductsWorksheet = XLSX.utils.aoa_to_sheet(productSheetColumns);
    XLSX.utils.book_append_sheet(workbook, woProductsWorksheet, "wo_products");

    // Create data for productTypes sheet
    const productTypesData = [["Product Types"], ...productTypesList];
    const productTypesWorksheet = XLSX.utils.aoa_to_sheet(productTypesData);
    XLSX.utils.book_append_sheet(
      workbook,
      productTypesWorksheet,
      "productTypes"
    );

    // Generate Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Create a blob and download
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Sample-WoProduct-ImportFile.xlsx";
    link.click();

    // Clean up
    URL.revokeObjectURL(url);
  }

  /*   ALL UseEffects
   ********************************************* */

  useEffect(() => {
    if (selectedWorkOrder) {
      let productsList = selectedWorkOrder?.WOProducts;
      let sortedProductList = productsList?.sort((a, b) => a.sortId - b.sortId);
      setLastSortId(
        sortedProductList[sortedProductList?.length - 1]?.sortId
          ? sortedProductList[sortedProductList?.length - 1]?.sortId
          : 0
      );
      setWOProductsData(sortedProductList);
    }
    // getWorkOrdersProducts();
    setSelected(null);
  }, [selectedWorkOrder, showUpdateModal, showAddModal]);

  /*   Table functions
   ********************************************* */
  const btn1 = () => (
    <div className="d-flex">
      {(user?.permissions?.includes("work_orders_add") ||
        user?.permissions?.includes("work_orders_dataEntry") ||
        user?.roles === "admin") && (
        <>
          <button
            onClick={() => setShowAddModal(true)}
            type="button"
            className="btn btn-primary  ms-1"
          >
            ADD Product
          </button>

          <button
            onClick={() => {
              setShowSelectProductModal(true);
            }}
            className="btn btn-info mx-1"
          >
            Add From Previous WO
          </button>

          <button
            onClick={() => {
              setShowExcelImportModal(true);
            }}
            className="btn btn-success"
          >
            Import From Excel
          </button>
          {productTypes && (
            <button
              onClick={() => {
                createAndDownloadExcel();
              }}
              className="btn btn-success ms-1"
            >
              <i className="mdi mdi-download"></i> Sample File
            </button>
          )}
        </>
      )}
    </div>
  );

  const buttons = () => (
    <div className=" d-flex justify-content-end ">
      {/* FilterBy */}
      <button
        type="button"
        className="px-1 bg-primary btn text-dark bg-opacity-10 border border-primary rounded-end mx-2 rounded-start"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {filterBy
          ? filterBy.toUpperCase().replaceAll("_", " ")
          : "Filter by All"}
      </button>
      <div className="dropdown-menu">
        <button onClick={() => setFilterBy(null)} className="dropdown-item">
          Filter by All
        </button>
        <button
          onClick={() => setFilterBy("productType")}
          className="dropdown-item"
        >
          Product type
        </button>
        <button
          onClick={() => setFilterBy("productStyle")}
          className="dropdown-item"
        >
          Product Style
        </button>
        <button
          onClick={() => setFilterBy("productColor")}
          className="dropdown-item"
        >
          Product Color
        </button>
        <button
          onClick={() => setFilterBy("productDescription")}
          className="dropdown-item"
        >
          Product Description
        </button>
        <button
          onClick={() => setFilterBy("totalQuantity")}
          className="dropdown-item"
        >
          Quantity
        </button>
      </div>
    </div>
  );
  const table_head = [
    "Item No",
    "Product Type",
    "Product Style",
    "Product Color",
    "Team Name",
    "Product Description",
    "Quantity",
    "Is Ready",
    "Actions",
  ];

  const table_body = (item, index) => (
    <tr
      onClick={() => setSelected(item)}
      className={selected?.id === item.id ? "bg-primary bg-opacity-25" : ""}
      key={item.id}
      onDoubleClick={() => {
        setShowUpdateModal(true);
        setUpdateId(item?.id);
      }}
    >
      <td className="py-0 align-middle">{item.sortId}</td>
      <td className="py-0 align-middle">{item.productType} </td>
      <td className="py-0 align-middle">{item.productStyle}</td>
      <td className="py-0 align-middle">{item.productColor} </td>
      <td className="py-0 align-middle">{item.teamName} </td>
      <td className="py-0 align-middle">{item.productDescription} </td>
      <td className="py-0 align-middle">{item.totalQuantity} </td>
      <td className="py-0 align-middle">{item.isReady ? "Yes" : "No"} </td>
      <td className="table-action py-0">
        <div
          onClick={() => {
            item.sortId !== 1 && sortUp(index);
          }}
          className="action-icon"
          style={{ cursor: "pointer" }}
        >
          <i className="mdi mdi-chevron-double-up"></i>
        </div>
        <div
          onClick={() => {
            item.sortId !== wOProductsData?.length && sortDown(index);
          }}
          className="action-icon"
          style={{ cursor: "pointer" }}
        >
          <i className="mdi mdi-chevron-double-down"></i>
        </div>
        {(user?.permissions?.includes("work_orders_update") ||
          user?.permissions?.includes("work_orders_dataEntry") ||
          user?.roles === "admin") && (
          <div className="action-icon">
            <i
              onClick={() => {
                setShowUpdateModal(true);
                setUpdateId(item?.id);
              }}
              className="mdi mdi-pencil"
            ></i>
          </div>
        )}
        {(user?.permissions?.includes("work_orders_delete") ||
          user?.permissions?.includes("work_orders_dataEntry") ||
          user?.roles === "admin") && (
          <div
            onClick={() => {
              setShowDeleteModal(true);
              setDeleteID(item.id);
            }}
            className="action-icon"
          >
            <i className="mdi mdi-delete"></i>
          </div>
        )}
      </td>
    </tr>
  );

  if (!wOProductsData) return <Spinner />;
  return (
    <div className="d-flex flex-grow-1" style={{ height: "auto" }}>
      {/*   Table
       ********************************************* */}
      <div
        className="px-3 d-flex flex-grow-1 "
        style={{ minHeight: "50vh", borderRadius: 15 }}
      >
        <div className="horizontal-scrollable  d-flex flex-grow-1 ">
          {wOProductsData && (
            <Table
              filterBy={filterBy}
              buttons={buttons}
              btn1={btn1}
              table_head={table_head}
              table_body={table_body}
              table_data={wOProductsData}
            />
          )}
        </div>
      </div>

      {/*   ADD Modal
       ********************************************* */}
      <Modal
        size="xl"
        show={showAddModal}
        // onHide={() => setShowAddModal(false)}
      >
        <Modal.Body>
          <div>
            <WOProductAdd
              selectedWorkOrder={selectedWorkOrder}
              showAddPage={setShowAddModal}
              refreshFunc={refreshFunc}
              lastSortId={lastSortId}
            />
          </div>
        </Modal.Body>
      </Modal>

      {/*   Update Modal
       ********************************************* */}
      <Modal
        size="xl"
        show={showUpdateModal}
        // onHide={() => setShowUpdateModal(false)}
      >
        <Modal.Body>
          <div>
            <WOProductUpdate
              wOProductsData={wOProductsData}
              selectedWorkOrder={selectedWorkOrder}
              setShowUpdateModal={setShowUpdateModal}
              refreshFunc={refreshFunc}
              setUpdateId={setUpdateId}
            />
          </div>
        </Modal.Body>
      </Modal>

      {/*   Email Modal
       ********************************************* */}
      <Modal
        size="lg"
        show={showEmailModal}
        onHide={() => setShowEmailModal(false)}
      >
        <Modal.Body>
          {selectedWorkOrder && (
            <Email
              selectedWorkOrder={selectedWorkOrder}
              setShowEmailModal={setShowEmailModal}
            />
          )}
        </Modal.Body>
      </Modal>

      {/*   SelectProduct Modal
       ********************************************* */}
      <Modal
        size="lg"
        show={showSelectProductModal}
        onHide={() => setShowSelectProductModal(false)}
        contentClassName="bg-transparent"
      >
        <Modal.Body>
          <GetWOProduct
            selectedWO={selectedWorkOrder}
            sortId={getLastSortId(wOProductsData)}
            refreshFunc={refreshFunc}
            showModal={setShowSelectProductModal}
          />
        </Modal.Body>
      </Modal>

      {/*   Import From Excel Modal
       ********************************************* */}
      <Modal
        size="lg"
        show={showExcelImportModal}
        onHide={() => setShowExcelImportModal(false)}
        contentClassName="bg-transparent"
      >
        <Modal.Body>
          <ImportWOProductsExcel
            selectedWO={selectedWorkOrder}
            refreshFunc={refreshFunc}
            showModal={setShowExcelImportModal}
          />
        </Modal.Body>
      </Modal>

      {/*   Delete Modal
       ********************************************* */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Body className="bg-danger">
          <div className="">
            <div className="text-center text-white">
              <i className="ri-close-circle-line h1"></i>
              <h4 className="mt-2">Confirm Delete!</h4>
              <p className="mt-3">Do You want to delete ?</p>
              <button
                type="button"
                onClick={() => setShowDeleteModal(false)}
                className="btn btn-light my-2 mx-2"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-outline-light my-2 "
                data-bs-dismiss="modal"
                onClick={() => {
                  deleteWorkOrder(deleteID);
                }}
              >
                DELETE
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default WOProducts;
