import Spinner from "components/Spinner";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomProduct from "./CustomProduct/CustomProduct";
import SelectAProduct from "./SelectAProduct/SelectAProduct";

export default function AddNewProduct({
  setShowAddModal,
  selectedProduct,
  selectedDesign,
  setDesignRequests,
  setSelectedDesign,
  setSelectedProduct,
}) {
  /*   All States
   ********************************************* */
  const [activeTab, setActiveTab] = useState("custom Product");

  /*   All Functions
   ********************************************* */

  /*   All UseEffects
   ********************************************* */

  return (
    <div>
      <div className="">
        <h4>ADD Design</h4>
      </div>
      <hr className="mt-0 " />

      {activeTab === "custom Product" && (
        <CustomProduct
          selectedDesign={selectedDesign}
          setSelectedDesign={setSelectedDesign}
          setDesignRequests={setDesignRequests}
          setShowAddModal={setShowAddModal}
        />
      )}
    </div>
  );
}
