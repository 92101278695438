import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import authHeader from 'services/auth-header';
import API from 'services/axios';

function PaymentsTermsUpdate({ data, showUpdatePage, refreshFunc }) {
  /*   All Satates Below
   ********************************************* */
  const { register, handleSubmit } = useForm();
  const [formError, setFormError] = useState({});

  /*   All Functions Below
   ********************************************* */
  const updatePaymentTermsData = async (formData, e) => {
    setFormError({});
    e.preventDefault();
    if (formData.termName === '') {
      setFormError({
        termName: { type: 'termName', message: 'Location name is required' },
      });
      return;
    }
    try {
      const resp = await API.patch(
        '/paymentTerms/',
        { data: { ...formData, id: data.id } },
        { headers: authHeader() }
      );

      if (resp.data.message.type === 'success') {
        refreshFunc();
        showUpdatePage(false);
        return;
      }
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <div className='row'>
      <div className='col-12'>
        {/*    Update FORM START
         ********************************************* */}
        {data && (
          <form className='ps-3 pe-3' onSubmit={handleSubmit(updatePaymentTermsData)}>
            <div className='mb-3'>
              <h3>UPDATE {data?.termName?.toUpperCase()}</h3>
            </div>
            {/* ----------  Fields  ----------- */}
            <div className='mb-3'>
              <label htmlFor='termName' className='form-label'>
                Term Name
              </label>
              <input
                className='form-control'
                type='text'
                id='termName'
                defaultValue={data?.termName}
                placeholder='Enter term name'
                {...register('termName')}
              />
              {formError && formError.termName && (
                <p className='text-danger'>{formError.termName.message}</p>
              )}
            </div>
            <div className='mb-3'>
              <label htmlFor='companyName' className='form-label'>
                No of Days
              </label>
              <input
                className='form-control'
                type='text'
                id='noOfDays'
                placeholder='Enter no of days'
                defaultValue={data?.noOfDays}
                {...register('noOfDays')}
              />
            </div>

            <div className={`mt-4 mb-2 d-flex justify-content-end`}>
              <div>
                <button
                  className='btn px-1 btn-secondary'
                  type='button'
                  onClick={() => {
                    showUpdatePage(false);
                  }}
                >
                  Cancel
                </button>
                <button className='btn btn-primary ms-1' type='submit'>
                  UPDATE
                </button>
              </div>
            </div>
          </form>
        )}
        {/*    Update FORM END
         ********************************************* */}
      </div>
    </div>
  );
}

export default PaymentsTermsUpdate;
