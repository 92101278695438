import { Text, View } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";

export default function WOPdfInfo({ styles, selectedWO, productsList }) {
  /*   All States
   ********************************************* */
  const [productDetails, setProductDetails] = useState("");

  const customerInfo =
    typeof selectedWO?.contactData === "string"
      ? JSON.parse(selectedWO?.contactData)
      : selectedWO?.contactData;

  const shippingAddress =
    typeof selectedWO?.shippingAddress === "string"
      ? JSON.parse(selectedWO?.shippingAddress)
      : selectedWO?.shippingAddress;

  /*   All Functions
   ********************************************* */

  /*   All UseEffects
   ********************************************* */

  useEffect(() => {
    if (productsList) {
      let detail = productsList.reduce((acc, item) => {
        if (acc[item.productStyle]) {
          acc[item.productStyle] += parseInt(item.totalQuantity);
        } else {
          acc[item.productStyle] = item?.totalQuantity;
        }
        return acc;
      }, {});

      let formattedProductDetail = "";
      for (const [productStyle, totalQuantity] of Object.entries(detail)) {
        formattedProductDetail += `${productStyle} = ${totalQuantity}, `;
      }

      setProductDetails(formattedProductDetail);
    }
  }, [productsList]);
  return (
    <>
      <View style={styles.shippingDescription}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <View style={{ flexGrow: 1 }}>
            <Text style={styles.headingSecond}>Ordered By:</Text>
            <View
              style={{
                border: 1,
                borderRight: 0,
                paddingVertical: 2,
                paddingHorizontal: 5,
              }}
            >
              <Text style={styles.textbody}>
                {customerInfo?.firstName} {customerInfo?.lastName}
              </Text>
            </View>
          </View>
          <View style={{ flexGrow: 1 }}>
            <Text style={styles.headingSecond}>Phone:</Text>
            <View
              style={{
                border: 1,
                borderRight: 0,
                paddingVertical: 2,
                paddingHorizontal: 5,
              }}
            >
              <Text style={styles.textbody}>
                {customerInfo?.phone ? customerInfo?.phone : " "}
              </Text>
            </View>
          </View>
          <View style={{ flexGrow: 2 }}>
            <Text style={styles.headingSecond}>Email:</Text>
            <View
              style={{ border: 1, paddingVertical: 2, paddingHorizontal: 5 }}
            >
              <Text style={styles.textbody}>
                {customerInfo?.email ? customerInfo?.email : " "}
              </Text>
            </View>
          </View>
        </View>
      </View>

      <View style={{ ...styles.shippingDescription, marginTop: 5 }}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Text
            style={{
              ...styles.headingSecond,
              textAlign: "left",
            }}
          >
            Job Name:{" "}
            <Text style={{ textDecoration: "underline" }}>
              {selectedWO?.jobName}
            </Text>
          </Text>
        </View>
      </View>

      <View style={{ ...styles.shippingDescription }}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Text
            style={{
              ...styles.headingSecond,
              textAlign: "left",
            }}
          >
            Customer Name:{" "}
            <Text style={{ textDecoration: "underline" }}>
              {selectedWO?.customerName}
            </Text>
          </Text>
        </View>
      </View>

      <View style={{ ...styles.shippingDescription }}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Text
            style={{
              ...styles.headingSecond,
              textAlign: "left",
            }}
          >
            Shipping Address:
            {shippingAddress && (
              <Text style={{ textDecoration: "underline" }}>
                {shippingAddress?.addressCompany &&
                  shippingAddress?.addressCompany + ", "}
                {shippingAddress?.addressLine1 &&
                  shippingAddress?.addressLine1 + ", "}
                {shippingAddress?.addressLine2 &&
                  shippingAddress?.addressLine2 + ", "}
                {shippingAddress?.city && shippingAddress?.city + ", "}
                {shippingAddress?.state && shippingAddress?.state + ", "}
                {shippingAddress?.zip && shippingAddress?.zip + ", "}
                {shippingAddress?.country && shippingAddress?.country + ", "}
              </Text>
            )}
          </Text>
        </View>
      </View>
      <View style={{ ...styles.shippingDescription, marginTop: 10 }}>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Text
            style={{
              ...styles.headingSecond,
              textAlign: "left",
            }}
          >
            Product Details: ( Quantity: {productsList?.length} ){" "}
            {productDetails}
          </Text>
        </View>
      </View>
    </>
  );
}
