import Table from "components/DataTable/Table";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import authHeader from "services/auth-header";
import API from "services/axios";
import LocationsAdd from "./locationsAdd";
import LocationsUpdate from "./locationsUpdate";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import ImgWithSignedUrl from "components/SignedUrl/ImgWithSignedUrl";
import Spinner from "components/Spinner";

function Loations() {
  const user = useSelector((state) => state.user.userInfo);
  /*   All Satates Below
   ********************************************* */
  const [locationsData, setLocationsData] = useState(null);
  const [lastSortId, setLastSortId] = useState(null);

  // Modals
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // below update data is used to set a variable before moving to update component
  const [updateData, setUpdateData] = useState(null);
  const [loading, setLoading] = useState(false);

  const [selectedDelete, setSelectedDelete] = useState();

  /*   All Functions Below
   ********************************************* */
  // get All Locations
  const getAllLocations = async () => {
    try {
      setLoading(true);
      const { data } = await API.get("/locations", { headers: authHeader() });
      setLocationsData(data);
      setLastSortId(
        data[data.length - 1]?.sortId ? data[data.length - 1]?.sortId : 0
      );
      setLoading(false);
    } catch (err) {
      alert(err.message);
      setLoading(false);
    }
  };

  // Delete password Data
  const deleteLocationsData = async (deleteData) => {
    setLoading(true);
    const { data } = await API.post("/locations/delete/", deleteData, {
      headers: authHeader(),
    });
    if (data.message.type == "success") {
      setTimeout(() => {
        getAllLocations();
      }, 100);
      setLoading(false);
      setShowDeleteModal(false);
      return;
    }
  };

  // Sorting Up
  const sortUp = async (index) => {
    const currentSortId = locationsData[index].sortId;
    const currentId = locationsData[index].id;
    const upSortId = locationsData[index - 1].sortId;
    const upId = locationsData[index - 1].id;

    setLoading(true);
    try {
      const { data } = await API.post(
        "/locations/sortup",
        { currentSortId, currentId, upSortId, upId },
        { headers: authHeader() }
      );
      if (data.success) {
        getAllLocations();
        setLoading(false);
      }
    } catch (err) {
      alert(err.message);
      setLoading(false);
    }
  };

  // Sorting Up
  const sortDown = async (index) => {
    const currentSortId = locationsData[index].sortId;
    const currentId = locationsData[index].id;
    const downSortId = locationsData[index + 1].sortId;
    const downId = locationsData[index + 1].id;
    setLoading(true);
    try {
      const { data } = await API.post(
        "/locations/sortDown",
        { currentSortId, currentId, downSortId, downId },
        { headers: authHeader() }
      );
      if (data.success) {
        getAllLocations();
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      alert(err.message);
    }
  };

  useEffect(() => {
    getAllLocations();
  }, []);

  /*   Table Functions below
   ********************************************* */
  const buttons = () => (
    <div className="d-flex justify-content-end ms-2">
      <button onClick={() => setShowAddModal(true)} className="btn btn-primary">
        ADD NEW
      </button>
    </div>
  );
  const table_head = [
    "Location Name",
    "Company Name",
    // "Logo",
    "Pdf Logo",
    "Action",
  ];
  const table_body = (item, index) => (
    <tr key={item.id}>
      <td>{item.locationName}</td>

      <td>{item.companyName}</td>

      {/* <td>{item?.logo && <ImgWithSignedUrl path={item?.logo} width={35} />}</td> */}
      <td>
        {item?.pdfLogo && <ImgWithSignedUrl path={item?.pdfLogo} width={35} />}
      </td>

      <td className="table-action">
        {user?.roles === "admin" && (
          <Link
            onClick={() => {
              item.sortId != 1 && sortUp(index);
            }}
            className="action-icon"
          >
            <i className="mdi mdi-chevron-double-up"></i>
          </Link>
        )}
        {user?.roles === "admin" && (
          <Link
            onClick={() => {
              item.sortId != locationsData.length && sortDown(index);
            }}
            className="action-icon"
          >
            <i className="mdi mdi-chevron-double-down"></i>
          </Link>
        )}
        <Link className="action-icon">
          <i
            onClick={() => {
              setShowUpdateModal(true);
              setUpdateData(item);
            }}
            className="mdi mdi-pencil"
          ></i>
        </Link>
        <Link className="action-icon">
          <i
            data-bs-toggle="modal"
            data-bs-target="#danger-alert-modal"
            className="mdi mdi-delete"
            onClick={() => {
              setSelectedDelete(item);
              setShowDeleteModal(true);
            }}
          ></i>
        </Link>
      </td>
    </tr>
  );

  return (
    <div className="row">
      {loading && <Spinner />}
      <h3>Location</h3>
      <div className="col-12">
        <Table
          btn1={buttons}
          table_body={table_body}
          table_data={locationsData}
          table_head={table_head}
        />

        {/*   ADD Modal
         ********************************************* */}
        <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
          <Modal.Body className="custom-shadow">
            <div>
              <LocationsAdd
                showAddPage={setShowAddModal}
                refreshFunc={getAllLocations}
                lastSortId={lastSortId}
              />
            </div>
          </Modal.Body>
        </Modal>
        {/*   Update Modal
         ********************************************* */}
        <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
          <Modal.Body className="custom-shadow">
            <div>
              <LocationsUpdate
                data={updateData}
                showUpdatePage={setShowUpdateModal}
                refreshFunc={getAllLocations}
              />
            </div>
          </Modal.Body>
        </Modal>
        {/*   Confirmation Modal
         ********************************************* */}
        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <Modal.Body className="custom-shadow rounded-2 bg-danger text-white">
            <div className="text-center">
              <i className="ri-close-circle-line h1"></i>
              <h4 className="mt-2">Confirm Delete!</h4>
              <p className="mt-3">Do You want to delete ?</p>
              <button
                type="button"
                className="btn btn-light my-2 mx-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-outline-light text-white my-2 "
                data-bs-dismiss="modal"
                onClick={() => {
                  deleteLocationsData({
                    id: selectedDelete.id,
                    logo: selectedDelete?.logo,
                    pdfLogo: selectedDelete?.pdfLogo,
                  });
                }}
              >
                DELETE
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default Loations;
