import React, { useState } from "react";
import { useForm } from "react-hook-form";
import authHeader from "services/auth-header";
import API from "services/axios";

function PortalUsersUpdate({ data, showUpdatePage, refreshFunc }) {
  /*   All States Below
   ********************************************* */
  const { register, handleSubmit } = useForm();

  /*   All Functions Below
   ********************************************* */
  const updatePortalUserData = async (formData, e) => {
    e.preventDefault();

    try {
      const resp = await API.patch(
        "/portal-users/",
        { ...formData, id: data.id },
        { headers: authHeader() }
      );

      if (resp.data.message.type === "success") {
        refreshFunc();
        showUpdatePage(false);
        return;
      }
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        {/*    Update FORM START
         ********************************************* */}
        {data && (
          <form
            className="ps-3 pe-3"
            onSubmit={handleSubmit(updatePortalUserData)}
          >
            <div className="mb-3">
              <h3>UPDATE {data?.taxAccount?.toUpperCase()}</h3>
            </div>
            {/* ----------  Fields  ----------- */}
            <div className="row">
              <div className="col-12">
                <div className="mb-3">
                  <label htmlFor="firstName" className="form-label mb-0">
                    First Name
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    required
                    id="firstName"
                    placeholder="Enter First Name"
                    defaultValue={data?.firstName}
                    {...register("firstName")}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="lastName" className="form-label mb-0">
                    Last Name
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    required
                    id="lastName"
                    placeholder="Enter Last Name"
                    defaultValue={data?.lastName}
                    {...register("lastName")}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label mb-0">
                    Email
                  </label>
                  <input
                    className="form-control"
                    type="email"
                    required
                    id="email"
                    placeholder="Enter Email"
                    defaultValue={data?.email}
                    {...register("email")}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label mb-0">
                    Password
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="password"
                    placeholder="Enter Password"
                    {...register("password")}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="phone" className="form-label mb-0">
                    Phone
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    required
                    id="phone"
                    placeholder="Enter Phone"
                    defaultValue={data?.phone}
                    {...register("phone")}
                  />
                </div>
              </div>
            </div>

            {/*   Buttons
             ********************************************* */}
            <div className={`mt-4 mb-2 d-flex justify-content-end`}>
              <div>
                <button
                  className="btn px-1 btn-secondary"
                  type="button"
                  onClick={() => {
                    showUpdatePage(false);
                  }}
                >
                  Cancel
                </button>
                <button className="btn btn-primary ms-1" type="submit">
                  UPDATE
                </button>
              </div>
            </div>
          </form>
        )}
        {/*    Update FORM END
         ********************************************* */}
      </div>
    </div>
  );
}

export default PortalUsersUpdate;
