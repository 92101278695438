import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import BidPlanner from "pages/BidPlanner/BidPlanner";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function ImportBid({ refreshFunc, selectedQuote, updateBidId }) {
  const dispatch = useDispatch();
  /*   All States
   ********************************************* */
  const [showImportModal, setShowImportModal] = useState(null);
  const [loading, setLoading] = useState(false);

  const [selected, setSelected] = useState(null);

  /*   All Functions
   ********************************************* */
  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  const handleImportBid = async () => {
    try {
      loadingOn();
      if (!selected) {
        return dispatch(
          setNotification({
            message: "Please Select Bid",
            type: "error",
          })
        );
      }
      let patchBody = {
        id: selectedQuote?.id,
        bidId: selected.id,
        bidDate: selected?.bidDate,
        customerBidId: selected?.customerBidId,
        deliveryMethod: selected?.deliveryMethod,
        dueDate: selected?.dueDate,
        estimatedValue: selected?.estimatedValue,
        openingDate: selected?.openingDate,
        result: selected?.result,
        amendmentFollowUpDate: selected?.amendmentFollowUpDate,
        amendmentNotificationLocation: selected?.amendmentNotificationLocation,
        bidNotes: selected?.bidNotes,
      };
      const { data } = await API.patch("/quotes/import-bid", patchBody, {
        headers: authHeader(),
      });

      if (data?.type === "success") {
        dispatch(setNotification({ message: data.message, type: "success" }));
        await refreshFunc();
        setShowImportModal(false);
        loadingOff();
        return;
      }
      dispatch(
        setNotification({
          message: "something went wrong check logs",
          type: "error",
        })
      );
      console.log(data);

      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  /*   All UseEffects
   ********************************************* */

  return (
    <div>
      {loading && <Spinner />}
      <button
        onClick={() => {
          setShowImportModal(true);
        }}
        className="btn btn-info text-nowrap"
      >
        Add / Import Bid
      </button>
      {/*  Product Update
       ********************************************* */}
      <Modal
        size="lg"
        show={showImportModal}
        onHide={() => setShowImportModal(false)}
        contentClassName="bg-transparent"
      >
        <Modal.Body className="bg-white custom-border-radius-sm px-3">
          <h3>Select Bid</h3>
          {loading && <Spinner />}
          {selected && (
            <div className="my-3">
              <div className="bg-white custom-border-radius-sm custom-shadow-sm px-3">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="">{selected?.id}</h5>
                  <h5 className="">{selected?.jobName}</h5>
                  <h5 className="">{selected?.bidStatus}</h5>
                  <h5 className="">{selected?.estimatedValue}</h5>
                  <h5 className="">
                    <button
                      onClick={() => {
                        setSelected(null);
                      }}
                      className="btn btn-danger btn-sm"
                    >
                      <i className="mdi mdi-delete"></i>
                    </button>
                  </h5>
                </div>
              </div>
            </div>
          )}
          <div>
            <BidPlanner
              selectedQuote={selectedQuote}
              updateBidId={updateBidId}
              setSelected={setSelected}
              refreshFunc={refreshFunc}
            />
          </div>
          <div className="d-flex justify-content-end gap-1">
            <button
              className="btn btn-secondary"
              onClick={() => {
                setShowImportModal(false);
              }}
            >
              Close
            </button>
            <button
              disabled={!selected}
              onClick={handleImportBid}
              className="btn btn-success"
            >
              Import Bid
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
