import Table from 'components/DataTable/Table';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import authHeader from 'services/auth-header';
import API from 'services/axios';
import PaymentTermsAdd from './paymentsTermsAdd';
import PaymentTermsUpdate from './paymentTermsUpdate';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';

function PaymentTerms() {
  const user = useSelector((state) => state.user.userInfo);
  /*   All Satates Below
   ********************************************* */
  const [paymentTerms, setPaymentTerms] = useState(null);
  const [lastSortId, setLastSortId] = useState(null);

  // Modals
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  // below update data is used to set a varible before moving to update component
  const [updateData, setUpdateData] = useState(null);

  const [selectedDelete, setSelectedDelete] = useState();

  /*   All Functions Below
   ********************************************* */

  // get All paymentTerms
  const getAllPaymentTerms = async () => {
    try {
      const { data } = await API.get('/paymentTerms', { headers: authHeader() });
      setPaymentTerms(data);

      setLastSortId(data[data.length - 1]?.sortId ? data[data.length - 1]?.sortId : 0);
    } catch (err) {
      alert(err.message);
    }
  };

  // Delete password Data
  const deletePaymentTermsData = async (id) => {
    const { data } = await API.delete('/paymentTerms/delete/' + id, { headers: authHeader() });
    if (data.message.type == 'success') {
      getAllPaymentTerms();
      return;
    }
  };

  // Sorting Up
  const sortUp = async (index) => {
    const currentSortId = paymentTerms[index].sortId;
    const currentId = paymentTerms[index].id;
    const upSortId = paymentTerms[index - 1].sortId;
    const upId = paymentTerms[index - 1].id;
    try {
      const { data } = await API.post(
        '/paymentterms/sortup',
        { currentSortId, currentId, upSortId, upId },
        { headers: authHeader() }
      );
      if (data.success) {
        getAllPaymentTerms();
      }
    } catch (err) {
      alert(err.message);
    }
  };

  // Sorting Up
  const sortDown = async (index) => {
    const currentSortId = paymentTerms[index].sortId;
    const currentId = paymentTerms[index].id;
    const downSortId = paymentTerms[index + 1].sortId;
    const downId = paymentTerms[index + 1].id;
    try {
      const { data } = await API.post(
        '/paymentterms/sortDown',
        { currentSortId, currentId, downSortId, downId },
        { headers: authHeader() }
      );
      if (data.success) {
        getAllPaymentTerms();
      }
    } catch (err) {
      alert(err.message);
    }
  };

  useEffect(() => {
    getAllPaymentTerms();
  }, []);

  /*   Table Functions below
   ********************************************* */
  const buttons = () => (
    <div className='d-flex justify-content-end ms-2'>
      <button onClick={() => setShowAddModal(true)} className='btn btn-primary'>
        ADD NEW
      </button>
    </div>
  );
  const table_head = ['Term Name', 'No of days', 'Action'];
  const table_body = (item, index) => (
    <tr key={item.id}>
      <td>{item.termName}</td>
      <td>{item.noOfDays}</td>
      <td className='table-action'>
        {user?.roles === 'admin' && (
          <Link
            onClick={() => {
              item.sortId != 1 && sortUp(index);
            }}
            className='action-icon'
          >
            <i className='mdi mdi-chevron-double-up'></i>
          </Link>
        )}
        {user?.roles === 'admin' && (
          <Link
            onClick={() => {
              item.sortId != paymentTerms.length && sortDown(index);
            }}
            className='action-icon'
          >
            <i className='mdi mdi-chevron-double-down'></i>
          </Link>
        )}
        <Link className='action-icon'>
          <i
            onClick={() => {
              setShowUpdateModal(true);
              setUpdateData(item);
            }}
            className='mdi mdi-pencil'
          ></i>
        </Link>
        <Link className='action-icon'>
          <i
            data-bs-toggle='modal'
            data-bs-target='#danger-alert-modal'
            className='mdi mdi-delete'
            onClick={() => setSelectedDelete(item)}
          ></i>
        </Link>
      </td>
    </tr>
  );

  return (
    <div className='row'>
      <h3>Payment Terms</h3>
      <div className='col-12'>
        <Table
          btn1={buttons}
          table_body={table_body}
          table_data={paymentTerms}
          table_head={table_head}
        />

        {/*   ADD Modal
         ********************************************* */}
        <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
          <Modal.Body className='custom-shadow'>
            <div>
              <PaymentTermsAdd
                showAddPage={setShowAddModal}
                refreshFunc={getAllPaymentTerms}
                lastSortId={lastSortId}
              />
            </div>
          </Modal.Body>
        </Modal>
        {/*   Update Modal
         ********************************************* */}
        <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
          <Modal.Body className='custom-shadow'>
            <div>
              <PaymentTermsUpdate
                data={updateData}
                showUpdatePage={setShowUpdateModal}
                refreshFunc={getAllPaymentTerms}
              />
            </div>
          </Modal.Body>
        </Modal>
        {/*   Confirmation Modal
         ********************************************* */}

        <div
          id='danger-alert-modal'
          className='modal fade'
          tabIndex='-1'
          role='dialog'
          aria-hidden='true'
        >
          <div className='modal-dialog modal-sm '>
            <div className='modal-content modal-filled bg-danger'>
              <div className='modal-body p-4'>
                <div className='text-center'>
                  <i className='ri-close-circle-line h1'></i>
                  <h4 className='mt-2'>Confirm Delete!</h4>
                  <p className='mt-3'>Do You want to delete ?</p>
                  <button type='button' className='btn btn-light my-2 mx-2' data-bs-dismiss='modal'>
                    Cancel
                  </button>
                  <button
                    type='button'
                    className='btn btn-outline-light my-2 '
                    data-bs-dismiss='modal'
                    onClick={() => {
                      deletePaymentTermsData(selectedDelete.id);
                    }}
                  >
                    DELETE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentTerms;
