import API from "services/axios";
import authHeader from "services/auth-header";
import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";

function CustomerUpdate({
  id,
  selected,
  refreshFunc,
  addressData,
  setShowUpdateModal,
}) {
  const { register, handleSubmit, watch } = useForm();
  const watchedValues = watch();

  const [showSignatureReleaseInputs, setShowSignatureReleaseInputs] =
    useState(false);

  // Add Customers

  const updateCustomer = async (formData, e) => {
    e.preventDefault();

    const submitData = {
      id: selected.id,
      sortId: selected.sortId,
      type: "address",
      customerId: id,
      data: JSON.stringify(formData),
    };

    try {
      const { data } = await API.patch(
        "/customers/addresses/update",
        { data: submitData },
        { headers: authHeader() }
      );
      if (data.success) {
        refreshFunc();
        setShowUpdateModal(false);
        return;
      }
      alert(data);
    } catch (err) {
      alert(err.message);
    }
  };

  useEffect(() => {
    setShowSignatureReleaseInputs(selected.signatureRelease);
  }, [selected]);

  useEffect(() => {
    setShowSignatureReleaseInputs(watchedValues.signatureRelease);
  }, [watchedValues]);
  return (
    <div className="d-flex justify-content-center flex-column px-2">
      <h3 className="mb-3"> UPDATE ADDRESS </h3>
      <form onSubmit={handleSubmit(updateCustomer)}>
        <div className="row">
          <div className="col-6">
            {/* Description */}
            <div className="row mb-1">
              <div className="flex-center justify-content-start col-4 pe-0">
                <label className="form-label">Description</label>
              </div>
              <div className="col-8">
                <input
                  {...register("description")}
                  defaultValue={selected?.description}
                  placeholder="Enter description"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>

            {/* Address Company Name */}
            <div className="row mb-1">
              <div className="flex-center justify-content-start col-4 pe-0">
                <label className="form-label">Company Name</label>
              </div>
              <div className="col-8">
                <input
                  {...register("addressCompanyName")}
                  defaultValue={selected?.addressCompanyName}
                  placeholder="Enter address company name"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>

            {/* Attention To */}
            <div className="row mb-1">
              <div className="flex-center justify-content-start col-4 pe-0">
                <label className="form-label">Contact Name</label>
              </div>
              <div className="col-8">
                <input
                  {...register("attentionTo")}
                  defaultValue={selected?.attentionTo}
                  placeholder="Enter attention to"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
            {/* Attention To */}
            <div className="row mb-1">
              <div className="flex-center justify-content-start col-4 pe-0">
                <label className="form-label">Phone</label>
              </div>
              <div className="col-8">
                <input
                  {...register("phone")}
                  defaultValue={selected?.phone}
                  placeholder="Enter phone"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
            {/* Attention To */}
            <div className="row mb-1">
              <div className="flex-center justify-content-start col-4 pe-0">
                <label className="form-label">Email</label>
              </div>
              <div className="col-8">
                <input
                  {...register("email")}
                  defaultValue={selected?.email}
                  placeholder="Enter attention to"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
            <hr />

            {/*   DHL Section
             ********************************************* */}
            {/* DHL To */}
            <div className="row mb-1">
              <div className="flex-center justify-content-start col-5 pe-0">
                <label className="form-label">DHL</label>
              </div>
              <div className="col-7">
                <input
                  {...register("email")}
                  defaultValue={selected?.email}
                  placeholder="Enter DHL to"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
            {/* Remote Shipping */}
            <div className="row mb-1">
              <div className="flex-center justify-content-start col-5 pe-0">
                <label className="form-label">Remote Shipping</label>
              </div>
              <div className="col-7 d-flex align-items-center justify-content-between gap-2x">
                <div className="d-flex  mb-1">
                  <input
                    type="checkbox"
                    {...register("remoteShipping")}
                    defaultChecked={selected?.remoteShipping}
                    id={"remoteShipping"}
                    data-switch="success"
                  />
                  <label
                    htmlFor={"remoteShipping"}
                    data-on-label="Yes"
                    data-off-label="No"
                  ></label>
                </div>
                <div className="">
                  <button type="button" className="btn btn-sm btn-info">
                    Validate Address
                  </button>
                </div>
              </div>
            </div>
            {/* Signature Release  */}
            <div className="row mb-1">
              <div className="flex-center justify-content-start col-5 pe-0">
                <label className="form-label">Signature Release</label>
              </div>
              <div className="col-7 d-flex">
                <input
                  type="checkbox"
                  {...register("signatureRelease")}
                  defaultChecked={selected?.signatureRelease}
                  id={"signatureRelease"}
                  data-switch="success"
                />
                <label
                  htmlFor={"signatureRelease"}
                  data-on-label="Yes"
                  data-off-label="No"
                ></label>
              </div>
            </div>
            {showSignatureReleaseInputs && (
              <div>
                {/* Authorize Person */}
                <div className="row mb-1">
                  <div className="flex-center justify-content-start col-5 pe-0">
                    <label className="form-label">Authorize Person</label>
                  </div>
                  <div className="col-7 d-flex">
                    <input
                      {...register("authorizePerson")}
                      defaultValue={selected?.authorizePerson}
                      placeholder="Enter Authorize Person"
                      type="text"
                      className="form-control"
                    />
                  </div>
                </div>
                {/* Authorize To */}
                <div className="row mb-1">
                  <div className="flex-center justify-content-start col-5 pe-0">
                    <label className="form-label">Authorize To</label>
                  </div>
                  <div className="col-7 d-flex">
                    <input
                      {...register("authorizeTo")}
                      defaultValue={selected?.authorizeTo}
                      placeholder="Enter Authorize To"
                      type="text"
                      className="form-control"
                    />
                  </div>
                </div>
                {/* Authorize Location */}
                <div className="row mb-1">
                  <div className="flex-center justify-content-start col-5 pe-0">
                    <label className="form-label">Authorize Location</label>
                  </div>
                  <div className="col-7 d-flex">
                    <input
                      {...register("authorizeLocation")}
                      defaultValue={selected?.authorizeLocation}
                      placeholder="Enter Authorize location"
                      type="text"
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            )}

            {/* Self Collect  */}
            <div className="row mb-1">
              <div className="flex-center justify-content-start col-5 pe-0">
                <label className="form-label">Self Collect</label>
              </div>
              <div className="col-7 d-flex">
                <input
                  type="checkbox"
                  {...register("selfCollect")}
                  defaultChecked={selected?.selfCollect}
                  id={"selfCollect"}
                  data-switch="success"
                />
                <label
                  htmlFor={"selfCollect"}
                  data-on-label="Yes"
                  data-off-label="No"
                ></label>
              </div>
            </div>
            {/* Service ID */}
            <div className="row mb-1">
              <div className="flex-center justify-content-start col-5 pe-0">
                <label className="form-label">Service ID</label>
              </div>
              <div className="col-7 d-flex align-items-center justify-content-between gap-2">
                <input
                  {...register("serviceId")}
                  defaultValue={selected?.serviceId}
                  placeholder="Enter Service ID"
                  type="text"
                  className="form-control"
                />
                <button type="button" className="btn btn-info text-nowrap">
                  Service Point
                </button>
              </div>
            </div>
          </div>

          {/*   Right Panel
           ********************************************* */}
          <div className="col-6 d-flex flex-column">
            {/* IS company Location */}
            <div className="row mb-1">
              <div className="flex-center justify-content-start col-4 pe-0">
                <label className="form-label">Company Location</label>
              </div>
              <div className="col-8">
                <div className="d-flex gap-2 mb-1">
                  <input
                    type="checkbox"
                    {...register("companyLocation")}
                    defaultChecked={selected?.companyLocation}
                    id={"companyLocation"}
                    data-switch="success"
                  />
                  <label
                    htmlFor={"companyLocation"}
                    data-on-label="Yes"
                    data-off-label="No"
                  ></label>
                </div>
              </div>
            </div>
            <div className="row mb-1">
              <div className="flex-center justify-content-start col-4 pe-0">
                <label className="form-label">Address 1</label>
              </div>
              <div className="col-8">
                <input
                  {...register("address1")}
                  defaultValue={selected?.address1}
                  placeholder="Enter address 1"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>

            {/* Address 2 */}
            <div className="row mb-1">
              <div className="flex-center justify-content-start col-4 pe-0">
                <label className="form-label">Address 2</label>
              </div>
              <div className="col-8">
                <input
                  {...register("address2")}
                  defaultValue={selected?.address2}
                  placeholder="Enter address 2"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>

            {/* Address 2 */}
            <div className="row mb-1">
              <div className="flex-center justify-content-start col-4 pe-0">
                <label className="form-label">City | State | Zip</label>
              </div>
              <div className="d-flex col-8">
                <input
                  {...register("city")}
                  defaultValue={selected?.city}
                  placeholder="city"
                  type="text"
                  className="form-control"
                />
                <input
                  {...register("state")}
                  defaultValue={selected?.state}
                  placeholder="state"
                  type="text"
                  className="form-control"
                />
                <input
                  {...register("zipcode")}
                  defaultValue={selected?.zipcode}
                  placeholder="zipcode"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>

            <div className="row mb-1">
              <div className="flex-center justify-content-start col-4 pe-0">
                <label className="form-label">Country</label>
              </div>
              <div className="col-8">
                <input
                  {...register("country")}
                  defaultValue={selected?.country}
                  placeholder="Enter country"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
          </div>
          {/* Buttons */}
          <div className="mb-1 flex-grow-1 d-flex justify-content-end align-items-end">
            <div>
              <button
                onClick={() => setShowUpdateModal(false)}
                type="button"
                className="btn btn-secondary me-1"
              >
                CLOSE
              </button>
              <button type="submit" className="btn btn-primary">
                UPDATE
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default CustomerUpdate;
