import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { v4 as uuid } from "uuid";

export default function AttHolidaysList({
  selectedDevice,
  updateDevice,
  formData,
  setFormData,
}) {
  /*   All States
   ********************************************* */
  const [showHolidaysListModal, setShowHolidaysListModal] = useState(false);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [selectedHoliday, setSelectedHoliday] = useState(null);
  const [holidaysList, setHolidaysList] = useState([]);

  const [newHolidayForm, setNewHolidayForm] = useState({
    name: "",
    date: "",
  });

  /*   All Functions
   ********************************************* */
  const handleHolidaysListChange = (e) => {
    let id = e.target.id;
    let name = e.target.name;
    let value = e.target.value;

    setNewHolidayForm((prev) => {
      return { ...prev, [name]: value, id };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isUpdateMode) {
      updateHoliday(selectedHoliday);
    } else {
      addHoliday();
    }
  };

  const addHoliday = () => {
    let newHolidaysList = [...holidaysList, newHolidayForm];

    setFormData((prev) => ({
      ...prev,
      ...selectedDevice,
      holidaysList: newHolidaysList,
    }));
    setHolidaysList(newHolidaysList);

    setNewHolidayForm({ name: "", date: "" });
  };
  const updateHoliday = (holiday) => {
    let updatedHoliday = { ...holiday, ...newHolidayForm };
    let updatedHolidaysList = holidaysList.map((item) =>
      item.id === updatedHoliday.id ? updatedHoliday : item
    );
    setHolidaysList(updatedHolidaysList);
    setFormData((prev) => ({
      ...prev,
      ...selectedDevice,
      holidaysList: updatedHolidaysList,
    }));
    setIsUpdateMode(false);
    setNewHolidayForm({ name: "", date: "" });
  };

  const deleteHoliday = (id) => {
    let updatedHolidaysList = holidaysList.filter((item) => item.id !== id);
    setFormData((prev) => ({
      ...prev,
      holidaysList: updatedHolidaysList,
    }));
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    setHolidaysList((prev) =>
      selectedDevice?.holidaysList
        ? JSON.parse(selectedDevice?.holidaysList)
        : []
    );
  }, [selectedDevice]);

  return (
    <div>
      <button
        type="button"
        onClick={(e) => {
          setShowHolidaysListModal(true);
        }}
        className="btn btn-sm btn-info mb-3"
      >
        Add Holidays
      </button>
      <Modal
        show={showHolidaysListModal}
        onHide={(e) => {
          setShowHolidaysListModal(false);
        }}
        size="lg"
        contentClassName="bg-transparent"
      >
        <Modal.Body className="bg-white custom-border-radius custom-shadow ps-3">
          <h3>Add new Holiday</h3>

          <div>
            <form className="pt-2">
              <div className="d-flex gap-1 align-items-center">
                <input
                  onChange={handleHolidaysListChange}
                  type="date"
                  placeholder="Select Date"
                  name="date"
                  value={newHolidayForm?.date || ""}
                  id={newHolidayForm?.id || uuid()}
                  className="form-control"
                />
                <input
                  onChange={handleHolidaysListChange}
                  type="text"
                  name="name"
                  id={newHolidayForm?.id || uuid()}
                  placeholder="Enter Holiday Name"
                  value={newHolidayForm?.name || ""}
                  className="form-control"
                />
                <input
                  type="submit"
                  value={isUpdateMode ? "Update" : "ADD"}
                  onClick={handleSubmit}
                  className="btn btn-success"
                />
                {isUpdateMode && (
                  <button
                    onClick={(e) => {
                      setNewHolidayForm({ name: "", date: "" });
                      setIsUpdateMode(false);
                    }}
                    className="btn btn-danger"
                  >
                    Clear
                  </button>
                )}
              </div>
            </form>
          </div>
          <hr />
          <h4 className="mt-4">Holidays List</h4>

          <table className="table">
            <thead>
              <tr className="">
                <th scope="w-75">Holiday</th>
                <th scope="w-75">Date</th>
                <th scope="w-25">Action</th>
              </tr>
            </thead>
            <tbody>
              {holidaysList?.map((holiday) => (
                <tr className="">
                  <td className="w-75">{holiday?.name}</td>
                  <td className="w-75">{holiday?.date}</td>
                  <td className="w-25">
                    <div className="d-flex">
                      <button
                        onClick={(e) => {
                          setIsUpdateMode(true);
                          setSelectedHoliday(holiday);
                          setNewHolidayForm(holiday);
                        }}
                        className="btn btn-info btn-sm "
                        type="button"
                      >
                        <i className="mdi mdi-pencil"></i>
                      </button>
                      <button
                        onClick={(e) => {
                          deleteHoliday(holiday.id);
                        }}
                        className="btn btn-danger btn-sm ms-1"
                        type="button"
                      >
                        <i className="mdi mdi-delete"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="text-end me-3">
            <button
              onClick={(e) => {
                updateDevice();
              }}
              className="btn btn-sm me-1 btn-success"
            >
              Save holidays
            </button>
            <button
              onClick={(e) => {
                setShowHolidaysListModal(false);
              }}
              className="btn btn-sm btn-secondary"
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
