import React, { useEffect, useState } from "react";
import API from "services/axios";
import authHeader from "services/auth-header";
import Table from "components/DataTable/Table";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { setNotification } from "features/Notification/notificationSlice";
import BidDetails from "./DetailPage/BidDetails";
import { Link } from "react-router-dom";
import BidAdd from "./BidAdd";

function BidPlanner({ selectedQuote, updateBidId, setSelected, refreshFunc }) {
  const user = useSelector((state) => state.user?.userInfo);
  const dispatch = useDispatch();

  /*   All States
   ********************************************* */
  const [bidData, setBidData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [searchResult, setSearchResult] = useState(null);
  const [searchResultIds, setSearchResultIds] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [selectedId, setSelectedId] = useState(null);
  const [showDetailsPage, setShowDetailsPage] = useState(false);
  const [deleteID, setDeleteID] = useState(null);

  const [lastBidId, setLastBidId] = useState(null);

  const [active, setActive] = useState("pending");
  const checkActive = (val) => {
    setActive(val);
    setShowDetailsPage(null);
    setSearchResultIds(null);
  };

  /*   All Functions
   ********************************************* */
  const getBidData = async () => {
    try {
      const { data } = await API.get("/bids", { headers: authHeader() });
      setBidData(data);

      setLastBidId((prev) => data[0]?.bidId);
      return data;
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  useEffect(() => {
    getBidData();
  }, [showDetailsPage]);

  useEffect(() => {
    if (bidData && active === "all") {
      setFilteredData(bidData);
    } else if (bidData && active === "pending") {
      let pending = bidData.filter((item) => item.bidStatus === "pending");
      setFilteredData(pending);
    } else if (bidData && active === "amendment") {
      let amendment = bidData.filter((item) => item.bidStatus === "amendment");
      setFilteredData(amendment);
    } else if (bidData && active === "submitted") {
      let submitted = bidData.filter((item) => item.bidStatus === "submitted");
      setFilteredData(submitted);
    }
  }, [active, bidData]);

  useEffect(() => {
    let ids = searchResult?.reduce((result, cur) => {
      result?.push(cur.id);
      return result;
    }, []);
    setSearchResultIds(ids);
  }, [searchResult]);

  // Delete Bids
  const deleteBid = async (id) => {
    try {
      const { data } = await API.post(
        "/bids/delete",
        {
          id,
        },
        { headers: authHeader() }
      );
      if (data?.type === "success") {
        setBidData(data.data);
        setShowDeleteModal(false);
        return;
      }
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  // Getting 12 Hours Time from SQL timestamp
  const convertSqlTimestampToDate = (sqlTime) => {
    const timestamp = sqlTime;
    const date = new Date(timestamp);
    const time = date.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    return time;
  };

  /*   Table Functions
   ********************************************* */
  const btn1 = () => (
    <div>
      {(user?.permissions?.includes("bids_add") || user?.roles === "admin") &&
        !selectedQuote && (
          <button
            onClick={() => setShowAddModal(true)}
            type="button"
            className="btn btn-primary  ms-1"
          >
            Add Bid
          </button>
        )}
    </div>
  );

  const table_head = [
    "ID",
    "Created Date",
    "Due Date",
    "Job Name",
    "Value",
    "Result",
    "Actions",
  ];

  const table_body = (item, index) => (
    <tr className="" key={item.id}>
      <td className="py-1 align-middle">{item.bidId}</td>
      <td className="py-1 align-middle">
        {convertSqlTimestampToDate(item.createdAt)}{" "}
      </td>
      <td className="py-1 align-middle">
        {convertSqlTimestampToDate(item.dueDate)}{" "}
      </td>
      <td className="py-1 align-middle">{item.jobName} </td>
      <td className="py-1 align-middle">{item.estimatedValue}</td>
      <td className="py-1 align-middle">
        {item.result?.replaceAll("_", " ")?.toUpperCase()}
      </td>
      <td className="table-action py-1">
        {!selectedQuote ? (
          <button
            onClick={() => {
              setSelectedId(item.id);
              setShowDetailsPage(true);
            }}
            className={`btn btn-sm bg-success text-white mx-2 `}
          >
            Details
          </button>
        ) : (
          <button
            onClick={() => {
              setSelected(item);
            }}
            className={`btn btn-sm bg-success text-white mx-2 `}
          >
            Select
          </button>
        )}
        {(user?.permissions?.includes("bids_delete") ||
          user?.roles === "admin") &&
          !selectedQuote && (
            <Link
              onClick={() => {
                setShowDeleteModal(true);
                setDeleteID(item.id);
              }}
              className="action-icon"
            >
              <i className="mdi mdi-delete"></i>
            </Link>
          )}
      </td>
    </tr>
  );

  return (
    <div className="row">
      <div className="col-12">
        <div className="d-flex">
          {/*    Table Card
           ********************************************* */}
          {!showDetailsPage && !selectedQuote && (
            <div
              className={`flex-grow-1 d-flex flex-column bg-white ${
                !showDetailsPage && "shadow-lg"
              } px-5 py-2`}
              style={{
                position: "fixed",
                top: 0,
                width: "calc(100vw - 200px)",
                marginLeft: 200,
                zIndex: "99",
              }}
            >
              <ul className="nav nav-tabs nav-bordered border-0 d-flex flex-grow-1">
                <li
                  onClick={() => {
                    checkActive("pending");
                  }}
                  className="nav-item"
                >
                  <button
                    className={
                      active === "pending"
                        ? `nav-link rounded-0 active bg-primary text-white`
                        : "nav-link rounded-0"
                    }
                  >
                    <span className="d-none d-md-block">PENDING</span>
                  </button>
                </li>

                <li
                  onClick={() => {
                    checkActive("submitted");
                  }}
                  className="nav-item"
                >
                  <button
                    className={
                      active === "submitted"
                        ? `nav-link rounded-0 active bg-primary text-white`
                        : "nav-link rounded-0"
                    }
                  >
                    <span className="d-none d-md-block">SUBMITTED</span>
                  </button>
                </li>
                <li
                  onClick={() => {
                    checkActive("all");
                  }}
                  className=""
                >
                  <button
                    className={
                      active === "all"
                        ? `nav-link rounded-0 active bg-primary text-white`
                        : "nav-link rounded-0"
                    }
                  >
                    <i className="mdi mdi-settings-outline d-md-none d-block"></i>
                    <span className="d-none d-md-block">ALL</span>
                  </button>
                </li>
              </ul>
            </div>
          )}
          <div className=" flex-grow-1">
            <div
              className={`flex-grow-1 rounded d-flex ${
                !selectedQuote && "mt-4"
              }`}
            >
              {!showDetailsPage ? (
                <div
                  className={`horizontal-scrollable  d-flex flex-grow-1 ${
                    !selectedQuote && "py-4 px-3"
                  }`}
                  style={{
                    borderRadius: 10,
                    marginLeft: !selectedQuote && 260,
                  }}
                >
                  <Table
                    btn1={btn1}
                    table_head={table_head}
                    table_body={table_body}
                    table_data={filteredData}
                    searchResults={setSearchResult}
                  />
                </div>
              ) : (
                <BidDetails
                  currentTab={active}
                  searchResultIds={searchResultIds}
                  setSearchResultIds={setSearchResultIds}
                  selectedId={selectedId}
                  setShowDetailsPage={setShowDetailsPage}
                  convertSqlTimestampToDate={convertSqlTimestampToDate}
                />
              )}
            </div>
          </div>

          {/*   ADD Modal
           ********************************************* */}
          <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
            <Modal.Body>
              <div>
                {bidData && (
                  <BidAdd
                    lastBidId={lastBidId}
                    showAddModal={setShowAddModal}
                    setSelectedId={setSelectedId}
                    setShowDetailsPage={setShowDetailsPage}
                    selectedQuote={selectedQuote}
                    refreshFunc={refreshFunc}
                    updateBidId={updateBidId}
                  />
                )}
              </div>
            </Modal.Body>
          </Modal>

          {/*   Delete Modal
           ********************************************* */}
          <Modal
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
          >
            <Modal.Body className="bg-danger">
              <div className="">
                <div className="text-center text-white">
                  <i className="ri-close-circle-line h1"></i>
                  <h4 className="mt-2">Confirm Delete!</h4>
                  <p className="mt-3">Do You want to delete ?</p>
                  <button
                    type="button"
                    onClick={() => setShowDeleteModal(false)}
                    className="btn btn-light my-2 mx-2"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-light my-2 "
                    data-bs-dismiss="modal"
                    onClick={() => {
                      deleteBid(deleteID);
                    }}
                  >
                    DELETE
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default BidPlanner;
