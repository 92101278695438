import React from "react";

function Spinner() {
  return (
    <div
      className="position-absolute top-50 start-50 spinner-border text-primary"
      style={{ zIndex: 999999 }}
      role="status"
    ></div>
  );
}

export default Spinner;
