import React, { useEffect, useState } from 'react';

export default function ByAreaCalculator({ matrix, setLocation, setColors, setCalculatedPrice }) {
  /*   All States
   ********************************************* */
  const [quantity, setQuantity] = useState(1);
  const [printingGaps, setPrintingGaps] = useState(0.0);
  const [tableHead, setTableHead] = useState(null);
  const [tableBody, setTableBody] = useState(null);

  const [width, setWidth] = useState(1);
  const [length, setLength] = useState(1);

  const [area, setArea] = useState('');
  const [totalArea, setTotalArea] = useState('');
  const [price, setPrice] = useState('');
  const [pricePerPiece, setPricePerPiece] = useState('');

  const [totalPrice, setTotalPrice] = useState(0);

  /*   All Functions
   ********************************************* */

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    let data = matrix?.matrixJson ? JSON.parse(matrix?.matrixJson) : null;
    if (data) {
      let tHead = data?.tableHead;
      let tbody = data?.tableBody;

      setTableHead(tHead);
      setTableBody(tbody);
      setPrintingGaps(data?.printingGaps);
    }
  }, [matrix]);
  useEffect(() => {
    if (width && length && quantity) {
      let calculatedArea = (width + printingGaps) * (length + printingGaps);

      //  Getting Area of 1 Pcs
      let areaRoundedOff = Math.round(parseFloat(calculatedArea) * 100) / 100;
      setArea(areaRoundedOff);

      // Getting Total Area according to quantity
      let totalCalculatedArea = Math.round(parseFloat(quantity * areaRoundedOff) * 100) / 100;
      setTotalArea(totalCalculatedArea);
      if (tableHead && tableBody) {
        getTotalPrice(areaRoundedOff, totalCalculatedArea);
      }
    }
  }, [width, length, quantity, printingGaps]);

  const getTotalPrice = (areaRoundedOff, totalCalculatedArea) => {
    let quantityList = [];
    tableBody?.map((item) => {
      quantityList.push(item[Object.keys(item)[0]]);
    });

    // Getting Correct Column Row =================================================================
    let selectedColumn = '';
    // let selectedColumn = '10 Sqft';

    for (let i = 1; i < tableHead?.length; i++) {
      if (totalCalculatedArea < parseInt(tableHead[i].split(' ')[0])) {
        selectedColumn = tableHead[i - 1];
        break;
      }
    }
    if (totalCalculatedArea >= parseInt(tableHead[tableHead?.length - 1].split(' ')[0])) {
      selectedColumn = tableHead[tableHead.length - 1];
    }
    // ========================================================

    // Getting Correct Quantity Row =================================================================
    let totalPrice = 0;

    for (let i = 1; i < tableBody?.length; i++) {
      if (quantity < quantityList[i]) {
        let roundedOffPrice = parseFloat(tableBody[i - 1][selectedColumn]) * area;
        totalPrice = roundedOffPrice;
        break;
      }
    }
    if (quantity >= quantityList[quantityList.length - 1]) {
      let roundedOffPrice = parseFloat(tableBody[tableBody.length - 1][selectedColumn]);
      totalPrice = roundedOffPrice;
    }
    // ========================================================

    let roundedOffPrice = Math.round(parseFloat(totalPrice) * 100) / 100;

    setPrice(roundedOffPrice);
    let perPiecePrice = Math.round(parseFloat(roundedOffPrice * areaRoundedOff) * 100) / 100;
    setPricePerPiece(perPiecePrice);
    setTotalPrice();
    setTotalPrice(Math.round(parseFloat(perPiecePrice * quantity) * 100) / 100);
  };

  useEffect(() => {
    setCalculatedPrice(totalPrice);
  }, [totalPrice]);

  return (
    <div className=''>
      <div className='d-flex gap-1'>
        <div className='w-50'>
          Quantity
          <input
            name='quantityRow'
            type='number'
            min={1}
            value={quantity}
            onChange={(e) => {
              setQuantity(parseInt(e.target.value));
            }}
            className='form-control'
          />
        </div>
        <div className='w-50'>
          Width
          <input
            type='number'
            min={0}
            step={0.01}
            value={width}
            onChange={(e) => {
              setWidth(parseFloat(e.target.value));
            }}
            className='form-control'
          />
        </div>
        <div className='w-50'>
          Length
          <input
            type='number'
            min={0}
            step={0.01}
            value={length}
            onChange={(e) => {
              setLength(parseFloat(e.target.value));
            }}
            className='form-control'
          />
        </div>
      </div>

      <div className='d-flex gap-1 my-3'>
        <div className='w-50'>
          Area
          <input
            name='quantityRow'
            type='number'
            min={1}
            value={area}
            disabled
            readOnly
            className='form-control'
          />
        </div>
        <div className='w-50'>
          Total Area
          <input
            name='quantityRow'
            type='number'
            min={1}
            value={totalArea}
            disabled
            readOnly
            className='form-control'
          />
        </div>
        <div className='w-50'>
          Price
          <input
            name='quantityRow'
            type='number'
            min={1}
            value={price}
            disabled
            readOnly
            className='form-control'
          />
        </div>
      </div>

      <div className='d-flex mt-2 align-items-center gap-2'>
        <div className='d-flex gap-2 w-50 align-items-center'>
          <div className='text-nowrap'>Per Piece</div>
          <input
            name='quantityRow'
            type='number'
            min={1}
            value={pricePerPiece}
            disabled
            readOnly
            className='form-control'
          />
        </div>
        <div className='w-50 d-flex gap-1 align-items-center'>
          <div className='text-nowrap'>Total Price</div>
          <div className='bg-primary flex-grow-1 bg-opacity-25  custom-border-radius-sm p-1 text-center'>
            {totalPrice}
          </div>
        </div>
      </div>
      <div className='d-flex gap-3 mt-3 align-items-center'>
        <div className=''>Location</div>
        <div className='flex-grow-1 w-100'>
          <input
            type='text'
            onChange={(e) => setLocation(e.target.value)}
            placeholder='Enter location'
            className='form-control'
          />
        </div>
      </div>
      <div className='row mt-2 align-items-center'>
        <div className='col-3'>Colors</div>
        <div className='flex-grow-1 w-100'>
          <input
            type='text'
            onChange={(e) => setColors(e.target.value)}
            placeholder='Enter location'
            className='form-control'
          />
        </div>
      </div>
    </div>
  );
}
