import { Image, Text, View } from "@react-pdf/renderer";
import React from "react";

export default function TeamViewPdfHeader({
  locationLogo,
  logo,
  selectedWO,
  styles,
}) {
  /*   All States
   ********************************************* */

  /*   All Functions
   ********************************************* */
  const convertSqlTimestampToDate = (sqlTime) => {
    const timestamp = sqlTime;
    const date = new Date(timestamp);
    const time = date.toLocaleString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    return time.replaceAll("/", "-");
  };

  /*   All UseEffects
   ********************************************* */

  return (
    <>
      <View style={{ ...styles.topHeader }}>
        {/*   Top Header Section
         ********************************************* */}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <View style={{}}>
            {locationLogo ? (
              <Image
                src={locationLogo}
                style={{
                  width: 110,
                  height: 30,
                }}
              />
            ) : (
              <Image
                src={logo}
                style={{
                  width: 110,
                  height: 30,
                }}
              />
            )}
          </View>
          <View
            style={{
              flexGrow: 1,
              alignItems: "flex-end",
            }}
          >
            <Text style={[styles.textbody]}>WO : {selectedWO?.id}</Text>
            <View
              style={{
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Text style={[styles.textbody]}>
                WO Date: &nbsp;&nbsp;&nbsp;
                {convertSqlTimestampToDate(selectedWO?.WODate)}
              </Text>
              <Text style={[styles.textbody]}>
                Print Date:&nbsp;&nbsp;&nbsp;
                {convertSqlTimestampToDate(Date.now())}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </>
  );
}
