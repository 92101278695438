/* 
  Documentation:
  It requires 2 params to work
  1. filterBy 2. selectedID

  Gets WorkOrders using filterBy
  Then sets selectedWorkOrder by going through data received
  having same id as selectedID in param.

 */
import Spinner from "components/Spinner";
import useWorkOrder from "features/Contexts/WorkOrderContext";
import { setNotification } from "features/Notification/notificationSlice";
import { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import authHeader from "services/auth-header";
import API from "services/axios";
import DuplicateWO from "./DuplicateWO";
import ManageWOs from "./ManageWOs";
import WOProducts from "./WOProducts/WOProducts";
import WOFiles from "./files/WOFiles";
import WOOrderEmail from "components/WorkOrderOrderEmail/WOOrderEmail";
import PrintProductPdf from "components/ProductPdfPrintButton/PrintProductPdf";
import WOTeamView from "./TeamView/WOTeamView";
import WOPdfButton from "components/Pdfs/WOPdf/WOPdfButton";

function DetailsPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  /*   ALL STATES
   ********************************************* */
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchUrl, setSearchUrl] = useState("");

  const user = useSelector((state) => state.user?.userInfo);
  const disableForDataEntry = user?.permissions?.includes(
    "work_orders_dataEntry"
  );

  const { clearWorkOrderData, setCustomerData } = useWorkOrder();

  const [active, setActive] = useState(
    disableForDataEntry ? "wOProducts" : "WODetails"
  );
  const [loading, setLoading] = useState(false);
  const [allWorkOrdersData, setAllWorkOrdersData] = useState(null);
  const [WOStatus, setWOStatus] = useState(null);

  const [selectedWorkOrder, setSelectedWorkOrder] = useState(null);

  const [wOProductsData, setWOProductsData] = useState(null);
  const [formData, setFormData] = useState(null);

  const [sowDuplicateModal, setSowDuplicateModal] = useState(false);

  /*   ALL FUNCTIONS
   ********************************************* */

  const checkActive = (val) => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.set("tab", val);
      return newParams.toString();
    });
  };

  function getWOBodyData(searchOptions, activeTab) {
    let page = searchOptions.get("page");
    let pageLimit = searchOptions.get("limit");
    let query = searchOptions.get("query");
    let location = searchOptions.get("location");
    let body = { page, activeTab, pageLimit };

    if (query) {
      body.query = query;
    }
    if (location) {
      body.location = location;
    }
    return body;
  }

  const getWorkOrders = async () => {
    setLoading(true);
    try {
      let activeTab = params?.filterBy;
      let currentID = params?.selectedID;

      let body = getWOBodyData(searchParams, activeTab);

      const { data } = await API.post("/work-orders", body, {
        headers: authHeader(),
      });
      setAllWorkOrdersData(data.result);
      let matchedOrderById = data?.result?.find(
        (item) => item?.id === parseInt(currentID)
      );
      if (matchedOrderById) {
        setSelectedWorkOrder(matchedOrderById);
        setLoading(false);
        return data.result;
      } else {
        let WOById = await getWorkOrderById(currentID);
        setSelectedWorkOrder(WOById);
      }
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      setLoading(false);
    }
  };

  const getWorkOrderById = async (id) => {
    setLoading(true);
    try {
      const { data } = await API.get("/work-order/" + id, {
        headers: authHeader(),
      });
      return data?.result;
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      setLoading(false);
    }
  };

  const updateWorkOrderData = async (e) => {
    e.preventDefault();

    let contactData = { ...formData };
    delete contactData.jobName;

    try {
      // let currentIndex = allWorkOrdersData?.indexOf(selectedWorkOrder);
      // let totalLength = allWorkOrdersData?.length;
      // let wasLastEntry = currentIndex === totalLength - 1;

      const { data } = await API.patch(
        "/work-orders",
        {
          data: { jobName: formData?.jobName, contactData, WOStatus },
          id: selectedWorkOrder.id,
        },
        { headers: authHeader() }
      );

      if (data.message.type === "success") {
        await getWorkOrders();
        // let updatedData = await getWorkOrders();

        // if (wasLastEntry) {
        //   let newID = updatedData[updatedData?.length - 1]?.id;
        //   navigate(
        //     `/work-order/details/${params?.filterBy}/${newID}/${searchUrl}`
        //   );
        // } else if (currentIndex === 0) {
        //   // handleBack();
        // } else {
        //   let newID = updatedData[currentIndex]?.id;
        //   navigate(
        //     `/work-order/details/${params?.filterBy}/${newID}/${searchUrl}`
        //   );
        // }

        setLoading(false);
        return;
      }
      setLoading(false);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      setLoading(false);
    }
  };

  const getWorkOrdersProducts = useCallback(
    async (wOId) => {
      setLoading(true);
      try {
        const { data } = await API.get(`/wo-products/${wOId}`, {
          headers: authHeader(),
        });

        setWOProductsData(data);
        setLoading(false);
        return data;
      } catch (err) {
        setLoading(false);
        dispatch(
          setNotification({
            message: err.message,
            type: "error",
          })
        );
      }
    },
    [dispatch]
  );

  const prevWorkOrder = () => {
    let curWOId = params.selectedID;

    let checkCrrIdExists = allWorkOrdersData?.find(
      (item) => item?.id === parseInt(curWOId)
    );

    if (!checkCrrIdExists) {
      return;
    }
    let currentIndex = allWorkOrdersData?.indexOf(selectedWorkOrder);
    if (currentIndex === 0) {
      return;
    }
    let newId = allWorkOrdersData[currentIndex - 1]?.id;
    navigate(`/work-order/details/${params?.filterBy}/${newId}/${searchUrl}`);
  };

  const nextWorkOrder = () => {
    let curWOId = params.selectedID;
    let checkCrrIdExists = allWorkOrdersData?.find(
      (item) => item?.id === parseInt(curWOId)
    );
    if (!checkCrrIdExists) {
      return;
    }
    let currentIndex = allWorkOrdersData?.indexOf(selectedWorkOrder);
    let totalLength = allWorkOrdersData?.length;
    if (currentIndex === totalLength - 1) {
      return;
    }
    let newId = allWorkOrdersData[currentIndex + 1]?.id;
    navigate(`/work-order/details/${params?.filterBy}/${newId}/${searchUrl}`);
  };

  const handleBack = () => {
    searchParams.delete("tab");
    navigate(`/work-orders/${params?.filterBy}?${searchParams.toString()}`);
    clearWorkOrderData();
  };

  const handleViewCustomer = () => {
    setCustomerData({ id: selectedWorkOrder?.customerId });
    navigate("/customer");
  };

  const getSearchParams = () => {
    let paramsUrl = "";
    searchParams.forEach((value, key) => {
      paramsUrl += `${key}=${value}&`;
    });

    setSearchUrl(`?${paramsUrl.slice(0, -1)}`);
    return `?${paramsUrl.slice(0, -1)}`;
  };

  /*   All UseEffects
   ********************************************* */

  useEffect(() => {
    if (selectedWorkOrder) {
      setWOStatus(selectedWorkOrder.WOStatus);
    }
  }, [selectedWorkOrder]);

  useEffect(() => {
    if (
      params?.filterBy &&
      params?.selectedID &&
      selectedWorkOrder?.id !== parseInt(params?.selectedID)
    ) {
      getWorkOrders();
    }
  }, [params]);

  useEffect(() => {
    getSearchParams();
    let tab = searchParams.get("tab");
    if (disableForDataEntry && !tab) {
      setSearchParams((prev) => {
        return [...prev, ["tab", "wo-products"]];
      });
      return setActive("wOProducts");
    }

    let tabList = {
      "wo-details": "WODetails",
      "wo-products": "wOProducts",
      files: "files",
      "team-view": "teamView",
      "product-costs": "productCosts",
      commission: "commission",
    };

    if (tab) {
      setActive(tabList[tab]);
    } else {
      setSearchParams((prev) => {
        return [...prev, ["tab", "wo-details"]];
      });
      setActive("wo-details");
    }
  }, [searchParams]);

  useEffect(() => {
    if (selectedWorkOrder) {
      getWorkOrdersProducts(selectedWorkOrder?.id);
    }
  }, [selectedWorkOrder, getWorkOrdersProducts]);

  return (
    <div
      className="d-flex flex-grow-1 "
      style={{
        borderRadius: "10px",
        position: "fixed",
        top: 0,
        width: "calc(100vw - 180px)",
        paddingLeft: 80,
        marginLeft: 180,
        zIndex: 101,
      }}
    >
      {loading && <Spinner />}
      <div className="d-flex flex-grow-1 flex-column">
        <div className="bg-white px-4 shadow-lg py-2 d-flex justify-content-between align-items-center ">
          <div className="">
            <div className="mb-1">
              WO ID : {selectedWorkOrder?.id}&nbsp;&nbsp;&nbsp;&nbsp;
              <span className="font-weight-bold h5">
                {selectedWorkOrder?.jobName?.toUpperCase()}
              </span>
            </div>
            <div>
              <button
                onClick={handleViewCustomer}
                className="btn btn-sm btn-info me-2 py-0 px-1 mb-1 "
              >
                View Customer
              </button>
              <span className="mt-0">
                Customer ID :&nbsp;
                {selectedWorkOrder?.customer?.customerId}
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span className="font-weight-bold h5">
                  {selectedWorkOrder?.customer?.customerName?.toUpperCase()}
                </span>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span className="font-weight-bold h5">
                  PO Required: &nbsp;
                  {typeof selectedWorkOrder?.customer?.poRequired === "boolean"
                    ? selectedWorkOrder?.customer?.poRequired
                      ? "Yes"
                      : "No"
                    : "No Set"}
                </span>
              </span>
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-center ">
            <h5 className="text-nowrap me-2">WO Status</h5>
            <select
              onChange={(e) => setWOStatus(e.target.value)}
              value={WOStatus || ""}
              className="form-select"
              id="commissionType"
            >
              <option value={"pending"}>Pending</option>
              <option value={"processing"}>Processing</option>
              <option value={"completed"}>Completed</option>
            </select>
            {active === "WODetails" && (
              <button
                onClick={updateWorkOrderData}
                className="btn btn-success mx-1"
              >
                Save
              </button>
            )}
            {wOProductsData &&
              !user?.permissions?.includes("work_orders_dataEntry") && (
                <WOOrderEmail
                  btnClass={"btn btn-info ms-1"}
                  btnTitle={"Email"}
                  selectedWorkOrder={selectedWorkOrder}
                  wOProductsData={wOProductsData}
                />
              )}
            {wOProductsData &&
              !user?.permissions?.includes("work_orders_dataEntry") && (
                <>
                  {/* <PrintProductPdf
                    btnClass={"btn btn-success ms-1 text-nowrap"}
                    btnTitle={"Print Pdf"}
                    selectedWorkOrder={selectedWorkOrder}
                    WOProductsData={wOProductsData}
                  /> */}
                  <WOPdfButton
                    btnClasses={"btn btn-success ms-1 text-nowrap"}
                    btnTitle={"Print Pdf"}
                    selectedWO={selectedWorkOrder}
                  />
                </>
              )}
            <button onClick={handleBack} className={"btn btn-danger ms-1"}>
              BACK
            </button>
            <button
              onClick={() => {
                prevWorkOrder();
              }}
              className={"btn btn-success btn-sm p-0 px-1 mx-1"}
            >
              <i className="mdi mdi-arrow-left-bold fs-3 py-0 my-0"></i>
            </button>
            <button
              onClick={() => {
                nextWorkOrder();
              }}
              className={"btn btn-success btn-sm p-0 px-1 "}
            >
              <i className="mdi mdi-arrow-right-bold fs-3 py-0 my-0"></i>
            </button>
          </div>
        </div>

        <div className="d-flex flex-grow-1">
          <div className="flex-grow-1  ">
            <div className="bg-white mx-2 custom-shadow mt-2 custom-border-radius-sm px-3 d-flex justify-content-between align-items-center">
              <ul className="nav nav-tabs nav-bordered border-0 d-flex flex-grow-1 py-2">
                <li
                  onClick={() => {
                    if (selectedWorkOrder?.jobName) {
                      if (!disableForDataEntry) {
                        checkActive("wo-details");
                      }
                    } else {
                      alert("Please add a job name and save");
                    }
                  }}
                  className="nav-item"
                >
                  <button
                    className={
                      active === "WODetails"
                        ? `nav-link rounded-0 active bg-primary text-white`
                        : "nav-link rounded-0"
                    }
                  >
                    <span className="d-none d-md-block">WO Details</span>
                  </button>
                </li>
                <li
                  onClick={() => {
                    if (selectedWorkOrder?.jobName) {
                      checkActive("wo-products");
                    } else {
                      alert("Please add a job name and save");
                    }
                  }}
                  className=""
                >
                  <button
                    className={
                      active === "wOProducts"
                        ? `nav-link rounded-0 active bg-primary text-white`
                        : "nav-link rounded-0"
                    }
                  >
                    <span className="d-none d-md-block">WO Products</span>
                  </button>
                </li>
                <li
                  onClick={() => {
                    if (selectedWorkOrder?.jobName) {
                      checkActive("files");
                    } else {
                      alert("Please add a job name and save");
                    }
                  }}
                  className="nav-item"
                >
                  <button
                    className={
                      active === "files"
                        ? `nav-link rounded-0 active bg-primary text-white`
                        : "nav-link rounded-0"
                    }
                  >
                    <span className="d-none d-md-block">WO Files</span>
                  </button>
                </li>
                <li
                  onClick={() => {
                    if (selectedWorkOrder?.jobName) {
                      checkActive("team-view");
                    } else {
                      alert("Please add a job name and save");
                    }
                  }}
                  className="nav-item"
                >
                  <button
                    className={
                      active === "teamView"
                        ? `nav-link rounded-0 active bg-primary text-white`
                        : "nav-link rounded-0"
                    }
                  >
                    <span className="d-none d-md-block">Team View</span>
                  </button>
                </li>
                <li
                  onClick={() => {
                    if (selectedWorkOrder?.jobName) {
                      if (!disableForDataEntry) checkActive("product-costs");
                    } else {
                      alert("Please add a job name and save");
                    }
                  }}
                  className="nav-item"
                >
                  <button
                    className={
                      active === "productCosts"
                        ? `nav-link rounded-0 active bg-primary text-white`
                        : "nav-link rounded-0"
                    }
                  >
                    <span className="d-none d-md-block">Product Costs</span>
                  </button>
                </li>
                <li
                  onClick={() => {
                    if (selectedWorkOrder?.jobName) {
                      if (!disableForDataEntry) checkActive("commission");
                    } else {
                      alert("Please add a job name and save");
                    }
                  }}
                  className="nav-item"
                >
                  <button
                    className={
                      active === "commission"
                        ? `nav-link rounded-0 active bg-primary text-white`
                        : "nav-link rounded-0"
                    }
                  >
                    <span className="d-none d-md-block">Commission</span>
                  </button>
                </li>
              </ul>
              <div>
                <button
                  onClick={() => {
                    setSowDuplicateModal(true);
                  }}
                  className="btn btn-success"
                >
                  Duplicate This workOrder
                </button>
              </div>
            </div>
            <div className="px-2 mt-2">
              {active == "WODetails" && (
                <div className="tab-pane show active">
                  {selectedWorkOrder && (
                    <ManageWOs
                      formData={formData}
                      setFormData={setFormData}
                      refreshFunc={getWorkOrders}
                      selectedWorkOrder={selectedWorkOrder}
                    />
                  )}
                </div>
              )}
              {active == "wOProducts" && (
                <div className="tab-pane show active">
                  {selectedWorkOrder?.customer && (
                    <WOProducts
                      refreshFunc={getWorkOrders}
                      selectedWorkOrder={selectedWorkOrder}
                    />
                  )}
                </div>
              )}
              {active == "files" && (
                <div className="tab-pane show active">
                  {selectedWorkOrder?.customer && (
                    <WOFiles
                      selected={selectedWorkOrder}
                      selectedCustomer={selectedWorkOrder?.customer}
                    />
                  )}
                </div>
              )}
              {active == "teamView" && (
                <div className="tab-pane show active">
                  <WOTeamView
                    refreshFunc={getWorkOrders}
                    selectedWorkOrder={selectedWorkOrder}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/*   Duplicate WorkOrder
       ********************************************* */}
      <Modal
        size="xl"
        show={sowDuplicateModal}
        onHide={() => setSowDuplicateModal(false)}
      >
        <Modal.Body>
          <h3>Duplicate WorkOrder</h3>
          <div>
            <DuplicateWO
              searchUrl={searchUrl}
              selectedWorkOrder={selectedWorkOrder}
              showModal={setSowDuplicateModal}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default DetailsPage;
