import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";
import Modal from "react-bootstrap/Modal";

export default function CompAddress({ companyInfo, refreshFunc }) {
  const user = useSelector((state) => state.user.userInfo);
  const dispatch = useDispatch();
  /*   All States
   ********************************************* */
  const [showModal, setShowModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [companyAddressData, setCompanyAddressData] = useState({});

  /*   All Functions
   ********************************************* */
  const updateCompanyInfo = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await API.post(
        "/companies/update/" + user.companyId,
        companyAddressData,
        {
          headers: authHeader(),
        }
      );
      await refreshFunc();
      setLoading(false);
      setShowModal(false);
    } catch (err) {
      setLoading(false);
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      setShowModal(false);
    }
  };

  const resetState = () => {};

  const updateField = (e) => {
    setCompanyAddressData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    setCompanyAddressData({
      address1: companyInfo?.address1,
      address2: companyInfo?.address2,
      city: companyInfo?.city,
      state: companyInfo?.state,
      zip: companyInfo?.zip,
      country: companyInfo?.country,
    });
  }, [companyInfo]);
  return (
    <div
      className="p-2 p-4 flex-grow-1 bg-white shadow-sm"
      style={{ borderRadius: 10 }}
    >
      {/* Company Info */}
      <div className="d-flex justify-content-between">
        <div className="py-0 fw-bold fs-3 text-left">Company Address</div>
        <button
          onClick={() => {
            setShowModal(true);
          }}
          className="btn btn-sm btn-primary mb-1"
        >
          Edit
        </button>
      </div>
      <hr className="my-0 mb-3" />
      {/* Address 2 */}
      <div className="row mb-2">
        <div className="col-3 fw-bold">Address 1</div>
        <div className="col-9">{companyInfo?.address1}</div>
      </div>
      {/* address 2 */}
      <div className="row mb-2">
        <div className="col-3 fw-bold">Address 2</div>
        <div className="col-9">{companyInfo?.address2}</div>
      </div>
      {/* City */}
      <div className="row mb-2">
        <div className="col-3 fw-bold">City</div>
        <div className="col-9">{companyInfo?.city}</div>
      </div>
      {/* State */}
      <div className="row mb-2">
        <div className="col-3 fw-bold">State</div>
        <div className="col-9">{companyInfo?.state}</div>
      </div>
      {/* Zip */}
      <div className="row mb-2">
        <div className="col-3 fw-bold">Zip</div>
        <div className="col-9">{companyInfo?.zip}</div>
      </div>
      {/* Country */}
      <div className="row mb-2">
        <div className="col-3 fw-bold">Country</div>
        <div className="col-9">{companyInfo?.country}</div>
      </div>
      <Modal
        contentClassName="bg-transparent"
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
      >
        <Modal.Body className="bg-white px-4 py-3 pb-1 custom-border-radius custom-shadow-sm">
          {loading && <Spinner />}
          <h2>Update Company Address</h2>
          <form onSubmit={updateCompanyInfo} className="my-3">
            <div>
              <div className="mb-3">
                <label className="form-label mb-1">Address 1</label>
                <input
                  className="form-control"
                  type="text"
                  name="address1"
                  value={companyAddressData?.address1}
                  onChange={updateField}
                  placeholder="Address 1"
                />
              </div>
              <div className="mb-3">
                <label className="form-label mb-1">Address 2</label>
                <input
                  className="form-control"
                  type="text"
                  name="address2"
                  value={companyAddressData?.address2}
                  onChange={updateField}
                  placeholder="Address 2"
                />
              </div>
              <div className="mb-3">
                <label className="form-label mb-1">city</label>
                <input
                  className="form-control"
                  type="text"
                  name="city"
                  value={companyAddressData?.city}
                  onChange={updateField}
                  placeholder="city"
                />
              </div>
              <div className="mb-3">
                <label className="form-label mb-1">state</label>
                <input
                  className="form-control"
                  type="text"
                  name="state"
                  value={companyAddressData?.state}
                  onChange={updateField}
                  placeholder="state"
                />
              </div>
              <div className="mb-3">
                <label className="form-label mb-1">zip</label>
                <input
                  className="form-control"
                  type="text"
                  name="zip"
                  value={companyAddressData?.zip}
                  onChange={updateField}
                  placeholder="zip"
                />
              </div>
              <div className="mb-3">
                <label className="form-label mb-1">country</label>
                <input
                  className="form-control"
                  type="text"
                  name="country"
                  value={companyAddressData?.country}
                  onChange={updateField}
                  placeholder="country"
                />
              </div>
            </div>
            {/* ******************** Buttons ************************* */}
            <div className="text-end mb-2">
              <button
                className="btn px-2 me-1 btn-secondary"
                type="button"
                onClick={() => {
                  setShowModal(false);
                  resetState();
                }}
              >
                Close
              </button>
              <button className="btn px-2 btn-primary" type="submit">
                Update
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
