import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import authHeader from 'services/auth-header';
import API from 'services/axios';

function PaymentsTermsUpdate({ data, showUpdatePage, refreshFunc }) {
  /*   All Satates Below
   ********************************************* */
  const { register, handleSubmit } = useForm();
  const [formError, setFormError] = useState({});

  /*   All Functions Below
   ********************************************* */
  const updateShipmentMethodsData = async (formData, e) => {
    setFormError({});
    e.preventDefault();
    if (formData.shippingMethod === '') {
      setFormError({
        shippingMethod: { type: 'shippingMethod', message: 'Location name is required' },
      });
      return;
    }
    try {
      const resp = await API.patch(
        '/shipmentmethods/',
        { data: { ...formData, id: data.id } },
        { headers: authHeader() }
      );

      if (resp.data.message.type === 'success') {
        refreshFunc();
        showUpdatePage(false);
        return;
      }
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <div className='row'>
      <div className='col-12'>
        {/*    Update FORM START
         ********************************************* */}
        {data && (
          <form className='ps-3 pe-3' onSubmit={handleSubmit(updateShipmentMethodsData)}>
            <div className='mb-3'>
              <h3>UPDATE {data?.shippingMethod?.toUpperCase()}</h3>
            </div>
            {/* ----------  Fields  ----------- */}
            <div className='mb-3'>
              <label htmlFor='shippingMethod' className='form-label'>
                Shipping Method
              </label>
              <input
                className='form-control'
                type='text'
                id='shippingMethod'
                defaultValue={data?.shippingMethod}
                placeholder='Enter shipping method'
                {...register('shippingMethod')}
              />
              {formError && formError.shippingMethod && (
                <p className='text-danger'>{formError.shippingMethod.message}</p>
              )}
            </div>

            <div className={`mt-4 mb-2 d-flex justify-content-end`}>
              <div>
                <button
                  className='btn px-1 btn-secondary'
                  type='button'
                  onClick={() => {
                    showUpdatePage(false);
                  }}
                >
                  Cancel
                </button>
                <button className='btn btn-primary ms-1' type='submit'>
                  UPDATE
                </button>
              </div>
            </div>
          </form>
        )}
        {/*    Update FORM END
         ********************************************* */}
      </div>
    </div>
  );
}

export default PaymentsTermsUpdate;
