import API from 'services/axios';
import authHeader from 'services/auth-header';
import { useForm } from 'react-hook-form';
import { useState } from 'react';

function CustomerContactAdd({ id, lastSortId, refreshFunc, setShowAddModal }) {
  const { register, handleSubmit } = useForm();
  // Add Customers
  const addContact = async (formData, e) => {
    e.preventDefault();
    let jsonData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      department: formData.department,
      phone: phone.replace(' ', ''),
      mobilePhone: mobilePhone.replace(' ', ''),
      smsNotification: formData.smsNotification,
      email: formData.email,
    };
    const submitData = {
      sortId: lastSortId + 1,
      type: 'contact',
      customerId: id,
      data: JSON.stringify(jsonData),
    };

    console.log(submitData);

    try {
      const { data } = await API.post(
        '/customers/contacts/insert',
        { data: submitData },
        { headers: authHeader() }
      );
      if (data.success) {
        refreshFunc();
        setShowAddModal(false);
        return;
      }
      alert(data);
    } catch (err) {
      alert(err.message);
    }
  };

  const [phone, setPhone] = useState('');
  const [mobilePhone, setMobilePhone] = useState('');

  function handlePhone(value) {
    setPhone(value);
  }
  return (
    <div className='d-flex justify-content-center flex-column px-2'>
      <h3 className='mb-3'> ADD CONTACT </h3>
      <form onSubmit={handleSubmit(addContact)}>
        <div className='row'>
          <div className='col-6'>
            {/* First Name */}
            <div className='mb-3'>
              <label className='form-label'>First Name</label>
              <input
                {...register('firstName')}
                placeholder='Enter first name'
                type='text'
                className='form-control'
              />
            </div>
            {/* Last Name */}
            <div className='mb-3 me-1'>
              <label className='form-label'>Last Name</label>
              <input
                {...register('lastName')}
                placeholder='Enter last name'
                type='text'
                className='form-control'
              />
            </div>
            {/* Department */}
            <div className='mb-3 me-1'>
              <label className='form-label'>Department</label>
              <input
                {...register('department')}
                placeholder='Enter department'
                type='text'
                className='form-control'
              />
            </div>
          </div>
          <div className='col-6 d-flex flex-column'>
            {/* Email */}
            <div className='mb-3'>
              <label className='form-label'>Email</label>
              <input
                {...register('email')}
                placeholder='Enter email'
                type='text'
                className='form-control'
              />
            </div>
            {/* Phone */}
            <div className='mb-3'>
              <label className='form-label'>Phone</label>
              <input
                value={phone}
                placeholder='Enter Phone'
                className='form-control'
                onChange={(e) => {
                  handlePhone(e.target.value);
                }}
              />
            </div>
            {/* mobile Phone */}
            <div className='row mb-3'>
              <div className='col-7'>
                <label className='form-label'>Mobile Phone</label>
                <input
                  value={mobilePhone}
                  placeholder='Enter Phone'
                  className='form-control'
                  onChange={(e) => {
                    setMobilePhone(e.target.value);
                  }}
                />
              </div>
              <div className='col-5 d-flex flex-column justify-content-around align-items-between'>
                <label className=''>Sms Notification</label>
                <input
                  {...register('smsNotification')}
                  type='checkbox'
                  id='switch0'
                  data-switch='none'
                />
                <label htmlFor='switch0' data-on-label='Yes' data-off-label='No'></label>
              </div>
            </div>

            {/* Buttons */}
            <div className='mb-3  flex-grow-1 d-flex justify-content-end align-items-end'>
              <div>
                <button
                  onClick={() => setShowAddModal(false)}
                  type='button'
                  className='btn btn-secondary me-2 px-1'
                >
                  CLOSE
                </button>
                <button type='submit' className='btn btn-primary'>
                  ADD
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default CustomerContactAdd;
