import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import authHeader from "services/auth-header";
import API from "services/axios";
import SelectMockupCatalogue from "./SelectMockupCatalogue";
import FabricSelectModal from "./components/ProductSection/FabricSelectModal";
import { useSelector } from "react-redux";

export default function ProductEditModal({
  selectedDesign,
  setSelectedDesign,
  setDesignRequests,
  showModal,
  mockupFabrics,
  selectedProduct,
  updateSelectedProduct,
}) {
  const user = useSelector((state) => state.user?.userInfo);
  /*   All States
   ********************************************* */
  const [showFabricsModal, setShowFabricsModal] = useState(false);
  const [formData, setFormData] = useState(null);
  const [selectedFabric, setSelectedFabric] = useState(null);
  const [showMockupSelectModal, setShowMockupSelectModal] = useState(false);

  /*   All Functions
   ********************************************* */

  const updateProduct = async (e) => {
    e.preventDefault();
    try {
      let updatedProduct = {
        ...selectedProduct,
        ...formData,
        fabrics: selectedFabric,
      };
      await updateSelectedProduct(updatedProduct);
      showModal(false);
    } catch (err) {
      console.log(err.message);
    }
  };

  const deleteProduct = async () => {
    try {
      let updatedProducts = selectedDesign?.products.filter(
        (item) => item.id !== selectedProduct.id
      );

      setDesignRequests((prev) => {
        return prev.map((item) => {
          if (item.id === selectedDesign.id) {
            let newItem = { ...item, products: updatedProducts };
            setSelectedDesign((prev) => newItem);
            return newItem;
          } else {
            return item;
          }
        });
      });

      let deletePath = `kms/design-requests/${user?.companyId}/${selectedDesign?.id}/${selectedProduct?.id}`;

      const { data } = await API.patch(
        "/design-request/delete-product",
        {
          id: selectedDesign?.id,
          products: JSON.stringify(updatedProducts),
          path: deletePath,
        },
        { headers: authHeader() }
      );
      if (data?.success) {
        showModal(false);
      } else {
        alert("Something went wrong!");
        console.log(data);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const updateInput = (e) => {
    setFormData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    if (selectedProduct) {
      setFormData({
        title: selectedProduct?.title,
        description: selectedProduct?.description,
        hasPlayerNames: selectedProduct?.hasPlayerNames,
        hasNumbers: selectedProduct?.hasNumbers,
      });
      setSelectedFabric(selectedProduct?.fabrics);
    }
  }, [selectedProduct]);
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <h3>Edit Product</h3>
        <div>
          <button
            onClick={deleteProduct}
            className="btn btn-sm btn-danger me-1"
          >
            Delete Product
          </button>
          <button
            onClick={() => {
              setShowMockupSelectModal(true);
            }}
            className="btn btn-sm btn-success"
          >
            Choose
          </button>
        </div>
      </div>
      <form className="ps-3 pe-3" onSubmit={updateProduct}>
        {/* ----------  Fields  ----------- */}
        <div className="mt-3 mb-3">
          <label htmlFor="title" className="form-label">
            Style No
          </label>
          <input
            className="form-control"
            type="text"
            required
            id="title"
            value={formData?.title || ""}
            placeholder="Enter Style No"
            name="title"
            onChange={updateInput}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Description
          </label>
          <input
            className="form-control"
            type="text"
            id="description"
            value={formData?.description || ""}
            placeholder="Enter description"
            name="description"
            onChange={updateInput}
          />
        </div>
        <div className="mb-2">
          <div className="row mb-1">
            <label className="col-5">Has Player Names?</label>
            <div className="col-7 ">
              <input
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    hasPlayerNames: e.target.checked,
                  })
                }
                checked={formData?.hasPlayerNames}
                type="checkbox"
                id="switch0"
                data-switch="success"
              />
              <label
                htmlFor="switch0"
                data-on-label="Yes"
                data-off-label="No"
              ></label>
            </div>
          </div>
          <div className="row mb-1">
            <label className="col-5">Has Numbers?</label>
            <div className="col-7 ">
              <input
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    hasNumbers: e.target.checked,
                  })
                }
                checked={formData?.hasNumbers}
                type="checkbox"
                id="switch1"
                data-switch="success"
              />
              <label
                htmlFor="switch1"
                data-on-label="Yes"
                data-off-label="No"
              ></label>
            </div>
          </div>
        </div>

        <div className="d-flex gap-2">
          <button
            onClick={() => {
              setShowFabricsModal(true);
            }}
            type="button"
            className={`btn btn-sm ${
              selectedFabric ? "btn-success" : "btn-secondary"
            }`}
          >
            Select Fabrics
          </button>
        </div>
        <div className={`mt-2 mb-2 d-flex justify-content-end`}>
          <div>
            <button
              className="btn px-1 btn-secondary"
              type="button"
              onClick={() => {
                showModal(false);
              }}
            >
              Cancel
            </button>
            <button className="btn btn-primary ms-1" type="submit">
              Update
            </button>
          </div>
        </div>
      </form>
      {/*   Choose Mockup Catalogue
       ********************************************* */}
      <Modal
        contentClassName="shadow-lg custom-border-radius"
        show={showMockupSelectModal}
        onHide={() => setShowMockupSelectModal(false)}
      >
        <Modal.Body className="custom-shadow">
          <div>
            <SelectMockupCatalogue
              setFormData={setFormData}
              showModal={setShowMockupSelectModal}
            />
          </div>
        </Modal.Body>
      </Modal>
      {/*   Fabrics List Modal
       ********************************************* */}
      <Modal
        size="sm"
        contentClassName="shadow-lg custom-border-radius"
        show={showFabricsModal}
        onHide={() => setShowFabricsModal(false)}
      >
        <Modal.Body className="custom-shadow">
          <div>
            <FabricSelectModal
              showModal={setShowFabricsModal}
              selectedFabric={selectedFabric}
              setSelectedFabric={setSelectedFabric}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
