import { setNotification } from "features/Notification/notificationSlice";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function UpdateCustomer({
  refreshFunc,
  setIsLoading,
  showModal,
  selectedDesign,
  setSelectedDesign,
}) {
  const dispatch = useDispatch();

  /*   All States
   ********************************************* */
  const [customersList, setCustomersList] = useState(null);

  const [searchResults, setSearchResults] = useState(null);
  const [searchText, setSearchText] = useState("");

  const [title, setTitle] = useState(selectedDesign?.title);
  const [formData, setFormData] = useState({
    contactName: selectedDesign?.contactName,
    email: selectedDesign?.email,
    phone: selectedDesign?.phone,
  });
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const [showCustomerContactModal, setShowCustomerContactModal] =
    useState(false);
  const [customerContactList, setCustomerContactList] = useState(null);

  let inputFields = [
    {
      // customer contact name
      name: "Contact Name",
      value: "contactName",
      placeholder: "Enter Customer Contact Name",
      type: "text",
      required: true,
    },
    {
      // customer contact email
      name: "Contact Email",
      value: "email",
      placeholder: "Enter Customer Contact Email",
      type: "email",
      required: false,
    },
    {
      // customer contact phone
      name: "Contact Phone",
      value: "phone",
      placeholder: "Enter Customer Contact Phone",
      type: "text",
      required: false,
    },
  ];

  /*   All Functions
   ********************************************* */
  const getCustomersData = async () => {
    try {
      const { data } = await API.get("/customers", { headers: authHeader() });
      setCustomersList(data);
    } catch (err) {
      alert(err.message);
    }
  };

  const handleSelectedCustomer = (item) => {
    setSelectedCustomer(item);

    let customerContactList = item?.customerinfos?.reduce((acc, curr) => {
      if (curr?.type === "contact") {
        acc.push({ ...curr, data: JSON.parse(curr.data) });
        return acc;
      } else {
        return acc;
      }
    }, []);

    setCustomerContactList(customerContactList);
  };

  const updateMockupCustomer = async () => {
    try {
      if (
        title === selectedDesign?.title &&
        selectedCustomer &&
        selectedCustomer?.id === selectedDesign?.customerId
      ) {
        showModal(false);
        return;
      }
      setIsLoading(true);
      const { data } = await API.patch(
        "/design-request/update",
        {
          formData: {
            ...formData,
            id: selectedDesign?.id,
            title: title,
            customerId: selectedCustomer
              ? selectedCustomer?.id
              : selectedDesign?.customerId,
          },
        },
        { headers: authHeader() }
      );

      if (data?.success) {
        let mockupLists = await refreshFunc();
        let selectedMockup = mockupLists?.find(
          (item) => item.id === selectedDesign?.id
        );
        setSelectedDesign(selectedMockup);
        setIsLoading(false);
        showModal(false);
      }
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    getCustomersData();
  }, []);

  useEffect(() => {
    let results = customersList?.filter((item) => {
      return (
        item.id.toString().includes(searchText.toString()) ||
        item.customerName
          .toLowerCase()
          .includes(searchText.toString().toLowerCase())
      );
    });
    setSearchResults(results);

    return () => {
      setSearchResults(null);
    };
  }, [searchText]);
  return (
    <div>
      <div className="mb-3">
        <h3>Change Customer</h3>
      </div>
      {/*   Customer Selection
       ********************************************* */}

      <div className="">
        {!selectedCustomer && (
          <>
            <div className="fw-bold fs-4 mb-1 px-1">Select Customer</div>
            <div className="d-flex align-items-center mb-3">
              <div className="flex-grow-1 ">
                <input
                  className="form-control bg-white shadow-sm"
                  placeholder="Search Customer"
                  type="search"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
            </div>
          </>
        )}
        {selectedCustomer && (
          <div className="my-3">
            <div className="fw-bold fs-4 mb-1 px-1">Selected Customer</div>
            <div
              className="bg-white border-primary border py-2 shadow-lg"
              style={{ borderRadius: 8 }}
            >
              <div className="row ps-3 pe-1">
                <div className="col-2">{selectedCustomer?.id}</div>
                <div className="col-8">{selectedCustomer?.customerName}</div>
                <div className="col-2">
                  <div
                    onClick={() => setSelectedCustomer(null)}
                    className="btn btn-sm btn-danger p-0 px-1"
                  >
                    <i className="mdi mdi-delete"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {searchText && (
          <div
            className="bg-white px-2 shadow-lg scroll py-2 mt-1"
            style={{
              maxHeight: "50%",
              scrollBehavior: "auto",
              borderRadius: 10,
            }}
          >
            {searchResults?.map((item, index) => (
              <div key={index} className="px-2">
                <div
                  className={` rounded py-1  ${
                    item.customerName.toLowerCase() === searchText.toLowerCase()
                      ? "bg-primary bg-opacity-25"
                      : ""
                  } row`}
                >
                  <div className="col-2">{item?.id}</div>
                  <div className="col-8">{item?.customerName}</div>
                  <div className="col-2">
                    <div
                      onClick={() => handleSelectedCustomer(item)}
                      className="btn btn-sm btn-success p-0 px-1"
                    >
                      Select
                    </div>
                  </div>
                </div>
                {!(item.id === searchResults[searchResults.length - 1].id) &&
                  !(searchResults.length === 1) && <hr className="my-0 " />}
              </div>
            ))}
          </div>
        )}

        <div className="mt-3 d-flex justify-content-between align-items-center">
          <h3>Project Details</h3>
          {selectedCustomer && (
            <button
              type="button"
              onClick={(e) => {
                setShowCustomerContactModal(true);
                e.stopPropagation();
              }}
              className="btn btn-sm btn-primary"
            >
              Choose Contact
            </button>
          )}
        </div>

        <div className="mb-2">
          <label htmlFor="title">Project Name</label>
          <input
            type="text"
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="form-control"
            placeholder="Enter Project Name"
          />
        </div>
        {/*   Input Fields
         ********************************************* */}
        {inputFields?.map((item, index) => (
          <div className="mb-3" key={index}>
            <label htmlFor={item.value} className="form-label">
              {item.name}
            </label>
            <input
              className="form-control"
              type={item.type}
              id={item.value}
              value={formData[item.value] || ""}
              placeholder={item.placeholder}
              name={item.value}
              onChange={(e) =>
                setFormData((prev) => {
                  return { ...prev, [item.value]: e.target.value };
                })
              }
              required={item.required}
            />
          </div>
        ))}
        {/*   Buttons
         ********************************************* */}
        <div className="d-flex justify-content-end align-items-center gap-1 mt-3">
          <button
            onClick={() => {
              showModal(false);
            }}
            className="btn btn-secondary"
          >
            Close
          </button>
          <button onClick={updateMockupCustomer} className="btn btn-primary">
            Update
          </button>
        </div>
      </div>
      <Modal
        show={showCustomerContactModal}
        onHide={() => setShowCustomerContactModal(false)}
        size="lg"
      >
        <Modal.Body
          className="custom-shadow bg-light px-3 "
          style={{ borderRadius: 15 }}
        >
          <h4>Select Customer Contact</h4>
          <hr />
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {customerContactList?.map((item, index) => (
                <tr key={index}>
                  <th scope="row">{`${item?.data?.firstName} ${item?.data?.lastName}`}</th>
                  <td> {item?.data?.email} </td>
                  <td> {item?.data?.phone}</td>
                  <td>
                    <div>
                      <button
                        onClick={() => {
                          setFormData((prev) => ({
                            ...prev,
                            contactName: `${item?.data?.firstName} ${item?.data?.lastName}`,
                            email: item?.data?.email,
                            phone: item?.data?.phone,
                          }));
                          setShowCustomerContactModal(false);
                        }}
                        type="button"
                        className="btn btn-sm btn-success"
                      >
                        Select
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </div>
  );
}
