import React, { useEffect, useState } from "react";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function MDesignImage({ selectedImg }) {
  /*   All States
   ********************************************* */
  const [imgSrc, setImgSrc] = useState(null);

  /*   All Functions
   ********************************************* */
  const urlToBase64 = async (url) => {
    // Fetch the image as a blob
    const response = await fetch(url);
    const blob = await response.blob();
    // Create a file reader object
    const reader = new FileReader();
    // Return a promise that resolves with the base64 string
    const base64Url = await new Promise((resolve, reject) => {
      // Set the onloadend event handler
      reader.onloadend = async () => {
        // Get the base64 string from the result
        const base64 = reader.result;
        // Resolve the promise with the base64 string
        resolve(base64);
      };
      // Set the onerror event handler
      reader.onerror = reject;
      // Read the blob as a data URL
      reader.readAsDataURL(blob);
    });
    // console.log(base64Url)

    return base64Url;
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    if (selectedImg) {
      setImgSrc(null);
      const getImageUrl = async () => {
        const { data } = await API.post(
          "/art-approval/get-image",
          { path: selectedImg.img },
          {
            headers: authHeader(),
          }
        );
        let url = await urlToBase64(data);
        setImgSrc(url);
        return data;
      };

      getImageUrl();
    }
  }, [selectedImg]);

  const Spinner = () => {
    return (
      <div className="spinner-grow" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    );
  };

  return (
    <div
      className="bg-white p-2 flex-center h-100 custom-border-radius-sm custom-shadow-sm"
      style={{ overflowY: "auto" }}
    >
      {(!selectedImg || !imgSrc) && <Spinner />}
      {selectedImg && imgSrc && (
        <div style={{ width: "90dvw", height: "100%" }}>
          <img
            className="custom-border-radius-sm"
            src={imgSrc}
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
            alt={"product"}
          />
        </div>
      )}
    </div>
  );
}
