import Spinner from "components/Spinner";
import { useState } from "react";
import authHeader from "services/auth-header";
import API from "services/axios";
import { GetNameWithTimeStamp } from "utils/UtilityFuncs";
import GeneralDetails from "./generalDetails";

function WOProductAdd({
  selectedWorkOrder,
  showAddPage,
  refreshFunc,
  lastSortId,
}) {
  /*   All States Below
   ********************************************* */
  const [active, setActive] = useState("general");
  const [generalData, setGeneralData] = useState({
    productType: "",
    productStyle: "",
    productDescription: "",
    productFabric: "",
    productColor: "",
    totalQuantity: 0,
    totalPrice: 0,
    notes: "",
    hasNumbers: false,
    hasName: false,
  });
  const [loading, setLoading] = useState(false);

  /*   All Functions Below
   ********************************************* */
  function loadingOn() {
    setLoading(true);
  }
  function loadingOff() {
    setLoading(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (generalData?.productType === "") {
      alert("Please select product type");
      return;
    }
    loadingOn();
    try {
      let formData = new FormData();
      let bodyData = {
        ...generalData,
        sortId: lastSortId + 1,
        WOId: selectedWorkOrder?.id,
      };

      //Appending File if present.
      if (generalData?.file) {
        let file = generalData?.file;
        formData.append("file", file);
        let folder = `kms/wo/${selectedWorkOrder?.id}/products`;
        formData.append("folder", folder);
        // Creating filePath of upload folder to save it to database
        let fileName = GetNameWithTimeStamp(file.name);
        let productImage = `${folder}/${fileName}`;
        bodyData.productImage = productImage;
        formData.append("fileName", fileName);
      }

      // Assigning update data to formData in JSON string
      formData.append("data", JSON.stringify(bodyData));

      const { data } = await API.post(`/wo-products`, formData, {
        headers: { ...authHeader(), "Content-Type": "multipart/form-data" },
      });

      if (data) {
        await refreshFunc();
        showAddPage(false);
        loadingOff();
        return;
      }
      loadingOff();
    } catch (err) {
      alert(err.message);
      loadingOff();
    }
  };

  const checkActive = (val) => {
    setActive(val);
  };

  return (
    <div className="row">
      {loading && <Spinner />}
      <div className="col-12">
        {/*    ADD FORM START
         ********************************************* */}
        <div className="mb-13 ms-1">
          <h3>ADD WO Product</h3>
        </div>
        <div className="flex-grow-1">
          <ul className="nav nav-tabs nav-bordered mb-3 d-flex flex-grow-1">
            <li
              onClick={() => {
                checkActive("general");
              }}
              className="nav-item"
            >
              <button
                className={
                  active === "general"
                    ? `nav-link rounded-0 active bg-primary text-white`
                    : "nav-link rounded-0"
                }
              >
                <span className="d-none d-md-block">General</span>
              </button>
            </li>
          </ul>
          <form onSubmit={handleSubmit}>
            <div className="">
              {active === "general" && (
                <div className="tab-pane show active">
                  <GeneralDetails
                    generalData={generalData}
                    setGeneralData={setGeneralData}
                  />
                </div>
              )}
            </div>
            {active === "general" && (
              <div className="text-end my-3 mb-1">
                <button
                  type="button"
                  className="btn btn-secondary me-1"
                  onClick={() => {
                    showAddPage(false);
                  }}
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  ADD
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default WOProductAdd;
