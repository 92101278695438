import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";

export default function CopyPastePicture({
  setFile,
  setFormData,
  propertyName,
  placeholder,
  customClass,
}) {
  const fileInputRef = useRef(null);
  const pastedFileRef = useRef(null);

  const [showPasteModal, setShowPasteModal] = useState(null);
  const [previewImg, setPreviewImg] = useState(null);

  const handlePaste = (e) => {
    setShowPasteModal(true);
    const files = e.clipboardData.files;
    const imageFile = files[0];
    const imgUrl = URL.createObjectURL(imageFile);
    setPreviewImg(imgUrl);
    setFile(files[0]);
  };

  useEffect(() => {}, [showPasteModal]);
  return (
    <div className="position-relative">
      <div>
        <input
          type="text"
          className={`form-control ${customClass}`}
          onPaste={handlePaste}
          placeholder={placeholder}
          ref={fileInputRef}
          onChange={(e) => {
            setFormData((prevState) => {
              return { ...prevState, [propertyName]: e.target.value };
            });
          }}
        />
      </div>
      {/*   Copy Paste Function
       ********************************************* */}
      <Modal show={showPasteModal} onHide={() => setShowPasteModal(false)}>
        <Modal.Body className="">
          <h3>Paste Image</h3>
          {previewImg && (
            <img
              src={previewImg}
              style={{ maxWidth: 450, maxHeight: 300 }}
              alt="Pasted Image"
            />
          )}
          <div className="d-flex justify-content-end mt-3">
            <button
              className="btn btn-primary"
              onClick={() => {
                setShowPasteModal(false);
                setFile(pastedFileRef.current.files[0]);
                fileInputRef.current.value = null;
                fileInputRef.current.value = null;
              }}
            >
              Send
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
