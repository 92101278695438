import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import ContactSelectModal from "../contactSelectModal";
import ChangeCustomer from "./ChangeCustomer";

export default function QuoteInfo({
  formData,
  setFormData,
  refreshFunc,
  selectedQuote,
  disableCustomerSelect,
}) {
  /*   All States
   ********************************************* */
  const [showCustomerModal, setShowCustomerModal] = useState(false);

  const [showContactModal, setShowContactModal] = useState(false);
  const [contactsList, setContactsList] = useState(null);

  /*   All Functions
   ********************************************* */

  const parseJsonData = (data) => {
    let newArray = [];
    data?.map((item) => {
      newArray.push({ ...item, ...JSON.parse(item.data) });
    });
    return newArray;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    setFormData({
      contactFirstName: selectedQuote?.contactFirstName,
      contactLastName: selectedQuote?.contactLastName,
      contactDepartment: selectedQuote?.contactDepartment,
      contactPhone: selectedQuote?.contactPhone,
      contactEmail: selectedQuote?.contactEmail,
      jobName: selectedQuote?.jobName,
    });
  }, [selectedQuote]);

  useEffect(() => {
    if (selectedQuote?.customer?.customerinfos) {
      let typeByContact = [];
      selectedQuote?.customer?.customerinfos?.forEach((item) => {
        if (item?.type === "contact") {
          typeByContact.push(item);
        }
      });
      setContactsList(parseJsonData(typeByContact));
    }
  }, [selectedQuote]);

  return (
    <div className="bg-white custom-shadow flex-grow-1 px-3 pb-1 pt-3 custom-border-radius-sm">
      {/*   WO Info
       ********************************************* */}
      <div className="">
        <div className="d-flex justify-content-between align-items-center gap-3">
          <input
            type="text"
            className="form-control "
            value={formData?.jobName || ""}
            placeholder="Enter Job Name"
            name="jobName"
            onChange={handleChange}
          />

          <div className="d-flex align-items-center gap-1">
            <button
              disabled={!disableCustomerSelect}
              className="btn btn-info text-nowrap"
              onClick={() => setShowCustomerModal(true)}
            >
              Change Customer
            </button>
            <button
              disabled={!disableCustomerSelect}
              className="btn btn-info text-nowrap"
              onClick={() => setShowContactModal(true)}
            >
              Edit Contact
            </button>
          </div>
        </div>
        {/*   Customer Contact
         ********************************************* */}
        <div className="my-2 d-flex align-items-center justify-content-between gap-2">
          <input
            type="text"
            className="form-control"
            value={formData?.contactFirstName || ""}
            placeholder="Enter first name"
            name="contactFirstName"
            onChange={handleChange}
          />

          <input
            type="text"
            className="form-control"
            value={formData?.contactLastName || ""}
            placeholder="Enter last name"
            name="contactLastName"
            onChange={handleChange}
          />

          <input
            type="text"
            className="form-control"
            value={formData?.contactDepartment || ""}
            placeholder="Enter department"
            name="contactDepartment"
            onChange={handleChange}
          />

          <input
            type="text"
            className="form-control"
            value={formData?.contactPhone || ""}
            placeholder="Enter phone"
            name="contactPhone"
            onChange={handleChange}
          />

          <input
            type="text"
            className="form-control"
            value={formData?.contactEmail || ""}
            placeholder="Enter email"
            name="contactEmail"
            onChange={handleChange}
          />
        </div>
      </div>

      {/*   Customer Select Modal
       ********************************************* */}
      <Modal
        size="xl"
        contentClassName="bg-transparent"
        show={showCustomerModal}
        onHide={() => setShowCustomerModal(false)}
      >
        <Modal.Body>
          <ChangeCustomer
            selectedQuote={selectedQuote}
            showModal={setShowCustomerModal}
            refreshFunc={refreshFunc}
          />
        </Modal.Body>
      </Modal>
      {/*   Customer Contact Modal
       ********************************************* */}
      <Modal
        size="xl"
        contentClassName="bg-transparent"
        show={showContactModal}
        onHide={() => setShowContactModal(false)}
      >
        <Modal.Body>
          <ContactSelectModal
            setFormData={setFormData}
            contactsList={contactsList}
            showModal={setShowContactModal}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
