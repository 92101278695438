import Breadcrumbs from "../../components/Breadcrumbs";
import CompanyPasswords from "./passwords/passwords";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PasswordGenerator from "./PasswordGenerator";
import API from "services/axios";
import authHeader from "services/auth-header";
import { setNotification } from "../../features/Notification/notificationSlice";
import useBreadCrumb from "features/Contexts/BreadCrumbs";
import logo from "assets/images/logo.png";
import Toast from "components/Toast/ToastComponent";

function PasswordManager() {
  const user = useSelector((state) => state?.user?.userInfo);
  const { setBreadCrumb } = useBreadCrumb();
  useEffect(() => {
    setBreadCrumb("password manager");
    return () => setBreadCrumb("");
  }, []);

  const dispatch = useDispatch();
  /*   All Satates Below
   ********************************************* */
  const [active, setActive] = useState("company");
  const [showContent, setShowContent] = useState(false);
  const [formError, setFormError] = useState({});
  const [password, setPassword] = useState("");

  const [passwordsData, setPasswordsData] = useState(null);
  const [filterdData, setFilterdData] = useState(null);
  const [filterBy, setFilterBy] = useState(null);

  /*   All Functions Below
   ********************************************* */
  const filterData = () => {
    let data = passwordsData?.filter((item) => item.passwordType == filterBy);
    setFilterdData(data);
  };

  // API call to get All passwords related to user
  const getPasswords = async () => {
    if (showContent) {
      try {
        const { data } = await API.get("/passwords", { headers: authHeader() });
        setPasswordsData(data);
        if (!filterBy) {
          setFilterBy("_company");
        }
      } catch (err) {
        alert(err.message);
      }
    }
  };

  // Changes Bg of selected Tab
  const checkActive = (val) => {
    setActive(val);
  };

  // Verify lock password
  const verifyLockPassword = async (e) => {
    setFormError({});
    setPassword("");
    e.preventDefault();
    try {
      const { data } = await API.post(
        "/verifyLockPassword",
        {
          password,
        },
        { headers: authHeader() }
      );

      if (data?.message?.type == "error") {
        let id = Math.floor(Math.random() * 101 + 1);
        dispatch(
          setNotification({
            message: data.message.message,
            type: "error",
          })
        );
        setFormError({
          password: { type: "password", message: data.message.message },
        });
        return;
      }
      if (data?.message?.type == "success") {
        setShowContent(true);
        return;
      }
    } catch (err) {
      setFormError({
        password: { type: "password", message: `${err}` },
      });
      alert(err);
    }
  };

  // checking in activity of user
  const checkActivity = () => {
    let timeoutId;
    const handleUserActivity = () => {
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => setShowContent(false), 5 * 60000);
    };

    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);

    timeoutId = setTimeout(() => setShowContent(false), 5 * 60000);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
    };
  };

  useEffect(() => {
    checkActivity();
    getPasswords();
  }, [showContent]);

  useEffect(() => {
    if (passwordsData) {
      filterData();
    }
  }, [filterBy, passwordsData]);

  return (
    <div className="d-flex" style={{ minHeight: "100vh" }}>
      <Toast />
      <div className="d-flex flex-grow-1 flex-column">
        <div className="d-flex flex-grow-1">
          {showContent ? (
            <div className="flex-grow-1">
              <ul
                className="nav nav-tabs nav-bordered mb-3 bg-white shadow-sm py-2  d-flex flex-grow-1"
                style={{
                  position: "fixed",
                  top: 0,
                  width: "calc(100vw - 180px)",
                  paddingLeft: 100,
                  marginLeft: 180,
                  zIndex: 101,
                }}
              >
                <li
                  onClick={() => {
                    checkActive("company");
                    setFilterBy("_company");
                  }}
                  className="nav-item"
                >
                  <a
                    role={"button"}
                    className={
                      active == "company"
                        ? `nav-link rounded-0 acitve bg-primary text-white`
                        : "nav-link rounded-0"
                    }
                  >
                    <span className="d-none d-md-block">Company Password</span>
                  </a>
                </li>
                <li
                  onClick={() => {
                    checkActive("bid");
                    setFilterBy("_bid");
                  }}
                  className=""
                >
                  <a
                    role={"button"}
                    className={
                      active == "bid"
                        ? `nav-link rounded-0 acitve bg-primary text-white`
                        : "nav-link rounded-0"
                    }
                  >
                    <i className="mdi mdi-settings-outline d-md-none d-block"></i>
                    <span className="d-none d-md-block">Bid Passwords</span>
                  </a>
                </li>
                <li
                  onClick={() => {
                    checkActive("private");
                    setFilterBy("_private");
                  }}
                  className="nav-item"
                >
                  <a
                    role={"button"}
                    className={
                      active == "private"
                        ? `nav-link rounded-0 acitve bg-primary text-white`
                        : "nav-link rounded-0"
                    }
                  >
                    <i className="mdi mdi-account-circle d-md-none d-block"></i>
                    <span className="d-none d-md-block">Private Passwords</span>
                  </a>
                </li>
                <li
                  onClick={() => checkActive("password")}
                  className="nav-item"
                >
                  <a
                    role={"button"}
                    className={
                      active == "password"
                        ? `nav-link rounded-0 acitve bg-primary text-white`
                        : "nav-link rounded-0"
                    }
                  >
                    <i className="mdi mdi-account-circle d-md-none d-block"></i>
                    <span className="d-none d-md-block">
                      Password Generator
                    </span>
                  </a>
                </li>
              </ul>

              <div style={{ marginTop: 100 }}>
                {passwordsData && filterdData && active != "password" && (
                  <div className="tab-pane show active">
                    <CompanyPasswords
                      passwordsData={filterdData}
                      filterBy={filterBy}
                      refreshFunc={getPasswords}
                    />
                  </div>
                )}
                {active == "password" && (
                  <div className="tab-pane ">
                    <PasswordGenerator />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-center flex-grow-1 align-items-center">
              <div className="card">
                <div className="card-header py-3 text-center bg-primary">
                  <a href="index.html">
                    <span>
                      <img src={logo} alt="logo" height="35" />
                    </span>
                  </a>
                </div>

                <div className="card-body p-4">
                  <div className="text-center w-75 m-auto">
                    <img
                      src="assets/images/users/avatar-1.jpg"
                      height="64"
                      alt="user-image"
                      className="rounded-circle shadow"
                    />
                    <h4 className="text-dark-50 text-center mt-3 fw-bold">
                      Hi ! {user?.firstName.toUpperCase()}
                    </h4>
                    <p className="text-muted mb-4">
                      Enter your password to access the Password Manager.
                    </p>
                  </div>

                  <form onSubmit={verifyLockPassword}>
                    <div className="mb-3">
                      <label htmlFor="password" className="form-label">
                        Password
                      </label>
                      <input
                        autoFocus
                        className="form-control"
                        type="password"
                        required={true}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter Your Password"
                      />
                    </div>

                    <div className="mb-0 text-center">
                      <button className="btn btn-primary" type="submit">
                        Show Content
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PasswordManager;
