import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import DeleteModalButton from "pages/artApproval/Mockups/components/DeleteButton/DeleteModalButton";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import authHeader from "services/auth-header";
import API from "services/axios";
import AttHolidaysList from "./AttHolidaysList";

export default function AttSelectDevice({ showModal }) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  /*   All States
   ********************************************* */
  const [loading, setLoading] = useState(false);

  const [devicesList, setDevicesList] = useState(null);
  const [locationsList, setLocationsList] = useState(null);
  const [formData, setFormData] = useState(null);

  const [isUpdateMode, setIsUpdateMode] = useState(false);

  /*   All Functions
   ********************************************* */
  const loadingOn = () => {
    setLoading(true);
  };

  const loadingOff = () => {
    setLoading(false);
  };

  /*   Device Select Functions
   ********************************************* */
  const getDevicesList = async () => {
    try {
      loadingOn();
      const { data } = await API.get("/get-attendance-devices", {
        headers: authHeader(),
      });

      setDevicesList(data);
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const handleDeviceSelect = (device) => {
    // Set deviceId=device.id in url params using useSearchParams
    setSearchParams({ deviceId: device.id });
    showModal(false);
  };

  const addDevice = async (e) => {
    e.preventDefault();
    try {
      loadingOn();
      const { data } = await API.post("/add-attendance-devices", formData, {
        headers: authHeader(),
      });
      setDevicesList(data);
      setFormData(data);
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  // Update Device
  const updateDevice = async () => {
    try {
      loadingOn();
      const { data } = await API.patch(
        "/patch-attendance-devices/" + formData.id,
        { ...formData, holidaysList: JSON.stringify(formData.holidaysList) },
        { headers: authHeader() }
      );
      if (data?.success) {
        setDevicesList(data.data);
        setFormData({});
        setIsUpdateMode(false);
        dispatch(
          setNotification({
            message: "Updated Successfully",
            type: "success",
          })
        );
      }
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const handleUpdateClick = (device) => {
    setFormData(device);
    setIsUpdateMode(true);
  };

  // Delete Device
  const deleteDevice = async (id) => {
    try {
      loadingOn();
      const { data } = await API.delete("/delete-attendance-device/" + id, {
        headers: authHeader(),
      });

      if (data?.success) {
        dispatch(
          setNotification({
            message: data.message,
            type: "success",
          })
        );
        await getDevicesList();
      } else {
        dispatch(
          setNotification({
            message: data.message,
            type: "error",
          })
        );
      }
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const getLocations = async () => {
    try {
      loadingOn();
      const { data } = await API.get("/locations", {
        headers: authHeader(),
      });

      setLocationsList(data);
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const handleTestConnection = async (device) => {
    try {
      loadingOn();
      const { data } = await API.get(
        "/test-device-connection/" + device.id,
        formData,
        {
          headers: authHeader(),
        }
      );

      if (data?.success) {
        dispatch(
          setNotification({
            message: data.message,
            type: "success",
          })
        );
      } else {
        dispatch(
          setNotification({
            message: data.message,
            type: "error",
          })
        );
      }

      // setDevicesList(data);
      // setFormData(data);
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  /*   All UseEffects
   ********************************************* */

  useEffect(() => {
    let clearTimeoutId = setTimeout(() => {
      getDevicesList();
      getLocations();
    }, 100);
    return () => clearTimeout(clearTimeoutId);
  }, []);
  return (
    <>
      <h3>Devices List</h3>
      <hr className="mt-0" />
      {loading && <Spinner />}

      <div className="my-3">
        <h4>Add Device</h4>
        <form className="pt-2">
          <div className="d-flex align-items-end gap-2">
            <div>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                value={formData?.name || ""}
                onChange={(e) => {
                  setFormData((prev) => {
                    return { ...prev, name: e.target.value };
                  });
                }}
                placeholder="Enter Name"
                className="form-control"
              />
            </div>
            <div>
              <label htmlFor="name">Ip Address</label>
              <input
                type="text"
                value={formData?.ipAddress || ""}
                onChange={(e) => {
                  setFormData((prev) => {
                    return { ...prev, ipAddress: e.target.value };
                  });
                }}
                placeholder="Enter Ip Address"
                className="form-control"
              />
            </div>
            {
              <div style={{ width: "20%" }}>
                <label htmlFor="name">Location</label>
                <div>
                  <select
                    required
                    value={formData?.locationId || ""}
                    onChange={(e) => {
                      setFormData((prev) => {
                        return {
                          ...prev,
                          locationId: parseInt(e.target.value),
                        };
                      });
                    }}
                    name="locationId"
                    id=""
                    className="form-select"
                  >
                    <option value="">Select Location</option>
                    {locationsList &&
                      locationsList?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.locationName}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            }
            <div style={{ width: "20%" }}>
              <label htmlFor="name">Port ( Optional )</label>
              <input
                type="text"
                placeholder="4370"
                value={formData?.port || ""}
                onChange={(e) => {
                  setFormData((prev) => {
                    return { ...prev, port: e.target.value };
                  });
                }}
                className="form-control"
              />
            </div>
            <div>
              {!isUpdateMode ? (
                <button
                  type="button"
                  onClick={addDevice}
                  className="btn btn-primary"
                >
                  ADD
                </button>
              ) : (
                <>
                  <button
                    type="button"
                    onClick={updateDevice}
                    className="btn btn-success me-1"
                  >
                    Update
                  </button>
                  <button
                    onClick={() => {
                      setFormData({});
                      setIsUpdateMode(false);
                    }}
                    className="btn btn-warning"
                  >
                    Cancel
                  </button>
                </>
              )}
            </div>
          </div>
        </form>
      </div>
      <h4 className="mt-4">Devices List</h4>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Name</th>
            <th scope="col">Ip</th>
            <th scope="col">Port</th>
            <th scope="col">location</th>
            <th scope="col">Holidays List</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {devicesList?.map((device) => (
            <tr key={device.id}>
              <th scope="row">{device.id}</th>
              <td>{device.name}</td>
              <td>{device.ipAddress}</td>
              <td>{device.port}</td>
              <td>{device.location?.locationName}</td>
              <td>
                <AttHolidaysList
                  selectedDevice={device}
                  updateDevice={updateDevice}
                  formData={formData}
                  setFormData={setFormData}
                />
              </td>
              <td>
                <button
                  onClick={(e) => {
                    handleDeviceSelect(device);
                  }}
                  className="btn btn-sm btn-success"
                >
                  Select
                </button>
                <button
                  onClick={(e) => {
                    handleTestConnection(device);
                  }}
                  className="btn mx-1 btn-sm btn-success"
                >
                  Test Connection
                </button>

                <button
                  onClick={(e) => {
                    handleUpdateClick(device);
                  }}
                  className="btn btn-sm btn-info me-1"
                >
                  <i className="mdi mdi-pencil"></i>
                </button>

                <DeleteModalButton
                  deleteFunc={deleteDevice}
                  deleteParams={device.id}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
