import Spinner from "components/Spinner";
import React, { useEffect, useState } from "react";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function ImgWithSignedUrl({
  path,
  width,
  height,
  className,
  styles,
}) {
  const [url, setUrl] = useState(null);

  const getImgUrl = async () => {
    const { data } = await API.post(
      "/art-approval/get-image",
      { path },
      {
        headers: authHeader(),
      }
    );

    setUrl(data);
    return data;
  };

  useEffect(() => {
    getImgUrl(path);
  }, [path]);
  return (
    <>
      {!url && <Spinner />}
      {url && (
        <img
          style={styles}
          width={width}
          className={className}
          height={height}
          src={url}
          alt="logo"
        />
      )}
    </>
  );
}
