import React, { useEffect, useState } from "react";
import { socket } from "../socket";
import authHeader from "services/auth-header";
import API from "services/axios";
import DashboardItem from "components/DisplayBoard/DashboardItem";
import Spinner from "components/Spinner";
import { useNavigate } from "react-router-dom";

export default function Dashboard() {
  /*   All States
   ********************************************* */
  const [isConnected, setIsConnected] = useState(socket.connected);
  const navigate = useNavigate();

  /*   All Functions
   ********************************************* */

  /*   All UseEffects
   ********************************************* */

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("updateQuotation", onDisconnect);
    };
  }, [socket]);

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user?.roles === "customer") {
      navigate("/work-orders");
    }
  }, []);

  // if (!quoteData) return <Spinner />;
  return (
    <div className="" style={{ marginLeft: 280, height: "90vh" }}>
      <div className="container text-center mx-auto">
        <h3>{isConnected ? "Socket connected" : "Socket not connected"}</h3>
      </div>
    </div>
  );
}
