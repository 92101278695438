import React, { useEffect, useState } from 'react';

export default function AddByArea({ formData, addMatrix, updateMatrix }) {
  /*   All States
   ********************************************* */
  const [columnCount, setColumnCount] = useState(1);
  const [qtyLevelCount, setQtyLevelCount] = useState(1);
  const [printingGaps, setPrintingGaps] = useState(0.0);

  const [columnName, setColumnName] = useState('SqIn');

  let defaultQuantityName = 'Quantity';

  const [tableHead, setTableHead] = useState([defaultQuantityName, `1 ${columnName}`]);
  const [tableBody, setTableBody] = useState([
    {
      [`${defaultQuantityName}`]: 1,
      [`1 ${columnName}`]: '',
    },
  ]);

  /*   All Functions
   ********************************************* */
  const handleColumnChange = () => {
    let itemsLength = Object.keys(tableBody[0]).length;
    let columnCountInt = parseInt(columnCount) + 1;

    if (columnCountInt === itemsLength) return;

    if (columnCountInt < itemsLength) {
      let tableData = tableBody.map((item) => {
        let newItem = {};
        Object.keys(item).forEach((key, index) => {
          let limit = isNaN(parseInt(key.split(' ')[1])) ? 0 : parseInt(key.split(' ')[1]);

          if (limit < columnCountInt || key.includes(defaultQuantityName)) {
            newItem[key] = item[key];
          }
        });

        return newItem;
      });
      setTableHead(tableHead.slice(0, columnCountInt));

      return setTableBody(tableData);
    } else {
      let lastCountNumber = itemsLength - 1;

      let tableData = tableBody?.map((item, index) => {
        let newItem = { ...item };
        Array.from(Array(columnCountInt - (lastCountNumber + 1)).keys()).map((item, index) => {
          newItem[`${lastCountNumber + (index + 1)} ${columnName}`] = '';
        });

        return newItem;
      });
      Array.from(Array(columnCountInt - (lastCountNumber + 1)).keys()).map((item, index) => {
        setTableHead((prev) => {
          return [...prev, `${lastCountNumber + (index + 1)} ${columnName}`];
        });
      });

      setTableBody(tableData);
    }
  };

  const handleQtyChange = () => {
    let tableData = [];
    if (tableBody.length > qtyLevelCount) {
      let tableData = tableBody.slice(0, qtyLevelCount);
      return setTableBody(tableData);
    } else {
      Array.from(Array(parseInt(qtyLevelCount - tableBody.length)).keys()).map((item, index) => {
        let data = {};
        Object.keys(tableBody[0])?.map((item) => {
          data[item] = '';
        });
        tableData.push({ ...data, [`${defaultQuantityName}`]: tableBody.length + (index + 1) });
      });

      setTableBody([...tableBody, ...tableData]);
    }
  };

  const handleCellEdit = (e, count, field) => {
    // Condition :: Value Must be Number and have 2 decimal number
    // Solution  :: converting received value to number and
    //              using method "toFixed()" to get 2 decimal number
    let rawValue = e.target.innerText;
    const getFloatValue = (rawValue) => {
      let value = 0;
      if (rawValue.includes('.')) {
        let decimalSplit = rawValue.split('.');
        value = parseFloat(decimalSplit[0] + '.' + decimalSplit[1].slice(0, 2));
      } else {
        value = parseInt(e.target.innerText);
      }
      return value;
    }; // =================================================

    // Condition :: Value Must be Number and have 2 decimal number
    // Solution  :: converting received value to number and
    //              using method "toFixed()" to get 2 decimal number

    // let value = Math.round(parseFloat(rawValue) * 100) / 100;

    // Update :: Not using this method because it automatically rounds off
    //           1.565 to 1.57. Need to exclude all number after 2 decimals
    //           placed without rounding off.
    // =================================================

    // Condition :: New Value Must be greater than Previous Value
    // Solution  :: Getting previous Value using combination of parentNode and
    //              FirstChild properties
    let previousCountValue = 0;
    if (e.target === e.target.parentNode.firstChild) {
      previousCountValue =
        e.target?.parentNode?.previousSibling &&
        parseInt(e.target?.parentNode?.previousSibling?.firstChild?.innerText);
    } /*  ============================================================= */

    const value = getFloatValue(rawValue);

    // Update Value in JSON
    if (!isNaN(value) && value > previousCountValue) {
      const updatedData = tableBody.map((item) => {
        if (item[`${defaultQuantityName}`] === count) {
          return { ...item, [field]: value };
        }
        e.target.innerText = value;
        return item;
      });
      setTableBody(updatedData);
    } else {
      const updatedData = tableBody.map((item) => {
        if (item[`${defaultQuantityName}`] === count) {
          return { ...item, [field]: '' };
        }
        e.target.innerText = value;
        return item;
      });
      setTableBody(updatedData);
      e.target.innerText = '';
    }
  };

  const handleColumnNameChange = (e, columnName) => {
    let newValue = e.target.innerText;
    if (newValue === columnName || tableHead.includes(newValue)) {
      e.target.innerText = columnName;
      return;
    }
    let updatedColumnJson = tableBody?.map((item) => {
      let newItem = { ...item };
      Object.keys(item).map((key) => {
        if (key === columnName && !(newValue === columnName)) {
          newItem[newValue] = item[key];
          delete newItem[key];
        }
      });

      return newItem;
    });

    setTableHead((prev) => {
      prev[prev.indexOf(columnName)] = newValue;
      return prev;
    });

    setTableBody(updatedColumnJson);
  };

  const handleChangeAllColumnName = (colName) => {
    /*   Changing the table Column
     ********************************************* */
    let newArr = [];
    tableHead?.map((item) => {
      if (item != 'Quantity') {
        newArr.push(`${item.split(' ')[0]} ${colName}`);
      }
    });
    setTableHead(['Quantity', ...newArr]);

    /*   Changing the table Body
     ********************************************* */
    const newTableBody = tableBody?.reduce((result, currItem) => {
      let newItem = {};
      Object.keys(currItem).map((key) => {
        if (key === 'Quantity') {
          newItem[key] = currItem[key];
        } else {
          newItem[`${key.split(' ')[0]} ${colName}`] = currItem[key];
        }
      });
      result.push(newItem);

      return result;
    }, []);
    setTableBody(newTableBody);
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    if (formData) {
      let data = formData?.matrixJson ? JSON.parse(formData?.matrixJson) : null;
      if (data) {
        let tHead = data?.tableHead;
        let tbody = data?.tableBody;
        setColumnCount(Object.keys(tbody[0])?.length - 1);
        setQtyLevelCount(tbody?.length);
        setTableHead(tHead);
        setTableBody(tbody);
        setColumnName(tHead[1].split(' ')[1]);
        setPrintingGaps(data?.printingGaps);
      }
    }
  }, [formData]);

  /*   Temporarily getting values
   ********************************************* */

  const [quantity, setQuantity] = useState(1);

  const [width, setWidth] = useState(1);
  const [length, setLength] = useState(1);

  const [area, setArea] = useState('');
  const [totalArea, setTotalArea] = useState('');
  const [price, setPrice] = useState('');
  const [pricePerPiece, setPricePerPiece] = useState('');

  const [totalPrice, setTotalPrice] = useState(0);

  // :: Getting Area of 1 Pcs
  useEffect(() => {
    if (width && length && quantity) {
      let calculatedArea = (width + printingGaps) * (length + printingGaps);

      //  Getting Area of 1 Pcs
      let areaRoundedOff = Math.round(parseFloat(calculatedArea) * 100) / 100;
      setArea(areaRoundedOff);

      // Getting Total Area according to quantity
      let totalCalculatedArea = Math.round(parseFloat(quantity * areaRoundedOff) * 100) / 100;
      setTotalArea(totalCalculatedArea);

      getTotalPrice(areaRoundedOff, totalCalculatedArea);
    }
  }, [width, length, quantity, printingGaps]);

  const getTotalPrice = (areaRoundedOff, totalCalculatedArea) => {
    let quantityList = [];
    tableBody.map((item) => {
      quantityList.push(item[Object.keys(item)[0]]);
    });

    // Getting Correct Column Row =================================================================
    let selectedColumn = '';
    // let selectedColumn = '10 Sqft';

    for (let i = 1; i < tableHead?.length; i++) {
      if (totalCalculatedArea < parseInt(tableHead[i].split(' ')[0])) {
        selectedColumn = tableHead[i - 1];
        break;
      }
    }
    if (totalCalculatedArea >= parseInt(tableHead[tableHead.length - 1].split(' ')[0])) {
      selectedColumn = tableHead[tableHead.length - 1];
    }
    // ========================================================

    // Getting Correct Quantity Row =================================================================
    let totalPrice = 0;

    for (let i = 1; i < tableBody?.length; i++) {
      if (quantity < quantityList[i]) {
        let roundedOffPrice = parseFloat(tableBody[i - 1][selectedColumn]) * area;
        totalPrice = roundedOffPrice;
        break;
      }
    }
    if (quantity >= quantityList[quantityList.length - 1]) {
      let roundedOffPrice = parseFloat(tableBody[tableBody.length - 1][selectedColumn]);
      totalPrice = roundedOffPrice;
    }
    // ========================================================

    let roundedOffPrice = Math.round(parseFloat(totalPrice) * 100) / 100;

    setPrice(roundedOffPrice);
    let perPiecePrice = Math.round(parseFloat(roundedOffPrice * areaRoundedOff) * 100) / 100;
    setPricePerPiece(perPiecePrice);
    setTotalPrice();
    setTotalPrice(Math.round(parseFloat(perPiecePrice * quantity) * 100) / 100);
  };

  return (
    <div>
      <div className='d-flex'>
        <div className='w-75'>
          {/*   Buttons & Inputs
           ********************************************* */}
          <div className=''>
            <div className='row'>
              <label className='text-nowrap my-auto col-2'>Columns Name</label>
              <div className='col-2 my-auto'>
                <input
                  value={columnName}
                  onChange={(e) => setColumnName(e.target.value)}
                  placeholder='Label'
                  type='text'
                  className='form-control '
                />
              </div>
              <button
                onClick={() => {
                  handleChangeAllColumnName(columnName);
                }}
                className='col-1 btn btn-sm btn-success my-auto'
              >
                Set
              </button>
            </div>
            <div className='row'>
              <label className='text-nowrap my-auto col-2'>Printing Gaps</label>
              <div className='col-2 my-auto'>
                <input
                  min={0}
                  step={0.01}
                  value={printingGaps}
                  onChange={(e) => setPrintingGaps(parseFloat(e.target.value))}
                  placeholder='Label'
                  type='number'
                  className='form-control '
                />
              </div>
            </div>
            <div className='row'>
              <label className='text-nowrap my-auto col-2'>Columns Count</label>
              <div className='col-2 my-auto'>
                <input
                  min={1}
                  value={columnCount}
                  onChange={(e) => setColumnCount(parseInt(e.target.value))}
                  placeholder='Label'
                  type='number'
                  className='form-control '
                />
              </div>
              <button onClick={handleColumnChange} className='col-1 btn btn-sm btn-success my-auto'>
                Set
              </button>
            </div>
          </div>
        </div>
        <div
          className=' bg-white custom-shadow mb-3 px-3 py-3 custom-border-radius '
          style={{ width: '50%' }}
        >
          <div className='d-flex gap-1'>
            <div className='w-50'>
              Quantity
              <input
                name='quantityRow'
                type='number'
                min={1}
                value={quantity}
                onChange={(e) => {
                  setQuantity(parseInt(e.target.value));
                }}
                className='form-control'
              />
            </div>
            <div className='w-50'>
              Width
              <input
                type='number'
                min={0}
                step={0.01}
                value={width}
                onChange={(e) => {
                  setWidth(parseFloat(e.target.value));
                }}
                className='form-control'
              />
            </div>
            <div className='w-50'>
              Length
              <input
                type='number'
                min={0}
                step={0.01}
                value={length}
                onChange={(e) => {
                  setLength(parseFloat(e.target.value));
                }}
                className='form-control'
              />
            </div>
          </div>

          <div className='d-flex gap-1 my-3'>
            <div className='w-50'>
              Area
              <input
                name='quantityRow'
                type='number'
                min={1}
                value={area}
                disabled
                readOnly
                className='form-control'
              />
            </div>
            <div className='w-50'>
              Total Area
              <input
                name='quantityRow'
                type='number'
                min={1}
                value={totalArea}
                disabled
                readOnly
                className='form-control'
              />
            </div>
            <div className='w-50'>
              Price
              <input
                name='quantityRow'
                type='number'
                min={1}
                value={price}
                disabled
                readOnly
                className='form-control'
              />
            </div>
          </div>

          <div className='d-flex mt-2 align-items-center gap-2'>
            <div className='d-flex gap-2 w-50 align-items-center'>
              <div className='text-nowrap'>Per Piece</div>
              <input
                name='quantityRow'
                type='number'
                min={1}
                value={pricePerPiece}
                disabled
                readOnly
                className='form-control'
              />
            </div>
            <div className='w-50 d-flex gap-1 align-items-center'>
              <div className='text-nowrap'>Total Price</div>
              <div className='bg-primary flex-grow-1 bg-opacity-25  custom-border-radius-sm p-1 text-center'>
                {totalPrice}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*   Matrix Data Table
       ********************************************* */}
      {/* {console.log(tableHead)} */}
      {/* {console.log(JSON.stringify(tableBody))} */}
      {tableBody && (
        <table className='table table-bordered'>
          <thead className='thead-dark'>
            <tr>
              {tableHead?.map((item) => (
                <th
                  contentEditable={!(item === defaultQuantityName)}
                  onBlur={(e) => {
                    handleColumnNameChange(e, item);
                  }}
                  suppressContentEditableWarning={true}
                  key={item}
                >
                  {item}
                </th>
              ))}
              {/* {Object.keys(tableBody[0])?.map((item) => (
          <th
            contentEditable={!(item === defaultQuantityName)}
            onBlur={(e) => {
              handleColumnNameChange(e, item);
            }}
            suppressContentEditableWarning={true}
            key={item}
          >
            {item}
          </th>
        ))} */}
            </tr>
          </thead>
          <tbody>
            {tableBody.map((item, index) => (
              <tr key={index}>
                {tableHead?.map((i, index) => (
                  <td
                    suppressContentEditableWarning={true}
                    contentEditable={true}
                    onBlur={(e) => handleCellEdit(e, item[`${defaultQuantityName}`], i)}
                    key={index}
                  >
                    {item[i]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <div className='d-flex justify-content-end'>
        <button
          onClick={() => {
            formData?.id
              ? updateMatrix({
                  data: {
                    ...formData,
                    type: 'By Area',
                    matrixJson: JSON.stringify({
                      tableBody,
                      tableHead,
                      printingGaps,
                    }),
                  },
                })
              : addMatrix({
                  data: {
                    ...formData,
                    type: 'By Area',
                    matrixJson: JSON.stringify({
                      tableBody,
                      tableHead,
                      printingGaps,
                    }),
                  },
                });
          }}
          className='btn btn-primary'
        >
          {formData?.id ? 'Update' : 'Add'}
        </button>
      </div>
    </div>
  );
}
