import React, { useState, useEffect, useRef } from "react";
import { useReactMediaRecorder } from "react-media-recorder-2";

export default function ArtAudioRecorderComponent({
  deviceId,
  setShowAudioModal,
  addAudioMessage,
  isLoading,
}) {
  /*   All States
   ********************************************* */
  const audioRef = useRef();

  const [micId, setMicId] = useState(null);

  const { status, startRecording, stopRecording, mediaBlobUrl } =
    useReactMediaRecorder({
      audio: deviceId ? { deviceId } : true,
    });

  /*   All Functions
   ********************************************* */

  /*   All UseEffects
   ********************************************* */

  useEffect(() => {
    setMicId((prev) => null);
    const setMicTimeout = setTimeout(() => {
      setMicId(deviceId);
    }, 100);

    return () => {
      clearTimeout(setMicTimeout);
    };
  }, [deviceId]);
  return (
    <div className=" w-100">
      {micId && (
        <div>
          <div className="mb-3 d-flex gap-2">
            <button
              disabled={status === "recording" || status === "acquiring_media"}
              className="btn btn-sm btn-success"
              onClick={() => {
                startRecording();
                audioRef.current.src = "";
              }}
            >
              Start Recording
            </button>
            <button className="btn btn-sm btn-danger" onClick={stopRecording}>
              Stop Recording
            </button>
          </div>
          <div className="my-3">Status : {status}</div>
          <audio ref={audioRef} src={mediaBlobUrl} controls />
        </div>
      )}
      <div className="text-end mt-3">
        <button
          type="button"
          onClick={() => {
            setShowAudioModal(false);
            // resetStates();
          }}
          className="btn btn-sm btn-secondary mx-1"
        >
          Close
        </button>
        <button
          type="button"
          disabled={isLoading || !mediaBlobUrl}
          onClick={() => {
            addAudioMessage(mediaBlobUrl);
          }}
          className="btn btn-sm btn-primary"
        >
          Add
        </button>
      </div>
    </div>
  );
}
