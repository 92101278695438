import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function AttAddUpdateRecord({
  isUpdateMode,
  updateData,
  showModal,
  setIsUpdateMode,
  setUpdateData,
  refreshFunc,
}) {
  const dispatch = useDispatch();
  const params = useParams();

  /*   All States
   ********************************************* */

  let inputFields = [
    { name: "Date", value: "date", type: "date" },
    { name: "Time", value: "time", type: "time" },
  ];

  const [formData, setFormData] = useState(null);

  const [loading, setLoading] = useState(false);

  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };

  /*   All Functions
   ********************************************* */

  const addRecord = async (e) => {
    e.preventDefault();

    // Get "2024-10-21 14:41:49" format from formData.date and formData.time
    let dateString = formData.date + " " + formData.time;
    let finalDateString = new Date(dateString).toISOString();

    const requestBody = {
      recordTime: finalDateString,
      deviceId: parseInt(params.deviceId),
      userId: parseInt(params.userId),
    };

    try {
      loadingOn();
      const { data } = await API.post("/attendance-logs", requestBody, {
        headers: authHeader(),
      });
      if (data?.success) {
        dispatch(
          setNotification({
            message: data?.message,
            type: "success",
          })
        );
        await refreshFunc();
        resetData();
        showModal(false);
      } else {
        dispatch(
          setNotification({
            message: data?.message,
            type: "error",
          })
        );
      }
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const updateRecord = async (e) => {
    e.preventDefault();

    // Get "2024-10-21 14:41:49" format from formData.date and formData.time
    let dateString = formData.date + " " + formData.time;
    let finalDateString = new Date(dateString).toISOString();

    const requestBody = {
      recordTime: finalDateString,
      deviceId: params.deviceId,
      userId: params.userId,
      id: updateData.id,
    };

    try {
      loadingOn();
      const { data } = await API.patch("/attendance-logs", requestBody, {
        headers: authHeader(),
      });
      if (data?.success) {
        dispatch(
          setNotification({
            message: data?.message,
            type: "success",
          })
        );
        await refreshFunc();
        resetData();
        showModal(false);
      } else {
        dispatch(
          setNotification({
            message: data?.message,
            type: "error",
          })
        );
      }
      loadingOff();
    } catch (err) {
      loadingOff();
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  function handleChange(e) {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  }

  function resetData() {
    setIsUpdateMode(false);
    setUpdateData(null);
    setUpdateData(setFormData);
  }

  /*   All UseEffects
   ********************************************* */

  useEffect(() => {
    // Set date and time from updateData
    if (isUpdateMode) {
      const date = new Date(updateData.recordTime);

      //   Get time in format 17:32 or 06:32
      let time = date.toLocaleTimeString("en-US", {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
      });

      setFormData({
        date: date.toISOString().slice(0, 10),
        time,
      });
    }
  }, [updateData]);
  return (
    <div>
      <h3>{isUpdateMode ? "Update Record" : "Add Record"}</h3>
      {loading && <Spinner />}
      <form className="mt-3">
        {/*   Input Fields
         ********************************************* */}
        {inputFields.map((item, index) => (
          <div className="mb-2" key={index}>
            <label htmlFor={item.value} className="form-label">
              {item.name}
            </label>
            <input
              type={item.type}
              className="form-control"
              id={item.value}
              name={item.value}
              onChange={handleChange}
              value={formData?.[item.value] || ""}
            />
          </div>
        ))}

        {/*   Buttons
         ********************************************* */}
        <div className="text-end">
          <button
            className="btn btn-sm btn-secondary me-1"
            type="button"
            onClick={() => {
              resetData();
              showModal(false);
            }}
          >
            Cancel
          </button>
          <button
            className="btn btn-sm btn-success"
            type="submit"
            disabled={loading}
            onClick={isUpdateMode ? updateRecord : addRecord}
          >
            {isUpdateMode ? "Update" : "Add"}
          </button>
        </div>
      </form>
    </div>
  );
}
