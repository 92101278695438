import React, { useEffect, useState } from 'react';
import { generatePassword } from 'features/passwordGeneraotr/passwordSlice';
import { useForm } from 'react-hook-form';
import authHeader from 'services/auth-header';
import API from 'services/axios';
import { useDispatch, useSelector } from 'react-redux';

import CryptoJS from 'crypto-js';

function CompanyPasswordUpdate({ data, showUpdatePage, filterBy, refreshFunc }) {
  /*   All Satates Below
   ********************************************* */
  const { register, handleSubmit } = useForm();
  const [selectdType, setSelectedType] = useState(filterBy);
  const [randomPassword, setRandompassword] = useState('');
  const { password } = useSelector((state) => state.password);
  const user = useSelector((state) => state.user.userInfo);
  const [usersList, setUsersList] = useState([]);
  const [searchUserText, setSearchUserText] = useState('');
  const [selectedUsers, setSelectedUsers] = useState(JSON.parse(data.availableTo));

  // redux
  const dispatch = useDispatch();

  /*   All Functions Below
   ********************************************* */
  const updatePasswordData = async (formData, e) => {
    e.preventDefault();

    const userId = JSON.parse(localStorage.getItem('user')).id;
    const submitData = {
      ...formData,
      passwordValue: randomPassword,
      userId,
      passwordType: selectdType,
      id: data.id,
      availableTo: JSON.stringify(selectedUsers),
    };

    try {
      const resp = await API.patch(
        '/updatepassword',
        { data: submitData },
        { headers: authHeader() }
      );

      if (resp.data.message.type === 'success') {
        refreshFunc();
        showUpdatePage(false);
        return;
      }
    } catch (err) {
      alert(err.message);
    }
  };

  // Adds slected User to slectedUsersList
  const handleUserClick = (clickedUser) => {
    setSelectedUsers(
      selectedUsers.includes(clickedUser)
        ? selectedUsers.filter((item) => item !== clickedUser)
        : [...selectedUsers, clickedUser]
    );
  };

  // Get all users with role = users
  const getUsers = async () => {
    try {
      const { data } = await API.get('/password/users', {
        headers: authHeader(),
      });

      if (data.message?.type === 'error') {
        alert(data?.message?.message);
        return;
      }
      setUsersList(data);
    } catch (error) {
      alert(error.message);
    }
  };

  // generates random password using settings from Password Generator tabs
  const generateRandomPassword = async () => {
    dispatch(generatePassword());
    setRandompassword(password);
  };

  const decryptPassword = (data) => {
    try {
      const decrypted = CryptoJS.AES.decrypt(data, process.env.REACT_APP_ENCRYPTION_KEY).toString(
        CryptoJS.enc.Utf8
      );
      return decrypted;
    } catch (err) {
      alert(err.message);
    }
  };

  useEffect(() => {
    if (data?.passwordValue) {
      setRandompassword(decryptPassword(data?.passwordValue));
    }
  }, [data]);

  return (
    <div className='row'>
      <div className='col-12'>
        {/*    Update FORM START
         ********************************************* */}
        {data && (
          <form className='ps-3 pe-3' onSubmit={handleSubmit(updatePasswordData)}>
            <div className='mb-3'>
              <h3>UPDATE {data?.title?.toUpperCase()} FORM</h3>
            </div>
            {/* ----------  Fields  ----------- */}
            <div className='mb-3'>
              <label htmlFor='title' className='form-label'>
                Type
              </label>
              <div className='mb-3 w-100'>
                <select onChange={(e) => setSelectedType(e.target.value)} className='form-select'>
                  <option selected={selectdType === '_company'} value={'_company'}>
                    Company
                  </option>
                  <option selected={selectdType === '_bid'} value={'_bid'}>
                    Bid
                  </option>
                  <option selected={selectdType === '_private'} value={'_private'}>
                    Private
                  </option>
                </select>
              </div>
            </div>
            <div className='mb-3'>
              <label htmlFor='title' className='form-label'>
                Title
              </label>
              <input
                className='form-control'
                type='text'
                id='title'
                required
                defaultValue={data?.title}
                placeholder='Enter title'
                {...register('title')}
              />
            </div>
            <div className='mb-3'>
              <label htmlFor='password' className='form-label'>
                Url
              </label>
              <input
                className='form-control'
                type='text'
                id='url'
                placeholder='Enter url'
                defaultValue={data?.url}
                {...register('url')}
              />
            </div>
            <div className='mb-3'>
              <label htmlFor='username' className='form-label'>
                Username
              </label>
              <input
                className='form-control'
                type='text'
                id='username'
                defaultValue={data?.username}
                placeholder='Enter username'
                {...register('username')}
              />
            </div>

            <div className='mb-3'>
              <label htmlFor='password' className='form-label'>
                Password
              </label>
              <div className='d-flex' onChange={(e) => setRandompassword(e.target.value)}>
                <input
                  className='form-control'
                  type='text'
                  id='password'
                  value={randomPassword}
                  placeholder='Enter password'
                  {...register('passwordValue')}
                />
                <button
                  onClick={generateRandomPassword}
                  type='button'
                  className='bg-success btn-sm btn text-white'
                >
                  Random
                </button>
              </div>
            </div>

            <div
              className={`mt-4 mb-2 d-flex ${
                user.roles && user.roles != 'user' && filterBy != '_private'
                  ? 'justify-content-between'
                  : 'justify-content-end'
              }`}
            >
              {user.roles && user.roles != 'user' && filterBy != '_private' && (
                <div>
                  <button
                    className='btn btn-light dropdown-toggle'
                    type='button'
                    id='dropdownMenuButton'
                    data-bs-toggle='dropdown'
                    aria-haspopup='true'
                    onClick={() => getUsers()}
                    aria-expanded='false'
                  >
                    Allowed To
                  </button>
                  <div
                    className='dropdown-menu w-50 shadow-lg'
                    aria-labelledby='dropdownMenuButt on'
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className='d-flex justify-content-center flex-column  m-2 '>
                      <input
                        onChange={(e) => {
                          setSearchUserText(e.target.value);
                        }}
                        value={searchUserText}
                        className='form-control mb-2'
                        type={'search'}
                        placeholder='Search users'
                      />
                      {usersList &&
                        !searchUserText &&
                        usersList.map((item) => (
                          <div key={item.id} className='form-check form-switch mb-1'>
                            <input
                              type='checkbox'
                              checked={selectedUsers.includes(item.id)}
                              className='form-check-input'
                              onChange={() => handleUserClick(item.id)}
                            />
                            <label className='form-check-label'>
                              {`${item.firstName} ${item.lastName}`}
                            </label>
                          </div>
                        ))}
                      {usersList &&
                        searchUserText &&
                        usersList
                          .filter((o) =>
                            Object.keys(o).some((k) => {
                              if (typeof o[k] == 'string') {
                                return o[k]
                                  .toString()
                                  .toLowerCase()
                                  .includes(searchUserText.toLowerCase());
                              } else if (o[k] != null) {
                                o[k]
                                  .toString()
                                  .toLowerCase()
                                  .includes(searchUserText.toLowerCase());
                              }
                            })
                          )
                          .map((item) => (
                            <div key={item.id} className='form-check form-switch mb-1'>
                              <input
                                type='checkbox'
                                className='form-check-input'
                                checked={selectedUsers.includes(item.id)}
                                onChange={() => handleUserClick(item.id)}
                              />
                              <label className='form-check-label'>
                                {`${item.firstName} ${item.lastName}`}
                              </label>
                            </div>
                          ))}
                    </div>
                  </div>
                </div>
              )}
              <div>
                <button
                  className='btn px-1 btn-secondary'
                  type='button'
                  onClick={() => {
                    showUpdatePage(false);
                  }}
                >
                  Cancel
                </button>
                <button className='btn btn-primary ms-1' type='submit'>
                  UPDATE
                </button>
              </div>
            </div>
          </form>
        )}
        {/*    Update FORM END
         ********************************************* */}
      </div>
    </div>
  );
}

export default CompanyPasswordUpdate;
