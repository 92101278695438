import { createSlice } from "@reduxjs/toolkit";
import {
  registerCompany,
  companySubscription,
  getCompany,
} from "./companyActions";

const initialState = {
  loading: false,
  company: null,
  error: null,
  success: false,
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    resetCompany: (state) => {
      state.company = null;
    },
  },
  extraReducers: {
    // register company
    [registerCompany.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [registerCompany.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [registerCompany.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true; // registration successful
      state.company = payload?.company;
    },
    [registerCompany.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    // update company subscription
    [companySubscription.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [companySubscription.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true; // registration successful
      state.company = payload;
    },
    [companySubscription.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    // Get company details
    [getCompany.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getCompany.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.company = payload;
    },
    [getCompany.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const { resetCompany } = companySlice.actions;

export default companySlice.reducer;
