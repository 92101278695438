import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../features/user/userActions";

function ResetPassword() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // All states below
  const { register, handleSubmit } = useForm();
  const [newShowPass, setNewShowPass] = useState(false);
  const [confirmShowPass, setConfirmShowPass] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const [validateError, setValidateError] = useState([]);

  const { userInfo } = useSelector((state) => state.user);
  const { company } = useSelector((state) => state.company);

  const submitForm = async (data) => {
    setIsLoading(true);
    dispatch( resetPassword( { ...data, token: params.token } ) )
      .then( ({ payload }) => {
        if ( payload.status === 'success' ) {
          navigate('/login');
        } else {
          setError(payload);
        }
        setIsLoading(false);
      })
  }

  useEffect(() => {
    if (
      userInfo !== null &&
      userInfo?.companyId !== undefined &&
      company?.id === userInfo?.companyId &&
      company?.subscriptionPlan !== null
    ) {
      navigate('/dashboard');
    }
  }, [])


  return (
    <>
      <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-4 col-lg-5">
              <div className="card">
                <div className="card-header pt-4 pb-4 text-center bg-primary">
                  <a href="index.html">
                    <span>
                      <img
                        src={require("../assets/images/logo.png")}
                        alt="logo"
                        height="22"
                      />
                    </span>
                  </a>
                </div>

                <div className="card-body p-4">
                  <div className="text-center w-75 m-auto">
                    <h3 className="text-dark-50 text-center pb-0 fw-bold">
                      Set New Password
                    </h3>
                    <p className="text-muted mb-4">
                      You new password must be different to previously used passwords
                    </p>
                  </div>

                  { error && <p className="link-danger">{error}</p> }

                  <form className='needs-validation' onSubmit={handleSubmit(submitForm)} noValidate>
                    <div className="mb-3">
                      <label htmlFor="newPassword" className="form-label">
                        New Password
                      </label>
                      <div className="input-group input-group-merge">
                        <input
                          className="form-control"
                          type={newShowPass ? "text" : "password"}
                          placeholder="Enter new password"
                          {...register("password", {
                            required: {
                              value: true,
                              message: "Password is required",
                            },
                          })}
                        />
                        <div
                          className="input-group-text"
                          data-password="false"
                          onClick={() => setNewShowPass(!newShowPass)}
                        >
                          <span className="password-eye"></span>
                        </div>
                      </div>
                      {validateError.name == "password" && (
                        <div>
                          <small className="text-danger">
                            {validateError.message}
                          </small>
                        </div>
                      )}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="confirmPassword" className="form-label">
                        Confirm Password
                      </label>
                      <div className="input-group input-group-merge">
                        <input
                          className="form-control"
                          type={confirmShowPass ? "text" : "password"}
                          placeholder="Confirm new password"
                          {...register("confirmPassword", {
                            required: {
                              value: true,
                              message: "Please Re-type Password",
                            },
                          })}
                        />

                        <div
                          className="input-group-text"
                          data-password="false"
                          onClick={() => setConfirmShowPass(!confirmShowPass)}
                        >
                          <span className="password-eye"></span>
                        </div>
                      </div>
                      {validateError.name == "confirmPassword" && (
                        <div>
                          <small className="text-danger">
                            {validateError.message}
                          </small>
                        </div>
                      )}
                    </div>

                    <div className="mb-3 mb-0 text-center">
                      <button className="btn btn-primary" type="submit" disabled={isLoading}>
                        {isLoading && (
                          <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                        )}
                        Reset Password
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-12 text-center">
                  <p className="text-muted"> Back to<Link to={"/login"} className="p-1">Login</Link></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
