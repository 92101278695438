import Spinner from 'components/Spinner';
import { setNotification } from 'features/Notification/notificationSlice';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { useDispatch } from 'react-redux';
import authHeader from 'services/auth-header';
import API from 'services/axios';

function Email({ selectedQuotation, setShowEmailModal, blobData }) {
  const dispatch = useDispatch();
  /*   All States
   ********************************************* */
  const sendTo =
    typeof selectedQuotation?.contactData === 'string'
      ? JSON.parse(selectedQuotation?.contactData)?.email
      : selectedQuotation?.contactData?.email;
  const [emailForm, setEmailForm] = useState({
    to: sendTo,
  });
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const [emailFormats, setEmailFormats] = useState(null);
  const [emailSubject, setEmailSubject] = useState('');

  const subjectRef = useRef();

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
      ['clean'],
    ],
  };
  /*   All Functions
   ********************************************* */
  const sendEmail = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    file &&
      Array.from(file).map((item) => {
        formData.append('files', item);
      });
    formData.append('data', JSON.stringify({ ...emailForm, subject: emailSubject }));
    formData.append(  
      'fileName',
      `Quote-${selectedQuotation?.id}-${selectedQuotation?.customerName.slice(0, 10)}.pdf`
    );

    try {
      setLoading(true);
      const { data } = await API.post('/quoteproducts/mail', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (data == 'success') {
        setShowEmailModal(false);
        setLoading(false);
      }
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: 'error',
        })
      );
      setLoading(false);
    }
  };

  const handleFiles = (files) => {
    let filesArray = [];
    Array.from(files).map((item) => {
      filesArray.push(item);
    });
    // setFile(filesArray);
    setFile([...file, ...filesArray]);
  };

  const getAllEmailFormat = async () => {
    try {
      const { data } = await API.get('/emailFormat', { headers: authHeader() });
      let dataReceived = data?.data;
      setEmailFormats(dataReceived);
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: 'error',
        })
      );
    }
  };

  const replaceVariables = (val) => {
    let updateQuotationId = val.replaceAll('&lt;&lt; ID &gt;&gt;', selectedQuotation?.id);

    let updateCustomerId = updateQuotationId.replaceAll(
      '&lt;&lt; Customer ID &gt;&gt;',
      selectedQuotation?.customerId
    );
    let updateCustomerName = updateCustomerId.replaceAll(
      '&lt;&lt; Customer Name &gt;&gt;',
      selectedQuotation?.customerName
    );
    return updateCustomerName;
  };

  const setFormat = async (e) => {
    let subjectTest = await emailFormats?.filter((item) => item?.format == e.target.value)[0]
      ?.subject;
    setEmailForm({
      ...emailForm,
      body: await replaceVariables(e.target.value),
    });
    setEmailSubject(subjectTest ? subjectTest : '');
  };

  useEffect(() => {
    getAllEmailFormat();
    let filesArray = [];
    filesArray.push(blobData);
    setFile(filesArray);
  }, []);

  return (
    <div className='d-flex flex-column px-2'>
      <h3>SEND EMAIL</h3>
      <hr className='mt-0 mb-3' />
      <form onSubmit={sendEmail}>
        {loading && <Spinner />}
        <div className='flex-grow-1'>
          <div className='row mb-2'>
            <div className='col-2 my-auto '>TO</div>
            <div className='col-10'>
              <input
                className='form-control'
                type='email'
                value={emailForm?.to}
                onChange={(e) => {
                  setEmailForm({ ...emailForm, to: e.target.value });
                }}
              />
            </div>
          </div>

          <div className='row mb-2'>
            <div className='col-2 my-auto '>CC</div>
            <div className='col-10'>
              <input
                className='form-control'
                type='email'
                value={emailForm?.cc}
                onChange={(e) => {
                  setEmailForm({ ...emailForm, cc: e.target.value });
                }}
              />
            </div>
          </div>

          <div className='row mb-2'>
            <div className='col-2 my-auto '>BCC</div>
            <div className='col-10'>
              <input
                className='form-control'
                type='email'
                value={emailForm?.bcc}
                onChange={(e) => {
                  setEmailForm({ ...emailForm, bcc: e.target.value });
                }}
              />
            </div>
          </div>

          <div className='d-flex mb-2'>
            <div className='col-2 my-auto '>SUBJECT</div>
            <div className='col-10'>
              <input
                ref={subjectRef}
                className='form-control'
                value={emailSubject}
                onChange={(e) => {
                  setEmailSubject(e.target.value);
                }}
              />
            </div>
          </div>

          <div className='d-flex mb-2'>
            <div className='col-2 my-auto '>FILES</div>
            <div className='col-10'>
              <input
                type='file'
                multiple
                className='form-control'
                onChange={(e) => {
                  handleFiles(e.target.files);
                }}
              />
            </div>
          </div>

          <div className='d-flex mb-2'>
            <div className='col-2 my-auto '>ATTACHMENTS</div>
            <div className='col-10 d-flex'>
              <div className='bg-secondary bg-opacity-25 d-flex p-1 flex-wrap flex-grow-1'>
                {file &&
                  file.map((item, index) => (
                    <span
                      className='bg-white px-2 py-1 shadow-sm me-1 mb-1'
                      style={{ borderRadius: 5 }}
                      key={index}
                    >
                      {item?.name
                        ? item?.name?.substring(0, 20) + '...'
                        : `Quote-${selectedQuotation?.id}-${selectedQuotation?.customerName.slice(
                            0,
                            10
                          )}.pdf`}
                      <i
                        type='button'
                        onClick={() => {
                          setFile((prev) => prev.filter((pre) => pre != item));
                        }}
                        className='text-danger ms-1 mdi mdi-delete'
                      ></i>
                    </span>
                  ))}
              </div>
              <div className='d-flex'>
                <button
                  onClick={() => {
                    setFile(null);
                  }}
                  type='button'
                  className='btn btn-danger'
                >
                  CLEAR
                </button>
              </div>
            </div>
          </div>
          <div className='d-flex mb-2'>
            <div className='col-2 my-auto '>Email Formats</div>
            <div className='col-10'>
              <select onChange={setFormat} className='form-control'>
                <option value={''} selected className='form-control'>
                  None
                </option>
                {emailFormats?.map((item, index) => (
                  <option key={index} value={item?.format} className='form-control'>
                    {item?.title}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <ReactQuill
            modules={modules}
            theme='snow'
            value={emailForm?.body}
            onChange={(val) => setEmailForm({ ...emailForm, body: val })}
          />
        </div>

        <div className='text-end my-3 mb-1'>
          <button
            type='button'
            className='btn btn-secondary me-1'
            onClick={() => {
              setShowEmailModal(false);
            }}
          >
            Close
          </button>
          <button type='submit' className='btn btn-primary'>
            Send
          </button>
        </div>
      </form>
    </div>
  );
}

export default Email;
