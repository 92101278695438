import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  forgetPassword,
  VerifyResetCode,
  UpdateUserPassword,
} from "../features/user/userActions";

function ForgetPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // All states below
  const { register, handleSubmit } = useForm();
  const [newShowPass, setNewShowPass] = useState(false);
  const [confirmShowPass, setConfirmShowPass] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [showEmail, setShowEmail] = useState(true);
  const [showVerify, setShowVerify] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [userEmail, setUserEmail] = useState("");
  const [validateError, setValidateError] = useState([]);

  const { userInfo } = useSelector((state) => state.user);
  const { company } = useSelector((state) => state.company);

  // =========== functions ==============

  // Send user reset mail
  const SendEmail = async (data) => {
    setValidateError([]);
    setIsLoading(true);

    dispatch( forgetPassword( { email: data.email } ) )
      .then( ({ payload }) => {
        if ( payload.status ) {
          setEmailSent(true)
        }
        setIsLoading(false);
      })
      .catch( error => {
        console.log(error);
      });
    setUserEmail(data.email);

    // if ("error" in resEmail.payload[0]) {
    //   setValidateError(resEmail.payload[0].error);
    // }

    // if ("success" in resEmail.payload[0]) {
    //   setShowEmail(!showEmail);
    //   setShowVerify(!showVerify);
    // }
  };

  // Verify Code
  const VerifyCode = async (data) => {
    setValidateError([]);
    setIsLoading(true);
    const resCode = dispatch(
      VerifyResetCode({ userEmail, code: data.code })
    );
    if ("error" in resCode.payload[0]) {
      setValidateError(resCode.payload[0].error);
    }
    if ("success" in resCode.payload[0]) {
      setShowVerify(!showVerify);
      setShowPassword(!showPassword);
    }
    setIsLoading(false);
  };

  // User Update Funtion
  const UpdatePassword = async (data) => {
    setValidateError([]);
    setIsLoading(true);
    if (data.password != data.confirmPassword) {
      setValidateError({
        name: "confirmPassword",
        message: "Passwords dont match",
      });
      setIsLoading(false);
      return;
    }
    const updatePassword = dispatch(
      UpdateUserPassword({ email: userEmail, password: data.password })
    );
    setIsLoading(false);
    navigate("/login");
  };

  // Error Validation
  const onError = (data) => {
    setValidateError([]);
    if ("email" in data) {
      return setValidateError({ name: "email", message: data.email.message });
    }
    if ("code" in data) {
      return setValidateError({
        name: "code",
        message: data.code.message,
      });
    }
    if ("password" in data) {
      return setValidateError({
        name: "password",
        message: data.password.message,
      });
    }
    if ("confirmPassword" in data) {
      return setValidateError({
        name: "confirmPassword",
        message: data.confirmPassword.message,
      });
    }
  };

  useEffect(() => {
    console.log(isLoading)
    if (
      userInfo !== null &&
      userInfo?.companyId !== undefined &&
      company?.id === userInfo?.companyId &&
      company?.subscriptionPlan !== null
    ) {
      navigate('/dashboard');
    }
  }, [userInfo, company])


  return (
    <>
      <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-4 col-lg-5">
              {/*
              ==============  send email card  ====================
              */}
              {showEmail && (
                <div className="card">
                  <div className="card-header pt-4 pb-4 text-center bg-primary">
                    <a href="index.html">
                      <span>
                        <img
                          src={require("../assets/images/logo.png")}
                          alt="logo"
                          height="22"
                        />
                      </span>
                    </a>
                  </div>

                  <div className="card-body p-4">
                    <div className="text-center w-75 m-auto">
                      <h3 className="text-dark-50 text-center pb-0 fw-bold">
                        { emailSent ? 'Check your email' : 'Reset Password' }
                      </h3>
                      { emailSent ? (
                        <p className="text-muted mb-4">
                          we sent a password reset link to <strong>{userEmail}</strong>
                        </p>
                      ) : (
                        <p className="text-muted mb-4">
                          Enter your email address and we'll send you an email with instructions to reset your password.
                        </p>
                      )}
                    </div>

                    { emailSent ? (
                        <div className="mb-0 text-center d-grid">
                          <p className="text-muted"> Didn't receive the email?<a onClick={() => SendEmail({ email: userEmail })} className="p-1">Click to resend</a> </p>
                        </div>
                    ) : (
                      <form
                        className={ emailSent ? 'd-none' : 'needs-validation' }
                        onSubmit={handleSubmit(SendEmail, onError)}
                        noValidate
                      >
                        <div className="mb-3">
                          <label htmlFor="email" className="form-label">
                            Email address
                          </label>
                          <input
                            className="form-control"
                            type="email"
                            placeholder="Enter your email"
                            name="email"
                            {...register("email", {
                              required: {
                                value: true,
                                message: "email is required",
                              },
                            })}
                          />
                          {validateError.name == "email" && (
                            <div>
                              <small className="text-danger">
                                {validateError.message}
                              </small>
                            </div>
                          )}
                        </div>

                        <div className="mb-0 text-center d-grid">
                          <button className="btn btn-primary " type="submit" disabled={isLoading}>
                            {isLoading && (
                              <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                            )}
                            Send Email
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              )}
              {/*
              ============  Verify code card  ==============
              */}
              {showVerify && (
                <div className="card">
                  <div className="card-header pt-4 pb-4 text-center bg-primary">
                    <a href="index.html">
                      <span>
                        <img
                          src={require("../assets/images/logo.png")}
                          alt="logo"
                          height="22"
                        />
                      </span>
                    </a>
                  </div>

                  <div className="card-body p-4">
                    <div className="text-center w-75 m-auto">
                      <h2 className="text-dark-50 text-center pb-0 fw-bold">
                        Forgot Password
                      </h2>
                      <p className="text-muted mb-4">
                        Please enter reset code received sent to{" "}
                        <b className="text-dark-50">{userEmail}</b>.
                      </p>
                    </div>

                    <form
                      className="needs-validation"
                      onSubmit={handleSubmit(VerifyCode, onError)}
                      noValidate
                    >
                      <div className="mb-3">
                        <label htmlFor="restCode" className="form-label">
                          Verify Code
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Reset Code"
                          name="restCode"
                          {...register("code", {
                            required: {
                              value: true,
                              message: "Reset Code is required",
                            },
                          })}
                        />
                        {validateError.name == "code" && (
                          <div>
                            <small className="text-danger">
                              {validateError.message}
                            </small>
                          </div>
                        )}
                      </div>

                      <div className="mb-3 mb-0 text-center">
                        <button className="btn btn-primary" type="submit">
                          Verify
                          {isLoading && (
                            <span
                              className="spinner-border spinner-border-sm mx-1 mb-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}
              {/*
              ==============  Update Password Card ==============
               */}
              {showPassword && (
                <div className="card">
                  <div className="card-header pt-4 pb-4 text-center bg-primary">
                    <a href="index.html">
                      <span>
                        <img
                          src={require("../assets/images/logo.png")}
                          alt="logo"
                          height="22"
                        />
                      </span>
                    </a>
                  </div>

                  <div className="card-body p-4">
                    <div className="text-center w-75 m-auto">
                      <h2 className="text-dark-50 text-center pb-0 fw-bold">
                        Forgot Password
                      </h2>
                      <p className="text-muted mb-4">
                        Please enter your new password.
                      </p>
                    </div>

                    <form
                      className="needs-validation"
                      onSubmit={handleSubmit(UpdatePassword, onError)}
                      noValidate
                    >
                      <div className="mb-3">
                        <label htmlFor="newPassword" className="form-label">
                          New Password
                        </label>
                        <div className="input-group input-group-merge">
                          <input
                            className="form-control"
                            type={newShowPass ? "text" : "password"}
                            placeholder="Enter new password"
                            {...register("password", {
                              required: {
                                value: true,
                                message: "Password is required",
                              },
                            })}
                          />
                          <div
                            className="input-group-text"
                            data-password="false"
                            onClick={() => setNewShowPass(!newShowPass)}
                          >
                            <span className="password-eye"></span>
                          </div>
                        </div>
                        {validateError.name == "password" && (
                          <div>
                            <small className="text-danger">
                              {validateError.message}
                            </small>
                          </div>
                        )}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="confirmPassword" className="form-label">
                          Confirm Password
                        </label>
                        <div className="input-group input-group-merge">
                          <input
                            className="form-control"
                            type={confirmShowPass ? "text" : "password"}
                            placeholder="Confirm new password"
                            {...register("confirmPassword", {
                              required: {
                                value: true,
                                message: "Please Re-type Password",
                              },
                            })}
                          />

                          <div
                            className="input-group-text"
                            data-password="false"
                            onClick={() => setConfirmShowPass(!confirmShowPass)}
                          >
                            <span className="password-eye"></span>
                          </div>
                        </div>
                        {validateError.name == "confirmPassword" && (
                          <div>
                            <small className="text-danger">
                              {validateError.message}
                            </small>
                          </div>
                        )}
                      </div>

                      <div className="mb-3 mb-0 text-center">
                        <button className="btn btn-primary" type="submit">
                          Update Password
                          {isLoading && (
                            <span
                              className="spinner-border spinner-border-sm mx-1 mb-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}

              <div className="row mt-3">
                <div className="col-12 text-center">
                  { emailSent ? (
                    <p className="text-muted"> Back to<Link to={"/login"} className="p-1">Login</Link></p>
                  ) : (
                    <p className="text-muted">Don't have an account? Contact Admin</p>
                  ) }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgetPassword;
