import Spinner from "components/Spinner";
import Toast from "components/Toast/ToastComponent";
import { setNotification } from "features/Notification/notificationSlice";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";

export default function SelectMockupCatalogue({ showModal, setFormData }) {
  const dispatch = useDispatch();

  /*   All States
   ********************************************* */

  const [loading, setLoading] = useState(false);
  const [mockupCatalogue, setMockupCatalogue] = useState(null);

  /*   All Functions
   ********************************************* */
  const loadingOn = () => {
    setLoading(true);
  };
  const loadingOff = () => {
    setLoading(false);
  };
  //get Data
  const getMockupCatalogue = async () => {
    try {
      loadingOn();
      const { data } = await API.get("/mockup-catalogues", {
        headers: authHeader(),
      });
      setMockupCatalogue(data);
      loadingOff();
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      loadingOff();
    }
  };

  const handleSelect = (item) => {
    setFormData((prev) => {
      return { ...prev, title: item?.styleNo, description: item?.description };
    });
    showModal(false);
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    getMockupCatalogue();
  }, []);
  return (
    <div>
      {loading && <Spinner />}
      <Toast />
      <h3>Select Mockup</h3>

      <table class="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Style No</th>
            <th scope="col">Description</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {mockupCatalogue &&
            mockupCatalogue.map((mockupCatalogue, i) => (
              <tr key={mockupCatalogue?.id}>
                <th scope="row">{i + 1}</th>
                <td>{mockupCatalogue?.styleNo}</td>
                <td>{mockupCatalogue?.description}</td>
                <td>
                  <button
                    onClick={(e) => {
                      handleSelect(mockupCatalogue);
                    }}
                    className="btn btn-sm btn-success"
                  >
                    Select
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div></div>
    </div>
  );
}
