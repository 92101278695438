import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ReactQuill from "react-quill";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";
import CustomerSelectModal from "./SelectCustomer/CustomerSelectModal";
import BidContactSelectModal from "./SelectCustomer/BidContactSelectModal";

function ManageBid({
  selectedBid,
  updateBid,
  formData,
  setFormData,
  isLoading,
  setIsLoading,
  setSelectedBid,
}) {
  /*   All States
   ********************************************* */
  const dispatch = useDispatch();

  const [selectedContact, setSelectedContact] = useState(null);
  const [showContactModal, setShowContactModal] = useState(null);

  const [contactsList, setContactsList] = useState(null);
  const [customerSelectionModal, setCustomerSelectionModal] = useState(null);

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["clean"],
    ],
  };

  /*   All Functions
   ********************************************* */

  const getCustomerContacts = async () => {
    setIsLoading(true);
    try {
      const { data } = await API.post(
        "/bids/customer-contacts",
        {
          id: selectedBid?.customerId,
        },
        { headers: authHeader() }
      );
      if (data.type === "success") {
        setContactsList(parseJsonData(data.data));

        setIsLoading(false);
        return;
      } else {
        console.log(data);
        setIsLoading(false);
      }
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      setIsLoading(false);
    }
  };
  const parseJsonData = (data) => {
    let newArray = [];
    data.map((item) => {
      newArray.push({ ...item, ...JSON.parse(item.data) });
    });
    return newArray;
  };

  const handleFormData = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name == "dueDate") {
      setFormData({
        ...formData,
        amendmentFollowUpDate: getThreeDaysBeforeDate(value),
        [name]: value,
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleCustomerSelection = (selected) => {
    setContactsList(null);
    setSelectedBid((prev) => {
      return {
        ...prev,
        customerId: selected?.id,
        customerName: selected?.customerName,
      };
    });
    setFormData((prev) => {
      return {
        ...prev,
        customerId: selected?.id,
        customerName: selected?.customerName,
      };
    });
    setCustomerSelectionModal(false);
  };

  const getDate = (inputDate) => {
    if (inputDate) {
      const date = new Date(inputDate);
      return date.toISOString().slice(0, 10).replace("T", " ");
    }
    return;
  };

  function getThreeDaysBeforeDate(initialDate) {
    if (initialDate) {
      const threeDaysAgo = new Date(initialDate);
      threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
      return threeDaysAgo;
    }
  }

  const handleContactSelect = (item) => {
    setSelectedContact(item);
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    if (selectedBid?.customerId) {
      setFormData({
        ...selectedBid,
        amendmentFollowUpDate: getThreeDaysBeforeDate(selectedBid?.dueDate),
      });
      getCustomerContacts();
    }
  }, [selectedBid]);

  useEffect(() => {
    if (selectedContact) {
      setFormData({
        ...formData,
        customerContactName: `${selectedContact?.firstName} ${selectedContact?.lastName}`,
        customerContactPhone: selectedContact?.phone,
        customerContactEmail: selectedContact?.email,
      });
    }
  }, [selectedContact]);

  return (
    <>
      {isLoading && <Spinner />}
      <form onSubmit={updateBid} className="">
        <div className="mb-2 bg-white custom-shadow px-2 py-3 custom-border-radius ">
          <div className="">
            <label className=" form-label fw-bold mb-1 text-uppercase">
              Job Name
            </label>
            <input
              required
              className="form-control"
              type="text"
              placeholder="Enter Job Name"
              value={formData?.jobName}
              name="jobName"
              onChange={handleFormData}
            />
          </div>
        </div>

        <div className="d-flex justify-content-between gap-2 mb-2 bg-white custom-shadow px-2 py-3 custom-border-radius ">
          <div className="w-25">
            <label className="form-label fw-bold mb-1 text-uppercase">
              Bid Date
            </label>
            <input
              required
              className="form-control"
              type="date"
              placeholder="Enter Bid Date"
              value={getDate(formData?.bidDate)}
              name="bidDate"
              onChange={handleFormData}
            />
          </div>
          <div className="flex-grow-1">
            <label className="form-label fw-bold text-uppercase">
              Customer Bid ID
            </label>
            <input
              required
              className="form-control "
              type="text"
              placeholder="Enter Custom Bid ID"
              value={formData?.customerBidId}
              name="customerBidId"
              onChange={handleFormData}
            />
          </div>
          <div className="flex-grow-1">
            <label className="form-label fw-bold mb-1 text-uppercase">
              Delivery Method
            </label>
            <input
              required
              className="form-control"
              type="text"
              placeholder="Enter Delivery Method"
              value={formData?.deliveryMethod}
              name="deliveryMethod"
              onChange={handleFormData}
            />
          </div>
          <div className="w-25">
            <label className="form-label fw-bold mb-1 text-uppercase">
              Closing Date
            </label>
            <input
              required
              className="form-control"
              type="date"
              placeholder="Enter Due Date"
              value={getDate(formData?.dueDate)}
              name="dueDate"
              onChange={handleFormData}
            />
          </div>
        </div>

        <div className="mb-2 border p-2 bg-white custom-shadow px-2 py-3 custom-border-radius ">
          <div className="d-flex justify-content-between gap-2">
            <div className="d-flex gap-2">
              <div className="text-end fs-4 ">
                Customer ID :{" "}
                <span className="h4">{selectedBid?.customerId} </span>
              </div>
              <div className="text-end fs-4 ">|</div>
              <div className="text-right fs-4 ">
                Customer Name :{" "}
                <span className="h4">{selectedBid?.customerName}</span>
              </div>
            </div>
            <div className="mb-1">
              <button
                type="button"
                onClick={() => {
                  setCustomerSelectionModal(true);
                }}
                className="btn btn-sm btn-success"
              >
                Choose
              </button>
            </div>
          </div>

          <hr className="mt-0  mb-3" />
          <div className="d-flex justify-content-between align-items-center gap-2">
            <div className="flex-grow-1">
              <label className="form-label fw-bold  text-uppercase">
                Contact Name
              </label>
              <input
                required
                className="form-control "
                type="text"
                placeholder="Enter Contact Name"
                value={formData?.customerContactName}
                name="customerContactName"
                onChange={handleFormData}
              />
            </div>
            <div className="flex-grow-1">
              <label className="form-label fw-bold  text-uppercase">
                contact Phone
              </label>
              <input
                required
                className="form-control  "
                type="text"
                placeholder="Enter Phone"
                value={formData?.customerContactPhone}
                name="customerContactPhone"
                onChange={handleFormData}
              />
            </div>
            <div style={{ flexGrow: 2 }}>
              <label className="form-label fw-bold  text-uppercase">
                contact Email
              </label>
              <input
                required
                className="form-control  "
                type="text"
                placeholder="Enter Email"
                value={formData?.customerContactEmail}
                name="customerContactEmail"
                onChange={handleFormData}
              />
            </div>
            <div className="mt-1">
              <label className="form-label fw-bold  text-uppercase "></label>
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  disabled={!contactsList}
                  onClick={() => {
                    setShowContactModal(true);
                  }}
                  className="btn btn-success"
                >
                  Choose
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-2 d-flex justify-content-between gap-2 bg-white custom-shadow px-2 py-3 custom-border-radius ">
          <div className="flex-grow-1">
            <label className="form-label fw-bold mb-0 text-uppercase">
              Estimated Value
            </label>
            <div className="d-flex gap-2">
              <div className="ms-1 mt-2">$</div>
              <input
                required
                className="form-control mb-2"
                type="number"
                step={0.01}
                placeholder="Enter Estimated Value"
                value={parseFloat(formData?.estimatedValue)}
                name="estimatedValue"
                onChange={handleFormData}
              />
            </div>
          </div>
          <div className="flex-grow-1">
            <label className="form-label fw-bold mb-0 text-uppercase">
              Opening Date
            </label>
            <input
              required
              className="form-control  mb-2"
              type="date"
              placeholder="Enter Open Date"
              name="openingDate"
              value={getDate(formData?.openingDate)}
              onChange={handleFormData}
            />
          </div>
          <div className="flex-grow-1">
            <label className="form-label  fw-bold mb-0 text-uppercase">
              Result
            </label>
            <select
              value={formData?.result}
              onChange={handleFormData}
              name="result"
              className="form-select"
            >
              <option value={"none"}>None</option>
              <option value={"won"}>Won</option>
              <option value={"partially_Won"}>Partially Won</option>
              <option value={"lost"}>Lost</option>
              <option value={"abandoned_By_Customer"}>
                Abandoned By Customer
              </option>
              <option value={"abandonedByStaff"}>Abandoned By Staff</option>
            </select>
          </div>
        </div>
        <div className="mb-3 bg-white custom-shadow px-2 py-3 custom-border-radius ">
          <div className="d-flex gap-2">
            <div className="mb-2 flex-grow-1">
              <label className="form-label fw-bold mb-1 text-uppercase">
                AMENDMENT FOLLOW-UP DATE
              </label>
              <input
                required
                className="form-control"
                type="date"
                value={getDate(formData?.amendmentFollowUpDate)}
                name="amendmentFollowUpDate"
                onChange={handleFormData}
              />
            </div>
          </div>
          <div>
            <label className="form-label fw-bold mb-1 text-uppercase">
              Amendment Notification Location
            </label>
            <input
              required
              className="form-control"
              type="text"
              placeholder="Enter Amendment Notification Location"
              value={formData?.amendmentNotificationLocation}
              name="amendmentNotificationLocation"
              onChange={handleFormData}
            />
          </div>
        </div>
        <div className="mb-3 bg-white custom-shadow px-2 py-3 custom-border-radius ">
          <div>
            <ReactQuill
              modules={modules}
              theme="snow"
              value={formData?.notes}
              onChange={(val) => setFormData({ ...formData, notes: val })}
            />
          </div>
        </div>
      </form>

      {/*   Customer Select Modal
       ********************************************* */}
      <Modal
        contentClassName="bg-transparent"
        show={customerSelectionModal}
        onHide={() => setCustomerSelectionModal(false)}
      >
        <Modal.Body className="bg-white px-4  custom-border-radius">
          <CustomerSelectModal
            modalStatus={customerSelectionModal}
            showModal={setCustomerSelectionModal}
            handleCustomerSelection={handleCustomerSelection}
          />
        </Modal.Body>
      </Modal>

      {/*   Customer Contact Modal
       ********************************************* */}
      <Modal
        size="xl"
        contentClassName="bg-transparent"
        show={showContactModal}
        onHide={() => setShowContactModal(false)}
      >
        <Modal.Body>
          <BidContactSelectModal
            handleContactSelect={handleContactSelect}
            contactsList={contactsList}
            showModal={setShowContactModal}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ManageBid;
