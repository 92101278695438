import Spinner from "components/Spinner";
import { setNotification } from "features/Notification/notificationSlice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import authHeader from "services/auth-header";
import API from "services/axios";
import BidFileManager from "./BidFileManager";
import ManageBid from "./ManageBid";

function BidDetails({
  currentTab,
  searchResultIds,
  setSearchResultIds,
  selectedId,
  setShowDetailsPage,
  convertSqlTimestampToDate,
}) {
  /*   ALL STATES
   ********************************************* */
  const dispatch = useDispatch();

  const [active, setActive] = useState("manageBids");
  const [selectedBid, setSelectedBid] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);

  const [selectedBidId, setSelectedBidId] = useState(null);

  const [allBidData, setAllBidData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({});

  /*   ALL FUNCTIONS
   ********************************************* */

  const getBidsData = async ({ id, changeTab = true }) => {
    try {
      let { data } = await API.get("/bids", { headers: authHeader() });

      let filteredData = [];
      if (searchResultIds) {
        filteredData = data?.filter((item) =>
          searchResultIds?.includes(item.id)
        );
      } else {
        filteredData = data;
      }
      if (changeTab) {
        if (filteredData && currentTab === "all") {
          setAllBidData(filteredData);
        } else if (filteredData && currentTab === "pending") {
          let pending = filteredData.filter(
            (item) => item.bidStatus === "pending"
          );
          setAllBidData(pending);
        } else if (filteredData && currentTab === "amendment") {
          let amendment = filteredData.filter(
            (item) => item.bidStatus === "amendment"
          );
          setAllBidData(amendment);
        } else if (filteredData && currentTab === "submitted") {
          let submitted = filteredData.filter(
            (item) => item.bidStatus === "submitted"
          );
          setAllBidData(submitted);
        }
      }
      if (id) {
        setSelectedBidId(id);
      }
      return filteredData;
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
    }
  };

  const updateBid = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const { data } = await API.patch(
        "/bids",

        {
          data: {
            id: selectedBid?.id,
            ...formData,
          },
        },
        { headers: authHeader() }
      );
      if (data.type === "success") {
        getBidsData({ id: selectedBid.id });
        // data.data.map((item, index) => {
        //   if (item.id === selectedBid.id) {
        //     setCurrentIndex(index);
        //     setSelectedBid(item);
        //   }
        // });
        setIsLoading(false);
        return;
      } else {
        console.log(data);
        setIsLoading(false);
      }
    } catch (err) {
      dispatch(
        setNotification({
          message: err.message,
          type: "error",
        })
      );
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getBidsData({ id: selectedId });
  }, [selectedId]);

  useEffect(() => {
    if (allBidData) {
      allBidData?.some((item, index) => {
        if (item.id === selectedBidId) {
          setCurrentIndex(index);
          return item.id === selectedBidId;
        }
      });
    }
  }, [allBidData, selectedBidId]);

  useEffect(() => {
    if (allBidData) {
      setSelectedBid(allBidData[currentIndex]);
    }
  }, [allBidData, currentIndex]);

  // Changes Bg of selected Tab
  const checkActive = (val) => {
    setActive(val);
  };

  const nextBid = () => {
    if (currentIndex === allBidData.length - 1) {
      return;
    }
    setCurrentIndex((prev) => prev + 1);
    setSelectedBid(allBidData[currentIndex + 1]);
  };

  const prevBid = () => {
    if (currentIndex == 0) {
      return;
    }
    setCurrentIndex((prev) => {
      setSelectedBid(allBidData[prev - 1]);
      return prev - 1;
    });
  };

  if (!allBidData) return <Spinner />;
  return (
    <div
      className="d-flex flex-grow-1 "
      style={{
        borderRadius: "10px",
        position: "fixed",
        top: 0,
        width: "calc(100vw - 180px)",
        paddingLeft: 80,
        marginLeft: 180,
        zIndex: 101,
      }}
    >
      <div className="d-flex flex-grow-1 flex-column">
        <div className="bg-white shadow-lg py-1 px-3 d-flex justify-content-between align-items-center ">
          <div className="d-flex justify-content-between align-items-center flex-grow-1 ">
            <div className="">
              Bid ID : &nbsp;&nbsp;&nbsp;
              <span className="font-weight-bold h5">{selectedBid?.bidId}</span>
              &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span className="mt-0">
                Created Date:&nbsp;&nbsp;&nbsp;&nbsp;
                <span className="font-weight-bold h5">
                  {convertSqlTimestampToDate(selectedBid?.createdAt)}
                </span>
              </span>
            </div>

            <div>
              Job Name : &nbsp;&nbsp;&nbsp;&nbsp;
              <span className="font-weight-bold h5">
                {selectedBid?.jobName}
              </span>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <div>Status :&nbsp;&nbsp;&nbsp;&nbsp;</div>
              <div className="">
                <select
                  value={formData?.bidStatus}
                  onChange={(e) => {
                    setFormData({ ...formData, bidStatus: e.target.value });
                  }}
                  name="bidStatus"
                  className="form-select py-1"
                >
                  <option value={"pending"}>Pending</option>
                  <option value={"submitted"}>Submitted</option>
                  <option value={"amendment"}>Amendment</option>
                </select>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end ">
            <button onClick={updateBid} className="btn btn-success mx-1">
              Save
            </button>
            <button
              onClick={() => {
                setShowDetailsPage(false);
                setSearchResultIds(null);
              }}
              className={"btn btn-danger "}
            >
              BACK
            </button>
            <button
              onClick={() => {
                prevBid();
              }}
              className={"btn btn-success btn-sm p-0 px-1 mx-1"}
            >
              <i className="mdi mdi-arrow-left-bold fs-3 py-0 my-0"></i>
            </button>
            <button
              onClick={() => {
                nextBid();
              }}
              className={"btn btn-success btn-sm p-0 px-1 "}
            >
              <i className="mdi mdi-arrow-right-bold fs-3 py-0 my-0"></i>
            </button>
          </div>
        </div>

        <div className="d-flex flex-grow-1 pt-2 ">
          <div className="flex-grow-1  ">
            <div className="bg-white custom-shadow px-3 mx-2 custom-border-radius">
              <ul className="nav nav-tabs nav-bordered border-0 d-flex flex-grow-1 py-2">
                <li
                  onClick={() => {
                    checkActive("manageBids");
                  }}
                  className="nav-item"
                >
                  <button
                    className={
                      active == "manageBids"
                        ? `nav-link rounded-0 acitve bg-primary text-white`
                        : "nav-link rounded-0"
                    }
                  >
                    <span className="d-none d-md-block">Manage Bids</span>
                  </button>
                </li>
                <li
                  onClick={() => {
                    checkActive("files");
                  }}
                  className="nav-item"
                >
                  <button
                    className={
                      active === "files"
                        ? `nav-link rounded-0 acitve bg-primary text-white`
                        : "nav-link rounded-0"
                    }
                  >
                    <span className="d-none d-md-block">File Manager</span>
                  </button>
                </li>
              </ul>
            </div>

            <div
              className="px-2 mt-2 overflow-auto scrollbar-hide "
              style={{ height: "85vh" }}
            >
              {active === "manageBids" && (
                <div className="tab-pane show active">
                  <ManageBid
                    selectedBid={selectedBid}
                    updateBid={updateBid}
                    formData={formData}
                    setFormData={setFormData}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    setSelectedBid={setSelectedBid}
                  />
                </div>
              )}
              {active === "files" && (
                <div className="tab-pane show active">
                  <BidFileManager selectedBid={selectedBid} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BidDetails;
