import axios from "axios";
import ImgWithSignedUrl from "components/SignedUrl/ImgWithSignedUrl";
import { useEffect, useRef, useState } from "react";
import Avatar from "react-avatar";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import API from "services/axios";
import { v4 as uuid } from "uuid";
import AudioMsgModal from "./CommentTypes/Audio/AudioMsgModal";
import AudioWIthSignedUrl from "./CommentTypes/Audio/AudioWIthSignedUrl";
import VideoWIthSignedUrl from "./CommentTypes/Video/VideoWIthSignedUrl";
import Spinner from "components/Spinner";

export default function CommentSection({
  selectedProduct,
  selectedDesign,
  updateSelectedProduct,
  selectedImg,
  updateSelectedState,
  selectedComment,
  setSelectedComment,
  setSelectedProduct,
  setDesignRequests,
  designRequests,
}) {
  const user = useSelector((state) => state.user?.userInfo);
  /*   All States
   ********************************************* */
  const [formData, setFormData] = useState({ comment: "", file: null });

  const [isLoading, setIsLoading] = useState(false);
  const [selectedComments, setSelectedComments] = useState(null);
  const [selectedCommentId, setSelectedCommentId] = useState(9999999);

  const [showAddCommentModal, setShowAddCommentModal] = useState(false);
  const [showAudioModal, setShowAudioModal] = useState(false);
  const [newMsg, setNewMsg] = useState(false);

  const [previewImg, setPreviewImg] = useState(null);

  const messagesEndRef = useRef(null);

  /*   All Functions
   ********************************************* */
  const getNewComment = (type, imagePath, comment) => {
    let rawDate = new Date();
    var ampm = rawDate.getHours() >= 12 ? "pm" : "am";

    let newDate = `${
      rawDate.getHours() > 12 ? rawDate.getHours() - 12 : rawDate.getHours()
    }:${rawDate.getMinutes()}${ampm} - ${rawDate.getDate()}-${rawDate.toLocaleDateString(
      "default",
      { month: "short" }
    )}-${rawDate.getFullYear()}`;

    let newComment = {
      id: uuid(),
      user:
        user.roles === "admin"
          ? `${user.firstName} ${user.lastName} V-${selectedImg?.version}`
          : `Designer (${user.id}) V-${selectedImg?.version}`,
      comment,
      type,
      imagePath: imagePath || null,
      imageVersion: selectedImg?.version,
      markerState: null,
      date: newDate,
    };
    return newComment;
  };

  const addNewCommentWithoutMarker = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);

      let path = `kms/design-requests/${user?.companyId}/${selectedDesign?.id}/${selectedProduct?.id}/comments`;

      let imagePath = `${path}${
        formData?.file ? `/${formData?.file?.name}` : ""
      }`;

      let type = formData.file ? "picture" : "text";
      let newComment = getNewComment(type, imagePath, formData?.comment);
      let newCommentData = [...selectedComments, newComment];

      selectedProduct["comments"] = newCommentData;
      selectedProduct["newAppMsg"] = true;

      /*   Form Data
       ********************************************* */
      const updatedData = new FormData();

      if (formData?.file) {
        updatedData.append("file", formData?.file);
        let folder = path;
        updatedData.append("folder", folder);
      }

      updatedData.append("id", selectedDesign?.id);

      /*   Update Product data
       ********************************************* */
      let updatedProduct = selectedProduct;

      let updatedSelectedDesign = null;
      let updatedDesignRequest = designRequests.map((item) => {
        if (item.id === selectedDesign.id) {
          let updatedProducts = item.products.map((prod) => {
            if (prod.id === updatedProduct.id) {
              return updatedProduct;
            } else {
              return prod;
            }
          });

          let newItem = { ...item, products: updatedProducts };
          updatedSelectedDesign = newItem;
          return newItem;
        } else {
          return item;
        }
      });

      updatedData.append(
        "products",
        JSON.stringify(updatedSelectedDesign?.products)
      );

      const response = await API.post("/design-request/message", updatedData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setFormData({ comment: "", file: null });
      setSelectedComment(null);
      setSelectedProduct((prev) => updatedProduct);
      setDesignRequests(updatedDesignRequest);
      setSelectedComments((prev) => newCommentData);
      setNewMsg(false);
      setIsLoading(false);
      setShowAddCommentModal(false);
    } catch (err) {
      console.log(err.message);
      setIsLoading(false);
    }
  };

  const updateComment = async () => {
    setIsLoading(true);

    let newCommentData = selectedComments.map((item) => {
      if (item.id === selectedComment.id) {
        return {
          ...item,
          comment: formData?.comment,
        };
      } else {
        return item;
      }
    });

    setSelectedComments((prev) => newCommentData);
    selectedProduct["comments"] = newCommentData;

    await updateSelectedProduct(selectedProduct);

    setFormData({ comment: "" });
    setNewMsg(false);
    setIsLoading(false);
    setShowAddCommentModal(false);
  };

  const deleteComment = async (id, comment) => {
    setIsLoading(true);
    let newCommentData = selectedComments.filter((item) => item.id !== id);
    setSelectedComments((prev) => newCommentData);
    selectedProduct["comments"] = newCommentData;
    await updateSelectedProduct(selectedProduct, comment);
    setIsLoading(false);
    setShowAddCommentModal(false);
  };

  const handleInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const resetStates = () => {
    setFormData({ comment: "", file: "" });
    setSelectedComment(null);
    setNewMsg(false);
    setPreviewImg(null);
  };

  const getDescArr = (arr) => {
    if (arr) {
      // const unsortedArr = JSON.parse(JSON.stringify(arr));
      // let sortedArr = unsortedArr.reverse();

      return arr;
    }
  };

  const downloadFile = (fileKey) => {
    setIsLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URI}/aws/images`, {
        key: fileKey,
      })
      .then((response) => {
        const url = response.data[0];
        setIsLoading(false);
        const link = document.createElement("a");
        link.href = url;
        link.download = fileKey;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })

      .catch((error) => {
        console.log("Error downloading file:", error);
      });
  };

  const handlePaste = (e) => {
    if (!e.clipboardData.files.length) {
      return;
    }
    setShowAddCommentModal(true);
    setNewMsg(true);

    const files = e.clipboardData.files;

    if (files[0]?.type === "image/jpeg" || files[0]?.type === "image/png") {
      const imageFile = files[0];
      const imgUrl = URL.createObjectURL(imageFile);
      setPreviewImg(imgUrl);
      setFormData((prev) => {
        return { ...prev, file: imageFile };
      });
    } else {
      return alert("Only Png and Jpeg Files are allowed.");
    }
  };

  const getPreviewImg = (img) => {
    const imgUrl = URL.createObjectURL(img);
    setPreviewImg(imgUrl);
  };

  /*   All UseEffects
   ********************************************* */
  useEffect(() => {
    if (!selectedComment) {
      setSelectedCommentId(null);
    }
  }, [selectedComment]);

  useEffect(() => {
    setSelectedCommentId(9999999);
    setSelectedComments((prev) => selectedProduct?.comments);
  }, [selectedProduct]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [selectedComments]);
  return (
    <div className="w-25 px-3 pb-3 position-relative d-flex flex-column bg-white bg-white shadow-lg custom-border-radius ">
      <h3 className="my-2">Comments</h3>
      {isLoading && <Spinner />}
      <div className="bg-light flex-grow-1 mb-3 px-2 py-2 custom-border-radius overflow-scroll scrollbar-hide">
        {getDescArr(selectedComments, "date")?.map((item) => (
          <div key={item?.id}>
            {item.type === "text" && (
              <div
                className={`${
                  selectedCommentId === item.id
                    ? "bg-white custom-shadow-lg"
                    : "bg-white shadow-sm"
                }  mb-2 px-2 py-2 custom-border-radius-sm`}
                onClick={() => {
                  setSelectedComment(item);
                  updateSelectedState(item.markerState, item?.imageVersion);
                  setSelectedComment(item);
                  setSelectedCommentId(item.id);
                }}
              >
                <div className="mb-2 d-flex align-items-center gap-2">
                  <Avatar
                    size={35}
                    round={true}
                    textSizeRatio={1.75}
                    name={item.user}
                  />

                  <div className="flex-grow-1">
                    <span
                      className="h4 my-0 d-flex justify-content-between"
                      style={{ textTransform: "capitalize" }}
                    >
                      {item?.user}
                      {user.roles === "admin" && (
                        <div className="d-flex justify-content-between gap-1">
                          <i
                            role="button"
                            onClick={() => {
                              setFormData((prev) => {
                                let updatedFormData = {
                                  ...prev,
                                  comment: item.comment,
                                };
                                return updatedFormData;
                              });
                              setShowAddCommentModal(true);
                              setSelectedCommentId(item.id);
                            }}
                            className="mdi mdi-circle-edit-outline"
                          ></i>
                          <i
                            onClick={() => {
                              deleteComment(item.id);
                            }}
                            role="button"
                            className="mdi mdi-delete"
                          ></i>
                        </div>
                      )}
                    </span>
                    <p className="h6 pb-0 m-0" style={{ paddingTop: 4 }}>
                      {item.date}
                    </p>
                  </div>
                </div>
                <p
                  role="button"
                  className="my-0 text-dark bg-light custom-border-radius-sm px-2 py-1"
                >
                  {item.comment}
                </p>
              </div>
            )}
            {item.type === "picture" && (
              <div
                className={`${
                  selectedCommentId === item.id
                    ? "bg-white custom-shadow-lg"
                    : "bg-white shadow-sm"
                }  mb-2 px-2 py-2 custom-border-radius-sm`}
                onClick={() => {
                  setSelectedComment(item);
                  updateSelectedState(item.markerState, item?.imageVersion);
                  setSelectedComment(item);
                  setSelectedCommentId(item.id);
                }}
              >
                <div className="mb-2 d-flex align-items-center gap-2">
                  <Avatar
                    size={35}
                    round={true}
                    textSizeRatio={1.75}
                    name={item.user}
                  />

                  <div className="flex-grow-1">
                    <span
                      className="h4 my-0 d-flex justify-content-between"
                      style={{ textTransform: "capitalize" }}
                    >
                      {item?.user}

                      {user.roles === "admin" && (
                        <div className="d-flex justify-content-between gap-1">
                          <i
                            role="button"
                            onClick={() => {
                              setFormData((prev) => {
                                let updatedFormData = {
                                  ...prev,
                                  comment: item.comment,
                                };
                                return updatedFormData;
                              });
                              setShowAddCommentModal(true);
                              setSelectedCommentId(item.id);
                            }}
                            className="mdi mdi-circle-edit-outline"
                          ></i>
                          <i
                            onClick={() => {
                              deleteComment(item.id, item.imagePath);
                            }}
                            role="button"
                            className="mdi mdi-delete"
                          ></i>
                        </div>
                      )}
                    </span>
                    <p className="h6 pb-0 m-0" style={{ paddingTop: 4 }}>
                      {item.date}
                    </p>
                  </div>
                </div>
                <p
                  role="button"
                  className="my-0 text-dark bg-light custom-border-radius-sm px-2 py-1"
                >
                  {item.comment}
                </p>
                <div className="pt-2 flex-center position-relative">
                  {item?.imagePath && (
                    <ImgWithSignedUrl
                      path={item?.imagePath}
                      className={"img-fluid "}
                    />
                  )}
                  <div
                    onClick={() => {
                      downloadFile(item?.imagePath);
                    }}
                    className="position-absolute"
                    style={{ right: 10, top: 20 }}
                  >
                    <button className="btn btn-success">
                      <i className="mdi mdi-download"></i>
                    </button>
                  </div>
                </div>
              </div>
            )}
            {item.type === "audio" && (
              <div
                className={`${
                  selectedCommentId === item.id
                    ? "bg-white custom-shadow-lg"
                    : "bg-white shadow-sm"
                }  mb-2 px-2 py-2 custom-border-radius-sm`}
                onClick={() => {
                  setSelectedComment(item);
                  setSelectedCommentId(item.id);
                  updateSelectedState(item.markerState, item?.imageVersion);
                }}
              >
                <div className="mb-2 d-flex align-items-center gap-2">
                  <Avatar
                    size={35}
                    round={true}
                    textSizeRatio={1.75}
                    name={item.user}
                  />

                  <div className="flex-grow-1">
                    <span
                      className="h4 my-0 d-flex justify-content-between"
                      style={{ textTransform: "capitalize" }}
                    >
                      {item?.user}
                      <div className="d-flex justify-content-between gap-1">
                        <i
                          onClick={() => {
                            item?.type === "text"
                              ? deleteComment(item.id)
                              : deleteComment(item.id, item.comment);
                          }}
                          role="button"
                          className="mdi mdi-delete"
                        ></i>
                      </div>
                    </span>
                    <p className="h6 pb-0 m-0" style={{ paddingTop: 4 }}>
                      {item.date}
                    </p>
                  </div>
                </div>
                <AudioWIthSignedUrl path={item?.comment} />
              </div>
            )}
            {item.type === "video" && (
              <div
                className={`${
                  selectedCommentId === item.id
                    ? "bg-white custom-shadow-lg"
                    : "bg-white shadow-sm"
                }  mb-2 px-2 py-2 custom-border-radius-sm`}
                onClick={() => {
                  setSelectedComment(item);
                  updateSelectedState(item.markerState, item?.imageVersion);
                  setSelectedCommentId(item.id);
                }}
              >
                <div className="mb-2 d-flex align-items-center gap-2">
                  <Avatar
                    size={35}
                    round={true}
                    textSizeRatio={1.75}
                    name={item.user}
                  />

                  <div className="flex-grow-1">
                    <span
                      className="h4 my-0 d-flex justify-content-between"
                      style={{ textTransform: "capitalize" }}
                    >
                      {item?.user}
                      <div className="d-flex justify-content-between gap-1">
                        <i
                          onClick={() => {
                            item?.type === "text"
                              ? deleteComment(item.id)
                              : deleteComment(item.id, item.comment);
                          }}
                          role="button"
                          className="mdi mdi-delete"
                        ></i>
                      </div>
                    </span>
                    <p className="h6 pb-0 m-0" style={{ paddingTop: 4 }}>
                      {item.date}
                    </p>
                  </div>
                </div>
                <VideoWIthSignedUrl path={item?.comment} />
              </div>
            )}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      <div className="d-flex align-items-center gap-1">
        <form
          onSubmit={addNewCommentWithoutMarker}
          className="flex-grow-1 d-flex align-items-center gap-2"
        >
          <input
            placeholder="Type Message..."
            name="comment"
            required
            disabled={!selectedProduct || selectedProduct?.length || isLoading}
            onPaste={handlePaste}
            value={formData?.comment}
            onChange={handleInput}
            className={`form-control bg-light`}
          />
          <button
            disabled={!selectedProduct || selectedProduct?.length}
            type="submit"
            className="btn btn-sm btn-primary "
          >
            <i className="mdi mdi-send"></i>
          </button>
        </form>
        <div>
          <button
            disabled={!selectedProduct || selectedProduct?.length}
            onClick={() => {
              setShowAddCommentModal(true);
              setSelectedComment(null);
            }}
            className="btn btn-sm btn-secondary"
          >
            <i className="mdi mdi-attachment"></i>
          </button>
        </div>
        <div>
          <AudioMsgModal
            isDisabled={!selectedProduct || selectedProduct?.length}
            selectedImg={selectedImg}
            selectedDesign={selectedDesign}
            selectedProduct={selectedProduct}
            designRequests={designRequests}
            setSelectedProduct={setSelectedProduct}
            setDesignRequests={setDesignRequests}
            showAudioModal={showAudioModal}
            setShowAudioModal={setShowAudioModal}
          />
          {/* <button
            onClick={() => {
              setShowAudioModal(true);
            }}
            className="btn btn-info"
          >
            <i className="mdi mdi-microphone"></i>
          </button> */}
        </div>
      </div>
      {/*   Comment Modal
       ********************************************* */}
      <Modal
        contentClassName="bg-transparent"
        show={showAddCommentModal}
        onHide={() => {
          setShowAddCommentModal(false);
          resetStates();
        }}
      >
        <Modal.Body className="bg-white custom-border-radius px-4">
          <h3> {newMsg ? "Add new message" : "Update your message"} </h3>
          {isLoading && <Spinner />}

          <div>
            {/*   Text Message
             ********************************************* */}
            <div className="my-3">
              <label className="form-label">New comment</label>
              <textarea
                name="comment"
                onPaste={handlePaste}
                required
                value={formData?.comment}
                onChange={handleInput}
                className={`form-control bg-light ${
                  formData.comment === "" && "border-2 border-danger"
                }`}
                placeholder="Enter comment"
              ></textarea>
              {formData.comment === "" && (
                <div className="text-danger">Required</div>
              )}
            </div>
            <div className="my-3">
              <label className="form-label">
                Add Picture <small>(optional)</small>
              </label>
              <input
                name="comment"
                type="file"
                onChange={(e) => {
                  setFormData((prev) => {
                    return { ...prev, file: e.target.files[0] };
                  });
                  getPreviewImg(e.target.files[0]);
                }}
                className={`form-control bg-light`}
              />
            </div>

            {previewImg && (
              <>
                <label className="form-label">Preview</label>

                <div className="bg-white p-2 custom-shadow-sm custom-border-radius flex-center">
                  <img
                    src={previewImg}
                    style={{ maxWidth: 400, maxHeight: 300 }}
                    alt="preview"
                  />
                </div>
              </>
            )}

            <div className="text-end mt-3">
              <button
                type="button"
                onClick={() => {
                  setShowAddCommentModal(false);
                  resetStates();
                }}
                className="btn btn-sm btn-secondary mx-1"
              >
                Close
              </button>

              <button
                type="button"
                disabled={isLoading || formData?.comment === ""}
                onClick={(e) => {
                  selectedComment
                    ? updateComment()
                    : addNewCommentWithoutMarker(e);
                }}
                className="btn btn-sm btn-primary"
              >
                {selectedComment ? "Update" : "Add"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
