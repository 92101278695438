import {
  generatePassword,
  changePasswordLength,
  changeLowerCase,
  changeUpperCase,
  changeNumber,
  changeSpecialChars,
} from '../../features/passwordGeneraotr/passwordSlice';
import { setNotification } from '../../features/Notification/notificationSlice';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function PasswordGenerator() {
  /*   All States Here
   ********************************************* */
  // redux
  const dispatch = useDispatch();
  const {
    password,
    passwordLength,
    includeLowerCase,
    includeUpperCase,
    includeNumer,
    includeSpecialChars,
  } = useSelector((state) => state.password);

  const [alertData, setAlertData] = useState(null);

  // Show copy alert
  const showAlert = (val) => {
    navigator.clipboard.writeText(val);
    setAlertData([`${val}`, 'success']);
    dispatch(
      setNotification({
        message: val,
        type: 'success',
      })
    );
    setTimeout(() => {
      setAlertData(null);
    }, 2000);
  };
  return (
    <div className='row' style={{ marginLeft: 260 }}>
      <div className='col-12'>
        <h3>Generate Random Password</h3>

        <div className='bg-white shadow container-fluid '>
          <div className='container d-flex flex-column  justify-content-center align-content-center py-4 px-3 my-3 w-50'>
            <ul className='list-group '>
              <li className='list-group-item d-flex align-items-center'>
                <div className='form-floating  flex-grow-1'>
                  <input
                    type='number'
                    className='form-control '
                    onChange={(e) => dispatch(changePasswordLength(e.target.value))}
                    value={passwordLength}
                  />
                  <label>Enter password Length</label>
                </div>
              </li>
              <li className='list-group-item d-flex align-items-center'>
                <div className='form-check form-switch'>
                  <input
                    onChange={(e) => dispatch(changeLowerCase(e.target.checked))}
                    checked={includeLowerCase}
                    type='checkbox'
                    className='form-check-input'
                  />
                </div>
                Lower Case <small> &nbsp;( a,b,c...... )</small>
              </li>
              <li className='list-group-item d-flex align-items-center'>
                <div className='form-check form-switch'>
                  <input
                    onChange={(e) => dispatch(changeUpperCase(e.target.checked))}
                    checked={includeUpperCase}
                    type='checkbox'
                    className='form-check-input'
                  />
                </div>
                Upper Case <small>&nbsp; ( A,B,C,D...... )</small>
              </li>
              <li className='list-group-item d-flex align-items-center'>
                <div className='form-check form-switch'>
                  <input
                    onChange={(e) => dispatch(changeNumber(e.target.checked))}
                    checked={includeNumer}
                    type='checkbox'
                    className='form-check-input'
                  />
                </div>
                Numbers
                <small>&nbsp; ( 1,2,3,4...... )</small>
              </li>
              <li className='list-group-item d-flex align-items-center'>
                <div className='form-check form-switch'>
                  <input
                    onChange={(e) => dispatch(changeSpecialChars(e.target.checked))}
                    checked={includeSpecialChars}
                    type='checkbox'
                    className='form-check-input'
                  />
                </div>
                Special Characters <small>&nbsp; ( @,!,?,(,)...... )</small>
              </li>
            </ul>
            <div className='text-center my-4 d-flex  '>
              <button
                onClick={() => dispatch(generatePassword())}
                className='btn btn-primary flex-grow-1 '
              >
                Generate Password
              </button>
            </div>
            {password && (
              <div>
                <div onDoubleClick={() => showAlert(password)} className='mb-3'>
                  <input
                    type='text'
                    id='example-readonly'
                    className='form-control text-center py-3 '
                    readOnly={true}
                    value={password}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordGenerator;
