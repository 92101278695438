import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import authHeader from 'services/auth-header';
import API from 'services/axios';

function ShipmentMethodsAdd({ showAddPage, refreshFunc, lastSortId }) {
  /*   All Satates Below
   ********************************************* */
  const { register, handleSubmit } = useForm();
  const [formError, setFormError] = useState({});
  // redux

  /*   All Functions Below
   ********************************************* */
  const addShipmentMethodsData = async (formData, e) => {
    setFormError({});
    e.preventDefault();
    if (formData.shippingMethod === '') {
      setFormError({
        shippingMethod: { type: 'shippingMethod', message: 'Shipping method is required' },
      });
      return;
    }

    try {
      const { data } = await API.post(
        '/shipmentmethods',
        { data: { ...formData, sortId: lastSortId + 1 } },
        { headers: authHeader() }
      );
      if (data.message.type === 'success') {
        refreshFunc();
        showAddPage(false);
        return;
      }
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <div className='row'>
      <div className='col-12'>
        {/*    ADD FORM START
         ********************************************* */}
        <div className='mb-13 ms-3'>
          <h3>ADD Shipment Method</h3>
        </div>
        <form className='ps-3 pe-3' onSubmit={handleSubmit(addShipmentMethodsData)}>
          {/* ----------  Fields  ----------- */}
          <div className='mt-3 mb-3'>
            <label htmlFor='shippingMethod' className='form-label'>
              Shipping Method
            </label>
            <input
              className='form-control'
              type='text'
              id='shippingMethod'
              placeholder='Enter shipping method'
              {...register('shippingMethod')}
            />
            {formError && formError.shippingMethod && (
              <p className='text-danger'>{formError.shippingMethod.message}</p>
            )}
          </div>

          <div className={`mt-4 mb-2 d-flex justify-content-end`}>
            <div>
              <button
                className='btn px-1 btn-secondary'
                type='button'
                onClick={() => {
                  showAddPage(false);
                }}
              >
                Cancel
              </button>
              <button className='btn btn-primary ms-1' type='submit'>
                ADD
              </button>
            </div>
          </div>
        </form>

        {/*    ADD FORM END
         ********************************************* */}
      </div>
    </div>
  );
}

export default ShipmentMethodsAdd;
